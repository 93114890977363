import React, { FC, useCallback, useState } from 'react';
import './RadioBox.scss';
import EditableBox from './EditableBox';
import { IState, MyCalcThunkDispatch } from '../../../redux/store';
import { ParametersState } from '../../../redux/parametersReducer';
import { useDispatch, useSelector } from 'react-redux';
import { changeCalculationParameter } from '../../../redux/parametersActions';
import '@material/form-field/mdc-form-field.scss';
import '@material/radio/mdc-radio.scss';
import { MDCFormField } from '@material/form-field';
import { MDCRadio } from '@material/radio';
import { FormattedMessage } from 'react-intl';
import { ValueKey } from '../../../redux/valueKey';
import { LockingDrive, RangeOfApplication } from '../../../redux/constants';
import { Validation } from '../../../redux/nrwgReducer';
import { AnyAction } from 'redux';
import { Help } from '../ParameterSection';

type OptionsProps = Array<ButtonPropsWithoutChecked>;

interface RadioButtonProps extends ButtonPropsWithoutChecked {
  checked: boolean;
  valueKey: ValueKey;
  onChange?: (s: string) => void;
  usedOutsideOfParameterSection?: boolean;
}

interface ButtonPropsWithoutChecked {
  name: string;
}

export interface RadioBoxProps {
  options: OptionsProps;
  heading: string;
  valueKey: ValueKey;
  isSingleEdit: boolean;
  helpContent?: Help;
  usedOutsideOfParameterSection?: boolean;
  onChange?: (s: string) => void;
  value?: string;
  errorMessage?: string;
}

function RadioButton(props: RadioButtonProps) {
  const dispatch: MyCalcThunkDispatch<AnyAction> = useDispatch();
  const [radioButton, setRadioButton] = useState<HTMLInputElement>();
  const [form, setForm] = useState<MDCFormField>();

  const radioButtonRef = useCallback(
    (button: any) => {
      if (!button) {
        return;
      }

      const radioButton = new MDCRadio(button);
      setRadioButton(button);

      if (form) {
        form.input = radioButton;
      }
    },
    [form],
  );

  const formRef = useCallback(
    (formElement: any) => {
      if (!formElement) {
        return;
      }
      const localForm = new MDCFormField(formElement);
      setForm(localForm);

      if (radioButton) {
        localForm.input = new MDCRadio(radioButton);
      }
    },
    [radioButton],
  );

  function getId(): string {
    return `${props.name}${props.valueKey}${!!props.onChange}`;
  }

  return (
    <div className="radio-box mdc-form-field" ref={formRef}>
      <div className="mdc-radio" ref={radioButtonRef}>
        <input
          className="mdc-radio__native-control"
          id={getId()}
          type="radio"
          name={props.valueKey}
          value={props.name}
          checked={props.checked}
          onChange={() => {
            props.onChange
              ? props.onChange(props.name)
              : dispatch(
                  changeCalculationParameter(props.valueKey, props.name),
                );
          }}
        />
        <div className="mdc-radio__background">
          <div className="mdc-radio__outer-circle" />
          <div className="mdc-radio__inner-circle" />
        </div>
        <div className="mdc-radio__ripple" />
      </div>
      <label htmlFor={getId()}>
        <FormattedMessage id={props.name} />
      </label>
    </div>
  );
}

export const RadioBox: FC<React.PropsWithChildren<RadioBoxProps>> = (
  props: RadioBoxProps,
) => {
  const storeValues: ParametersState = useSelector(
    (store: IState) => store.parameters,
  );
  const validation = useSelector<IState, Validation | undefined>(
    s => s.nrwg.validation,
  );

  function lockingDriveInvalid(): boolean {
    if (!validation) {
      return false;
    }
    return !validation.lockingDrive;
  }

  function showValidationErrorForLockingDrive(valueKey: ValueKey): boolean {
    return (
      valueKey === ValueKey.LOCKING_DRIVE &&
      storeValues.lockingDrive.value === LockingDrive.VLD &&
      storeValues.rangeOfApplication.value === RangeOfApplication.ROOF &&
      lockingDriveInvalid()
    );
  }

  return (
    <EditableBox
      {...{
        ...props,
        elementToFocusSelector: `#${storeValues[props.valueKey].value}${
          props.valueKey
        }`,
      }}
    >
      <div data-testid="radio-box">
        {props.options.map(option => {
          return (
            <RadioButton
              key={option.name}
              {...option}
              valueKey={props.valueKey}
              checked={
                !props.usedOutsideOfParameterSection
                  ? storeValues[props.valueKey].value === option.name
                  : props.value === option.name
              }
              onChange={props.onChange}
              usedOutsideOfParameterSection={
                props.usedOutsideOfParameterSection
              }
            />
          );
        })}
      </div>
      {showValidationErrorForLockingDrive(props.valueKey) &&
        props.errorMessage && (
          <LockingDriveError errorMessage={props.errorMessage} />
        )}
    </EditableBox>
  );
};

interface LockingDriveErrorProps {
  errorMessage: string;
}

const LockingDriveError: React.FC<
  React.PropsWithChildren<LockingDriveErrorProps>
> = (props: LockingDriveErrorProps) => {
  return (
    <div
      className={
        'window-area-box__tested-area window-area-box__tested-area--invalid'
      }
      style={{ display: 'flex' }}
    >
      <div className="window-area-box__locking-drive-marker validation-label">
        {/*<FormattedMessage id={props.errorMessage} />*/}
      </div>
      <div className="window-area-box__locking-drive-label validation-label">
        <FormattedMessage id={props.errorMessage} />
      </div>
    </div>
  );
};

export default RadioBox;
