import React, { FC, ReactNode } from 'react';
import './TableHeader.scss';
import classNames from 'classnames';

interface TableHeaderProps {
  children: ReactNode;
  largeHeader?: boolean;
  sticky?: boolean;
}

const TableHeader: FC<React.PropsWithChildren<TableHeaderProps>> = (
  props: TableHeaderProps,
) => {
  return (
    <thead>
      <tr
        className={classNames('table-header', {
          'table-header--large-header': props.largeHeader,
          'table-header--sticky': props.sticky,
        })}
      >
        {props.children}
      </tr>
    </thead>
  );
};

export default TableHeader;
