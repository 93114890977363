import React, { FC, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import './TextArea.scss';
import { FieldLabel } from './FieldLabel';
import { Parameters } from '../components/Parameters/ParameterValue';
import { LiteralParameterValue } from '../redux/parametersReducer';

interface TextAreaProps {
  label: string;
  placeholder: string;
  value: LiteralParameterValue;
  id?: string;
  additionalClass?: string;
  onChange?: (s: string) => void;
  saveValue?: (s: string) => void;
  size?: number;
  inputMax?: number;
  inputCount?: boolean;
  disabled?: boolean;
}

export const TextArea: FC<React.PropsWithChildren<TextAreaProps>> = (
  props: TextAreaProps,
) => {
  const [inputSize, setInputSize] = useState(
    props.value !== Parameters.NO_SELECTION
      ? props.value?.toString().length
      : 0,
  );

  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    setInputSize(props.value?.toString().length);
  }, [props.value]);

  function conditionalClass(
    property: string | undefined,
    prefix?: string,
  ): string {
    return property ? ` ${prefix || ''}${property}` : '';
  }

  function selectAllText(): void {
    if (textAreaRef.current) {
      textAreaRef.current.select();
    }
  }

  return (
    <div
      className={classNames(
        'text-area' + conditionalClass(props.additionalClass),
      )}
    >
      <FieldLabel>{props.label}</FieldLabel>
      <textarea
        onFocus={selectAllText}
        ref={textAreaRef}
        disabled={props.disabled}
        className={classNames('text-area__area', {
          'text-area__area--small': props.size === 3,
        })}
        placeholder={props.placeholder}
        value={props.value as string}
        id={props.id}
        onChange={e => {
          props.onChange && props.onChange(e.target.value);
          props.saveValue && props.saveValue(e.target.value);
          setInputSize(
            props.value === Parameters.NO_SELECTION
              ? 0
              : props.value?.toString().length,
          );
        }}
        rows={props.size}
        maxLength={props.inputMax}
      />
      {props.inputCount && (
        <div className="text-area__input-count">
          {inputSize}/{props.inputMax}
        </div>
      )}
    </div>
  );
};

export default TextArea;
