import { Action, AnyAction, Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';
import {
  BasicProfile,
  ExchangeProfile,
  FrameProfileFacade,
  SashProfileFacade,
  Series,
  System,
  TestPhase,
  TestPhaseApplicationOverviewWithOptionalId,
  TestPhaseApplicationWithOptionalId,
  TestPhaseMountingTypeWithOptionalId,
  TestPhaseProfileTypeWithOptionalId,
  TestPhaseSeriesOpeningDirectionWithOptionalId,
  TestPhaseSeriesWithOptionalId,
  TestPhaseSystem,
  TestPhaseSystemWithOptionalId,
  TestPhaseWindLoadOrSnowLoadWithOptionalId,
  TestPhaseWithOptionalId,
} from './adminFacadeReducer';
import { AdminState } from './adminStore';
import _ from 'lodash';
import {
  Funktionssicherheit,
  UmgebungsTemp,
  Waermebestaendigkeit,
} from '../../admin/constants';
import {
  applicationAlreadyIncludedInOpeningType,
  mountingTypeAlreadyIncludedInApplication,
  openingDirectionAlreadyIncludedInSeries,
  profileTypeAlreadyIncludedInMountingType,
} from '../../admin/facade/FacadeTestPhases';
import { ACTIVE_STATE } from './adminRoofReducer';

export interface UpdateEditableTestPhase
  extends Action<'UPDATE_EDITED_TEST_PHASE'> {
  editedTestPhase: TestPhaseWithOptionalId;
}

export interface UpdateEditedTestPhaseSystem
  extends Action<'UPDATE_EDITED_TEST_PHASE_SYSTEM'> {
  editedTestPhaseSystem: TestPhaseSystemWithOptionalId | undefined;
  values?: Record<string, number | string>;
}

export interface UpdateEditedTestPhaseSeries
  extends Action<'UPDATE_EDITED_TEST_PHASE_SERIES'> {
  editedTestPhaseSeries: TestPhaseSeriesWithOptionalId | undefined;
  values?: Record<
    string,
    | number
    | undefined
    | null
    | TestPhaseSeriesOpeningDirectionWithOptionalId[]
    | TestPhaseApplicationOverviewWithOptionalId[]
    | TestPhaseWindLoadOrSnowLoadWithOptionalId[]
    | FrameProfileFacade[]
    | ExchangeProfile[]
    | SashProfileFacade[]
    | BasicProfile[]
  >;
}

export interface UpdateEditedTestPhaseSeriesOpeningDirection
  extends Action<'UPDATE_EDITED_TEST_PHASE_SERIES_OPENING_DIRECTION'> {
  editedTestPhaseSeriesOpeningDirection:
    | TestPhaseSeriesOpeningDirectionWithOptionalId
    | undefined;
  values?: Record<string, TestPhaseSeriesOpeningDirectionWithOptionalId>;
}

export interface UpdateEditedTestPhaseApplication
  extends Action<'UPDATE_EDITED_TEST_PHASE_APPLICATION'> {
  editedTestPhaseApplication: TestPhaseApplicationWithOptionalId | undefined;
  values?: Record<string, TestPhaseApplicationWithOptionalId>;
}

export interface UpdateEditedTestPhaseMountingType
  extends Action<'UPDATE_EDITED_TEST_PHASE_MOUNTING_TYPE'> {
  editedTestPhaseMountingType: TestPhaseMountingTypeWithOptionalId | undefined;
  values?: Record<string, TestPhaseMountingTypeWithOptionalId>;
}

export interface UpdateEditedTestPhaseProfileType
  extends Action<'UPDATE_EDITED_TEST_PHASE_PROFILE_TYPE'> {
  editedTestPhaseProfileType: TestPhaseProfileTypeWithOptionalId | undefined;
  values?: Record<string, TestPhaseProfileTypeWithOptionalId>;
}

export interface UpdateEditedTestPhaseApplicationOverviews
  extends Action<'UPDATE_EDITED_TEST_PHASE_APPLICATION_OVERVIEWS'> {
  editedTestPhaseApplicationOverviews: TestPhaseApplicationOverviewWithOptionalId[];
}

export interface UpdateEditedTestPhaseApplicationOverview
  extends Action<'UPDATE_EDITED_TEST_PHASE_APPLICATION_OVERVIEW'> {
  editedTestPhaseApplicationOverview:
    | TestPhaseApplicationOverviewWithOptionalId
    | {};
}

export interface UpdateEditedTestPhaseWindLoads
  extends Action<'UPDATE_EDITED_TEST_PHASE_LOADS'> {
  editedTestPhaseLoads: TestPhaseWindLoadOrSnowLoadWithOptionalId[];
}

export interface UpdateEditedTestPhaseWindLoad
  extends Action<'UPDATE_EDITED_TEST_PHASE_LOAD'> {
  editedTestPhaseLoad: TestPhaseWindLoadOrSnowLoadWithOptionalId | {};
}

export type TEST_PHASE_ACTIONS =
  | UpdateEditableTestPhase
  | UpdateEditedTestPhaseSystem
  | UpdateEditedTestPhaseSeries
  | UpdateEditedTestPhaseSeriesOpeningDirection
  | UpdateEditedTestPhaseApplication
  | UpdateEditedTestPhaseMountingType
  | UpdateEditedTestPhaseProfileType
  | UpdateEditedTestPhaseApplicationOverviews
  | UpdateEditedTestPhaseApplicationOverview
  | UpdateEditedTestPhaseWindLoads
  | UpdateEditedTestPhaseWindLoad;

export function updateEditedTestPhase(
  editableTestPhase: TestPhaseWithOptionalId,
): UpdateEditableTestPhase {
  return {
    type: 'UPDATE_EDITED_TEST_PHASE',
    editedTestPhase: editableTestPhase,
  };
}

export function updateEditedTestPhaseSystem(
  editedTestPhaseSystem: TestPhaseSystemWithOptionalId | undefined,
  values?: Record<string, any>,
): UpdateEditedTestPhaseSystem {
  return {
    type: 'UPDATE_EDITED_TEST_PHASE_SYSTEM',
    editedTestPhaseSystem: editedTestPhaseSystem,
    values: values,
  };
}

export function updateEditedTestPhaseSeries(
  editedTestPhaseSeries: TestPhaseSeriesWithOptionalId | undefined,
  values?: Record<
    string,
    | number
    | undefined
    | null
    | TestPhaseSeriesOpeningDirectionWithOptionalId[]
    | TestPhaseApplicationOverviewWithOptionalId[]
    | TestPhaseWindLoadOrSnowLoadWithOptionalId[]
    | FrameProfileFacade[]
    | ExchangeProfile[]
    | SashProfileFacade[]
    | BasicProfile[]
  >,
): UpdateEditedTestPhaseSeries {
  return {
    type: 'UPDATE_EDITED_TEST_PHASE_SERIES',
    editedTestPhaseSeries: editedTestPhaseSeries,
    values: values,
  };
}

export function updateEditedTestPhaseSeriesOpeningDirection(
  editedTestPhaseSeriesOpeningDirection:
    | TestPhaseSeriesOpeningDirectionWithOptionalId
    | undefined,
  values?: Record<string, any>,
): UpdateEditedTestPhaseSeriesOpeningDirection {
  return {
    type: 'UPDATE_EDITED_TEST_PHASE_SERIES_OPENING_DIRECTION',
    editedTestPhaseSeriesOpeningDirection:
      editedTestPhaseSeriesOpeningDirection,
    values: values,
  };
}

export function updateEditedTestPhaseApplication(
  editedTestPhaseApplication: TestPhaseApplicationWithOptionalId | undefined,
  values?: Record<string, any>,
): UpdateEditedTestPhaseApplication {
  return {
    type: 'UPDATE_EDITED_TEST_PHASE_APPLICATION',
    editedTestPhaseApplication: editedTestPhaseApplication,
    values: values,
  };
}

export function updateEditedTestPhaseMountingType(
  editedTestPhaseMountingType: TestPhaseMountingTypeWithOptionalId | undefined,
  values?: Record<string, any>,
): UpdateEditedTestPhaseMountingType {
  return {
    type: 'UPDATE_EDITED_TEST_PHASE_MOUNTING_TYPE',
    editedTestPhaseMountingType: editedTestPhaseMountingType,
    values: values,
  };
}

export function updateEditedTestPhaseProfileType(
  editedTestPhaseProfileType: TestPhaseProfileTypeWithOptionalId | undefined,
  values?: Record<string, any>,
): UpdateEditedTestPhaseProfileType {
  return {
    type: 'UPDATE_EDITED_TEST_PHASE_PROFILE_TYPE',
    editedTestPhaseProfileType: editedTestPhaseProfileType,
    values: values,
  };
}

export function updateEditedTestPhaseApplicationOverviews(
  editedTestPhaseApplicationOverviews: TestPhaseApplicationOverviewWithOptionalId[],
): UpdateEditedTestPhaseApplicationOverviews {
  return {
    type: 'UPDATE_EDITED_TEST_PHASE_APPLICATION_OVERVIEWS',
    editedTestPhaseApplicationOverviews: editedTestPhaseApplicationOverviews,
  };
}

export function updateEditedTestPhaseApplicationOverview(
  editedTestPhaseApplicationOverview:
    | TestPhaseApplicationOverviewWithOptionalId
    | {},
): UpdateEditedTestPhaseApplicationOverview {
  return {
    type: 'UPDATE_EDITED_TEST_PHASE_APPLICATION_OVERVIEW',
    editedTestPhaseApplicationOverview: editedTestPhaseApplicationOverview,
  };
}

export function updateEditedTestPhaseLoads(
  editedTestPhaseLoads: TestPhaseWindLoadOrSnowLoadWithOptionalId[],
): UpdateEditedTestPhaseWindLoads {
  return {
    type: 'UPDATE_EDITED_TEST_PHASE_LOADS',
    editedTestPhaseLoads: editedTestPhaseLoads,
  };
}

export function updateEditedTestPhaseLoad(
  editedTestPhaseLoad: TestPhaseWindLoadOrSnowLoadWithOptionalId | {},
): UpdateEditedTestPhaseWindLoad {
  return {
    type: 'UPDATE_EDITED_TEST_PHASE_LOAD',
    editedTestPhaseLoad: editedTestPhaseLoad,
  };
}

export function initializeNewEditableTestPhase(): ThunkAction<
  Promise<void>,
  AdminState,
  void,
  AnyAction
> {
  return async (
    dispatch: Dispatch,
    getState: () => AdminState,
  ): Promise<void> => {
    const newEditableTestPhase: TestPhaseWithOptionalId = {
      name: 'Neue Testphase',
      active: ACTIVE_STATE.INACTIVE,
      minAbstandDrehband: undefined,
      testPhaseSystems: [],
    };

    dispatch(updateEditedTestPhase(newEditableTestPhase));
  };
}

export function addTestPhaseSystemToEditableTestPhase(
  addedSystem: System,
): ThunkAction<Promise<void>, AdminState, void, AnyAction> {
  return async (
    dispatch: Dispatch,
    getState: () => AdminState,
  ): Promise<void> => {
    const updatedTestPhase: TestPhase = _.cloneDeep(
      getState().adminFacade.editedTestPhase as TestPhase,
    )!;

    const newTps: TestPhaseSystemWithOptionalId = {
      system: addedSystem,
      klassWaermebest: Waermebestaendigkeit.B300E,
      klassFunk: Funktionssicherheit.RE1000PLUS,
      umgebungstemp: UmgebungsTemp.ZERO,
      testPhaseSeries: [],
      maxWindLoad: undefined,
    };
    if (
      updatedTestPhase.testPhaseSystems &&
      !updatedTestPhase.testPhaseSystems
        .map(tps => tps.system.id)
        .includes(addedSystem.id)
    ) {
      updatedTestPhase.testPhaseSystems.push(newTps as TestPhaseSystem);
    }
    dispatch(updateEditedTestPhase(updatedTestPhase));
  };
}

export function addTestPhaseSeriesToEditableTestPhaseSystem(
  addedSeries: Series,
): ThunkAction<Promise<void>, AdminState, void, AnyAction> {
  return async (
    dispatch: Dispatch,
    getState: () => AdminState,
  ): Promise<void> => {
    if (
      getState()
        .adminFacade.editedTestPhaseSystem!.testPhaseSeries.map(
          series => series.series,
        )
        .includes(addedSeries)
    ) {
      return;
    }

    const newTestPhaseSeries: TestPhaseSeriesWithOptionalId = {
      maxEinbauwinkel: undefined,
      minGepruefteFlaeche: undefined,
      minEinbauwinkel: undefined,
      maxGepruefteFlaeche: undefined,
      series: addedSeries,
      openingDirection: [],
      applicationOverviews: [],
      windLoads: [],
      frameProfiles: [],
      sashProfiles: [],
      exchangeProfiles: [],
      baseProfiles: [],
    };

    const updatedSeriesList = [
      ...getState().adminFacade.editedTestPhaseSystem!.testPhaseSeries,
      newTestPhaseSeries,
    ];

    dispatch(
      updateEditedTestPhaseSystem(
        getState().adminFacade.editedTestPhaseSystem,
        { testPhaseSeries: updatedSeriesList },
      ),
    );
  };
}

export function removeTestPhaseSystemFromEditableTestPhase(
  removedSystem: System,
): ThunkAction<Promise<void>, AdminState, void, AnyAction> {
  return async (
    dispatch: Dispatch,
    getState: () => AdminState,
  ): Promise<void> => {
    const updatedTestPhase: TestPhase = _.cloneDeep(
      getState().adminFacade.editedTestPhase as TestPhase,
    )!;
    if (updatedTestPhase.testPhaseSystems) {
      const indexToDelete = (
        updatedTestPhase.testPhaseSystems as TestPhaseSystem[]
      )
        .map(tps => tps.system.id)
        .indexOf(removedSystem.id);

      if (indexToDelete !== -1) {
        updatedTestPhase.testPhaseSystems.splice(indexToDelete, 1);
        dispatch(updateEditedTestPhase(updatedTestPhase));
      }
    }
    dispatch(updateEditedTestPhaseSystem(undefined));
  };
}

export function removeTestPhaseSeriesFromEditableTestPhaseSystem(
  removedSeries: Series,
): ThunkAction<Promise<void>, AdminState, void, AnyAction> {
  return async (
    dispatch: Dispatch,
    getState: () => AdminState,
  ): Promise<void> => {
    const updatedSeriesList = [
      ...getState().adminFacade.editedTestPhaseSystem!.testPhaseSeries,
    ];
    const indexToDelete = _.findIndex(
      updatedSeriesList,
      e => e.series === removedSeries,
    );

    if (indexToDelete !== -1) {
      updatedSeriesList.splice(indexToDelete, 1);
    }

    dispatch(
      updateEditedTestPhaseSystem(
        getState().adminFacade.editedTestPhaseSystem,
        { testPhaseSeries: updatedSeriesList },
      ),
    );

    dispatch(updateEditedTestPhaseSeries(undefined));
  };
}

export function addTestPhaseSeriesOpeningDirectionToEditableTestPhaseSeries(
  addedOpeningDirection: TestPhaseSeriesOpeningDirectionWithOptionalId,
): ThunkAction<Promise<void>, AdminState, void, AnyAction> {
  return async (
    dispatch: Dispatch,
    getState: () => AdminState,
  ): Promise<void> => {
    if (
      openingDirectionAlreadyIncludedInSeries(
        getState,
        state =>
          state.adminFacade.editedTestPhaseSeries
            ?.openingDirection as TestPhaseSeriesOpeningDirectionWithOptionalId[],
        addedOpeningDirection,
      )
    ) {
      return;
    }

    const updatedOpeningDirectionList: TestPhaseSeriesOpeningDirectionWithOptionalId[] =
      [
        ...getState().adminFacade.editedTestPhaseSeries!.openingDirection,
        addedOpeningDirection,
      ];

    dispatch(
      updateEditedTestPhaseSeries(
        getState().adminFacade.editedTestPhaseSeries,
        { openingDirection: updatedOpeningDirectionList },
      ),
    );
    dispatch(updateEditedTestPhaseSeriesOpeningDirection(undefined));
  };
}

function deleteInList<T>(updatedList: T[], removedElement: T): void {
  const indexToDelete = _.findIndex(updatedList, e => e === removedElement);

  if (indexToDelete !== -1) {
    updatedList.splice(indexToDelete, 1);
  }
}

export function removeTestPhaseSeriesOpeningDirectionFromEditableTestPhaseSeries(
  removedOpeningDirection: TestPhaseSeriesOpeningDirectionWithOptionalId,
): ThunkAction<Promise<void>, AdminState, void, AnyAction> {
  return async (
    dispatch: Dispatch,
    getState: () => AdminState,
  ): Promise<void> => {
    const updatedOpeningDirectionList = [
      ...getState().adminFacade.editedTestPhaseSeries!.openingDirection,
    ];
    deleteInList(updatedOpeningDirectionList, removedOpeningDirection);

    dispatch(
      updateEditedTestPhaseSeries(
        getState().adminFacade.editedTestPhaseSeries,
        { openingDirection: updatedOpeningDirectionList },
      ),
    );

    dispatch(updateEditedTestPhaseSeriesOpeningDirection(undefined));
  };
}

export function addTestPhaseApplicationToEditedSeriesOpeningDirection(
  addedApplication: TestPhaseApplicationWithOptionalId,
): ThunkAction<Promise<void>, AdminState, void, AnyAction> {
  return async (
    dispatch: Dispatch,
    getState: () => AdminState,
  ): Promise<void> => {
    if (
      applicationAlreadyIncludedInOpeningType(
        getState,
        state =>
          state.adminFacade.editedTestPhaseSeriesOpeningDirection
            ?.anwendungen as TestPhaseApplicationWithOptionalId[],
        addedApplication,
      )
    ) {
      return;
    }

    const updatedTestPhaseApplicationList: TestPhaseApplicationWithOptionalId[] =
      [
        ...getState().adminFacade.editedTestPhaseSeriesOpeningDirection!
          .anwendungen,
        addedApplication,
      ];

    dispatch(
      updateEditedTestPhaseSeriesOpeningDirection(
        getState().adminFacade.editedTestPhaseSeriesOpeningDirection,
        { anwendungen: updatedTestPhaseApplicationList },
      ),
    );
    dispatch(updateEditedTestPhaseApplication(undefined));
  };
}

export function removeTestPhaseApplicationFromEditedSeriesOpeningDirection(
  removedApplication: TestPhaseApplicationWithOptionalId,
): ThunkAction<Promise<void>, AdminState, void, AnyAction> {
  return async (
    dispatch: Dispatch,
    getState: () => AdminState,
  ): Promise<void> => {
    const updatedApplicationList = [
      ...(getState().adminFacade.editedTestPhaseSeriesOpeningDirection
        ?.anwendungen || []),
    ];

    deleteInList(updatedApplicationList, removedApplication);

    dispatch(
      updateEditedTestPhaseSeriesOpeningDirection(
        getState().adminFacade.editedTestPhaseSeriesOpeningDirection,
        { anwendungen: updatedApplicationList },
      ),
    );

    dispatch(updateEditedTestPhaseApplication(undefined));
  };
}

export function addTestPhaseMountingTypeToEditedTestPhaseApplication(
  addedMountingType: TestPhaseMountingTypeWithOptionalId,
): ThunkAction<Promise<void>, AdminState, void, AnyAction> {
  return async (
    dispatch: Dispatch,
    getState: () => AdminState,
  ): Promise<void> => {
    if (
      mountingTypeAlreadyIncludedInApplication(
        getState,
        state =>
          state.adminFacade.editedTestPhaseApplication
            ?.mountingTypes as TestPhaseMountingTypeWithOptionalId[],
        addedMountingType,
      )
    ) {
      return;
    }

    const updatedTestPhaseMountingTypeList: TestPhaseMountingTypeWithOptionalId[] =
      [
        ...getState().adminFacade.editedTestPhaseApplication!.mountingTypes,
        addedMountingType,
      ];

    dispatch(
      updateEditedTestPhaseApplication(
        getState().adminFacade.editedTestPhaseApplication,
        { mountingTypes: updatedTestPhaseMountingTypeList },
      ),
    );

    dispatch(updateEditedTestPhaseMountingType(undefined));
  };
}

export function removeTestPhaseMountingTypeFromEditedTestPhaseApplication(
  removedMountingType: TestPhaseMountingTypeWithOptionalId,
): ThunkAction<Promise<void>, AdminState, void, AnyAction> {
  return async (
    dispatch: Dispatch,
    getState: () => AdminState,
  ): Promise<void> => {
    const updatedMountingTypeList = [
      ...getState().adminFacade.editedTestPhaseApplication!.mountingTypes,
    ];

    deleteInList(updatedMountingTypeList, removedMountingType);

    dispatch(
      updateEditedTestPhaseApplication(
        getState().adminFacade.editedTestPhaseApplication,
        { mountingTypes: updatedMountingTypeList },
      ),
    );

    dispatch(updateEditedTestPhaseMountingType(undefined));
  };
}

export function addTestPhaseProfileTypeToEditedTestPhaseMountingType(
  addedProfileType: TestPhaseProfileTypeWithOptionalId,
): ThunkAction<Promise<void>, AdminState, void, AnyAction> {
  return async (
    dispatch: Dispatch,
    getState: () => AdminState,
  ): Promise<void> => {
    if (
      profileTypeAlreadyIncludedInMountingType(
        getState,
        state =>
          state.adminFacade.editedTestPhaseMountingType
            ?.profileTypes as TestPhaseProfileTypeWithOptionalId[],
        addedProfileType,
      )
    ) {
      return;
    }

    const updatedTestPhaseProfileTypeList: TestPhaseProfileTypeWithOptionalId[] =
      [
        ...getState().adminFacade.editedTestPhaseMountingType!.profileTypes,
        addedProfileType,
      ];

    dispatch(
      updateEditedTestPhaseMountingType(
        getState().adminFacade.editedTestPhaseMountingType,
        { profileTypes: updatedTestPhaseProfileTypeList },
      ),
    );

    dispatch(updateEditedTestPhaseProfileType(undefined));
  };
}

export function removeTestPhaseProfileTypeFromEditedTestPhaseMountingType(
  removedProfileType: TestPhaseProfileTypeWithOptionalId,
): ThunkAction<Promise<void>, AdminState, void, AnyAction> {
  return async (
    dispatch: Dispatch,
    getState: () => AdminState,
  ): Promise<void> => {
    const updatedProfileTypeList = [
      ...getState().adminFacade.editedTestPhaseMountingType!.profileTypes,
    ];

    deleteInList(updatedProfileTypeList, removedProfileType);

    dispatch(
      updateEditedTestPhaseMountingType(
        getState().adminFacade.editedTestPhaseMountingType,
        { profileTypes: updatedProfileTypeList },
      ),
    );

    dispatch(updateEditedTestPhaseProfileType(undefined));
  };
}
