import React, { FC, useCallback, useEffect, useRef } from 'react';
import './Snackbar.scss';
import { MDCSnackbar } from '@material/snackbar';
import { useDispatch, useSelector } from 'react-redux';
import { IState } from '../redux/store';
import { updateDisplaySnackBar } from '../redux/globalUiStateActions';
import { FormattedMessage } from 'react-intl';

const Snackbar: FC<React.PropsWithChildren<unknown>> = () => {
  const snackbarRef = useRef<MDCSnackbar | undefined>();
  const snackBarMessage = useSelector<IState, string>(
    state => state.globalUIState.snackBarMessage,
  );
  const snackBarMessageText = useSelector<IState, string>(
    state => state.globalUIState.snackBarMessageText,
  );

  const displaySnackBar = useSelector<IState, boolean>(
    state => state.globalUIState.displaySnackBar,
  );
  const dispatch = useDispatch();

  const snackbarCallback = useCallback((snackbar: any) => {
    if (snackbarRef.current) {
      snackbarRef.current.destroy();
    }

    if (!snackbar) {
      return;
    }

    snackbarRef.current = new MDCSnackbar(snackbar);
    snackbarRef.current!.listen('MDCSnackbar:closed', () =>
      dispatch(updateDisplaySnackBar(false)),
    );

    if (displaySnackBar && !snackbarRef.current?.isOpen) {
      snackbarRef.current!.open();
    }
  }, []);

  useEffect(() => {
    if (displaySnackBar && snackbarRef.current) {
      snackbarRef.current.open();
    }
  }, [displaySnackBar]);

  return (
    <aside className="mdc-snackbar" ref={snackbarCallback}>
      <div
        className="mdc-snackbar__surface"
        role="status"
        aria-relevant="additions"
      >
        <div className="mdc-snackbar__label" aria-atomic="false">
          {snackBarMessage ? (
            <FormattedMessage id={snackBarMessage} />
          ) : (
            snackBarMessageText
          )}
        </div>
        {/*<div className="mdc-snackbar__actions" aria-atomic="true">*/}
        {/*  <button type="button" className="mdc-button mdc-snackbar__action">*/}
        {/*    <div className="mdc-button__ripple"></div>*/}
        {/*    <span className="mdc-button__label">Retry</span>*/}
        {/*  </button>*/}
        {/*</div>*/}
      </div>
    </aside>
  );
};

export default Snackbar;
