import React, { FC, useState } from 'react';
import { DialogFE } from '../../Dialog';
import Table from '../../../elements/Table';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { ConsoleSet } from '../../../redux/staticDataReducer';
import { IState, MyCalcThunkDispatch } from '../../../redux/store';
import {
  selectConsoleSet,
  showDialog,
  storeSelectedNRWGConsoleSet,
} from '../../../redux/uiStateActions';
import { AnyAction } from 'redux';

const AddNRWGConsoleSetDialog: FC<React.PropsWithChildren<unknown>> = () => {
  const nrwgConsoleSetCandidates = useSelector<IState, ConsoleSet[]>(
    s => s.staticData.nrwgConsoleSetCandidates,
  );
  const [markedConsoleSet, setMarkedConsoleSet] = useState<
    ConsoleSet | undefined
  >();
  const dispatch: MyCalcThunkDispatch<AnyAction> = useDispatch();

  function selectConsoleSetHelper(): void {
    if (markedConsoleSet) {
      dispatch(storeSelectedNRWGConsoleSet(markedConsoleSet));
      dispatch(selectConsoleSet([markedConsoleSet]));
      dispatch(showDialog(undefined));
    }
  }

  return (
    <DialogFE
      heading="ADD_CONSOLE"
      componentClass="add-nrwg-console-set-dialog"
      footerProps={{
        cancelAction: true,
        saveAction: markedConsoleSet ? selectConsoleSetHelper : undefined,
        primaryActionLabel: 'BUTTON_LABEL_ADD',
      }}
    >
      <Table
        head={{
          columns: [<FormattedMessage id="PDF_BRACKET_RESULT_LABLE" />],
        }}
        body={
          nrwgConsoleSetCandidates?.map(cs => {
            return {
              key: cs.name,
              selected: cs === markedConsoleSet,
              onClick: () => setMarkedConsoleSet(cs),
              columns: [<FormattedMessage id={cs.name} />],
            };
          }) || []
        }
      />
    </DialogFE>
  );
};

export default AddNRWGConsoleSetDialog;
