import { ReactElement, useEffect, useState } from 'react';
import { Application } from '../../../redux/constants';
import { Locale } from '../../../redux/uiStateReducer';
import { useSelector } from 'react-redux';
import { IState } from '../../../redux/store';
import { FormattedMessage, useIntl } from 'react-intl';
import { fetchFromServer, RequestTypes } from '../../../redux/httpClient';
import { Message } from '../../../redux/nrwgReducer';
import './WindowAreaHelp.scss';
import {
  getImageTranslationLocale,
  TRANSLATION,
  windowAreaHelpImage,
} from './window-area-help-images/WindowAreaHelpImage';

interface WindowAreaHelpProps {
  application: Application;
  numberOfWindDeflectors: number | undefined;
  locale: Locale;
}

// Adding a new entry to the legend requires adding a new entry to the legend in attachmentGeneralDependencies.html as well
function Legend(props: { locale: Locale }) {
  return (
    <div className="information-dialog__legend">
      <h3>
        <FormattedMessage id="WINDOW_AREA_HELP_LEGEND_HEADING" />
      </h3>
      <dl className="information-dialog__legend-list">
        <dt>
          H
          <sub>
            {TRANSLATION[getImageTranslationLocale(props.locale)]['frameFR']}
          </sub>
        </dt>
        <dd>
          <FormattedMessage id="WINDOW_AREA_HELP_LEGEND_SASH_HEIGHT" />
        </dd>
        <dt>
          {TRANSLATION[getImageTranslationLocale(props.locale)]['widthB']}
          <sub>
            {TRANSLATION[getImageTranslationLocale(props.locale)]['frameFR']}
          </sub>
        </dt>
        <dd>
          <FormattedMessage id="WINDOW_AREA_HELP_LEGEND_SASH_WIDTH" />
        </dd>
        <dt>FRA</dt>
        <dd>
          <FormattedMessage id="WINDOW_AREA_BOX_LABEL_SASH_FRAME_DISTANCE" />
        </dd>
        <dt>
          {TRANSLATION[getImageTranslationLocale(props.locale)]['widthB']}
          <sub>
            {
              TRANSLATION[getImageTranslationLocale(props.locale)][
                'doubleFlapFRZK'
              ]
            }
          </sub>
        </dt>
        <dd>
          <FormattedMessage id="WINDOW_AREA_HELP_LEGEND_DOUBLE_FLAP_WIDTH" />
        </dd>
        <dt>
          H
          <sub>
            {
              TRANSLATION[getImageTranslationLocale(props.locale)][
                'doubleFlapFRZK'
              ]
            }
          </sub>
        </dt>
        <dd>
          <FormattedMessage id="WINDOW_AREA_HELP_LEGEND_DOUBLE_FLAP_HEIGHT" />
        </dd>
        <dt>
          {
            TRANSLATION[getImageTranslationLocale(props.locale)][
              'windDeflectorWD'
            ]
          }
        </dt>
        <dd>
          <FormattedMessage id="tree_parent_fenster_windleitwand" />
        </dd>
        <dt>
          H
          <sub>
            {
              TRANSLATION[getImageTranslationLocale(props.locale)][
                'windDeflectorWD'
              ]
            }
          </sub>
        </dt>
        <dd>
          <FormattedMessage id="WINDOW_AREA_HELP_LEGEND_WIND_DEFLECTOR_HEIGHT" />
        </dd>
        <dt>FA</dt>
        <dd>
          <FormattedMessage id="WINDOW_AREA_HELP_LEGEND_DISTANCE_TO_RIDGE" />
        </dd>
        <dt>TA</dt>
        <dd>
          <FormattedMessage id="WINDOW_AREA_HELP_LEGEND_DISTANCE_TO_ROOF_EDGE" />
        </dd>
        <dt>F</dt>
        <dd>
          <FormattedMessage id="WINDOW_AREA_HELP_LEGEND_DISTANCE_FLAP_TO_RIDGE" />
        </dd>
      </dl>
    </div>
  );
}

export default function WindowAreaHelp(
  props: WindowAreaHelpProps,
): ReactElement {
  const ranges = useSelector((state: IState) => state.nrwg.ranges);
  const state = useSelector((state: IState) => state);
  const { formatMessage } = useIntl();
  const [numberOfWindDeflectors, setNumberOfWindDeflectors] = useState<
    number | null
  >(null);

  useEffect(() => {
    fetchFromServer<{
      numberOfWindDeflectors: number | null;
    }>(RequestTypes.NUMBER_OF_WIND_DEFLECTORS, state).then(resp => {
      if (typeof resp === 'object') {
        setNumberOfWindDeflectors(resp.numberOfWindDeflectors);
      } else {
        setNumberOfWindDeflectors(null);
      }
    });
  }, []);

  return (
    <div className="information-dialog__content">
      {ranges && ranges.messagesForUnvalidatedRanges.length > 0 && (
        <h3>
          <FormattedMessage id="WINDOW_AREA_HELP_ADDITIONAL_CONDITIONS_HEADING" />
        </h3>
      )}
      {ranges?.messagesForUnvalidatedRanges.map(message => (
        <MessageForUnvalidatedRange {...message} />
      ))}
      <h3>
        <FormattedMessage id="WINDOW_AREA_HELP_MEASURES_HEADING" />
      </h3>
      {windowAreaHelpImage(
        props.application,
        numberOfWindDeflectors,
        props.locale,
      ) || (
        <div>
          <FormattedMessage id="WINDOW_AREA_HELP_IMAGE_NOT_FOUND" />
        </div>
      )}
      <Legend locale={props.locale} />
    </div>
  );
}

function MessageForUnvalidatedRange(props: Message): ReactElement {
  const { formatMessage } = useIntl();

  const message = formatMessage({ id: props.message }, props.values);

  return props.heading ? (
    <h4
      className="window-area-help__message-heading"
      dangerouslySetInnerHTML={{
        __html: message,
      }}
    />
  ) : (
    <p
      className="window-area-help__message-paragraph"
      dangerouslySetInnerHTML={{
        __html: message,
      }}
    />
  );
}
