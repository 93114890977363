import React, { FC, ReactNode } from 'react';

import './InfoMessage.scss';
import { ReactComponent as IconInformation } from './IconInformation.svg';

interface InfoMessageProps {
  children: ReactNode;
}

export const InfoMessage: FC<React.PropsWithChildren<InfoMessageProps>> = (
  props: InfoMessageProps,
) => {
  return (
    <div className="info-message">
      <div className="info-message__icon">
        <IconInformation />
      </div>
      <div className="info-message__text">
        <p>{props.children}</p>
      </div>
    </div>
  );
};

export default InfoMessage;
