import { Action, AnyAction } from 'redux';
import {
  AerodynamicDesmoking,
  GeometricDesmoking,
} from './desmokingResultReducer';
import { ThunkAction } from 'redux-thunk';
import { IState } from './store';
import { Dispatch } from './parametersActions';
import { calculating, finishedCalculation } from './uiStateActions';
import {
  calcultationURLParameters,
  fetchAndStore,
  handleResponse,
  RequestTypes,
} from './httpClient';
import { getMarkedDrive, getSelectedWindow } from '../hooks/selectorHooks';

export interface UpdateGeometricDesmokingData
  extends Action<'UPDATE_GEOMETRIC_DESMOKING_DATA'> {
  value: GeometricDesmoking | undefined;
}

export interface UpdateAerodynamicDesmokingData
  extends Action<'UPDATE_AERODYNAMIC_DESMOKING_DATA'> {
  value: AerodynamicDesmoking | undefined;
}

export type ResetDesmoking = Action<'RESET_DESMOKING'>;

export function resetDesmoking(): ResetDesmoking {
  return {
    type: 'RESET_DESMOKING',
  };
}
let fetchDelayTimerId: number;
let abortController: AbortController;

function clearFetchDelayTimer(): void {
  if (fetchDelayTimerId) {
    clearTimeout(fetchDelayTimerId);
  }
}

function abortFetch(): void {
  if (abortController) {
    abortController.abort();
  }
}

export function getAerodynamicDesmoking(): ThunkAction<
  Promise<void>,
  IState,
  void,
  AnyAction
> {
  return async (dispatch: Dispatch, getState: () => IState): Promise<void> => {
    const selectedConsoleSetIds = getState().ui.selectedConsoleSets?.map(
      c => c.id,
    );
    if (!selectedConsoleSetIds || selectedConsoleSetIds.length === 0) {
      dispatch(updateAerodynamicDesmokingData(undefined));
    }

    if (!getSelectedWindow(getState())?.nrwg) {
      return;
    }
    dispatch(calculating(RequestTypes.AERODYNAMIC_DESMOKING));
    return fetchAndStore(
      RequestTypes.AERODYNAMIC_DESMOKING,
      (response: AerodynamicDesmoking) =>
        dispatch(updateAerodynamicDesmokingData(response)),
      getState,
      { args: { selectedConsoleSetIds: selectedConsoleSetIds } },
    ).then(() => {
      dispatch(finishedCalculation(RequestTypes.AERODYNAMIC_DESMOKING));
    });
  };
}

export function updateAerodynamicDesmokingData(
  geometricDesmokingResult: AerodynamicDesmoking | undefined,
): UpdateAerodynamicDesmokingData {
  return {
    type: 'UPDATE_AERODYNAMIC_DESMOKING_DATA',
    value: geometricDesmokingResult,
  };
}

export function getGeomtricDesmoking(): ThunkAction<
  Promise<void>,
  IState,
  void,
  AnyAction
> {
  return async (dispatch: Dispatch, getState: () => IState): Promise<void> => {
    return new Promise(resolve => {
      const selectedConsoleSetIds = getState().ui.selectedConsoleSets?.map(
        c => c.id,
      );
      if (
        getSelectedWindow(getState())?.nrwg &&
        (!selectedConsoleSetIds || selectedConsoleSetIds.length === 0)
      ) {
        dispatch(updateGeometricDesmokingData(undefined));
      }
      const a = getSelectedWindow(getState())?.rwa === false;
      const b = getMarkedDrive(getState());

      if (
        getSelectedWindow(getState())?.rwa === false ||
        !getMarkedDrive(getState())
      ) {
        resolve();
        return;
      }
      clearFetchDelayTimer();

      fetchDelayTimerId = window.setTimeout(async () => {
        abortFetch();

        if (!getMarkedDrive(getState())) {
          resolve();
          return;
        }

        if (window.AbortController) {
          abortController = new AbortController();
        }
        const signal = abortController && abortController.signal;

        dispatch(calculating(RequestTypes.GEOMETRIC_DESMOKING));
        const geometricDesmokingResult = await fetchGeometricDesmoking(
          getState(),
          signal,
        );
        dispatch(finishedCalculation(RequestTypes.GEOMETRIC_DESMOKING));
        dispatch(updateGeometricDesmokingData(geometricDesmokingResult));
      }, 300);
      resolve();
    });
  };
}

export function updateGeometricDesmokingData(
  geometricDesmokingResult: GeometricDesmoking | undefined,
): UpdateGeometricDesmokingData {
  return {
    type: 'UPDATE_GEOMETRIC_DESMOKING_DATA',
    value: geometricDesmokingResult,
  };
}

export async function fetchGeometricDesmoking(
  state: IState,
  signal: AbortSignal,
): Promise<GeometricDesmoking | undefined> {
  const url = `/geometricDesmoking?${calcultationURLParameters(state)}`;
  return window
    .fetch(url, {
      signal,
    })
    .then(handleResponse(url));
}
