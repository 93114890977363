import * as React from 'react';
// @ts-ignore
const SvgEinzelklappeKippMit3Wlw = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="Einzelklappe_Kipp_mit_3_WLW_svg__Ebene_3"
    data-name="Ebene 3"
    viewBox="0 0 1440 1080"
    {...props}
    style={{ marginBottom: '-70px', marginTop: '-50px' }}
  >
    <defs>
      <style>
        {
          '.Einzelklappe_Kipp_mit_3_WLW_svg__cls-11,.Einzelklappe_Kipp_mit_3_WLW_svg__cls-12,.Einzelklappe_Kipp_mit_3_WLW_svg__cls-13,.Einzelklappe_Kipp_mit_3_WLW_svg__cls-2,.Einzelklappe_Kipp_mit_3_WLW_svg__cls-6,.Einzelklappe_Kipp_mit_3_WLW_svg__cls-7,.Einzelklappe_Kipp_mit_3_WLW_svg__cls-9{fill:none}.Einzelklappe_Kipp_mit_3_WLW_svg__cls-11,.Einzelklappe_Kipp_mit_3_WLW_svg__cls-12,.Einzelklappe_Kipp_mit_3_WLW_svg__cls-13,.Einzelklappe_Kipp_mit_3_WLW_svg__cls-17,.Einzelklappe_Kipp_mit_3_WLW_svg__cls-2,.Einzelklappe_Kipp_mit_3_WLW_svg__cls-6,.Einzelklappe_Kipp_mit_3_WLW_svg__cls-7,.Einzelklappe_Kipp_mit_3_WLW_svg__cls-9{stroke:#1e1e1c}.Einzelklappe_Kipp_mit_3_WLW_svg__cls-12,.Einzelklappe_Kipp_mit_3_WLW_svg__cls-6{stroke-width:.75px}.Einzelklappe_Kipp_mit_3_WLW_svg__cls-11,.Einzelklappe_Kipp_mit_3_WLW_svg__cls-12,.Einzelklappe_Kipp_mit_3_WLW_svg__cls-13{stroke-miterlimit:10}.Einzelklappe_Kipp_mit_3_WLW_svg__cls-2{stroke-width:1.88px}.Einzelklappe_Kipp_mit_3_WLW_svg__cls-17,.Einzelklappe_Kipp_mit_3_WLW_svg__cls-2,.Einzelklappe_Kipp_mit_3_WLW_svg__cls-6,.Einzelklappe_Kipp_mit_3_WLW_svg__cls-7,.Einzelklappe_Kipp_mit_3_WLW_svg__cls-9{stroke-linejoin:round}.Einzelklappe_Kipp_mit_3_WLW_svg__cls-22,.Einzelklappe_Kipp_mit_3_WLW_svg__cls-24{fill:#1e1e1c}.Einzelklappe_Kipp_mit_3_WLW_svg__cls-24{font-family:ArialMT,Arial}.Einzelklappe_Kipp_mit_3_WLW_svg__cls-11{stroke-width:2px}.Einzelklappe_Kipp_mit_3_WLW_svg__cls-9{stroke-width:.62px}.Einzelklappe_Kipp_mit_3_WLW_svg__cls-24{font-size:27.5px}.Einzelklappe_Kipp_mit_3_WLW_svg__cls-17{fill:#fff;stroke-width:1.25px}'
        }
      </style>
    </defs>
    <path
      d="m395.39 426.43 182.02-179.76 167.9 354.19-349.92-174.43z"
      style={{
        fill: '#ececec',
      }}
    />
    <path
      d="m577.41 246.67 199.89-91.15 167.12 354.01-99.5 45.64-80-152.9 54.91-25.12-64.04-135.32-96.93 44.84 63.87 134.84 42.19-19.24 80 152.9-99.61 45.69-167.9-354.19z"
      style={{
        fill: '#b0b0b1',
      }}
    />
    <path
      d="m577.41 246.67 199.89-91.15 167.12 354.01-199.11 91.33"
      className="Einzelklappe_Kipp_mit_3_WLW_svg__cls-11"
    />
    <path
      d="m395.39 426.43 182.02-179.76 167.9 354.19-349.92-174.43z"
      style={{
        strokeWidth: 2,
        strokeLinecap: 'round',
        strokeLinejoin: 'round',
        stroke: '#1e1e1c',
        fill: 'none',
      }}
    />
    <path
      d="m722.73 421.51-63.87-134.84 96.93-44.84 64.04 135.32-97.1 44.36z"
      className="Einzelklappe_Kipp_mit_3_WLW_svg__cls-11"
    />
    <text
      style={{
        fontSize: '27.5px',
        fontFamily: 'ArialMT,Arial',
        fill: '#1e1e1c',
      }}
      transform="rotate(-23.8 1448.765 -1609.5)"
    >
      <tspan x={0} y={0}>
        {props.widthB}
      </tspan>
    </text>
    <text
      style={{
        fontSize: 30,
        fontFamily: 'ArialMT,Arial',
        fill: '#1e1e1c',
      }}
      transform="rotate(-23.8 1463.304 -1658.03) scale(.58)"
    >
      <tspan x={0} y={0}>
        {props.frameFR}
      </tspan>
    </text>
    <text
      style={{
        fontSize: '27.5px',
        fontFamily: 'ArialMT,Arial',
        fill: '#1e1e1c',
      }}
      transform="rotate(64.08 42.772 686.392)"
    >
      <tspan x={0} y={0}>
        {props.heightH}
      </tspan>
    </text>
    <text
      style={{
        fontSize: 30,
        fontFamily: 'ArialMT,Arial',
        fill: '#1e1e1c',
      }}
      transform="rotate(64.08 24.862 697.267) scale(.58)"
    >
      <tspan x={0} y={0}>
        {props.frameFR}
      </tspan>
    </text>
    <text
      style={{
        fontFamily: 'ArialMT,Arial',
        fill: '#1e1e1c',
        fontSize: '27.5px',
        letterSpacing: '-.06em',
      }}
      transform="rotate(65.18 97.496 598.107)"
    >
      <tspan x={0} y={0}>
        {props.distanceNRWGToRidgeFA}
      </tspan>
    </text>
    <text
      className="Einzelklappe_Kipp_mit_3_WLW_svg__cls-24"
      transform="translate(659.19 529.1)"
    >
      <tspan x={0} y={0}>
        {'\u03B1'}
      </tspan>
    </text>
    <path
      d="M632.62 534.06a131.691 131.691 0 0 1 45.52-45.66"
      className="Einzelklappe_Kipp_mit_3_WLW_svg__cls-13"
    />
    <path
      d="m636.16 533.76-7.8 8.06 2.72-10.88 5.08 2.82zM677.84 491.94l8.05-7.83-10.88 2.75 2.83 5.08z"
      className="Einzelklappe_Kipp_mit_3_WLW_svg__cls-22"
    />
    <path
      d="m738.02 435.97 81.36-36.96"
      className="Einzelklappe_Kipp_mit_3_WLW_svg__cls-13"
    />
    <path
      d="m741.03 437.8-11.07 1.84 8.67-7.13 2.4 5.29zM818.77 402.48l8.67-7.13-11.07 1.84 2.4 5.29z"
      className="Einzelklappe_Kipp_mit_3_WLW_svg__cls-22"
    />
    <path
      d="m724.02 424.32 1.07 2.26"
      className="Einzelklappe_Kipp_mit_3_WLW_svg__cls-12"
    />
    <path
      d="m727.32 431.3 7.82 16.51"
      style={{
        strokeDasharray: '0 0 5.22 5.22',
        strokeMiterlimit: 10,
        strokeWidth: '.75px',
        stroke: '#1e1e1c',
        fill: 'none',
      }}
    />
    <path
      d="m736.26 450.16 1.07 2.26"
      className="Einzelklappe_Kipp_mit_3_WLW_svg__cls-12"
    />
    <path
      d="m645.11 301.28 56.28 118.88"
      className="Einzelklappe_Kipp_mit_3_WLW_svg__cls-13"
    />
    <path
      d="m643.33 304.32-2.01-11.04 7.27 8.55-5.26 2.49zM697.91 419.61l7.27 8.56-2.01-11.05-5.26 2.49z"
      className="Einzelklappe_Kipp_mit_3_WLW_svg__cls-22"
    />
    <path
      d="m628.53 300.45 2.28-1.04"
      className="Einzelklappe_Kipp_mit_3_WLW_svg__cls-12"
    />
    <path
      d="m635.28 297.38 15.65-7.11"
      style={{
        strokeDasharray: '0 0 4.91 4.91',
        strokeMiterlimit: 10,
        strokeWidth: '.75px',
        stroke: '#1e1e1c',
        fill: 'none',
      }}
    />
    <path
      d="m653.17 289.26 2.28-1.04M692.39 435.34l2.28-1.04"
      className="Einzelklappe_Kipp_mit_3_WLW_svg__cls-12"
    />
    <path
      d="m699.28 432.21 16.14-7.33"
      style={{
        strokeMiterlimit: 10,
        strokeWidth: '.75px',
        stroke: '#1e1e1c',
        fill: 'none',
        strokeDasharray: '0 0 5.06 5.06',
      }}
    />
    <path
      d="m717.72 423.83 2.28-1.03M821.7 380.44l1.07 2.26"
      className="Einzelklappe_Kipp_mit_3_WLW_svg__cls-12"
    />
    <path
      d="m824.96 387.33 7.68 16.23"
      style={{
        strokeDasharray: '0 0 5.13 5.13',
        strokeMiterlimit: 10,
        strokeWidth: '.75px',
        stroke: '#1e1e1c',
        fill: 'none',
      }}
    />
    <path
      d="m833.74 405.88 1.07 2.26"
      className="Einzelklappe_Kipp_mit_3_WLW_svg__cls-12"
    />
    <path
      d="m616.96 242.6 18.97 40.08"
      className="Einzelklappe_Kipp_mit_3_WLW_svg__cls-13"
    />
    <path
      d="m615.18 245.64-2.01-11.04 7.26 8.55-5.25 2.49zM632.46 282.13l7.26 8.56-2.01-11.05-5.25 2.49z"
      className="Einzelklappe_Kipp_mit_3_WLW_svg__cls-22"
    />
    <path
      d="M669.8 101.83h78.24"
      className="Einzelklappe_Kipp_mit_3_WLW_svg__cls-13"
    />
    <text
      className="Einzelklappe_Kipp_mit_3_WLW_svg__cls-24"
      transform="translate(686.01 97.81)"
    >
      <tspan x={0} y={0}>
        {props.windDeflectorWD}
      </tspan>
    </text>
    <text
      className="Einzelklappe_Kipp_mit_3_WLW_svg__cls-24"
      transform="translate(826.87 833.37)"
    >
      <tspan x={0} y={0}>
        3 {props.windDeflectorWD}
      </tspan>
    </text>
    <path
      d="M486.13 801.65H642.9v84.84H486.13z"
      className="Einzelklappe_Kipp_mit_3_WLW_svg__cls-2"
    />
    <path
      d="M482.26 787.14h171.61v112.58H482.26"
      className="Einzelklappe_Kipp_mit_3_WLW_svg__cls-2"
    />
    <path
      d="m486.13 801.65 156.77 39.68-156.77 45.16"
      className="Einzelklappe_Kipp_mit_3_WLW_svg__cls-9"
    />
    <path
      d="m612.26 789.39 2.42.61"
      className="Einzelklappe_Kipp_mit_3_WLW_svg__cls-6"
    />
    <path
      d="m619.63 791.25 195.47 49.17"
      style={{
        strokeDasharray: '0 0 5.1 5.1',
        strokeLinejoin: 'round',
        strokeWidth: '.75px',
        stroke: '#1e1e1c',
        fill: 'none',
      }}
    />
    <path
      d="m817.58 841.04 2.42.61-2.41.66"
      className="Einzelklappe_Kipp_mit_3_WLW_svg__cls-6"
    />
    <path
      d="m812.87 843.59-195.84 53.22"
      style={{
        strokeDasharray: '0 0 4.89 4.89',
        strokeLinejoin: 'round',
        strokeWidth: '.75px',
        stroke: '#1e1e1c',
        fill: 'none',
      }}
    />
    <path
      d="m614.67 897.45-2.41.65"
      className="Einzelklappe_Kipp_mit_3_WLW_svg__cls-6"
    />
    <path
      d="M892.58 841.65h-70.32"
      className="Einzelklappe_Kipp_mit_3_WLW_svg__cls-2"
    />
    <path
      d="M718.06 735.85v210.96M520.16 745.36c-36.77-25.9-71.38-26.1-104.03-2.9-32.66 21.03-64.91 18.54-96.77-6.61v210.97c28.55-3.79 55.32-17.1 103.08 2.17 27.89 12.67 45.47 33.31 97.73-3.95"
      className="Einzelklappe_Kipp_mit_3_WLW_svg__cls-9"
    />
    <path
      d="M669.19 745.36c25.26-22.18 50.73-24.14 76.45 0 25.04 24.25 49.82 23.49 74.35 0 25.12-21.73 50.04-24.61 74.68 0 25.7 24.31 51.18 24.72 76.45 0 23.55-23.19 48.39-23.19 74.52 0 25.53 23.99 50.55 25.04 75 0v201.45"
      style={{
        strokeWidth: '.62px',
        strokeMiterlimit: 10,
        stroke: '#1e1e1c',
        fill: 'none',
      }}
    />
    <path
      d="M669.19 945.07c25.26-22.18 50.73-24.14 76.45 0 25.04 24.25 49.82 23.49 74.35 0 25.12-21.73 50.04-24.61 74.68 0 25.7 24.31 51.18 24.72 76.45 0 23.55-23.19 48.39-23.19 74.52 0 25.53 23.99 50.55 25.04 75 0M520.16 745.36c23.73-21.58 48.4-24.8 74.52 0 25.14 21.72 50.02 24.74 74.52 0M520.16 945.03c23.73-21.58 48.4-24.8 74.52 0 25.14 21.72 50.02 24.74 74.52 0"
      className="Einzelklappe_Kipp_mit_3_WLW_svg__cls-9"
    />
    <path
      d="M655.4 841.65h2.5"
      className="Einzelklappe_Kipp_mit_3_WLW_svg__cls-6"
    />
    <path
      d="M662.97 841.65h149.5"
      style={{
        strokeDasharray: '0 0 5.07 5.07',
        strokeLinejoin: 'round',
        strokeWidth: '.75px',
        stroke: '#1e1e1c',
        fill: 'none',
      }}
    />
    <path
      d="M815 841.65h2.5"
      className="Einzelklappe_Kipp_mit_3_WLW_svg__cls-6"
    />
    <path
      d="m819.83 346.52-.18 28.53-63.87-134.84V211.5l64.05 135.02z"
      className="Einzelklappe_Kipp_mit_3_WLW_svg__cls-17"
    />
    <path
      d="m699.35 297.6 96.07-43.6 24.41 124.44-97.1 44.36-23.38-125.2z"
      style={{
        fill: '#b0b0b1',
        opacity: 0.3,
      }}
    />
    <path
      d="m723.33 420.72-23.55-125.17 96.07-43.59 23.55 124.83-96.07 43.93z"
      className="Einzelklappe_Kipp_mit_3_WLW_svg__cls-11"
    />
    <path
      d="m755.78 211.5.01 30.33-96.93 44.84v-28.71l96.92-46.46z"
      className="Einzelklappe_Kipp_mit_3_WLW_svg__cls-17"
    />
    <path
      d="m670.08 101.83 39.75 143.27"
      className="Einzelklappe_Kipp_mit_3_WLW_svg__cls-7"
    />
    <path
      d="M706.16 245.93c.56 2 2.63 3.17 4.63 2.62 2-.56 3.17-2.63 2.62-4.63a3.77 3.77 0 0 0-4.63-2.62 3.77 3.77 0 0 0-2.62 4.63Z"
      className="Einzelklappe_Kipp_mit_3_WLW_svg__cls-22"
    />
    <path
      d="m722.9 392.99-.17 28.52-63.87-134.84v-28.71l64.04 135.03z"
      className="Einzelklappe_Kipp_mit_3_WLW_svg__cls-17"
    />
    <path
      d="m664.38 284.65 5.7-182.82 92.2 137.04"
      className="Einzelklappe_Kipp_mit_3_WLW_svg__cls-7"
    />
    <path
      d="M668.15 284.58a3.751 3.751 0 0 1-3.88 3.64 3.751 3.751 0 0 1-3.64-3.88c.06-2.08 1.8-3.71 3.88-3.64 2.08.06 3.71 1.8 3.64 3.87ZM759.05 240.81a3.764 3.764 0 0 0 5.22 1.02 3.764 3.764 0 0 0 1.02-5.22 3.764 3.764 0 0 0-5.22-1.02 3.764 3.764 0 0 0-1.02 5.22Z"
      className="Einzelklappe_Kipp_mit_3_WLW_svg__cls-22"
    />
  </svg>
);
export default SvgEinzelklappeKippMit3Wlw;
