import React, { FC, ReactElement, useState } from 'react';
import { DialogFE } from '../Dialog';
import FormLayout from '../../elements/FormLayout';
import InputField from '../../elements/InputField';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  createWindow,
  updateWindowBaseData,
} from '../../redux/projectsActions';
import {
  BuildingArea,
  NEW_WINDOW,
  Project,
  ShallowWindow,
} from '../../redux/projectsReducer';
import './WindowDialog.scss';
import { Switch } from '../../elements/Switch';
import RadioBox from '../Parameters/EditBoxes/RadioBox';
import { ValueKey } from '../../redux/valueKey';
import { useDispatch, useSelector } from 'react-redux';
import { RangeOfApplication } from '../../redux/constants';
import { MenuPlacement, SelectField } from '../../elements/SelectField';
import { IState, MyCalcThunkDispatch } from '../../redux/store';
import {
  WindowTemplate,
  WindowTemplateWithOptionalId,
} from '../../redux/uiStateReducer';
import TextBanner from '../../elements/TextBanner';
import { ResultTable } from '../ResultTable';
import TabNavigation from '../../elements/TabNavigation';
import classNames from 'classnames';
import { showDialog } from '../../redux/uiStateActions';
import { AnyAction } from 'redux';

interface WindowDialogProps {
  editedOrNewWindow: Partial<ShallowWindow> | undefined;
  buildingAreaId: number;
  copy: boolean;
  originalWindowId?: number;
}

const RANGE_OF_APPLICATION_OPTIONS = [
  { name: RangeOfApplication.FACADE },
  { name: RangeOfApplication.ROOF },
];

function TemplateTabContent(props: {
  windowTemplates: WindowTemplate[];
  setSelectedTemplate: (
    value:
      | ((prevState: WindowTemplate | undefined) => WindowTemplate | undefined)
      | WindowTemplate
      | undefined,
  ) => void;
  highlightedEntry: WindowTemplate | undefined;
}): ReactElement {
  const { formatMessage } = useIntl();

  function getKeyData(windowTemplate: WindowTemplateWithOptionalId): string {
    let keyData = '';
    if (windowTemplate.calculationParameters.rangeOfApplication.value) {
      if (windowTemplate.rwa) {
        keyData += formatMessage({ id: 'RWA' }) + ', ';
      }
      if (windowTemplate.nrwg) {
        keyData += formatMessage({ id: 'NRWG' }) + ', ';
      }
      keyData += formatMessage({
        id: windowTemplate.calculationParameters.rangeOfApplication.value!.toString(),
      });
    }
    return keyData;
  }

  return (
    <div
      className={classNames('window-dialog__with-templates-table', {
        'window-dialog__with-templates-table--empty':
          props.windowTemplates.length === 0,
      })}
    >
      {props.windowTemplates.length > 0 ? (
        <>
          <ResultTable
            rowClickCallback={props.setSelectedTemplate}
            columnTitles={[
              'TEMPLATE_TABLE_HEADER_NAME',
              'TEMPLATE_TABLE_HEADER_KEY_DATA',
            ]}
            entries={props.windowTemplates}
            toRow={(windowTemplate: WindowTemplate) => [
              windowTemplate.name,
              getKeyData(windowTemplate),
            ]}
            highlightedEntry={props.highlightedEntry}
          />
          <TextBanner
            additionalClass="window-dialog__description"
            label={'vorlage_description'}
            textContent={props.highlightedEntry?.description}
          />
        </>
      ) : (
        <div className="window-dialog__template-empty-state">
          <FormattedMessage id="TEMPLATE_EMPTY_STATE" />
        </div>
      )}
    </div>
  );
}

function NewWindowTabContent(props: {
  setBuildingAreaId: (id: number) => void;
  buildingAreaId: number;
  buildingAreas: BuildingArea[];
  copy: boolean;
  nrwg: boolean;
  setNrwg: (value: ((prevState: boolean) => boolean) | boolean) => void;
  rwa: boolean;
  setRwa: (value: ((prevState: boolean) => boolean) | boolean) => void;
  // props: WindowDialogProps;
  setRangeOfApplication: (prevState: RangeOfApplication) => void;
  value: string;
}): ReactElement {
  const buildingArea = props.buildingAreas.find(
    b => b.id === props.buildingAreaId,
  )!;

  return (
    <FormLayout additionalClass="window-dialog__without-template-sub-form">
      <SelectField
        action={newValue => {
          props.setBuildingAreaId(+newValue.value);
        }}
        value={{
          value: props.buildingAreaId.toString(),
          label: buildingArea.name,
        }}
        options={props.buildingAreas.map(b => ({
          value: b.id!.toString(),
          label: b.name,
        }))}
        name="building-area"
        label={<FormattedMessage id="BUILDING_AREA_LABEL" />}
        mandatory={true}
        additionalClass="window-dialog__building-area"
        menuPlacement={MenuPlacement.BOTTOM}
      />
      {!props.copy && (
        <>
          <h2 className="window-dialog__sub-headline-desmoking">
            <FormattedMessage id="WINDOW_DIALOG_HEADING_DESMOKING_SECTION" />
          </h2>{' '}
          <div className="window-dialog__switch-nrwg">
            <Switch
              label="WINDOW_DIALOG_LABEL_NRWG"
              turnedOn={props.nrwg}
              onChange={props.setNrwg}
            />{' '}
          </div>{' '}
          <div className="window-dialog__switch-rwa">
            <Switch
              label="WINDOW_DIALOG_LABEL_RWA"
              turnedOn={props.rwa}
              onChange={props.setRwa}
            />{' '}
          </div>
          <h2 className={'window-dialog__sub-headline-roa'}>
            <FormattedMessage id="fenster_edit_winginstallationtype" />
          </h2>{' '}
          <div className="window-dialog__radio-roa">
            <RadioBox
              options={RANGE_OF_APPLICATION_OPTIONS || []}
              heading={''}
              isSingleEdit={true}
              valueKey={ValueKey.VALUEKEY_RANGE_OF_APPLICATION}
              usedOutsideOfParameterSection={true}
              onChange={s =>
                props.setRangeOfApplication(s as RangeOfApplication)
              }
              value={props.value}
            />
          </div>
        </>
      )}
    </FormLayout>
  );
}

const WindowDialog: FC<React.PropsWithChildren<WindowDialogProps>> = (
  props: WindowDialogProps,
) => {
  const [fromTemplate, setFromTemplate] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState<
    WindowTemplate | undefined
  >();
  const dispatch: MyCalcThunkDispatch<AnyAction> = useDispatch();
  const windowTemplates = useSelector<IState, WindowTemplate[]>(
    state => state.ui.templatesForCurrentUser,
  );
  const [windowName, setWindowName] = useState(props.editedOrNewWindow?.name);
  const [quantity, setQuantity] = useState(
    props.editedOrNewWindow?.quantity?.toString() || '',
  );
  const [nrwg, setNrwg] = useState(!!props.editedOrNewWindow?.nrwg);
  const [rwa, setRwa] = useState(!!props.editedOrNewWindow?.rwa);
  const [rangeOfApplication, setRangeOfApplication] = useState(
    props.editedOrNewWindow?.rangeOfApplication || RangeOfApplication.FACADE,
  );
  const { formatMessage } = useIntl();

  const [buildingAreaId, setBuildingAreaId] = useState(props.buildingAreaId);
  const selectedProject = useSelector<IState, Project>(
    p => p.ui.selectedProject!,
  );

  if (!props.editedOrNewWindow || !buildingAreaId) {
    return null;
  }

  function editMode(): boolean {
    return props.editedOrNewWindow !== NEW_WINDOW;
  }

  function resetInputFields(): void {
    setWindowName('');
    setQuantity('1');
    setNrwg(false);
    setRwa(false);
    setRangeOfApplication(RangeOfApplication.FACADE);
  }

  function saveActionDisabled(): boolean {
    return (
      !windowName ||
      !quantity ||
      !quantity.match(/^\d+$/) ||
      (fromTemplate && !selectedTemplate)
    );
  }

  function saveWindow(): void {
    if (props.copy) {
      dispatch(
        createWindow(
          windowName!,
          parseInt(quantity),
          buildingAreaId,
          nrwg,
          rwa,
          rangeOfApplication!,
          props.originalWindowId,
        ),
      );
    } else if (editMode()) {
      if (!props.editedOrNewWindow?.id) {
        return;
      }

      dispatch(
        updateWindowBaseData(
          windowName!,
          parseInt(quantity),
          buildingAreaId,
          props.editedOrNewWindow?.id,
          nrwg,
          rwa,
          rangeOfApplication!,
        ),
      );
    } else {
      dispatch(
        createWindow(
          windowName!,
          parseInt(quantity),
          buildingAreaId,
          selectedTemplate ? selectedTemplate.nrwg : nrwg,
          selectedTemplate ? selectedTemplate.rwa : rwa,
          rangeOfApplication!,
          selectedTemplate?.calculationParameters,
        ),
      );
    }

    resetInputFields();
    dispatch(showDialog(undefined));
  }

  function getHeading(): string {
    if (props.copy) {
      return 'COPY_WINDOW_DIALOG_HEADING';
    } else if (editMode()) {
      return 'EDIT_WINDOW_DIALOG_HEADING';
    } else {
      return 'NEW_WINDOW_DIALOG_HEADING';
    }
  }

  function getPrimaryActionLabel(): string {
    if (props.copy) {
      return 'COPY_LABEL';
    } else if (editMode()) {
      return 'calculations_button_save';
    } else {
      return 'CREATE_PROJECT_LABEL';
    }
  }

  function getBuildingAreas(): BuildingArea[] {
    return selectedProject.buildingAreas;
  }

  return (
    <DialogFE
      heading={getHeading()}
      componentClass="window-dialog"
      footerProps={{
        cancelAction: true,
        saveAction: saveActionDisabled() ? undefined : saveWindow,
        primaryActionLabel: getPrimaryActionLabel(),
      }}
    >
      <FormLayout additionalClass="window-dialog__layout">
        <InputField
          additionalClass="window-dialog__name"
          label={formatMessage({
            id: 'LABEL_WINDOW_NAME_INPUT',
          })}
          placeholder={formatMessage({
            id: 'PLACEHOLDER_WINDOW_NAME_INPUT',
          })}
          value={windowName || ''}
          id="windowName"
          onChange={setWindowName}
        />

        <InputField
          additionalClass="window-dialog__quantity"
          label={formatMessage({
            id: 'LABEL_WINDOW_QUANTITY_INPUT',
          })}
          placeholder={formatMessage({
            id: 'PLACEHOLDER_WINDOW_QUANTITY_INPUT',
          })}
          value={quantity || ''}
          id="quantity"
          onChange={setQuantity}
        />
      </FormLayout>
      {!props.copy && !editMode() ? (
        <TabNavigation
          tabNavItems={[
            {
              label: 'PROJECT_NAVIGATION_CREATE_WINDOW_BUTTON_LABEL',
              active: !fromTemplate,
              body: (
                <NewWindowTabContent
                  setBuildingAreaId={setBuildingAreaId}
                  buildingAreaId={buildingAreaId}
                  buildingAreas={getBuildingAreas()}
                  copy={props.copy}
                  nrwg={nrwg}
                  setNrwg={setNrwg}
                  rwa={rwa}
                  setRwa={setRwa}
                  setRangeOfApplication={setRangeOfApplication}
                  value={rangeOfApplication}
                />
              ),
              action: () => setFromTemplate(false),
            },
            {
              label: 'FROM_TEMPLATE',
              active: fromTemplate,
              body: (
                <TemplateTabContent
                  windowTemplates={windowTemplates}
                  setSelectedTemplate={setSelectedTemplate}
                  highlightedEntry={selectedTemplate}
                />
              ),
              action: () => setFromTemplate(true),
            },
          ]}
        />
      ) : (
        <div className="tab-nav-section__tab-nav-container">
          <NewWindowTabContent
            setBuildingAreaId={setBuildingAreaId}
            buildingAreaId={buildingAreaId}
            buildingAreas={getBuildingAreas()}
            copy={props.copy}
            nrwg={nrwg}
            setNrwg={setNrwg}
            rwa={rwa}
            setRwa={setRwa}
            setRangeOfApplication={setRangeOfApplication}
            value={rangeOfApplication}
          />
        </div>
      )}
    </DialogFE>
  );
};

export default WindowDialog;
