import * as d3 from 'd3';
import _ from 'lodash';
const digitWidth = 16;
const baseMargin = { top: 0, right: 0, bottom: 67, left: 90 };
let digitSizeAdjustment = 0;
let dynamicLeftMarginAdjustment = 0;
const height = 1000;
const width = 1000;
const numberOfTicks = 6;
const scaleSizeCalculationIncrements = 300;
const possibleMaxDomains = _.range(0, 40).map(
  v => v * scaleSizeCalculationIncrements,
);
const possibleNegativeDomains = _.range(0, 40).map(
  v => -v * scaleSizeCalculationIncrements,
);
export function setupChart(chart) {
  const svg = d3.select(chart);
  svg
    .attr('viewBox', [0, 0, width, height].join(' '))
    .attr('font-family', 'Roboto Condensed');
  svg.append('defs').html(`
    <clipPath id="cp"></clipPath>
    <filter x="0" y="0" width="1" height="1" id="solid">
      <feFlood flood-color="white"/>
      <feComponentTransfer>
        <feFuncA type="table" tableValues="0.8"/>
      </feComponentTransfer>
      <feComposite in="SourceGraphic" operator="over" />
    </filter>
    <filter x="0" y="0" width="1" height="1" id="whiteBackground">
      <feFlood flood-color="white"/>
      <feGaussianBlur stdDeviation="2"/>
      <feComponentTransfer>
        <feFuncA type="table" tableValues="0 0 0 1"/>
      </feComponentTransfer>

      <feComponentTransfer>
        <feFuncA type="table" tableValues="0 1 1 1 1 1 1 1"/>
      </feComponentTransfer>
         <feComposite operator="over" in="SourceGraphic"/>
    </filter>
    <filter id="Schatten" filterUnits="userSpaceOnUse" x="0" y="0">
      <feGaussianBlur
        stdDeviation='0 14'
        result='offset-blur'
      />
      <feComponentTransfer in="offset-blur" result="shadow">
          <feFuncA type="table" tableValues="0 0.4 0.4 0.4 0.4 0.4 0.4 0.4 0.4 0.4 0.4 0.4 0.4 0.4 0.4 0.4 0.4 0.4 0.4 0.4 0.4"/>
          <feFuncR type="identity"/>
          <feFuncG type="identity"/>
          <feFuncB type="identity"/>
        </feComponentTransfer>
      <feGaussianBlur
        stdDeviation='0 14'
        in="shadow"
        result='shadow2'
      />
      <!-- Put original object over shadow -->
      <feComposite
        operator='over'
        in='SourceGraphic'
        in2='shadow2'
      />
    </filter>`);
}
export function drawDiagramm(
  chart,
  calculatedForces,
  strokeForceData,
  strokeAxisDescription,
  forceAxisDescription,
  drivesMaximumPullForce,
  drivesMaximumStroke,
  numberOfDrives,
  id,
  digitAdjustment,
) {
  if (!calculatedForces || !strokeForceData) {
    return;
  }
  digitSizeAdjustment = digitAdjustment || 0;
  const svg = d3.select(chart);
  const adjustedForceData = cutOffMaxStrokeAndMultiplyBydriveForce(
    drivesMaximumStroke,
    strokeForceData,
    numberOfDrives,
  );
  const adjustedDrivesMaximumPullForce =
    drivesMaximumPullForce * numberOfDrives;
  const [minRequiredForce, maxRequiredForce] =
    getMinMaxForceRoundedUpToScaleStepBound(calculatedForces);
  const [xScale, yScale] = drawScales(
    svg,
    calculatedForces,
    adjustedForceData,
    maxRequiredForce,
    adjustedDrivesMaximumPullForce,
    minRequiredForce < 0 && maxRequiredForce === 0,
    id,
  );
  drawDriveGraphs(
    chart,
    calculatedForces,
    adjustedForceData,
    xScale,
    yScale,
    minRequiredForce < 0 || (minRequiredForce === 0 && maxRequiredForce === 0),
    maxRequiredForce > 0 || (minRequiredForce === 0 && maxRequiredForce === 0),
    adjustedDrivesMaximumPullForce,
    drivesMaximumStroke,
    id,
  );
  drawRequiredForce(
    chart,
    calculatedForces,
    xScale,
    yScale,
    minRequiredForce < 0 || (minRequiredForce === 0 && maxRequiredForce === 0),
    id,
  );
  drawAxisDescriptions(svg, forceAxisDescription, strokeAxisDescription, id);
}
function drawAxisDescriptions(
  svg,
  forceAxisDescription,
  strokeAxisDescription,
  id,
) {
  d3.select('#forceAxisDescription-' + id).remove();
  d3.select('#forceAxisDescriptionWhiteBackground-' + id).remove();
  d3.select('#strokeAxisDescription-' + id).remove();
  svg
    .append('text')
    .text(strokeAxisDescription)
    .attr('id', 'strokeAxisDescription-' + id)
    .attr('y', 910)
    .attr('x', '50%')
    .attr('text-anchor', 'middle')
    .attr('fill', 'white')
    .attr('text-anchor', 'middle')
    .attr('font-size', 45)
    .attr('font-family', 'Roboto Condensed')
    .attr('filter', 'url(#solid)');
  svg
    .append('text')
    .text(strokeAxisDescription)
    .attr('id', 'strokeAxisDescription-' + id)
    .attr('y', 910)
    .attr('x', '50%')
    .attr('text-anchor', 'middle')
    .attr('fill', '#535353')
    .attr('text-anchor', 'middle')
    .attr('font-size', 45)
    .attr('font-family', 'Roboto Condensed');
  svg
    .append('text')
    .text(forceAxisDescription)
    .attr('id', 'forceAxisDescriptionWhiteBackground-' + id)
    .attr('x', '-50% ')
    .attr('y', margin().left + 55)
    .attr('filter', 'url(#solid)')
    .attr('transform', 'rotate(270)')
    .attr('fill', 'white')
    .attr('font-size', 45)
    .attr('text-anchor', 'middle')
    .attr('font-family', 'Roboto Condensed');
  svg
    .append('text')
    .text(forceAxisDescription)
    .attr('id', 'forceAxisDescription-' + id)
    .attr('x', '-50% ')
    .attr('y', margin().left + 55)
    .attr('transform', 'rotate(270)')
    .attr('fill', '#535353')
    .attr('font-size', 45)
    .attr('text-anchor', 'middle')
    .attr('font-family', 'Roboto Condensed');
}
function getDigitWidth() {
  return digitWidth + digitSizeAdjustment;
}
function setDynamicLeftMarginAdjustment(maxLengthOfForceAxisValue) {
  dynamicLeftMarginAdjustment = getDigitWidth() * maxLengthOfForceAxisValue;
}
function drawScales(
  svg,
  calculatedForces,
  strokeForceData,
  maxRequiredForce,
  drivesMaximumPullForce,
  onlyPullForceDisplayed,
  id,
) {
  svg.select('#xAxis-' + id).remove();
  svg.select('#yAxis-' + id).remove();
  const correctedStrokeForceData = onlyPullForceDisplayed
    ? cutOffAtMaxPullForce(drivesMaximumPullForce, strokeForceData)
    : strokeForceData;
  const [strokeAxisValues, forceAxisValues] = getAllAxisValues(
    calculatedForces,
    correctedStrokeForceData,
    maxRequiredForce,
  );
  const maxLengthOfForceAxisValue = Math.max(
    ...strokeAxisValues.map(v => v.toString().length),
  );
  setDynamicLeftMarginAdjustment(maxLengthOfForceAxisValue);
  const xScale = getScale(
    strokeAxisValues,
    margin().left,
    width - margin().right,
  );
  const yScale = getScale(
    forceAxisValues,
    height - margin().top - margin().bottom,
    margin().top,
  );
  const [xAxis, yAxis] = getAxis(
    xScale,
    yScale,
    strokeAxisValues,
    forceAxisValues,
  );
  svg
    .append('g')
    .attr('id', 'xAxis-' + id)
    .call(xAxis)
    .attr('font-size', 45);
  svg
    .append('g')
    .attr('id', 'yAxis-' + id)
    .call(yAxis)
    .attr('font-size', 45);
  return [xScale, yScale];
}
export function drawRequiredForce(
  chart,
  calculatedForces,
  xScale,
  yScale,
  negativeForceRangeDisplayed,
  id,
) {
  const svg = d3.select(chart);
  svg.select('#performanceGraph-' + id).remove();
  svg.select('#performanceGraphShadow-' + id).remove();
  svg.select('#cpPath-' + id).remove();
  svg.select('#pushForceMax-' + id).remove();
  svg.select('#pullForceMax-' + id).remove();
  const drivePerformanceLine = d3.line();
  const l = drivePerformanceLine
    .x(d => xScale(d[0]) || 0)
    .y(d => yScale(d[1]) || 0);
  const requiredData = objToArray(calculatedForces);
  const endPoint = _.last(requiredData) || [0, 0];
  const upperBoundOfClipPath = [
    ...requiredData.map(v => [v[0], v[1]]),
    [endPoint[0] + 30, endPoint[1]],
  ];
  const lowerBoundOfClipPath = upperBoundOfClipPath
    .slice()
    .reverse()
    .map(v => [
      v[0],
      negativeForceRangeDisplayed ? v[1] - 1000 : Math.max(0, v[1] - 1000),
    ]);
  const clipPathData = [
    ...upperBoundOfClipPath,
    ...lowerBoundOfClipPath,
    requiredData[0],
  ];
  svg
    .append('path')
    .datum(requiredData)
    .attr('fill', 'none')
    .attr('stroke', '#57b6ff')
    .attr('stroke-width', '10px')
    .attr('stroke-linejoin', 'round')
    .attr('id', 'performanceGraph-' + id)
    .attr('d', l);
  svg
    .append('path')
    .datum(requiredData)
    .attr('fill', 'none')
    .attr('stroke', '#57b6ff')
    .attr('stroke-width', '7px')
    .attr('stroke-linejoin', 'round')
    .attr('filter', 'url(#Schatten)')
    .attr('id', 'performanceGraphShadow-' + id)
    .attr('clip-path', 'url(#cp)')
    .attr('shape-rendering', 'geometricPrecision')
    .attr('d', l);
  svg
    .select('#cp')
    .append('path')
    .datum(clipPathData)
    .attr('id', 'cpPath-' + id)
    .attr('shape-rendering', 'geometricPrecision')
    .attr('stroke-linejoin', 'round')
    .attr('d', l);
  drawPushMax(svg, calculatedForces, xScale, yScale, id);
  drawPullMax(svg, calculatedForces, xScale, yScale, id);
}
function drawPushMax(svg, calculatedForces, xScale, yScale, id) {
  const maxPositiveForce = Math.max(...Object.values(calculatedForces));
  const maxPoint = Object.entries(calculatedForces).find(
    e => e[1] === maxPositiveForce,
  ) || ['0', 0];
  if (maxPositiveForce > 0) {
    svg
      .append('circle')
      .attr('id', 'pushForceMax-' + id)
      .attr('cx', xScale(parseInt(maxPoint[0])) || 0)
      .attr('cy', yScale(maxPoint[1]) || 0)
      .attr('r', 16)
      .attr('fill', '#57b6ff');
  }
}
function drawPullMax(svg, calculatedForces, xScale, yScale, id) {
  const maxNegativeForce = Math.min(...Object.values(calculatedForces));
  const maxPoint = Object.entries(calculatedForces).find(
    e => e[1] === maxNegativeForce,
  ) || ['0', 0];
  if (maxNegativeForce < 0) {
    svg
      .append('circle')
      .attr('id', 'pullForceMax-' + id)
      .attr('cx', xScale(parseInt(maxPoint[0])) || 0)
      // @ts-ignore
      .attr('cy', yScale(maxPoint[1]) || 0)
      .attr('r', 16)
      .attr('fill', '#57b6ff');
    svg
      .append('circle')
      .attr('id', 'pullForceMaxInner-' + id)
      .attr('cx', xScale(parseInt(maxPoint[0])) || 0)
      .attr('cy', yScale(maxPoint[1]) || 0)
      .attr('r', 10)
      .attr('fill', 'white');
  }
}
function objToArray(obj) {
  return Object.entries(obj).map(v => [parseInt(v[0]), v[1]]);
}
function getAxis(xScale, yScale, strokeAxisValues, forceAxisValues) {
  const xAxis = svg => {
    svg
      .attr('transform', `translate(0,${height - margin().bottom})`)
      .call(
        d3
          .axisTop(xScale)
          .tickSize(height - margin().top - margin().bottom - 50)
          .tickPadding(10)
          .tickValues(strokeAxisValues)
          .tickFormat(d3.format('')),
      )
      .call(g => g.select('.domain').remove())
      .call(g =>
        g
          .selectAll('.tick line')
          .attr('stroke-opacity', 0.5)
          .attr('stroke-dasharray', '4,4'),
      )
      .call(g => g.selectAll('.tick:last-of-type').attr('opacity', 0))
      .call(g =>
        g
          .selectAll('.tick text')
          .attr('font-family', 'Roboto Condensed')
          .attr('color', '#535353')
          .attr('y', 65)
          .attr('dx', 0),
      );
  };
  const yAxis = svg => {
    svg
      .attr('transform', `translate(${width - margin().right},0)`)
      .call(
        d3
          .axisLeft(yScale)
          .tickSize(width - margin().right - margin().left - 50)
          .tickValues(forceAxisValues)
          .tickFormat(d3.format('')),
      )
      .call(g => g.select('.domain').remove())
      .call(g =>
        g
          .selectAll('.tick line')
          .attr('stroke-opacity', 0.5)
          .attr('transform', 'translate(-50)')
          .attr('stroke-dasharray', '4,4'),
      )
      .call(g => g.selectAll('.tick:last-of-type').attr('opacity', 0))
      .call(g =>
        g
          .selectAll('.tick text')
          .attr('x', -width + margin().left + margin().right - 32)
          .attr('font-family', 'Roboto Condensed')
          .attr('color', '#535353')
          .attr('dy', 15),
      );
  };
  return [xAxis, yAxis];
}
function drawDriveGraphs(
  chart,
  calculatedForces,
  strokeForceData,
  xScale,
  yScale,
  drawNegativePerformanceGraph,
  drawPositivePerformanceGraph,
  drivesMaximumPullForce,
  drivesMaximumStroke,
  id,
) {
  const svg = d3.select(chart);
  svg.select('#driveForceNeg-' + id).remove();
  svg.select('#driveForce-' + id).remove();
  const data = objToArray(strokeForceData);
  if (drawNegativePerformanceGraph) {
    const negativeData = [
      [0, drivesMaximumPullForce],
      [drivesMaximumStroke, drivesMaximumPullForce],
      [drivesMaximumStroke, 0],
    ];
    const lNeg = d3
      .line()
      .x(d => xScale(d[0]) || 0)
      .y(d => yScale(d[1] > 0 ? -drivesMaximumPullForce : -d[1]) || 0);
    svg
      .append('path')
      .datum(negativeData)
      .attr('fill', 'none')
      .attr('stroke', '#E10025')
      .attr('stroke-width', '10px')
      .attr('id', 'driveForceNeg-' + id)
      .attr('stroke-linejoin', 'round')
      .attr('d', lNeg);
  }
  if (drawPositivePerformanceGraph) {
    const l = d3
      .line()
      .x(d => xScale(d[0]) || 0)
      .y(d => yScale(d[1]) || 0);
    svg
      .append('path')
      .datum(data)
      .attr('fill', 'none')
      .attr('stroke', '#E10025')
      .attr('stroke-width', '10px')
      .attr('stroke-linejoin', 'round')
      .attr('id', 'driveForce-' + id)
      .attr('d', l);
  }
}
function getMaxStroke(calculatedForces, strokeForceData) {
  return getUpperBound(
    Math.max(
      ...Object.keys(calculatedForces).map(v => parseInt(v)),
      ...Object.keys(strokeForceData).map(v => parseInt(v)),
    ),
  );
}
function getScale(axisValues, screenPositionStart, screenPositionEnd) {
  return d3
    .scaleLinear()
    .domain([Math.min(...axisValues), Math.max(...axisValues)])
    .range([screenPositionStart, screenPositionEnd]);
}
function getUpperBound(max) {
  const upperBound = possibleMaxDomains.find(v => v - max >= 0);
  return upperBound !== undefined ? upperBound : 24000;
}
function getLowerBound(min) {
  const lowerBound = possibleNegativeDomains.find(v => min - v >= 0);
  return lowerBound !== undefined ? lowerBound : 0;
}
function getMinMaxForceRoundedUpToScaleStepBound(
  calculatedForces,
  driveForces,
) {
  const forces = [
    ...Object.values(calculatedForces),
    ...Object.values(driveForces || []),
  ];
  const min = Math.min(...forces);
  const max = Math.max(...forces);
  return [getLowerBound(min), getUpperBound(max)];
}
function getStepSize(domainMin, domainMax) {
  const domainSize = Math.abs(domainMin) + domainMax;
  return domainSize / numberOfTicks;
}
function getAxisValues(stepSize, domainMin) {
  return _.range(0, numberOfTicks + 2).map(v => {
    return v * stepSize + domainMin;
  });
}
function getAllAxisValues(calculatedForces, strokeForceData, maxRequiredForce) {
  const [minForce, maxForce] = getMinMaxForceRoundedUpToScaleStepBound(
    calculatedForces,
    strokeForceData,
  );
  const minForceForStepSize =
    minForce < 0 ? -Math.max(-minForce, maxForce) : minForce;
  const maxStroke = getMaxStroke(calculatedForces, strokeForceData);
  const strokeStepSize = getStepSize(0, maxStroke);
  const forceStepSize = getStepSize(
    minForceForStepSize === 0 && maxRequiredForce === 0
      ? -maxForce
      : minForceForStepSize,
    maxRequiredForce > 0 ||
      (minForceForStepSize === 0 && maxRequiredForce === 0)
      ? maxForce
      : 0,
  );
  const forceAxisValues = getAxisValues(
    forceStepSize,
    minForceForStepSize === 0 && maxRequiredForce === 0
      ? -maxForce
      : minForceForStepSize,
  );
  const strokeAxisValues = getAxisValues(strokeStepSize, 0);
  return [strokeAxisValues, forceAxisValues];
}
function cutOffAtMaxPullForce(drivesMaximumPullForce, drivesForceData) {
  return _.mapValues(drivesForceData, () => drivesMaximumPullForce);
}
function cutOffMaxStrokeAndMultiplyBydriveForce(
  drivesMaximumStroke,
  drivesForceData,
  numberOfDrives,
) {
  const cutForces = {};
  const forceDataArray = Object.entries(drivesForceData);
  for (let i = 0; i < forceDataArray.length; i++) {
    if (parseInt(forceDataArray[i][0]) > drivesMaximumStroke) {
      cutForces[forceDataArray[i][0]] = 0;
      break;
    }
    cutForces[forceDataArray[i][0]] = forceDataArray[i][1] * numberOfDrives;
  }
  return cutForces;
}
function margin() {
  return {
    ...baseMargin,
    left: baseMargin.left + digitSizeAdjustment + dynamicLeftMarginAdjustment,
  };
}
