import React, { FC } from 'react';
import Badge, { BadgeColor } from '../elements/Badge';
import { ShallowWindow } from '../redux/projectsReducer';
import { RangeOfApplication } from '../redux/constants';

interface BadgeProps {
  window: ShallowWindow;
}
export const BadgeNRWGPrimary: FC<React.PropsWithChildren<BadgeProps>> = (
  props: BadgeProps,
) => (props.window.nrwg ? <Badge name="NRWG" color={BadgeColor.nrwg} /> : null);

export const BadgeRWAPrimary: FC<React.PropsWithChildren<BadgeProps>> = (
  props: BadgeProps,
) => (props.window.rwa ? <Badge name="RWA" color={BadgeColor.rwa} /> : null);

export const BadgeFacadePrimary: FC<React.PropsWithChildren<BadgeProps>> = (
  props: BadgeProps,
) =>
  props.window.rangeOfApplication === RangeOfApplication.FACADE ? (
    <Badge name="FACADE" color={BadgeColor.default} />
  ) : null;

export const BadgeRoofPrimary: FC<React.PropsWithChildren<BadgeProps>> = (
  props: BadgeProps,
) =>
  props.window.rangeOfApplication === RangeOfApplication.ROOF ? (
    <Badge name="ROOF" color={BadgeColor.default} />
  ) : null;
