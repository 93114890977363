import * as React from 'react';
// @ts-ignore
const SvgDoppelklappePultdachOhneWlw = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="Doppelklappe_Pultdach_ohne_WLW_svg__Ebene_3"
    data-name="Ebene 3"
    viewBox="0 0 1440 1080"
    {...props}
    style={{ marginTop: '-180px', marginBottom: '-180px' }}
  >
    <defs>
      <style>
        {
          '.Doppelklappe_Pultdach_ohne_WLW_svg__cls-1{stroke-dasharray:0 0 5.03 5.03}.Doppelklappe_Pultdach_ohne_WLW_svg__cls-1,.Doppelklappe_Pultdach_ohne_WLW_svg__cls-3,.Doppelklappe_Pultdach_ohne_WLW_svg__cls-4,.Doppelklappe_Pultdach_ohne_WLW_svg__cls-8,.Doppelklappe_Pultdach_ohne_WLW_svg__cls-9{stroke:#1e1e1c;fill:none}.Doppelklappe_Pultdach_ohne_WLW_svg__cls-1,.Doppelklappe_Pultdach_ohne_WLW_svg__cls-4,.Doppelklappe_Pultdach_ohne_WLW_svg__cls-8,.Doppelklappe_Pultdach_ohne_WLW_svg__cls-9{stroke-miterlimit:10}.Doppelklappe_Pultdach_ohne_WLW_svg__cls-1,.Doppelklappe_Pultdach_ohne_WLW_svg__cls-4,.Doppelklappe_Pultdach_ohne_WLW_svg__cls-8{stroke-width:.75px}.Doppelklappe_Pultdach_ohne_WLW_svg__cls-3{stroke-linejoin:round}.Doppelklappe_Pultdach_ohne_WLW_svg__cls-10{font-size:30px}.Doppelklappe_Pultdach_ohne_WLW_svg__cls-10,.Doppelklappe_Pultdach_ohne_WLW_svg__cls-11,.Doppelklappe_Pultdach_ohne_WLW_svg__cls-12,.Doppelklappe_Pultdach_ohne_WLW_svg__cls-13{fill:#1e1e1c}.Doppelklappe_Pultdach_ohne_WLW_svg__cls-10,.Doppelklappe_Pultdach_ohne_WLW_svg__cls-11,.Doppelklappe_Pultdach_ohne_WLW_svg__cls-13{font-family:ArialMT,Arial}.Doppelklappe_Pultdach_ohne_WLW_svg__cls-16{opacity:.3;fill:#b0b0b1}.Doppelklappe_Pultdach_ohne_WLW_svg__cls-11{font-size:27.5px}.Doppelklappe_Pultdach_ohne_WLW_svg__cls-3{stroke-width:2px}.Doppelklappe_Pultdach_ohne_WLW_svg__cls-4{stroke-dasharray:0 0 5.45 5.45}.Doppelklappe_Pultdach_ohne_WLW_svg__cls-13{font-size:27.5px}'
        }
      </style>
    </defs>
    <path
      d="m496.18 479.74-71.35 74.45 356.9 176.94-285.55-251.39z"
      style={{
        fill: '#ececec',
      }}
    />
    <path
      d="M782.75 730.89 497.2 479.5l232.41-115.47 285.56 251.39-106.67 52.99-80.63-76.53 43.14-20.81-120.37-105.96-99.42 49.4 119.3 105.02 57.35-27.65 80.63 76.53-125.75 62.48z"
      style={{
        fill: '#b0b0b1',
      }}
    />
    <path
      d="m751.1 465.76-100.49 48.46 15.82-45.74 101.46-50.37-16.79 47.65zM870.93 571.07l-100.48 48.46 15.82-45.74 101.46-50.37-16.8 47.65z"
      className="Doppelklappe_Pultdach_ohne_WLW_svg__cls-16"
    />
    <path
      d="m809.32 518.1-98.46 48.92-6.51-6.67 98.13-48.75 6.84 6.5z"
      style={{
        strokeLinejoin: 'round',
        fill: '#575656',
        stroke: '#1e1e1c',
      }}
    />
    <path
      d="m497.11 479.74-71.34 74.45 356.9 176.94-285.56-251.39z"
      className="Doppelklappe_Pultdach_ohne_WLW_svg__cls-3"
    />
    <path
      d="m497.2 479.5 232.41-115.47 285.56 251.39-232.42 115.47"
      style={{
        strokeWidth: 2,
        strokeMiterlimit: 10,
        fill: 'none',
        stroke: '#1e1e1c',
      }}
    />
    <path
      d="m872.08 570.37-100.49 48.46 15.83-45.74 101.45-50.38-16.79 47.66zM751.1 465.76l-100.49 48.46 15.82-45.74 101.46-50.37-16.79 47.65z"
      className="Doppelklappe_Pultdach_ohne_WLW_svg__cls-3"
    />
    <path
      d="m770.52 619.53 100.49-48.46-120.37-105.96-99.42 49.4 119.3 105.02z"
      style={{
        strokeWidth: '2.5px',
        strokeMiterlimit: 10,
        fill: 'none',
        stroke: '#1e1e1c',
      }}
    />
    <text
      className="Doppelklappe_Pultdach_ohne_WLW_svg__cls-11"
      transform="rotate(41.8 -417.666 1132.317)"
    >
      <tspan x={0} y={0}>
        {props.heightH}
      </tspan>
    </text>
    <text
      className="Doppelklappe_Pultdach_ohne_WLW_svg__cls-10"
      transform="rotate(41.8 -440.682 1153.333) scale(.58)"
    >
      <tspan x={0} y={0}>
        {props.doubleFlapFRZK}
      </tspan>
    </text>
    <path
      d="m641.55 529.45 105.58 92.76"
      className="Doppelklappe_Pultdach_ohne_WLW_svg__cls-9"
    />
    <path
      d="m641.12 532.94-6.22-9.33 10.06 4.97-3.84 4.36zM743.72 623.08l10.06 4.98-6.22-9.34-3.84 4.36z"
      className="Doppelklappe_Pultdach_ohne_WLW_svg__cls-12"
    />
    <path
      d="m621.4 529.5 2.24-1.11"
      className="Doppelklappe_Pultdach_ohne_WLW_svg__cls-8"
    />
    <path
      d="m628.14 526.15 15.77-7.82"
      className="Doppelklappe_Pultdach_ohne_WLW_svg__cls-1"
    />
    <path
      d="m646.16 517.22 2.24-1.11"
      className="Doppelklappe_Pultdach_ohne_WLW_svg__cls-8"
    />
    <text
      className="Doppelklappe_Pultdach_ohne_WLW_svg__cls-13"
      transform="rotate(-26.95 1747.712 -1417.022)"
    >
      <tspan x={0} y={0}>
        {props.widthB}
      </tspan>
    </text>
    <text
      style={{
        fontSize: 30,
        fontFamily: 'ArialMT,Arial',
        fill: '#1e1e1c',
      }}
      transform="rotate(-26.95 1759.383 -1460.297) scale(.58)"
    >
      <tspan x={0} y={0}>
        {props.frameFR}
      </tspan>
    </text>
    <path
      d="m794.13 630.92 83.8-41.63"
      className="Doppelklappe_Pultdach_ohne_WLW_svg__cls-9"
    />
    <path
      d="m797.2 632.64-11 2.22 8.41-7.42 2.59 5.2zM877.45 592.77l8.42-7.42-11.01 2.22 2.59 5.2z"
      className="Doppelklappe_Pultdach_ohne_WLW_svg__cls-12"
    />
    <path
      d="m871.99 573.19 1.88 1.65"
      className="Doppelklappe_Pultdach_ohne_WLW_svg__cls-8"
    />
    <path
      d="m877.97 578.43 14.36 12.58"
      className="Doppelklappe_Pultdach_ohne_WLW_svg__cls-4"
    />
    <path
      d="m894.38 592.81 1.88 1.64M772.38 622.75l1.88 1.65"
      className="Doppelklappe_Pultdach_ohne_WLW_svg__cls-8"
    />
    <path
      d="m778.36 627.99 14.36 12.58"
      className="Doppelklappe_Pultdach_ohne_WLW_svg__cls-4"
    />
    <path
      d="m794.77 642.37 1.88 1.65M740.28 634.75l2.24-1.11"
      className="Doppelklappe_Pultdach_ohne_WLW_svg__cls-8"
    />
    <path
      d="m747.02 631.41 15.77-7.82"
      className="Doppelklappe_Pultdach_ohne_WLW_svg__cls-1"
    />
    <path
      d="m765.04 622.47 2.24-1.11"
      className="Doppelklappe_Pultdach_ohne_WLW_svg__cls-8"
    />
    <text
      style={{
        fontSize: '27.5px',
        fontFamily: 'ArialMT,Arial',
        fill: '#1e1e1c',
      }}
      transform="translate(640.45 652.45)"
    >
      <tspan x={0} y={0}>
        {'\u03B1'}
      </tspan>
    </text>
    <path
      d="M624.59 642c5.8-7.19 12.35-13.75 19.52-19.57"
      className="Doppelklappe_Pultdach_ohne_WLW_svg__cls-9"
    />
    <path
      d="m628.14 642.22-8.88 6.86 4.26-10.38 4.62 3.52zM644.33 625.99l6.84-8.9-10.37 4.29 3.53 4.61z"
      className="Doppelklappe_Pultdach_ohne_WLW_svg__cls-12"
    />
    <text
      className="Doppelklappe_Pultdach_ohne_WLW_svg__cls-11"
      transform="rotate(41.8 -322.629 1164.95)"
    >
      <tspan x={0} y={0}>
        {props.heightH}
      </tspan>
    </text>
    <text
      className="Doppelklappe_Pultdach_ohne_WLW_svg__cls-10"
      transform="rotate(41.8 -345.631 1185.96) scale(.58)"
    >
      <tspan x={0} y={0}>
        {props.frameFR}
      </tspan>
    </text>
    <text
      className="Doppelklappe_Pultdach_ohne_WLW_svg__cls-13"
      transform="rotate(-26.95 1500.1 -1610.485)"
    >
      <tspan x={0} y={0}>
        {props.sashFrameDistanceFRA}
      </tspan>
    </text>
    <path
      d="m712.35 493.16 38.69 33.99"
      className="Doppelklappe_Pultdach_ohne_WLW_svg__cls-9"
    />
    <path
      d="m711.92 496.65-6.23-9.34 10.07 4.97-3.84 4.37zM747.63 528.03l10.06 4.97-6.22-9.34-3.84 4.37z"
      className="Doppelklappe_Pultdach_ohne_WLW_svg__cls-12"
    />
    <path
      d="m760.2 501.43 16.43 14.44"
      className="Doppelklappe_Pultdach_ohne_WLW_svg__cls-9"
    />
    <path
      d="m773.23 516.75 10.06 4.97-6.23-9.34-3.83 4.37z"
      className="Doppelklappe_Pultdach_ohne_WLW_svg__cls-12"
    />
    <path
      d="m946.02 481.4-133.27 66.21-15.64-13.75"
      style={{
        strokeLinejoin: 'round',
        fill: 'none',
        stroke: '#1e1e1c',
      }}
    />
    <path
      d="m800.51 532.99-10.06-4.98 6.23 9.34 3.83-4.36z"
      className="Doppelklappe_Pultdach_ohne_WLW_svg__cls-12"
    />
  </svg>
);
export default SvgDoppelklappePultdachOhneWlw;
