import React, { MouseEventHandler, ReactElement, ReactNode } from 'react';
import classNames from 'classnames';
import { NavLink } from 'react-router-dom';

export default function AdminNavLink(props: {
  to: string;
  children: ReactNode;
  onClick?: MouseEventHandler<HTMLAnchorElement>;
  additionalClassName?: string;
}): ReactElement {
  function navLinkClass(active: {
    isActive: boolean;
    isPending: boolean;
  }): string {
    return classNames(
      'admin__tab-nav-item',
      {
        'admin__tab-nav-item--active': active.isActive,
      },
      props.additionalClassName,
    );
  }

  return (
    <NavLink to={props.to} className={navLinkClass} onClick={props.onClick}>
      {props.children}
    </NavLink>
  );
}
