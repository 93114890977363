import React, { FC, ReactElement, useEffect, useState } from 'react';
import FormLayout from '../../elements/FormLayout';
import InputField, { InputFieldNumber } from '../../elements/InputField';
import TextArea from '../../elements/TextArea';
import CheckBox from '../../elements/CheckBox';
import { Switch } from '../../elements/Switch';
import './FacadeFrameProfileDataView.scss';
import { SelectField } from '../../elements/SelectField';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Route, Routes } from 'react-router-dom';
import Dialog from '../../components/Dialog';
import Table from '../components/Table';
import TableHeader from '../elements/TableHeader';
import TableRow from '../elements/TableRow';
import { AdminState, AdminThunkDispatch } from '../../redux/admin/adminStore';
import {
  FrameProfileFacade,
  Series,
} from '../../redux/admin/adminFacadeReducer';
import {
  changeFrameProfileFacade,
  createFrameProfileFacade,
} from '../../redux/admin/adminFacadeActions';
import {
  DialogSubCategoryProps,
  ProfileDataDialogBasicProps,
} from '../constants';
import AdminRadioButton from '../../elements/RadioButton';
import SearchField from '../../elements/SearchField';
import Pagination from '../components/Pagination';
import {
  fieldsFilled,
  getSeriesForProfileId,
  seriesIsForInwardsOrBoth,
  seriesIsForOutwardsOrBoth,
  seriesIsOnlyInwards,
  seriesIsOnlyOutwards,
} from '../general/helpers';
import AdminListIcon from '../elements/AdminListIcon';
import classNames from 'classnames';
import { useValidateProfileArticleNumber } from './FacadeSashProfileDataView';
import AdminNavLink from '../common/AdminNavLink';
import { AnyAction } from 'redux';
import { useAdminSearch, useNumberState } from '../hooks';
import { downloadListAsCSV } from '../common/download';
import FormLayoutSubgroupTitle from '../../elements/FormLayoutSubgroupTitle';

function FrameProfileDataDialogTabNav(): ReactElement {
  return (
    <div className="admin__tab-nav frame-profile-data-dialog-tab-nav">
      <AdminNavLink to="/admin/facade/profiles/frame-profiles/basic">
        <Routes>
          <Route
            path="/"
            element={
              <Navigate
                replace
                to="/admin/facade/profiles/frame-profiles/basic"
              />
            }
          />
        </Routes>
        Allgemein
      </AdminNavLink>
      <AdminNavLink to="/admin/facade/profiles/frame-profiles/basic-information">
        Eckdaten
      </AdminNavLink>
      <AdminNavLink to="/admin/facade/profiles/frame-profiles/properties">
        Eigenschaften
      </AdminNavLink>{' '}
    </div>
  );
}

const FrameProfileDataDialogBasic: FC<
  React.PropsWithChildren<ProfileDataDialogBasicProps>
> = (props: ProfileDataDialogBasicProps) => {
  const articleNumberError = useValidateProfileArticleNumber(
    s => s.adminFacade.frameProfiles,
    props.series,
    props.artNr,
    props.id,
  );

  return (
    <div className="">
      <FormLayout additionalClass="frame-profile-data-dialog__layout-basic">
        <InputField
          label="Artikelnummer*"
          placeholder="Artikelnummer des Blendrahmen"
          value={props.artNr}
          id="1"
          additionalClass="frame-profile-data-dialog__article-nr"
          onChange={props.setArtNr}
          errorMessage={articleNumberError}
        />
        <TextArea
          label="Beschreibung"
          additionalClass="frame-profile-data-dialog__info"
          placeholder="Beschreibung zum Blendrahmen"
          value={props.beschreibung}
          onChange={props.setBeschreibung}
        />
        <SelectField
          additionalClass="frame-profile-data-dialog__serie"
          key={props.series?.id}
          label="Serie *"
          value={
            props.series
              ? {
                  value: props.series,
                  label: props.series!.name,
                }
              : undefined
          }
          action={newValue => {
            props.setSeries(newValue.value);
          }}
          options={props.allSeries.map(series => ({
            label: series.name,
            value: series,
          }))}
          name="seriesOfFrameProfile"
          searchable={true}
          placeholder="Serie auswählen"
        />
        <div className="frame-profile-data-dialog__switch">
          <Switch
            labelText={
              props.series?.active
                ? 'aktiv'
                : 'Aktivierung nicht möglich. Serie ' +
                  props.series?.name +
                  ' muss aktiv sein.'
            }
            turnedOn={props.isActive}
            onChange={props.setIsActive}
          />
        </div>
        <div className="frame-profile-data-dialog__preview">
          <Switch
            labelText="Vorschau"
            turnedOn={props.isPreview}
            onChange={props.setIsPreview}
          />
        </div>
      </FormLayout>
    </div>
  );
};

interface FrameProfileDataDialogBasicInformationProps
  extends DialogSubCategoryProps {
  kammermassInnen: number | null | undefined;
  setKammermassInnen: (i: number | null) => void;
  kammermassAussen: number | null | undefined;
  setKammermassAussen: (i: number | null) => void;
  bautiefe: number | null | undefined;
  setBautiefe: (i: number | null) => void;
  maxFluegelgewicht: number | null | undefined;
  setMaxFluegelgewicht: (i: number | null) => void;
  maxFluegelaufschlag: number | null | undefined;
  setMaxFluegelaufschlag: (i: number | null) => void;
}

const FrameProfileDataDialogBasicInformation: FC<
  React.PropsWithChildren<FrameProfileDataDialogBasicInformationProps>
> = (props: FrameProfileDataDialogBasicInformationProps) => {
  return (
    <div>
      <FormLayout additionalClass="frame-profile-data-dialog__layout-basic-information">
        <InputFieldNumber
          label="Kammermaß innen (mm)*"
          placeholder="z.B. 42"
          value={props.kammermassInnen ? props.kammermassInnen : ''}
          id="1"
          additionalClass="frame-profile-data-dialog__measurement-inside"
          onChange={props.setKammermassInnen}
        />
        <InputFieldNumber
          label="Kammermaß außen (mm)*"
          placeholder="z.B. 42"
          value={props.kammermassAussen ? props.kammermassAussen : ''}
          additionalClass="frame-profile-data-dialog__measurement-outside"
          onChange={props.setKammermassAussen}
        />
        <InputFieldNumber
          label="Bautiefe (mm)"
          placeholder="z.B. 42"
          value={props.bautiefe ? props.bautiefe : ''}
          id="3"
          additionalClass="frame-profile-data-dialog__building-depth"
          onChange={props.setBautiefe}
        />
        <InputFieldNumber
          label="Maximales Flügelgewicht (kg)*"
          placeholder="z.B. 42"
          value={props.maxFluegelgewicht ? props.maxFluegelgewicht : ''}
          id="4"
          additionalClass="frame-profile-data-dialog__max-sash-weight"
          onChange={props.setMaxFluegelgewicht}
        />
        <InputFieldNumber
          label="Maximaler Flügelaufschlag (mm)*"
          placeholder="z.B. 42"
          value={props.maxFluegelaufschlag ? props.maxFluegelaufschlag : ''}
          id="5"
          additionalClass="frame-profile-data-dialog__max-sash-addition"
          onChange={props.setMaxFluegelaufschlag}
        />
      </FormLayout>
    </div>
  );
};

interface FrameProfileDataDialogPropertiesProps extends DialogSubCategoryProps {
  tiptronic: boolean;
  setTipTronic: (b: boolean) => void;
  forIntegrierteAntriebe: boolean;
  setForIntegrierteAntriebe: (b: boolean) => void;
  kammermassIntegriert: number | null | undefined;
  setKammermassIntegriert: (n: number | null) => void;
  forGrundprofil: boolean;
  setForGrundprofil: (b: boolean) => void;
  forWechselprofil: boolean;
  setForWechselprofil: (b: boolean) => void;
  isEinwaerts: boolean;
  setIsEinwaerts: (b: boolean) => void;
  isAuswaerts: boolean;
  setIsAuswaerts: (b: boolean) => void;
  left: boolean;
  setLeft: (b: boolean) => void;
  right: boolean;
  setRight: (b: boolean) => void;
  top: boolean;
  setTop: (b: boolean) => void;
  bottom: boolean;
  setBottom: (b: boolean) => void;
  kipp: boolean;
  setKipp: (b: boolean) => void;
  klapp: boolean;
  setKlapp: (b: boolean) => void;
  dreh: boolean;
  setDreh: (b: boolean) => void;
  senkKlapp: boolean;
  setSenkKlapp: (b: boolean) => void;
  isPfostenRiegel: boolean;
  setIsPfostenRiegel: (b: boolean) => void;
  isLochfenster: boolean;
  setIsLochfenster: (b: boolean) => void;
  isStandardFenster: boolean;
  setIsStandardFenster: (b: boolean) => void;
  isBlockFenster: boolean;
  setIsBlockFenster: (b: boolean) => void;
  isFlaechenbuendig: boolean;
  setIsFlaechenbuendig: (b: boolean) => void;
  serie: Series | undefined;
}

const FrameProfileDataDialogProperties: FC<
  React.PropsWithChildren<FrameProfileDataDialogPropertiesProps>
> = (props: FrameProfileDataDialogPropertiesProps) => {
  useEffect(() => {
    if (seriesIsOnlyOutwards(props.serie)) {
      props.setIsAuswaerts(true);
      props.setIsEinwaerts(false);
    }
    if (seriesIsOnlyInwards(props.serie)) {
      props.setIsEinwaerts(true);
      props.setIsAuswaerts(false);
    }
  }, [props.isAuswaerts, props.isEinwaerts]);

  function setProfileTypeToStandardFenster(): void {
    props.setIsStandardFenster(true);
    props.setIsFlaechenbuendig(false);
    props.setIsBlockFenster(false);
  }

  function setProfileTypeToBlockfenster(): void {
    props.setIsBlockFenster(true);
    props.setIsFlaechenbuendig(false);
    props.setIsStandardFenster(false);
  }

  function setProfileTypeToFlaechenbuendig(): void {
    props.setIsFlaechenbuendig(true);
    props.setIsStandardFenster(false);
    props.setIsBlockFenster(false);
  }

  return (
    <div>
      <FormLayout additionalClass="frame-profile-data-dialog__layout-properties">
        <div className="frame-profile-data-dialog__tip-tronic">
          <Switch
            labelText="Tip Tronic"
            turnedOn={props.tiptronic}
            onChange={props.setTipTronic}
          />
        </div>
        <div className="frame-profile-data-dialog__integrated">
          <Switch
            labelText="Für integrierte Antriebe"
            turnedOn={props.forIntegrierteAntriebe}
            onChange={props.setForIntegrierteAntriebe}
          />
        </div>
        {props.forIntegrierteAntriebe && (
          <InputFieldNumber
            label="Kammermaß für integrierte Montage (mm)"
            placeholder="z.B. 42"
            value={
              props.kammermassIntegriert
                ? props.kammermassIntegriert
                : undefined
            }
            additionalClass="frame-profile-data-dialog__integrated-measurement"
            onChange={props.setKammermassIntegriert}
          />
        )}

        <div className="frame-profile-data-dialog__basic-profile">
          <Switch
            labelText="Für Grundprofil geeignet"
            turnedOn={props.forGrundprofil}
            onChange={props.setForGrundprofil}
          />
        </div>
        <div className="frame-profile-data-dialog__exchange-profile">
          <Switch
            labelText="Für Wechselprofil geeignet"
            turnedOn={props.forWechselprofil}
            onChange={props.setForWechselprofil}
          />
        </div>
        <div
          className={classNames(
            'form-layout__sub-group form-layout__sub-group--2 frame-profile-data-dialog__opening-direction',
            {
              'frame-profile-data-dialog__opening-direction--disabled':
                seriesIsOnlyInwards(props.serie) ||
                seriesIsOnlyOutwards(props.serie),
            },
          )}
        >
          <FormLayoutSubgroupTitle>Öffnungsrichtung*</FormLayoutSubgroupTitle>

          {props.serie?.oeffnungsrichtungEinwaerts && (
            <CheckBox
              id="Einwärts"
              labelText="Einwärts"
              checked={
                seriesIsForInwardsOrBoth(props.serie) ? props.isEinwaerts : true
              }
              onClick={() => props.setIsEinwaerts(!props.isEinwaerts)}
            />
          )}
          {props.serie?.oeffnungsrichtungAuswaerts && (
            <CheckBox
              id="Auswärts"
              labelText="Auswärts"
              checked={
                seriesIsForOutwardsOrBoth(props.serie)
                  ? props.isAuswaerts
                  : true
              }
              onClick={() => props.setIsAuswaerts(!props.isAuswaerts)}
            />
          )}
        </div>
        <div className="form-layout__sub-group form-layout__sub-group--4 frame-profile-data-dialog__mountage">
          <FormLayoutSubgroupTitle>montierbar *</FormLayoutSubgroupTitle>
          <CheckBox
            id="Links"
            labelText="Links"
            checked={props.left}
            onClick={() => props.setLeft(!props.left)}
          />
          <CheckBox
            id="Rechts"
            labelText="Rechts"
            checked={props.right}
            onClick={() => props.setRight(!props.right)}
          />
          <CheckBox
            id="Oben"
            labelText="Oben"
            checked={props.top}
            onClick={() => props.setTop(!props.top)}
          />
          <CheckBox
            id="Unten"
            labelText="Unten"
            checked={props.bottom}
            onClick={() => props.setBottom(!props.bottom)}
          />
        </div>
        <div className="form-layout__sub-group frame-profile-data-dialog__application">
          <FormLayoutSubgroupTitle>Anwendung *</FormLayoutSubgroupTitle>
          <CheckBox
            id="Kipp"
            labelText="Kipp"
            checked={props.kipp}
            onClick={() => props.setKipp(!props.kipp)}
          />
          <CheckBox
            id="Klapp"
            labelText="Klapp"
            checked={props.klapp}
            onClick={() => props.setKlapp(!props.klapp)}
          />
          <CheckBox
            id="Dreh"
            labelText="Dreh"
            checked={props.dreh}
            onClick={() => props.setDreh(!props.dreh)}
          />
          <CheckBox
            id="Senkklapp"
            labelText="Senkklapp"
            checked={props.senkKlapp}
            onClick={() => props.setSenkKlapp(!props.senkKlapp)}
          />
        </div>

        <div className="frame-profile-data-dialog__profile-type">
          <FormLayoutSubgroupTitle>Profiltyp *</FormLayoutSubgroupTitle>
          <AdminRadioButton
            checked={props.isStandardFenster}
            name="Standardfenster (innen flächenversetzt)"
            onChange={setProfileTypeToStandardFenster}
          />
          <AdminRadioButton
            checked={props.isBlockFenster}
            name="Blockfenster (innen flächenversetzt)"
            onChange={setProfileTypeToBlockfenster}
          />
          <AdminRadioButton
            checked={props.isFlaechenbuendig}
            name="Flächenbündig (innen u. außen)"
            onChange={setProfileTypeToFlaechenbuendig}
          />
        </div>

        <div className="form-layout__sub-group form-layout__sub-group--2 frame-profile-data-dialog__installation">
          <FormLayoutSubgroupTitle>Einbauart *</FormLayoutSubgroupTitle>
          <CheckBox
            id="a"
            label="Pfosten-Riegel"
            checked={props.isPfostenRiegel}
            onClick={() => props.setIsPfostenRiegel(!props.isPfostenRiegel)}
          />
          <CheckBox
            id="a"
            label="Lochfenster"
            checked={props.isLochfenster}
            onClick={() => props.setIsLochfenster(!props.isLochfenster)}
          />
        </div>
      </FormLayout>
    </div>
  );
};

interface AdminFrameProfileDialogProps {
  selectedFrameProfile: FrameProfileFacade | undefined;
  allSeries: Series[];
  dialogIsShown: boolean;
  setDialogIsShown: (b: boolean) => void;
  activeSwitchEnabled: boolean;
}

function AdminFrameProfileDialog(
  props: AdminFrameProfileDialogProps,
): ReactElement {
  const [isActive, setIsActive] = useState(
    props.selectedFrameProfile?.active || false,
  );
  const [isPreview, setIsPreview] = useState(
    props.selectedFrameProfile?.preview ||
      (props.selectedFrameProfile?.preview === false ? false : true),
  );
  const [artNr, setArtNr] = useState(props.selectedFrameProfile?.artNr || '');
  const [beschreibung, setBeschreibung] = useState(
    props.selectedFrameProfile?.beschreibung || '',
  );

  const [serie, setSerie] = useState(
    getSeriesForProfileId(
      props.selectedFrameProfile?.seriesId,
      props.allSeries,
    ),
  );

  if (props.selectedFrameProfile && !serie) {
    setSerie(
      getSeriesForProfileId(
        props.selectedFrameProfile?.seriesId,
        props.allSeries,
      ),
    );
  }

  const [kammermassInnen, setKammermassInnen] = useNumberState(
    props.selectedFrameProfile?.kammermassInnen,
  );
  const [kammermassAussen, setKammermassAussen] = useNumberState(
    props.selectedFrameProfile?.kammermassAussen,
  );
  const [bautiefe, setBautiefe] = useNumberState(
    props.selectedFrameProfile?.bautiefe,
  );
  const [maxFluegelgewicht, setMaxFluegelgewicht] = useNumberState(
    props.selectedFrameProfile?.maxFluegelgewicht,
  );
  const [maxFluegelaufschlag, setMaxFluegelaufschlag] = useNumberState(
    props.selectedFrameProfile?.maxFluegelaufschlag,
  );

  //for FrameProfileDataDialogProperties
  const [tiptronic, setTipTronic] = useState(
    props.selectedFrameProfile?.tipTronic || false,
  );
  const [forIntegrierteAntriebe, setForIntegrierteAntriebe] = useState(
    props.selectedFrameProfile?.fuerIntegrierteAntriebe || false,
  );
  const [kammermassIntegriert, setKammermassIntegriert] = useNumberState(
    props.selectedFrameProfile?.kammermassFuerIntegrierteMontage,
  );
  const [forGrundprofil, setForGrundprofil] = useState(
    props.selectedFrameProfile?.fuerGrundprofilGeeignet || false,
  );
  const [forWechselprofil, setForWechselprofil] = useState(
    props.selectedFrameProfile?.fuerWechselprofilGeeignet || false,
  );
  const [isEinwaerts, setIsEinwaerts] = useState(
    (seriesIsOnlyInwards(serie)
      ? true
      : props.selectedFrameProfile?.oeffnungsrichtungEinwaerts) || false,
  );
  const [isAuswaerts, setIsAuswaerts] = useState(
    (seriesIsOnlyOutwards(serie)
      ? true
      : props.selectedFrameProfile?.oeffnungsrichtungAuswaerts) || false,
  );
  const [left, setLeft] = useState(
    props.selectedFrameProfile?.montierbarLinks || false,
  );
  const [right, setRight] = useState(
    props.selectedFrameProfile?.montierbarRechts || false,
  );
  const [top, setTop] = useState(
    props.selectedFrameProfile?.montierbarOben || false,
  );
  const [bottom, setBottom] = useState(
    props.selectedFrameProfile?.montierbarUnten || false,
  );
  const [kipp, setKipp] = useState(
    props.selectedFrameProfile?.anwendungKipp || false,
  );
  const [klapp, setKlapp] = useState(
    props.selectedFrameProfile?.anwendungKlapp || false,
  );
  const [dreh, setDreh] = useState(
    props.selectedFrameProfile?.anwendungDreh || false,
  );
  const [senkKlapp, setSenkKlapp] = useState(
    props.selectedFrameProfile?.anwendungSenkklapp || false,
  );
  const [isPfostenRiegel, setIsPfostenRiegel] = useState(
    props.selectedFrameProfile?.profiltypEinsatzprofilPfostenRiegelKonstr ||
      false,
  );
  const [isLochfenster, setIsLochfenster] = useState(
    props.selectedFrameProfile?.profiltypLochfenster || false,
  );
  const [isStandardFenster, setIsStandardFenster] = useState(
    props.selectedFrameProfile?.standardfenster || false,
  );
  const [isBlockFenster, setIsBlockFenster] = useState(
    props.selectedFrameProfile?.blockfenster || false,
  );
  const [isFlaechenbuendig, setIsFlaechenbuendig] = useState(
    props.selectedFrameProfile?.flaechenbuendig || false,
  );
  const dispatch: AdminThunkDispatch<AnyAction> = useDispatch();
  const articleNumberError = useValidateProfileArticleNumber(
    s => s.adminFacade.frameProfiles,
    serie,
    artNr,
    props.selectedFrameProfile?.id,
  );

  useEffect(() => {
    resetInputFields();
  }, [props.selectedFrameProfile]);

  function resetInputFields(): void {
    //for FrameProfileDataDialogBasic
    setIsActive(props.selectedFrameProfile?.active || false);
    setIsPreview(
      props.selectedFrameProfile?.preview ||
        (props.selectedFrameProfile?.preview === false ? false : true),
    );
    setArtNr(props.selectedFrameProfile?.artNr || '');
    setBeschreibung(props.selectedFrameProfile?.beschreibung || '');
    setSerie(
      getSeriesForProfileId(
        props.selectedFrameProfile?.seriesId,
        props.allSeries,
      )! || undefined,
    );
    //for FrameProfileDataDialogBasicInformation
    setKammermassInnen(props.selectedFrameProfile?.kammermassInnen);
    setKammermassAussen(props.selectedFrameProfile?.kammermassAussen);
    setBautiefe(props.selectedFrameProfile?.bautiefe);
    setMaxFluegelgewicht(props.selectedFrameProfile?.maxFluegelgewicht);
    setMaxFluegelaufschlag(props.selectedFrameProfile?.maxFluegelaufschlag);

    //for FrameProfileDataDialogProperties
    setTipTronic(props.selectedFrameProfile?.tipTronic || false);
    setForIntegrierteAntriebe(
      props.selectedFrameProfile?.fuerIntegrierteAntriebe || false,
    );
    setKammermassIntegriert(
      props.selectedFrameProfile?.kammermassFuerIntegrierteMontage,
    );
    setForGrundprofil(
      props.selectedFrameProfile?.fuerGrundprofilGeeignet || false,
    );
    setForWechselprofil(
      props.selectedFrameProfile?.fuerWechselprofilGeeignet || false,
    );
    setIsEinwaerts(
      (seriesIsOnlyInwards(serie)
        ? true
        : props.selectedFrameProfile?.oeffnungsrichtungEinwaerts) || false,
    );
    setIsAuswaerts(
      (seriesIsOnlyOutwards(serie)
        ? true
        : props.selectedFrameProfile?.oeffnungsrichtungAuswaerts) || false,
    );
    setLeft(props.selectedFrameProfile?.montierbarLinks || false);
    setRight(props.selectedFrameProfile?.montierbarRechts || false);
    setTop(props.selectedFrameProfile?.montierbarOben || false);
    setBottom(props.selectedFrameProfile?.montierbarUnten || false);
    setKipp(props.selectedFrameProfile?.anwendungKipp || false);
    setKlapp(props.selectedFrameProfile?.anwendungKlapp || false);
    setDreh(props.selectedFrameProfile?.anwendungDreh || false);
    setSenkKlapp(props.selectedFrameProfile?.anwendungSenkklapp || false);
    setIsPfostenRiegel(
      props.selectedFrameProfile?.profiltypEinsatzprofilPfostenRiegelKonstr ||
        false,
    );
    setIsLochfenster(props.selectedFrameProfile?.profiltypLochfenster || false);
    setIsStandardFenster(props.selectedFrameProfile?.standardfenster || false);
    setIsBlockFenster(props.selectedFrameProfile?.blockfenster || false);
    setIsFlaechenbuendig(props.selectedFrameProfile?.flaechenbuendig || false);
  }

  function mandatoryFieldsFilled(): boolean {
    return (
      fieldsFilled(
        isActive,
        artNr,
        serie,
        kammermassInnen,
        kammermassAussen,
        maxFluegelgewicht,
        maxFluegelaufschlag,
      ) &&
      (isEinwaerts || isAuswaerts) &&
      (kipp || klapp || dreh || senkKlapp) &&
      (isStandardFenster || isBlockFenster || isFlaechenbuendig) &&
      (left || right || top || bottom) &&
      (isLochfenster || isPfostenRiegel) &&
      !articleNumberError
    );
  }

  function editFrameProfile(): void {
    dispatch(
      changeFrameProfileFacade({
        ...props.selectedFrameProfile!,
        seriesId: serie!.id,
        series: serie!,
        kammermassAussen: kammermassAussen!,
        maxFluegelaufschlag: maxFluegelaufschlag!,
        kammermassFuerIntegrierteMontage: forIntegrierteAntriebe
          ? kammermassIntegriert!
          : undefined,
        artNr: artNr,
        beschreibung: beschreibung,
        kammermassInnen: kammermassInnen!,
        fuerIntegrierteAntriebe: forIntegrierteAntriebe,
        maxFluegelgewicht: maxFluegelgewicht!,
        active: isActive,
        preview: isPreview,
        fuerGrundprofilGeeignet: forGrundprofil,
        fuerWechselprofilGeeignet: forWechselprofil,
        tipTronic: tiptronic,
        bautiefe: bautiefe!,
        montierbarLinks: left,
        montierbarRechts: right,
        montierbarOben: top,
        montierbarUnten: bottom,
        anwendungKipp: kipp,
        anwendungKlapp: klapp,
        anwendungDreh: dreh,
        anwendungSenkklapp: senkKlapp,
        oeffnungsrichtungEinwaerts: isEinwaerts,
        oeffnungsrichtungAuswaerts: isAuswaerts,
        profiltypEinsatzprofilPfostenRiegelKonstr: isPfostenRiegel,
        profiltypLochfenster: isLochfenster,
        blockfenster: isBlockFenster,
        standardfenster: isStandardFenster,
        flaechenbuendig: isFlaechenbuendig,
      }),
    );
  }

  function newFrameProfile(): void {
    dispatch(
      createFrameProfileFacade({
        seriesId: serie!.id,
        series: serie!,
        kammermassAussen: kammermassAussen!,
        maxFluegelaufschlag: maxFluegelaufschlag!,
        kammermassFuerIntegrierteMontage: kammermassIntegriert,
        artNr: artNr,
        beschreibung: beschreibung,
        kammermassInnen: kammermassInnen!,
        fuerIntegrierteAntriebe: forIntegrierteAntriebe,
        maxFluegelgewicht: maxFluegelgewicht!,
        active: isActive,
        preview: isPreview,
        fuerGrundprofilGeeignet: forGrundprofil,
        fuerWechselprofilGeeignet: forWechselprofil,
        tipTronic: tiptronic,
        bautiefe: bautiefe!,
        montierbarLinks: left,
        montierbarRechts: right,
        montierbarOben: top,
        montierbarUnten: bottom,
        anwendungKipp: kipp,
        anwendungKlapp: klapp,
        anwendungDreh: dreh,
        anwendungSenkklapp: senkKlapp,
        oeffnungsrichtungEinwaerts: isEinwaerts,
        oeffnungsrichtungAuswaerts: isAuswaerts,
        profiltypEinsatzprofilPfostenRiegelKonstr: isPfostenRiegel,
        profiltypLochfenster: isLochfenster,
        blockfenster: isBlockFenster,
        standardfenster: isStandardFenster,
        flaechenbuendig: isFlaechenbuendig,
      }),
    );
  }

  return (
    <Dialog
      cancelAction={resetInputFields}
      setDialogIsShown={props.setDialogIsShown}
      dialogIsShown={props.dialogIsShown}
      headingText={
        props.selectedFrameProfile
          ? `Blendrahmen ${props.selectedFrameProfile.artNr} bearbeiten`
          : 'Blendrahmen anlegen'
      }
      componentClass="full-view-dialog"
      key={props.dialogIsShown.toString()}
      footerProps={{
        notTranslated: true,
        cancelAction: () => {
          resetInputFields();
          props.setDialogIsShown(false);
        },
        saveAction: mandatoryFieldsFilled()
          ? () => {
              props.selectedFrameProfile
                ? editFrameProfile()
                : newFrameProfile();
              props.setDialogIsShown(false);
            }
          : undefined,
        primaryActionLabelText:
          props.selectedFrameProfile === undefined ? 'Anlegen' : 'Speichern',
      }}
    >
      <FrameProfileDataDialogTabNav />
      <Routes>
        <Route
          path="/basic/*"
          element={
            <FrameProfileDataDialogBasic
              selectedFrameProfile={props.selectedFrameProfile}
              allSeries={props.allSeries}
              setIsActive={setIsActive}
              isActive={isActive}
              setIsPreview={setIsPreview}
              isPreview={isPreview}
              artNr={artNr}
              setArtNr={setArtNr}
              beschreibung={beschreibung}
              setBeschreibung={setBeschreibung}
              series={serie}
              setSeries={setSerie}
              id={props.selectedFrameProfile?.id}
            />
          }
        />
        <Route
          path="/basic-information/*"
          element={
            <FrameProfileDataDialogBasicInformation
              kammermassInnen={kammermassInnen}
              setKammermassInnen={setKammermassInnen}
              kammermassAussen={kammermassAussen}
              setKammermassAussen={setKammermassAussen}
              bautiefe={bautiefe}
              setBautiefe={setBautiefe}
              maxFluegelaufschlag={maxFluegelaufschlag}
              setMaxFluegelaufschlag={setMaxFluegelaufschlag}
              maxFluegelgewicht={maxFluegelgewicht}
              setMaxFluegelgewicht={setMaxFluegelgewicht}
              selectedFrameProfile={props.selectedFrameProfile}
            />
          }
        />

        <Route
          path="/properties/*"
          element={
            <FrameProfileDataDialogProperties
              selectedFrameProfile={props.selectedFrameProfile}
              tiptronic={tiptronic}
              setTipTronic={setTipTronic}
              forIntegrierteAntriebe={forIntegrierteAntriebe}
              setForIntegrierteAntriebe={setForIntegrierteAntriebe}
              kammermassIntegriert={kammermassIntegriert}
              setKammermassIntegriert={setKammermassIntegriert}
              forGrundprofil={forGrundprofil}
              setForGrundprofil={setForGrundprofil}
              forWechselprofil={forWechselprofil}
              setForWechselprofil={setForWechselprofil}
              isEinwaerts={isEinwaerts}
              setIsEinwaerts={setIsEinwaerts}
              isAuswaerts={isAuswaerts}
              setIsAuswaerts={setIsAuswaerts}
              left={left}
              setLeft={setLeft}
              right={right}
              setRight={setRight}
              top={top}
              setTop={setTop}
              bottom={bottom}
              setBottom={setBottom}
              kipp={kipp}
              setKipp={setKipp}
              klapp={klapp}
              setKlapp={setKlapp}
              dreh={dreh}
              setDreh={setDreh}
              senkKlapp={senkKlapp}
              setSenkKlapp={setSenkKlapp}
              isPfostenRiegel={isPfostenRiegel}
              setIsPfostenRiegel={setIsPfostenRiegel}
              isLochfenster={isLochfenster}
              setIsLochfenster={setIsLochfenster}
              isStandardFenster={isStandardFenster}
              setIsStandardFenster={setIsStandardFenster}
              isBlockFenster={isBlockFenster}
              setIsBlockFenster={setIsBlockFenster}
              isFlaechenbuendig={isFlaechenbuendig}
              setIsFlaechenbuendig={setIsFlaechenbuendig}
              serie={serie}
            />
          }
        />
      </Routes>
    </Dialog>
  );
}

const FacadeFrameProfileDataView: FC<React.PropsWithChildren<unknown>> = () => {
  const [dialogIsShown, setDialogIsShown] = useState(false);
  const frameProfiles = useSelector<AdminState, FrameProfileFacade[]>(
    state => state.adminFacade.frameProfiles,
  );
  const allSeries = useSelector<AdminState, Series[]>(
    state => state.adminFacade.series,
  );
  const [searchString, setSearchString] = useState('');
  const [filterActive, setFilterActive, searchResult] = useAdminSearch(
    frameProfiles,
    searchString,
    ['artNr'],
    entry => {
      const series = getSeriesForProfileId(entry.seriesId, allSeries);
      return [series?.name || '', series?.system.name || ''];
    },
  );

  const [selectedFrameProfileID, setSelectedFrameProfileID] = useState<
    undefined | number
  >(undefined);

  const selectedFrameProfile = frameProfiles.find(
    f => f.id === selectedFrameProfileID || undefined,
  );

  const [indexOfFirstPageElement, setIndexOfFirstPageElement] = useState(0);
  const [page, setPage] = useState(1);

  function enableEditFrameProfile(frameProfile: FrameProfileFacade): void {
    setSelectedFrameProfileID(frameProfile.id);
    setDialogIsShown(true);
  }

  function triggerCreationMode(): void {
    setDialogIsShown(true);
    setSelectedFrameProfileID(undefined);
  }

  function getCurrentTableContent(): FrameProfileFacade[] {
    return searchResult.slice(
      indexOfFirstPageElement,
      indexOfFirstPageElement + 20,
    );
  }

  function parentSeriesIsActive(frameProfile: FrameProfileFacade): boolean {
    return (
      allSeries.find(series => series.id === frameProfile.seriesId)?.active ||
      false
    );
  }

  return (
    <>
      <AdminFrameProfileDialog
        selectedFrameProfile={selectedFrameProfile}
        dialogIsShown={dialogIsShown}
        setDialogIsShown={setDialogIsShown}
        allSeries={allSeries}
        activeSwitchEnabled={
          (selectedFrameProfile &&
            parentSeriesIsActive(selectedFrameProfile)) ||
          false
        }
      />

      <div className="sub-header">
        <div className="sub-header__title"> Blendrahmen</div>

        <SearchField
          setSearchString={setSearchString}
          searchString={searchString}
          placeholderText="Blendrahmen suchen..."
          small={true}
          setFilterActive={setFilterActive}
          filterActive={filterActive}
        />
        <button
          className="sub-header__download-button"
          onClick={() =>
            downloadListAsCSV(
              searchResult,
              ';',
              ['legacyId'],
              'BlendrahmenFassade',
              { series: (s: Series) => s.name },
              { system: profile => profile.series.system.name },
            )
          }
        >
          CSV
        </button>
        <button
          className="sub-header__button sub-header__button--add"
          onClick={() => triggerCreationMode()}
        >
          Neuer Eintrag
        </button>
      </div>

      <div className="frame-profile-data-view">
        <Table subNavigation={true}>
          <TableHeader>
            <th>Artikelnummer</th>
            <th>Beschreibung</th>
            <th>System</th>
            <th>Serie</th>
            <th>Kipp</th>
            <th>Klapp</th>
            <th>Dreh</th>
            <th>Einwärts</th>
            <th>Auswärts</th>
            <th>Pfosten-Riegel</th>
            <th>Standardfenster (innen flächenversetzt)</th>
            <th>Blockfenster (innen flächenversetzt)</th>
            <th>Flächenbündig (innen u. außen)</th>
            <th>Integrierte Antriebe</th>
            <th>Aktion</th>
          </TableHeader>
          <tbody>
            {getCurrentTableContent().map(frameProfile => (
              <TableRow key={frameProfile.id}>
                <td>{frameProfile.artNr}</td>
                <td>{frameProfile.beschreibung}</td>
                <td>
                  {getSeriesForProfileId(frameProfile.seriesId, allSeries)
                    ?.system.name ?? ''}
                </td>
                <td>
                  {getSeriesForProfileId(frameProfile.seriesId, allSeries)
                    ?.name ?? ''}
                </td>
                <td>
                  <AdminListIcon checked={frameProfile.anwendungKipp} />
                </td>
                <td>
                  <AdminListIcon checked={frameProfile.anwendungKlapp} />
                </td>
                <td>
                  <AdminListIcon checked={frameProfile.anwendungDreh} />
                </td>
                <td>
                  <AdminListIcon
                    checked={frameProfile.oeffnungsrichtungEinwaerts}
                  />
                </td>
                <td>
                  <AdminListIcon
                    checked={frameProfile.oeffnungsrichtungAuswaerts}
                  />
                </td>
                <td>
                  <AdminListIcon
                    checked={
                      frameProfile.profiltypEinsatzprofilPfostenRiegelKonstr
                    }
                  />
                </td>
                <td>
                  <AdminListIcon checked={frameProfile.standardfenster} />
                </td>
                <td>
                  <AdminListIcon checked={frameProfile.blockfenster} />
                </td>
                <td>
                  <AdminListIcon checked={frameProfile.flaechenbuendig} />
                </td>
                <td>
                  <AdminListIcon
                    checked={frameProfile.fuerIntegrierteAntriebe}
                  />
                </td>
                <td>
                  <button
                    onClick={() => {
                      enableEditFrameProfile(frameProfile);
                    }}
                  >
                    Bearbeiten
                  </button>
                </td>
              </TableRow>
            ))}
          </tbody>
        </Table>
      </div>
      <Pagination
        searchString={searchString}
        numberOfPages={searchResult.length}
        page={page}
        setPage={setPage}
        indexOfFirstPageElement={indexOfFirstPageElement}
        setIndexOfFirstPageElement={setIndexOfFirstPageElement}
      />
    </>
  );
};
export default FacadeFrameProfileDataView;
