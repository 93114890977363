import React, { FC, ReactNode, useRef, useState } from 'react';
import { standardHeaders } from '../redux/httpClient';

interface ProtectedDownloadLinkProps {
  link: string;
  label: ReactNode;
}

const ProtectedDownloadLink: FC<
  React.PropsWithChildren<ProtectedDownloadLinkProps>
> = (props: ProtectedDownloadLinkProps) => {
  const linkRef = useRef<HTMLAnchorElement>(null);
  const [downloadInProgress, setDownloadInProgress] = useState(false);

  function fetchTokenAndTriggerDownload(e: React.MouseEvent): void {
    if (!linkRef.current || !window.keyCloak?.loggedIn) {
      return;
    }

    if (downloadInProgress) {
      setDownloadInProgress(false);
    } else {
      standardHeaders().then(headers =>
        window
          .fetch('/downloadToken', { headers: headers })
          .then(r => r.json())
          .then(token => {
            setDownloadInProgress(true);
            linkRef.current!.setAttribute(
              'href',
              props.link.match(/\?/)
                ? props.link + `&token=${token.token}`
                : props.link + `&token=${token.token}`.replace('&', '?'),
            );

            linkRef.current!.click();
          }),
      );
      e.preventDefault();
    }
  }
  return (
    <a
      onClick={fetchTokenAndTriggerDownload}
      href={props.link}
      ref={linkRef}
      download
    >
      {props.label}
    </a>
  );
};

export default ProtectedDownloadLink;
