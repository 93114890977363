import React, { FC, ReactNode } from 'react';
import './FieldLable.scss';
import { conditionalClass } from './element_utils';
import classNames from 'classnames';

interface FieldLableProps {
  children: ReactNode;
  additionalClass?: string;
}

export const FieldLabel: FC<React.PropsWithChildren<FieldLableProps>> = (
  props: FieldLableProps,
) => {
  return (
    <div
      className={classNames(
        'field-label' + conditionalClass(props.additionalClass),
      )}
    >
      {props.children}
    </div>
  );
};

export default FieldLabel;
