import { SelectOption } from '../elements/SelectField';
import {
  BasicProfile,
  CompanyWithOptionalId,
  ExchangeProfile,
  FrameProfileFacade,
  Kurvensatz,
  SashProfileFacade,
  Series,
  TestPhaseApplicationWithOptionalId,
  TestPhaseMountingTypeWithOptionalId,
  TestPhaseProfileTypeWithOptionalId,
  TestPhaseSeriesOpeningDirectionWithOptionalId,
} from '../redux/admin/adminFacadeReducer';
import {
  TestPhaseAerodynamicCaseRoofWithOptionalId,
  TestPhaseApplicationRoofWithOptionalId,
} from '../redux/admin/adminRoofReducer';
import { AERODYNAMIC_CASE, DriveSeries } from '../redux/constants';

export enum CompanyBluekitPriceListOptions {
  STANDARD = 'Standard',
  DHDE_SCHINDLER_202404 = 'DHDE_Schindler_202404',
}

export enum CompanyBluekitDhdeMontageCalculationOptions {
  STANDARD = 'Standard',
  SCHINDLER_202404 = 'Schindler_202404',
}

export const newCompany: CompanyWithOptionalId = {
  bluekitArticleNumberMappings: [],
  active: true,
  city: '',
  companyType: '',
  country: 'de',
  cprNumberFacade: '',
  cprNumberRoof: '',
  cprNumberFacadeSteel: '',
  name: '',
  nameShort: '',
  number: '',
  street: '',
  zip: '',
  mycalc: false,
  bluekit: false,
  bluekitDiscountCode: '',
  debtorNumber: '',
  showPerformanceDataInNrwgLabel: true,
  bluekitDhdeMontageCalculation:
    CompanyBluekitDhdeMontageCalculationOptions.STANDARD,
  bluekitPriceList: CompanyBluekitPriceListOptions.STANDARD,
};

export const POSSIBLE_TEST_PHASE_AERODYNAMIC_CASES_OPTIONS: TestPhaseAerodynamicCaseRoofWithOptionalId[] =
  [
    {
      windDeflectorConfiguration: [
        {
          largerOrEqualThanAngleOfInstallation: 25,
          smallerOrEqualThanAngleOfInstallation: 60,
          numberOfWindDeflectors: 2,
        },
      ],
      largerOrEqualSashHeight: undefined,
      smallerOrEqualSashHeight: undefined,
      maximumRatioWidthToHeight: undefined,
      aerodynamicCase: AERODYNAMIC_CASE.AERODYNAMIC_CASE_2560,
      maxFluegelflaeche: undefined,
      cvCurve: undefined,
    },
    {
      windDeflectorConfiguration: [
        {
          largerOrEqualThanAngleOfInstallation: 25,
          smallerOrEqualThanAngleOfInstallation: 60,
          numberOfWindDeflectors: 0,
        },
      ],
      largerOrEqualSashHeight: undefined,
      smallerOrEqualSashHeight: undefined,
      maximumRatioWidthToHeight: undefined,
      aerodynamicCase: AERODYNAMIC_CASE.AERODYNAMIC_CASE_2560_OHNE_WLW,
      maxFluegelflaeche: undefined,
      cvCurve: undefined,
    },
    {
      windDeflectorConfiguration: [
        {
          largerOrEqualThanAngleOfInstallation: 30,
          smallerOrEqualThanAngleOfInstallation: 60,
          numberOfWindDeflectors: 0,
        },
      ],
      largerOrEqualSashHeight: undefined,
      smallerOrEqualSashHeight: undefined,
      maximumRatioWidthToHeight: undefined,
      aerodynamicCase: AERODYNAMIC_CASE.AERODYNAMIC_CASE_0030OWLW,
      maxFluegelflaeche: undefined,
      cvCurve: undefined,
    },
    {
      windDeflectorConfiguration: [
        {
          largerOrEqualThanAngleOfInstallation: 0,
          smallerOrEqualThanAngleOfInstallation: 15,
          numberOfWindDeflectors: 2,
        },
      ],
      largerOrEqualSashHeight: undefined,
      smallerOrEqualSashHeight: undefined,
      maximumRatioWidthToHeight: undefined,
      aerodynamicCase: AERODYNAMIC_CASE.AERODYNAMIC_CASE_0015,
      maxFluegelflaeche: undefined,
      cvCurve: undefined,
    },
    {
      windDeflectorConfiguration: [
        {
          largerOrEqualThanAngleOfInstallation: 16,
          smallerOrEqualThanAngleOfInstallation: 30,
          numberOfWindDeflectors: 2,
        },
      ],
      largerOrEqualSashHeight: undefined,
      smallerOrEqualSashHeight: undefined,
      maximumRatioWidthToHeight: undefined,
      aerodynamicCase: AERODYNAMIC_CASE.AERODYNAMIC_CASE_1630,
      maxFluegelflaeche: undefined,
      cvCurve: undefined,
    },
    {
      windDeflectorConfiguration: [
        {
          largerOrEqualThanAngleOfInstallation: 2,
          smallerOrEqualThanAngleOfInstallation: 30,
          numberOfWindDeflectors: 2,
        },
      ],
      largerOrEqualSashHeight: undefined,
      smallerOrEqualSashHeight: undefined,
      maximumRatioWidthToHeight: undefined,
      aerodynamicCase: AERODYNAMIC_CASE.AERODYNAMIC_CASE_0230,
      maxFluegelflaeche: undefined,
      cvCurve: undefined,
    },
    {
      windDeflectorConfiguration: [
        {
          largerOrEqualThanAngleOfInstallation: 2,
          smallerOrEqualThanAngleOfInstallation: 15,
          numberOfWindDeflectors: 3,
        },
      ],
      largerOrEqualSashHeight: undefined,
      smallerOrEqualSashHeight: undefined,
      maximumRatioWidthToHeight: undefined,
      aerodynamicCase: AERODYNAMIC_CASE.AERODYNAMIC_CASE_0215WLW3,
      maxFluegelflaeche: undefined,
      cvCurve: undefined,
    },
    {
      windDeflectorConfiguration: [
        {
          largerOrEqualThanAngleOfInstallation: 2,
          smallerOrEqualThanAngleOfInstallation: 24,
          numberOfWindDeflectors: 3,
        },
      ],
      largerOrEqualSashHeight: undefined,
      smallerOrEqualSashHeight: undefined,
      maximumRatioWidthToHeight: undefined,
      aerodynamicCase: AERODYNAMIC_CASE.AERODYNAMIC_CASE_0224WLW3,
      maxFluegelflaeche: undefined,
      cvCurve: undefined,
    },
    {
      windDeflectorConfiguration: [
        {
          largerOrEqualThanAngleOfInstallation: 16,
          smallerOrEqualThanAngleOfInstallation: 30,
          numberOfWindDeflectors: 3,
        },
      ],
      largerOrEqualSashHeight: undefined,
      smallerOrEqualSashHeight: undefined,
      maximumRatioWidthToHeight: undefined,
      aerodynamicCase: AERODYNAMIC_CASE.AERODYNAMIC_CASE_1630WLW3,
      maxFluegelflaeche: undefined,
      cvCurve: undefined,
    },
    {
      windDeflectorConfiguration: [
        {
          largerOrEqualThanAngleOfInstallation: 31,
          smallerOrEqualThanAngleOfInstallation: 50,
          numberOfWindDeflectors: 3,
        },
      ],
      largerOrEqualSashHeight: undefined,
      smallerOrEqualSashHeight: undefined,
      maximumRatioWidthToHeight: undefined,
      aerodynamicCase: AERODYNAMIC_CASE.AERODYNAMIC_CASE_3150WLW3,
      maxFluegelflaeche: undefined,
      cvCurve: undefined,
    },
    {
      windDeflectorConfiguration: [
        {
          largerOrEqualThanAngleOfInstallation: 0,
          smallerOrEqualThanAngleOfInstallation: 15,
          numberOfWindDeflectors: 0,
        },
      ],
      largerOrEqualSashHeight: undefined,
      smallerOrEqualSashHeight: undefined,
      maximumRatioWidthToHeight: undefined,
      aerodynamicCase: AERODYNAMIC_CASE.AERODYNAMIC_CASE_0015OWLW,
      maxFluegelflaeche: undefined,
      cvCurve: undefined,
    },
    {
      windDeflectorConfiguration: [
        {
          largerOrEqualThanAngleOfInstallation: 16,
          smallerOrEqualThanAngleOfInstallation: 30,
          numberOfWindDeflectors: 0,
        },
      ],
      largerOrEqualSashHeight: undefined,
      smallerOrEqualSashHeight: undefined,
      maximumRatioWidthToHeight: undefined,
      aerodynamicCase: AERODYNAMIC_CASE.AERODYNAMIC_CASE_1630OWLW,
      maxFluegelflaeche: undefined,
      cvCurve: undefined,
    },
    {
      windDeflectorConfiguration: [
        {
          largerOrEqualThanAngleOfInstallation: 16,
          smallerOrEqualThanAngleOfInstallation: 30,
          numberOfWindDeflectors: 0,
        },
      ],
      largerOrEqualSashHeight: undefined,
      smallerOrEqualSashHeight: undefined,
      maximumRatioWidthToHeight: undefined,
      aerodynamicCase: AERODYNAMIC_CASE.AERODYNAMIC_CASE_0030OWLW,
      maxFluegelflaeche: undefined,
      cvCurve: undefined,
    },
    {
      windDeflectorConfiguration: [
        {
          largerOrEqualThanAngleOfInstallation: 0,
          smallerOrEqualThanAngleOfInstallation: 9,
          numberOfWindDeflectors: 2,
        },
        {
          largerOrEqualThanAngleOfInstallation: 10,
          smallerOrEqualThanAngleOfInstallation: 20,
          numberOfWindDeflectors: 1,
        },
      ],
      largerOrEqualSashHeight: undefined,
      smallerOrEqualSashHeight: undefined,
      maximumRatioWidthToHeight: undefined,
      aerodynamicCase: AERODYNAMIC_CASE.AERODYNAMIC_CASE_0020WLW,
      maxFluegelflaeche: undefined,
      cvCurve: undefined,
    },
    {
      windDeflectorConfiguration: [
        {
          largerOrEqualThanAngleOfInstallation: 0,
          smallerOrEqualThanAngleOfInstallation: 20,
          numberOfWindDeflectors: 0,
        },
      ],
      largerOrEqualSashHeight: undefined,
      smallerOrEqualSashHeight: undefined,
      maximumRatioWidthToHeight: undefined,
      aerodynamicCase: AERODYNAMIC_CASE.AERODYNAMIC_CASE_0020OWLW,
      maxFluegelflaeche: undefined,
      cvCurve: undefined,
    },
    {
      windDeflectorConfiguration: [
        {
          largerOrEqualThanAngleOfInstallation: 0,
          smallerOrEqualThanAngleOfInstallation: 14,
          numberOfWindDeflectors: 2,
        },
        {
          largerOrEqualThanAngleOfInstallation: 15,
          smallerOrEqualThanAngleOfInstallation: 20,
          numberOfWindDeflectors: 1,
        },
      ],
      largerOrEqualSashHeight: 1200,
      smallerOrEqualSashHeight: undefined,
      maximumRatioWidthToHeight: undefined,
      aerodynamicCase: AERODYNAMIC_CASE.AERODYNAMIC_CASE_0020WLWBLE1200,
      maxFluegelflaeche: undefined,
      cvCurve: undefined,
    },
    {
      windDeflectorConfiguration: [
        {
          largerOrEqualThanAngleOfInstallation: 0,
          smallerOrEqualThanAngleOfInstallation: 20,
          numberOfWindDeflectors: 2,
        },
      ],
      largerOrEqualSashHeight: undefined,
      smallerOrEqualSashHeight: 1199,
      maximumRatioWidthToHeight: undefined,
      aerodynamicCase: AERODYNAMIC_CASE.AERODYNAMIC_CASE_0020WLWBS1200,
      maxFluegelflaeche: undefined,
      cvCurve: undefined,
    },
  ];

export const POSSIBLE_OPENING_DIRECTION_OPTIONS: TestPhaseSeriesOpeningDirectionWithOptionalId[] =
  [
    { oeffnungsrichtung: 'ausw', anwendungen: [] },
    { oeffnungsrichtung: 'einw', anwendungen: [] },
  ];

export const POSSIBLE_TEST_PHASE_APPLICATIONS_OPTIONS: TestPhaseApplicationWithOptionalId[] =
  [
    { application: 'klapp', maxSashWeight: undefined, mountingTypes: [] },
    { application: 'kipp', maxSashWeight: undefined, mountingTypes: [] },
    { application: 'dreh', maxSashWeight: undefined, mountingTypes: [] },
  ];

export const POSSIBLE_TEST_PHASE_APPLICATIONS_OPTIONS_ROOF: TestPhaseApplicationRoofWithOptionalId[] =
  [
    {
      application: 'klapp',
      maxSashWeight: undefined,
      sashFrameDistanceMin: undefined,
      sashFrameDistanceMax: undefined,
      testPhaseAerodynamicCaseRoofs: [],
      doubleFlapBottomOrTopHung: false,
      doubleFlapSideHung: false,
    },
    {
      application: 'kipp',
      maxSashWeight: undefined,
      sashFrameDistanceMin: undefined,
      sashFrameDistanceMax: undefined,
      testPhaseAerodynamicCaseRoofs: [],
      doubleFlapBottomOrTopHung: false,
      doubleFlapSideHung: false,
    },
    {
      application: 'dreh',
      maxSashWeight: undefined,
      sashFrameDistanceMin: undefined,
      sashFrameDistanceMax: undefined,
      testPhaseAerodynamicCaseRoofs: [],
      doubleFlapBottomOrTopHung: false,
      doubleFlapSideHung: false,
    },
    {
      application: 'einzelklappe',
      maxSashWeight: undefined,
      sashFrameDistanceMin: undefined,
      sashFrameDistanceMax: undefined,
      testPhaseAerodynamicCaseRoofs: [],
      doubleFlapBottomOrTopHung: false,
      doubleFlapSideHung: false,
    },
    {
      application: 'einzelklappeklapp',
      maxSashWeight: undefined,
      sashFrameDistanceMin: undefined,
      sashFrameDistanceMax: undefined,
      testPhaseAerodynamicCaseRoofs: [],
      doubleFlapBottomOrTopHung: false,
      doubleFlapSideHung: false,
    },
    {
      application: 'doubleFlapPentRoofBottomTopHung',
      maxSashWeight: undefined,
      sashFrameDistanceMin: undefined,
      sashFrameDistanceMax: undefined,
      testPhaseAerodynamicCaseRoofs: [],
      doubleFlapBottomOrTopHung: true,
      doubleFlapSideHung: false,
    },
    {
      application: 'doubleFlapSaddleRoofBottomHungBottomHung',
      maxSashWeight: undefined,
      sashFrameDistanceMin: undefined,
      sashFrameDistanceMax: undefined,
      testPhaseAerodynamicCaseRoofs: [],
      doubleFlapBottomOrTopHung: true,
      doubleFlapSideHung: false,
    },
    {
      application: 'doppelklappetonnendach',
      maxSashWeight: undefined,
      sashFrameDistanceMin: undefined,
      sashFrameDistanceMax: undefined,
      testPhaseAerodynamicCaseRoofs: [],
      doubleFlapBottomOrTopHung: true,
      doubleFlapSideHung: false,
    },
    {
      application: 'doubleFlapSideHungSideHungPentRoof',
      maxSashWeight: undefined,
      sashFrameDistanceMin: undefined,
      sashFrameDistanceMax: undefined,
      testPhaseAerodynamicCaseRoofs: [],
      doubleFlapBottomOrTopHung: false,
      doubleFlapSideHung: true,
    },
    {
      application: 'doubleFlapSideHungSideHungSaddleRoof',
      maxSashWeight: undefined,
      sashFrameDistanceMin: undefined,
      sashFrameDistanceMax: undefined,
      testPhaseAerodynamicCaseRoofs: [],
      doubleFlapBottomOrTopHung: false,
      doubleFlapSideHung: true,
    },
  ];

export const AERODYNAMIC_CASE_DESCRIPTIONS: Record<AERODYNAMIC_CASE, string> = {
  [AERODYNAMIC_CASE.AERODYNAMIC_CASE_2560]: '25°-60° mit zweiseitigen WLW',
  [AERODYNAMIC_CASE.AERODYNAMIC_CASE_2560_OHNE_WLW]: '25°-60° ohne WLW',
  [AERODYNAMIC_CASE.AERODYNAMIC_CASE_0015]: '0°-15° mit zweiseitigen WLW',
  [AERODYNAMIC_CASE.AERODYNAMIC_CASE_1630]: '16°-30° mit zweiseitigen WLW',
  [AERODYNAMIC_CASE.AERODYNAMIC_CASE_0230]: '2°-30° mit zweiseitigen WLW',
  [AERODYNAMIC_CASE.AERODYNAMIC_CASE_0215WLW3]: '2°-15° mit dreiseitigen WLW',
  [AERODYNAMIC_CASE.AERODYNAMIC_CASE_0224WLW3]: '2°-24° mit dreiseitigen WLW',
  [AERODYNAMIC_CASE.AERODYNAMIC_CASE_1630WLW3]: '16°-30° mit dreiseitigen WLW',
  [AERODYNAMIC_CASE.AERODYNAMIC_CASE_3150WLW3]: '31°-50° mit dreiseitigen WLW',
  [AERODYNAMIC_CASE.AERODYNAMIC_CASE_0015OWLW]: '0°-15° ohne WLW',
  [AERODYNAMIC_CASE.AERODYNAMIC_CASE_1630OWLW]: '16°-30° ohne WLW',
  [AERODYNAMIC_CASE.AERODYNAMIC_CASE_0030OWLW]: '0°-30° ohne WLW',
  [AERODYNAMIC_CASE.AERODYNAMIC_CASE_0020WLWBLE1200]:
    '0°-20° mit WLW h >= 1200',
  [AERODYNAMIC_CASE.AERODYNAMIC_CASE_0020WLWBS1200]: '0°-20° mit WLW h < 1200',
  [AERODYNAMIC_CASE.AERODYNAMIC_CASE_0020OWLW]: '0°-20° ohne WLW',
  [AERODYNAMIC_CASE.AERODYNAMIC_CASE_0020WLW]: '0°-20° mit WLW',
};

export const POSSIBLE_TEST_PHASE_MOUNTING_TYPES_OPTIONS: TestPhaseMountingTypeWithOptionalId[] =
  [
    { mountingType: 'pr', profileTypes: [] },
    { mountingType: 'lf', profileTypes: [] },
    { mountingType: 'lfgt0', profileTypes: [] },
  ];

export const MOUNTING_TYPES: SelectOption<string>[] = [
  { label: 'Pfosten-Riegel', value: 'pr' },
  { label: 'Lochfenster', value: 'lf' },
  { label: 'Lochfenster > 0mm', value: 'lfgt0' },
];

export const POSSIBLE_TEST_PHASE_PROFILE_TYPES_OPTIONS: TestPhaseProfileTypeWithOptionalId[] =
  [
    { profileType: 'fb', cvCurve: undefined },
    { profileType: 'bf', cvCurve: undefined },
    { profileType: 'sf', cvCurve: undefined },
  ];

export const PROFILE_TYPES: SelectOption<string>[] = [
  { label: 'Flächenbündig', value: 'fb' },
  { label: 'Blockfenster', value: 'bf' },
  { label: 'Standardfenster', value: 'sf' },
];

export enum ActiveFurtherInformationUiSection {
  SYSTEM = 'SYSTEM',
  SERIES = 'SERIES',
  OPENING_DIRECTION = 'OPENING_DIRECTION',
  APPLICATION = 'APPLICATION',
  MOUNTING_TYPE = 'MOUNTING_TYPE',
  PROFILE_TYPE = 'PROFILE_TYPE',
  AERODYNAMIC_CASE = 'AERODYNAMIC_CASE',
}

export enum Funktionssicherheit {
  RE1000 = 're1000',
  RE1000PLUS = 're1000plus',
}

export enum UserGender {
  MALE = 'male',
  FEMALE = 'female',
  DIVERSE = 'diverse',
}

export enum UmgebungsTemp {
  ZERO = 0,
  FIVE_UNDER_ZERO = -5,
  TEN_UNDER_ZERO = -10,
  FIFTEEN_UNDER_ZERO = -15,
}

export enum Waermebestaendigkeit {
  B300E = 'B₃₀₀30-E',
  B300F = 'B₃₀₀30-F',
  N_P_D = 'n.p.d.',
  B300_N_P_D = 'B₃₀₀30-n.p.d.',
}

export enum Material {
  ALUMINIUM = 'ALUMINIUM',
  HOLZ = 'WOOD',
  ALUMINIUM_HOLZ = 'ALUMINIUM_WOOD',
  STAHL = 'STEEL',
  GLAS = 'GLAS',
}

export enum CvCurveDescription {
  CVKURVE_DESCRIPTION_LHLB = 'lh/lb',
  CVKURVE_DESCRIPTION_LBLH = 'lb/lh',
  CVKURVE_DESCRIPTION_LH = 'lh',
  CVKURVE_DESCRIPTION_LB = 'lb',
  CVKURVE_DESCRIPTION_HB = 'h/b',
  CVKURVE_DESCRIPTION_BH = 'b/h',
  CVKURVE_DESCRIPTION_H = 'h',
  CVKURVE_DESCRIPTION_B = 'b',
  CVKURVE_DESCRIPTION_LDKHLB = 'ldkh/lb',
  CVKURVE_DESCRIPTION_LHLDKB = 'lh/ldkb',
  CVKURVE_DESCRIPTION_LDKBLH = 'ldkb/lh',
  CVKURVE_DESCRIPTION_LBLDKH = 'lb/ldkh',
  CVKURVE_DESCRIPTION_LDKH = 'ldkh',
  CVKURVE_DESCRIPTION_DKHB = 'dkh/b',
  CVKURVE_DESCRIPTION_HDKB = 'h/dkb',
  CVKURVE_DESCRIPTION_DKBH = 'dkb/h',
  CVKURVE_DESCRIPTION_BDKH = 'b/dkh',
  CVKURVE_DESCRIPTION_DKH = 'dkh',
  CVKURVE_DESCRIPTION_DKB = 'dkb',
}

export const EXISTING_DRIVE_SERIES_FACADE: { driveSeries: string }[] = [
  { driveSeries: 'ka' },
  { driveSeries: 'katw' },
  { driveSeries: 'cdc' },
  { driveSeries: 'cdctw' },
  { driveSeries: 'za' },
  { driveSeries: 'shd' },
  { driveSeries: 'cdm' },
];

export const EXISTING_DRIVE_SERIES_ROOF: { driveSeries: string }[] = [
  { driveSeries: 'ka' },
  { driveSeries: 'katw' },
  { driveSeries: 'za' },
  { driveSeries: 'dxd' },
  { driveSeries: 'cdp' },
  { driveSeries: 'cdptw' },
  { driveSeries: 'cdm' },
];

export enum CompareOperators {
  ST = 'st',
  STOE = 'stoe',
  EMPTY = 'empty',
}

export const DEFAULT_CURVE_SET: Kurvensatz = {
  value1: 100,
  value2: 100,
  compare1: CompareOperators.STOE,
  compare2: CompareOperators.ST,
  description: CvCurveDescription.CVKURVE_DESCRIPTION_LB,
  cvwerte: [{ grad: 0, cvDurchflusswert: 0 }],
};

export const ROOF_TYPE_OPTIONS: { roofType: string }[] = [
  {
    roofType: 'Satteldach',
  },
  {
    roofType: 'Tonnendach',
  },
  {
    roofType: 'Pultdach',
  },
];

export enum UiConstants {
  EDIT = 'Bearbeiten',
  NEW_ENTRY = 'Neuer Eintrag',
  SELECT_SERIES = 'Serie auswählen',
  ADD = 'Anlegen',
  SAVE = 'Speichern',
  NAME = 'Name',
  DELETE = 'Löschen',
  FINISH = 'Fertig',
  NEW_USER = 'Neuer Benutzer',
  COPY = 'kopieren',
  DOWNLOAD_USER_LIST = 'Nutzerliste',
  DOWNLOAD_COMPANY_LIST = 'Firmenliste',
  NEW_ROW = 'Neue Zeile',
}

export const COMPARE_OPTIONS: SelectOption<string>[] = [
  { value: CompareOperators.ST, label: '<' },
  { value: CompareOperators.STOE, label: '<=' },
  { value: CompareOperators.EMPTY, label: '' },
];

export const DESCRIPTION_OPTIONS: SelectOption<string>[] = [
  { label: CvCurveDescription.CVKURVE_DESCRIPTION_LB, value: 'lb' },
  { label: CvCurveDescription.CVKURVE_DESCRIPTION_LH, value: 'lh' },
  { label: CvCurveDescription.CVKURVE_DESCRIPTION_LHLB, value: 'lhlb' },
  { label: CvCurveDescription.CVKURVE_DESCRIPTION_LBLH, value: 'lblh' },
  { label: CvCurveDescription.CVKURVE_DESCRIPTION_HB, value: 'hb' },
  { label: CvCurveDescription.CVKURVE_DESCRIPTION_BH, value: 'bh' },
  { label: CvCurveDescription.CVKURVE_DESCRIPTION_H, value: 'h' },
  { label: CvCurveDescription.CVKURVE_DESCRIPTION_B, value: 'b' },
  { label: CvCurveDescription.CVKURVE_DESCRIPTION_LDKHLB, value: 'ldkhlb' },
  { label: CvCurveDescription.CVKURVE_DESCRIPTION_LHLDKB, value: 'lhldkb' },
  { label: CvCurveDescription.CVKURVE_DESCRIPTION_LDKBLH, value: 'ldkblh' },
  { label: CvCurveDescription.CVKURVE_DESCRIPTION_LBLDKH, value: 'lbldkh' },
  { label: CvCurveDescription.CVKURVE_DESCRIPTION_LDKH, value: 'ldkh' },
  { label: CvCurveDescription.CVKURVE_DESCRIPTION_LDKH, value: 'ldkb' },
  { label: CvCurveDescription.CVKURVE_DESCRIPTION_DKHB, value: 'dkhb' },
  { label: CvCurveDescription.CVKURVE_DESCRIPTION_HDKB, value: 'hdkb' },
  { label: CvCurveDescription.CVKURVE_DESCRIPTION_DKBH, value: 'dkbh' },
  { label: CvCurveDescription.CVKURVE_DESCRIPTION_BDKH, value: 'bdkh' },
  { label: CvCurveDescription.CVKURVE_DESCRIPTION_DKH, value: 'dkh' },
  { label: CvCurveDescription.CVKURVE_DESCRIPTION_DKB, value: 'dkb' },
];

export interface DialogSubCategoryProps {
  selectedFrameProfile?: FrameProfileFacade | undefined;
  selectedSashProfile?: SashProfileFacade | undefined;
  selectedBasicProfile?: BasicProfile | undefined;
  selectedExchangeProfile?: ExchangeProfile | undefined;
}

export interface ProfileDataDialogBasicProps extends DialogSubCategoryProps {
  allSeries: Series[];
  setIsActive: (b: boolean) => void;
  isActive: boolean;
  setIsPreview: (b: boolean) => void;
  isPreview: boolean;
  setArtNr: (s: string) => void;
  artNr: string;
  beschreibung: string;
  setBeschreibung: (s: string) => void;
  series: Series | undefined;
  setSeries: (series: Series) => void;
  id?: number;
}

export const LOCKING_DRIVE_OPTIONS: Record<string, string> = {
  ohne: 'ohne',
  brv: 'BRV',
  vp: 'VP',
};

export const MATERIAL_OPTIONS: SelectOption<string>[] = [
  { value: Material.ALUMINIUM, label: 'Aluminium' },
  { value: Material.HOLZ, label: 'Holz' },
  { value: Material.ALUMINIUM_HOLZ, label: 'Holz/Aluminium' },
  { value: Material.STAHL, label: 'Stahl' },
  { value: Material.GLAS, label: 'Ganzglassystem' },
];
