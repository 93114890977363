import * as React from 'react';
// @ts-ignore
const SvgEinzelklappeKlappMit3Wlw = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="Einzelklappe_Klapp_mit_3_WLW_svg__Ebene_2"
    data-name="Ebene 2"
    viewBox="0 0 1440 1080"
    style={{ marginTop: '-150px', marginBottom: '-150px' }}
    {...props}
  >
    <defs>
      <style>
        {
          '.Einzelklappe_Klapp_mit_3_WLW_svg__cls-11,.Einzelklappe_Klapp_mit_3_WLW_svg__cls-12,.Einzelklappe_Klapp_mit_3_WLW_svg__cls-17,.Einzelklappe_Klapp_mit_3_WLW_svg__cls-7,.Einzelklappe_Klapp_mit_3_WLW_svg__cls-9{stroke:#1e1e1c}.Einzelklappe_Klapp_mit_3_WLW_svg__cls-11,.Einzelklappe_Klapp_mit_3_WLW_svg__cls-12,.Einzelklappe_Klapp_mit_3_WLW_svg__cls-7,.Einzelklappe_Klapp_mit_3_WLW_svg__cls-9{fill:none}.Einzelklappe_Klapp_mit_3_WLW_svg__cls-11,.Einzelklappe_Klapp_mit_3_WLW_svg__cls-12,.Einzelklappe_Klapp_mit_3_WLW_svg__cls-9{stroke-miterlimit:10}.Einzelklappe_Klapp_mit_3_WLW_svg__cls-11{stroke-width:.75px}.Einzelklappe_Klapp_mit_3_WLW_svg__cls-23,.Einzelklappe_Klapp_mit_3_WLW_svg__cls-26{fill:#1e1e1c}.Einzelklappe_Klapp_mit_3_WLW_svg__cls-26{font-family:ArialMT,Arial}.Einzelklappe_Klapp_mit_3_WLW_svg__cls-17,.Einzelklappe_Klapp_mit_3_WLW_svg__cls-7{stroke-linejoin:round}.Einzelklappe_Klapp_mit_3_WLW_svg__cls-9{stroke-width:2px}.Einzelklappe_Klapp_mit_3_WLW_svg__cls-29{fill:#b0b0b1}.Einzelklappe_Klapp_mit_3_WLW_svg__cls-26{font-size:27.5px}.Einzelklappe_Klapp_mit_3_WLW_svg__cls-17{stroke-width:1.25px}.Einzelklappe_Klapp_mit_3_WLW_svg__cls-30{fill:#ececec}.Einzelklappe_Klapp_mit_3_WLW_svg__cls-17{fill:#fff}'
        }
      </style>
    </defs>
    <path
      d="M370.13 529.8 296 448.48l96.93-44.84 74.85 81.63-97.65 44.53z"
      style={{
        fill: '#b0b0b1',
        strokeWidth: '2.5px',
        opacity: 0.3,
        strokeMiterlimit: 10,
        stroke: '#1e1e1c',
      }}
    />
    <path
      d="m656.79 752.26 368.39-260.64 368.39 260.64H656.79z"
      className="Einzelklappe_Klapp_mit_3_WLW_svg__cls-30"
    />
    <path
      d="m1059.77 516.09 14.32-20.65 198.97 141.62-14.25 18.51-198.98-141.62"
      style={{
        strokeWidth: '1.25px',
        fill: '#b0b0b1',
        strokeMiterlimit: 10,
        stroke: '#1e1e1c',
      }}
    />
    <path
      d="m32.53 588.23 182.02-179.75 167.9 354.19L32.53 588.23z"
      className="Einzelklappe_Klapp_mit_3_WLW_svg__cls-30"
    />
    <path
      d="m214.55 408.48 199.89-91.15 167.12 354.01-99.5 45.64-80-152.91 54.91-25.11-64.04-135.32L296 448.48l63.87 134.84 42.19-19.25 80 152.91-99.61 45.69-167.9-354.19z"
      className="Einzelklappe_Klapp_mit_3_WLW_svg__cls-29"
    />
    <path
      d="M656.79 752.26h736.78l-368.39-260.64-368.39 260.64zM1259.98 653.97l-195.86-138.62 229.47 13.59"
      className="Einzelklappe_Klapp_mit_3_WLW_svg__cls-9"
    />
    <path
      d="m32.53 588.23 182.02-179.75 167.9 354.19L32.53 588.23z"
      style={{
        strokeWidth: 2,
        strokeLinejoin: 'round',
        strokeLinecap: 'round',
        fill: 'none',
        stroke: '#1e1e1c',
      }}
    />
    <path
      d="m214.55 408.48 199.89-91.15 167.12 354.01-199.11 91.33"
      className="Einzelklappe_Klapp_mit_3_WLW_svg__cls-9"
    />
    <path
      d="m456.97 508.33-.18 28.52-63.87-134.83v-28.71l64.05 135.02z"
      className="Einzelklappe_Klapp_mit_3_WLW_svg__cls-17"
    />
    <path
      d="M359.87 583.32 296 448.48l96.93-44.84 64.04 135.32-97.1 44.36z"
      style={{
        strokeWidth: '2.5px',
        strokeMiterlimit: 10,
        fill: 'none',
        stroke: '#1e1e1c',
      }}
    />
    <path
      d="M370.13 529.8 296 448.48l96.93-44.84 74.85 81.63-97.65 44.53z"
      className="Einzelklappe_Klapp_mit_3_WLW_svg__cls-9"
    />
    <path
      d="m360.04 554.79-.17 28.53L296 448.48v-28.71l64.04 135.02zM456.97 508.14l.01 31.32-96.94 43.86v-28.71l96.93-46.47z"
      className="Einzelklappe_Klapp_mit_3_WLW_svg__cls-17"
    />
    <text
      style={{
        fontSize: '27.5px',
        fontFamily: 'ArialMT,Arial',
        fill: '#1e1e1c',
      }}
      transform="rotate(-23.8 1651.233 -667.654)"
    >
      <tspan x={0} y={0}>
        {props.widthB}
      </tspan>
    </text>
    <text
      style={{
        fontSize: 30,
        fontFamily: 'ArialMT,Arial',
        fill: '#1e1e1c',
      }}
      transform="rotate(-23.8 1665.772 -716.183) scale(.58)"
    >
      <tspan x={0} y={0}>
        {props.frameFR}
      </tspan>
    </text>
    <text
      style={{
        fontSize: '27.5px',
        fontFamily: 'ArialMT,Arial',
        fill: '#1e1e1c',
      }}
      transform="rotate(64.08 -267.932 477.399)"
    >
      <tspan x={0} y={0}>
        {props.heightH}
      </tspan>
    </text>
    <text
      style={{
        fontSize: 30,
        fontFamily: 'ArialMT,Arial',
        fill: '#1e1e1c',
      }}
      transform="rotate(64.08 -285.842 488.274) scale(.58)"
    >
      <tspan x={0} y={0}>
        {props.frameFR}
      </tspan>
    </text>
    <text
      style={{
        letterSpacing: '-.06em',
        fontSize: '27.5px',
        fontFamily: 'ArialMT,Arial',
        fill: '#1e1e1c',
      }}
      transform="rotate(65.18 -210.486 395.216)"
    >
      <tspan x={0} y={0}>
        {props.distanceNRWGToRidgeFA}
      </tspan>
    </text>
    <text
      className="Einzelklappe_Klapp_mit_3_WLW_svg__cls-26"
      transform="translate(296.33 690.9)"
    >
      <tspan x={0} y={0}>
        {'\u03B1'}
      </tspan>
    </text>
    <path
      d="M269.76 695.86a131.691 131.691 0 0 1 45.52-45.66"
      className="Einzelklappe_Klapp_mit_3_WLW_svg__cls-12"
    />
    <path
      d="m273.3 695.56-7.8 8.07 2.72-10.89 5.08 2.82zM314.98 653.74l8.04-7.83-10.88 2.76 2.84 5.07z"
      className="Einzelklappe_Klapp_mit_3_WLW_svg__cls-23"
    />
    <path
      d="m375.16 597.78 81.35-36.96"
      className="Einzelklappe_Klapp_mit_3_WLW_svg__cls-12"
    />
    <path
      d="m378.17 599.6-11.07 1.84 8.66-7.13 2.41 5.29zM455.91 564.28l8.67-7.12-11.07 1.83 2.4 5.29z"
      className="Einzelklappe_Klapp_mit_3_WLW_svg__cls-23"
    />
    <path
      d="m361.16 586.12 1.07 2.26"
      className="Einzelklappe_Klapp_mit_3_WLW_svg__cls-11"
    />
    <path
      d="m364.46 593.1 7.82 16.51"
      style={{
        strokeDasharray: '0 0 5.22 5.22',
        strokeWidth: '.75px',
        strokeMiterlimit: 10,
        fill: 'none',
        stroke: '#1e1e1c',
      }}
    />
    <path
      d="m373.4 611.97 1.07 2.26"
      className="Einzelklappe_Klapp_mit_3_WLW_svg__cls-11"
    />
    <path
      d="m282.25 463.09 56.28 118.87"
      className="Einzelklappe_Klapp_mit_3_WLW_svg__cls-12"
    />
    <path
      d="m280.47 466.12-2.01-11.04 7.26 8.56-5.25 2.48zM335.05 581.42l7.27 8.55-2.01-11.04-5.26 2.49z"
      className="Einzelklappe_Klapp_mit_3_WLW_svg__cls-23"
    />
    <path
      d="m265.67 462.25 2.28-1.03"
      className="Einzelklappe_Klapp_mit_3_WLW_svg__cls-11"
    />
    <path
      d="m272.42 459.19 15.65-7.11"
      style={{
        strokeDasharray: '0 0 4.91 4.91',
        strokeWidth: '.75px',
        strokeMiterlimit: 10,
        fill: 'none',
        stroke: '#1e1e1c',
      }}
    />
    <path
      d="m290.31 451.06 2.28-1.03M329.53 597.14l2.28-1.03"
      className="Einzelklappe_Klapp_mit_3_WLW_svg__cls-11"
    />
    <path
      d="m336.42 594.02 16.14-7.34"
      style={{
        strokeWidth: '.75px',
        strokeMiterlimit: 10,
        fill: 'none',
        stroke: '#1e1e1c',
        strokeDasharray: '0 0 5.06 5.06',
      }}
    />
    <path
      d="m354.86 585.64 2.28-1.04M458.84 542.24l1.07 2.26"
      className="Einzelklappe_Klapp_mit_3_WLW_svg__cls-11"
    />
    <path
      d="m462.1 549.14 7.68 16.22"
      style={{
        strokeDasharray: '0 0 5.13 5.13',
        strokeWidth: '.75px',
        strokeMiterlimit: 10,
        fill: 'none',
        stroke: '#1e1e1c',
      }}
    />
    <path
      d="m470.88 567.68 1.07 2.26"
      className="Einzelklappe_Klapp_mit_3_WLW_svg__cls-11"
    />
    <path
      d="m252.49 400.91 22.14 46.18"
      className="Einzelklappe_Klapp_mit_3_WLW_svg__cls-12"
    />
    <path
      d="m250.73 403.95-2.07-11.03 7.31 8.52-5.24 2.51zM271.15 446.56l7.31 8.52-2.07-11.03-5.24 2.51z"
      className="Einzelklappe_Klapp_mit_3_WLW_svg__cls-23"
    />
    <path
      d="M602.77 516.1h78.24"
      className="Einzelklappe_Klapp_mit_3_WLW_svg__cls-12"
    />
    <text
      className="Einzelklappe_Klapp_mit_3_WLW_svg__cls-26"
      transform="translate(618.98 512.08)"
    >
      <tspan x={0} y={0}>
        {props.windDeflectorWD}
      </tspan>
    </text>
    <text
      style={{
        letterSpacing: '-.06em',
        fontSize: '27.5px',
        fontFamily: 'ArialMT,Arial',
        fill: '#1e1e1c',
      }}
      transform="translate(1043.72 620.59)"
    >
      <tspan x={0} y={0}>
        {props.distanceNRWGToRidgeFA}
      </tspan>
    </text>
    <text
      className="Einzelklappe_Klapp_mit_3_WLW_svg__cls-26"
      transform="translate(1282.93 727.03)"
    >
      <tspan x={0} y={0}>
        {'\u03B1'}
      </tspan>
    </text>
    <path
      d="M1262.98 742.64c1.63-21.03 8.23-40.67 18.63-57.75"
      className="Einzelklappe_Klapp_mit_3_WLW_svg__cls-12"
    />
    <path
      d="m1266.02 740.81-3.43 10.69-2.38-10.97 5.81.28zM1282.99 688.17l3.48-10.67-8.35 7.5 4.87 3.17z"
      className="Einzelklappe_Klapp_mit_3_WLW_svg__cls-23"
    />
    <path
      d="m1022.03 497.35-1.46 2.02"
      className="Einzelklappe_Klapp_mit_3_WLW_svg__cls-11"
    />
    <path
      d="m1017.54 503.55-53.01 73.23"
      style={{
        strokeDasharray: '0 0 5.17 5.17',
        strokeWidth: '.75px',
        strokeMiterlimit: 10,
        fill: 'none',
        stroke: '#1e1e1c',
      }}
    />
    <path
      d="m963.02 578.87-1.47 2.02"
      className="Einzelklappe_Klapp_mit_3_WLW_svg__cls-11"
    />
    <path
      d="m951.34 560.31 9.57 6.76"
      className="Einzelklappe_Klapp_mit_3_WLW_svg__cls-12"
    />
    <path
      d="m957.61 568.3 10.53 3.89-7.17-8.63-3.36 4.74z"
      className="Einzelklappe_Klapp_mit_3_WLW_svg__cls-23"
    />
    <path
      d="m1053.97 520.09-1.45 2.04"
      className="Einzelklappe_Klapp_mit_3_WLW_svg__cls-11"
    />
    <path
      d="m1049.55 526.3-51.93 73.09"
      style={{
        strokeDasharray: '0 0 5.12 5.12',
        strokeWidth: '.75px',
        strokeMiterlimit: 10,
        fill: 'none',
        stroke: '#1e1e1c',
      }}
    />
    <path
      d="m996.14 601.48-1.45 2.03"
      className="Einzelklappe_Klapp_mit_3_WLW_svg__cls-11"
    />
    <path
      d="m1007.41 600.09 33 23.35h40"
      className="Einzelklappe_Klapp_mit_3_WLW_svg__cls-12"
    />
    <path
      d="m1007.34 603.6-7.17-8.63 10.53 3.89-3.36 4.74z"
      className="Einzelklappe_Klapp_mit_3_WLW_svg__cls-23"
    />
    <path
      d="m1269.33 674.63-6.74 9.32"
      className="Einzelklappe_Klapp_mit_3_WLW_svg__cls-12"
    />
    <path
      d="m1265.82 674.53 8.71-7.08-4 10.49-4.71-3.41z"
      className="Einzelklappe_Klapp_mit_3_WLW_svg__cls-23"
    />
    <path
      d="m1293.4 641.39 22.48-31.06h70.62"
      className="Einzelklappe_Klapp_mit_3_WLW_svg__cls-12"
    />
    <path
      d="m1296.92 641.48-8.71 7.08 4-10.48 4.71 3.4z"
      className="Einzelklappe_Klapp_mit_3_WLW_svg__cls-23"
    />
    <path
      d="m1276.12 639.25 20.72 14.66"
      className="Einzelklappe_Klapp_mit_3_WLW_svg__cls-29"
    />
    <path
      d="m1276.12 639.25 2.04 1.45"
      className="Einzelklappe_Klapp_mit_3_WLW_svg__cls-11"
    />
    <path
      d="m1281.49 643.05 11.64 8.24"
      style={{
        strokeDasharray: '0 0 4.08 4.08',
        strokeWidth: '.75px',
        strokeMiterlimit: 10,
        fill: 'none',
        stroke: '#1e1e1c',
      }}
    />
    <path
      d="m1294.79 652.46 2.05 1.45"
      className="Einzelklappe_Klapp_mit_3_WLW_svg__cls-11"
    />
    <text
      className="Einzelklappe_Klapp_mit_3_WLW_svg__cls-26"
      transform="translate(1332.02 596.42)"
    >
      <tspan x={0} y={0}>
        {props.heightH}
      </tspan>
    </text>
    <text
      style={{
        fontSize: 35,
        fontFamily: 'ArialMT,Arial',
        fill: '#1e1e1c',
      }}
      transform="matrix(.58 0 0 .58 1351.88 608.08)"
    >
      <tspan x={0} y={0}>
        {props.windDeflectorWD}
      </tspan>
    </text>
    <text
      className="Einzelklappe_Klapp_mit_3_WLW_svg__cls-26"
      transform="translate(1327.66 523.18)"
    >
      <tspan x={0} y={0}>
        {props.windDeflectorWD}
      </tspan>
    </text>
    <path
      d="m1238.66 628.8 77.22-97.18"
      className="Einzelklappe_Klapp_mit_3_WLW_svg__cls-23"
    />
    <path
      d="m1240.88 626.01 75-94.39"
      className="Einzelklappe_Klapp_mit_3_WLW_svg__cls-12"
    />
    <path
      d="M1243.94 628.2c-1.29 1.63-3.66 1.9-5.28.6s-1.9-3.66-.6-5.28c1.29-1.63 3.66-1.9 5.28-.6s1.9 3.66.6 5.28Z"
      className="Einzelklappe_Klapp_mit_3_WLW_svg__cls-23"
    />
    <path
      d="M1315.88 531.62h66.56"
      style={{
        fill: '#1e1e1c',
        strokeMiterlimit: 10,
        stroke: '#1e1e1c',
      }}
    />
    <path
      d="m604.11 516.1-202.54 35.43"
      className="Einzelklappe_Klapp_mit_3_WLW_svg__cls-7"
    />
    <path
      d="M401.11 547.8a3.768 3.768 0 0 0-3.06 4.35 3.768 3.768 0 0 0 4.35 3.06c2.05-.36 3.41-2.31 3.06-4.35s-2.31-3.41-4.35-3.06Z"
      className="Einzelklappe_Klapp_mit_3_WLW_svg__cls-23"
    />
    <text
      style={{
        fontSize: '27.5px',
        fontFamily: 'ArialMT,Arial',
        fill: '#1e1e1c',
      }}
      transform="rotate(65.18 -347.878 611.834)"
    >
      <tspan
        x={0}
        y={0}
        style={{
          letterSpacing: '-.07em',
        }}
      >
        {props.distanceNRWGToEavesTA}
      </tspan>
    </text>
    <path
      d="m346.11 599.22 65.94 139.29"
      className="Einzelklappe_Klapp_mit_3_WLW_svg__cls-12"
    />
    <path
      d="m344.33 602.26-2.01-11.04 7.26 8.55-5.25 2.49zM408.57 737.96l7.27 8.55-2.01-11.04-5.26 2.49z"
      className="Einzelklappe_Klapp_mit_3_WLW_svg__cls-23"
    />
    <path
      d="m348.55 544.26 254.22-27.93-155-14.77"
      className="Einzelklappe_Klapp_mit_3_WLW_svg__cls-7"
    />
    <path
      d="M349.15 547.97a3.762 3.762 0 1 1-.82-7.48 3.762 3.762 0 1 1 .82 7.48ZM448.32 497.83a3.76 3.76 0 0 0-4.1 3.39 3.76 3.76 0 0 0 3.39 4.1c2.07.2 3.9-1.32 4.1-3.39a3.76 3.76 0 0 0-3.39-4.1Z"
      className="Einzelklappe_Klapp_mit_3_WLW_svg__cls-23"
    />
  </svg>
);
export default SvgEinzelklappeKlappMit3Wlw;
