import React, { FC, ReactElement, ReactNode, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RequestTypes } from './../../../redux/httpClient';
import { IState, MyCalcThunkDispatch } from './../../../redux/store';
import { Column } from './../Column';
import './DynamicSizedColumn.scss';
import { useSelectedWindow } from '../../../hooks/selectorHooks';
import ResultItem, { ResultItemStyle } from './../../ResultItem';
import { ReactComponent as IconPlus } from '../../../assets/icons/IconPlus.svg';
import { changeCalculationParameter } from '../../../redux/parametersActions';
import { ParametersState } from '../../../redux/parametersReducer';
import { Parameters } from './../../Parameters/ParameterValue';
import {
  TestParameterWithInfo,
  TestSearchOptions,
  TRANSLATED_TEST_SEARCH_OPTION_VALUES,
} from '../../../redux/nrwgReducer';
import _ from 'lodash';
import { ValueKey } from '../../../redux/valueKey';
import { FormattedMessage, useIntl } from 'react-intl';
import { RangeOfApplication } from '../../../redux/constants';
import { ReactComponent as MeasureIcon } from '../../../assets/icons/MeasureIcon.svg';
import { ReactComponent as AdditionalIcon } from '../../../assets/icons/AdditionalIcon.svg';
import { ReactComponent as CheckmarkIcon } from '../../../assets/icons/CheckmarkIcon.svg';
import { ReactComponent as WindowIcon } from '../../../assets/icons/WindowIcon.svg';
import classNames from 'classnames';
import { useTranslateConditionally } from '../../../lib/utils';
import { AnyAction } from 'redux';
import InformationDialog from '../../InformationDialog';

const TRANSLATED_COLUMNS = [
  ValueKey.VALUEKEY_OPENING_DIRECTION.valueOf(),
  ValueKey.APPLICATION.valueOf(),
  ValueKey.AERODYNAMIC_CASE.valueOf(),
  ValueKey.MOUNTING_TYPE.valueOf(),
  ValueKey.VALUEKEY_DRIVE_POSITION.valueOf(),
  ValueKey.VALUEKEY_ASSEMBLY_TYPE.valueOf(),
];

const FACADE_COLUMN_ORDER = [
  ValueKey.VALUEKEY_OPENING_DIRECTION,
  ValueKey.PROFILE_SYSTEM,
  ValueKey.PROFILE_SERIES,
  ValueKey.PROFILE_SASH,
  ValueKey.PROFILE_FRAME,
  ValueKey.PROFILE_EXCHANGE,
  ValueKey.PROFILE_BASE,
  ValueKey.APPLICATION,
  ValueKey.AERODYNAMIC_CASE,
  ValueKey.MOUNTING_TYPE,
  ValueKey.VALUEKEY_DRIVE_POSITION,
  ValueKey.VALUEKEY_ASSEMBLY_TYPE,
];

const ROOF_COLUMN_ORDER = [
  ValueKey.PROFILE_SYSTEM,
  ValueKey.PROFILE_SERIES,
  ValueKey.PROFILE_SASH,
  ValueKey.PROFILE_FRAME,
  ValueKey.APPLICATION,
  ValueKey.AERODYNAMIC_CASE,
  ValueKey.VALUEKEY_DRIVE_POSITION,
];

const ICONS: Record<string, ReactNode> = {
  TYPE_ASSEMBLY_SPACE: <MeasureIcon />,
  TYPE_BASE_PROFILE_POSSIBLE: <AdditionalIcon />,
  TYPE_WITH_EXCHANGE_PROFILE: <CheckmarkIcon />,
  TYPE_FACADE: <WindowIcon />,
};

const NAME_TO_ID_PARAMTER_MAPPING: { [index: string]: ValueKey } = {
  profileSystem: ValueKey.PROFILE_SYSTEM_ID,
  profileSeries: ValueKey.PROFILE_SERIES_ID,
  profileSash: ValueKey.PROFILE_SASH_ID,
  profileFrame: ValueKey.PROFILE_FRAME_ID,
  profileExchange: ValueKey.PROFILE_EXCHANGE_ID,
  profileBase: ValueKey.PROFILE_BASE_ID,
};

const WITH_LABEL = ['TYPE_ASSEMBLY_SPACE'];

function edgeVersion(): number | undefined {
  const uaString = navigator.userAgent;
  const match = /\b(MSIE |Trident.*?rv:|Edge\/)(\d+)/.exec(uaString);
  return match ? parseInt(match[2]) : undefined;
}

const LoadingState: FC<React.PropsWithChildren<unknown>> = () => {
  return (
    <>
      <ResultItem
        propertiesStyle={ResultItemStyle.WITHOUT_ICONS}
        loading={true}
        name="."
      />
      <ResultItem
        propertiesStyle={ResultItemStyle.WITHOUT_ICONS}
        loading={true}
        name="."
      />
      <ResultItem
        propertiesStyle={ResultItemStyle.WITHOUT_ICONS}
        loading={true}
        name="."
      />
    </>
  );
};

function InfoModalMountingType(): ReactElement {
  return (
    <InformationDialog
      dialogIsForValueKey={ValueKey.MOUNTING_TYPE}
      heading="MOUNTING_TYPE"
      help={[
        {
          heading: 'INFORMATION_DIALOG_HEADING_POST_AND_BEEM',
          image: 'mountingTypes/schnitt_pfosten-riegel_a01.png',
        },
        {
          heading: 'INFORMATION_DIALOG_HEADING_PUNCHED_WINDOW_WITH_EMBRASURE',
          image: 'mountingTypes/schnitt_lochfenster_mit_laibung_a01.png',
        },
        {
          heading:
            'INFORMATION_DIALOG_HEADING_PUNCHED_WINDOW_WITH_OUT_EMBRASURE',
          image: 'mountingTypes/schnitt_lochfenster_ohne_laibung_a01.png',
        },
      ]}
    />
  );
}

export const DynamicSizedColumn: FC<React.PropsWithChildren<unknown>> = () => {
  const testSearchOptions = useSelector<IState, TestSearchOptions>(
    (s: IState) => s.nrwg.testSearchOptions,
  );
  const [columnWidth, setColumnWidth] = useState(0);

  const calculating = useSelector<IState, boolean>(
    (s: IState) => s.ui.calculating[RequestTypes.NRWG],
  );
  const dispatch: MyCalcThunkDispatch<AnyAction> = useDispatch();
  const selectedWindow = useSelectedWindow();
  const calculationParameters = useSelector<IState, ParametersState>(
    s => s.parameters,
  );
  const { formatMessage } = useIntl();
  const translateConditionally = useTranslateConditionally();

  function getColumns(): ValueKey[] {
    return calculationParameters.rangeOfApplication.value ===
      RangeOfApplication.FACADE
      ? FACADE_COLUMN_ORDER
      : ROOF_COLUMN_ORDER;
  }

  function getRenderedColumns(): ValueKey[] {
    return getColumns().filter(c => {
      const testCategoryValues = testSearchOptions[c].sort(
        compareTestSearchOptions,
      );

      if (testCategoryValues.length === 0) {
        return false;
      }

      return (
        isNRWG &&
        (calculationParameters[c].value === Parameters.NO_SELECTION ||
          calculationParameters[c].value ===
            Parameters.NO_SELECTION_SYSTEM_SERIES ||
          calculationParameters[c].value === Parameters.ENTRY_REQUIRED)
      );
    });
  }

  const isNRWG = selectedWindow?.nrwg;

  function getName(option: string | TestParameterWithInfo): string {
    return Object.prototype.hasOwnProperty.call(option, 'value')
      ? (option as TestParameterWithInfo).value
      : (option as string);
  }

  function getId(option: string | TestParameterWithInfo): string | undefined {
    return Object.prototype.hasOwnProperty.call(option, 'value')
      ? (option as TestParameterWithInfo).id
      : undefined;
  }

  function translateName(testCategoryKey: string, name: string): string {
    return TRANSLATED_COLUMNS.includes(testCategoryKey) ||
      TRANSLATED_TEST_SEARCH_OPTION_VALUES.includes(name)
      ? formatMessage({
          id: name,
        })
      : name;
  }

  function label(typeOrValue: string): ReactNode {
    return WITH_LABEL.includes(typeOrValue) ? (
      <>
        <FormattedMessage id={typeOrValue} />:
      </>
    ) : (
      ''
    );
  }

  function formatInfoItem(infoItem: string): ReactNode {
    const [typeOrValue, value, active, translated] = infoItem.split(';');

    function boolean(string: string): boolean {
      return string === 'true';
    }

    return (
      <div
        className={classNames('dynamic-sized-column__info-item', {
          'dynamic-sized-column__info-item--active': boolean(active),
        })}
        key={infoItem}
      >
        {ICONS[typeOrValue]} {label(typeOrValue)}{' '}
        {translateConditionally(value, boolean(translated))}
      </div>
    );
  }

  function formatInfo(option: string | TestParameterWithInfo): ReactNode {
    if (!Object.prototype.hasOwnProperty.call(option, 'info')) {
      return null;
    }

    const info = (option as TestParameterWithInfo).info;

    if (info.length === 0) {
      return null;
    }

    return (
      <div className="dynamic-sized-column__info-items">
        {info.map(formatInfoItem)}
      </div>
    );
  }

  function extractNameFromTestSearchOption(
    option: string | TestParameterWithInfo,
  ): string {
    return Object.prototype.hasOwnProperty.call(option, 'info')
      ? (option as TestParameterWithInfo).value
      : (option as string);
  }

  function compareTestSearchOptions(
    a: string | TestParameterWithInfo,
    b: string | TestParameterWithInfo,
  ): number {
    const nameA = extractNameFromTestSearchOption(a);
    const nameB = extractNameFromTestSearchOption(b);

    return nameA.localeCompare(nameB);
  }

  function updateParameters(
    key: ValueKey,
    name: string,
    id: string | undefined,
  ): void {
    dispatch(changeCalculationParameter(key, name));

    if (id) {
      dispatch(
        changeCalculationParameter(NAME_TO_ID_PARAMTER_MAPPING[key], id),
      );
    }
  }

  return (
    <div
      className={classNames('dynamic-sized-column', {
        'dynamic-sized-column--for-old-browsers':
          edgeVersion() && edgeVersion()! < 79,
      })}
      style={{ width: `${getRenderedColumns().length * columnWidth}px` }}
    >
      {getRenderedColumns().map((testCategoryKey: string) => {
        const testCategoryValues = testSearchOptions[testCategoryKey].sort(
          compareTestSearchOptions,
        );

        return (
          <Column
            columnRenderedCallback={(d: HTMLDivElement) => {
              d && setColumnWidth(d.clientWidth);
            }}
            loading={calculating}
            key={testCategoryKey}
            heading={_.snakeCase(testCategoryKey).toUpperCase()}
            className=""
            infoModal={
              testCategoryKey === ValueKey.MOUNTING_TYPE ? (
                <InfoModalMountingType />
              ) : null
            }
          >
            {calculating ? (
              <LoadingState />
            ) : (
              // @ts-ignore
              testCategoryValues.map(
                (option: string | TestParameterWithInfo) => {
                  const name = getName(option);
                  const id = getId(option);
                  return (
                    <ResultItem
                      key={name}
                      loading={false}
                      name={translateName(testCategoryKey, name)}
                      actionIcon={<IconPlus />}
                      onClick={() => {
                        updateParameters(testCategoryKey as ValueKey, name, id);
                      }}
                      propertiesStyle={ResultItemStyle.WITH_ICONS}
                    >
                      {formatInfo(option)}
                    </ResultItem>
                  );
                },
              )
            )}
          </Column>
        );
      })}{' '}
    </div>
  );
};
