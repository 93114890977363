import * as React from 'react';
// @ts-ignore
const SvgDoppelklappeKippKippSatteldachOhneWlw = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="Doppelklappe_Kipp_Kipp_Satteldach_ohne_WLW_svg__Ebene_2"
    data-name="Ebene 2"
    viewBox="0 0 1440 1080"
    {...props}
    style={{ marginBottom: '-70px', marginTop: '-50px' }}
  >
    <defs>
      <style>
        {
          '.Doppelklappe_Kipp_Kipp_Satteldach_ohne_WLW_svg__cls-3,.Doppelklappe_Kipp_Kipp_Satteldach_ohne_WLW_svg__cls-4,.Doppelklappe_Kipp_Kipp_Satteldach_ohne_WLW_svg__cls-6,.Doppelklappe_Kipp_Kipp_Satteldach_ohne_WLW_svg__cls-7,.Doppelklappe_Kipp_Kipp_Satteldach_ohne_WLW_svg__cls-8{stroke:#1e1e1c;fill:none}.Doppelklappe_Kipp_Kipp_Satteldach_ohne_WLW_svg__cls-4,.Doppelklappe_Kipp_Kipp_Satteldach_ohne_WLW_svg__cls-6,.Doppelklappe_Kipp_Kipp_Satteldach_ohne_WLW_svg__cls-7,.Doppelklappe_Kipp_Kipp_Satteldach_ohne_WLW_svg__cls-8{stroke-miterlimit:10}.Doppelklappe_Kipp_Kipp_Satteldach_ohne_WLW_svg__cls-4,.Doppelklappe_Kipp_Kipp_Satteldach_ohne_WLW_svg__cls-7{stroke-width:.75px}.Doppelklappe_Kipp_Kipp_Satteldach_ohne_WLW_svg__cls-10{font-size:35px}.Doppelklappe_Kipp_Kipp_Satteldach_ohne_WLW_svg__cls-10,.Doppelklappe_Kipp_Kipp_Satteldach_ohne_WLW_svg__cls-14,.Doppelklappe_Kipp_Kipp_Satteldach_ohne_WLW_svg__cls-16{fill:#1e1e1c}.Doppelklappe_Kipp_Kipp_Satteldach_ohne_WLW_svg__cls-10,.Doppelklappe_Kipp_Kipp_Satteldach_ohne_WLW_svg__cls-16{font-family:ArialMT,Arial}.Doppelklappe_Kipp_Kipp_Satteldach_ohne_WLW_svg__cls-3{stroke-linejoin:round}.Doppelklappe_Kipp_Kipp_Satteldach_ohne_WLW_svg__cls-3,.Doppelklappe_Kipp_Kipp_Satteldach_ohne_WLW_svg__cls-6{stroke-width:2px}.Doppelklappe_Kipp_Kipp_Satteldach_ohne_WLW_svg__cls-4{stroke-dasharray:0 5}.Doppelklappe_Kipp_Kipp_Satteldach_ohne_WLW_svg__cls-16{font-size:27.5px}.Doppelklappe_Kipp_Kipp_Satteldach_ohne_WLW_svg__cls-19{fill:#ececec}'
        }
      </style>
    </defs>
    <path
      d="M367.71 937.59 736.1 676.94l368.38 260.65H367.71z"
      className="Doppelklappe_Kipp_Kipp_Satteldach_ohne_WLW_svg__cls-19"
    />
    <path
      d="M623.61 261.24 817.15 532.2l90.97-45-93.63-141.53-37.98 19.11-92.42-128.22 97.26-48.87 90.97 130.16-57.83 27.82 93.63 141.53 112.74-57.09-194.51-270.97-202.74 102.1z"
      style={{
        fill: '#b0b0b1',
      }}
    />
    <path
      d="m451.36 347.79 172.62-86.55L817.52 532.2 451.36 347.79z"
      className="Doppelklappe_Kipp_Kipp_Satteldach_ohne_WLW_svg__cls-19"
    />
    <path
      d="m584.97 278.32 7.25-108.45 83.71-41.95-9.39 108.95"
      style={{
        fill: '#d8d9d9',
        opacity: 0.3,
      }}
    />
    <path
      d="M367.71 937.59h736.77L736.1 676.94 367.71 937.59zM601.44 685.64 518.22 830.8M870.74 685.64l83.23 145.16"
      className="Doppelklappe_Kipp_Kipp_Satteldach_ohne_WLW_svg__cls-6"
    />
    <path
      d="m451.78 347.36 171.78-86.12L817.1 532.2 451.78 347.36zM623.61 261.24l202.74-102.1 194.51 270.97L817.15 532.2"
      className="Doppelklappe_Kipp_Kipp_Satteldach_ohne_WLW_svg__cls-3"
    />
    <path
      d="m684.09 236.56 97.26-48.87 90.97 130.16-95.81 46.93-92.42-128.22z"
      className="Doppelklappe_Kipp_Kipp_Satteldach_ohne_WLW_svg__cls-6"
    />
    <path
      d="m776.51 364.78-10.89-137.01 95.22-47.48 11.48 137.56-95.81 46.93z"
      style={{
        fill: '#d8d9d9',
        opacity: 0.3,
        strokeWidth: '2.5px',
        strokeLinejoin: 'round',
        stroke: '#1e1e1c',
      }}
    />
    <path
      d="m776.51 364.78-10.89-137.01 95.22-47.48 11.48 137.56-95.81 46.93z"
      className="Doppelklappe_Kipp_Kipp_Satteldach_ohne_WLW_svg__cls-3"
    />
    <path
      d="m583.12 281.65 9.1-111.78 83.71-41.95-8.65 111.32"
      className="Doppelklappe_Kipp_Kipp_Satteldach_ohne_WLW_svg__cls-6"
    />
    <text
      className="Doppelklappe_Kipp_Kipp_Satteldach_ohne_WLW_svg__cls-16"
      transform="translate(707.32 622.53)"
    >
      <tspan x={0} y={0}>
        {props.sashFrameDistanceFRA}
      </tspan>
    </text>
    <text
      className="Doppelklappe_Kipp_Kipp_Satteldach_ohne_WLW_svg__cls-16"
      transform="translate(696.7 846.59)"
    >
      <tspan x={0} y={0}>
        {props.heightH}
      </tspan>
    </text>
    <text
      className="Doppelklappe_Kipp_Kipp_Satteldach_ohne_WLW_svg__cls-10"
      transform="matrix(.58 0 0 .58 716.56 858.24)"
    >
      <tspan x={0} y={0}>
        {props.doubleFlapFRZK}
      </tspan>
    </text>
    <path
      d="M716.93 682.67v-54.7M754.79 682.67v-54.7"
      className="Doppelklappe_Kipp_Kipp_Satteldach_ohne_WLW_svg__cls-4"
    />
    <path
      d="M763.65 641.19h35.82"
      className="Doppelklappe_Kipp_Kipp_Satteldach_ohne_WLW_svg__cls-8"
    />
    <path
      d="m765.63 644.1-10.84-2.9 10.84-2.91v5.81z"
      className="Doppelklappe_Kipp_Kipp_Satteldach_ohne_WLW_svg__cls-14"
    />
    <path
      d="M527.08 863.13h415.87"
      className="Doppelklappe_Kipp_Kipp_Satteldach_ohne_WLW_svg__cls-8"
    />
    <path
      d="m529.06 866.03-10.84-2.9 10.84-2.91v5.81zM940.97 866.03l10.84-2.9-10.84-2.91v5.81z"
      className="Doppelklappe_Kipp_Kipp_Satteldach_ohne_WLW_svg__cls-14"
    />
    <path
      d="M708.29 641.19h-35.82"
      className="Doppelklappe_Kipp_Kipp_Satteldach_ohne_WLW_svg__cls-8"
    />
    <path
      d="m706.3 638.29 10.85 2.9-10.85 2.91v-5.81z"
      className="Doppelklappe_Kipp_Kipp_Satteldach_ohne_WLW_svg__cls-14"
    />
    <path
      d="M518.22 889.03v-54.7M953.97 889.03v-54.7M955.46 827.52l29.97-38.32M1108.49 937.59l30.68-39.21M515.78 827.52l-30.18-38.59M362.54 937.32l-30.68-39.21"
      className="Doppelklappe_Kipp_Kipp_Satteldach_ohne_WLW_svg__cls-4"
    />
    <path
      d="m986.97 802.35 139.04 98.49"
      className="Doppelklappe_Kipp_Kipp_Satteldach_ohne_WLW_svg__cls-8"
    />
    <path
      d="m986.91 805.86-7.17-8.63 10.52 3.89-3.35 4.74zM1122.72 902.06l10.52 3.9-7.17-8.64-3.35 4.74z"
      className="Doppelklappe_Kipp_Kipp_Satteldach_ohne_WLW_svg__cls-14"
    />
    <path
      d="m487.02 805.23-139.05 98.49"
      className="Doppelklappe_Kipp_Kipp_Satteldach_ohne_WLW_svg__cls-8"
    />
    <path
      d="m483.72 804 10.52-3.89-7.16 8.63-3.36-4.74zM347.91 900.2l-7.17 8.64 10.53-3.9-3.36-4.74z"
      className="Doppelklappe_Kipp_Kipp_Satteldach_ohne_WLW_svg__cls-14"
    />
    <text
      className="Doppelklappe_Kipp_Kipp_Satteldach_ohne_WLW_svg__cls-16"
      transform="rotate(-34.29 1596.563 -146.973)"
    >
      <tspan x={0} y={0}>
        min. {props.heightH}
      </tspan>
    </text>
    <text
      className="Doppelklappe_Kipp_Kipp_Satteldach_ohne_WLW_svg__cls-10"
      transform="rotate(-34.29 1575.73 -281.572) scale(.58)"
    >
      <tspan x={0} y={0}>
        {props.frameFR}
      </tspan>
    </text>
    <text
      className="Doppelklappe_Kipp_Kipp_Satteldach_ohne_WLW_svg__cls-16"
      transform="rotate(35.03 -771.123 2024.315)"
    >
      <tspan x={0} y={0}>
        min. {props.heightH}
      </tspan>
    </text>
    <text
      className="Doppelklappe_Kipp_Kipp_Satteldach_ohne_WLW_svg__cls-10"
      transform="rotate(35.03 -829.32 2144.353) scale(.58)"
    >
      <tspan x={0} y={0}>
        {props.frameFR}
      </tspan>
    </text>
    <text
      style={{
        fontSize: '27.5px',
        fontFamily: 'ArialMT,Arial',
        fill: '#1e1e1c',
      }}
      transform="rotate(-27.36 1204.021 -1512.325)"
    >
      <tspan x={0} y={0}>
        {props.widthB}
      </tspan>
    </text>
    <text
      style={{
        fontSize: 30,
        fontFamily: 'ArialMT,Arial',
        fill: '#1e1e1c',
      }}
      transform="rotate(-27.36 1215.365 -1554.996) scale(.58)"
    >
      <tspan x={0} y={0}>
        {props.frameFR}
      </tspan>
    </text>
    <text
      style={{
        fontSize: '27.5px',
        fontFamily: 'ArialMT,Arial',
        fill: '#1e1e1c',
      }}
      transform="rotate(53.49 36.97 812.185)"
    >
      <tspan x={0} y={0}>
        {props.heightH}
      </tspan>
    </text>
    <text
      style={{
        fontSize: 30,
        fontFamily: 'ArialMT,Arial',
        fill: '#1e1e1c',
      }}
      transform="rotate(53.49 17.136 826.895) scale(.58)"
    >
      <tspan x={0} y={0}>
        {props.frameFR}
      </tspan>
    </text>
    <text
      className="Doppelklappe_Kipp_Kipp_Satteldach_ohne_WLW_svg__cls-16"
      transform="translate(696.95 441.33)"
    >
      <tspan x={0} y={0}>
        {'\u03B1'}
      </tspan>
    </text>
    <path
      d="M668.28 446.67a131.691 131.691 0 0 1 45.52-45.66"
      className="Doppelklappe_Kipp_Kipp_Satteldach_ohne_WLW_svg__cls-8"
    />
    <path
      d="m671.81 446.37-7.8 8.07 2.72-10.89 5.08 2.82zM713.5 404.55l8.04-7.83-10.88 2.76 2.84 5.07z"
      className="Doppelklappe_Kipp_Kipp_Satteldach_ohne_WLW_svg__cls-14"
    />
    <path
      d="m795.47 376.77 79.67-40.19"
      className="Doppelklappe_Kipp_Kipp_Satteldach_ohne_WLW_svg__cls-8"
    />
    <path
      d="m798.54 378.47-10.98 2.29 8.37-7.48 2.61 5.19zM874.68 340.07l8.37-7.48-10.99 2.29 2.62 5.19z"
      className="Doppelklappe_Kipp_Kipp_Satteldach_ohne_WLW_svg__cls-14"
    />
    <path
      d="m777.88 367.27 1.46 2.03"
      className="Doppelklappe_Kipp_Kipp_Satteldach_ohne_WLW_svg__cls-7"
    />
    <path
      d="m782 373.02 9.35 13.02"
      style={{
        strokeWidth: '.75px',
        strokeMiterlimit: 10,
        fill: 'none',
        stroke: '#1e1e1c',
        strokeDasharray: '0 0 4.58 4.58',
      }}
    />
    <path
      d="m792.68 387.9 1.46 2.03"
      className="Doppelklappe_Kipp_Kipp_Satteldach_ohne_WLW_svg__cls-7"
    />
    <path
      d="m663.84 256.19 82.12 114.47"
      className="Doppelklappe_Kipp_Kipp_Satteldach_ohne_WLW_svg__cls-8"
    />
    <path
      d="m662.63 259.49-3.96-10.5 8.68 7.11-4.72 3.39zM742.44 370.74l8.68 7.12-3.95-10.5-4.73 3.38z"
      className="Doppelklappe_Kipp_Kipp_Satteldach_ohne_WLW_svg__cls-14"
    />
    <path
      d="m874.15 320.18 1.45 2.03"
      className="Doppelklappe_Kipp_Kipp_Satteldach_ohne_WLW_svg__cls-7"
    />
    <path
      d="m878.12 325.72 8.8 12.27"
      style={{
        strokeDasharray: '0 0 4.31 4.31',
        strokeWidth: '.75px',
        strokeMiterlimit: 10,
        fill: 'none',
        stroke: '#1e1e1c',
      }}
    />
    <path
      d="m888.18 339.74 1.45 2.03"
      className="Doppelklappe_Kipp_Kipp_Satteldach_ohne_WLW_svg__cls-7"
    />
    <path
      d="m649.49 253.62 32.51-16.4M741.74 382.59l32.07-16.17"
      className="Doppelklappe_Kipp_Kipp_Satteldach_ohne_WLW_svg__cls-4"
    />
  </svg>
);
export default SvgDoppelklappeKippKippSatteldachOhneWlw;
