import React, { FC, ReactNode } from 'react';
import EditableBox from './EditableBox';
import './TabBarBoxWithValues.scss';
import NumberField from './NumberField';
import {
  ChangeCalculationAction,
  changeCalculationParameter,
} from '../../../redux/parametersActions';
import { useDispatch, useSelector } from 'react-redux';
import { IState, MyCalcThunkDispatch } from '../../../redux/store';
import { FormattedMessage, useIntl } from 'react-intl';
import ParameterValue from '../ParameterValue';
import { Options, ParameterBoxRange } from '../ParametersColumn';
import flatMap from 'array.prototype.flatmap';
import TabBar from './TabBar';
import {
  Help,
  RangeMessages,
  SaveComponentLeadingValueKeyAction,
} from '../ParameterSection';
import { ValueKey } from '../../../redux/valueKey';
import { useSelectedWindow } from '../../../hooks/selectorHooks';
import { AnyAction } from 'redux';

flatMap.shim();

interface TabBarWithValuesProps {
  valueKey: ValueKey;
  options: Options;
  saveComponentLeadingValueKeyAction: SaveComponentLeadingValueKeyAction;
  name: string;
  hint?: (state: IState) => string;
  selectedValueKey: string;
  rangeMessages?: (state: IState) => RangeMessages;
  rangeComponents?: (state: IState) => ReactNode[];
  children?: ReactNode;
  withoutRanges?: (s: IState) => boolean;
}

interface TabBarProps extends TabBarWithValuesProps {
  name: string;
  isSingleEdit: boolean;
  helpContent: Help;
}

interface TabBarWithValuesInputProps {
  name: string;
  currentValueKey: ValueKey;
  selectedValueKey: ParameterValue;
  currentValue: ParameterValue;
  range: (state: IState) => ParameterBoxRange;
  onChange: (newValue: number | undefined) => ChangeCalculationAction;
  unit: string | undefined;
  index: number;
  withSmallInputField?: boolean;
}

const TabBarWithValuesInputField: FC<
  React.PropsWithChildren<TabBarWithValuesInputProps>
> = (props: TabBarWithValuesInputProps) => {
  const dispatch: MyCalcThunkDispatch<AnyAction> = useDispatch();
  const state = useSelector((state: IState) => state);
  const selectedWindow = useSelectedWindow();
  const { formatMessage } = useIntl();

  function getValue(value: string): number | undefined {
    if (selectedWindow?.nrwg) {
      return value ? parseInt(value) : undefined;
    }

    return value ? parseInt(value) : 0;
  }

  function disabled(): boolean {
    return (
      !!props.selectedValueKey.value &&
      props.selectedValueKey.value.toString() !==
        props.currentValueKey.toString()
    );
  }

  return (
    <>
      <NumberField
        key={`${props.name}_${props.index}_${
          props.selectedValueKey?.value?.toString() || ''
        }`}
        value={props.currentValue.toNumber()}
        hideRange={true}
        name={`${props.name}_${props.index}`}
        range={props.range(state)}
        onChange={(newValue: string) => {
          dispatch(props.onChange(getValue(newValue)));
        }}
        disabledByUIState={disabled()}
        valueKey={props.currentValueKey}
        small={props.withSmallInputField}
      />
      <div className="tab-bar-with-values__unit">
        {props.unit
          ? formatMessage({ id: props.unit })
          : props.currentValue.unit}
      </div>
    </>
  );
};

const TabBarWithValues: FC<React.PropsWithChildren<TabBarWithValuesProps>> = (
  props: TabBarWithValuesProps,
) => {
  const { formatMessage } = useIntl();
  const state = useSelector((state: IState) => state);
  return (
    <>
      <TabBar
        {...props}
        options={props.options.map(o => {
          return { name: o.name, key: o.valueKey || '' };
        })}
        key={props.name}
      />
      {props.children}
      {props.hint && (
        <div
          className="tab-bar-with-values__annotation"
          dangerouslySetInnerHTML={{
            __html: formatMessage({ id: props.hint(state).toString() }),
          }}
        />
      )}
      {props.rangeMessages &&
        props.rangeMessages(state) &&
        props.rangeMessages(state)!.map(rm => (
          <div
            className={`tab-bar-with-values__range tab-bar-with-values__range--${rm.field}`}
            key={rm.id}
          >
            {!(props.withoutRanges && props.withoutRanges(state)) && (
              <FormattedMessage {...rm} />
            )}
          </div>
        ))}
      {props.rangeComponents && props.rangeComponents(state)}
    </>
  );
};

export const TabBarBoxWithValuesWrapper: FC<
  React.PropsWithChildren<TabBarProps>
> = (props: TabBarProps) => {
  const state = useSelector((state: IState) => state);
  return (
    <EditableBox {...props} heading={props.name}>
      <TabBarWithValues {...props}>{props.children}</TabBarWithValues>
    </EditableBox>
  );
};

export const TabBarBoxWithValuesMultipleInputs: FC<
  React.PropsWithChildren<TabBarProps>
> = (props: TabBarProps) => {
  return (
    <TabBarBoxWithValuesWrapper {...props}>
      <MultipleInputs {...props} />
    </TabBarBoxWithValuesWrapper>
  );
};

const MultipleInputs: FC<React.PropsWithChildren<TabBarWithValuesProps>> = (
  props: TabBarWithValuesProps,
) => {
  const state = useSelector((state: IState) => state);
  const selectedWindow = useSelectedWindow();

  return (
    <div
      className="tab-bar-with-values__input-box"
      key={state.parameters[props.selectedValueKey].value!.toString()}
    >
      <div className="tab-bar-with-values__row">
        {props.options
          .flatMap((option, index) => {
            return [
              <div
                className="tab-bar-with-values__input"
                key={
                  option.name +
                  'field' +
                  state.parameters[props.selectedValueKey].value!.toString()
                }
              >
                <TabBarWithValuesInputField
                  key={
                    option.name +
                    state.parameters[props.selectedValueKey].value!.toString()
                  }
                  index={index}
                  name={props.name}
                  currentValueKey={option.valueKey || ValueKey.VALUEKEY_NONE}
                  selectedValueKey={state.parameters[props.selectedValueKey]}
                  currentValue={state.parameters[option.valueKey || '']}
                  onChange={
                    option.onChangeDispatch
                      ? newValue =>
                          option.onChangeDispatch!(newValue, selectedWindow)
                      : newValue =>
                          changeCalculationParameter(option.valueKey!, newValue)
                  }
                  range={
                    option.range || ((state: IState) => [-Infinity, Infinity])
                  }
                  unit={option.unit}
                />
              </div>,
              <div
                key={props.name + '_icon'}
                className="tab-bar-with-values__chain-icon"
              >
                link
              </div>,
            ];
          })
          .splice(0, 3)}
      </div>
    </div>
  );
};

export const TabBarBoxWithValuesSingleInput: FC<
  React.PropsWithChildren<TabBarProps>
> = (props: TabBarProps) => {
  return (
    <TabBarBoxWithValuesWrapper {...props}>
      <SingleInput {...props} />
    </TabBarBoxWithValuesWrapper>
  );
};

const SingleInput: FC<React.PropsWithChildren<TabBarWithValuesProps>> = (
  props: TabBarWithValuesProps,
) => {
  const activeOption = useSelector((state: IState) => {
    return props.options.find(option => {
      return option.valueKey === state.parameters[props.selectedValueKey].value;
    });
  })!;
  const selectedValueKey = useSelector(
    (state: IState) => state.parameters[props.selectedValueKey],
  );
  const currentValue = useSelector(
    (state: IState) => state.parameters[activeOption.valueKey || ''],
  );
  const selectedWindow = useSelectedWindow();
  return (
    <TabBarWithValuesInputField
      key={activeOption.name + selectedValueKey.value!.toString()}
      index={1}
      name={props.name}
      currentValueKey={activeOption.valueKey || ValueKey.VALUEKEY_NONE}
      selectedValueKey={selectedValueKey}
      currentValue={currentValue}
      onChange={
        activeOption.onChangeDispatch
          ? newValue => activeOption.onChangeDispatch!(newValue, selectedWindow)
          : newValue =>
              changeCalculationParameter(activeOption.valueKey!, newValue)
      }
      range={activeOption.range || ((state: IState) => [-Infinity, Infinity])}
      unit={activeOption.unit}
      withSmallInputField={true}
    />
  );
};

export default TabBarBoxWithValuesWrapper;
