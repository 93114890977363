import React, { FC } from 'react';
import { Route, Routes } from 'react-router-dom';
import GeneralDrivesDataView from './GeneralDrivesDataView';
import { useSelector } from 'react-redux';
import { IState } from '../../redux/store';
import { LoggedInUser } from '../../redux/authenticationReducer';
import AdminNavLink from '../common/AdminNavLink';
import GeneralDriveFamiliesDataView from './GeneralDriveFamiliesDataView';

const TabNavGeneralData: FC<React.PropsWithChildren<unknown>> = () => {
  return (
    <div className="admin__tab-nav">
      <AdminNavLink to="/admin/general-data/drives">Antriebe</AdminNavLink>
      <AdminNavLink to="/admin/general-data/drive-families">
        Antriebsfamilien
      </AdminNavLink>
    </div>
  );
};

const GeneralDataArea: FC<React.PropsWithChildren<unknown>> = () => {
  const currentUser = useSelector<IState, LoggedInUser | undefined>(
    (state: IState) => state.authentication.currentUser,
  );

  if (!currentUser?.admin) {
    return null;
  }

  return (
    <div className="admin__main">
      <TabNavGeneralData />
      <Routes>
        <Route path="/drives/*" element={<GeneralDataDrives />} />
        <Route
          path="/drive-families/*"
          element={<GeneralDataDriveFamilies />}
        />
      </Routes>
    </div>
  );
};

const GeneralDataDrives: FC<React.PropsWithChildren<unknown>> = () => {
  return (
    <div>
      <GeneralDrivesDataView />
    </div>
  );
};

const GeneralDataDriveFamilies: FC<React.PropsWithChildren<unknown>> = () => {
  return (
    <div>
      <GeneralDriveFamiliesDataView />
    </div>
  );
};

export default GeneralDataArea;
