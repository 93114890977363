import React, { FC } from 'react';
import EditableBox, { EditableBoxProps } from './EditableBox';
import { FormattedMessage } from 'react-intl';
import NumberField from './NumberField';
import './RevealMeasureBox.scss';
import { ValueKey } from '../../../redux/valueKey';
import { useDispatch, useSelector } from 'react-redux';
import { IState, MyCalcThunkDispatch } from '../../../redux/store';
import { changeCalculationParameter } from '../../../redux/parametersActions';
import { GeometricArea } from '../../../redux/constants';
import { Parameters } from '../ParameterValue';
import { AnyAction } from 'redux';

const REVEAL_RANGE: [number, number] = [0, 500];

const RevealMeasuresBox: FC<
  React.PropsWithChildren<EditableBoxProps>
> = props => {
  const dispatch: MyCalcThunkDispatch<AnyAction> = useDispatch();
  const revealOppositeTheHinge = useSelector<
    IState,
    number | Parameters.ENTRY_REQUIRED
  >(state => state.parameters.revealOppositeTheHinge.value as number);
  const revealTriangleOne = useSelector<IState, number>(
    state => state.parameters.revealTriangleOne.value as number,
  );
  const revealTriangleTwo = useSelector<IState, number>(
    state => state.parameters.revealTriangleTwo.value as number,
  );
  const oneTriangle = useSelector<IState, boolean>(
    state =>
      state.parameters.geometricArea.value ===
      GeometricArea.ONE_TRIANGLES_RECTANGLE,
  );

  function withoutEntryRequired(
    value: number | Parameters.ENTRY_REQUIRED,
  ): number | undefined {
    return value !== Parameters.ENTRY_REQUIRED ? value : undefined;
  }

  return (
    <EditableBox {...props} heading="REVEAL_MEASURES">
      <div className="reveal-measure-box">
        <div className="reveal-measure-box__title">
          <FormattedMessage id="ANTRIEB_MONTAGEPOSITION_POSITION_BANDGEGENSEITE" />
        </div>
        <div className="reveal-measure-box__opposite-hinge">
          <NumberField
            range={REVEAL_RANGE}
            name={ValueKey.REVEAL_OPPOSITE_THE_HINGE}
            value={withoutEntryRequired(revealOppositeTheHinge)}
            onChange={v =>
              dispatch(
                changeCalculationParameter(
                  ValueKey.REVEAL_OPPOSITE_THE_HINGE,
                  v,
                ),
              )
            }
            valueKey={ValueKey.REVEAL_OPPOSITE_THE_HINGE}
            unit="mm"
            dontResetWhenOutOfRange={true}
          />
        </div>
        <div className="reveal-measure-box__triangles">
          <div className="reveal-measure-box__title">
            <FormattedMessage
              id={
                oneTriangle ? 'REVEAL_OF_TRIANGLE' : 'REVEAL_OF_THE_TRIANGLES'
              }
            />
          </div>
          <div className="reveal-measure-box__triangle-one">
            <NumberField
              range={REVEAL_RANGE}
              name={ValueKey.REVEAL_TRIANGLE_ONE}
              value={withoutEntryRequired(revealTriangleOne)}
              onChange={v =>
                dispatch(
                  changeCalculationParameter(ValueKey.REVEAL_TRIANGLE_ONE, v),
                )
              }
              valueKey={ValueKey.REVEAL_TRIANGLE_ONE}
              unit="mm"
              dontResetWhenOutOfRange={true}
            />
          </div>
          {!oneTriangle && (
            <div className="reveal-measure-box__triangle-two">
              <NumberField
                range={REVEAL_RANGE}
                name={ValueKey.REVEAL_TRIANGLE_TWO}
                value={withoutEntryRequired(revealTriangleTwo)}
                onChange={v =>
                  dispatch(
                    changeCalculationParameter(ValueKey.REVEAL_TRIANGLE_TWO, v),
                  )
                }
                valueKey={ValueKey.REVEAL_TRIANGLE_TWO}
                unit="mm"
                dontResetWhenOutOfRange={true}
              />
            </div>
          )}
        </div>
      </div>
    </EditableBox>
  );
};

export default RevealMeasuresBox;
