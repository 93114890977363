import React, { FC, useState } from 'react';
import FormLayout from '../../elements/FormLayout';
import '../general/CVDataView.scss';
import Dialog from '../../components/Dialog';
import Table from '../components/Table';
import TableHeader from '../elements/TableHeader';
import TableRow from '../elements/TableRow';
import SearchField from '../../elements/SearchField';

const DrivesDialog: FC<React.PropsWithChildren<unknown>> = () => {
  return (
    <div>
      <FormLayout additionalClass="drives-group-data-dialog">
        Existiert im Ordner General und ist in Dach und Fassade gleich
      </FormLayout>
    </div>
  );
};

const DrivesData: FC<React.PropsWithChildren<unknown>> = () => {
  const [dialogIsShown, setDialogIsShown] = useState(false);

  function triggerCreationMode(): void {
    setDialogIsShown(true);
  }
  return (
    <>
      <Dialog
        setDialogIsShown={setDialogIsShown}
        dialogIsShown={dialogIsShown}
        headingText="Antriebsgruppe bearbeiten"
        componentClass="full-view-dialog"
        key={dialogIsShown.toString()}
      >
        <DrivesDialog />
      </Dialog>
      <div className="sub-header">
        <div className="sub-header__title"> Antriebsgruppen</div>

        <SearchField
          setSearchString={() => 'prevent lint warning'}
          searchString=""
          placeholderText="Antriebsgruppe suchen..."
          small={true}
        />
        <button
          className="sub-header__button sub-header__button--add"
          onClick={() => triggerCreationMode()}
        >
          Neuer Eintrag
        </button>
      </div>
      <div className="cv-data">
        <Table>
          <TableHeader>
            <th>Name</th> <th></th>
            <th></th>
          </TableHeader>
          <TableRow>
            <td>1368-CPD-P-043-2010-B</td>
            <td>
              <button
                onClick={() => {
                  setDialogIsShown(true);
                }}
              >
                löschen
              </button>
            </td>{' '}
            <td>
              <button
                onClick={() => {
                  setDialogIsShown(true);
                }}
              >
                Bearbeiten
              </button>
            </td>
          </TableRow>
        </Table>
      </div>
    </>
  );
};
export default DrivesData;
