import { qaMode } from '../keyCloak/keyCloak';
import { useEffect } from 'react';

function displayMaintenanceWindow(alpha: boolean, qaMode: boolean): void {
  if (alpha || qaMode) {
    return;
  }

  window
    .fetch('/api/maintenance')
    .then(r => r.json())
    .then(m => {
      if (m['MAINTENANCE']) {
        window.location.href = '/under_construction.html';
      }
    });

  setInterval(
    () =>
      window
        .fetch('/api/maintenance')
        .then(r => r.json())
        .then(m => {
          if (m['MAINTENANCE']) {
            window.location.href = '/under_construction.html';
          }
        }),
    60 * 1000,
  );
}

export function useDisplayMaintenanceWindowEffect(
  alpha: boolean,
  qaMode: boolean,
): void {
  useEffect(() => displayMaintenanceWindow(alpha, qaMode), [alpha, qaMode]);
}
