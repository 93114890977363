import React, { FC } from 'react';
import { getModifyClass } from './element_utils';
import classNames from 'classnames';
import './Badge.scss';
import { FormattedMessage } from 'react-intl';

export enum BadgeColor {
  nrwg = 'nrwg',
  rwa = 'rwa',
  default = 'default',
}

interface BadgeProps {
  name: string;
  color: BadgeColor;
}
const modifyClass = getModifyClass('badge');

export const Badge: FC<React.PropsWithChildren<BadgeProps>> = (
  props: BadgeProps,
) => {
  return (
    <div className={classNames('badge' + modifyClass(props.color))}>
      <FormattedMessage id={props.name} />
    </div>
  );
};

export default Badge;
