import React, { FC, useEffect, useState } from 'react';
import FormLayout from '../../elements/FormLayout';
import InputField, { InputFieldNumber } from '../../elements/InputField';
import TextArea from '../../elements/TextArea';
import CheckBox from '../../elements/CheckBox';
import { Switch } from '../../elements/Switch';
import './FacadeSashProfileDataView.scss';
import { MenuPlacement, SelectField } from '../../elements/SelectField';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Route, Routes } from 'react-router-dom';
import Table from '../components/Table';
import TableHeader from '../elements/TableHeader';
import TableRow from '../elements/TableRow';
import Dialog from '../../components/Dialog';
import {
  SashProfileFacade,
  Series,
} from '../../redux/admin/adminFacadeReducer';
import SearchField from '../../elements/SearchField';
import {
  DialogSubCategoryProps,
  ProfileDataDialogBasicProps,
} from '../constants';
import { AdminState, AdminThunkDispatch } from '../../redux/admin/adminStore';
import {
  changeSashProfileFacade,
  createSashProfile,
} from '../../redux/admin/adminFacadeActions';
import AdminRadioButton from '../../elements/RadioButton';
import Pagination from '../components/Pagination';
import {
  fieldsFilled,
  getSeriesForProfileId,
  seriesIsOnlyInwards,
  seriesIsOnlyOutwards,
} from '../general/helpers';
import AdminListIcon from '../elements/AdminListIcon';
import classNames from 'classnames';
import AdminNavLink from '../common/AdminNavLink';
import { AnyAction } from 'redux';
import { useAdminSearch } from '../hooks';
import { downloadListAsCSV } from '../common/download';
import FormLayoutSubgroupTitle from '../../elements/FormLayoutSubgroupTitle';

const SashProfileDataDialogTabNav: FC<
  React.PropsWithChildren<unknown>
> = () => {
  return (
    <div className="admin__tab-nav frame-profile-data-dialog-tab-nav">
      <AdminNavLink to="/admin/facade/profiles/sash-profiles/basic">
        <Routes>
          <Route
            path="/"
            element={
              <Navigate
                replace
                to="/admin/facade/profiles/sash-profiles/basic"
              />
            }
          />
        </Routes>
        Allgemein
      </AdminNavLink>
      <AdminNavLink to="/admin/facade/profiles/sash-profiles/basic-information">
        Eckdaten
      </AdminNavLink>
      <AdminNavLink to="/admin/facade/profiles/sash-profiles/properties">
        Eigenschaften
      </AdminNavLink>{' '}
    </div>
  );
};

export function useValidateProfileArticleNumber(
  selectProfiles: (
    adminState: AdminState,
  ) => { series: Series; artNr: string; id: number }[],
  series: Series | undefined,
  artNr: string,
  id: number | undefined,
): string | undefined {
  const profiles = useSelector<
    AdminState,
    { series: Series; artNr: string; id: number }[]
  >(selectProfiles);

  const otherProfileWithSameArticleNumber = profiles.filter(
    s =>
      s.series.name.trim() === series?.name.trim() &&
      s.artNr.trim() === artNr.trim() &&
      s.id !== id,
  );

  return otherProfileWithSameArticleNumber.length > 0
    ? 'Artikelnummer bereits genutzt'
    : undefined;
}

const SashProfileDataDialogBasic: FC<
  React.PropsWithChildren<ProfileDataDialogBasicProps>
> = (props: ProfileDataDialogBasicProps) => {
  const articleNumberError = useValidateProfileArticleNumber(
    s => s.adminFacade.sashProfiles,
    props.series,
    props.artNr,
    props.id,
  );

  return (
    <div className="">
      <FormLayout additionalClass="sash-profile-data-view__layout-basic">
        <InputField
          label="Artikelnummer*"
          placeholder="Artikelnummer des Flügelprofils"
          value={props.artNr}
          additionalClass="sash-profile-data-view__article-nr"
          onChange={props.setArtNr}
          errorMessage={articleNumberError}
        />
        <TextArea
          label="Information"
          additionalClass="sash-profile-data-view__info"
          placeholder="Informationen zum System"
          value={props.beschreibung}
          onChange={props.setBeschreibung}
        />
        <SelectField
          additionalClass="sash-profile-data-view__serie"
          label="Serie *"
          value={
            props.series
              ? {
                  value: props.series,
                  label: props.series!.name,
                }
              : undefined
          }
          action={newValue => {
            props.setSeries(newValue.value);
          }}
          options={props.allSeries.map(series => ({
            label: series.name,
            value: series,
          }))}
          name="seriesOfSashProfileFacade"
          searchable={true}
          placeholder="Serie auswählen"
          menuPlacement={MenuPlacement.BOTTOM}
        />
        <div className="sash-profile-data-view__switch">
          <Switch
            labelText={
              props.series?.active
                ? 'aktiv'
                : 'Aktivierung nicht möglich. Serie ' +
                  props.series?.name +
                  ' muss aktiv sein.'
            }
            turnedOn={props.series?.active ? props.isActive : false}
            onChange={props.setIsActive}
          />
        </div>
        <div className="sash-profile-data-view__preview">
          <Switch
            labelText="Vorschau"
            turnedOn={props.isPreview}
            onChange={props.setIsPreview}
          />
        </div>
      </FormLayout>
    </div>
  );
};

interface SashProfileDataDialogBasicInformationProps
  extends DialogSubCategoryProps {
  kammermassInnen: number | null | undefined;
  setKammermassInnen: (i: number | null) => void;
  kammermassAussen: number | null | undefined;
  setKammermassAussen: (i: number | null) => void;
  bautiefe: number | null | undefined;
  setBautiefe: (i: number | null) => void;
  maxFluegelgewicht: number | null | undefined;
  setMaxFluegelgewicht: (i: number | null) => void;
  maxEinspannstaerke: number | null | undefined;
  setMaxEinspannstaerke: (s: number | null) => void;
  minEinspannstaerke: number | null | undefined;
  setMinEinspannstaerke: (s: number | null) => void;
}

const SashProfileDataDialogBasicInformation: FC<
  React.PropsWithChildren<SashProfileDataDialogBasicInformationProps>
> = (props: SashProfileDataDialogBasicInformationProps) => {
  return (
    <div>
      <FormLayout additionalClass="sash-profile-data-view__layout-basic-information">
        <InputFieldNumber
          label="Kammermaß innen (mm)*"
          placeholder="z.B. 42"
          value={props.kammermassInnen}
          additionalClass="sash-profile-data-view__measurement-inside"
          onChange={props.setKammermassInnen}
        />
        <InputFieldNumber
          label="Kammermaß außen (mm)*"
          placeholder="z.B. 42"
          value={props.kammermassAussen}
          additionalClass="sash-profile-data-view__measurement-outside"
          onChange={props.setKammermassAussen}
        />
        <InputFieldNumber
          label="Bautiefe (mm)"
          placeholder="z.B. 42"
          value={props.bautiefe}
          additionalClass="frame-profile-data-dialog__building-depth"
          onChange={props.setBautiefe}
        />
        <InputFieldNumber
          label="Maximales Flügelgewicht (kg)*"
          placeholder="z.B. 42"
          value={props.maxFluegelgewicht}
          additionalClass="sash-profile-data-view__max-sash-weight"
          onChange={props.setMaxFluegelgewicht}
        />
        <InputFieldNumber
          label="Maximale Einspannstärke (mm)"
          placeholder="z.B. 42"
          value={props.maxEinspannstaerke}
          additionalClass="sash-profile-data-view__max-chuck"
          onChange={props.setMaxEinspannstaerke}
        />
        <InputFieldNumber
          label="Minimale Einspannstärke (mm)"
          placeholder="z.B. 42"
          value={props.minEinspannstaerke}
          additionalClass="sash-profile-data-view__min-chuck"
          onChange={props.setMinEinspannstaerke}
        />
      </FormLayout>
    </div>
  );
};

interface SashProfileDataDialogPropertiesProps extends DialogSubCategoryProps {
  forIntegrierteMontage: boolean;
  setForIntegrierteMontage: (b: boolean) => void;
  verriegelungsbeschlagGeeignet: boolean;
  setVerriegelungsbeschlagGeeignet: (b: boolean) => void;
  isEinwaerts: boolean;
  setIsEinwaerts: (b: boolean) => void;
  isAuswaerts: boolean;
  setIsAuswaerts: (b: boolean) => void;
  kipp: boolean;
  setKipp: (b: boolean) => void;
  klapp: boolean;
  setKlapp: (b: boolean) => void;
  dreh: boolean;
  setDreh: (b: boolean) => void;
  senkKlapp: boolean;
  setSenkKlapp: (b: boolean) => void;
  isStandardFenster: boolean;
  setIsStandardFenster: (b: boolean) => void;
  isBlockFenster: boolean;
  setIsBlockFenster: (b: boolean) => void;
  isFlaechenbuendig: boolean;
  setIsFlaechenbuendig: (b: boolean) => void;
  serie: Series | undefined;
}

const SashProfileDataDialogProperties: FC<
  React.PropsWithChildren<SashProfileDataDialogPropertiesProps>
> = (props: SashProfileDataDialogPropertiesProps) => {
  useEffect(() => {
    if (seriesIsOnlyOutwards(props.serie)) {
      props.setIsAuswaerts(true);
      props.setIsEinwaerts(false);
    }
    if (seriesIsOnlyInwards(props.serie)) {
      props.setIsEinwaerts(true);
      props.setIsAuswaerts(false);
    }
  }, [props.isAuswaerts, props.isEinwaerts]);

  function setProfileTypeToStandardFenster(): void {
    props.setIsStandardFenster(true);
    props.setIsFlaechenbuendig(false);
    props.setIsBlockFenster(false);
  }

  function setProfileTypeToBlockfenster(): void {
    props.setIsBlockFenster(true);
    props.setIsFlaechenbuendig(false);
    props.setIsStandardFenster(false);
  }

  function setProfileTypeToFlaechenbuendig(): void {
    props.setIsFlaechenbuendig(true);
    props.setIsStandardFenster(false);
    props.setIsBlockFenster(false);
  }
  return (
    <div>
      <FormLayout additionalClass="sash-profile-data-view__layout-properties">
        <div className="sash-profile-data-view__integrated">
          <Switch
            labelText="Für integrierte Montage"
            turnedOn={props.forIntegrierteMontage}
            onChange={props.setForIntegrierteMontage}
          />
        </div>
        <div className="sash-profile-data-view__verriegelungsbeschlag">
          <Switch
            labelText="Für Verriegelungsbeschlag geeignet"
            turnedOn={props.verriegelungsbeschlagGeeignet}
            onChange={props.setVerriegelungsbeschlagGeeignet}
          />
        </div>
        <div
          className={classNames(' sash-profile-data-view__opening-direction', {
            'sash-profile-data-view__opening-direction--disabled form-layout__sub-group form-layout__sub-group--2':
              seriesIsOnlyInwards(props.serie) ||
              seriesIsOnlyOutwards(props.serie),
          })}
        >
          <FormLayoutSubgroupTitle>Öffnungsrichtung *</FormLayoutSubgroupTitle>

          {!props.serie && (
            <div className="sash-profile-data-view__hint">
              Bitte zuerst eine Serie ausgewählen
            </div>
          )}

          {props.serie?.oeffnungsrichtungEinwaerts && (
            <CheckBox
              id="Einwärts"
              label="Einwärts"
              checked={props.isEinwaerts}
              onClick={() => props.setIsEinwaerts(!props.isEinwaerts)}
            />
          )}
          {props.serie?.oeffnungsrichtungAuswaerts && (
            <CheckBox
              id="Auswärts"
              label="Auswärts"
              checked={props.isAuswaerts}
              onClick={() => props.setIsAuswaerts(!props.isAuswaerts)}
            />
          )}
        </div>

        <div className="form-layout__sub-group frame-profile-data-dialog__application">
          <FormLayoutSubgroupTitle>Anwendung *</FormLayoutSubgroupTitle>
          <CheckBox
            id="Kipp"
            label="Kipp"
            checked={props.kipp}
            onClick={() => props.setKipp(!props.kipp)}
          />
          <CheckBox
            id="Klapp"
            label="Klapp"
            checked={props.klapp}
            onClick={() => props.setKlapp(!props.klapp)}
          />
          <CheckBox
            id="Dreh"
            label="Dreh"
            checked={props.dreh}
            onClick={() => props.setDreh(!props.dreh)}
          />
          <CheckBox
            id="Senkklapp"
            label="Senkklapp"
            checked={props.senkKlapp}
            onClick={() => props.setSenkKlapp(!props.senkKlapp)}
          />
        </div>

        <div className="sash-profile-data-view__profile-type">
          <FormLayoutSubgroupTitle>Profiltyp *</FormLayoutSubgroupTitle>
          <AdminRadioButton
            checked={props.isStandardFenster}
            name="Standardfenster (innen flächenversetzt)"
            onChange={setProfileTypeToStandardFenster}
          />
          <AdminRadioButton
            checked={props.isBlockFenster}
            name="Blockfenster (innen flächenversetzti)"
            onChange={setProfileTypeToBlockfenster}
          />
          <AdminRadioButton
            checked={props.isFlaechenbuendig}
            name="Flächenbündig (innen u. außen)"
            onChange={setProfileTypeToFlaechenbuendig}
          />
        </div>
      </FormLayout>
    </div>
  );
};

interface AdminSashProfileDialogProps {
  selectedSashProfile: SashProfileFacade | undefined;
  allSeries: Series[];
  dialogIsShown: boolean;
  setDialogIsShown: (b: boolean) => void;
}

const AdminSashProfileDialog: FC<
  React.PropsWithChildren<AdminSashProfileDialogProps>
> = (props: AdminSashProfileDialogProps) => {
  const dispatch: AdminThunkDispatch<AnyAction> = useDispatch();
  const [isActive, setIsActive] = useState(
    props.selectedSashProfile?.active || false,
  );
  const [isPreview, setIsPreview] = useState(
    props.selectedSashProfile?.preview ||
      (props.selectedSashProfile?.preview === false ? false : true),
  );
  const [artNr, setArtNr] = useState(props.selectedSashProfile?.artNr || '');
  const [beschreibung, setBeschreibung] = useState(
    props.selectedSashProfile?.beschreibung || '',
  );
  const [serie, setSerie] = useState(
    getSeriesForProfileId(props.selectedSashProfile?.seriesId, props.allSeries),
  );

  if (props.selectedSashProfile && !serie) {
    setSerie(
      getSeriesForProfileId(
        props.selectedSashProfile?.seriesId,
        props.allSeries,
      ),
    );
  }

  const [kammermassInnen, setKammermassInnen] = useState<
    number | null | undefined
  >(props.selectedSashProfile?.kammermassInnen);
  const [kammermassAussen, setKammermassAussen] = useState<
    number | null | undefined
  >(props.selectedSashProfile?.kammermassAussen);
  const [bautiefe, setBautiefe] = useState<number | null | undefined>(
    props.selectedSashProfile?.bautiefe,
  );
  const [maxFluegelgewicht, setMaxFluegelgewicht] = useState<
    number | null | undefined
  >(props.selectedSashProfile?.maxFluegelgewicht);
  const [maxEinspannstaerke, setMaxEinspannstaerke] = useState<
    number | null | undefined
  >(props.selectedSashProfile?.maxEinspannstaerke);
  const [minEinspannstaerke, setMinEinspannstaerke] = useState<
    number | null | undefined
  >(props.selectedSashProfile?.minEinspannstaerke);

  const [forIntegrierteMontage, setForIntegrierteMontage] = useState(
    props.selectedSashProfile?.fuerIntegrierteMontage || false,
  );
  const [verriegelungsbeschlagGeeignet, setVerriegelungsbeschlagGeeignet] =
    useState(props.selectedSashProfile?.verriegelungsbeschlagGeeignet || false);
  const [isEinwaerts, setIsEinwaerts] = useState(
    (seriesIsOnlyInwards(serie)
      ? true
      : props.selectedSashProfile?.oeffnungsrichtungEinwaerts) || false,
  );
  const [isAuswaerts, setIsAuswaerts] = useState(
    (seriesIsOnlyOutwards(serie)
      ? true
      : props.selectedSashProfile?.oeffnungsrichtungAuswaerts) || false,
  );
  const [kipp, setKipp] = useState(
    props.selectedSashProfile?.anwendungKipp || false,
  );
  const [klapp, setKlapp] = useState(
    props.selectedSashProfile?.anwendungKlapp || false,
  );
  const [dreh, setDreh] = useState(
    props.selectedSashProfile?.anwendungDreh || false,
  );
  const [senkKlapp, setSenkKlapp] = useState(
    props.selectedSashProfile?.anwendungSenkklapp || false,
  );
  const [isStandardFenster, setIsStandardFenster] = useState(
    props.selectedSashProfile?.standardfenster || false,
  );
  const [isBlockFenster, setIsBlockFenster] = useState(
    props.selectedSashProfile?.blockfenster || false,
  );
  const [isFlaechenbuendig, setIsFlaechenbuendig] = useState(
    props.selectedSashProfile?.flaechenbuendig || false,
  );
  const articleNumberError = useValidateProfileArticleNumber(
    s => s.adminFacade.sashProfiles,
    serie,
    artNr,
    props.selectedSashProfile?.id,
  );

  useEffect(() => {
    resetInputFields();
  }, [props.selectedSashProfile]);

  function resetInputFields(): void {
    setIsActive(props.selectedSashProfile?.active || false);
    setIsPreview(
      props.selectedSashProfile?.preview ||
        (props.selectedSashProfile?.preview === false ? false : true),
    );
    setArtNr(props.selectedSashProfile?.artNr || '');
    setBeschreibung(props.selectedSashProfile?.beschreibung || '');
    setSerie(
      getSeriesForProfileId(
        props.selectedSashProfile?.seriesId,
        props.allSeries,
      )! || undefined,
    );
    setKammermassInnen(props.selectedSashProfile?.kammermassInnen);
    setKammermassAussen(props.selectedSashProfile?.kammermassAussen);
    setBautiefe(props.selectedSashProfile?.bautiefe);
    setMaxFluegelgewicht(props.selectedSashProfile?.maxFluegelgewicht);
    setMaxEinspannstaerke(props.selectedSashProfile?.maxEinspannstaerke);
    setMinEinspannstaerke(props.selectedSashProfile?.minEinspannstaerke);
    setVerriegelungsbeschlagGeeignet(
      props.selectedSashProfile?.verriegelungsbeschlagGeeignet || false,
    );
    setForIntegrierteMontage(
      props.selectedSashProfile?.fuerIntegrierteMontage || false,
    );
    setIsEinwaerts(
      (seriesIsOnlyInwards(serie)
        ? true
        : props.selectedSashProfile?.oeffnungsrichtungEinwaerts) || false,
    );
    setIsAuswaerts(
      (seriesIsOnlyInwards(serie)
        ? true
        : props.selectedSashProfile?.oeffnungsrichtungAuswaerts) || false,
    );
    setKipp(props.selectedSashProfile?.anwendungKipp || false);
    setKlapp(props.selectedSashProfile?.anwendungKlapp || false);
    setDreh(props.selectedSashProfile?.anwendungDreh || false);
    setSenkKlapp(props.selectedSashProfile?.anwendungSenkklapp || false);
    setIsStandardFenster(props.selectedSashProfile?.standardfenster || false);
    setIsBlockFenster(props.selectedSashProfile?.blockfenster || false);
    setIsFlaechenbuendig(props.selectedSashProfile?.flaechenbuendig || false);
  }

  function editSashProfile(): void {
    dispatch(
      changeSashProfileFacade({
        ...props.selectedSashProfile!,
        seriesId: serie!.id,
        kammermassAussen: kammermassAussen!,
        kammermassInnen: kammermassInnen!,
        artNr: artNr,
        beschreibung: beschreibung,
        active: isActive,
        preview: isPreview,
        bautiefe: bautiefe!,
        maxFluegelgewicht: maxFluegelgewicht!,
        series: serie!,
        oeffnungsrichtungEinwaerts: isEinwaerts,
        oeffnungsrichtungAuswaerts: isAuswaerts,
        anwendungKipp: kipp,
        anwendungKlapp: klapp,
        anwendungDreh: dreh,
        anwendungSenkklapp: senkKlapp,
        blockfenster: isBlockFenster,
        standardfenster: isStandardFenster,
        flaechenbuendig: isFlaechenbuendig,
        maxEinspannstaerke: maxEinspannstaerke!,
        minEinspannstaerke: minEinspannstaerke!,
        fuerIntegrierteMontage: forIntegrierteMontage,
        verriegelungsbeschlagGeeignet: verriegelungsbeschlagGeeignet,
      }),
    );
  }

  function newSashProfile(): void {
    dispatch(
      createSashProfile({
        seriesId: serie!.id,
        kammermassAussen: kammermassAussen!,
        series: serie!,
        kammermassInnen: kammermassInnen!,
        artNr: artNr,
        beschreibung: beschreibung,
        active: isActive,
        preview: isPreview,
        bautiefe: bautiefe!,
        maxFluegelgewicht: maxFluegelgewicht!,

        oeffnungsrichtungEinwaerts: isEinwaerts,
        oeffnungsrichtungAuswaerts: isAuswaerts,
        anwendungKipp: kipp,
        anwendungKlapp: klapp,
        anwendungDreh: dreh,
        anwendungSenkklapp: senkKlapp,
        blockfenster: isBlockFenster,
        standardfenster: isStandardFenster,
        flaechenbuendig: isFlaechenbuendig,
        maxEinspannstaerke: maxEinspannstaerke!,
        minEinspannstaerke: minEinspannstaerke!,
        fuerIntegrierteMontage: forIntegrierteMontage,
        verriegelungsbeschlagGeeignet: verriegelungsbeschlagGeeignet,
      }),
    );
  }

  function mandatoryFieldsFilled(): boolean {
    return (
      fieldsFilled(
        artNr,
        serie,
        kammermassInnen,
        kammermassAussen,
        maxFluegelgewicht,
      ) &&
      (isEinwaerts || isAuswaerts) &&
      (kipp || klapp || dreh || senkKlapp) &&
      (isStandardFenster || isBlockFenster || isFlaechenbuendig) &&
      !articleNumberError
    );
  }

  return (
    <Dialog
      setDialogIsShown={props.setDialogIsShown}
      dialogIsShown={props.dialogIsShown}
      headingText={
        props.selectedSashProfile
          ? `Flügelprofil ${props.selectedSashProfile.artNr} bearbeiten`
          : 'Flügelprofil anlegen'
      }
      componentClass="full-view-dialog"
      key={props.dialogIsShown.toString()}
      footerProps={{
        notTranslated: true,
        cancelAction: () => {
          resetInputFields();
          props.setDialogIsShown(false);
        },
        saveAction: mandatoryFieldsFilled()
          ? () => {
              props.selectedSashProfile ? editSashProfile() : newSashProfile();
              props.setDialogIsShown(false);
            }
          : undefined,
        primaryActionLabelText: props.selectedSashProfile
          ? 'Speichern'
          : 'Anlegen',
      }}
    >
      <SashProfileDataDialogTabNav />
      <Routes>
        <Route
          path="/basic/*"
          element={
            <SashProfileDataDialogBasic
              selectedSashProfile={props.selectedSashProfile}
              allSeries={props.allSeries}
              isActive={isActive}
              setIsActive={setIsActive}
              isPreview={isPreview}
              setIsPreview={setIsPreview}
              artNr={artNr}
              setArtNr={setArtNr}
              beschreibung={beschreibung}
              setBeschreibung={setBeschreibung}
              series={serie}
              setSeries={setSerie}
              id={props.selectedSashProfile?.id}
            />
          }
        />
        <Route
          path="/basic-information/*"
          element={
            <SashProfileDataDialogBasicInformation
              kammermassInnen={kammermassInnen}
              setKammermassInnen={setKammermassInnen}
              kammermassAussen={kammermassAussen}
              setKammermassAussen={setKammermassAussen}
              bautiefe={bautiefe}
              setBautiefe={setBautiefe}
              maxFluegelgewicht={maxFluegelgewicht}
              setMaxFluegelgewicht={setMaxFluegelgewicht}
              maxEinspannstaerke={maxEinspannstaerke}
              setMaxEinspannstaerke={setMaxEinspannstaerke}
              minEinspannstaerke={minEinspannstaerke}
              setMinEinspannstaerke={setMinEinspannstaerke}
              selectedSashProfile={props.selectedSashProfile}
            />
          }
        />
        <Route
          path="/properties/*"
          element={
            <SashProfileDataDialogProperties
              selectedSashProfile={props.selectedSashProfile}
              verriegelungsbeschlagGeeignet={verriegelungsbeschlagGeeignet}
              setVerriegelungsbeschlagGeeignet={
                setVerriegelungsbeschlagGeeignet
              }
              forIntegrierteMontage={forIntegrierteMontage}
              setForIntegrierteMontage={setForIntegrierteMontage}
              isEinwaerts={isEinwaerts}
              setIsEinwaerts={setIsEinwaerts}
              isAuswaerts={isAuswaerts}
              setIsAuswaerts={setIsAuswaerts}
              kipp={kipp}
              setKipp={setKipp}
              klapp={klapp}
              setKlapp={setKlapp}
              dreh={dreh}
              setDreh={setDreh}
              senkKlapp={senkKlapp}
              setSenkKlapp={setSenkKlapp}
              isStandardFenster={isStandardFenster}
              setIsStandardFenster={setIsStandardFenster}
              isBlockFenster={isBlockFenster}
              setIsBlockFenster={setIsBlockFenster}
              isFlaechenbuendig={isFlaechenbuendig}
              setIsFlaechenbuendig={setIsFlaechenbuendig}
              serie={serie}
            />
          }
        />
      </Routes>
    </Dialog>
  );
};

const FacadeSashProfileDataView: FC<React.PropsWithChildren<unknown>> = () => {
  const [dialogIsShown, setDialogIsShown] = useState(false);
  const [searchString, setSearchString] = useState('');

  const allSeries = useSelector<AdminState, Series[]>(
    state => state.adminFacade.series,
  );

  const [indexOfFirstPageElement, setIndexOfFirstPageElement] = useState(0);
  const [page, setPage] = useState(1);
  const sashProfiles = useSelector<AdminState, SashProfileFacade[]>(
    state => state.adminFacade.sashProfiles,
  );
  const [filterActive, setFilterActive, searchResult] = useAdminSearch(
    sashProfiles,
    searchString,
    ['artNr'],
    entry => {
      const series = getSeriesForProfileId(entry.seriesId, allSeries);
      return [series?.name || '', series?.system.name || ''];
    },
  );

  const [selectedSashProfileID, setSelectedSashProfileId] = useState<
    undefined | number
  >(undefined);

  const selectedSashProfile = sashProfiles.find(
    s => s.id === selectedSashProfileID || undefined,
  );

  function enableEditSashProfile(sashProfile: SashProfileFacade): void {
    setSelectedSashProfileId(sashProfile.id);
    setDialogIsShown(true);
  }

  function triggerCreationMode(): void {
    setDialogIsShown(true);
    setSelectedSashProfileId(undefined);
  }

  function getCurrentTableContent(): SashProfileFacade[] {
    return searchResult.slice(
      indexOfFirstPageElement,
      indexOfFirstPageElement + 20,
    );
  }

  return (
    <>
      <AdminSashProfileDialog
        selectedSashProfile={selectedSashProfile}
        dialogIsShown={dialogIsShown}
        setDialogIsShown={setDialogIsShown}
        allSeries={allSeries}
      />
      <div className="sub-header">
        <div className="sub-header__title"> Flügelprofile</div>
        <SearchField
          setSearchString={setSearchString}
          searchString={searchString}
          placeholderText="Flügelprofil suchen..."
          small={true}
          setFilterActive={setFilterActive}
          filterActive={filterActive}
        />
        <button
          className="sub-header__download-button"
          onClick={() =>
            downloadListAsCSV(
              searchResult,
              ';',
              ['legacyId'],
              'FlügelprofileFassade',
              { series: (s: Series) => s.name },
              { system: profile => profile.series.system.name },
            )
          }
        >
          CSV
        </button>
        <button
          className="sub-header__button sub-header__button--add"
          onClick={() => triggerCreationMode()}
        >
          Neuer Eintrag
        </button>
      </div>
      <div className="sash-profile-data">
        <Table subNavigation={true}>
          <TableHeader>
            <th>Artikelnummer</th>
            <th>Beschreibung</th>
            <th>System</th>
            <th>Serie</th>
            <th>Kipp</th>
            <th>Klapp</th>
            <th>Dreh</th>
            <th>Einwärts</th>
            <th>Auswärts</th>
            <th>Standardfenster (innen flächenversetzt)</th>
            <th>Blockfenster (innen flächenversetzt)</th>
            <th>Flächenbündig (innen u. außen)</th>
            <th>integrierte Montage</th>
            <th>Verriegelungsbeschlag geeignet</th>
            <th>Aktion</th>
          </TableHeader>
          <tbody>
            {getCurrentTableContent().map(sashProfile => (
              <TableRow key={sashProfile.id}>
                <td>{sashProfile.artNr}</td>
                <td>{sashProfile.beschreibung}</td>
                <td>
                  {getSeriesForProfileId(sashProfile.seriesId, allSeries)
                    ?.system.name ?? ''}
                </td>
                <td>
                  {getSeriesForProfileId(sashProfile.seriesId, allSeries)
                    ?.name ?? ''}
                </td>
                <td>
                  <AdminListIcon checked={sashProfile.anwendungKipp} />
                </td>
                <td>
                  <AdminListIcon checked={sashProfile.anwendungKlapp} />
                </td>
                <td>
                  <AdminListIcon checked={sashProfile.anwendungDreh} />
                </td>
                <td>
                  <AdminListIcon
                    checked={sashProfile.oeffnungsrichtungEinwaerts}
                  />
                </td>
                <td>
                  <AdminListIcon
                    checked={sashProfile.oeffnungsrichtungAuswaerts}
                  />
                </td>
                <td>
                  <AdminListIcon checked={sashProfile.standardfenster} />
                </td>
                <td>
                  <AdminListIcon checked={sashProfile.blockfenster} />
                </td>
                <td>
                  <AdminListIcon checked={sashProfile.flaechenbuendig} />
                </td>
                <td>
                  <AdminListIcon checked={sashProfile.fuerIntegrierteMontage} />
                </td>
                <td>
                  <AdminListIcon
                    checked={sashProfile.verriegelungsbeschlagGeeignet}
                  />
                </td>
                <td>
                  <button
                    onClick={() => {
                      enableEditSashProfile(sashProfile);
                    }}
                  >
                    Bearbeiten
                  </button>
                </td>
              </TableRow>
            ))}
          </tbody>
        </Table>
      </div>
      <Pagination
        searchString={searchString}
        numberOfPages={searchResult.length}
        page={page}
        setPage={setPage}
        indexOfFirstPageElement={indexOfFirstPageElement}
        setIndexOfFirstPageElement={setIndexOfFirstPageElement}
      />
    </>
  );
};
export default FacadeSashProfileDataView;
