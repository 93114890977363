import { AnyAction, Reducer } from 'redux';
import { calcStrokeBasic } from '../calculations/angle_stroke';
import ParameterValue, {
  Parameters,
  ParameterValueEntryRequired,
  ParameterValueNotTranslated,
  ParameterValueProfileData,
} from '../components/Parameters/ParameterValue';
import { getDistance, getPercent } from '../calculations/distance_calculations';
import { ValueKey } from './valueKey';
import _ from 'lodash';
import {
  Application,
  APPLICATIONS_BY_OPENING_TYPE,
  AssemblyType,
  DrivePosition,
  DriveType,
  FillType,
  GeometricArea,
  LockingDrive,
  OpeningDirection,
  OpeningSide,
  OpeningType,
  OpeningTypes,
  RangeOfApplication,
  RevealDepth,
  RevealStructure,
  SideWindConsideration,
  TabOptions,
  Voltage,
} from './constants';

export type LiteralParameterValue = string | number | boolean | undefined;

export const MINIMUM_DISTANCE_TO_HINGE_SIDE_HUNG_SIDE_INSTALLATION = 390;
export const STANDARD_DISTANCE_TO_HINGE_SIDE_HUNG_SIDE_INSTALLATION = 550;

export interface ParametersState {
  [index: string]: ParameterValue;

  //Drivedata
  assemblyType: ParameterValue;
  drivePosition: ParameterValue;
  distanceToHinge: ParameterValue;
  percentOfDistanceToHinge: ParameterValue;
  numberOfDrives: ParameterValue;
  voltage: ParameterValue;
  driveType: ParameterValue;
  driveSeries: ParameterValue;
  lockingDrive: ParameterValue;
  //Sashdata
  rangeOfApplication: ParameterValue;
  angleOfInstallation: ParameterValue;
  openingType: ParameterValue;
  openingSide: ParameterValue;
  openingDirection: ParameterValue;
  sashWidth: ParameterValue;
  sashHeight: ParameterValue;
  area: ParameterValue;
  [ValueKey.VALUEKEY_SANDWICH_ELEMENT]: ParameterValue;
  sashWeight: ParameterValue;
  glassThickness: ParameterValue;
  openingAngle: ParameterValue;
  openingStroke: ParameterValue;
  windLoad: ParameterValue;
  reveal: ParameterValue;
  revealStructure: ParameterValue;
  revealOppositeTheHinge: ParameterValue;
  revealTriangleOne: ParameterValue;
  revealTriangleTwo: ParameterValue;
  distanceSashToReveal: ParameterValue;
  //smokeExtraction
  exhaustDimension: ParameterValue;
  buildingDepth: ParameterValue;
  geometricArea: ParameterValue;
  correctionFactor: ParameterValue;
  correctionFactorDescription: ParameterValue;
  //UI
  weightOption: ParameterValue;
  distanceOption: ParameterValue;
  openingOption: ParameterValue;
  profileSystem: ParameterValueNotTranslated;
  profileSeries: ParameterValueNotTranslated;
  profileSeriesId: ParameterValueProfileData;

  //Profiledata
  assemblySpaceFrame: ParameterValue;
  assemblySpaceSashProfile: ParameterValue;
  typeOfProfileDataInput: ParameterValue;
  spaceFrame: ParameterValueProfileData;
  spaceSashFrame: ParameterValueProfileData;
  profileSashId: ParameterValueProfileData;
  profileSash: ParameterValueNotTranslated;
  profileFrameId: ParameterValue;
  profileFrame: ParameterValueNotTranslated;
  profileExchangeId: ParameterValue;
  profileExchange: ParameterValueNotTranslated;
  profileBaseId: ParameterValueProfileData;
  profileBase: ParameterValueNotTranslated;

  //nrwg data
  sideWindConsideration: ParameterValue;
  application: ParameterValue;
  mountingType: ParameterValue;
  aerodynamicCase: ParameterValue;
  sashFrameDistance: ParameterValue;
}

// lockingDrive = WITHOUT_LOCKING_DRIVE;

const ROOF_PARAMETERS_STATE: ParametersState = {
  //Drivedata
  assemblyType: new ParameterValue(AssemblyType.ASSEMBLY_TYPE_FRAME),
  drivePosition: new ParameterValue(
    DrivePosition.ANTRIEB_MONTAGEPOSITION_POSITION_BANDGEGENSEITE,
  ),
  distanceToHinge: new ParameterValue(1000, 'mm'),
  percentOfDistanceToHinge: new ParameterValue(100, '%'),
  numberOfDrives: new ParameterValue(1),
  voltage: new ParameterValue(Voltage.ANTRIEB_SPANNUNG_24V, 'V'),
  driveType: new ParameterValue(DriveType.ANTRIEB_TYP_ZAHNSTANGE),
  driveSeries: new ParameterValue(Parameters.NO_SELECTION),
  lockingDrive: new ParameterValue(LockingDrive.WITHOUT_LOCKING_DRIVE),

  //Sashdata
  rangeOfApplication: new ParameterValue(RangeOfApplication.ROOF),
  angleOfInstallation: new ParameterValue(0, '˚'),
  openingType: new ParameterValue(OpeningType.FENSTER_OEFFNUNGSART_KIPP),
  openingSide: new ParameterValue(OpeningSide.OPENING_SIDE_RIGHT),
  openingDirection: new ParameterValue(
    OpeningDirection.FENSTER_OEFFNUNGSRICHTUNG_AUSWAERTS,
  ),
  sashWidth: new ParameterValue(1000, 'mm'),
  sashHeight: new ParameterValue(1000, 'mm'),
  area: new ParameterValue(1, 'm²'),
  [ValueKey.VALUEKEY_SANDWICH_ELEMENT]: new ParameterValue(FillType.GLASS_FILL),
  sashWeight: new ParameterValue(30, 'kg'),
  glassThickness: new ParameterValue(0, 'mm'),
  revealStructure: new ParameterValue(RevealStructure.CIRCUMFERENTIAL),
  reveal: new ParameterValue(RevealDepth.NO_REVEAL),
  revealOppositeTheHinge: new ParameterValue(100, 'mm'),
  revealTriangleOne: new ParameterValue(100, 'mm'),
  revealTriangleTwo: new ParameterValue(100, 'mm'),
  distanceSashToReveal: new ParameterValue(40, 'mm'),
  openingAngle: new ParameterValue(20, '˚'),
  openingStroke: new ParameterValue(null, 'mm'),
  windLoad: new ParameterValue(1000, 'Pa'),
  snowLoad: new ParameterValue(850, 'Pa'),
  //smokeExtraction
  exhaustDimension: new ParameterValue(57, 'mm'),
  buildingDepth: new ParameterValue(94, 'mm'),
  geometricArea: new ParameterValue(GeometricArea.ONLY_RECTANGLE),
  correctionFactor: new ParameterValue(1),
  correctionFactorDescription: new ParameterValue(Parameters.NO_SELECTION),
  //UI
  weightOption: new ParameterValue(ValueKey.VALUEKEY_SASH_WEIGHT),
  distanceOption: new ParameterValue(
    ValueKey.VALUEKEY_PERCENT_OF_DISTANCE_TO_HINGE,
  ),
  openingOption: new ParameterValue(ValueKey.VALUEKEY_OPENING_ANGLE),
  profileSystem: new ParameterValueNotTranslated(
    Parameters.NO_SELECTION_SYSTEM_SERIES,
  ),
  profileSeries: new ParameterValueNotTranslated(
    Parameters.NO_SELECTION_SYSTEM_SERIES,
  ),
  profileSeriesId: new ParameterValueProfileData(Parameters.NO_SELECTION),

  //Profiledata
  assemblySpaceFrame: new ParameterValue(120, 'mm'),
  assemblySpaceSashProfile: new ParameterValue(120, 'mm'),
  typeOfProfileDataInput: new ParameterValue(TabOptions.OPTION_FREE_ENTRY),
  spaceFrame: new ParameterValue(120),
  spaceSashFrame: new ParameterValue(120),
  profileSashId: new ParameterValueProfileData(Parameters.NO_SELECTION),
  profileSash: new ParameterValueNotTranslated(
    Parameters.NO_SELECTION_SYSTEM_SERIES,
  ),
  profileFrameId: new ParameterValueProfileData(Parameters.NO_SELECTION),
  profileFrame: new ParameterValueNotTranslated(
    Parameters.NO_SELECTION_SYSTEM_SERIES,
  ),
  profileExchangeId: new ParameterValueProfileData(Parameters.NO_SELECTION),
  profileExchange: new ParameterValueNotTranslated(
    Parameters.NO_SELECTION_SYSTEM_SERIES,
  ),
  profileBaseId: new ParameterValueProfileData(Parameters.NO_SELECTION),
  profileBase: new ParameterValueNotTranslated(
    Parameters.NO_SELECTION_SYSTEM_SERIES,
  ),

  //NRWG Data
  sideWindConsideration: new ParameterValue(
    SideWindConsideration.WITH_CONSIDERATION,
  ),
  application: new ParameterValue(Parameters.NO_SELECTION),
  mountingType: new ParameterValue(Parameters.NO_SELECTION),
  aerodynamicCase: new ParameterValue(Parameters.NO_SELECTION),
  sashFrameDistance: new ParameterValueEntryRequired(Parameters.ENTRY_REQUIRED),
};
export const FACADE_PARAMETERS_STATE: ParametersState = {
  //Drivedata
  assemblyType: new ParameterValue(AssemblyType.ASSEMBLY_TYPE_FRAME),
  drivePosition: new ParameterValue(
    DrivePosition.ANTRIEB_MONTAGEPOSITION_POSITION_BANDGEGENSEITE,
  ),
  distanceToHinge: new ParameterValue(1000, 'mm'),
  percentOfDistanceToHinge: new ParameterValue(100, '%'),
  numberOfDrives: new ParameterValue(1),
  voltage: new ParameterValue(Voltage.ANTRIEB_SPANNUNG_24V, 'V'),
  driveType: new ParameterValue(DriveType.ANTRIEB_TYP_KETTE),
  driveSeries: new ParameterValue(Parameters.NO_SELECTION),
  lockingDrive: new ParameterValue(LockingDrive.WITHOUT_LOCKING_DRIVE),
  //Sashdata
  rangeOfApplication: new ParameterValue(RangeOfApplication.FACADE),
  angleOfInstallation: new ParameterValue(90, '˚'),
  openingType: new ParameterValue(OpeningType.FENSTER_OEFFNUNGSART_KIPP),
  openingSide: new ParameterValue(OpeningSide.OPENING_SIDE_RIGHT),
  openingDirection: new ParameterValue(
    OpeningDirection.FENSTER_OEFFNUNGSRICHTUNG_AUSWAERTS,
  ),
  sashWidth: new ParameterValue(1000, 'mm'),
  sashHeight: new ParameterValue(1000, 'mm'),
  area: new ParameterValue(1, 'm²'),
  [ValueKey.VALUEKEY_SANDWICH_ELEMENT]: new ParameterValue(FillType.GLASS_FILL),
  sashWeight: new ParameterValue(30, 'kg'),
  glassThickness: new ParameterValue(0, 'mm'),
  revealStructure: new ParameterValue(RevealStructure.CIRCUMFERENTIAL),
  reveal: new ParameterValue(RevealDepth.NO_REVEAL),
  revealOppositeTheHinge: new ParameterValue(100, 'mm'),
  revealTriangleOne: new ParameterValue(100, 'mm'),
  revealTriangleTwo: new ParameterValue(100, 'mm'),
  distanceSashToReveal: new ParameterValue(40, 'mm'),
  openingAngle: new ParameterValue(20, '˚'),
  openingStroke: new ParameterValue(null, 'mm'),
  windLoad: new ParameterValue(1000, 'Pa'),
  snowLoad: new ParameterValue(0, 'Pa'),
  //smokeExtraction
  exhaustDimension: new ParameterValue(34, 'mm'),
  buildingDepth: new ParameterValue(77, 'mm'),
  geometricArea: new ParameterValue(GeometricArea.ONLY_RECTANGLE),
  //UI
  weightOption: new ParameterValue(ValueKey.VALUEKEY_SASH_WEIGHT),
  distanceOption: new ParameterValue(
    ValueKey.VALUEKEY_PERCENT_OF_DISTANCE_TO_HINGE,
  ),
  openingOption: new ParameterValue(ValueKey.VALUEKEY_OPENING_ANGLE),
  profileSystem: new ParameterValueNotTranslated(
    Parameters.NO_SELECTION_SYSTEM_SERIES,
  ),
  profileSeries: new ParameterValueNotTranslated(
    Parameters.NO_SELECTION_SYSTEM_SERIES,
  ),
  profileSeriesId: new ParameterValueProfileData(Parameters.NO_SELECTION),
  correctionFactor: new ParameterValue(1),
  correctionFactorDescription: new ParameterValue(Parameters.NO_SELECTION),

  //Profiledata
  assemblySpaceFrame: new ParameterValue(120, 'mm'),
  assemblySpaceSashProfile: new ParameterValue(120, 'mm'),
  typeOfProfileDataInput: new ParameterValue(TabOptions.OPTION_FREE_ENTRY),
  spaceFrame: new ParameterValueProfileData(
    Parameters.NO_SELECTION_SYSTEM_SERIES,
  ),
  spaceSashFrame: new ParameterValueProfileData(
    Parameters.NO_SELECTION_SYSTEM_SERIES,
  ),
  profileSashId: new ParameterValueProfileData(Parameters.NO_SELECTION),
  profileSash: new ParameterValueNotTranslated(
    Parameters.NO_SELECTION_SYSTEM_SERIES,
  ),
  profileFrameId: new ParameterValueProfileData(Parameters.NO_SELECTION),
  profileFrame: new ParameterValueNotTranslated(
    Parameters.NO_SELECTION_SYSTEM_SERIES,
  ),
  profileExchangeId: new ParameterValueProfileData(Parameters.NO_SELECTION),
  profileExchange: new ParameterValueNotTranslated(
    Parameters.NO_SELECTION_SYSTEM_SERIES,
  ),
  profileBaseId: new ParameterValueProfileData(Parameters.NO_SELECTION),
  profileBase: new ParameterValueNotTranslated(
    Parameters.NO_SELECTION_SYSTEM_SERIES,
  ),

  //NRWG Data
  sideWindConsideration: new ParameterValue(
    SideWindConsideration.WITH_CONSIDERATION,
  ),
  application: new ParameterValue(Parameters.NO_SELECTION),
  mountingType: new ParameterValue(Parameters.NO_SELECTION),
  aerodynamicCase: new ParameterValue(Parameters.NO_SELECTION),
  sashFrameDistance: new ParameterValueEntryRequired(Parameters.ENTRY_REQUIRED),
};

export const EMPTY_PARAMETERS_STATE: ParametersState = {
  //Drivedata
  assemblyType: new ParameterValueEntryRequired(Parameters.ENTRY_REQUIRED),
  drivePosition: new ParameterValueEntryRequired(Parameters.ENTRY_REQUIRED),
  distanceToHinge: new ParameterValueEntryRequired(
    Parameters.ENTRY_REQUIRED,
    'mm',
  ),
  percentOfDistanceToHinge: new ParameterValueEntryRequired(
    Parameters.ENTRY_REQUIRED,
    '%',
  ),
  numberOfDrives: new ParameterValueEntryRequired(Parameters.ENTRY_REQUIRED),
  voltage: new ParameterValue(Parameters.NO_SELECTION, 'V'),
  driveType: new ParameterValue(Parameters.NO_SELECTION),
  openingSide: new ParameterValue(Parameters.NO_SELECTION),
  driveSeries: new ParameterValue(Parameters.NO_SELECTION),
  lockingDrive: new ParameterValueEntryRequired(Parameters.ENTRY_REQUIRED),
  //Sashdata
  rangeOfApplication: new ParameterValue(RangeOfApplication.FACADE),
  angleOfInstallation: new ParameterValueEntryRequired(
    Parameters.ENTRY_REQUIRED,
    '˚',
  ),
  openingType: new ParameterValueEntryRequired(Parameters.ENTRY_REQUIRED),
  openingDirection: new ParameterValueEntryRequired(Parameters.ENTRY_REQUIRED),
  sashWidth: new ParameterValueEntryRequired(Parameters.ENTRY_REQUIRED, 'mm'),
  sashHeight: new ParameterValueEntryRequired(Parameters.ENTRY_REQUIRED, 'mm'),
  area: new ParameterValueEntryRequired(Parameters.ENTRY_REQUIRED, 'm²'),
  [ValueKey.VALUEKEY_SANDWICH_ELEMENT]: new ParameterValue(FillType.GLASS_FILL),
  sashWeight: new ParameterValueEntryRequired(Parameters.ENTRY_REQUIRED, 'kg'),
  glassThickness: new ParameterValueEntryRequired(
    Parameters.ENTRY_REQUIRED,
    'mm',
  ),
  reveal: new ParameterValueEntryRequired(RevealDepth.NO_REVEAL),
  revealStructure: new ParameterValueEntryRequired(Parameters.ENTRY_REQUIRED),
  revealOppositeTheHinge: new ParameterValueEntryRequired(
    Parameters.ENTRY_REQUIRED,
    'mm',
  ),
  revealTriangleOne: new ParameterValueEntryRequired(
    Parameters.ENTRY_REQUIRED,
    'mm',
  ),
  revealTriangleTwo: new ParameterValueEntryRequired(
    Parameters.ENTRY_REQUIRED,
    'mm',
  ),
  distanceSashToReveal: new ParameterValueEntryRequired(
    Parameters.ENTRY_REQUIRED,
    'mm',
  ),
  openingAngle: new ParameterValueEntryRequired(Parameters.ENTRY_REQUIRED, '˚'),
  openingStroke: new ParameterValueEntryRequired(
    Parameters.ENTRY_REQUIRED,
    'mm',
  ),
  windLoad: new ParameterValueEntryRequired(Parameters.ENTRY_REQUIRED, 'Pa'),
  snowLoad: new ParameterValueEntryRequired(Parameters.ENTRY_REQUIRED, 'Pa'),
  //smokeExtraction
  exhaustDimension: new ParameterValueEntryRequired(
    Parameters.ENTRY_REQUIRED,
    'mm',
  ),
  buildingDepth: new ParameterValueEntryRequired(
    Parameters.ENTRY_REQUIRED,
    'mm',
  ),
  geometricArea: new ParameterValueEntryRequired(Parameters.ENTRY_REQUIRED),
  //UI
  weightOption: new ParameterValue(ValueKey.VALUEKEY_SASH_WEIGHT),
  distanceOption: new ParameterValue(
    ValueKey.VALUEKEY_PERCENT_OF_DISTANCE_TO_HINGE,
  ),
  openingOption: new ParameterValue(ValueKey.VALUEKEY_OPENING_ANGLE),
  profileSystem: new ParameterValueNotTranslated(
    Parameters.NO_SELECTION_SYSTEM_SERIES,
  ),
  profileSeries: new ParameterValueNotTranslated(
    Parameters.NO_SELECTION_SYSTEM_SERIES,
  ),
  profileSeriesId: new ParameterValueProfileData(Parameters.ENTRY_REQUIRED),
  correctionFactor: new ParameterValueEntryRequired(1),
  correctionFactorDescription: new ParameterValue(Parameters.NO_SELECTION),

  //Profiledata
  assemblySpaceFrame: new ParameterValueEntryRequired(
    Parameters.ENTRY_REQUIRED,
    'mm',
  ),
  assemblySpaceSashProfile: new ParameterValueEntryRequired(
    Parameters.ENTRY_REQUIRED,
    'mm',
  ),
  typeOfProfileDataInput: new ParameterValue(TabOptions.OPTION_PROFILE_ENTRY),
  spaceFrame: new ParameterValueProfileData(
    Parameters.NO_SELECTION_SYSTEM_SERIES,
  ),
  spaceSashFrame: new ParameterValueProfileData(
    Parameters.NO_SELECTION_SYSTEM_SERIES,
  ),
  profileSashId: new ParameterValueProfileData(Parameters.ENTRY_REQUIRED),
  profileSash: new ParameterValueNotTranslated(
    Parameters.NO_SELECTION_SYSTEM_SERIES,
  ),
  profileFrameId: new ParameterValueProfileData(Parameters.ENTRY_REQUIRED),
  profileFrame: new ParameterValueNotTranslated(
    Parameters.NO_SELECTION_SYSTEM_SERIES,
  ),
  profileExchangeId: new ParameterValueProfileData(Parameters.ENTRY_REQUIRED),
  profileExchange: new ParameterValueNotTranslated(
    Parameters.NO_SELECTION_SYSTEM_SERIES,
  ),
  profileBaseId: new ParameterValueProfileData(Parameters.ENTRY_REQUIRED),
  profileBase: new ParameterValueNotTranslated(
    Parameters.NO_SELECTION_SYSTEM_SERIES,
  ),

  //NRWG Data
  sideWindConsideration: new ParameterValue(
    SideWindConsideration.WITH_CONSIDERATION,
  ),
  application: new ParameterValueEntryRequired(Parameters.ENTRY_REQUIRED),
  mountingType: new ParameterValueEntryRequired(Parameters.ENTRY_REQUIRED),
  aerodynamicCase: new ParameterValueEntryRequired(Parameters.ENTRY_REQUIRED),
  sashFrameDistance: new ParameterValueEntryRequired(Parameters.ENTRY_REQUIRED),
};

export const EMPTY_PARAMETERS_STATE_FACADE = {
  ...EMPTY_PARAMETERS_STATE,
  rangeOfApplication: new ParameterValue(RangeOfApplication.FACADE),
  angleOfInstallation: new ParameterValue(90, '˚'),
};

export const EMPTY_PARAMETERS_STATE_ROOF = {
  ...EMPTY_PARAMETERS_STATE,
  rangeOfApplication: new ParameterValue(RangeOfApplication.ROOF),
  openingDirection: new ParameterValue(
    OpeningDirection.FENSTER_OEFFNUNGSRICHTUNG_AUSWAERTS,
  ),
  assemblyType: new ParameterValue(AssemblyType.ASSEMBLY_TYPE_FRAME),
  lockingDrive: new ParameterValue(LockingDrive.WITHOUT_LOCKING_DRIVE),
};

export const EMPTY_PARAMETERS_STATES: { [key: string]: ParametersState } = {
  [RangeOfApplication.FACADE]: EMPTY_PARAMETERS_STATE_FACADE,
  [RangeOfApplication.ROOF]: EMPTY_PARAMETERS_STATE_ROOF,
};

export const INITIAL_STATE: ParametersState = FACADE_PARAMETERS_STATE;

export const DEFAULT_STATES: { [key: string]: ParametersState } = {
  [RangeOfApplication.ROOF]: ROOF_PARAMETERS_STATE,
  [RangeOfApplication.FACADE]: FACADE_PARAMETERS_STATE,
};

type CalculateStrokeParams = [number, number, number, number, boolean, boolean];

function resetDriveSeries(
  state: ParametersState,
  action: AnyAction,
): ParametersState {
  if (action.payload.valueKeyName !== ValueKey.VALUEKEY_DRIVE_TYPE) {
    return state;
  }

  return {
    ...state,
    driveSeries: state.driveSeries.copy(Parameters.NO_SELECTION),
  };
}

function setOpeningTypeBasedOnApplication(
  state: ParametersState,
  action: AnyAction,
): ParametersState {
  if (action.payload.valueKeyName !== ValueKey.APPLICATION) {
    return state;
  }

  OpeningTypes.forEach(o => {
    const t = APPLICATIONS_BY_OPENING_TYPE[o];
    // @ts-ignore
    if (t.includes(Application[action.payload.value as any])) {
      state.openingType = state.openingType.copy(o);
    }
  });

  if (action.payload.value === Parameters.NO_SELECTION) {
    state.openingType = state.openingType.copy(Parameters.ENTRY_REQUIRED);
  }

  return state;
}

function setNumberOfDrivesIfSideInstallation(
  state: ParametersState,
  action: AnyAction,
): ParametersState {
  if (
    action.payload.valueKeyName !== ValueKey.VALUEKEY_DRIVE_POSITION ||
    state.drivePosition.value !==
      DrivePosition.ANTRIEB_MONTAGEPOSITION_POSITION_SEITLICH
  ) {
    return state;
  }

  return {
    ...state,
    numberOfDrives: state.numberOfDrives.copy(2),
  };
}

function strokeCalculationPossible(
  state: ParametersState,
  newAngleValue: ParameterValue,
): boolean {
  return allDefined(
    state.sashWidth,
    state.sashHeight,
    newAngleValue,
    state.distanceToHinge,
    state.openingType,
    state.drivePosition,
  );
}

function calculateStrokeParams(
  state: ParametersState,
  angle?: number,
): CalculateStrokeParams {
  return [
    state.sashWidth.toNumber()!,
    state.sashHeight.toNumber()!,
    state.distanceToHinge.toNumber()!,
    angle || state.openingAngle.toNumber()!,
    state.openingType.value === OpeningType.FENSTER_OEFFNUNGSART_DREH,
    state.drivePosition.value !==
      DrivePosition.ANTRIEB_MONTAGEPOSITION_POSITION_BANDGEGENSEITE,
  ];
}

function setAssemblyTypeBasedOnOpeningDirection(
  parametersState: ParametersState,
  action: AnyAction,
): ParametersState {
  if (
    parametersState.openingDirection.isUndefined() ||
    action.payload.valueKeyName !== ValueKey.VALUEKEY_OPENING_DIRECTION
  ) {
    return parametersState;
  }

  if (
    parametersState.openingDirection.value ===
    OpeningDirection.FENSTER_OEFFNUNGSRICHTUNG_AUSWAERTS
  ) {
    return {
      ...parametersState,
      assemblyType: parametersState.assemblyType.copy(
        AssemblyType.ASSEMBLY_TYPE_FRAME,
      ),
    };
  } else {
    return parametersState;
  }
}

function resetRevealStructure(
  parametersState: ParametersState,
  action: AnyAction,
): ParametersState {
  if (action.payload.valueKeyName !== ValueKey.VALUEKEY_GEOMETRIC_AREA) {
    return parametersState;
  }

  if (parametersState.geometricArea.value === GeometricArea.ONLY_RECTANGLE) {
    return {
      ...parametersState,
      revealStructure: parametersState.revealStructure.copy(
        RevealStructure.CIRCUMFERENTIAL,
      ),
    };
  } else {
    return parametersState;
  }
}

export function updateDependingParameters(
  stateAfterValueChange: ParametersState,
  action: AnyAction,
): ParametersState {
  return updateDistanceToHinge(
    resetRevealStructure(
      setAssemblyTypeBasedOnOpeningDirection(
        resetDriveSeries(
          switchDefaultParameters(
            setNumberOfDrivesIfSideInstallation(
              resetNumberOfDrives(
                setOpeningTypeBasedOnApplication(stateAfterValueChange, action),
                action,
              ),
              action,
            ),
            action,
          ),
          action,
        ),
        action,
      ),
      action,
    ),
    action,
  );
}

const parametersReducer: Reducer = (
  state: ParametersState,
  action: AnyAction,
) => {
  if (state === undefined) {
    return addInitialStrokeCalculation(INITIAL_STATE);
  }
  switch (action.type) {
    case 'UPDATE_STORE': {
      const newParameterValue = state[action.payload.valueKeyName].copy(
        action.payload.value,
      );

      const stateAfterValueChange = {
        ...state,
        [action.payload.valueKeyName]: newParameterValue,
      };

      return updateDependingParameters(stateAfterValueChange, action);
    }

    case 'CHANGE_PARAMETERS_STATE': {
      return {
        ...action.calculationParameters,
      };
    }

    default:
      return state;
  }
};

function resetNumberOfDrives(
  state: ParametersState,
  action: AnyAction,
): ParametersState {
  const resetNumberOfDrives = [ValueKey.VALUEKEY_DRIVE_POSITION].includes(
    action.payload.valueKeyName,
  );

  return {
    ...state,
    numberOfDrives: resetNumberOfDrives
      ? state.numberOfDrives.copy(1)
      : state.numberOfDrives,
  };
}

function switchDefaultParameters(
  state: ParametersState,
  action: AnyAction,
): ParametersState {
  if (action.payload.valueKeyName === ValueKey.VALUEKEY_RANGE_OF_APPLICATION) {
    return addInitialStrokeCalculation({
      ...DEFAULT_STATES[action.payload.value],
      id: state.id,
    });
  }

  return state;
}

function addInitialStrokeCalculation(state: ParametersState): ParametersState {
  if (strokeCalculationPossible(state, state.openingAngle)) {
    return {
      ...state,
      openingStroke: state.openingStroke.copy(
        calcStrokeBasic(
          ...(calculateStrokeParams(INITIAL_STATE) as CalculateStrokeParams),
        ),
      ),
    };
  }

  return state;
}

function newDistanceIfNotOppositeTheHinge(
  state: ParametersState,
  isSideHungVent: boolean,
): number | Parameters.ENTRY_REQUIRED {
  if (isSideHungVent && state.sashWidth.isDefined()) {
    return Math.round(state.sashWidth.toNumber()! * 0.7);
  } else if (state.sashHeight.isDefined()) {
    return Math.round(state.sashHeight.toNumber()! * 0.7);
  }

  return Parameters.ENTRY_REQUIRED;
}

function updateDistanceToHinge(
  state: ParametersState,
  action: AnyAction,
): ParametersState {
  //reset
  if (
    action.payload.valueKeyName === ValueKey.VALUEKEY_OPENING_TYPE ||
    action.payload.valueKeyName === ValueKey.VALUEKEY_DRIVE_POSITION ||
    action.payload.valueKeyName === ValueKey.APPLICATION
  ) {
    if (
      state.drivePosition.value ===
      DrivePosition.ANTRIEB_MONTAGEPOSITION_POSITION_BANDGEGENSEITE
    ) {
      return updateDistanceToHingeBySashSize({
        ...state,
        percentOfDistanceToHinge: state.percentOfDistanceToHinge.copy(100),
        distanceOption: state.distanceOption.copy(
          ValueKey.VALUEKEY_PERCENT_OF_DISTANCE_TO_HINGE,
        ),
      });
    }

    const isSideHungVent =
      state.openingType.value === OpeningType.FENSTER_OEFFNUNGSART_DREH;

    if (
      state.drivePosition.value ===
        DrivePosition.ANTRIEB_MONTAGEPOSITION_POSITION_SEITLICH &&
      isSideHungVent
    ) {
      return updateDistanceToHingeBySashSize({
        ...state,
        distanceToHinge: state.distanceToHinge.copy(
          STANDARD_DISTANCE_TO_HINGE_SIDE_HUNG_SIDE_INSTALLATION,
        ),
        distanceOption: state.distanceOption.copy(
          ValueKey.VALUEKEY_DISTANCE_TO_HINGE,
        ),
      });
    } else {
      const newDistance = newDistanceIfNotOppositeTheHinge(
        state,
        isSideHungVent,
      );

      return {
        ...state,
        percentOfDistanceToHinge: state.percentOfDistanceToHinge.copy(70),
        distanceOption: state.distanceOption.copy(
          ValueKey.VALUEKEY_PERCENT_OF_DISTANCE_TO_HINGE,
        ),
        distanceToHinge: state.distanceToHinge.copy(newDistance),
      };
    }
  }

  //
  if (
    [
      ValueKey.VALUEKEY_SASH_HEIGHT,
      ValueKey.VALUEKEY_SASH_WIDTH,
      ValueKey.VALUEKEY_PERCENT_OF_DISTANCE_TO_HINGE,
      ValueKey.VALUEKEY_DISTANCE_TO_HINGE,
      ValueKey.VALUEKEY_OPENING_TYPE,
      ValueKey.APPLICATION,
    ].includes(action.payload.valueKeyName)
  ) {
    //recalculate other value
    return updateDistanceToHingeBySashSize(state);
  }

  return state;
}

function distanceToHingeRecalculationParametersDefined(state: ParametersState) {
  return (
    allDefined(state.openingType, state.sashWidth, state.sashHeight) &&
    ((state.distanceOption.value ===
      ValueKey.VALUEKEY_PERCENT_OF_DISTANCE_TO_HINGE &&
      state.percentOfDistanceToHinge.isDefined()) ||
      (state.distanceOption.value === ValueKey.VALUEKEY_DISTANCE_TO_HINGE &&
        state.distanceToHinge.isDefined()))
  );
}

function updateDistanceToHingeBySashSize(
  state: ParametersState,
): ParametersState {
  if (!distanceToHingeRecalculationParametersDefined(state)) {
    if (
      state.distanceOption.value ===
      ValueKey.VALUEKEY_PERCENT_OF_DISTANCE_TO_HINGE
    ) {
      return {
        ...state,
        distanceToHinge: state.distanceToHinge.copy(Parameters.ENTRY_REQUIRED),
      };
    } else {
      return {
        ...state,
        percentOfDistanceToHinge: state.percentOfDistanceToHinge.copy(
          Parameters.ENTRY_REQUIRED,
        ),
      };
    }
  }

  const heightOrWidth =
    state.openingType.value === OpeningType.FENSTER_OEFFNUNGSART_DREH
      ? state.sashWidth
      : state.sashHeight;

  if (
    state.drivePosition.value ===
    DrivePosition.ANTRIEB_MONTAGEPOSITION_POSITION_BANDGEGENSEITE
  ) {
    return {
      ...state,
      distanceToHinge: state.distanceToHinge.copy(heightOrWidth.value),
    };
  }

  const distanceToHinge =
    state.distanceOption.value ===
    ValueKey.VALUEKEY_PERCENT_OF_DISTANCE_TO_HINGE
      ? state.distanceToHinge.copy(
          getDistance(
            heightOrWidth.toNumber()!,
            state.percentOfDistanceToHinge.toNumber()!,
          ),
        )
      : state.distanceToHinge;

  const percentOfDistanceToHinge =
    state.distanceOption.value === ValueKey.VALUEKEY_DISTANCE_TO_HINGE
      ? state.percentOfDistanceToHinge.copy(
          getPercent(
            heightOrWidth.toNumber()!,
            state.distanceToHinge.toNumber()!,
          ),
        )
      : state.percentOfDistanceToHinge;

  // if (distanceToHinge.toNumber()! < 390) {
  //   distanceToHinge = distanceToHinge.copy(390);
  //   percentOfDistanceToHinge = percentOfDistanceToHinge.copy(
  //     getPercent(heightOrWidth.toNumber()!, distanceToHinge.toNumber()!),
  //   );
  // }

  if (percentOfDistanceToHinge.toNumber()! <= 100) {
    return {
      ...state,
      distanceToHinge: distanceToHinge,
      percentOfDistanceToHinge: percentOfDistanceToHinge,
    };
  }

  if (state.openingType.value === OpeningType.FENSTER_OEFFNUNGSART_DREH) {
    return {
      ...state,
      sashWidth: state.sashWidth.copy(distanceToHinge.value),
      percentOfDistanceToHinge: state.percentOfDistanceToHinge.copy(100),
      distanceToHinge: distanceToHinge,
    };
  } else {
    return {
      ...state,
      sashHeight: state.sashHeight.copy(distanceToHinge.value),
      percentOfDistanceToHinge: state.percentOfDistanceToHinge.copy(100),
      distanceToHinge: distanceToHinge,
    };
  }
}

export function allDefined(...args: ParameterValue[]): boolean {
  return _.every(args, p => p.isDefined());
}

export default parametersReducer;

export function calculateMaxStroke(parameters: ParametersState): number {
  const calculationPossible = allDefined(
    parameters.sashWidth,
    parameters.sashHeight,
    parameters.distanceToHinge,
  );

  return calculationPossible
    ? calcStrokeBasic(
        parameters.sashWidth.toNumber()!,
        parameters.sashHeight.toNumber()!,
        parameters.distanceToHinge.toNumber()!,
        180,
        parameters.openingType.value === OpeningType.FENSTER_OEFFNUNGSART_DREH,
        parameters.drivePosition.value !==
          DrivePosition.ANTRIEB_MONTAGEPOSITION_POSITION_BANDGEGENSEITE,
      )
    : 10000;
}
