import React, { FC, useEffect, useState } from 'react';
import './RoofFrameProfileData.scss';
import FormLayout from '../../elements/FormLayout';
import InputField, { InputFieldNumber } from '../../elements/InputField';
import TextArea from '../../elements/TextArea';
import { SelectField } from '../../elements/SelectField';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { Switch } from '../../elements/Switch';
import CheckBox from '../../elements/CheckBox';
import Dialog from '../../components/Dialog';
import Table from '../components/Table';
import TableHeader from '../elements/TableHeader';
import TableRow from '../elements/TableRow';
import SearchField from '../../elements/SearchField';
import { useDispatch, useSelector } from 'react-redux';
import { AdminState, AdminThunkDispatch } from '../../redux/admin/adminStore';
import {
  FrameProfileRoof,
  NewFrameProfileRoof,
} from '../../redux/admin/adminRoofReducer';
import {
  changeFrameProfileRoof,
  createFrameProfileRoof,
  updatedEditedFrameProfileRoof,
} from '../../redux/admin/adminRoofActions';
import { Series } from '../../redux/admin/adminFacadeReducer';
import _ from 'lodash';
import {
  fieldsFilled,
  getSeriesForProfileId,
  seriesIsOnlyInwards,
  seriesIsOnlyOutwards,
} from '../general/helpers';
import Pagination from '../components/Pagination';
import AdminListIcon from '../elements/AdminListIcon';
import AdminActiveSwitch from '../components/AdminActiveSwitch';
import classNames from 'classnames';
import { useValidateProfileArticleNumber } from '../facade/FacadeSashProfileDataView';
import { AnyAction } from 'redux';
import AdminNavLink from '../common/AdminNavLink';
import { useAdminSearch } from '../hooks';
import { downloadListAsCSV } from '../common/download';
import { Edit } from '../../types';
import FormLayoutSubgroupTitle from '../../elements/FormLayoutSubgroupTitle';

const FrameProfileDataDialogTabNav: FC<
  React.PropsWithChildren<unknown>
> = () => {
  return (
    <div className="admin__tab-nav roof-frame-profile-data-dialog-tab-nav">
      <AdminNavLink to="/admin/roof/profiles/frame-profiles/basic">
        Allgemein
      </AdminNavLink>
      <AdminNavLink to="/admin/roof/profiles/frame-profiles/basic-information">
        Eckdaten
      </AdminNavLink>
      <AdminNavLink to="/admin/roof/profiles/frame-profiles/properties">
        Eigenschaften
      </AdminNavLink>
    </div>
  );
};

const FrameProfileDataDialogBasic: FC<
  React.PropsWithChildren<unknown>
> = props => {
  const allRoofSeries = useSelector<AdminState, Series[]>(
    s => s.adminRoof.series,
  );
  const editedFrameProfile = useSelector<
    AdminState,
    Edit<FrameProfileRoof> | Edit<NewFrameProfileRoof>
  >(s => s.adminRoof.editedFrameProfile);
  const dispatch: AdminThunkDispatch<AnyAction> = useDispatch();
  const articleNumberError = useValidateProfileArticleNumber(
    s => s.adminRoof.frameProfiles,
    editedFrameProfile.series!,
    editedFrameProfile.artNr || '',
    'id' in editedFrameProfile ? editedFrameProfile.id! : undefined,
  );

  useEffect(() => {
    dispatch(
      updatedEditedFrameProfileRoof({
        ...editedFrameProfile,
        oeffnungsrichtungAuswaerts:
          editedFrameProfile.series?.oeffnungsrichtungAuswaerts || null,
      }),
    );
  }, [editedFrameProfile.series]);

  function parentSeriesIsActive(
    frameProfile: Edit<FrameProfileRoof> | Edit<NewFrameProfileRoof>,
  ): boolean {
    return !!(
      !frameProfile.series ||
      allRoofSeries.find(series => series.id === frameProfile.seriesId)?.active
    );
  }

  return (
    <div className="">
      <FormLayout additionalClass="roof-frame-profile-data-dialog__layout-basic">
        <InputField
          label="Artikelnummer*"
          placeholder="Artikelnummer des Blendrahmen"
          value={editedFrameProfile.artNr}
          additionalClass="roof-frame-profile-data-dialog__article-nr"
          onChange={v =>
            dispatch(
              updatedEditedFrameProfileRoof({
                ...editedFrameProfile,
                artNr: v,
              }),
            )
          }
          errorMessage={articleNumberError}
        />
        <TextArea
          label="Information"
          additionalClass="roof-frame-profile-data-dialog__info"
          placeholder="Informationen zum System"
          value={editedFrameProfile.beschreibung || ''}
          onChange={v =>
            dispatch(
              updatedEditedFrameProfileRoof({
                ...editedFrameProfile,
                beschreibung: v,
              }),
            )
          }
        />

        <SelectField
          additionalClass="roof-frame-profile-data-dialog__serie"
          label="Serie *"
          value={
            editedFrameProfile.series
              ? {
                  value: editedFrameProfile.series.id.toString(),
                  label: editedFrameProfile.series.name,
                }
              : undefined
          }
          action={newValue => {
            dispatch(
              updatedEditedFrameProfileRoof({
                ...editedFrameProfile,
                series: allRoofSeries.find(
                  s => s.id === +newValue.value,
                ) as Series,
                seriesId: +newValue.value,
              }),
            );
          }}
          options={allRoofSeries.map(s => ({
            value: s.id.toString(),
            label: s.name,
          }))}
          name="seriesOfFrameProfileRoof"
          searchable={true}
          placeholder="Serie"
        />
        <div className="roof-frame-profile-data-dialog__switch">
          <Switch
            labelText={
              parentSeriesIsActive(editedFrameProfile)
                ? 'aktiv'
                : 'Aktivierung nicht möglich. Serie ' +
                  editedFrameProfile.series?.name +
                  ' muss aktiv sein.'
            }
            turnedOn={
              parentSeriesIsActive(editedFrameProfile)
                ? !!editedFrameProfile.active
                : false
            }
            onChange={v =>
              dispatch(
                updatedEditedFrameProfileRoof({
                  ...editedFrameProfile,
                  active: v,
                }),
              )
            }
          />
        </div>
        <div className="roof-frame-profile-data-dialog__preview">
          <Switch
            labelText="Vorschau"
            turnedOn={editedFrameProfile.preview === false ? false : true}
            onChange={v =>
              dispatch(
                updatedEditedFrameProfileRoof({
                  ...editedFrameProfile,
                  preview: v,
                }),
              )
            }
          />
        </div>
      </FormLayout>
    </div>
  );
};

const FrameProfileDataDialogBasicInformation: FC<
  React.PropsWithChildren<unknown>
> = props => {
  const editedFrameProfile = useSelector<
    AdminState,
    Edit<FrameProfileRoof> | Edit<NewFrameProfileRoof>
  >(s => s.adminRoof.editedFrameProfile);
  const dispatch: AdminThunkDispatch<AnyAction> = useDispatch();

  return (
    <div>
      <FormLayout additionalClass="roof-frame-profile-data-dialog__layout-basic-information">
        <InputFieldNumber
          label="Einspannstärke (mm)"
          placeholder="z.B. 42"
          value={editedFrameProfile.einspannstaerke}
          additionalClass="roof-frame-profile-data-dialog__strength"
          onChange={v =>
            dispatch(
              updatedEditedFrameProfileRoof({
                ...editedFrameProfile,
                einspannstaerke: v,
              }),
            )
          }
        />
        <InputFieldNumber
          label="Einspanntiefe (mm)"
          placeholder="z.B. 42"
          value={editedFrameProfile.einspanntiefe}
          additionalClass="roof-frame-profile-data-dialog__depth"
          onChange={v =>
            dispatch(
              updatedEditedFrameProfileRoof({
                ...editedFrameProfile,
                einspanntiefe: v,
              }),
            )
          }
        />
        <InputFieldNumber
          label="Ansichtsbreite innen 2 (gerade Fläche)"
          placeholder="z.B. 42"
          value={editedFrameProfile.ansichtsbreite_innen_gerade}
          additionalClass="roof-frame-profile-data-dialog__inner-width-1"
          onChange={v =>
            dispatch(
              updatedEditedFrameProfileRoof({
                ...editedFrameProfile,
                ansichtsbreite_innen_gerade: v,
              }),
            )
          }
        />
        <InputFieldNumber
          label="Ansichtsbreite innen 2 (schräge Fläche)"
          placeholder="z.B. 42"
          value={editedFrameProfile.ansichtsbreite_innen_schraege}
          additionalClass="roof-frame-profile-data-dialog__inner-width-2"
          onChange={v =>
            dispatch(
              updatedEditedFrameProfileRoof({
                ...editedFrameProfile,
                ansichtsbreite_innen_schraege: v,
              }),
            )
          }
        />
      </FormLayout>
    </div>
  );
};

const FrameProfileDataDialogProperties: FC<
  React.PropsWithChildren<unknown>
> = props => {
  const editedFrameProfile = useSelector<
    AdminState,
    Edit<FrameProfileRoof> | Edit<NewFrameProfileRoof>
  >(s => s.adminRoof.editedFrameProfile);
  const dispatch: AdminThunkDispatch<AnyAction> = useDispatch();

  return (
    <div>
      <FormLayout additionalClass="roof-frame-profile-data-dialog__layout-properties">
        <div className="roof-frame-profile-data-dialog__isolation">
          <Switch
            labelText="Isolation"
            turnedOn={!!editedFrameProfile.isolation}
            onChange={v =>
              dispatch(
                updatedEditedFrameProfileRoof({
                  ...editedFrameProfile,
                  isolation: v,
                }),
              )
            }
          />
        </div>
        <div
          className={classNames(
            'form-layout__sub-group form-layout__sub-group--2 roof-frame-profile-data-dialog__opening-direction',
            {
              'roof-frame-profile-data-dialog__opening-direction--disabled':
                seriesIsOnlyInwards(editedFrameProfile.series!) ||
                seriesIsOnlyOutwards(editedFrameProfile.series!),
            },
          )}
        >
          <FormLayoutSubgroupTitle>Öffnungsrichtung *</FormLayoutSubgroupTitle>
          {editedFrameProfile.oeffnungsrichtungEinwaerts && (
            <CheckBox
              label="Einwärts"
              checked={editedFrameProfile.oeffnungsrichtungEinwaerts}
              onClick={() =>
                dispatch(
                  updatedEditedFrameProfileRoof({
                    ...editedFrameProfile,
                    oeffnungsrichtungEinwaerts:
                      !editedFrameProfile.oeffnungsrichtungEinwaerts,
                  }),
                )
              }
            />
          )}
          {editedFrameProfile.oeffnungsrichtungAuswaerts && (
            <CheckBox
              label="Auswärts"
              checked={editedFrameProfile.oeffnungsrichtungAuswaerts}
              onClick={() =>
                dispatch(
                  updatedEditedFrameProfileRoof({
                    ...editedFrameProfile,
                    oeffnungsrichtungAuswaerts:
                      !editedFrameProfile.oeffnungsrichtungAuswaerts,
                  }),
                )
              }
            />
          )}
        </div>
        <div className="form-layout__sub-group roof-frame-profile-data-dialog__installation">
          <FormLayoutSubgroupTitle>Einbauart *</FormLayoutSubgroupTitle>
          <CheckBox
            label="Pfosten-Riegel Konstruktion eingesetzt"
            checked={
              !!editedFrameProfile.profiltypEinsatzprofil_pfostenRiegelKonstr_eingesetzt
            }
            onClick={() =>
              dispatch(
                updatedEditedFrameProfileRoof({
                  ...editedFrameProfile,
                  profiltypEinsatzprofil_pfostenRiegelKonstr_eingesetzt:
                    !editedFrameProfile.profiltypEinsatzprofil_pfostenRiegelKonstr_eingesetzt,
                }),
              )
            }
          />
          <CheckBox
            label="Pfosten-Riegel Konstruktion aufgesetzt"
            checked={
              !!editedFrameProfile.profiltypEinsatzprofil_pfostenRiegelKonstr_aufgesetzt
            }
            onClick={() =>
              dispatch(
                updatedEditedFrameProfileRoof({
                  ...editedFrameProfile,
                  profiltypEinsatzprofil_pfostenRiegelKonstr_aufgesetzt:
                    !editedFrameProfile.profiltypEinsatzprofil_pfostenRiegelKonstr_aufgesetzt,
                }),
              )
            }
          />
          <CheckBox
            label="Lochfenster im Dach"
            checked={!!editedFrameProfile.profiltypLochfenster}
            onClick={() =>
              dispatch(
                updatedEditedFrameProfileRoof({
                  ...editedFrameProfile,
                  profiltypLochfenster:
                    !editedFrameProfile.profiltypLochfenster,
                }),
              )
            }
          />
        </div>
      </FormLayout>
    </div>
  );
};

interface RoofFrameProfileDataDialogProps {
  dialogIsShown: boolean;
  setDialogIsShown: (b: boolean) => void;
}

const RoofFrameProfileDataDialog: FC<
  React.PropsWithChildren<RoofFrameProfileDataDialogProps>
> = props => {
  const dispatch: AdminThunkDispatch<AnyAction> = useDispatch();
  const editedFrameProfile = useSelector<
    AdminState,
    Edit<FrameProfileRoof> | Edit<NewFrameProfileRoof>
  >(s => s.adminRoof.editedFrameProfile);
  const articleNumberError = useValidateProfileArticleNumber(
    s => s.adminRoof.frameProfiles,
    editedFrameProfile.series!,
    editedFrameProfile.artNr || '',
    'id' in editedFrameProfile ? editedFrameProfile.id! : undefined,
  );

  function resetInputFields(): void {
    dispatch(updatedEditedFrameProfileRoof({} as Edit<FrameProfileRoof>));
  }

  function mandatoryFieldsFilled(): boolean {
    return !!(
      fieldsFilled(editedFrameProfile.series, editedFrameProfile.artNr) &&
      (editedFrameProfile.oeffnungsrichtungAuswaerts ||
        editedFrameProfile.oeffnungsrichtungEinwaerts) &&
      (editedFrameProfile.profiltypEinsatzprofil_pfostenRiegelKonstr_aufgesetzt ||
        editedFrameProfile.profiltypEinsatzprofil_pfostenRiegelKonstr_eingesetzt ||
        editedFrameProfile.profiltypLochfenster) &&
      !articleNumberError
    );
  }

  function newFrameProfile(): void {
    if (mandatoryFieldsFilled()) {
      dispatch(
        createFrameProfileRoof(editedFrameProfile as NewFrameProfileRoof),
      );
    }
  }

  function editFrameProfile(): void {
    if (mandatoryFieldsFilled()) {
      dispatch(changeFrameProfileRoof(editedFrameProfile as FrameProfileRoof));
    }
  }

  return (
    <Dialog
      setDialogIsShown={props.setDialogIsShown}
      dialogIsShown={props.dialogIsShown}
      headingText="Blendrahmen bearbeiten"
      componentClass="full-view-dialog"
      key={props.dialogIsShown.toString()}
      footerProps={{
        notTranslated: true,
        cancelAction: () => {
          resetInputFields();
          props.setDialogIsShown(false);
        },
        saveAction: mandatoryFieldsFilled()
          ? () => {
              'id' in editedFrameProfile && editedFrameProfile.id
                ? editFrameProfile()
                : newFrameProfile();
              props.setDialogIsShown(false);
            }
          : undefined,
        primaryActionLabelText:
          'id' in editedFrameProfile && editedFrameProfile.id
            ? 'Speichern'
            : 'Anlegen',
      }}
    >
      <FrameProfileDataDialogTabNav />
      <Routes>
        <Route path="/basic/*" element={<FrameProfileDataDialogBasic />} />
        <Route
          path="/basic-information/*"
          element={<FrameProfileDataDialogBasicInformation />}
        />
        <Route
          path="/properties/*"
          element={<FrameProfileDataDialogProperties />}
        />{' '}
      </Routes>
    </Dialog>
  );
};

const RoofFrameProfileData: FC<React.PropsWithChildren<unknown>> = () => {
  const [dialogIsShown, setDialogIsShown] = useState(false);
  const frameProfilesRoof = useSelector<AdminState, FrameProfileRoof[]>(
    s => s.adminRoof.frameProfiles,
  );
  const allSeries = useSelector<AdminState, Series[]>(
    state => state.adminRoof.series,
  );

  const [indexOfFirstPageElement, setIndexOfFirstPageElement] = useState(0);
  const [page, setPage] = useState(1);
  const dispatch: AdminThunkDispatch<AnyAction> = useDispatch();
  const [searchString, setSearchString] = useState('');
  const navigate = useNavigate();
  const [filterActive, setFilterActive, searchResult] = useAdminSearch(
    frameProfilesRoof,
    searchString,
    ['artNr'],
    entry => {
      const series = getSeriesForProfileId(entry.seriesId, allSeries);
      return [series?.name || '', series?.system.name || ''];
    },
  );

  function triggerCreationMode(): void {
    showDialog();
    dispatch(updatedEditedFrameProfileRoof({} as Edit<FrameProfileRoof>));
  }

  function getCurrentTableContent(): FrameProfileRoof[] {
    return searchResult.slice(
      indexOfFirstPageElement,
      indexOfFirstPageElement + 20,
    );
  }

  function showDialog(): void {
    setDialogIsShown(true);
    navigate('/admin/roof/profiles/frame-profiles/basic');
  }

  function enableEditFrameProfile(frameProfile: FrameProfileRoof): void {
    dispatch(updatedEditedFrameProfileRoof(_.cloneDeep(frameProfile)));
    showDialog();
  }

  return (
    <>
      <RoofFrameProfileDataDialog
        dialogIsShown={dialogIsShown}
        setDialogIsShown={setDialogIsShown}
      />
      <div className="sub-header">
        <div className="sub-header__title">Blendrahmen</div>

        <SearchField
          setSearchString={setSearchString}
          searchString={searchString}
          placeholderText="Blendrahmen suchen..."
          small={true}
          setFilterActive={setFilterActive}
          filterActive={filterActive}
        />
        <button
          className="sub-header__download-button"
          onClick={() =>
            downloadListAsCSV(
              searchResult,
              ';',
              ['legacyId'],
              'BlendrahmenDach',
              { series: (s: Series) => s.name },
              { system: profile => profile.series.system.name },
            )
          }
        >
          CSV
        </button>
        <button
          className="sub-header__button sub-header__button--add"
          onClick={() => triggerCreationMode()}
        >
          Neuer Eintrag
        </button>
      </div>
      <div className="frame-profile-data">
        <Table>
          <TableHeader>
            <th>Artikelnummer</th>
            <th>System</th>
            <th>Serie</th>
            <th>Einspannstärke (mm)</th>
            <th>Einspanntiefe (mm)</th>
            <th>Ansichtsbreite innen (gerade)</th>
            <th>Ansichtsbreite innen (schräg)</th>
            <th>Isolation</th>
            <th>Einwärts</th>
            <th>Auswärts</th>
            <th>Aktiv</th>
            <th>Aktion</th>
          </TableHeader>
          {getCurrentTableContent().map(frameProfileRoof => (
            <TableRow key={frameProfileRoof.id}>
              <td>{frameProfileRoof.artNr}</td>
              <td>{frameProfileRoof.series.system.name}</td>
              <td>{frameProfileRoof.series.name}</td>
              <td>{frameProfileRoof.einspannstaerke}</td>
              <td>{frameProfileRoof.einspanntiefe}</td>
              <td>{frameProfileRoof.ansichtsbreite_innen_gerade}</td>
              <td>{frameProfileRoof.ansichtsbreite_innen_schraege}</td>
              <td>
                <AdminListIcon checked={frameProfileRoof.isolation} />
              </td>
              <td>
                <AdminListIcon
                  checked={frameProfileRoof.oeffnungsrichtungEinwaerts}
                />
              </td>
              <td>
                <AdminListIcon
                  checked={frameProfileRoof.oeffnungsrichtungAuswaerts}
                />
              </td>
              <td>
                <AdminActiveSwitch
                  active={frameProfileRoof.active}
                  description={frameProfileRoof.beschreibung || ''}
                  itemNumber={frameProfileRoof.artNr}
                  saveAction={active =>
                    dispatch(
                      changeFrameProfileRoof({
                        ...frameProfileRoof,
                        active: active,
                      }),
                    )
                  }
                />
              </td>

              <td>
                <button
                  onClick={() => {
                    enableEditFrameProfile(frameProfileRoof);
                  }}
                >
                  Bearbeiten
                </button>
              </td>
            </TableRow>
          ))}
        </Table>
      </div>
      <Pagination
        searchString={searchString}
        numberOfPages={searchResult.length}
        page={page}
        setPage={setPage}
        indexOfFirstPageElement={indexOfFirstPageElement}
        setIndexOfFirstPageElement={setIndexOfFirstPageElement}
      />
    </>
  );
};
export default RoofFrameProfileData;
