import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IState, MyCalcThunkDispatch } from '../../../redux/store';
import { FormattedMessage } from 'react-intl';
import { notAllRequiredCalculationParametersDefined } from '../../../redux/valueKey';
import { PerformanceClasses } from '../../../redux/nrwgReducer';
import './PerformanceClassColumn.scss';
import { ReactComponent as IconCheckedClasses } from '../../../assets/icons/IconCheckedClasses.svg';
import { ReactComponent as IconWarning } from '../../../assets/icons/IconWarning.svg';
import EmptyStateContainer from '../../../elements/EmptyStateContainer';
import { setPerformanceClassesOpen } from '../../../redux/uiStateActions';
import classNames from 'classnames';
import { AnyAction } from 'redux';

const PerformanceClassColumn: FC<React.PropsWithChildren<unknown>> = () => {
  const nrwgInvalid = useSelector<IState, boolean>(
    s => !!s.nrwg.validation && !s.nrwg.validation.valid,
  );
  const allRequiredParametersDefined = useSelector<IState, boolean>(s =>
    notAllRequiredCalculationParametersDefined(s),
  );
  const performanceClassesOpen = useSelector<IState, boolean>(
    s => s.ui.performanceClassesOpen,
  );

  const performanceClasses = useSelector<
    IState,
    PerformanceClasses | undefined
  >(s => s.nrwg.performanceClasses);
  const FIELDS = [
    'aerodynamics',
    'safety',
    'snowLoad',
    'temperature',
    'windLoad',
    'heat',
  ];
  const dispatch: MyCalcThunkDispatch<AnyAction> = useDispatch();

  return (
    <div
      className={classNames('performance-class-column', {
        'performance-class-column--closed': !performanceClassesOpen,
      })}
    >
      <div
        className="performance-class-column__expand-action"
        onClick={() =>
          dispatch(setPerformanceClassesOpen(!performanceClassesOpen))
        }
      >
        {performanceClasses && (
          <div className="performance-class-column__icon-check">
            <IconCheckedClasses />
          </div>
        )}
        {(allRequiredParametersDefined || nrwgInvalid) && (
          <div className="performance-class-column__icon-warning">
            <IconWarning />
          </div>
        )}
        <div className="performance-class-column__icon-unfold">unfold_more</div>
        <div className="performance-class-column__expand-lable">
          <FormattedMessage id="TESTED_PERFORMANCE_CLASSES" />
        </div>
      </div>
      <button
        className="performance-class-column__collapse-action"
        onClick={() =>
          dispatch(setPerformanceClassesOpen(!performanceClassesOpen))
        }
      >
        unfold_less
      </button>
      <div className="performance-class-column__content">
        <div className="performance-class-column__title">
          <FormattedMessage id="TESTED_PERFORMANCE_CLASSES" />
        </div>

        {nrwgInvalid && (
          <div className="performance-class-column__empty-state-invalid-parameters">
            <div className="performance-class-column__empty-state-invalid-parameters-marker"></div>
            <FormattedMessage id="PERFORMANCE_CLASSES_EMPTY_STATE_INVALID_PARAMETERS" />
          </div>
        )}
        {allRequiredParametersDefined && (
          <div className="performance-class-column__empty-state-missing-parameters">
            <EmptyStateContainer size="sm">
              <FormattedMessage id="PERFORMANCE_CLASSES_EMPTY_STATE_MISSING_PARAMETERS" />
            </EmptyStateContainer>
          </div>
        )}
        {performanceClasses && (
          <div>
            {FIELDS.map(
              f =>
                performanceClasses[f]?.label && (
                  <dl className="performance-class-column__class-list" key={f}>
                    <dt className="performance-class-column__class-list-item">
                      <IconCheckedClasses /> {performanceClasses[f]?.label}
                    </dt>
                    <dd className="performance-class-column__class-list-item-text">
                      {performanceClasses[f]?.details}
                    </dd>
                  </dl>
                ),
            )}
          </div>
        )}
      </div>
    </div>
  );
};
export default PerformanceClassColumn;
