import { MessageDescriptor } from 'react-intl';

export enum Parameters {
  NO_SELECTION = 'NO_SELECTION',
  ENTRY_REQUIRED = 'ENTRY_REQUIRED',
  NO_SELECTION_SYSTEM_SERIES = 'NO_SELECTION_SYSTEM_SERIES',
}

const SERIALIZE_TO_NULL_CONSTANTS = [
  Parameters.NO_SELECTION,
  Parameters.ENTRY_REQUIRED,
];

export type Value =
  | string
  | number
  | Parameters.ENTRY_REQUIRED
  | Parameters.NO_SELECTION
  | null;

export default class ParameterValue {
  value: Value;
  unit: string | undefined;

  constructor(value: Value, unit?: string) {
    this.value = value;
    this.unit = unit;
  }

  serialize(): Value {
    if (SERIALIZE_TO_NULL_CONSTANTS.includes(this.value as Parameters)) {
      return null;
    }
    return this.value;
  }

  toString(
    formatMessage: (
      descriptor: MessageDescriptor,
      values?:
        | Record<string, string | number | boolean | null | undefined>
        | undefined,
    ) => string,
  ): string {
    if (
      this.value === '' ||
      (typeof this.value === 'string' && !this.value.match(/^\d*$/))
    ) {
      return formatMessage({
        id: this.value || Parameters.ENTRY_REQUIRED,
      });
    }

    return this.unit ? `${this.value} ${this.unit}` : `${this.value}`;
  }

  isUndefined(): boolean {
    return (
      this.value == null ||
      this.value === Parameters.ENTRY_REQUIRED ||
      this.value === Parameters.NO_SELECTION ||
      this.value === Parameters.NO_SELECTION_SYSTEM_SERIES
    );
  }

  isDefined(): boolean {
    return !this.isUndefined();
  }

  toNumber(): number | undefined {
    return this.isUndefined() ? undefined : +this.value!;
  }

  copy(newValue: Value): ParameterValue {
    return new ParameterValue(
      newValue == null || newValue === '' ? Parameters.NO_SELECTION : newValue,
      this.unit,
    );
  }
}

export class ParameterValueProfileData extends ParameterValue {
  isUndefined(): boolean {
    return (
      this.value == null ||
      this.value === Parameters.ENTRY_REQUIRED ||
      this.value === Parameters.NO_SELECTION
    );
  }

  copy(newValue: Value): ParameterValueProfileData {
    return new ParameterValueProfileData(
      newValue == null || newValue === ''
        ? Parameters.ENTRY_REQUIRED
        : newValue,
      this.unit,
    );
  }
}

export class ParameterValueEntryRequired extends ParameterValue {
  copy(newValue: Value): ParameterValueEntryRequired {
    return new ParameterValueEntryRequired(
      newValue == null || newValue === ''
        ? Parameters.ENTRY_REQUIRED
        : newValue,
      this.unit,
    );
  }
}

export class ParameterValueNotTranslated extends ParameterValue {
  toString(
    formatMessage: (
      descriptor: MessageDescriptor,
      values?:
        | Record<string, string | number | boolean | null | undefined>
        | undefined,
    ) => string,
  ): string {
    return `${this.value}`;
  }

  copy(newValue: Value): ParameterValueNotTranslated {
    return new ParameterValueNotTranslated(newValue, this.unit);
  }
}
