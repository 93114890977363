import { Reducer } from 'redux';
import { ADMIN_FACADE_ACTIONS } from './adminFacadeActions';
import _ from 'lodash';
import {
  DrivePosition,
  DriveSeries,
  DriveType,
  OpeningDirection,
  OpeningType,
  RangeOfApplication,
} from '../constants';
import { TEST_PHASE_ACTIONS } from './editedTestPhaseActions';
import { Funktionssicherheit } from '../../admin/constants';
import { USER_MANAGEMENT_ACTIONS } from './user-management/editedUserAndCompanyActions';
import { fieldsFilled } from '../../admin/general/helpers';
import { Edit } from '../../types';
import { ACTIVE_STATE } from './adminRoofReducer';

export interface BaseProfileDataWithId extends BaseProfileData {
  id: number;
}

export interface BaseProfileData {
  seriesId: number;
  series: Series;
  artNr: string;
  beschreibung: string | undefined;
  kammermassAussen: number;
  kammermassInnen: number;
  active: boolean;
  preview: boolean;
  bautiefe: number;
  maxFluegelgewicht: number;
  oeffnungsrichtungEinwaerts: boolean;
  oeffnungsrichtungAuswaerts: boolean;
  anwendungKipp: boolean;
  anwendungKlapp: boolean;
  anwendungDreh: boolean;
  anwendungSenkklapp: boolean;
  blockfenster: boolean;
  standardfenster: boolean;
  flaechenbuendig: boolean;
}

interface BasicOrExchangeProfile {
  id: number;
  seriesId: number;
  series: Series;
  artNr: string;
  active: boolean;
  preview: boolean;
  kammermassInnen: number;
  kammermassAussen: number;
  beschreibung: string;
  bautiefe: number;
}

export interface System extends NewSystem {
  id: number;
}

export interface NewSystem {
  active: boolean;
  info: string;
  name: string;
  shortCode: string;
}

export interface NewSeries {
  name: string;
  system: System;
  shortCode: string;
  bautiefe: number;
  laengeEckverbinder: number;
  active: boolean;
  preview: boolean;
  asAbstandAufgesetzteBaender: number;
  b2Auswaerts: number | undefined;
  b2Einwaerts: number | undefined;
  lichteabzugsmassAuswaerts: number | undefined;
  lichteabzugsmassEinwaerts: number | undefined;
  fluegelaufschlag: number;
  rangeOfApplication: RangeOfApplication;
  material: string;
  info: string;
  oeffnungsrichtungEinwaerts: boolean;
  oeffnungsrichtungAuswaerts: boolean;
  verriegelungMoeglich: boolean;
  profileHeight?: number;
  profileWeightPerMeter?: number;
}

export interface Series extends NewSeries {
  id: number;
}

export interface FrameProfileFacade extends NewFrameProfileFacade {
  id: number;
}

export interface NewFrameProfileFacade extends BaseProfileData {
  maxFluegelaufschlag: number;
  kammermassFuerIntegrierteMontage: number | undefined | null;
  fuerIntegrierteAntriebe: boolean;
  fuerGrundprofilGeeignet: boolean;
  fuerWechselprofilGeeignet: boolean;
  tipTronic: boolean;
  montierbarLinks: boolean;
  montierbarRechts: boolean;
  montierbarOben: boolean;
  montierbarUnten: boolean;
  profiltypEinsatzprofilPfostenRiegelKonstr: boolean;
  profiltypLochfenster: boolean;
  series: Series;
}

export interface SashProfileFacade extends NewSashProfileFacade {
  id: number;
}

export interface NewSashProfileFacade extends BaseProfileData {
  maxEinspannstaerke: number;
  minEinspannstaerke: number;
  fuerIntegrierteMontage: boolean;
  verriegelungsbeschlagGeeignet: boolean;
}

export interface BasicProfile extends BasicOrExchangeProfile {
  pfostenRiegelKonstr: boolean;
}

export type ExchangeProfile = BasicOrExchangeProfile;

export type CVWert = {
  grad: number;
  cvDurchflusswert: number;
};

export type Kurvensatz = {
  value1: number;
  value2: number;
  compare1: string;
  compare2: string;
  description: string;
  cvwerte: CVWert[];
};

export interface CVKurve extends NewCVKurve {
  id: number;
  readonly forDoubleFlapApplicationTopOrBottomHung: boolean;
  readonly forDoubleFlapApplicationSideHung: boolean;
}

export interface NewCVKurve {
  kurvensaetze: Kurvensatz[];
  name: string;
  rangeOfApplication: RangeOfApplication;
}

export interface Console extends NewConsole {
  id: number;
}

export interface StrokeType extends NewStrokeType {
  id: number;
}

export interface Point {
  x: number | null;
  y: number | null;
}

export interface NewStrokeType {
  drucklastPunkte: Point[];
  bezeichnung: string;
  kette: boolean;
  elementgroesse: number;
  active: boolean;
}

export interface DriveStrokeVariant extends NewDriveStrokeVariant {
  id: number;
}

export interface NewDriveStrokeVariant {
  maxHub: number | null;
  breite: number | null;
  laenge: number | null;
  tiefe: number | null;
  gewicht: number | null;
  power: number | null;
  powerConsumption: number | null;
}

export interface AdminDrive extends NewAdminDrive {
  id: number;
}

export interface NumberOfDrivesConfigItem {
  minSashWidth: number | null;
  maxSashWidth: number | null;
  minSashHeight: number | null;
  maxSashHeight: number | null;
  minNumberOfDrives: number;
  maxNumberOfDrives: number;
  maximumWindLoad: number | null;
}

export interface ScopeOfApplication {
  // maximumOpeningStroke: number | null;
  rangeOfApplication: RangeOfApplication;
  openingDirection: OpeningDirection;
  drivePosition: DrivePosition;
  openingType: OpeningType;
  minimalDistanceToHinge?: number | null;
  maximumOpeningAngle?: number | null;
  maximumOpeningStroke?: number | null;
  numberOfDrivesConfig: NumberOfDrivesConfigItem[];
  needsAtLeastOneGasSpring: boolean;
}

export interface DriveFamily extends NewDriveFamily {
  id: number;
}

export type EditedDriveFamily = Edit<DriveFamily> | Edit<NewDriveFamily>;

export interface NewDriveFamily {
  appendOpeningSideToName: boolean;
  anwendungsbereiche: ScopeOfApplication[];
  name: string;
  antriebsfamilie: string;
  spiegelbar: boolean;
  symmetrisch: boolean;
  verriegelungsKuerzel: string;
  driveSeries: DriveSeries;
  active: boolean;
  technologie: string;
  legacyId: string;
  maxOpeningAngleSideInstBottomHung: number | undefined;
  drawBridge: boolean;
  printDrawBridgeSuffix: boolean;
  supportsLockingDrive: boolean;
  leftAvailable: boolean;
  rightAvailable: boolean;
  consoleSetFacade: ConsoleSet | undefined;
  consoleSetRoof: ConsoleSet | undefined;
}

export interface NewAdminDrive {
  antriebsHubVarianten: Partial<DriveStrokeVariant>[];
  powerConsumption: number | undefined;
  power: number | undefined;
  standardLaufzeit: number;
  highSpeedLaufzeit: number;
  breiteKurzeSeite: number;
  hubtypen: NewStrokeType[];
  active: boolean;
  artikelNr: string;
  bezeichnung: string;
  info: string;
  antriebsfamilie: DriveFamily;
  volt24: boolean;
  volt230: boolean;
  druckKraft: number;
  zugKraft: number;
  nennverriegelungsKraft: number;
  abschaltreserveZugKraft: number;
  abschaltreserveDruckKraft: number;
  anzahlAngriffspunkte: number;
  maxAnzahlAntriebe: number;
  neuAb2016: boolean;
  testedForNRWGFacade: boolean;
  testedForNRWGRoof: boolean;
  preview: boolean;
  powerAndPowerConsumption: PowerAndpowerConsumption;
  highSpeedPowerConsumption: number | undefined;
  highSpeedPower: number | undefined;
}

export enum PowerAndpowerConsumption {
  STANDARD = 'STANDARD',
  STROKE_VARIANTS = 'STROKE_VARIANTS',
  HIGH_SPEED = 'HIGH_SPEED',
}

export interface NewConsole {
  active: boolean;
  artNr: string;
  name: string;
  isFrameConsole: boolean;
  isSashConsole: boolean;
  rangeOfApplication: RangeOfApplication;
}

export interface ConsoleSet extends NewConsoleSet {
  id: number;
}

export type ConsoleSetApplications = Partial<
  Record<
    OpeningType,
    { sideInstallation: boolean; sideOppositeTheHinge: boolean }
  >
>;

export interface NewConsoleSet {
  driveFamilies: DriveFamily[];
  consoleSetApplications: ConsoleSetApplications;
  active: boolean;
  preview: boolean;
  artNr: string;
  name: string;
  onlyConsoleSetAssignment: boolean;
  sashConsole: Console;
  frameConsole: Console;
  aluminium: boolean;
  wood: boolean;
  glass: boolean;
  steel: boolean;
  woodAluminium: boolean;
  plastic: boolean;
  driveSeries: DriveSeries[];
  assemblyPositionFrame: boolean;
  assemblyPositionSash: boolean;
  openingDirectionInwards: boolean;
  openingDirectionOutwards: boolean;
  montageOnTop: boolean;
  assemblyIntegrated: boolean;
  assemblyRotatable: boolean;
  spaceRequirementSashProfile: number;
  spaceRequirementFrameProfile: number;
  minSpaceBetweenSets: number;
  montageplatzInklLagerschraube: number;
  abstandFluegelaussenkanteZumAntriebB3: number;
  rangeOfApplication: RangeOfApplication;
  withGasSpring: boolean;
  freeEntry: boolean;
  maxOpeningAngle: number | undefined;
  requiresVcdTailPiece: boolean;
}

export interface ConsoleSetAssignment extends NewConsoleSetAssignment {
  id: number;
}

export interface NewConsoleSetAssignment {
  system: System;
  series: Series;
  sashProfileFacade?: BaseProfileDataWithId;
  frameProfileFacade?: BaseProfileDataWithId;
  sashProfileRoof?: BaseProfileDataWithId;
  frameProfileRoof?: BaseProfileDataWithId;
  allFluegelprofile?: boolean;
  allFluegelprofileDach?: boolean;
  allBlendrahmen?: boolean;
  allBlendrahmenDach?: boolean;
  consoleSet: ConsoleSet;
  kettenaustritt: number;
  active: boolean;
  preview: boolean;
  rangeOfApplication: RangeOfApplication;
}

export interface TestPhaseSystem extends TestPhaseSystemWithOptionalId {
  id: number;
}

export interface TestPhaseSystemWithOptionalId {
  id?: number;
  system: System;
  klassFunk: Funktionssicherheit;
  umgebungstemp: number;
  klassWaermebest: string;
  testPhaseSeries: TestPhaseSeriesWithOptionalId[];
  maxWindLoad: number | undefined;
}

export interface TestPhaseSeriesOpeningDirectionWithOptionalId {
  id?: number;
  oeffnungsrichtung: string;
  anwendungen: TestPhaseApplicationWithOptionalId[];
}

export interface TestPhaseApplicationWithOptionalId {
  id?: number;
  application: string;
  maxSashWeight: number | undefined;
  maxWidthToHeightRelation?: number;
  mountingTypes: TestPhaseMountingTypeWithOptionalId[];
}

export interface TestPhaseMountingTypeWithOptionalId {
  id?: number;
  mountingType: string;
  profileTypes: TestPhaseProfileTypeWithOptionalId[];
}

export interface TestPhaseProfileTypeWithOptionalId {
  id?: number;
  profileType: string;
  cvCurve?: CVKurve | undefined;
}

export interface TestPhaseApplicationOverviewWithOptionalId {
  id?: number;
  minBreite: number;
  maxBreite: number;
  minHoehe: number;
  maxHoehe: number;
  minAntriebe: number;
  maxAntriebe: number;
  seitlMontage: boolean;
  gegenueberBand: boolean;
  traverse: boolean;
  kipp: boolean;
  klapp: boolean;
  dreh: boolean;
  senkklapp: boolean;
  oeffnungsrichtung_einw: boolean;
  oeffnungsrichtung_ausw: boolean;
}

export interface TestPhaseWindLoadOrSnowLoadWithOptionalId {
  id?: number;
  gepruefteFlaeche: number;
  glasstaerke: number;
  einbauwinkel: number;
  gepruefteSchneelast: number;
  gepruefteWindlast: number;
  antriebsAnzahl: number;
  antriebsAnzahlBis: number;
  oeffnungsrichtung_einw: boolean;
  oeffnungsrichtung_ausw: boolean;
  format_quer: boolean;
  format_hoch: boolean;
  montagePosition_ggBand: boolean;
  montagePosition_seitl: boolean;
  montagePosition_traverse: boolean;
  verriegelung: boolean;
  driveSeries: string;
}

export interface TestPhaseSeriesWithOptionalId {
  id?: number;
  series: Series;
  minGepruefteFlaeche: number | undefined;
  maxGepruefteFlaeche: number | undefined;
  minEinbauwinkel: number | undefined;
  maxEinbauwinkel: number | undefined;
  applicationOverviews: TestPhaseApplicationOverviewWithOptionalId[];
  windLoads: TestPhaseWindLoadOrSnowLoadWithOptionalId[];
  openingDirection: TestPhaseSeriesOpeningDirectionWithOptionalId[];
  frameProfiles: FrameProfileFacade[];
  sashProfiles: SashProfileFacade[];
  exchangeProfiles: ExchangeProfile[];
  baseProfiles: BasicProfile[];
}

export interface TestPhaseWithOptionalId {
  id?: number;
  name: string;
  active: ACTIVE_STATE;
  minAbstandDrehband: number | undefined | null;
  testPhaseSystems: TestPhaseSystemWithOptionalId[];
}

export interface TestPhase extends TestPhaseWithOptionalId {
  id: number;
}

export interface User extends UserWithOptionalId {
  id: number;
}

export interface UserWithOptionalId {
  id?: number;
  admin: boolean;
  firstName: string;
  lastName: string;
  username: string;
  email: string;
  active: boolean;
  password: string;
  certificate?: File;
  company: Company;
}

export interface Company extends CompanyWithOptionalId {
  [key: string]:
    | number
    | string
    | boolean
    | undefined
    | BluekitArticleNumberMappings[]
    | Company;

  id: number;
}

export interface CompanyWithOptionalId {
  bluekitPriceList: string | undefined;
  bluekitDhdeMontageCalculation: string | undefined;
  showPerformanceDataInNrwgLabel: boolean;
  debtorNumber: string | undefined;
  parentCompany?: Company;
  id?: number;
  name: string;
  nameShort: string;
  street: string;
  number: string;
  zip: string;
  city: string;
  country: string;
  companyType: string;
  active: boolean;
  cprNumberRoof: string;
  cprNumberFacade: string;
  cprNumberFacadeSteel: string;
  trainingCertificateFileName?: string;
  mycalc: boolean;
  bluekit: boolean;
  bluekitDiscountCode: string;
  bluekitArticleNumberMappings: BluekitArticleNumberMappings[];
}

interface DriveSeriesObject {
  name: string;
  driveType: DriveType;
}

interface MaxStrokes {
  id: number;
  driveSeries: DriveSeriesObject;
  openingType: string;
  maxOpenStroke: number;
}

export interface SeriesENMaxStrokeFacade extends MaxStrokes {
  withLockingDrive: boolean;
}

export interface SeriesENMaxStrokeRoof extends MaxStrokes {
  voltage: string;
}

export const INITIAL_STATE: AdminFacadeState = {
  driveSeries: [],
  bluekitArticleNumberMappings: [],
  trainingCertificateToUpload: undefined,
  deleteTrainingCertificateOnSave: false,
  systems: [],
  series: [],
  frameProfiles: [],
  sashProfiles: [],
  basicProfiles: [],
  exchangeProfiles: [],
  cvKurven: [],
  consoles: [],
  consoleSets: [],
  consoleSetAssignments: [],
  testPhases: [],
  editedTestPhase: undefined,
  editedTestPhaseSystem: undefined,
  editedTestPhaseSeries: undefined,
  editedTestPhaseSeriesOpeningDirection: undefined,
  editedTestPhaseApplication: undefined,
  editedTestPhaseMountingType: undefined,
  editedTestPhaseProfileType: undefined,
  users: [],
  editedUser: undefined,
  companies: [],
  editedCompany: undefined,
  editedTestPhaseApplicationOverviews: [],
  editedTestPhaseApplicationOverview: {},
  editedTestPhaseLoads: [],
  editedTestPhaseLoad: {},
  editedTestPhaseValid: false,
  drives: [],
  driveFamilies: [],
  editedDriveFamily: undefined,
  editedDrive: undefined,
  editedStrokeType: undefined,
  seriesENMaxStrokesFacade: [],
  editedSeriesENMaxStrokesFacade: undefined,
};

export interface BluekitArticleNumberMappings {
  sourceArticleNumber: string;
  companyId?: number;
  destinationArticleNumber: string;
}

export type AdminFacadeState = {
  driveSeries: DriveSeries[];
  bluekitArticleNumberMappings: BluekitArticleNumberMappings[];
  trainingCertificateToUpload: File | undefined;
  deleteTrainingCertificateOnSave: boolean;
  editedSeriesENMaxStrokesFacade: SeriesENMaxStrokeFacade[] | undefined;
  editedStrokeType:
    | (Edit<StrokeType | NewStrokeType> & { index: number | undefined })
    | undefined;
  editedDrive: Edit<AdminDrive> | Edit<NewAdminDrive> | undefined;
  editedDriveFamily: Edit<NewDriveFamily> | Edit<DriveFamily> | undefined;
  editedTestPhaseApplicationOverview?:
    | TestPhaseApplicationOverviewWithOptionalId
    | {};
  editedTestPhaseLoad?: TestPhaseWindLoadOrSnowLoadWithOptionalId | {};
  editedTestPhaseApplicationOverviews: TestPhaseApplicationOverviewWithOptionalId[];
  editedTestPhaseLoads: TestPhaseWindLoadOrSnowLoadWithOptionalId[];
  systems: System[];
  series: Series[];
  frameProfiles: FrameProfileFacade[];
  sashProfiles: SashProfileFacade[];
  basicProfiles: BasicProfile[];
  exchangeProfiles: ExchangeProfile[];
  cvKurven: CVKurve[];
  consoles: Console[];
  consoleSets: ConsoleSet[];
  consoleSetAssignments: ConsoleSetAssignment[];
  testPhases: TestPhase[];
  editedTestPhase: TestPhaseWithOptionalId | undefined;
  editedTestPhaseSystem: TestPhaseSystemWithOptionalId | undefined;
  editedTestPhaseSeries: TestPhaseSeriesWithOptionalId | undefined;
  editedTestPhaseSeriesOpeningDirection:
    | TestPhaseSeriesOpeningDirectionWithOptionalId
    | undefined;
  editedTestPhaseApplication: TestPhaseApplicationWithOptionalId | undefined;
  editedTestPhaseMountingType: TestPhaseMountingTypeWithOptionalId | undefined;
  editedTestPhaseProfileType: TestPhaseProfileTypeWithOptionalId | undefined;
  users: User[];
  editedUser: UserWithOptionalId | undefined;
  companies: Company[];
  editedCompany: CompanyWithOptionalId | undefined;
  editedTestPhaseValid: boolean;
  drives: AdminDrive[];
  driveFamilies: DriveFamily[];
  seriesENMaxStrokesFacade: SeriesENMaxStrokeFacade[];
};

export function getUpdatedElementIfValueChanges<T>(
  elementSelectedForEditing: T | undefined,
  newElementSelectedForEditing: T,
  values?: Record<string, any>,
): T {
  const updatedOpeningDirections = elementSelectedForEditing
    ? {
        ...newElementSelectedForEditing,
        ...values,
      }
    : newElementSelectedForEditing;
  return updatedOpeningDirections;
}

export function replaceElement<T extends { id: number }>(
  elements: T[],
  element: T,
): T[] {
  const updatedElements = [...elements];
  const indexToUpdate = _.findIndex(updatedElements, ['id', element.id]);
  updatedElements.splice(indexToUpdate, 1, element);

  return updatedElements;
}

function validateEditedTestPhase(state: AdminFacadeState): AdminFacadeState {
  if (!state.editedTestPhase) {
    return state;
  }

  const valid = fieldsFilled(
    state.editedTestPhase.name,
    state.editedTestPhase.minAbstandDrehband,
    ...state.editedTestPhase.testPhaseSystems.map(s => s.maxWindLoad),
    ...state.editedTestPhase.testPhaseSystems.flatMap(s =>
      s.testPhaseSeries.flatMap(se => [
        se.minGepruefteFlaeche,
        se.maxGepruefteFlaeche,
        se.minEinbauwinkel,
        se.maxEinbauwinkel,
      ]),
    ),
    ...state.editedTestPhase.testPhaseSystems.flatMap(s =>
      s.testPhaseSeries.flatMap(se =>
        se.openingDirection.flatMap(od =>
          od.anwendungen.flatMap(a => [a.maxSashWeight]),
        ),
      ),
    ),
    ...state.editedTestPhase.testPhaseSystems.flatMap(s =>
      s.testPhaseSeries.flatMap(se =>
        se.openingDirection.flatMap(od =>
          od.anwendungen.flatMap(a =>
            a.mountingTypes.flatMap(mt =>
              mt.profileTypes.flatMap(pt => [pt.cvCurve]),
            ),
          ),
        ),
      ),
    ),
  );

  return { ...state, editedTestPhaseValid: valid };
}

const adminFacadeReducer: Reducer<
  AdminFacadeState | undefined,
  ADMIN_FACADE_ACTIONS | TEST_PHASE_ACTIONS | USER_MANAGEMENT_ACTIONS
> = (
  state: AdminFacadeState | undefined,
  action: ADMIN_FACADE_ACTIONS | TEST_PHASE_ACTIONS | USER_MANAGEMENT_ACTIONS,
) => {
  if (state === undefined) {
    return INITIAL_STATE;
  }

  switch (action.type) {
    case 'UPDATE_SERIES_EN_MAX_STROKES_FACADE': {
      return {
        ...state,
        seriesENMaxStrokesFacade: action.seriesENMaxStrokesFacade,
      };
    }

    case 'UPDATE_DRIVE_SERIES': {
      return { ...state, driveSeries: action.driveSeries };
    }

    case 'UPDATE_COMPANIES': {
      return { ...state, companies: action.companies };
    }

    case 'STORE_EDITED_COMPANY': {
      return { ...state, editedCompany: action.editedCompany };
    }

    case 'STORE_TRAINING_CERTIFICATE_TO_UPLOAD': {
      return { ...state, trainingCertificateToUpload: action.certificate };
    }

    case 'STORE_DELETED_TRAINING_CERTIFICATE_ON_SAVE': {
      return { ...state, deleteTrainingCertificateOnSave: action.deleteOnSave };
    }

    case 'UPDATE_SINGLE_COMPANY': {
      const users: User[] = state.users;

      users.forEach(user => {
        if (user.company.id === action.company.id) {
          user.company = action.company;
        }
      });

      return {
        ...state,
        companies: replaceElement(state.companies, action.company),
      };
    }

    case 'ADD_NEW_COMPANY': {
      return {
        ...state,
        companies: [action.company, ...state.companies],
      };
    }

    case 'UPDATE_USERS': {
      return { ...state, users: action.users };
    }

    case 'UPDATE_BLUEKIT_ARTICLE_NUMBER_MAPPINGS': {
      return { ...state, bluekitArticleNumberMappings: action.mappings };
    }

    case 'UPDATE_SINGLE_USER': {
      return { ...state, users: replaceElement(state.users, action.user) };
    }

    case 'STORE_EDITED_USER': {
      return { ...state, editedUser: action.editedUser };
    }

    case 'ADD_NEW_USER': {
      return {
        ...state,
        users: [action.user, ...state.users],
      };
    }

    case 'UPDATE_SYSTEM_FACADE': {
      return {
        ...state,
        systems: replaceElement(state.systems, action.system),
      };
    }

    case 'UPDATE_SYSTEMS_FACADE': {
      return { ...state, systems: action.systems };
    }

    case 'ADD_SYSTEM_FACADE': {
      return { ...state, systems: [action.system, ...state.systems] };
    }

    case 'ADD_SERIES_FACADE': {
      return { ...state, series: [action.series, ...state.series] };
    }

    case 'ADD_FRAME_PROFILE_FACADE': {
      return {
        ...state,
        frameProfiles: [action.frameProfile, ...state.frameProfiles],
      };
    }

    case 'ADD_SASH_PROFILE': {
      return {
        ...state,
        sashProfiles: [action.sashProfile, ...state.sashProfiles],
      };
    }

    case 'ADD_BASIC_PROFILE': {
      return {
        ...state,
        basicProfiles: [action.basicProfile, ...state.basicProfiles],
      };
    }

    case 'ADD_EXCHANGE_PROFILE': {
      return {
        ...state,
        exchangeProfiles: [action.exchangeProfile, ...state.exchangeProfiles],
      };
    }

    case 'ADD_CV_CURVE_FACADE': {
      return {
        ...state,
        cvKurven: [action.cvCurve, ...state.cvKurven],
      };
    }

    case 'UPDATE_ALL_SERIES_FACADE': {
      return { ...state, series: action.series };
    }

    case 'UPDATE_SERIES_FACADE': {
      return {
        ...state,
        series: replaceElement(state.series, action.series),
      };
    }

    case 'UPDATE_FRAME_PROFILES_FACADE': {
      return { ...state, frameProfiles: action.frameProfiles };
    }

    case 'UPDATE_FRAME_PROFILE_FACADE': {
      return {
        ...state,
        frameProfiles: replaceElement(state.frameProfiles, action.frameProfile),
      };
    }

    case 'UPDATE_SASH_PROFILES_FACADE': {
      return { ...state, sashProfiles: action.sashProfiles };
    }

    case 'UPDATE_SASH_PROFILE_FACADE': {
      return {
        ...state,
        sashProfiles: replaceElement(state.sashProfiles, action.sashProfile),
      };
    }

    case 'UPDATE_BASIC_PROFILES': {
      return { ...state, basicProfiles: action.basicProfiles };
    }

    case 'UPDATE_BASIC_PROFILE': {
      return {
        ...state,
        basicProfiles: replaceElement(state.basicProfiles, action.basicProfile),
      };
    }

    case 'UPDATE_EXCHANGE_PROFILES': {
      return { ...state, exchangeProfiles: action.exchangeProfiles };
    }

    case 'UPDATE_EXCHANGE_PROFILE': {
      return {
        ...state,
        exchangeProfiles: replaceElement(
          state.exchangeProfiles,
          action.exchangeProfile,
        ),
      };
    }

    case 'UPDATE_CV_KURVEN_FACADE': {
      return { ...state, cvKurven: action.cvKurven };
    }

    case 'UPDATE_CV_CURVE_FACADE': {
      return {
        ...state,
        cvKurven: replaceElement(state.cvKurven, action.cvCurve),
      };
    }

    case 'UPDATE_CONSOLES_FACADE': {
      return { ...state, consoles: action.consoles };
    }

    case 'UPDATE_CONSOLE_FACADE': {
      return {
        ...state,
        consoles: replaceElement(state.consoles, action.console),
      };
    }

    case 'ADD_CONSOLE_FACADE': {
      return { ...state, consoles: [action.console, ...state.consoles] };
    }

    case 'UPDATE_ADMIN_DRIVES': {
      return { ...state, drives: action.drives };
    }

    case 'UPDATE_ADMIN_DRIVE': {
      return {
        ...state,
        drives: replaceElement(state.drives, action.drive),
      };
    }

    case 'ADD_ADMIN_DRIVE': {
      return { ...state, drives: [action.drive, ...state.drives] };
    }

    case 'UPDATE_DRIVE_FAMILIES': {
      return { ...state, driveFamilies: action.families };
    }

    case 'UPDATE_EDITED_DRIVE_FAMILY': {
      return {
        ...state,
        editedDriveFamily: action.family,
      };
    }

    case 'UPDATE_EDITED_DRIVE': {
      return {
        ...state,
        editedDrive: action.drive,
      };
    }

    case 'UPDATE_EDITED_STROKES_FACADE': {
      return { ...state, editedSeriesENMaxStrokesFacade: action.facadeStrokes };
    }

    case 'UPDATE_EDITED_STROKE_TYPE': {
      return {
        ...state,
        editedStrokeType: action.strokeType,
      };
    }

    case 'UPDATE_DRIVE_FAMILY': {
      return {
        ...state,
        driveFamilies: replaceElement(state.driveFamilies, action.family),
      };
    }

    case 'ADD_DRIVE_FAMILY': {
      return {
        ...state,
        driveFamilies: [action.family, ...state.driveFamilies],
      };
    }

    case 'UPDATE_CONSOLE_SETS_FACADE': {
      return { ...state, consoleSets: action.consoleSets };
    }

    case 'UPDATE_CONSOLE_SET_FACADE': {
      return {
        ...state,
        consoleSets: replaceElement(state.consoleSets, action.consoleSet),
      };
    }

    case 'ADD_CONSOLE_SET_FACADE': {
      return {
        ...state,
        consoleSets: [action.consoleSet, ...state.consoleSets],
      };
    }

    case 'UPDATE_CONSOLE_SET_ASSIGNMENTS_FACADE': {
      return {
        ...state,
        consoleSetAssignments: action.consoleSetAssignments,
      };
    }

    case 'UPDATE_CONSOLE_SET_ASSIGNMENT_FACADE': {
      return {
        ...state,
        consoleSetAssignments: replaceElement(
          state.consoleSetAssignments,
          action.consoleSetAssignment,
        ),
      };
    }

    case 'ADD_CONSOLE_SET_ASSIGNMENT_FACADE': {
      return {
        ...state,
        consoleSetAssignments: [
          action.consoleSetAssignment,
          ...state.consoleSetAssignments,
        ],
      };
    }

    case 'UPDATE_TEST_PHASES': {
      return {
        ...state,
        testPhases: action.testPhases,
      };
    }

    case 'UPDATE_TEST_PHASE': {
      return {
        ...state,
        testPhases: replaceElement(state.testPhases, action.testPhase),
      };
    }

    case 'ADD_TEST_PHASE': {
      return {
        ...state,
        testPhases: [action.testPhase, ...state.testPhases],
      };
    }

    //editable test phase actions
    case 'UPDATE_EDITED_TEST_PHASE': {
      return validateEditedTestPhase({
        ...state,
        editedTestPhase: action.editedTestPhase,
      });
    }

    case 'UPDATE_EDITED_TEST_PHASE_SYSTEM': {
      if (!action.editedTestPhaseSystem) {
        return {
          ...state,
          editedTestPhaseSystem: undefined,
        };
      }

      const updatedSystem = {
        ...action.editedTestPhaseSystem,
        ...action.values,
      };

      if (state.editedTestPhase) {
        replaceInList(
          state.editedTestPhase.testPhaseSystems,
          action.editedTestPhaseSystem,
          updatedSystem,
        );
      }

      return validateEditedTestPhase({
        ...state,
        editedTestPhaseSystem: updatedSystem,
      });
    }

    case 'UPDATE_EDITED_TEST_PHASE_SERIES': {
      if (!action.editedTestPhaseSeries) {
        return {
          ...state,
          editedTestPhaseSeries: undefined,
        };
      }

      const updatedSeries = {
        ...action.editedTestPhaseSeries,
        ...action.values,
      };

      if (state.editedTestPhaseSystem) {
        replaceInList(
          state.editedTestPhaseSystem.testPhaseSeries,
          action.editedTestPhaseSeries,
          updatedSeries,
        );
      }

      return validateEditedTestPhase({
        ...state,
        editedTestPhaseSeries: updatedSeries,
      });
    }

    case 'UPDATE_EDITED_TEST_PHASE_SERIES_OPENING_DIRECTION': {
      if (!action.editedTestPhaseSeriesOpeningDirection) {
        return {
          ...state,
          editedTestPhaseSeriesOpeningDirection: undefined,
        };
      }

      const updatedOpeningDirections = getUpdatedElementIfValueChanges(
        state.editedTestPhaseSeriesOpeningDirection,
        action.editedTestPhaseSeriesOpeningDirection,
        action.values,
      );

      if (
        state.editedTestPhaseSeriesOpeningDirection &&
        state.editedTestPhaseSeries?.openingDirection
      ) {
        replaceInList(
          state.editedTestPhaseSeries?.openingDirection,
          action.editedTestPhaseSeriesOpeningDirection,
          updatedOpeningDirections,
        );
      }

      return validateEditedTestPhase({
        ...state,
        editedTestPhaseSeriesOpeningDirection: updatedOpeningDirections,
      });
    }

    case 'UPDATE_EDITED_TEST_PHASE_APPLICATION': {
      if (!action.editedTestPhaseApplication) {
        return {
          ...state,
          editedTestPhaseApplication: undefined,
        };
      }

      const updatedApplications = getUpdatedElementIfValueChanges(
        state.editedTestPhaseApplication,
        action.editedTestPhaseApplication,
        action.values,
      );

      if (
        state.editedTestPhaseApplication &&
        state.editedTestPhaseSeriesOpeningDirection?.anwendungen
      ) {
        replaceInList(
          state.editedTestPhaseSeriesOpeningDirection?.anwendungen,
          action.editedTestPhaseApplication,
          updatedApplications,
        );
      }

      return validateEditedTestPhase({
        ...state,
        editedTestPhaseApplication: updatedApplications,
      });
    }

    case 'UPDATE_EDITED_TEST_PHASE_MOUNTING_TYPE': {
      if (!action.editedTestPhaseMountingType) {
        return {
          ...state,
          editedTestPhaseMountingType: undefined,
        };
      }

      const updatedMountingType = getUpdatedElementIfValueChanges(
        state.editedTestPhaseMountingType,
        action.editedTestPhaseMountingType,
        action.values,
      );

      if (
        state.editedTestPhaseMountingType &&
        state.editedTestPhaseApplication?.mountingTypes
      ) {
        replaceInList(
          state.editedTestPhaseApplication?.mountingTypes,
          action.editedTestPhaseMountingType,
          updatedMountingType,
        );
      }
      return validateEditedTestPhase({
        ...state,
        editedTestPhaseMountingType: updatedMountingType,
      });
    }

    case 'UPDATE_EDITED_TEST_PHASE_PROFILE_TYPE': {
      if (!action.editedTestPhaseProfileType) {
        return {
          ...state,
          editedTestPhaseProfileType: undefined,
        };
      }

      const updatedProfileType = getUpdatedElementIfValueChanges(
        state.editedTestPhaseProfileType,
        action.editedTestPhaseProfileType,
        action.values,
      );

      if (
        state.editedTestPhaseProfileType &&
        state.editedTestPhaseMountingType?.profileTypes
      ) {
        replaceInList(
          state.editedTestPhaseMountingType?.profileTypes,
          action.editedTestPhaseProfileType,
          updatedProfileType,
        );
      }

      return validateEditedTestPhase({
        ...state,
        editedTestPhaseProfileType: updatedProfileType,
      });
    }

    case 'UPDATE_EDITED_TEST_PHASE_APPLICATION_OVERVIEWS': {
      return {
        ...state,
        editedTestPhaseApplicationOverviews:
          action.editedTestPhaseApplicationOverviews,
      };
    }

    case 'UPDATE_EDITED_TEST_PHASE_APPLICATION_OVERVIEW': {
      return {
        ...state,
        editedTestPhaseApplicationOverview:
          action.editedTestPhaseApplicationOverview,
      };
    }

    case 'UPDATE_EDITED_TEST_PHASE_LOADS': {
      return {
        ...state,
        editedTestPhaseLoads: action.editedTestPhaseLoads,
      };
    }

    case 'UPDATE_EDITED_TEST_PHASE_LOAD': {
      return {
        ...state,
        editedTestPhaseLoad: action.editedTestPhaseLoad,
      };
    }

    default:
      return state;
  }
};

export function replaceInList<T>(
  list: T[],
  elementToReplace: T,
  updatedElement: T,
): void {
  const index = list.findIndex(listElement => {
    return listElement === elementToReplace;
  });

  if (index === -1) {
    throw Error('Element not found');
  }

  list.splice(index, 1, updatedElement);
}

export default adminFacadeReducer;
