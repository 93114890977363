import React, { FC, useEffect, useState } from 'react';
import { DialogFE } from '../Dialog';
import './BuildingAreaDialog.scss';
import FormLayout from '../../elements/FormLayout';
import InputField from '../../elements/InputField';
import { useIntl } from 'react-intl';
import {
  createBuildingArea,
  updateBuildingArea,
} from '../../redux/projectsActions';
import { useDispatch } from 'react-redux';
import { BuildingArea, NEW_BUILDING_AREA } from '../../redux/projectsReducer';
import { showDialog } from '../../redux/uiStateActions';
import { AnyAction } from 'redux';
import { MyCalcThunkDispatch } from '../../redux/store';

interface BuildingAreaDialogProps {
  editedOrNewBuildingArea: BuildingArea | undefined;
}

const BuildingAreaDialog: FC<
  React.PropsWithChildren<BuildingAreaDialogProps>
> = (props: BuildingAreaDialogProps) => {
  const [buildingAreaName, setBuildingAreaName] = useState('');
  const { formatMessage } = useIntl();

  useEffect(() => {
    setBuildingAreaName(props.editedOrNewBuildingArea?.name || '');
  }, [props.editedOrNewBuildingArea?.name]);

  const dispatch: MyCalcThunkDispatch<AnyAction> = useDispatch();

  function editMode(): boolean {
    return props.editedOrNewBuildingArea !== NEW_BUILDING_AREA;
  }

  function resetInputFields(): void {
    setBuildingAreaName('');
  }

  function saveActionDisabled(): boolean {
    return !buildingAreaName;
  }

  function saveBuildingArea(): void {
    if (editMode()) {
      dispatch(
        updateBuildingArea(
          props.editedOrNewBuildingArea!.id!,
          buildingAreaName,
        ),
      );
    } else {
      dispatch(createBuildingArea(buildingAreaName));
    }
    resetInputFields();
    dispatch(showDialog(undefined));
  }

  return (
    <DialogFE
      heading={
        editMode()
          ? 'EDIT_BUILDING_AREA_DIALOG_HEADING'
          : 'NEW_BUILDING_AREA_DIALOG_HEADING'
      }
      componentClass=""
      footerProps={{
        cancelAction: true,
        saveAction: saveActionDisabled() ? undefined : saveBuildingArea,
        primaryActionLabel: !editMode()
          ? 'CREATE_PROJECT_LABEL'
          : 'calculations_button_save',
      }}
    >
      <FormLayout
        additionalClass="building-area-dialog"
        key={props.editedOrNewBuildingArea?.toString()}
      >
        <InputField
          additionalClass="building-area-dialog__name"
          label={formatMessage({
            id: 'LABEL_BUILDING_AREA_NAME_INPUT',
          })}
          placeholder={formatMessage({
            id: 'PLACEHOLDER_BUILDING_NAME_INPUT',
          })}
          value={buildingAreaName}
          id="projectName"
          onChange={setBuildingAreaName}
        />
      </FormLayout>
    </DialogFE>
  );
};

export default BuildingAreaDialog;
