import React, { FC, ReactNode } from 'react';
import classNames from 'classnames';
import './FormLayout.scss';
import { conditionalClass } from '../lib/utils';

interface FormLayoutProps {
  fourColumns?: boolean;
  children: ReactNode;
  additionalClass?: string;
  inline?: boolean;
}

export const FormLayout: FC<React.PropsWithChildren<FormLayoutProps>> = (
  props: FormLayoutProps,
) => {
  return (
    <div
      className={classNames(
        'form-layout',
        conditionalClass(props.additionalClass),
        {
          'form-layout--inline': props.inline,
          'form-layout--four-columns': props.fourColumns,
        },
      )}
    >
      {props.children}
    </div>
  );
};

interface FormLayoutElementProps {
  children: ReactNode;
  rowStart: number;
  rowHeight?: number;
  columnStart: number;
  columnWidth?: number;
  additionalClass?: string;
  verticallyCentered?: boolean;
}

export const FormLayoutElement: FC<
  React.PropsWithChildren<FormLayoutElementProps>
> = props => {
  return (
    <div
      style={{
        gridColumnStart: props.columnStart.toString(),
        gridColumnEnd: props.columnStart + (props.columnWidth || 1),
        gridRowStart: props.rowStart.toString(),
        gridRowEnd: props.rowStart + (props.rowHeight || 1),
      }}
      className={classNames(
        'form-layout__element' + conditionalClass(props.additionalClass),
        {
          'form-layout__element--horizontal-centered': props.verticallyCentered,
        },
      )}
    >
      {props.children}
    </div>
  );
};

export default FormLayout;
