import React, { FC } from 'react';
import './SelectFieldBox.scss';
import EditableBox from './EditableBox';

import {
  MenuPlacement,
  SelectField,
  SelectOption,
} from '../../../elements/SelectField';

import { changeCalculationParameter } from '../../../redux/parametersActions';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { IState, MyCalcThunkDispatch } from '../../../redux/store';
import { ValueKey } from '../../../redux/valueKey';
import { AnyAction } from 'redux';
import { Help } from '../ParameterSection';

interface SelectFieldBoxProps {
  heading: string;
  isSingleEdit: boolean;
  helpContent: Help;
  valueKey: ValueKey;
  name: string;
  optionsFilter?: (state: IState, options: Options) => Options;
  options: Options;
}

type Options = { name: string }[];

export const SelectFieldBox: FC<
  React.PropsWithChildren<SelectFieldBoxProps>
> = (props: SelectFieldBoxProps) => {
  const dispatch: MyCalcThunkDispatch<AnyAction> = useDispatch();
  const { formatMessage } = useIntl();
  const state = useSelector((state: IState) => state);

  function mapDriveOptionsToSelectOptions(
    options: Options,
  ): SelectOption<string>[] {
    function optionToSelectOption(option: {
      name: string;
    }): SelectOption<string> {
      return {
        value: option.name,
        label: formatMessage({ id: option.name }),
      };
    }
    const filteredOptions = props.optionsFilter
      ? props.optionsFilter(state, options)
      : options;
    return filteredOptions.map(optionToSelectOption);
  }

  function findMatchingValue(): SelectOption<string> | undefined {
    return mapDriveOptionsToSelectOptions(props.options).find(
      v => v.value === state.parameters.driveSeries.value,
    );
  }

  return (
    <EditableBox {...props} heading={props.name}>
      <div data-testid="select-box">
        <div className="select-field-box">
          <SelectField
            hideLabel={true}
            value={findMatchingValue()}
            action={newValue => {
              dispatch(
                changeCalculationParameter(props.valueKey, newValue.value),
              );
            }}
            name={props.name}
            menuPlacement={MenuPlacement.TOP}
            options={mapDriveOptionsToSelectOptions(props.options)}
          />
        </div>
      </div>
    </EditableBox>
  );
};

export default SelectField;
