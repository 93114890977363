import * as React from 'react';
// @ts-ignore
const SvgEinzelklappeKippMit2Wlw = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="Einzelklappe_Kipp_mit_2_WLW_svg__Ebene_2"
    data-name="Ebene 2"
    viewBox="0 0 1440 1080"
    {...props}
    style={{ marginBottom: '-70px', marginTop: '-50px' }}
  >
    <defs>
      <style>
        {
          '.Einzelklappe_Kipp_mit_2_WLW_svg__cls-10,.Einzelklappe_Kipp_mit_2_WLW_svg__cls-14,.Einzelklappe_Kipp_mit_2_WLW_svg__cls-16,.Einzelklappe_Kipp_mit_2_WLW_svg__cls-18,.Einzelklappe_Kipp_mit_2_WLW_svg__cls-19,.Einzelklappe_Kipp_mit_2_WLW_svg__cls-2,.Einzelklappe_Kipp_mit_2_WLW_svg__cls-3{stroke:#1e1e1c}.Einzelklappe_Kipp_mit_2_WLW_svg__cls-10,.Einzelklappe_Kipp_mit_2_WLW_svg__cls-14,.Einzelklappe_Kipp_mit_2_WLW_svg__cls-16,.Einzelklappe_Kipp_mit_2_WLW_svg__cls-18,.Einzelklappe_Kipp_mit_2_WLW_svg__cls-19,.Einzelklappe_Kipp_mit_2_WLW_svg__cls-2{fill:none}.Einzelklappe_Kipp_mit_2_WLW_svg__cls-16,.Einzelklappe_Kipp_mit_2_WLW_svg__cls-18,.Einzelklappe_Kipp_mit_2_WLW_svg__cls-19,.Einzelklappe_Kipp_mit_2_WLW_svg__cls-3{stroke-miterlimit:10}.Einzelklappe_Kipp_mit_2_WLW_svg__cls-10,.Einzelklappe_Kipp_mit_2_WLW_svg__cls-18{stroke-width:.75px}.Einzelklappe_Kipp_mit_2_WLW_svg__cls-2{stroke-width:1.88px}.Einzelklappe_Kipp_mit_2_WLW_svg__cls-10,.Einzelklappe_Kipp_mit_2_WLW_svg__cls-14,.Einzelklappe_Kipp_mit_2_WLW_svg__cls-2{stroke-linejoin:round}.Einzelklappe_Kipp_mit_2_WLW_svg__cls-3{fill:#fff}.Einzelklappe_Kipp_mit_2_WLW_svg__cls-28,.Einzelklappe_Kipp_mit_2_WLW_svg__cls-30{fill:#1e1e1c}.Einzelklappe_Kipp_mit_2_WLW_svg__cls-30{font-family:ArialMT,Arial}.Einzelklappe_Kipp_mit_2_WLW_svg__cls-33{fill:#b0b0b1}.Einzelklappe_Kipp_mit_2_WLW_svg__cls-16{stroke-width:2px}.Einzelklappe_Kipp_mit_2_WLW_svg__cls-14{stroke-width:.62px}.Einzelklappe_Kipp_mit_2_WLW_svg__cls-30{font-size:27.5px}.Einzelklappe_Kipp_mit_2_WLW_svg__cls-34{fill:#ececec}'
        }
      </style>
    </defs>
    <path
      d="m650.74 598.74 368.39-260.65 368.38 260.65H650.74zM38.58 432.03 220.6 252.27l167.9 354.2L38.58 432.03z"
      className="Einzelklappe_Kipp_mit_2_WLW_svg__cls-34"
    />
    <path
      d="m220.6 252.27 199.89-91.14 167.13 354.01-99.51 45.64-80-152.91 54.91-25.11-64.03-135.32-96.94 44.83 63.87 134.84 42.19-19.24 80 152.91-99.61 45.69-167.9-354.2z"
      className="Einzelklappe_Kipp_mit_2_WLW_svg__cls-33"
    />
    <path
      d="m342.55 303.21 96.07-43.6 24.4 124.43-97.1 44.36-23.37-125.19z"
      style={{
        opacity: 0.3,
        fill: '#b0b0b1',
      }}
    />
    <path
      d="M650.74 598.74h736.77l-368.38-260.65-368.39 260.65zM1054.85 358.9l195.81 138.71-89.03-211.94M220.6 252.27l199.89-91.14 167.13 354.01-199.12 91.33"
      className="Einzelklappe_Kipp_mit_2_WLW_svg__cls-16"
    />
    <path
      d="M38.58 432.03 220.6 252.27l167.9 354.2L38.58 432.03z"
      style={{
        strokeWidth: 2,
        strokeLinecap: 'round',
        strokeLinejoin: 'round',
        fill: 'none',
        stroke: '#1e1e1c',
      }}
    />
    <path
      d="m365.92 427.11-63.87-134.84 96.94-44.83 64.03 135.32-97.1 44.35z"
      className="Einzelklappe_Kipp_mit_2_WLW_svg__cls-16"
    />
    <path
      d="m463.02 352.13-.18 28.52-63.87-134.84V217.1l64.05 135.03z"
      className="Einzelklappe_Kipp_mit_2_WLW_svg__cls-3"
    />
    <path
      d="m366.52 426.32-23.55-125.16 96.07-43.6 23.55 124.84-96.07 43.92z"
      className="Einzelklappe_Kipp_mit_2_WLW_svg__cls-16"
    />
    <path
      d="m366.1 398.59-.18 28.52-63.87-134.84v-28.71l64.05 135.03z"
      className="Einzelklappe_Kipp_mit_2_WLW_svg__cls-3"
    />
    <path
      d="m1053.72 362.57 14.31-20.66 198.98 141.62-14.26 18.52-198.97-141.62"
      style={{
        fill: '#b0b0b1',
        strokeWidth: '2.5px',
        strokeMiterlimit: 10,
        stroke: '#1e1e1c',
      }}
    />
    <text
      className="Einzelklappe_Kipp_mit_2_WLW_svg__cls-30"
      transform="translate(897.58 266.49)"
    >
      <tspan x={0} y={0}>
        {props.distanceFlapUpperEdgeToRidgeFA}
      </tspan>
    </text>
    <text
      style={{
        letterSpacing: '-.06em',
        fontSize: '27.5px',
        fontFamily: 'ArialMT,Arial',
        fill: '#1e1e1c',
      }}
      transform="translate(1037.66 467.06)"
    >
      <tspan x={0} y={0}>
        {props.distanceNRWGToRidgeFA}
      </tspan>
    </text>
    <text
      className="Einzelklappe_Kipp_mit_2_WLW_svg__cls-30"
      transform="translate(1276.88 573.51)"
    >
      <tspan x={0} y={0}>
        {'\u03B1'}
      </tspan>
    </text>
    <text
      style={{
        fontSize: '27.5px',
        fontFamily: 'ArialMT,Arial',
        fill: '#1e1e1c',
      }}
      transform="rotate(-23.8 1283.652 -760.132)"
    >
      <tspan x={0} y={0}>
        {props.widthB}
      </tspan>
    </text>
    <text
      style={{
        fontSize: 30,
        fontFamily: 'ArialMT,Arial',
        fill: '#1e1e1c',
      }}
      transform="rotate(-23.8 1298.186 -808.637) scale(.58)"
    >
      <tspan x={0} y={0}>
        {props.frameFR}
      </tspan>
    </text>
    <text
      style={{
        fontSize: '27.5px',
        fontFamily: 'ArialMT,Arial',
        fill: '#1e1e1c',
      }}
      transform="rotate(64.08 -140.11 404.14)"
    >
      <tspan x={0} y={0}>
        {props.heightH}
      </tspan>
    </text>
    <text
      style={{
        fontSize: 30,
        fontFamily: 'ArialMT,Arial',
        fill: '#1e1e1c',
      }}
      transform="rotate(64.08 -158.025 415.008) scale(.58)"
    >
      <tspan x={0} y={0}>
        {props.frameFR}
      </tspan>
    </text>
    <text
      style={{
        fontFamily: 'ArialMT,Arial',
        fill: '#1e1e1c',
        fontSize: '27.5px',
        letterSpacing: '-.06em',
      }}
      transform="rotate(65.18 -8.386 367.645)"
    >
      <tspan x={0} y={0}>
        {props.distanceNRWGToRidgeFA}
      </tspan>
    </text>
    <text
      className="Einzelklappe_Kipp_mit_2_WLW_svg__cls-30"
      transform="translate(302.39 534.7)"
    >
      <tspan x={0} y={0}>
        {'\u03B1'}
      </tspan>
    </text>
    <path
      d="M275.81 539.66A131.691 131.691 0 0 1 321.33 494"
      className="Einzelklappe_Kipp_mit_2_WLW_svg__cls-19"
    />
    <path
      d="m279.35 539.36-7.8 8.07 2.72-10.89 5.08 2.82zM321.03 497.54l8.05-7.83-10.88 2.76 2.83 5.07z"
      className="Einzelklappe_Kipp_mit_2_WLW_svg__cls-28"
    />
    <path
      d="M1256.77 591.33c1.38-22.65 8.51-43.74 19.95-61.84"
      className="Einzelklappe_Kipp_mit_2_WLW_svg__cls-18"
    />
    <path
      d="m1259.05 589.94-2.51 8.03-1.84-8.21 4.35.18zM1277.8 531.97l2.62-8-6.27 5.62 3.65 2.38z"
      className="Einzelklappe_Kipp_mit_2_WLW_svg__cls-28"
    />
    <path
      d="M1153.32 285.67h-2.5"
      className="Einzelklappe_Kipp_mit_2_WLW_svg__cls-18"
    />
    <path
      d="M1145.9 285.67H912.47"
      style={{
        strokeDasharray: '0 0 4.91 4.91',
        strokeWidth: '.75px',
        strokeMiterlimit: 10,
        fill: 'none',
        stroke: '#1e1e1c',
      }}
    />
    <path
      d="M910.01 285.67h-2.5M1012.03 338.09h-2.5"
      className="Einzelklappe_Kipp_mit_2_WLW_svg__cls-18"
    />
    <path
      d="M1004.29 338.09h-91.66"
      style={{
        strokeDasharray: '0 0 5.24 5.24',
        strokeWidth: '.75px',
        strokeMiterlimit: 10,
        fill: 'none',
        stroke: '#1e1e1c',
      }}
    />
    <path
      d="M910.01 338.09h-2.5M923.64 377.77v-33.03"
      className="Einzelklappe_Kipp_mit_2_WLW_svg__cls-18"
    />
    <path
      d="m925.82 346.22-2.18-8.13-2.18 8.13h4.36z"
      className="Einzelklappe_Kipp_mit_2_WLW_svg__cls-28"
    />
    <path
      d="M923.64 279.03v-50.29"
      className="Einzelklappe_Kipp_mit_2_WLW_svg__cls-18"
    />
    <path
      d="m925.82 277.54-2.18 8.13-2.18-8.13h4.36z"
      className="Einzelklappe_Kipp_mit_2_WLW_svg__cls-28"
    />
    <path
      d="m1015.98 343.82-1.47 2.02"
      className="Einzelklappe_Kipp_mit_2_WLW_svg__cls-18"
    />
    <path
      d="m1011.48 350.03-53 73.22"
      style={{
        strokeDasharray: '0 0 5.17 5.17',
        strokeWidth: '.75px',
        strokeMiterlimit: 10,
        fill: 'none',
        stroke: '#1e1e1c',
      }}
    />
    <path
      d="m956.96 425.34-1.46 2.03M945.29 406.78l11.38 8.05"
      className="Einzelklappe_Kipp_mit_2_WLW_svg__cls-18"
    />
    <path
      d="m954.19 415.75 7.9 2.92-5.38-6.48-2.52 3.56z"
      className="Einzelklappe_Kipp_mit_2_WLW_svg__cls-28"
    />
    <path
      d="m1047.92 366.56-1.45 2.04"
      className="Einzelklappe_Kipp_mit_2_WLW_svg__cls-18"
    />
    <path
      d="m1043.5 372.78-51.93 73.08"
      style={{
        strokeDasharray: '0 0 5.12 5.12',
        strokeWidth: '.75px',
        strokeMiterlimit: 10,
        fill: 'none',
        stroke: '#1e1e1c',
      }}
    />
    <path
      d="m990.08 447.95-1.45 2.04M999.54 445.28l34.82 24.64h40"
      className="Einzelklappe_Kipp_mit_2_WLW_svg__cls-18"
    />
    <path
      d="m999.5 447.92-5.38-6.47 7.9 2.91-2.52 3.56z"
      className="Einzelklappe_Kipp_mit_2_WLW_svg__cls-28"
    />
    <path
      d="m381.22 441.58 81.35-36.96"
      className="Einzelklappe_Kipp_mit_2_WLW_svg__cls-19"
    />
    <path
      d="m384.22 443.4-11.07 1.84 8.67-7.13 2.4 5.29zM461.97 408.08l8.66-7.13-11.07 1.84 2.41 5.29z"
      className="Einzelklappe_Kipp_mit_2_WLW_svg__cls-28"
    />
    <path
      d="m367.21 429.92 1.07 2.26"
      className="Einzelklappe_Kipp_mit_2_WLW_svg__cls-18"
    />
    <path
      d="m370.52 436.9 7.81 16.51"
      style={{
        strokeDasharray: '0 0 5.22 5.22',
        strokeWidth: '.75px',
        strokeMiterlimit: 10,
        fill: 'none',
        stroke: '#1e1e1c',
      }}
    />
    <path
      d="m379.45 455.77 1.07 2.26"
      className="Einzelklappe_Kipp_mit_2_WLW_svg__cls-18"
    />
    <path
      d="m288.3 306.89 56.28 118.87"
      className="Einzelklappe_Kipp_mit_2_WLW_svg__cls-19"
    />
    <path
      d="m286.53 309.92-2.02-11.04 7.27 8.55-5.25 2.49zM341.11 425.21l7.26 8.56-2.01-11.04-5.25 2.48z"
      className="Einzelklappe_Kipp_mit_2_WLW_svg__cls-28"
    />
    <path
      d="m271.73 306.05 2.27-1.03"
      className="Einzelklappe_Kipp_mit_2_WLW_svg__cls-18"
    />
    <path
      d="m278.47 302.99 15.66-7.12"
      style={{
        strokeDasharray: '0 0 4.91 4.91',
        strokeWidth: '.75px',
        strokeMiterlimit: 10,
        fill: 'none',
        stroke: '#1e1e1c',
      }}
    />
    <path
      d="m296.36 294.86 2.28-1.04M335.58 440.94l2.28-1.03"
      className="Einzelklappe_Kipp_mit_2_WLW_svg__cls-18"
    />
    <path
      d="m342.47 437.81 16.14-7.33"
      style={{
        strokeWidth: '.75px',
        strokeMiterlimit: 10,
        fill: 'none',
        stroke: '#1e1e1c',
        strokeDasharray: '0 0 5.06 5.06',
      }}
    />
    <path
      d="m360.92 429.43 2.27-1.03M464.89 386.04l1.07 2.26"
      className="Einzelklappe_Kipp_mit_2_WLW_svg__cls-18"
    />
    <path
      d="m468.16 392.94 7.68 16.22"
      style={{
        strokeDasharray: '0 0 5.13 5.13',
        strokeWidth: '.75px',
        strokeMiterlimit: 10,
        fill: 'none',
        stroke: '#1e1e1c',
      }}
    />
    <path
      d="m476.93 411.48 1.07 2.26"
      className="Einzelklappe_Kipp_mit_2_WLW_svg__cls-18"
    />
    <path
      d="m321.6 220.13 18.97 40.08"
      className="Einzelklappe_Kipp_mit_2_WLW_svg__cls-19"
    />
    <path
      d="m319.82 223.17-2.01-11.05 7.26 8.56-5.25 2.49zM337.1 259.66l7.26 8.55-2.01-11.04-5.25 2.49z"
      className="Einzelklappe_Kipp_mit_2_WLW_svg__cls-28"
    />
    <path
      d="m307.58 290.25 5.69-182.81 92.2 137.03"
      style={{
        strokeLinejoin: 'round',
        fill: 'none',
        stroke: '#1e1e1c',
      }}
    />
    <path
      d="M311.34 290.18a3.751 3.751 0 0 1-3.88 3.64 3.751 3.751 0 0 1-3.64-3.88c.06-2.08 1.8-3.71 3.88-3.64s3.71 1.8 3.64 3.87ZM402.25 246.42a3.764 3.764 0 0 0 5.22 1.02 3.764 3.764 0 0 0 1.02-5.22 3.764 3.764 0 0 0-5.22-1.02 3.764 3.764 0 0 0-1.02 5.22Z"
      className="Einzelklappe_Kipp_mit_2_WLW_svg__cls-28"
    />
    <path
      d="M312.99 107.44h78.24"
      className="Einzelklappe_Kipp_mit_2_WLW_svg__cls-19"
    />
    <text
      className="Einzelklappe_Kipp_mit_2_WLW_svg__cls-30"
      transform="translate(329.21 103.42)"
    >
      <tspan x={0} y={0}>
        {props.windDeflectorWD}
      </tspan>
    </text>
    <path
      d="m1264.58 519.31-8.04 11.11"
      className="Einzelklappe_Kipp_mit_2_WLW_svg__cls-18"
    />
    <path
      d="m1261.94 519.24 6.54-5.31-3.01 7.86-3.53-2.55z"
      className="Einzelklappe_Kipp_mit_2_WLW_svg__cls-28"
    />
    <path
      d="m1286.05 489.65 23.78-32.84h70.62"
      className="Einzelklappe_Kipp_mit_2_WLW_svg__cls-18"
    />
    <path
      d="m1288.69 489.73-6.54 5.31 3.01-7.87 3.53 2.56z"
      className="Einzelklappe_Kipp_mit_2_WLW_svg__cls-28"
    />
    <path
      d="m1270.07 485.73 20.71 14.65"
      className="Einzelklappe_Kipp_mit_2_WLW_svg__cls-33"
    />
    <path
      d="m1270.07 485.73 2.04 1.44"
      className="Einzelklappe_Kipp_mit_2_WLW_svg__cls-18"
    />
    <path
      d="m1275.43 489.52 11.65 8.24"
      style={{
        strokeDasharray: '0 0 4.08 4.08',
        strokeWidth: '.75px',
        strokeMiterlimit: 10,
        fill: 'none',
        stroke: '#1e1e1c',
      }}
    />
    <path
      d="m1288.74 498.94 2.04 1.44"
      className="Einzelklappe_Kipp_mit_2_WLW_svg__cls-18"
    />
    <text
      className="Einzelklappe_Kipp_mit_2_WLW_svg__cls-30"
      transform="translate(1325.97 442.89)"
    >
      <tspan x={0} y={0}>
        {props.heightH}
      </tspan>
    </text>
    <text
      style={{
        fontSize: 35,
        fontFamily: 'ArialMT,Arial',
        fill: '#1e1e1c',
      }}
      transform="matrix(.58 0 0 .58 1345.83 454.55)"
    >
      <tspan x={0} y={0}>
        {props.windDeflectorWD}
      </tspan>
    </text>
    <text
      className="Einzelklappe_Kipp_mit_2_WLW_svg__cls-30"
      transform="translate(1321.6 369.66)"
    >
      <tspan x={0} y={0}>
        {props.windDeflectorWD}
      </tspan>
    </text>
    <path
      d="m1232.6 475.28 77.23-97.19"
      className="Einzelklappe_Kipp_mit_2_WLW_svg__cls-28"
    />
    <path
      d="m1234.27 473.18 75.56-95.09"
      className="Einzelklappe_Kipp_mit_2_WLW_svg__cls-18"
    />
    <path
      d="M1236.56 474.82a2.815 2.815 0 0 1-3.96.45 2.815 2.815 0 0 1-.45-3.96 2.815 2.815 0 0 1 3.96-.45c1.22.97 1.42 2.74.45 3.96Z"
      className="Einzelklappe_Kipp_mit_2_WLW_svg__cls-28"
    />
    <path
      d="M1309.83 378.09h66.55"
      style={{
        fill: '#1e1e1c',
        strokeWidth: '.75px',
        strokeMiterlimit: 10,
        stroke: '#1e1e1c',
      }}
    />
    <text
      className="Einzelklappe_Kipp_mit_2_WLW_svg__cls-30"
      transform="translate(826.87 834.23)"
    >
      <tspan x={0} y={0}>
        2 {props.windDeflectorWD}
      </tspan>
    </text>
    <path
      d="M486.13 802.51H642.9v84.84H486.13zM482.26 788h171.61M482.26 900.58h171.61"
      className="Einzelklappe_Kipp_mit_2_WLW_svg__cls-2"
    />
    <path
      d="m486.13 802.51 156.77 39.68-156.77 45.16"
      className="Einzelklappe_Kipp_mit_2_WLW_svg__cls-14"
    />
    <path
      d="m612.26 790.25 2.42.61"
      className="Einzelklappe_Kipp_mit_2_WLW_svg__cls-10"
    />
    <path
      d="m619.63 792.11 195.47 49.17"
      style={{
        strokeDasharray: '0 0 5.1 5.1',
        strokeLinejoin: 'round',
        strokeWidth: '.75px',
        fill: 'none',
        stroke: '#1e1e1c',
      }}
    />
    <path
      d="m817.58 841.9 2.42.61-2.41.66"
      className="Einzelklappe_Kipp_mit_2_WLW_svg__cls-10"
    />
    <path
      d="m812.87 844.45-195.84 53.22"
      style={{
        strokeDasharray: '0 0 4.89 4.89',
        strokeLinejoin: 'round',
        strokeWidth: '.75px',
        fill: 'none',
        stroke: '#1e1e1c',
      }}
    />
    <path
      d="m614.67 898.31-2.41.65"
      className="Einzelklappe_Kipp_mit_2_WLW_svg__cls-10"
    />
    <path
      d="M892.58 842.51h-70.32"
      className="Einzelklappe_Kipp_mit_2_WLW_svg__cls-2"
    />
    <path
      d="M718.06 736.71v210.96M520.16 746.22c-36.77-25.9-71.38-26.1-104.03-2.9-32.66 21.03-64.91 18.54-96.77-6.61v210.97c28.55-3.79 55.32-17.1 103.08 2.17 27.89 12.67 45.47 33.31 97.73-3.95M669.19 746.22c25.26-22.18 50.73-24.14 76.45 0 25.04 24.25 49.82 23.49 74.35 0 25.12-21.73 50.04-24.61 74.68 0 25.7 24.31 51.18 24.72 76.45 0 23.55-23.19 48.39-23.19 74.52 0 25.53 23.99 50.55 25.04 75 0v201.45"
      className="Einzelklappe_Kipp_mit_2_WLW_svg__cls-14"
    />
    <path
      d="M669.19 945.93c25.26-22.18 50.73-24.14 76.45 0 25.04 24.25 49.82 23.49 74.35 0 25.12-21.73 50.04-24.61 74.68 0 25.7 24.31 51.18 24.72 76.45 0 23.55-23.19 48.39-23.19 74.52 0 25.53 23.99 50.55 25.04 75 0"
      className="Einzelklappe_Kipp_mit_2_WLW_svg__cls-14"
    />
    <path
      d="M520.16 746.22c23.73-21.58 48.4-24.8 74.52 0 25.14 21.72 50.02 24.74 74.52 0M520.16 945.89c23.73-21.58 48.4-24.8 74.52 0 25.14 21.72 50.02 24.74 74.52 0"
      className="Einzelklappe_Kipp_mit_2_WLW_svg__cls-2"
    />
  </svg>
);
export default SvgEinzelklappeKippMit2Wlw;
