import React, { FC, useState } from 'react';
import '@material/dialog/mdc-dialog.scss';
import './InformationDialog.scss';
import { FormattedMessage, useIntl } from 'react-intl';
import { DialogFE } from './Dialog';
import { useSelector } from 'react-redux';
import { IState } from '../redux/store';
import classNames from 'classnames';
import { Help } from './Parameters/ParameterSection';

interface DialogContent {
  help?: Help;
}

interface InformationDialogProps extends DialogContent {
  heading: string;
  dialogIsForValueKey: string;
}

const InformationDialog: FC<React.PropsWithChildren<InformationDialogProps>> = (
  props: InformationDialogProps,
) => {
  const selectedParameter = useSelector(
    (state: IState) => state.parameters[props.dialogIsForValueKey],
  );
  const [activeDialogItem, setActiveDialogItem] = useState(
    selectedParameter?.isUndefined()
      ? props.help && typeof props.help !== 'function' && props.help[0].heading
      : selectedParameter?.value,
  );
  const state = useSelector((state: IState) => state);

  const { formatMessage } = useIntl();

  const baseUrl = '/img/';

  return (
    <DialogFE
      heading={props.heading}
      dialogByline={'INFORMATION_DIALOG_INFORMATION'}
      componentClass=""
    >
      <div
        className={classNames('information-dialog', {
          'information-dialog--navigation': props.help && props.help.length > 1,
        })}
      >
        {typeof props.help == 'function' ? (
          props.help(state)
        ) : props.help && props.help.length > 1 ? (
          <div className="information-dialog__nav-container">
            {props.help.map(description => (
              <div
                className={classNames(
                  'information-dialog__nav-item information-dialog__nav-item',
                  {
                    'information-dialog__nav-item information-dialog__nav-item--active':
                      activeDialogItem === description.heading,
                  },
                )}
                key={`${description.heading}_menu`}
                onClick={() => {
                  setActiveDialogItem(description.heading);
                }}
              >
                <FormattedMessage id={description.heading} />
              </div>
            ))}
          </div>
        ) : null}
        {props.help &&
          typeof props.help !== 'function' &&
          props.help.map(description =>
            description.heading === activeDialogItem ||
            (props.help && props.help.length === 1) ? (
              <div
                className="information-dialog__content"
                key={description.heading}
              >
                <h3 className="information-dialog__sub-headline">
                  {props.help && props.help.length > 1 ? (
                    <FormattedMessage id={description.heading} />
                  ) : null}
                </h3>
                {description.image !== 'none' ? (
                  <img
                    className="information-dialog__image"
                    src={`${baseUrl}${description.image}`}
                    alt={formatMessage({
                      id: 'IMAGE_ALT_TEXT',
                    })}
                  />
                ) : null}
                {description.text && description.text !== 'none' && (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: formatMessage({
                        id: description.text,
                      }),
                    }}
                  />
                )}
              </div>
            ) : null,
          )}
      </div>
    </DialogFE>
  );
};

export default InformationDialog;
