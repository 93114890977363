import React, { FC, ReactElement, useEffect, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import './ProjectNavigation.scss';
import '@material/drawer/mdc-drawer.scss';
import { MDCDrawer, MDCModalDrawerFoundation } from '@material/drawer';
import { useDispatch, useSelector } from 'react-redux';
import { IState, MyCalcThunkDispatch } from '../../redux/store';
import {
  setProjectNavigationOpen,
  showDialog,
  switchSelectedWindow,
} from '../../redux/uiStateActions';
import BuildingAreaDialog from './BuildingAreaDialog';

import {
  BuildingArea,
  NEW_BUILDING_AREA,
  NEW_WINDOW,
  Project,
  ShallowWindow,
  Window,
} from '../../redux/projectsReducer';
import WindowDialog from './WindowDialog';
import { OptionsMenu } from '../OptionsMenu';
import classNames from 'classnames';
import {
  BadgeFacadePrimary,
  BadgeNRWGPrimary,
  BadgeRoofPrimary,
  BadgeRWAPrimary,
} from '../Badges';
import {
  ConfirmationDialog,
  TYPE_OF_CONFIRMATION,
} from '../ConfirmationDialog';
import _ from 'lodash';
import TemplateDialog from './TemplateDialog';
import { ReactComponent as IconMeasure } from '../../assets/icons/IconMeasure.svg';
import { ReactComponent as IconAmount } from '../../assets/icons/IconAmount.svg';
import { ReactComponent as IconWindow } from '../../assets/icons/IconWindow.svg';
import { Parameters } from '../Parameters/ParameterValue';
import ToolTip from '../ToolTip';
import { AnyAction } from 'redux';
import { LoggedInUser } from '../../redux/authenticationReducer';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

interface WindowInformationItemProps {
  informationValue: string;
  textResource?: string;
  icon: ReactElement;
  action?: () => void;
}

const WindowInformationItem: FC<
  React.PropsWithChildren<WindowInformationItemProps>
> = (props: WindowInformationItemProps) => {
  if (
    props.informationValue.match(Parameters.ENTRY_REQUIRED) ||
    !props.informationValue
  ) {
    return null;
  }

  return (
    <div
      className="project-navigation__window-information-item"
      onClick={props.action && props.action}
    >
      <ToolTip
        text={
          props.textResource && <FormattedMessage id={props.textResource} />
        }
        additionalClass="tooltip__text--position-bottom"
      >
        <div className="drive-details-column__drive-value-data drive-details-item">
          <span> {props.icon}</span>
          <span> {props.informationValue} </span>
        </div>
      </ToolTip>
    </div>
  );
};

function WindowPosition(props: {
  selectedWindowID: number | undefined;
  window: ShallowWindow;
  amountOfDrivesForPosition: number;
  windowMeasurements: string;
  windowActions: Record<string, () => void>;
}): ReactElement {
  const [menuOpen, setMenuOpen] = useState(false);
  const dispatch: MyCalcThunkDispatch<AnyAction> = useDispatch();
  const user = useSelector<IState, LoggedInUser | undefined>(
    state => state.ui.selectedProject?.user,
  );
  const projectID = useSelector<IState, string | undefined>(
    state => state.ui.selectedProject?.uniqueNumber,
  );

  if (!props.window) {
    return <></>;
  }

  function prefillContactWidget(w: ShallowWindow): void {
    // @ts-ignore
    if (!window.FreshworksWidget) {
      return;
    }
    // @ts-ignore
    FreshworksWidget('prefill', 'ticketForm', {
      name: user ? user.firstName + ' ' + user.lastName : '',
      custom_fields: {
        cf_projekt_id: projectID || '',
        cf_positions_id: w.uniqueNumber || '',
        cf_kundenfirma: user?.company.name || '',
        cf_country: user?.company.country || '',
        cf_zip: user?.company.zip || '',
      },
    });
  }

  function switchWindowAndCloseNavigation(w: ShallowWindow): void {
    dispatch(switchSelectedWindow(w));
    dispatch(setProjectNavigationOpen(false));
    prefillContactWidget(w);
  }

  return (
    <div
      className={classNames('project-navigation__window-container', {
        'project-navigation__window-container--selected':
          props.selectedWindowID === props.window.id,
        'project-navigation__window-container--menu-open': menuOpen,
      })}
    >
      <div className="project-navigation__window-data-container">
        <div
          className="project-navigation__window-click-target"
          onClick={() => switchWindowAndCloseNavigation(props.window)}
        />
        <div tabIndex={1} className="project-navigation__window">
          <div className="project-navigation__window-name">
            {props.window.name}
          </div>
        </div>
        <div className="project-navigation__window-badges">
          <div className="project-navigation__window-id">
            ID:{_.last(props.window.uniqueNumber?.split('-'))}
          </div>
          <BadgeFacadePrimary window={props.window} />
          <BadgeRoofPrimary window={props.window} />
          <BadgeNRWGPrimary window={props.window} />
          <BadgeRWAPrimary window={props.window} />
        </div>{' '}
        <div className="project-navigation__window-information">
          {props.window.driveName && (
            <WindowInformationItem
              icon={<IconAmount />}
              informationValue={props.amountOfDrivesForPosition.toString()}
              textResource="fenster_edit_anzahlAntriebe"
              action={() => switchWindowAndCloseNavigation(props.window)}
            />
          )}
          <WindowInformationItem
            icon={<IconWindow />}
            informationValue={props.window.quantity.toString()}
            textResource="WINDOW_PLURAL"
            action={() => switchWindowAndCloseNavigation(props.window)}
          />
          <WindowInformationItem
            icon={<IconMeasure />}
            informationValue={props.windowMeasurements}
            textResource="WINDOW_DIMENSION"
            action={() => switchWindowAndCloseNavigation(props.window)}
          />
        </div>
      </div>
      <div className="project-navigation__window-options">
        {props.window.locked ? (
          <div className="project-navigation__window-locked-icon">lock</div>
        ) : (
          <OptionsMenu actions={props.windowActions} menuOpened={setMenuOpen} />
        )}
      </div>
      {props.window.driveName && (
        <div
          className="project-navigation__selected-component-info"
          onClick={() => switchWindowAndCloseNavigation(props.window)}
        >
          {props.window.driveName && (
            <div className="project-navigation__selected-component-info--drive">
              {props.window.driveName}
            </div>
          )}
          {props.window.consoleSetNames &&
            props.window.consoleSetNames.length > 0 && (
              <div className="project-navigation__selected-component-info--console">
                {props.window.consoleSetNames.join(', ')}
              </div>
            )}
        </div>
      )}
    </div>
  );
}

const ProjectNavigation: FC<React.PropsWithChildren<unknown>> = () => {
  const dispatch: MyCalcThunkDispatch<AnyAction> = useDispatch();
  const { formatMessage } = useIntl();
  const navigationOpen = useSelector<IState, number>(
    s => s.ui.projectNavigationOpen,
  );
  const navigationRef = useRef<MDCDrawer | undefined>();

  const selectedProject = useSelector<IState, Project>(
    s => s.ui.selectedProject!,
  );
  const selectedWindowID = useSelector<IState, number | undefined>(
    s => s.ui.selectedWindow?.id,
  );
  const asideRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (navigationRef.current) {
      navigationRef.current.destroy();
    }

    if (asideRef.current !== null) {
      const drawer = new MDCDrawer(asideRef.current);

      //@ts-ignore
      getFoundation(drawer).handleScrimClick = () => {
        //Do Nothing
      };

      navigationRef.current = drawer;
    }
    return () => {
      if (navigationRef.current) {
        navigationRef.current.destroy();
        navigationRef.current = undefined;
      }
    };
  }, [asideRef.current]);

  useEffect(() => {
    if (!navigationRef.current) {
      return;
    }

    const drawer = navigationRef.current;

    if (navigationOpen) {
      getFoundation(drawer).open();
    } else {
      getFoundation(drawer).close();
    }
  }, [navigationOpen, navigationRef.current]);

  function getFoundation(drawer: MDCDrawer): MDCModalDrawerFoundation {
    //@ts-ignore
    return drawer.foundation as MDCModalDrawerFoundation;
  }

  function editWindow(newWindow: ShallowWindow, buildingAreaId: number): void {
    dispatch(
      showDialog(
        <WindowDialog
          editedOrNewWindow={newWindow}
          buildingAreaId={buildingAreaId}
          copy={false}
        />,
      ),
    );
  }

  function containsLockedWindows(b: BuildingArea): boolean {
    return !!b.windows.find(w => w.locked);
  }

  function getBuildingAreaActions(b: BuildingArea): Record<string, () => void> {
    const actions = {
      EDIT_LABEL: () => {
        dispatch(
          showDialog(<BuildingAreaDialog editedOrNewBuildingArea={b} />),
        );
      },
    };

    if (!containsLockedWindows(b)) {
      return {
        ...actions,
        DELETE_LABEL: () => {
          dispatch(
            showDialog(
              <ConfirmationDialog
                typeOfConfirmation={
                  TYPE_OF_CONFIRMATION.CONFIRMATION_BUILDING_AREA
                }
                deletedObject={b}
              />,
            ),
          );
        },
      };
    }
    return actions;
  }

  function addCopyIndex(windowName: string): string {
    const windowIndices = selectedProject
      .buildingAreas!.flatMap(b => b.windows)
      .filter(w => w.name.startsWith(windowName))
      .map(w => w.name.replace(windowName + ' ', ''))
      .filter(w => w.match(/^\d+$/))
      .map(w => +w);
    return `${windowName} ${Math.max(...[...windowIndices, 0]) + 1}`;
  }

  function copyWindow(w: ShallowWindow, id: number): void {
    const windowCopy: Partial<Window> = _.cloneDeep(w);
    windowCopy.id = undefined;
    // @ts-ignore
    windowCopy.createdAt = undefined;
    windowCopy.updatedAt = undefined;
    const translatedCopy = formatMessage({
      id: 'COPY',
    });
    const nameWithoutLastCopy = windowCopy.name!.replace(
      new RegExp(` ${translatedCopy} \\d+$`),
      '',
    );
    const tmpName = `${nameWithoutLastCopy} ${translatedCopy}`;
    windowCopy.name = addCopyIndex(tmpName);

    dispatch(
      showDialog(
        <WindowDialog
          editedOrNewWindow={windowCopy}
          buildingAreaId={id}
          copy={true}
          originalWindowId={w.id}
        />,
      ),
    );
  }

  function getWindowActions(
    w: ShallowWindow,
    b: BuildingArea,
  ): Record<string, () => void> {
    return {
      EDIT_LABEL: () => {
        editWindow(w, b.id!);
      },
      COPY_LABEL: () => {
        copyWindow(w, b.id!);
      },
      DELETE_LABEL: () => {
        dispatch(
          showDialog(
            <ConfirmationDialog
              typeOfConfirmation={TYPE_OF_CONFIRMATION.CONFIRMATION_WINDOW}
              deletedObject={w}
            />,
          ),
        );
      },
      TEMPLATE_LABEL: () => {
        dispatch(showDialog(<TemplateDialog window={w} />));
      },
    };
  }

  function getWindowMeasurements(w: ShallowWindow): string {
    if (!w.sashWidth || !w.sashHeight) {
      return '';
    }

    return `${w.sashWidth} x ${w.sashHeight}`;
  }

  function getAmountOfDrivesForPosition(w: ShallowWindow): number {
    return (w.numberOfDrives || 1) * w.quantity;
  }

  return (
    <>
      <aside className="mdc-drawer mdc-drawer--modal" ref={asideRef}>
        <div className="project-navigation mdc-drawer__content">
          <div className="project-navigation__header">
            <FormattedMessage id="PROJECT_NAVIGATION_HEADER" />
            <div
              className="project-navigation__icon-close"
              onClick={() => dispatch(setProjectNavigationOpen(false))}
            >
              close
            </div>
          </div>
          <div className="project-navigation__content">
            {selectedProject.buildingAreas?.map(b => (
              <div className="project-navigation__building-area" key={b.id}>
                <Accordion defaultExpanded>
                  <AccordionSummary
                    expandIcon={<ArrowDropDownIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                  >
                    <div className="project-navigation__building-area-header">
                      <div className="project-navigation__building-area-icon">
                        subdirectory_arrow_right
                      </div>
                      <div className="project-navigation__building-area-name">
                        {b.name}
                      </div>
                      <div className="project-navigation__building-area-options">
                        <OptionsMenu actions={getBuildingAreaActions(b)} />
                      </div>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div
                      className="project-navigation__new-window-action"
                      onClick={() => {
                        dispatch(
                          showDialog(
                            <WindowDialog
                              editedOrNewWindow={NEW_WINDOW}
                              buildingAreaId={b.id || 0}
                              copy={false}
                            />,
                          ),
                        );
                      }}
                    >
                      <div className="project-navigation__new-window-object">
                        <div className="project-navigation__new-window-object-icon">
                          add
                        </div>
                        <div className="project-navigation__new-window-object-label">
                          <FormattedMessage id="PROJECT_NAVIGATION_CREATE_WINDOW_BUTTON_LABEL" />
                        </div>
                      </div>
                    </div>
                    <div>
                      {b.windows.map(w => (
                        <WindowPosition
                          key={w.id}
                          selectedWindowID={selectedWindowID}
                          window={w}
                          amountOfDrivesForPosition={getAmountOfDrivesForPosition(
                            w,
                          )}
                          windowMeasurements={getWindowMeasurements(w)}
                          windowActions={getWindowActions(w, b)}
                        />
                      ))}{' '}
                    </div>
                  </AccordionDetails>
                </Accordion>
              </div>
            ))}
          </div>

          <button
            className="project-navigation__button-new-area"
            onClick={() => {
              dispatch(
                showDialog(
                  <BuildingAreaDialog
                    editedOrNewBuildingArea={NEW_BUILDING_AREA}
                  />,
                ),
              );
            }}
          >
            <FormattedMessage id="PROJECT_NAVIGATION_CREATE_BUILDING_AREA_BUTTON_LABEL" />
          </button>
          <div tabIndex={0}></div>
        </div>
      </aside>
      <div className="mdc-drawer-scrim"></div>
    </>
  );
};

export default ProjectNavigation;
