import { Switch } from '../../elements/Switch';
import React, { FC } from 'react';

interface AdminActiveSwitchProps {
  active: boolean;
  description: string;
  itemNumber: string;
  saveAction: (active: boolean) => void;
}

const AdminActiveSwitch: FC<
  React.PropsWithChildren<AdminActiveSwitchProps>
> = props => {
  return (
    <Switch
      turnedOn={props.active}
      onChange={() => {
        const confirmed = window.confirm(
          'Möchten Sie ' +
            props.description +
            `(${props.itemNumber})` +
            ' wirklich ' +
            (props.active ? 'deaktivieren' : 'aktivieren'),
        );
        if (confirmed) {
          props.saveAction(!props.active);
        }
      }}
    />
  );
};

export default AdminActiveSwitch;
