import React, { ReactElement, useEffect, useState } from 'react';
import Table from '../components/Table';
import TableHeader from '../elements/TableHeader';
import { useDispatch, useSelector } from 'react-redux';
import { AdminState, AdminThunkDispatch } from '../../redux/admin/adminStore';
import {
  BluekitArticleNumberMappings,
  Company,
} from '../../redux/admin/adminFacadeReducer';
import InputField from '../../elements/InputField';
import _, { Dictionary } from 'lodash';
import Dialog from '../../components/Dialog';
import FormLayout, { FormLayoutElement } from '../../elements/FormLayout';
import Button, { ButtonType } from '../../elements/Button';
import './ArticleNumberDataView.scss';
import { saveBluekitArticleNumberMappings } from '../../redux/admin/adminFacadeActions';
import TableRow from '../elements/TableRow';
import { AnyAction } from 'redux';

export function ArticleNumberDataView(): ReactElement {
  const companies = useSelector<AdminState, Company[]>(state =>
    state.adminFacade.companies.filter(c => !c.parentCompany && c.bluekit),
  );
  const mappingsByCompany = useSelector<
    AdminState,
    Dictionary<BluekitArticleNumberMappings[]>
  >(
    state =>
      _.groupBy(state.adminFacade.bluekitArticleNumberMappings, 'companyId') ||
      [],
  );
  const mappings = useSelector<AdminState, BluekitArticleNumberMappings[]>(
    state => state.adminFacade.bluekitArticleNumberMappings || [],
  );
  const [showNewEntry, setShowNewEntry] = useState(false);
  const [updatedMapping, setUpdatedMapping] = useState<
    BluekitArticleNumberMappings[]
  >([]);
  const dispatch: AdminThunkDispatch<AnyAction> = useDispatch();

  useEffect(() => {
    setUpdatedMapping(mappings);
  }, [mappings]);

  function addNewArticleNumber(articleNumber: string): void {
    const newMappings = companies.map(company => ({
      companyId: company.id,
      sourceArticleNumber: articleNumber,
      destinationArticleNumber: '',
    }));

    setUpdatedMapping([...updatedMapping, ...newMappings]);
    setShowNewEntry(false);
  }

  if (companies.length === 0) {
    return <></>;
  }

  const articleNumbers = [
    ..._.sortBy(
      _.uniq(updatedMapping.map(mapping => mapping.sourceArticleNumber)),
    ),
  ];

  function getMappingForCompany(
    company: Company,
  ): BluekitArticleNumberMappings[] {
    return mappingsByCompany[company.id] || [];
  }

  function saveMappings(): void {
    dispatch(saveBluekitArticleNumberMappings(updatedMapping));
  }

  function matchingMapping(
    mapping: BluekitArticleNumberMappings,
    companyId: number | undefined,
    sourceArticleNumber: string,
  ): boolean {
    return (
      mapping.companyId === companyId &&
      mapping.sourceArticleNumber === sourceArticleNumber
    );
  }

  function updateNewArticleNumber(
    companyId: number,
    sourceArticleNumber: string,
    newDestinationArticleNumber: string,
  ): void {
    const mappingEntry = updatedMapping.find(mapping =>
      matchingMapping(mapping, companyId, sourceArticleNumber),
    )!;
    mappingEntry.destinationArticleNumber = newDestinationArticleNumber.trim();
    setUpdatedMapping([...updatedMapping]);
  }

  function deleteSourceArticleNumber(articleNumber: string): void {
    if (
      window.confirm(`Wirklich die Artikelnummer ${articleNumber} löschen?`)
    ) {
      setUpdatedMapping(
        updatedMapping.filter(
          mapping => mapping.sourceArticleNumber !== articleNumber,
        ),
      );
    }
  }

  return (
    <div>
      <div className="sub-header">
        <div className="sub-header__title">Artikelnummern</div>
        <button
          className="sub-header__button sub-header__button--add"
          onClick={() => setShowNewEntry(true)}
        >
          Neuer Eintrag
        </button>
      </div>
      <Table
        maxHeight="calc(100vh - 300px)"
        maxWidth="calc(100vw - 250px)"
        stickyFirstColumn={true}
      >
        <TableHeader largeHeader={true}>
          <th></th>
          {articleNumbers.map(articleNumber => (
            <th key={articleNumber}>
              <div className="article-number-data-view__heading">
                <div>{articleNumber}</div>
                <button
                  className="article-number-data-view__delete-button"
                  onClick={() => deleteSourceArticleNumber(articleNumber)}
                />
              </div>
            </th>
          ))}
        </TableHeader>
        <tbody>
          {companies.map(company => (
            <TableRow key={company.id}>
              <td>{company.name}</td>
              {articleNumbers.map(sourceArticleNumber => {
                const mapping = getMappingForCompany(company).find(
                  mapping =>
                    mapping.sourceArticleNumber === sourceArticleNumber,
                );

                return (
                  <td key={sourceArticleNumber}>
                    <InputField
                      placeholder="123.456.345"
                      value={mapping?.destinationArticleNumber}
                      onChange={newDestinationArticleNumber =>
                        updateNewArticleNumber(
                          company.id,
                          sourceArticleNumber,
                          newDestinationArticleNumber,
                        )
                      }
                    />
                  </td>
                );
              })}
            </TableRow>
          ))}
        </tbody>
      </Table>
      <div className="article-number-data-view__footer">
        <Button
          type={ButtonType.PRIMARY}
          action={() => saveMappings()}
          labelText="Speichern"
        />
      </div>
      {showNewEntry && (
        <NewSourceArticleNumberModal
          dialogIsShown={showNewEntry}
          setDialogIsShown={setShowNewEntry}
          addNewArticleNumber={addNewArticleNumber}
          existingArticleNumbers={articleNumbers}
        />
      )}
    </div>
  );
}

interface NewSourceArticleNumberModalProps {
  existingArticleNumbers: string[];
  setDialogIsShown: (b: boolean) => void;
  dialogIsShown: boolean;
  addNewArticleNumber: (articleNumber: string) => void;
}

function NewSourceArticleNumberModal(
  props: NewSourceArticleNumberModalProps,
): ReactElement {
  const [articleNumber, setArticleNumber] = useState('');

  function isLegalArticleNumber(): boolean {
    return (
      !!articleNumber && !props.existingArticleNumbers.includes(articleNumber)
    );
  }

  return (
    <Dialog
      setDialogIsShown={props.setDialogIsShown}
      dialogIsShown={props.dialogIsShown}
      headingText="Artikelnummer hinzufügen"
      componentClass=""
      footerProps={{
        notTranslated: true,
        cancelAction: () => {
          props.setDialogIsShown(false);
        },
        saveAction: isLegalArticleNumber()
          ? () => props.addNewArticleNumber(articleNumber)
          : undefined,
        primaryActionLabel: 'Anlegen',
      }}
    >
      <FormLayout>
        <FormLayoutElement rowStart={1} columnStart={1} columnWidth={2}>
          <InputField
            label="Generische Artikelnummer"
            placeholder="123.456.xxx"
            value={articleNumber}
            onChange={articleNumber => setArticleNumber(articleNumber.trim())}
          />
        </FormLayoutElement>
      </FormLayout>
    </Dialog>
  );
}
