import { Action } from 'redux';
import { CalculationResult } from './calculationResultReducer';
import { resetDesmoking } from './desmokingResultActions';
import { abortCalculations, Dispatch } from './parametersActions';
import { ThunkAction } from 'redux-thunk';
import { IState } from './store';
import { storeMarkedDriveAction } from './uiStateActions';

export interface UpdateCalculationResult
  extends Action<'UPDATE_CALCULATION_RESULT'> {
  calculationResult: CalculationResult;
}

export type ResetCalculationResult = Action<'RESET_CALCULATION_RESULT'>;

export function updateCalculationResult(
  calculationResult: CalculationResult,
): UpdateCalculationResult {
  return {
    type: 'UPDATE_CALCULATION_RESULT',
    calculationResult: calculationResult,
  };
}

export function resetCalculationResults(): ThunkAction<
  Promise<ResetCalculationResult>,
  IState,
  void,
  ResetCalculationResult
> {
  return async (dispatch: Dispatch): Promise<ResetCalculationResult> => {
    dispatch(resetDesmoking());
    dispatch(storeMarkedDriveAction(undefined, undefined));
    abortCalculations();
    return dispatch(resetCalculationResult());
  };
}

export const resetCalculationResult = (): ResetCalculationResult => {
  return {
    type: 'RESET_CALCULATION_RESULT',
  };
};
