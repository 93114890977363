import * as log from 'loglevel';
import remote from 'loglevel-plugin-remote';

function plain(log: {
  message: string;
  level: { label: string };
  stacktrace: string;
  timestamp: string;
  logger: any;
}): string {
  return `[${log.timestamp}] (user_id: ${
    window.user_id
  }) ${log.level.label.toUpperCase()}${log.logger ? ` (${log.logger})` : ''}: ${
    log.message
  }${log.stacktrace ? `\n${log.stacktrace}` : ''}`;
}

remote.apply(log, {
  // format: customJSON,
  format: plain,
  url: '/log',
});

log.enableAll();

window.logger = log;
