import React, { FC, ReactElement, ReactNode } from 'react';
import Headline, { HeadlineSize } from '../elements/Headline';
import { FormattedMessage } from 'react-intl';
import OptionsMenu, { OptionsMenuActions } from './OptionsMenu';
import classNames from 'classnames';
import SearchField from '../elements/SearchField';
import InfiniteScroll from 'react-infinite-scroll-component';

interface ManagerPageProps {
  children: ReactElement;
  additionalClass?: string;
  headline: string;
}

const ManagerPage: FC<React.PropsWithChildren<ManagerPageProps>> = props => {
  return (
    <div className={classNames('manager-page', props.additionalClass)}>
      <div className="manager-page__headline">
        <Headline size={HeadlineSize.LG}>
          <FormattedMessage id={props.headline} />
        </Headline>
      </div>
      {props.children}
    </div>
  );
};

export interface ManagerTableCellProps {
  content: ReactNode;
  notClickable?: boolean;
  numberArea?: boolean;
}

interface ManagerPageTableRowProps<T> {
  cells: ManagerTableCellProps[];
  onClick?: () => void;
  icon: ReactElement;
  options?: OptionsMenuActions;
  rowElement: T;
  key: string;
}

function ManagerPageTableRow<T>(
  props: ManagerPageTableRowProps<T>,
): ReactElement {
  return (
    <tr className="manager-page__list-item">
      {props.cells.map((c, i) => (
        <td
          className={classNames('manager-page__list-item-cell', {
            'manager-page__list-item-cell--number-area': c.numberArea,
          })}
          onClick={c.notClickable ? undefined : props.onClick}
          key={i}
        >
          {i === 0 && (
            <span className="manager-page__list-item-icon">{props.icon}</span>
          )}
          {c.content}
        </td>
      ))}
      {props.options && (
        <td className="manager-page__list-item-options">
          <OptionsMenu actions={props.options} />
        </td>
      )}
    </tr>
  );
}

export interface ManagerTableHeaderElementProps {
  label: ReactElement;
  shrinked?: boolean;
  key: string;
}

const ManagerTableHeaderColumn: FC<
  React.PropsWithChildren<ManagerTableHeaderElementProps>
> = props => {
  return (
    <th
      className={classNames('manager-page__table-label', {
        'manager-page__table-label-options': props.shrinked,
      })}
    >
      {props.label}
    </th>
  );
};

interface ManagerPageTableProps<T> {
  header: ManagerTableHeaderElementProps[];
  rows: ManagerPageTableRowProps<T>[];
  setSearchString?: (s: string) => void;
  searchString?: string;
  placeHolder?: string;
  emptyState?: ReactElement;
  loadMore: () => void;
  hasMore: boolean;
}

export function ManagerPageTable<T>(
  props: ManagerPageTableProps<T>,
): ReactElement {
  return (
    <>
      {props.setSearchString && (
        <div className="manager-page__search">
          <SearchField
            setSearchString={props.setSearchString}
            searchString={props.searchString || ''}
            small={true}
            placeholder={props.placeHolder}
          />
        </div>
      )}
      {props.rows.length !== 0 ? (
        <div className="manager-page__table" id="scrollable">
          <InfiniteScroll
            scrollableTarget="scrollable"
            next={props.loadMore}
            hasMore={props.hasMore}
            loader={<Spinner />}
            dataLength={props.rows.length}
            scrollThreshold={1}
          >
            <table>
              <thead>
                <tr className="manager-page__table-labels">
                  {props.header.map(e => (
                    <ManagerTableHeaderColumn {...e} key={e.key} />
                  ))}
                </tr>
              </thead>
              <tbody>
                {props.rows.map((r, index) => (
                  <ManagerPageTableRow {...r} key={r.key} />
                ))}
              </tbody>
            </table>
          </InfiniteScroll>
        </div>
      ) : (
        props.emptyState
      )}
    </>
  );
}

export function label(
  label: string,
  shrinked?: boolean,
): ManagerTableHeaderElementProps {
  return { label: <FormattedMessage id={label} />, key: label, shrinked };
}

export function cell(
  content: ReactNode,
  notClickable?: boolean,
  numberArea?: boolean,
): ManagerTableCellProps {
  return { content: content, notClickable, numberArea };
}

export default ManagerPage;

function Spinner() {
  return (
    <div className="spinner">
      <div className="lds-ellipsis">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
}
