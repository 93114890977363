import React, { ReactElement, useEffect, useState } from 'react';
import { Locale } from '../redux/uiStateReducer';
import './Support.scss';

const FALLBACK_TRANSLATIONS_FOR_WIDGET_ELEMENTS = {
  banner: 'How can we help you?',
  launcher: 'Contact',
  contact_form: {
    title: 'Contact us',
    submit: 'Submit',
    confirmation: 'We will come back to you soon',
  },
};
const WIDGET_URL = 'https://euc-widget.freshworks.com/widgets/101000004495.js';

function setWidgetConfig(locale: Locale): void {
  window.fwSettings = {
    widget_id: 101000004495,
    locale: locale,
  };
}

function hideNotNeadedWidgetFields(): void {
  window.FreshworksWidget('hide', 'ticketForm', [
    'custom_fields.cf_country',
    'custom_fields.cf_zip',
  ]);
}

function adaptWidgetFieldsAndLabels(locale: Locale): void {
  hideNotNeadedWidgetFields();
  if (locale !== Locale.DE) {
    // @ts-ignore
    FreshworksWidget('setLabels', {
      en: FALLBACK_TRANSLATIONS_FOR_WIDGET_ELEMENTS,
    });
  }
}

export default function Support({ locale }: { locale: Locale }): ReactElement {
  const [loaded] = useScript(WIDGET_URL);
  const [firstLoad, setFirstLoad] = useState(true);

  useEffect(() => {
    if (typeof window !== undefined) {
      setWidgetConfig(locale);

      if ('function' != typeof window.FreshworksWidget) {
        const n = function (...args: any[]): void {
          // @ts-ignore
          n.q.push(...args);
        };
        // @ts-ignore
        n.q = [];
        // @ts-ignore
        window.FreshworksWidget = n;
      }
    }
  }, [loaded]);

  useEffect(() => {
    if (firstLoad) {
      setFirstLoad(false);
      setTimeout(() => {
        adaptWidgetFieldsAndLabels(locale);
        window.FreshworksWidget('show', 'launcher');
        document.querySelector('#freshworks-container')?.classList.add('show');
      }, 1000);
      return;
    }

    if (typeof window !== undefined) {
      if ('function' === typeof window.FreshworksWidget) {
        setWidgetConfig(locale);

        document.querySelector('#freshworks-container')?.remove();
        document.querySelector('#freshworks-frame')?.remove();
        const oldScript = document.querySelector(
          'script[src="https://euc-widget.freshworks.com/widgets/101000004495.js"]',
        );

        if (oldScript) oldScript.remove();

        const script = document.createElement('script');

        script.async = true;
        script.addEventListener('load', () => {
          setCustomLabels(locale, 0);
        });

        script.src = WIDGET_URL;

        document.head.appendChild(script);
      }
    }
  }, [locale]);

  return <></>;
}

function setCustomLabels(locale: Locale, numberTries: number): void {
  setTimeout(() => {
    if (numberTries >= 40) {
      // @ts-ignore
      try {
        adaptWidgetFieldsAndLabels(locale);
        document.querySelector('#freshworks-container')?.classList.add('show');

        window.FreshworksWidget('show', 'launcher');
      } catch (e) {
        //ignore frehsworks errors
      }
      return;
    }
    window.FreshworksWidget('hide', 'ticketForm', [
      'custom_fields.cf_country',
      'custom_fields.cf_zip',
    ]);
    // @ts-ignore
    FreshworksWidget('hide', 'launcher');

    setCustomLabels(locale, numberTries + 1);
  }, 25);
}

const cachedScripts: string[] = [];

function useScript(src: string): [boolean, boolean] {
  // Keeping track of script loaded and error state

  const [state, setState] = useState({
    loaded: false,
    error: false,
  });

  useEffect(() => {
    if (cachedScripts.includes(src)) {
      setState({
        loaded: true,

        error: false,
      });
    } else {
      cachedScripts.push(src);

      // Create script

      const script = document.createElement('script');
      script.src = src;
      script.async = true;

      // Script event listener callbacks for load and error

      const onScriptLoad = (): void => {
        setState({
          loaded: true,
          error: false,
        });
      };

      const onScriptError = (): void => {
        const index = cachedScripts.indexOf(src);
        if (index >= 0) cachedScripts.splice(index, 1);
        script.remove();
        setState({
          loaded: true,

          error: true,
        });
      };

      script.addEventListener('load', onScriptLoad);
      script.addEventListener('error', onScriptError);

      document.body.appendChild(script);

      return () => {
        script.removeEventListener('load', onScriptLoad);
        script.removeEventListener('error', onScriptError);
      };
    }
  }, [src]);

  return [state.loaded, state.error];
}
