import React, { FC, ReactElement, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AdminState, AdminThunkDispatch } from '../../redux/admin/adminStore';
import { TestPhaseWindLoadOrSnowLoadWithOptionalId } from '../../redux/admin/adminFacadeReducer';
import { fieldsFilled } from '../general/helpers';
import {
  updateEditedTestPhaseLoad,
  updateEditedTestPhaseLoads,
} from '../../redux/admin/editedTestPhaseActions';
import Dialog from '../../components/Dialog';
import FormLayout from '../../elements/FormLayout';
import { InputFieldNumber } from '../../elements/InputField';
import CheckBox from '../../elements/CheckBox';
import { SubType } from './FacadeTestPhases';
import Button, { ButtonType } from '../../elements/Button';
import Table from '../components/Table';
import TableHeader from '../elements/TableHeader';
import TableRow from '../elements/TableRow';
import AdminListIcon from '../elements/AdminListIcon';
import { UiConstants } from '../constants';
import Headline, { HeadlineSize } from '../../elements/Headline';
import { MenuPlacement, SelectField } from '../../elements/SelectField';
import { enumToList } from '../../lib/utils';
import { FormattedMessage, useIntl } from 'react-intl';
import './WindLoadsDialog.scss';
import { AnyAction } from 'redux';
import { DriveSeries } from '../../redux/constants';

interface WindLoadsEditDialogProps {
  shown: boolean;
  setShown: (b: boolean) => void;
  editedWindLoadIndex: number;
  snowLoad: boolean;
}

const WindLoadsEditDialog: FC<
  React.PropsWithChildren<WindLoadsEditDialogProps>
> = props => {
  const editedLoad = useSelector<
    AdminState,
    TestPhaseWindLoadOrSnowLoadWithOptionalId | undefined
  >(
    state =>
      state.adminFacade.editedTestPhaseLoad as
        | TestPhaseWindLoadOrSnowLoadWithOptionalId
        | undefined,
  );
  const editedLoads = useSelector<
    AdminState,
    TestPhaseWindLoadOrSnowLoadWithOptionalId[] | undefined
  >(state => state.adminFacade.editedTestPhaseLoads);

  const dispatch: AdminThunkDispatch<AnyAction> = useDispatch();
  const { formatMessage } = useIntl();
  const allDriveSeriesNames = useSelector<AdminState, string[]>(s =>
    s.adminFacade.driveSeries.map(ds => ds.name),
  );

  if (!editedLoad || !editedLoads) {
    return null;
  }

  function mandatoryFieldsFilled(): boolean {
    return !!(
      fieldsFilled(
        editedLoad?.gepruefteFlaeche,
        editedLoad?.glasstaerke,
        editedLoad?.einbauwinkel,
        props.snowLoad
          ? editedLoad?.gepruefteSchneelast
          : editedLoad?.gepruefteWindlast,
        editedLoad?.driveSeries,
        editedLoad?.antriebsAnzahlBis,
      ) &&
      (editedLoad?.montagePosition_ggBand ||
        editedLoad?.montagePosition_seitl ||
        editedLoad?.montagePosition_traverse) &&
      (editedLoad?.format_hoch || editedLoad?.format_quer) &&
      (editedLoad?.oeffnungsrichtung_ausw || editedLoad?.oeffnungsrichtung_einw)
    );
  }

  function saveAction(): (() => void) | undefined {
    if (mandatoryFieldsFilled()) {
      return () => {
        props.setShown(false);
        const updatedLoads = [...editedLoads!];
        if (props.editedWindLoadIndex >= 0) {
          updatedLoads.splice(props.editedWindLoadIndex, 1, editedLoad!);
        } else {
          updatedLoads.unshift(editedLoad!);
        }
        dispatch(updateEditedTestPhaseLoads(updatedLoads));
      };
    } else {
      return undefined;
    }
  }

  return (
    <Dialog
      setDialogIsShown={props.setShown}
      dialogIsShown={props.shown}
      componentClass=""
      headingText={
        props.snowLoad
          ? 'Schneelast (Pa) hinzufügen / bearbeiten'
          : 'Windlast (Pa) hinzufügen / bearbeiten'
      }
      footerProps={{
        notTranslated: true,
        cancelAction: () => props.setShown(false),
        saveAction: saveAction(),
        primaryActionLabelText: 'Speichern',
      }}
      nested={true}
    >
      <FormLayout additionalClass="test-phases__data-dialog-windload-form">
        <InputFieldNumber
          additionalClass="test-phases__data-dialog-windload-form-area"
          label="gepr. Fläche (m²)"
          placeholder="z.B. 42"
          value={editedLoad.gepruefteFlaeche}
          onChange={v =>
            dispatch(
              updateEditedTestPhaseLoad({
                ...editedLoad,
                gepruefteFlaeche: v,
              }),
            )
          }
        />
        <InputFieldNumber
          additionalClass="test-phases__data-dialog-windload-form-glas"
          label="Glasstärke (mm)"
          placeholder="z.B. 42"
          value={editedLoad.glasstaerke}
          onChange={v =>
            dispatch(
              updateEditedTestPhaseLoad({
                ...editedLoad,
                glasstaerke: v,
              }),
            )
          }
        />
        <InputFieldNumber
          additionalClass="test-phases__data-dialog-windload-form-angle"
          label="Einbauwinkel (°)"
          placeholder="z.B. 42"
          value={editedLoad.einbauwinkel}
          onChange={v =>
            dispatch(
              updateEditedTestPhaseLoad({
                ...editedLoad,
                einbauwinkel: v,
              }),
            )
          }
        />
        {props.snowLoad ? (
          <InputFieldNumber
            additionalClass="test-phases__data-dialog-windload-form-windload"
            label="gepr. Scheelast (Pa)"
            placeholder="z.B. 42"
            value={editedLoad.gepruefteSchneelast}
            onChange={v =>
              dispatch(
                updateEditedTestPhaseLoad({
                  ...editedLoad,
                  gepruefteSchneelast: v,
                }),
              )
            }
          />
        ) : (
          <InputFieldNumber
            additionalClass="test-phases__data-dialog-windload-form-windload"
            label="gepr. Windlast (Pa)"
            placeholder="z.B. 42"
            value={editedLoad.gepruefteWindlast}
            onChange={v =>
              dispatch(
                updateEditedTestPhaseLoad({
                  ...editedLoad,
                  gepruefteWindlast: v,
                }),
              )
            }
          />
        )}
        <InputFieldNumber
          additionalClass="test-phases__data-dialog-windload-form-minDrives"
          label="min. Anzahl Antriebe"
          placeholder="z.B. 42"
          value={editedLoad.antriebsAnzahl}
          onChange={v =>
            dispatch(
              updateEditedTestPhaseLoad({
                ...editedLoad,
                antriebsAnzahl: v,
              }),
            )
          }
        />
        <InputFieldNumber
          additionalClass="test-phases__data-dialog-windload-form-maxDrives"
          label="max. Anzahl Antriebe"
          placeholder="z.B. 42"
          value={editedLoad.antriebsAnzahlBis}
          onChange={v =>
            dispatch(
              updateEditedTestPhaseLoad({
                ...editedLoad,
                antriebsAnzahlBis: v,
              }),
            )
          }
        />
        <SelectField<string>
          additionalClass="test-phases__data-dialog-windload-form-driveType"
          label="Antriebsart"
          value={
            editedLoad?.driveSeries
              ? {
                  value: editedLoad.driveSeries,
                  label: formatMessage({
                    id: formatMessage({ id: editedLoad.driveSeries }),
                  }),
                }
              : undefined
          }
          action={newValue => {
            dispatch(
              updateEditedTestPhaseLoad({
                ...editedLoad,
                driveSeries: newValue.value,
              }),
            );
          }}
          options={allDriveSeriesNames.map(driveSeries => ({
            label: formatMessage({ id: driveSeries }),
            value: driveSeries,
          }))}
          name="company"
          searchable={true}
          menuPlacement={MenuPlacement.BOTTOM}
        />

        <EditWindLoadCheckbox
          additionalClass="test-phases__data-dialog-windload-form-lock"
          label="Verriegelung"
          propertyName="verriegelung"
        />
        <Headline
          additionalClass="test-phases__data-dialog-windload-form-headlinePosition"
          size={HeadlineSize.MD}
        >
          Montageposition
        </Headline>
        <EditWindLoadCheckbox
          additionalClass="test-phases__data-dialog-windload-form-opposite"
          label="gegü. Band"
          propertyName="montagePosition_ggBand"
        />
        <EditWindLoadCheckbox
          additionalClass="test-phases__data-dialog-windload-form-side"
          label="Seitl."
          propertyName="montagePosition_seitl"
        />
        <EditWindLoadCheckbox
          additionalClass="test-phases__data-dialog-windload-form-traverse"
          label="Traverse"
          propertyName="montagePosition_traverse"
        />
        <Headline
          additionalClass="test-phases__data-dialog-windload-form-headlineFormat"
          size={HeadlineSize.MD}
        >
          Format
        </Headline>
        <EditWindLoadCheckbox
          additionalClass="test-phases__data-dialog-windload-form-tall"
          label="Hoch"
          propertyName="format_hoch"
        />
        <EditWindLoadCheckbox
          additionalClass="test-phases__data-dialog-windload-form-across"
          label="Quer"
          propertyName="format_quer"
        />
        <Headline
          additionalClass="test-phases__data-dialog-windload-form-headlineOpeningDirection"
          size={HeadlineSize.MD}
        >
          Öffnungsrichtung
        </Headline>
        <EditWindLoadCheckbox
          additionalClass="test-phases__data-dialog-windload-form-in"
          label="Einwärts"
          propertyName="oeffnungsrichtung_einw"
        />
        <EditWindLoadCheckbox
          additionalClass="test-phases__data-dialog-windload-form-out"
          label="Auswärts"
          propertyName="oeffnungsrichtung_ausw"
        />
      </FormLayout>
    </Dialog>
  );
};

interface EditWindLoadCheckboxProps {
  label: string;
  propertyName: keyof SubType<
    TestPhaseWindLoadOrSnowLoadWithOptionalId,
    boolean
  >;
  additionalClass?: string;
}

const EditWindLoadCheckbox: FC<
  React.PropsWithChildren<EditWindLoadCheckboxProps>
> = props => {
  const editedWindLoad = useSelector<
    AdminState,
    TestPhaseWindLoadOrSnowLoadWithOptionalId
  >(
    state =>
      state.adminFacade
        .editedTestPhaseLoad! as TestPhaseWindLoadOrSnowLoadWithOptionalId,
  );
  const dispatch: AdminThunkDispatch<AnyAction> = useDispatch();

  return (
    <CheckBox
      additionalClass={props.additionalClass}
      label={props.label}
      checked={editedWindLoad[props.propertyName || 'format_quer']}
      onClick={() =>
        dispatch(
          updateEditedTestPhaseLoad({
            ...editedWindLoad,
            [props.propertyName || 'format_quer']:
              !editedWindLoad[props.propertyName || 'format_quer'],
          }),
        )
      }
    />
  );
};

interface WindLoadsDialogProps<T> {
  shown: boolean;
  setShown: (b: boolean) => void;
  editedTestPhaseSeries: T | undefined;
  updateEditedSeries: (t: T, v: Record<string, any>) => void;
  snowLoad: boolean;
}

const WindOrSnowLoadsDialog = <T,>(
  props: WindLoadsDialogProps<T>,
): ReactElement | null => {
  const dispatch: AdminThunkDispatch<AnyAction> = useDispatch();
  const [dialogIsShown, setDialogIsShown] = useState(false);
  const [editedWindLoadIndex, setEditedWindLoadIndex] = useState(0);
  const loads = useSelector<
    AdminState,
    TestPhaseWindLoadOrSnowLoadWithOptionalId[]
  >(state => state.adminFacade.editedTestPhaseLoads);

  function editOverview(
    windLoad: TestPhaseWindLoadOrSnowLoadWithOptionalId,
  ): void {
    dispatch(updateEditedTestPhaseLoad(windLoad));
    setEditedWindLoadIndex(loads.indexOf(windLoad));
    setDialogIsShown(true);
  }

  function deleteOverview(
    windLoad: TestPhaseWindLoadOrSnowLoadWithOptionalId,
  ): void {
    dispatch(updateEditedTestPhaseLoads(loads.filter(ao => ao !== windLoad)));
  }

  function newOverview(): void {
    dispatch(updateEditedTestPhaseLoad({}));
    setEditedWindLoadIndex(-1);
    setDialogIsShown(true);
  }

  if (!props.editedTestPhaseSeries) {
    return null;
  }

  return (
    <Dialog
      headingText={props.snowLoad ? 'Schneelast (Pa)' : 'Windlast (Pa)'}
      setDialogIsShown={props.setShown}
      dialogIsShown={props.shown}
      componentClass="test-phases__data-dialog-windload"
      nested={true}
      footerProps={{
        notTranslated: true,
        cancelAction: () => props.setShown(false),
        saveAction: () => {
          props.setShown(false);
          props.updateEditedSeries(props.editedTestPhaseSeries!, {
            [props.snowLoad ? 'snowLoads' : 'windLoads']: loads,
          });
        },
        primaryActionLabelText: 'Speichern',
      }}
    >
      <Button
        labelText={
          props.snowLoad ? 'Neue Scheelast (PA)' : 'Neue Windlast (Pa)'
        }
        action={newOverview}
        type={ButtonType.FRAME}
        additionalClass="test-phases__data-dialog-windload-button"
      />
      <Table>
        <TableHeader>
          <th>gepr. Fläche (m²)</th>
          <th>Glasstärke (mm)</th>
          <th>Einbauwinkel (mm)</th>
          <th>
            {props.snowLoad ? 'gepr. Schneelast (Pa)' : 'gepr. Windlast (Pa)'}
          </th>
          <th>Montageposition</th>
          <th>Format</th>
          <th>Antriebsart</th>
          <th>Antriebsanzahl</th>
          <th>Öffnunsrichtung</th>
          <th>Verriegelung</th>
          <th className="table-header__action-column"></th>
          <th className="table-header__action-column"></th>
        </TableHeader>
        {loads.map(load => (
          <TableRow key={load.id}>
            <td>{load.gepruefteFlaeche}</td>
            <td>{load.glasstaerke}</td>
            <td>{load.einbauwinkel}</td>
            <td>
              {props.snowLoad
                ? load.gepruefteSchneelast
                : load.gepruefteWindlast}
            </td>
            <td className="table__cell--multiline">
              <div className="table__cell-line">
                <AdminListIcon checked={load.montagePosition_ggBand} /> gegü.
                Band
              </div>
              <div className="table__cell-line">
                <AdminListIcon checked={load.montagePosition_seitl} /> Seitl.
              </div>
              <div className="table__cell-line">
                <AdminListIcon checked={load.montagePosition_traverse} />{' '}
                Traverse
              </div>
            </td>
            <td className="table__cell--multiline">
              <div className="table__cell-line">
                <AdminListIcon checked={load.format_hoch} /> Hochformat
              </div>
              <div className="table__cell-line">
                <AdminListIcon checked={load.format_quer} /> Querformat
              </div>
            </td>
            <td>
              <FormattedMessage id={load.driveSeries} />
            </td>
            <td>
              {load.antriebsAnzahl} bis {load.antriebsAnzahlBis}
            </td>
            <td className="table__cell--multiline">
              <div className="table__cell-line">
                <AdminListIcon checked={load.oeffnungsrichtung_einw} /> Einwärts
              </div>
              <div className="table__cell-line">
                <AdminListIcon checked={load.oeffnungsrichtung_ausw} /> Auswärts
              </div>
            </td>
            <td>
              <AdminListIcon checked={load.verriegelung} />
            </td>
            <td>
              <Button
                action={() => editOverview(load)}
                labelText={UiConstants.EDIT}
                type={ButtonType.SECONDARY}
              />
            </td>
            <td>
              <Button
                action={() => deleteOverview(load)}
                labelText={UiConstants.DELETE}
                type={ButtonType.SECONDARY}
              />
            </td>
          </TableRow>
        ))}
        {dialogIsShown && (
          <WindLoadsEditDialog
            shown={dialogIsShown}
            setShown={setDialogIsShown}
            editedWindLoadIndex={editedWindLoadIndex}
            snowLoad={props.snowLoad}
          />
        )}
      </Table>
    </Dialog>
  );
};

export default WindOrSnowLoadsDialog;
