import React, { FC, useEffect, useState } from 'react';
import FormLayout from '../../elements/FormLayout';
import InputField, { InputFieldNumber } from '../../elements/InputField';
import TextArea from '../../elements/TextArea';
import { Switch } from '../../elements/Switch';
import './FacadeBasicProfileDataView.scss';
import { SelectField } from '../../elements/SelectField';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import Dialog from '../../components/Dialog';
import Table from '../components/Table';
import TableHeader from '../elements/TableHeader';
import TableRow from '../elements/TableRow';
import SearchField from '../../elements/SearchField';
import { BasicProfile, Series } from '../../redux/admin/adminFacadeReducer';
import { ProfileDataDialogBasicProps, UiConstants } from '../constants';
import { AdminState, AdminThunkDispatch } from '../../redux/admin/adminStore';
import Pagination from '../components/Pagination';
import {
  changeBasicProfile,
  createBasicProfile,
} from '../../redux/admin/adminFacadeActions';
import { fieldsFilled, getSeriesForProfileId } from '../general/helpers';
import AdminListIcon from '../elements/AdminListIcon';
import { useValidateProfileArticleNumber } from './FacadeSashProfileDataView';
import { AnyAction } from 'redux';
import AdminNavLink from '../common/AdminNavLink';
import { useAdminSearch } from '../hooks';
import { downloadListAsCSV } from '../common/download';

const BasicProfileDataDialogTabNav: FC<
  React.PropsWithChildren<unknown>
> = () => {
  return (
    <div className="admin__tab-nav frame-profile-data-dialog-tab-nav">
      <AdminNavLink to="/admin/facade/profiles/basic-profiles/basic">
        <Routes>
          <Route
            path="/"
            element={
              <Navigate
                replace
                to="/admin/facade/profiles/basic-profiles/basic"
              />
            }
          />
        </Routes>
        Allgemein
      </AdminNavLink>
      <AdminNavLink to="/admin/facade/profiles/basic-profiles/basic-information">
        Eckdaten
      </AdminNavLink>
    </div>
  );
};

const BasicProfileDataDialogBasic: FC<
  React.PropsWithChildren<ProfileDataDialogBasicProps>
> = (props: ProfileDataDialogBasicProps) => {
  const articleNumberError = useValidateProfileArticleNumber(
    s => s.adminFacade.basicProfiles,
    props.series,
    props.artNr,
    props.id,
  );

  return (
    <div className="">
      <FormLayout additionalClass="basic-profile-data-dialog__layout-basic">
        <InputField
          label="Artikelnummer*"
          placeholder="Artikelnummer des Grundprofils"
          value={props.artNr}
          id="1"
          additionalClass="basic-profile-data-dialog__article-nr"
          onChange={props.setArtNr}
          errorMessage={articleNumberError}
        />
        <TextArea
          label="Information"
          additionalClass="basic-profile-data-dialog__info"
          placeholder="Informationen zum Grundprofil"
          value={props.beschreibung}
          onChange={props.setBeschreibung}
        />
        <SelectField
          additionalClass="basic-profile-data-dialog__serie"
          label="Serie*"
          value={
            props.series
              ? {
                  value: props.series!,
                  label: props.series!.name,
                }
              : undefined
          }
          action={newValue => {
            props.setSeries(newValue.value);
          }}
          options={props.allSeries.map(series => ({
            label: series.name,
            value: series,
          }))}
          name="seriesOfBasicProfile"
          searchable={true}
          placeholder={UiConstants.SELECT_SERIES}
        />
        <div className="basic-profile-data-dialog__switch">
          <Switch
            labelText={
              props.series?.active
                ? 'aktiv'
                : 'Aktivierung nicht möglich. Serie ' +
                  props.series?.name +
                  ' muss aktiv sein.'
            }
            turnedOn={props.isActive}
            onChange={props.setIsActive}
          />
        </div>
        <div className="basic-profile-data-dialog__preview">
          <Switch
            labelText="Vorschau"
            turnedOn={props.isPreview}
            onChange={props.setIsPreview}
          />
        </div>
      </FormLayout>
    </div>
  );
};

interface BasicProfileDataDialogBasicInformationProps {
  kammermassInnen: number | null | undefined;
  setKammermassInnen: (i: number | null) => void;
  kammermassAussen: number | null | undefined;
  setKammermassAussen: (i: number | null) => void;
  bautiefe: number | null | undefined;
  setBautiefe: (i: number | null) => void;
  isPfostenRiegel: boolean;
  setIsPfostenRiegel: (b: boolean) => void;
}

const BasicProfileDataDialogBasicInformation: FC<
  React.PropsWithChildren<BasicProfileDataDialogBasicInformationProps>
> = (props: BasicProfileDataDialogBasicInformationProps) => {
  return (
    <div>
      <FormLayout additionalClass="basic-profile-data-dialog__layout-basic-information">
        <InputFieldNumber
          label="Kammermaß innen (mm)*"
          placeholder="z.B. 42"
          value={props.kammermassInnen}
          additionalClass="basic-profile-data-dialog__measurement-inside"
          onChange={props.setKammermassInnen}
        />
        <InputFieldNumber
          label="Kammermaß außen (mm)*"
          placeholder="z.B. 42"
          value={props.kammermassAussen}
          additionalClass="basic-profile-data-dialog__measurement-outside"
          onChange={props.setKammermassAussen}
        />
        <div className="basic-profile-data-dialog__pfosten">
          <Switch
            labelText="Pfostenriegel"
            turnedOn={props.isPfostenRiegel}
            onChange={props.setIsPfostenRiegel}
          />
        </div>
        <InputFieldNumber
          label="Bautiefe (mm)"
          placeholder="z.B. 42"
          value={props.bautiefe}
          additionalClass="basic-profile-data-dialog__building-depth"
          onChange={props.setBautiefe}
        />
      </FormLayout>
    </div>
  );
};

interface AdminProfileDialogProps {
  setDialogIsShown: (b: boolean) => void;
  dialogIsShown: boolean;
  allSeries: Series[];
  selectedBasicProfile: BasicProfile | undefined;
  activeSwitchEnabled: boolean;
}

const AdminBasicProfileDialog: FC<
  React.PropsWithChildren<AdminProfileDialogProps>
> = (props: AdminProfileDialogProps) => {
  const dispatch: AdminThunkDispatch<AnyAction> = useDispatch();
  const [artNr, setArtNr] = useState(props.selectedBasicProfile?.artNr || '');
  const [isActive, setIsActive] = useState(
    props.selectedBasicProfile?.active || false,
  );
  const [isPreview, setIsPreview] = useState(
    props.selectedBasicProfile?.preview ||
      (props.selectedBasicProfile?.preview === false ? false : true),
  );
  const [serie, setSerie] = useState(
    getSeriesForProfileId(
      props.selectedBasicProfile?.seriesId,
      props.allSeries,
    ),
  );

  if (props.selectedBasicProfile && !serie) {
    setSerie(
      getSeriesForProfileId(
        props.selectedBasicProfile?.seriesId,
        props.allSeries,
      ),
    );
  }

  const [beschreibung, setBeschreibung] = useState(
    props.selectedBasicProfile?.beschreibung || '',
  );
  const [kammermassInnen, setKammermassInnen] = useState<
    number | undefined | null
  >(props.selectedBasicProfile?.kammermassInnen);
  const [kammermassAussen, setKammermassAussen] = useState<
    number | undefined | null
  >(props.selectedBasicProfile?.kammermassAussen);
  const [bautiefe, setBautiefe] = useState<number | undefined | null>(
    props.selectedBasicProfile?.bautiefe,
  );
  const [isPfostenRiegel, setIsPfostenRiegel] = useState(
    props.selectedBasicProfile?.pfostenRiegelKonstr || false,
  );
  const articleNumberError = useValidateProfileArticleNumber(
    s => s.adminFacade.basicProfiles,
    serie,
    artNr,
    props.selectedBasicProfile?.id,
  );
  const l = useLocation();

  useEffect(() => {
    resetInputFields();
  }, [props.selectedBasicProfile]);

  function resetInputFields(): void {
    //for BasicProfileDataDialogBasic
    setIsActive(props.selectedBasicProfile?.active || false);
    setIsPreview(
      props.selectedBasicProfile?.preview ||
        (props.selectedBasicProfile?.preview === false ? false : true),
    );
    setArtNr(props.selectedBasicProfile?.artNr || '');
    setBeschreibung(props.selectedBasicProfile?.beschreibung || '');
    setSerie(
      getSeriesForProfileId(
        props.selectedBasicProfile?.seriesId,
        props.allSeries,
      )! || undefined,
    );
    setKammermassInnen(props.selectedBasicProfile?.kammermassInnen);
    setKammermassAussen(props.selectedBasicProfile?.kammermassAussen);
    setBautiefe(props.selectedBasicProfile?.bautiefe);
    setIsPfostenRiegel(
      props.selectedBasicProfile?.pfostenRiegelKonstr || false,
    );
  }

  function editBasicProfile(): void {
    dispatch(
      changeBasicProfile({
        ...props.selectedBasicProfile,
        seriesId: serie!.id,
        series: serie!,
        kammermassAussen: kammermassAussen,
        artNr: artNr,
        beschreibung: beschreibung,
        kammermassInnen: kammermassInnen,
        active: isActive,
        preview: isPreview,
        bautiefe: bautiefe,
        pfostenRiegelKonstr: isPfostenRiegel,
        dbType: 'Fassade',
      }),
    );
  }

  function newBasicProfile(): void {
    dispatch(
      createBasicProfile({
        id: undefined,
        seriesId: serie!.id,
        series: serie!,
        kammermassAussen: kammermassAussen,
        artNr: artNr,
        beschreibung: beschreibung,
        kammermassInnen: kammermassInnen,
        active: isActive,
        preview: isPreview,
        bautiefe: bautiefe,
        pfostenRiegelKonstr: isPfostenRiegel,
        dbType: 'Fassade',
      }),
    );
  }

  function mandatoryFieldsFilled(): boolean {
    return (
      fieldsFilled(artNr, serie, kammermassAussen, kammermassInnen) &&
      !articleNumberError
    );
  }

  return (
    <Dialog
      cancelAction={resetInputFields}
      setDialogIsShown={props.setDialogIsShown}
      dialogIsShown={props.dialogIsShown}
      headingText={
        props.selectedBasicProfile === undefined
          ? 'Neues Grundprofil anlegen'
          : `Grundprofil ${props.selectedBasicProfile.artNr} bearbeiten`
      }
      componentClass="full-view-dialog"
      key={props.dialogIsShown.toString()}
      footerProps={{
        notTranslated: true,
        cancelAction: () => {
          resetInputFields();
          props.setDialogIsShown(false);
        },
        saveAction: mandatoryFieldsFilled()
          ? () => {
              props.selectedBasicProfile
                ? editBasicProfile()
                : newBasicProfile();
              props.setDialogIsShown(false);
            }
          : undefined,
        primaryActionLabelText:
          props.selectedBasicProfile === undefined
            ? UiConstants.ADD
            : UiConstants.SAVE,
      }}
    >
      <BasicProfileDataDialogTabNav />
      <Routes>
        <Route
          path="/basic"
          element={
            <BasicProfileDataDialogBasic
              selectedBasicProfile={props.selectedBasicProfile}
              allSeries={props.allSeries}
              setIsActive={setIsActive}
              isActive={isActive}
              setIsPreview={setIsPreview}
              isPreview={isPreview}
              artNr={artNr}
              setArtNr={setArtNr}
              beschreibung={beschreibung}
              setBeschreibung={setBeschreibung}
              series={serie}
              setSeries={setSerie}
              id={props.selectedBasicProfile?.id}
            />
          }
        />
        <Route
          path="/basic-information"
          element={
            <BasicProfileDataDialogBasicInformation
              kammermassInnen={kammermassInnen}
              setKammermassInnen={setKammermassInnen}
              kammermassAussen={kammermassAussen}
              setKammermassAussen={setKammermassAussen}
              bautiefe={bautiefe}
              setBautiefe={setBautiefe}
              isPfostenRiegel={isPfostenRiegel}
              setIsPfostenRiegel={setIsPfostenRiegel}
            />
          }
        />
      </Routes>
    </Dialog>
  );
};

const FacadeBasicProfileDataView: FC<React.PropsWithChildren<unknown>> = () => {
  const [dialogIsShown, setDialogIsShown] = useState(false);
  const [searchString, setSearchString] = useState('');
  const allSeries = useSelector<AdminState, Series[]>(
    state => state.adminFacade.series,
  );
  const [selectedBasicProfileID, setSelectedBasicProfileID] = useState<
    undefined | number
  >(undefined);
  const basicProfiles = useSelector<AdminState, BasicProfile[]>(
    state => state.adminFacade.basicProfiles,
  );
  const selectedBasicProfile = basicProfiles.find(
    b => b.id === selectedBasicProfileID || undefined,
  );
  const [filterActive, setFilterActive, searchResult] = useAdminSearch(
    basicProfiles,
    searchString,
    ['artNr'],
    entry => {
      const series = getSeriesForProfileId(entry.seriesId, allSeries);
      return [series?.name || '', series?.system.name || ''];
    },
  );

  const [indexOfFirstPageElement, setIndexOfFirstPageElement] = useState(0);
  const [page, setPage] = useState(1);
  const dispatch: AdminThunkDispatch<AnyAction> = useDispatch();

  function triggerCreationMode(): void {
    setDialogIsShown(true);
    setSelectedBasicProfileID(undefined);
  }

  function getCurrentTableContent(): BasicProfile[] {
    return searchResult.slice(
      indexOfFirstPageElement,
      indexOfFirstPageElement + 20,
    );
  }

  function enableEditBasicProfile(basicProfile: BasicProfile): void {
    setSelectedBasicProfileID(basicProfile.id);
    setDialogIsShown(true);
  }

  function parentSeriesIsActive(basicProfile: BasicProfile): boolean {
    return (
      allSeries.find(series => series.id === basicProfile.seriesId)?.active ||
      false
    );
  }

  return (
    <>
      <AdminBasicProfileDialog
        setDialogIsShown={setDialogIsShown}
        dialogIsShown={dialogIsShown}
        allSeries={allSeries}
        selectedBasicProfile={selectedBasicProfile}
        activeSwitchEnabled={
          (selectedBasicProfile &&
            parentSeriesIsActive(selectedBasicProfile)) ||
          false
        }
      />

      <div className="sub-header">
        <div className="sub-header__title"> Grundprofile</div>

        <SearchField
          setSearchString={setSearchString}
          searchString={searchString}
          placeholderText="Grundprofil suchen..."
          small={true}
          setFilterActive={setFilterActive}
          filterActive={filterActive}
        />
        <button
          className="sub-header__download-button"
          onClick={() =>
            downloadListAsCSV(
              searchResult,
              ';',
              ['legacyId'],
              'GrundprofileFassade',
              { series: (s: Series) => s.name },
              { system: profile => profile.series.system.name },
            )
          }
        >
          CSV
        </button>
        <button
          className="sub-header__button sub-header__button--add"
          onClick={() => triggerCreationMode()}
        >
          {UiConstants.NEW_ENTRY}
        </button>
      </div>
      <div className="basic-profile-data">
        <Table subNavigation={true}>
          <TableHeader>
            <th>Artikelnummer</th>
            <th>Beschreibung</th>
            <th>System</th>
            <th>Serie</th>
            <th>Pfosten-Riegel</th>
            <th>Aktiv</th>
            <th>Aktion</th>
          </TableHeader>
          <tbody>
            {getCurrentTableContent().map(basicProfile => (
              <TableRow key={basicProfile.artNr}>
                <td>{basicProfile.artNr}</td>
                <td>{basicProfile.beschreibung}</td>
                <td>
                  {getSeriesForProfileId(basicProfile.seriesId, allSeries)
                    ?.system.name ?? ''}
                </td>
                <td>
                  {getSeriesForProfileId(basicProfile.seriesId, allSeries)
                    ?.name ?? ''}
                </td>
                <td>
                  <AdminListIcon checked={basicProfile.pfostenRiegelKonstr} />
                </td>
                <td>
                  <Switch
                    turnedOn={
                      parentSeriesIsActive(basicProfile)
                        ? basicProfile.active
                        : false
                    }
                    onChange={() =>
                      parentSeriesIsActive(basicProfile)
                        ? dispatch(
                            changeBasicProfile({
                              ...basicProfile,
                              active: !basicProfile.active,
                            }),
                          )
                        : null
                    }
                  />
                </td>
                <td>
                  <button
                    onClick={() => {
                      enableEditBasicProfile(basicProfile);
                    }}
                  >
                    {UiConstants.EDIT}
                  </button>
                </td>
              </TableRow>
            ))}
          </tbody>
        </Table>
      </div>
      <Pagination
        searchString={searchString}
        numberOfPages={searchResult.length}
        page={page}
        setPage={setPage}
        indexOfFirstPageElement={indexOfFirstPageElement}
        setIndexOfFirstPageElement={setIndexOfFirstPageElement}
      />
    </>
  );
};
export default FacadeBasicProfileDataView;
