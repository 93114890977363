import { combineReducers, Reducer } from 'redux';
import adminFacadeReducer from './admin/adminFacadeReducer';
import { adminRoofReducer } from './admin/adminRoofReducer';
import globalUiStateReducer from './globalUiStateReducer';
import { intlReducer } from 'react-intl-redux';
import uiStateReducer from './uiStateReducer';
import authenticationReducer from './authenticationReducer';

const adminReducer: Reducer = combineReducers({
  globalUIState: globalUiStateReducer,
  adminFacade: adminFacadeReducer,
  adminRoof: adminRoofReducer,
  intl: intlReducer,
  ui: uiStateReducer,
  authentication: authenticationReducer,
});

export default adminReducer;
