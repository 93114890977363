import React, { FC, ReactElement, useState } from 'react';
import ManagerPage, {
  cell,
  label,
  ManagerPageTable,
  ManagerTableCellProps,
} from '../ManagerPage';
import '../../app/App.scss';
import './DocumentsArea.scss';
import '../ManagerPage.scss';
import '../Projects/ProjectsManager.scss';
import TabbedComponent, { Item } from '../../elements/TabbedComponent';
import EmptyStateContainer from '../../elements/EmptyStateContainer';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { IState } from '../../redux/store';
import {
  DocumentsListsResult,
  DocumentTypesForStaticDocuments,
  StaticDocument,
} from '../../redux/staticDataReducer';
import { ReactComponent as MenuPopupIconDocuments } from '../Header/MenuPopupIconDocuments.svg';
import { ReactComponent as IconFileDownload } from './IconFileDownload.svg';
import ProtectedDownloadLink from '../../elements/ProtectedDownloadLink';
import { Locale } from '../../redux/uiStateReducer';
import { searchByMultipleSubstrings } from '../../admin/general/helpers';
import _ from 'lodash';
import { LoggedInUser } from '../../redux/authenticationReducer';

const DocumentsArea: FC<React.PropsWithChildren<unknown>> = () => {
  const allDocuments = useSelector<IState, Partial<DocumentsListsResult>>(
    state => state.staticData.documentsListsResult,
  );
  const [searchString, setSearchString] = useState<string>('');
  const locale: Locale = useSelector(
    (state: IState) => state.authentication.locale,
  );
  const DOCUMENTS_EMPTY_STATE: ReactElement = (
    <EmptyStateContainer size="lg">
      <FormattedMessage id="NO_EXISTING_DOCUMENTS" />
    </EmptyStateContainer>
  );
  const currentUser = useSelector<IState, LoggedInUser | undefined>(
    (state: IState) => state.authentication.currentUser,
  );

  const SEARCHABLE_PROPERTIES_VALUES_EXTRACTORS = [
    (document: StaticDocument) => document.fileName,
    (document: StaticDocument) =>
      locale === Locale.DE ? document.descriptionDe : document.descriptionEn,
  ];

  function filterDocuments(setOfDocuments: StaticDocument[]): StaticDocument[] {
    function addCertificateToListOfWpkDocumentsIfNecessary(
      certificate: StaticDocument | undefined,
    ): void {
      if (
        certificate &&
        setOfDocuments[0].documentType ===
          DocumentTypesForStaticDocuments.WPK_DOCUMENT
      ) {
        sortedAndSearchedListOfWpkDocuments.unshift(certificate);
      }
    }

    const sortedAndSearchedListOfWpkDocuments = searchByMultipleSubstrings(
      SEARCHABLE_PROPERTIES_VALUES_EXTRACTORS,
      setOfDocuments,
      searchString,
    ).sort((a, b) =>
      a.fileName.localeCompare(
        b.fileName,
        locale === Locale.DE ? Locale.DE : Locale.EN,
        { numeric: true },
      ),
    );

    addCertificateToListOfWpkDocumentsIfNecessary(allDocuments.certificate);

    return sortedAndSearchedListOfWpkDocuments;
  }

  function getCorrectDescription(document: StaticDocument): string {
    let description: string;

    if (
      document.documentType === DocumentTypesForStaticDocuments.FORM ||
      document.documentType === DocumentTypesForStaticDocuments.CALCULATION_AID
    ) {
      description = document.descriptionDe || document.descriptionEn;
    } else {
      description =
        locale === Locale.DE ? document.descriptionDe : document.descriptionEn;
    }
    return description;
  }

  function getRow(
    document: StaticDocument,
    withoutDescription: boolean,
  ): ManagerTableCellProps[] {
    return _.compact([
      cell(document.fileName, true),
      withoutDescription ? null : cell(getCorrectDescription(document), true),
      cell(
        <ProtectedDownloadLink
          label={<IconFileDownload />}
          link={document.downloadLink}
        />,
        true,
      ),
    ]);
  }

  const WPK_MANAGER_PAGE_TABLE: ReactElement = (
    <ManagerPageTable
      setSearchString={searchsString => setSearchString(searchsString)}
      searchString={searchString}
      placeHolder={'PLACEHOLDER_SEARCH_FOR_WPK_DOCS'}
      header={[
        label('NAME_OF_DOCUMENT'),
        label('vorlage_description'),
        label('DOCUMENT_DOWNLOAD_DOWNLOAD_BUTTON'),
      ]}
      rows={filterDocuments(allDocuments.wpkDocuments || []).map(
        (wpkDocument, index) => ({
          cells: getRow(wpkDocument, false),
          rowElement: wpkDocument,
          icon: <MenuPopupIconDocuments />,
          key: wpkDocument.id
            ? wpkDocument.id.toString()
            : index.toString().concat(wpkDocument.fileName),
        }),
      )}
      hasMore={false}
      loadMore={() => false}
    />
  );

  const FORMS_MANAGER_PAGE_TABLE: ReactElement = (
    <ManagerPageTable
      header={[
        label('NAME_OF_DOCUMENT'),
        label('vorlage_description'),
        label('DOCUMENT_DOWNLOAD_DOWNLOAD_BUTTON'),
      ]}
      hasMore={false}
      loadMore={() => false}
      rows={(allDocuments.formDocuments || []).map((form, index) => ({
        cells: getRow(form, false),
        rowElement: form,
        icon: <MenuPopupIconDocuments />,
        key: form.id
          ? form.id.toString()
          : index.toString().concat(form.fileName),
      }))}
    />
  );

  const CALCULATION_AIDS_MANAGER_PAGE_TABLE: ReactElement = (
    <ManagerPageTable
      header={[
        label('NAME_OF_DOCUMENT'),
        label('DOCUMENT_DOWNLOAD_DOWNLOAD_BUTTON'),
      ]}
      hasMore={false}
      loadMore={() => false}
      rows={(allDocuments.calculationAids || []).map(
        (calculationAid, index) => ({
          cells: getRow(calculationAid, true),
          rowElement: calculationAid,
          icon: <MenuPopupIconDocuments />,
          key: calculationAid.id
            ? calculationAid.id.toString()
            : index.toString().concat(calculationAid.fileName),
        }),
      )}
    />
  );

  const CERTIFIED_ACCESSORY_MANAGER_PAGE_TABLE: ReactElement = (
    <ManagerPageTable
      header={[
        label('NAME_OF_DOCUMENT'),
        label('vorlage_description'),
        label('DOCUMENT_DOWNLOAD_DOWNLOAD_BUTTON'),
      ]}
      hasMore={false}
      loadMore={() => false}
      rows={(allDocuments.certifiedAccessory || []).map((accessory, index) => ({
        cells: getRow(accessory, false),
        rowElement: accessory,
        icon: <MenuPopupIconDocuments />,
        key: accessory.id
          ? accessory.id.toString()
          : index.toString().concat(accessory.fileName),
      }))}
    />
  );

  const documentsTabbedComponentItems = [
    {
      label: 'CALCULATION_AIDS',
      component:
        allDocuments.calculationAids?.length !== 0
          ? CALCULATION_AIDS_MANAGER_PAGE_TABLE
          : DOCUMENTS_EMPTY_STATE,
    },
    {
      label: 'FORMS',
      component:
        allDocuments.formDocuments?.length !== 0
          ? FORMS_MANAGER_PAGE_TABLE
          : DOCUMENTS_EMPTY_STATE,
    },
    {
      label: 'WPK',
      component: currentUser?.partnerOrAdmin
        ? WPK_MANAGER_PAGE_TABLE
        : DOCUMENTS_EMPTY_STATE,
    },
    {
      label: 'CERTIFIED_COMPONENTS',
      component:
        allDocuments.certifiedAccessory?.length !== 0
          ? CERTIFIED_ACCESSORY_MANAGER_PAGE_TABLE
          : DOCUMENTS_EMPTY_STATE,
    },
  ];

  function getItems(items: Item[]): Item[] {
    return currentUser?.partnerOrAdmin
      ? items
      : items.filter(
          item => item.label === 'FORMS' || item.label === 'CALCULATION_AIDS',
        );
  }

  return (
    <ManagerPage
      additionalClass="documents-area"
      headline={
        getItems(documentsTabbedComponentItems).length === 1
          ? getItems(documentsTabbedComponentItems)[0].label
          : 'result_docs_view1'
      }
    >
      <>{<TabbedComponent items={getItems(documentsTabbedComponentItems)} />}</>
    </ManagerPage>
  );
};

export default DocumentsArea;
