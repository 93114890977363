import * as React from 'react';
// @ts-ignore
const SvgEinzelklappeKippOhneWlw = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="Einzelklappe_Kipp_ohne_WLW_svg__Ebene_1"
    data-name="Ebene 1"
    viewBox="0 0 1440 1080"
    {...props}
    style={{ marginBottom: '-170px', marginTop: '-170px' }}
  >
    <defs>
      <style>
        {
          '.Einzelklappe_Kipp_ohne_WLW_svg__cls-10,.Einzelklappe_Kipp_ohne_WLW_svg__cls-11,.Einzelklappe_Kipp_ohne_WLW_svg__cls-8{fill:none;stroke:#1e1e1c;stroke-miterlimit:10}.Einzelklappe_Kipp_ohne_WLW_svg__cls-10{stroke-width:.75px}.Einzelklappe_Kipp_ohne_WLW_svg__cls-18,.Einzelklappe_Kipp_ohne_WLW_svg__cls-21{fill:#1e1e1c}.Einzelklappe_Kipp_ohne_WLW_svg__cls-21{font-family:ArialMT,Arial}.Einzelklappe_Kipp_ohne_WLW_svg__cls-8{stroke-width:2px}.Einzelklappe_Kipp_ohne_WLW_svg__cls-21{font-size:27.5px}.Einzelklappe_Kipp_ohne_WLW_svg__cls-25{fill:#ececec}'
        }
      </style>
    </defs>
    <path
      d="m655.17 758.96 368.38-260.64 368.39 260.64H655.17zM48.06 588.23l182.02-179.75 167.9 354.19L48.06 588.23z"
      className="Einzelklappe_Kipp_ohne_WLW_svg__cls-25"
    />
    <path
      d="m230.08 408.48 199.89-91.15 167.12 354.01-99.5 45.64-80-152.91 54.9-25.11-64.03-135.32-96.93 44.84 63.87 134.84 42.19-19.25 80 152.91-99.61 45.69-167.9-354.19z"
      style={{
        fill: '#b0b0b1',
      }}
    />
    <path
      d="m352.02 458.12 96.07-43.6 24.4 124.44-97.09 44.36-23.38-125.2z"
      style={{
        fill: '#b0b0b1',
        opacity: 0.3,
      }}
    />
    <path
      d="m48.06 588.23 182.02-179.75 167.9 354.19L48.06 588.23z"
      style={{
        strokeWidth: 2,
        strokeLinecap: 'round',
        strokeLinejoin: 'round',
        fill: 'none',
        stroke: '#1e1e1c',
      }}
    />
    <path
      d="M655.17 758.96h736.77l-368.39-260.64-368.38 260.64zM375.4 583.32l-63.87-134.84 96.93-44.84 64.03 135.32-97.09 44.36z"
      className="Einzelklappe_Kipp_ohne_WLW_svg__cls-8"
    />
    <path
      d="m376 583.3-23.55-125.16 96.07-43.6 23.55 124.84L376 583.3zM1059.28 519.12l195.81 138.71-89.04-211.93"
      className="Einzelklappe_Kipp_ohne_WLW_svg__cls-8"
    />
    <path
      d="m230.08 408.48 199.89-91.15 167.12 354.01-199.11 91.33"
      className="Einzelklappe_Kipp_ohne_WLW_svg__cls-8"
    />
    <path
      d="m1058.21 520.65 198.97 141.62"
      style={{
        strokeWidth: '2.5px',
        strokeMiterlimit: 10,
        fill: 'none',
        stroke: '#1e1e1c',
      }}
    />
    <text
      className="Einzelklappe_Kipp_ohne_WLW_svg__cls-21"
      transform="translate(902.01 426.71)"
    >
      <tspan x={0} y={0}>
        {props.distanceFlapUpperEdgeToRidgeFA}
      </tspan>
    </text>
    <text
      style={{
        letterSpacing: '-.06em',
        fontSize: '27.5px',
        fontFamily: 'ArialMT,Arial',
        fill: '#1e1e1c',
      }}
      transform="translate(1042.09 627.28)"
    >
      <tspan x={0} y={0}>
        {props.distanceNRWGToRidgeFA}
      </tspan>
    </text>
    <text
      className="Einzelklappe_Kipp_ohne_WLW_svg__cls-21"
      transform="translate(1281.31 733.73)"
    >
      <tspan x={0} y={0}>
        {'\u03B1'}
      </tspan>
    </text>
    <text
      style={{
        fontSize: '27.5px',
        fontFamily: 'ArialMT,Arial',
        fill: '#1e1e1c',
      }}
      transform="rotate(-23.8 1658.998 -704.501)"
    >
      <tspan x={0} y={0}>
        {props.widthB}
      </tspan>
    </text>
    <text
      style={{
        fontSize: 30,
        fontFamily: 'ArialMT,Arial',
        fill: '#1e1e1c',
      }}
      transform="rotate(-23.8 1673.537 -753.03) scale(.58)"
    >
      <tspan x={0} y={0}>
        {props.frameFR}
      </tspan>
    </text>
    <text
      style={{
        fontSize: '27.5px',
        fontFamily: 'ArialMT,Arial',
        fill: '#1e1e1c',
      }}
      transform="rotate(64.08 -260.167 489.806)"
    >
      <tspan x={0} y={0}>
        {props.heightH}
      </tspan>
    </text>
    <text
      style={{
        fontSize: 30,
        fontFamily: 'ArialMT,Arial',
        fill: '#1e1e1c',
      }}
      transform="rotate(64.08 -278.082 500.673) scale(.58)"
    >
      <tspan x={0} y={0}>
        {props.frameFR}
      </tspan>
    </text>
    <text
      style={{
        fontFamily: 'ArialMT,Arial',
        fill: '#1e1e1c',
        fontSize: '27.5px',
        letterSpacing: '-.06em',
      }}
      transform="rotate(65.18 -125.82 453.151)"
    >
      <tspan x={0} y={0}>
        {props.distanceNRWGToRidgeFA}
      </tspan>
    </text>
    <text
      style={{
        fontSize: '27.5px',
        fontFamily: 'MyriadPro-Regular,&quot',
        fill: '#1e1e1c',
      }}
      transform="translate(312.23 690.74)"
    >
      <tspan x={0} y={0}>
        {'\u03B1'}
      </tspan>
    </text>
    <path
      d="M285.29 695.86a131.691 131.691 0 0 1 45.52-45.66"
      className="Einzelklappe_Kipp_ohne_WLW_svg__cls-11"
    />
    <path
      d="m288.83 695.56-7.8 8.07 2.72-10.89 5.08 2.82zM330.51 653.74l8.04-7.83-10.88 2.76 2.84 5.07z"
      className="Einzelklappe_Kipp_ohne_WLW_svg__cls-18"
    />
    <path
      d="M1261.36 749.34c1.63-21.03 8.23-40.67 18.63-57.75"
      className="Einzelklappe_Kipp_ohne_WLW_svg__cls-11"
    />
    <path
      d="m1264.39 747.51-3.42 10.68-2.38-10.96 5.8.28zM1281.37 694.86l3.48-10.67-8.35 7.5 4.87 3.17z"
      className="Einzelklappe_Kipp_ohne_WLW_svg__cls-18"
    />
    <path
      d="M1157.75 445.9h-2.5"
      className="Einzelklappe_Kipp_ohne_WLW_svg__cls-10"
    />
    <path
      d="M1150.33 445.9H916.9"
      style={{
        strokeDasharray: '0 0 4.91 4.91',
        strokeWidth: '.75px',
        strokeMiterlimit: 10,
        fill: 'none',
        stroke: '#1e1e1c',
      }}
    />
    <path
      d="M914.44 445.9h-2.5M1016.46 498.32h-2.5"
      className="Einzelklappe_Kipp_ohne_WLW_svg__cls-10"
    />
    <path
      d="M1008.72 498.32h-91.66"
      style={{
        strokeDasharray: '0 0 5.24 5.24',
        strokeWidth: '.75px',
        strokeMiterlimit: 10,
        fill: 'none',
        stroke: '#1e1e1c',
      }}
    />
    <path
      d="M914.44 498.32h-2.5"
      className="Einzelklappe_Kipp_ohne_WLW_svg__cls-10"
    />
    <path
      d="M928.07 537.99v-30.82"
      className="Einzelklappe_Kipp_ohne_WLW_svg__cls-11"
    />
    <path
      d="m930.98 509.16-2.91-10.84-2.91 10.84h5.82z"
      className="Einzelklappe_Kipp_ohne_WLW_svg__cls-18"
    />
    <path
      d="M928.07 437.04v-48.08"
      className="Einzelklappe_Kipp_ohne_WLW_svg__cls-11"
    />
    <path
      d="m930.98 435.06-2.91 10.84-2.91-10.84h5.82z"
      className="Einzelklappe_Kipp_ohne_WLW_svg__cls-18"
    />
    <path
      d="m1020.41 504.04-1.47 2.03"
      className="Einzelklappe_Kipp_ohne_WLW_svg__cls-10"
    />
    <path
      d="m1015.91 510.25-53 73.22"
      style={{
        strokeDasharray: '0 0 5.17 5.17',
        strokeWidth: '.75px',
        strokeMiterlimit: 10,
        fill: 'none',
        stroke: '#1e1e1c',
      }}
    />
    <path
      d="m961.39 585.56-1.47 2.03"
      className="Einzelklappe_Kipp_ohne_WLW_svg__cls-10"
    />
    <path
      d="m949.72 567 9.57 6.77"
      className="Einzelklappe_Kipp_ohne_WLW_svg__cls-11"
    />
    <path
      d="m955.99 575 10.53 3.89-7.17-8.64-3.36 4.75z"
      className="Einzelklappe_Kipp_ohne_WLW_svg__cls-18"
    />
    <path
      d="m1052.34 526.78-1.44 2.04"
      className="Einzelklappe_Kipp_ohne_WLW_svg__cls-10"
    />
    <path
      d="m1047.93 533-51.94 73.08"
      style={{
        strokeDasharray: '0 0 5.12 5.12',
        strokeWidth: '.75px',
        strokeMiterlimit: 10,
        fill: 'none',
        stroke: '#1e1e1c',
      }}
    />
    <path
      d="m994.51 608.17-1.45 2.04"
      className="Einzelklappe_Kipp_ohne_WLW_svg__cls-10"
    />
    <path
      d="m1005.78 606.78 33.01 23.36h40"
      className="Einzelklappe_Kipp_ohne_WLW_svg__cls-11"
    />
    <path
      d="m1005.72 610.3-7.17-8.63 10.53 3.89-3.36 4.74z"
      className="Einzelklappe_Kipp_ohne_WLW_svg__cls-18"
    />
    <path
      d="m390.69 597.78 81.35-36.96"
      className="Einzelklappe_Kipp_ohne_WLW_svg__cls-11"
    />
    <path
      d="m393.7 599.6-11.07 1.84 8.66-7.13 2.41 5.29zM471.44 564.28l8.67-7.12-11.07 1.83 2.4 5.29z"
      className="Einzelklappe_Kipp_ohne_WLW_svg__cls-18"
    />
    <path
      d="m376.69 586.12 1.07 2.26"
      className="Einzelklappe_Kipp_ohne_WLW_svg__cls-10"
    />
    <path
      d="m379.99 593.1 7.82 16.51"
      style={{
        strokeDasharray: '0 0 5.22 5.22',
        strokeWidth: '.75px',
        strokeMiterlimit: 10,
        fill: 'none',
        stroke: '#1e1e1c',
      }}
    />
    <path
      d="m388.93 611.97 1.07 2.26"
      className="Einzelklappe_Kipp_ohne_WLW_svg__cls-10"
    />
    <path
      d="m297.78 463.09 56.28 118.87"
      className="Einzelklappe_Kipp_ohne_WLW_svg__cls-11"
    />
    <path
      d="m296 466.12-2.01-11.04 7.26 8.56-5.25 2.48zM350.58 581.42l7.27 8.55-2.01-11.04-5.26 2.49z"
      className="Einzelklappe_Kipp_ohne_WLW_svg__cls-18"
    />
    <path
      d="m281.2 462.25 2.28-1.03"
      className="Einzelklappe_Kipp_ohne_WLW_svg__cls-10"
    />
    <path
      d="m287.95 459.19 15.65-7.11"
      style={{
        strokeDasharray: '0 0 4.91 4.91',
        strokeWidth: '.75px',
        strokeMiterlimit: 10,
        fill: 'none',
        stroke: '#1e1e1c',
      }}
    />
    <path
      d="m305.84 451.06 2.27-1.03M345.06 597.14l2.28-1.03"
      className="Einzelklappe_Kipp_ohne_WLW_svg__cls-10"
    />
    <path
      d="m351.95 594.02 16.14-7.34"
      style={{
        strokeWidth: '.75px',
        strokeMiterlimit: 10,
        fill: 'none',
        stroke: '#1e1e1c',
        strokeDasharray: '0 0 5.06 5.06',
      }}
    />
    <path
      d="m370.39 585.64 2.28-1.04M474.37 542.24l1.07 2.26"
      className="Einzelklappe_Kipp_ohne_WLW_svg__cls-10"
    />
    <path
      d="m477.63 549.14 7.68 16.22"
      style={{
        strokeDasharray: '0 0 5.13 5.13',
        strokeWidth: '.75px',
        strokeMiterlimit: 10,
        fill: 'none',
        stroke: '#1e1e1c',
      }}
    />
    <path
      d="m486.41 567.68 1.07 2.26"
      className="Einzelklappe_Kipp_ohne_WLW_svg__cls-10"
    />
    <path
      d="m331.07 376.33 18.98 40.08"
      className="Einzelklappe_Kipp_ohne_WLW_svg__cls-11"
    />
    <path
      d="m329.3 379.37-2.02-11.04 7.27 8.55-5.25 2.49zM346.57 415.86l7.27 8.56-2.02-11.05-5.25 2.49z"
      className="Einzelklappe_Kipp_ohne_WLW_svg__cls-18"
    />
  </svg>
);
export default SvgEinzelklappeKippOhneWlw;
