import { AnyAction, Reducer } from 'redux';

export enum Locale {
  DE = 'de',
  EN = 'en',
  PL = 'pl',
  CS = 'cs',
  DA = 'da',
  ES = 'es',
  ET = 'et',
  FR = 'fr',
  IT = 'it',
  NL = 'nl',
  PT = 'pt',
  RU = 'ru',
  SV = 'sv',
  TR = 'tr',
  LT = 'lt',
  LV = 'lv',
}

export enum ProjectScopes {
  USER = 'USER',
  COMPANY = 'COMPANY',
  ALL = 'ALL',
}

export enum Pages {
  CALCULATION,
  SELECTED_PRODUCTS,
  SELECTED_PRODUCTS_NRWG,
  CONSOLE_CALCULATION,
  PROJECTS_MANAGER,
  TEMPLATES_MANAGER,
  DOCUMENTS_AREA,
}

export interface Calculating {
  [index: string]: boolean;
}

export interface Company {
  cprNumberRoof: string | undefined;
  cprNumberFacade: string | undefined;
  cprNumberFacadeSteel: string | undefined;
  id: number;
  name: string;
  country: string;
  zip: string;
}

export interface LoggedInUser {
  id: number;
  firstName: string;
  lastName: string;
  admin: boolean;
  userAdmin: boolean;
  companyHasCPRNumberRoof: boolean;
  companyHasCPRNumberFacade: boolean;
  company: Company;
  partnerOrAdmin: boolean;
}

export interface AuthenticationState {
  locale: Locale;
  currentUser?: LoggedInUser;
  loginError: boolean;
  userIsAuthenticated: boolean;
  userIsAuthorized: boolean;
}

export const INITIAL_STATE: AuthenticationState = {
  loginError: false,
  currentUser: undefined,
  locale: Locale.DE,
  userIsAuthenticated: false,
  userIsAuthorized: false,
};

const authenticationReducer: Reducer = (
  state: AuthenticationState,
  action: AnyAction,
) => {
  if (state === undefined) {
    return {
      ...INITIAL_STATE,
      alpha: !!window.location.search.match(/alpha/),
      //following is for admin development
      // alpha: true || !!window.location.search.match(/alpha/),
    };
  }

  switch (action.type) {
    case 'SET_USER_IS_AUTHENTICATED': {
      return { ...state, userIsAuthenticated: action.userIsAuthenticated };
    }

    case 'SET_USER_IS_AUTHORIZED': {
      return { ...state, userIsAuthorized: action.userIsAuthorized };
    }

    case 'UPDATE_LOCALE':
      return {
        ...state,
        locale: action.locale,
      };
    case 'STORE_USER_ACTION': {
      return {
        ...state,
        currentUser: action.user,
      };
    }

    default:
      return state;
  }
};

export default authenticationReducer;
