import { LoggedInUser } from '../redux/authenticationReducer';

export enum downloadEventLabel {
  specification = 'specification',
  dop = 'dop',
  auditLabel = 'auditLabel',
  auditSpecification = 'auditSpecification',
  zip = 'zip',
}

export function triggerAnalyticsEvent(
  eventName: string,
  eventCategory: string,
  eventLabel?: string,
): void {
  if (!window.gtag) {
    return;
  }

  gtagWrapper('event', eventName, {
    event_category: eventCategory,
    event_label: eventLabel,
  });
}

export function setUserTrackingData(user: LoggedInUser): void {
  setUserId(user.id);
  setCompanyId(user.company.id);
  setCompanyName(user.company.name);
}

function setUserId(id: number): void {
  gtagWrapper('set', { USER_ID: id.toString() });
}

function setCompanyId(id: number): void {
  gtagWrapper('set', { COMPANY_ID: id.toString() });
}

function setCompanyName(name: string): void {
  gtagWrapper('set', { COMPANY_NAME: name });
}

function gtagWrapper(...args: any[]): void {
  // @ts-ignore
  if (window.gtag) {
    // @ts-ignore
    gtag(...args);
  }
}

export function setLanguage(language: string): void {
  // @ts-ignore
  if (window.gtag) {
    gtagWrapper('set', { LANGUAGE: language });
  } else {
    setTimeout(() => setLanguage(language), 500);
  }
}
