import React, { FC, useEffect, useState } from 'react';
import FormLayout from '../../elements/FormLayout';
import InputField, { InputFieldNumber } from '../../elements/InputField';
import TextArea from '../../elements/TextArea';
import { Switch } from '../../elements/Switch';
import './SeriesDataView.scss';
import { useDispatch, useSelector } from 'react-redux';
import Dialog from '../../components/Dialog';
import Table from '../components/Table';
import TableHeader from '../elements/TableHeader';
import TableRow from '../elements/TableRow';
import { AdminState, AdminThunkDispatch } from '../../redux/admin/adminStore';
import {
  BasicProfile,
  ExchangeProfile,
  FrameProfileFacade,
  SashProfileFacade,
  Series,
  System,
} from '../../redux/admin/adminFacadeReducer';
import {
  changeSeries,
  createSeries,
  updateBasicProfile,
  updateExchangeProfile,
  updateFrameProfileFacade,
  updateSashProfileFacade,
} from '../../redux/admin/adminFacadeActions';
import {
  MenuPlacement,
  SelectField,
  SelectOption,
} from '../../elements/SelectField';
import { Material, MATERIAL_OPTIONS, UiConstants } from '../constants';
import SearchField from '../../elements/SearchField';
import Pagination from '../components/Pagination';
import { RangeOfApplication } from '../../redux/constants';
import {
  conditionalValue,
  fieldsFilled,
  findById,
  searchByMultipleSubstrings,
  trueValue,
} from './helpers';
import { useSeries } from '../../hooks/selectorHooks';
import {
  FrameProfileRoof,
  SashProfileRoof,
} from '../../redux/admin/adminRoofReducer';
import {
  updateFrameProfileRoof,
  updateSashProfileRoof,
} from '../../redux/admin/adminRoofActions';
import AdminRadioButton from '../../elements/RadioButton';
import { AnyAction } from 'redux';
import { useAdminSearch, useNumberState } from '../hooks';
import FormLayoutSubgroupTitle from '../../elements/FormLayoutSubgroupTitle';

interface AdminSeriesDialogProps {
  dialogIsShown: boolean;
  setDialogIsShown: (b: boolean) => void;
  selectedSeries: Series | undefined;
  rangeOfApplication: RangeOfApplication;
}

const AdminSeriesDialog: FC<React.PropsWithChildren<AdminSeriesDialogProps>> = (
  props: AdminSeriesDialogProps,
) => {
  const dispatch: AdminThunkDispatch<AnyAction> = useDispatch();

  const allSystems = useSelector<AdminState, System[]>(
    state => state.adminFacade.systems,
  );

  const [name, setName] = useState(props.selectedSeries?.name || '');
  const [info, setInfo] = useState(props.selectedSeries?.info || '');
  const [isPreview, setIsPreview] = useState(
    props.selectedSeries?.preview || false,
  );
  const [isActive, setIsActive] = useState(
    props.selectedSeries?.active || false,
  );
  const [verriegelungMoeglich, setVerriegelungMoeglich] = useState(
    props.selectedSeries?.verriegelungMoeglich || false,
  );
  const [shortCode, setShortCode] = useState(
    props.selectedSeries?.shortCode || '',
  );
  const [system, setSystem] = useState(
    props.selectedSeries?.system || undefined,
  );

  const [material, setMaterial] = useState(
    props.selectedSeries
      ? MATERIAL_OPTIONS.find(
          material => material.value === props.selectedSeries?.material,
        )?.value
      : undefined,
  );
  const [bautiefe, setBautiefe] = useNumberState(
    props.selectedSeries?.bautiefe,
  );
  const [laengeEckverbinder, setLaengeEckverbinder] = useNumberState(
    props.selectedSeries?.laengeEckverbinder,
  );
  const [b2Auswaerts, setb2Auswaerts] = useNumberState(
    props.selectedSeries?.b2Auswaerts,
  );
  const [b2Einwaerts, setb2Einwaerts] = useNumberState(
    props.selectedSeries?.b2Einwaerts,
  );
  const [lichteabzugsmassAuswaerts, setLichteabzugsmassAuswaerts] =
    useNumberState(props.selectedSeries?.lichteabzugsmassAuswaerts);
  const [lichteabzugsmassEinwaerts, setLichteabzugsmassEinwaerts] =
    useNumberState(props.selectedSeries?.lichteabzugsmassEinwaerts);
  const [fluegelaufschlag, setFluegelaufschlag] = useNumberState(
    props.selectedSeries?.fluegelaufschlag,
  );
  const [profileWeightPerMeter, setProfileWeightPerMeter] = useNumberState(
    props.selectedSeries?.profileWeightPerMeter,
  );
  const [profileHeight, setProfileHeight] = useNumberState(
    props.selectedSeries?.profileHeight,
  );
  const [asAbstandAufgesetzteBaender, setAsAbstandAufgesetzteBaender] =
    useNumberState(props.selectedSeries?.asAbstandAufgesetzteBaender);

  const [isOpeningDirectionInwards, setOpeningDirectionInwards] =
    useState<boolean>(
      (props.selectedSeries?.oeffnungsrichtungEinwaerts &&
        !props.selectedSeries?.oeffnungsrichtungAuswaerts) ||
        false,
    );
  const [isOpeningDirectionOutwards, setOpeningDirectionOutwards] =
    useState<boolean>(
      (props.selectedSeries?.oeffnungsrichtungAuswaerts &&
        !props.selectedSeries?.oeffnungsrichtungEinwaerts) ||
        false,
    );
  const [
    isOpeningDirectionOutwardsAndInwards,
    setOpeningDirectionOutwardsAndInwards,
  ] = useState<boolean>(
    (props.selectedSeries?.oeffnungsrichtungAuswaerts &&
      props.selectedSeries?.oeffnungsrichtungEinwaerts) ||
      false,
  );

  useEffect(() => {
    resetInputFields();
  }, [props.selectedSeries]);

  function resetInputFields(): void {
    setName(props.selectedSeries?.name || '');
    setInfo(props.selectedSeries?.info || '');
    setIsActive(props.selectedSeries?.active || false);
    setIsPreview(props.selectedSeries?.preview || false);
    setVerriegelungMoeglich(
      props.selectedSeries?.verriegelungMoeglich || false,
    );
    setShortCode(props.selectedSeries?.shortCode || '');
    setSystem(props.selectedSeries?.system);
    setMaterial(props.selectedSeries?.material);
    setBautiefe(props.selectedSeries?.bautiefe);
    setLaengeEckverbinder(props.selectedSeries?.laengeEckverbinder);
    setb2Auswaerts(props.selectedSeries?.b2Auswaerts);
    setb2Einwaerts(props.selectedSeries?.b2Einwaerts);
    setLichteabzugsmassAuswaerts(
      props.selectedSeries?.lichteabzugsmassAuswaerts,
    );
    setLichteabzugsmassEinwaerts(
      props.selectedSeries?.lichteabzugsmassEinwaerts,
    );
    setFluegelaufschlag(props.selectedSeries?.fluegelaufschlag);
    setProfileHeight(props.selectedSeries?.profileHeight);
    setProfileWeightPerMeter(props.selectedSeries?.profileWeightPerMeter);
    setAsAbstandAufgesetzteBaender(
      props.selectedSeries?.asAbstandAufgesetzteBaender,
    );
    setOpeningDirectionInwards(
      (props.selectedSeries?.oeffnungsrichtungEinwaerts &&
        !props.selectedSeries?.oeffnungsrichtungAuswaerts) ||
        false,
    );
    setOpeningDirectionOutwards(
      (props.selectedSeries?.oeffnungsrichtungAuswaerts &&
        !props.selectedSeries?.oeffnungsrichtungEinwaerts) ||
        false,
    );
    setOpeningDirectionOutwardsAndInwards(
      (props.selectedSeries?.oeffnungsrichtungAuswaerts &&
        props.selectedSeries?.oeffnungsrichtungEinwaerts) ||
        false,
    );
  }

  function mandatoryFieldsFilled(): boolean {
    const inwards =
      isOpeningDirectionOutwardsAndInwards || isOpeningDirectionInwards;
    const outwards =
      isOpeningDirectionOutwardsAndInwards || isOpeningDirectionOutwards;
    return fieldsFilled(
      name,
      system,
      shortCode,
      bautiefe,
      laengeEckverbinder,
      conditionalValue(inwards, lichteabzugsmassEinwaerts),
      conditionalValue(outwards, lichteabzugsmassAuswaerts),
      fluegelaufschlag,
      conditionalValue(material === Material.STAHL, profileHeight),
      conditionalValue(material === Material.STAHL, profileWeightPerMeter),
      material,
      trueValue(
        isOpeningDirectionOutwards ||
          isOpeningDirectionInwards ||
          isOpeningDirectionOutwardsAndInwards,
      ),
      conditionalValue(
        props.rangeOfApplication === RangeOfApplication.ROOF && inwards,
        b2Einwaerts,
      ),
      conditionalValue(
        props.rangeOfApplication === RangeOfApplication.ROOF && outwards,
        b2Auswaerts,
      ),
      conditionalValue(
        props.rangeOfApplication === RangeOfApplication.ROOF,
        asAbstandAufgesetzteBaender,
      ),
    );
  }

  function setSystemAndEditActiveStatus(system: System): void {
    if (!system.active) {
      setIsActive(false);
    }
    setSystem(system);
  }

  function editSeries(): void {
    if (!mandatoryFieldsFilled()) {
      return;
    }

    dispatch(
      changeSeries(
        {
          ...(props.selectedSeries as Series),
          name: name!,
          system: system!,
          shortCode: shortCode!,
          bautiefe: bautiefe!,
          laengeEckverbinder: laengeEckverbinder!,
          active: isActive,
          preview: isPreview,
          verriegelungMoeglich: verriegelungMoeglich,
          asAbstandAufgesetzteBaender: asAbstandAufgesetzteBaender!,
          b2Auswaerts: isOutwardsOrBoth() ? b2Auswaerts! : undefined,
          b2Einwaerts: isInwardsOrBoth() ? b2Einwaerts! : undefined,
          lichteabzugsmassAuswaerts: isOutwardsOrBoth()
            ? lichteabzugsmassAuswaerts!
            : undefined,
          lichteabzugsmassEinwaerts: isInwardsOrBoth()
            ? lichteabzugsmassEinwaerts!
            : undefined,
          fluegelaufschlag: fluegelaufschlag!,
          profileHeight: profileHeight!,
          profileWeightPerMeter: profileWeightPerMeter!,
          material: material!,
          info: info || '',
          oeffnungsrichtungEinwaerts: isOpeningDirectionOutwardsAndInwards
            ? isOpeningDirectionOutwardsAndInwards
            : isOpeningDirectionInwards,
          oeffnungsrichtungAuswaerts: isOpeningDirectionOutwardsAndInwards
            ? isOpeningDirectionOutwardsAndInwards
            : isOpeningDirectionOutwards,
        },
        props.rangeOfApplication,
      ),
    );
  }

  function generateSeries(): void {
    dispatch(
      createSeries({
        name: name!,
        system: system!,
        shortCode: shortCode!,
        bautiefe: bautiefe!,
        laengeEckverbinder: laengeEckverbinder!,
        active: isActive,
        preview: isPreview,
        verriegelungMoeglich: verriegelungMoeglich,
        asAbstandAufgesetzteBaender: asAbstandAufgesetzteBaender!,
        b2Auswaerts: isOutwardsOrBoth() ? b2Auswaerts! : undefined,
        b2Einwaerts: isInwardsOrBoth() ? b2Einwaerts! : undefined,
        lichteabzugsmassAuswaerts: isOutwardsOrBoth()
          ? lichteabzugsmassAuswaerts!
          : undefined,
        lichteabzugsmassEinwaerts: isInwardsOrBoth()
          ? lichteabzugsmassEinwaerts!
          : undefined,
        fluegelaufschlag: fluegelaufschlag!,
        profileHeight: profileHeight!,
        profileWeightPerMeter: profileWeightPerMeter!,
        rangeOfApplication: props.rangeOfApplication,
        material: material!,
        info: info || '',
        oeffnungsrichtungEinwaerts: isOpeningDirectionOutwardsAndInwards
          ? isOpeningDirectionOutwardsAndInwards
          : isOpeningDirectionInwards,
        oeffnungsrichtungAuswaerts: isOpeningDirectionOutwardsAndInwards
          ? isOpeningDirectionOutwardsAndInwards
          : isOpeningDirectionOutwards,
      }),
    );
  }

  function materialSelectOption(
    value: string,
  ): SelectOption<string> | undefined {
    return MATERIAL_OPTIONS.find(entry => entry.value === value);
  }

  function resetOpeningDirection(): void {
    setOpeningDirectionOutwardsAndInwards(false);
    setOpeningDirectionOutwards(false);
    setOpeningDirectionInwards(false);
  }

  function isOutwardsOrBoth(): boolean {
    return isOpeningDirectionOutwards || isOpeningDirectionOutwardsAndInwards;
  }

  function isInwardsOrBoth(): boolean {
    return isOpeningDirectionInwards || isOpeningDirectionOutwardsAndInwards;
  }

  function getActivationNotPossibleMessage(): string {
    function getSuffix(): string {
      if (system) {
        return 'System ' + system.name + ' muss aktiv sein.';
      } else {
        return 'Vorher System auswählen.';
      }
    }

    return `Aktivierung nicht möglich. ${getSuffix()}`;
  }

  return (
    <Dialog
      cancelAction={resetInputFields}
      setDialogIsShown={props.setDialogIsShown}
      dialogIsShown={props.dialogIsShown}
      headingText={
        props.selectedSeries === undefined
          ? 'Neue Serie anlegen'
          : `Serie ${props.selectedSeries.name} bearbeiten`
      }
      componentClass="full-view-dialog"
      key={props.selectedSeries?.id}
      footerProps={{
        notTranslated: true,
        cancelAction: () => {
          resetInputFields();
          props.setDialogIsShown(false);
        },
        saveAction: mandatoryFieldsFilled()
          ? () => {
              props.selectedSeries ? editSeries() : generateSeries();
              props.setDialogIsShown(false);
            }
          : undefined,
        primaryActionLabelText:
          props.selectedSeries === undefined ? 'Anlegen' : 'Speichern',
      }}
    >
      <FormLayout additionalClass="series-data-view-dialog">
        <InputField
          label="Name *"
          placeholder="Name der Serie"
          value={name}
          additionalClass="series-data-view-dialog__name"
          onChange={setName}
        />
        <TextArea
          label="Information"
          additionalClass="series-data-view-dialog__info"
          placeholder="Informationen zur Serie"
          value={info}
          onChange={setInfo}
        />

        <SelectField
          additionalClass="series-data-view-dialog__system"
          label="System *"
          value={
            system
              ? {
                  value: system.id.toString(),
                  label: system.name,
                }
              : undefined
          }
          action={newValue => {
            setSystemAndEditActiveStatus(findById(+newValue.value, allSystems));
          }}
          options={allSystems.map(system => ({
            label: system.name,
            value: system.id.toString(),
          }))}
          name="project-country"
          searchable={true}
          placeholder="z.B. Schüco"
          menuPlacement={MenuPlacement.BOTTOM}
        />
        <SelectField
          additionalClass="series-data-view-dialog__material"
          key={props.selectedSeries?.material}
          label="Material *"
          value={material ? materialSelectOption(material) : undefined}
          action={newValue => {
            setMaterial(newValue.value);
          }}
          options={MATERIAL_OPTIONS}
          name="series-material"
          searchable={true}
          placeholder="z.B. Aluminium"
          menuPlacement={MenuPlacement.BOTTOM}
        />

        <InputField
          additionalClass="series-data-view-dialog__short-name"
          label="Kürzel *"
          placeholder="z.B. SC für Schüco"
          value={shortCode}
          onChange={setShortCode}
        />
        <InputFieldNumber
          additionalClass="series-data-view-dialog__building-depth"
          label="Bautiefe (mm) *"
          placeholder="z.B. 42"
          value={bautiefe}
          onChange={setBautiefe}
        />
        <InputFieldNumber
          additionalClass="series-data-view-dialog__edge-length-connection"
          label="Länge der Eckverbindung (mm) *"
          placeholder="z.B. 42"
          value={laengeEckverbinder}
          onChange={setLaengeEckverbinder}
        />

        <div className="series-data-view-dialog__opening-type">
          <FormLayoutSubgroupTitle>Öffnungsrichtung</FormLayoutSubgroupTitle>
          <AdminRadioButton
            checked={isOpeningDirectionInwards}
            name="einwärts"
            onChange={() => {
              resetOpeningDirection();
              setOpeningDirectionInwards(true);
            }}
          />
          <AdminRadioButton
            checked={isOpeningDirectionOutwards}
            name="auswärts"
            onChange={() => {
              resetOpeningDirection();
              setOpeningDirectionOutwards(true);
            }}
          />
          <AdminRadioButton
            checked={isOpeningDirectionOutwardsAndInwards}
            name="einwärts und auswärts"
            onChange={() => {
              resetOpeningDirection();
              setOpeningDirectionOutwardsAndInwards(true);
            }}
          />
        </div>

        <div className="form-layout__sub-group form-layout__sub-group--2 series-data-view-dialog__distance-to-hinge">
          <FormLayoutSubgroupTitle>
            Abstand Rahmenaußenkante zum Drehpunkt, b2 (mm)
          </FormLayoutSubgroupTitle>
          {isOutwardsOrBoth() && (
            <InputFieldNumber
              label={`auswärts${
                props.rangeOfApplication === RangeOfApplication.ROOF ? ' *' : ''
              }`}
              placeholder="z.B. 42"
              value={b2Auswaerts}
              onChange={setb2Auswaerts}
            />
          )}
          {isInwardsOrBoth() && (
            <InputFieldNumber
              label={`einwärts${
                props.rangeOfApplication === RangeOfApplication.ROOF ? ' *' : ''
              }`}
              placeholder="z.B. 42"
              value={b2Einwaerts}
              onChange={setb2Einwaerts}
            />
          )}
        </div>
        <div className="form-layout__sub-group form-layout__sub-group--2 series-data-view-dialog__exhaust-dimension">
          <FormLayoutSubgroupTitle>
            Lichteabzugsmaß (mm)
          </FormLayoutSubgroupTitle>
          {isOutwardsOrBoth() && (
            <InputFieldNumber
              label="auswärts *"
              placeholder="z.B. 42"
              value={lichteabzugsmassAuswaerts}
              onChange={setLichteabzugsmassAuswaerts}
            />
          )}
          {isInwardsOrBoth() && (
            <InputFieldNumber
              label="einwärts *"
              placeholder="z.B. 42"
              value={lichteabzugsmassEinwaerts}
              onChange={setLichteabzugsmassEinwaerts}
            />
          )}
        </div>

        <div className="form-layout__sub-group series-data-view-dialog__as-distance">
          <div className="form-layout__sub-group-title">
            Abstand Flügelkante zu Drehpunkt (As) (mm)
          </div>
          <InputFieldNumber
            label="aufgesetzte Bänder"
            placeholder="z.B. 42"
            value={asAbstandAufgesetzteBaender}
            onChange={setAsAbstandAufgesetzteBaender}
          />
        </div>
        <div className="form-layout__sub-group form-layout__sub-group--3 series-data-view-dialog__sash-addition">
          <FormLayoutSubgroupTitle>Weitere</FormLayoutSubgroupTitle>
          <InputFieldNumber
            additionalClass=""
            label="Flügelaufschlag (mm) *"
            placeholder="z.B. 42,2"
            value={fluegelaufschlag}
            onChange={setFluegelaufschlag}
          />
        </div>

        {material === Material.STAHL && (
          <div className="form-layout__sub-group form-layout__sub-group--2 series-data-view-dialog__steel-weight-calculation-values">
            <FormLayoutSubgroupTitle>
              Für Fluegelgewichtsberechnung
            </FormLayoutSubgroupTitle>
            <InputFieldNumber
              label="Profilgewicht pro laufendem Meter *"
              placeholder="z.B. 42"
              value={profileWeightPerMeter}
              onChange={setProfileWeightPerMeter}
            />
            <InputFieldNumber
              label="Flügelprofilhöhe (fh) *"
              placeholder="z.B. 42"
              value={profileHeight}
              onChange={setProfileHeight}
            />
          </div>
        )}

        <div className="series-data-view-dialog__active-switch">
          <Switch
            labelText={
              system?.active ? 'aktiv' : getActivationNotPossibleMessage()
            }
            turnedOn={isActive}
            onChange={setIsActive}
            disabled={!system?.active}
          />
        </div>
        <div className="series-data-view-dialog__preview-switch">
          <Switch
            labelText="Preview"
            turnedOn={isPreview}
            onChange={setIsPreview}
          />
        </div>

        <div className="series-data-view-dialog__locking-available">
          <Switch
            labelText={'Verriegelung möglich'}
            turnedOn={verriegelungMoeglich}
            onChange={setVerriegelungMoeglich}
          />
        </div>
      </FormLayout>
    </Dialog>
  );
};

const SeriesDataView: FC<
  React.PropsWithChildren<{
    rangeOfApplication: RangeOfApplication;
  }>
> = props => {
  const allSeries = useSeries(props.rangeOfApplication);
  const [dialogIsShown, setDialogIsShown] = useState(false);
  const dispatch: AdminThunkDispatch<AnyAction> = useDispatch();
  const [selectedSeriesID, setSelectedSeriesID] = useState<undefined | number>(
    undefined,
  );
  const [indexOfFirstPageElement, setIndexOfFirstPageElement] = useState(0);
  const [page, setPage] = useState(1);
  const [searchString, setSearchString] = useState('');
  const [filterActive, setFilterActive, searchResult] = useAdminSearch(
    allSeries,
    searchString,
    ['name', 'shortCode'],
    (s: Series) => [s.system.name],
  );

  const selectedSeries =
    allSeries.find(series => series.id === selectedSeriesID) || undefined;

  const allFrameProfilesFacade = useSelector<AdminState, FrameProfileFacade[]>(
    state => state.adminFacade.frameProfiles,
  );
  const allSashProfilesFacade = useSelector<AdminState, SashProfileFacade[]>(
    state => state.adminFacade.sashProfiles,
  );
  const allFrameProfilesRoof = useSelector<AdminState, FrameProfileRoof[]>(
    state => state.adminRoof.frameProfiles,
  );
  const allSashProfilesRoof = useSelector<AdminState, SashProfileRoof[]>(
    state => state.adminRoof.sashProfiles,
  );
  const allExchangeProfiles = useSelector<AdminState, ExchangeProfile[]>(
    state => state.adminFacade.exchangeProfiles,
  );
  const allBasicProfiles = useSelector<AdminState, BasicProfile[]>(
    state => state.adminFacade.basicProfiles,
  );

  function updateFacadeProfiles(series: Series): void {
    allFrameProfilesFacade.forEach(frameProfileFacade => {
      if (frameProfileFacade.seriesId === series.id) {
        dispatch(
          updateFrameProfileFacade({ ...frameProfileFacade, active: false }),
        );
      }
    });
    allSashProfilesFacade.forEach(sashProfileFacade => {
      if (sashProfileFacade.seriesId === series.id) {
        dispatch(
          updateSashProfileFacade({ ...sashProfileFacade, active: false }),
        );
      }
    });
  }

  function updateRoofProfiles(series: Series): void {
    allFrameProfilesRoof.forEach(frameProfileRoof => {
      if (frameProfileRoof.seriesId === series.id) {
        dispatch(
          updateFrameProfileRoof({ ...frameProfileRoof, active: false }),
        );
      }
    });
    allSashProfilesRoof.forEach(sashProfileRoof => {
      if (sashProfileRoof.seriesId === series.id) {
        dispatch(updateSashProfileRoof({ ...sashProfileRoof, active: false }));
      }
    });
  }

  function switchActiveAction(series: Series): void {
    if (series.active) {
      alert('Alle Profile der Serie ' + series.name + ' wurden deaktiviert.');
    }
    if (props.rangeOfApplication === RangeOfApplication.FACADE) {
      updateFacadeProfiles(series);
    }
    if (props.rangeOfApplication === RangeOfApplication.ROOF) {
      updateRoofProfiles(series);
    }

    allBasicProfiles.forEach(basicProfile => {
      if (basicProfile.seriesId === series.id) {
        dispatch(updateBasicProfile({ ...basicProfile, active: false }));
      }
    });
    allExchangeProfiles.forEach(exchangeProfile => {
      if (exchangeProfile.seriesId === series.id) {
        dispatch(updateExchangeProfile({ ...exchangeProfile, active: false }));
      }
    });

    dispatch(
      changeSeries(
        { ...series, active: !series.active },
        props.rangeOfApplication,
      ),
    );
  }

  function enableEditSystem(s: Series): void {
    setDialogIsShown(true);
    setSelectedSeriesID(s.id);
  }

  function triggerCreationMode(): void {
    setDialogIsShown(true);
    setSelectedSeriesID(undefined);
  }

  function getCurrentTableContent(): Series[] {
    return searchResult.slice(
      indexOfFirstPageElement,
      indexOfFirstPageElement + 20,
    );
  }

  return (
    <div>
      <AdminSeriesDialog
        dialogIsShown={dialogIsShown}
        setDialogIsShown={setDialogIsShown}
        selectedSeries={selectedSeries}
        rangeOfApplication={props.rangeOfApplication}
      />
      <div className="sub-header">
        <div className="sub-header__title"> Serien</div>
        <SearchField
          setSearchString={setSearchString}
          searchString={searchString}
          placeholderText={'Serie suchen...'}
          small={true}
          setFilterActive={setFilterActive}
          filterActive={filterActive}
        />
        <button
          className="sub-header__button sub-header__button--add"
          onClick={() => triggerCreationMode()}
        >
          {UiConstants.NEW_ENTRY}
        </button>
      </div>
      <Table subNavigation={false}>
        <TableHeader>
          <th>Name</th>
          <th>Kürzel</th>
          <th>Info</th>
          <th>Bautiefe</th>
          <th>System</th>
          <th>Aktiv</th>
          <th>Aktion</th>
        </TableHeader>
        <tbody>
          {getCurrentTableContent().map(series => (
            <TableRow key={series.name}>
              <td>{series.name}</td>
              <td>{series.shortCode}</td>
              <td>{series.info}</td>
              <td>{series.bautiefe}</td>
              <td>{series.system.name}</td>
              <td>
                <Switch
                  key={series.id}
                  turnedOn={series.active}
                  onChange={(b: boolean) => switchActiveAction(series)}
                />
              </td>
              <td>
                <button
                  onClick={() => {
                    enableEditSystem(series);
                  }}
                >
                  {UiConstants.EDIT}
                </button>
              </td>
            </TableRow>
          ))}
        </tbody>
      </Table>
      <Pagination
        searchString={searchString}
        numberOfPages={searchResult.length}
        page={page}
        setPage={setPage}
        indexOfFirstPageElement={indexOfFirstPageElement}
        setIndexOfFirstPageElement={setIndexOfFirstPageElement}
      />
    </div>
  );
};

export default SeriesDataView;
