import React, { FC, useState } from 'react';
import './ParametersColumn.scss';
import ParameterSection, {
  ParameterConfiguration,
  ParameterSectionProps,
} from './ParameterSection';
import {
  changeCalculationParameter,
  updateAngleAndRecalculate,
  updateStore,
  updateStrokeAndRecalculate,
} from '../../redux/parametersActions';
import { IState } from '../../redux/store';
import { isDoubleFlapApplication, ValueKey } from '../../redux/valueKey';
import { useSelectedWindow } from '../../hooks/selectorHooks';
import { Window } from '../../redux/projectsReducer';
import classNames from 'classnames';
import NRWGDefinitionSection from './NRWGDefinitionSection';
import { Parameters } from './ParameterValue';
import {
  Application,
  AssemblyType,
  DrivePosition,
  DriveType,
  FillType,
  GeometricArea,
  LockingDrive,
  OpeningDirection,
  OpeningSide,
  OpeningType,
  RangeOfApplication,
  RevealDepth,
  RevealStructure,
  TabOptions,
  Voltage,
} from '../../redux/constants';
import {
  calculateMaxStroke,
  MINIMUM_DISTANCE_TO_HINGE_SIDE_HUNG_SIDE_INSTALLATION,
} from '../../redux/parametersReducer';
import { ThunkAction } from 'redux-thunk';
import _ from 'lodash';
import { Ratio } from './EditBoxes/WindowAreaBox';
import WindowAreaHelp from './EditBoxes/WindowAreaHelp';
import { Locale } from '../../redux/uiStateReducer';
import { getPercent } from '../../calculations/distance_calculations';
import { getDriveTypeNames } from '../../redux/staticDataReducer';

export enum SectionTitle {
  PROFILE_COMBINATION = 'PROFILE_COMBINATION',
  PROFILE_DATA = 'PROFILE_DATA',
}

export type ParameterBoxRange = [number, number] | [];

export type Options = {
  name: string;
  range?: (state: IState) => ParameterBoxRange;
  valueKey?: ValueKey;
  value?: TabOptions;
  onChangeDispatch?: (
    newValue: number | undefined,
    selectedWindow: Window | undefined,
  ) => ThunkAction<any, any, any, any>;
  unit?: string;
}[];

// export const RackPinion""= [
//   "ZA",
//   "DXD",
//   "SXD",
// ];
//
// export const Chain""= [
//   "KA",
//   "CDC",
//   "CDCTW",
//   "SHD",
//   "KATW",
//   "CDP",
//   "CDPTW",
//   "VCD",
//   "CDM",
// ];

export enum Hints {
  HINT_RECOMMENDATION_HEIGHT = 'INSTALLATION_RECOMMENDATION_BOTTOM_TOP_HUNG',
  HINT_RECOMMENDATION_WIDTH = 'INSTALLATION_RECOMMENDATION_SIDE_HUNG',
  HINT_RECOMMENDATION_SIDE_HUNG_SIDE_INSTALLATION = 'INSTALLATION_RECOMMENDATION_SIDE_HUNG_SIDE_INSTALLATION',
}

export enum EditTypes {
  TYPE_PANEL = 'panel',
  TYPE_TAB_BAR = 'tabBar',
  TYPE_TAB_BAR_SINGLE_INPUT = 'tabBarSingleInput',
  TYPE_RADIO = 'radio',
  TYPE_SELECT = 'select',
  TYPE_BUTTON = 'button',
  TYPE_NONE = 'none',
  TYPE_TAB_BAR_WITHOUT_VALUE_EDIT = 'TYPE_TAB_BAR_WITHOUT_VALUE_EDIT',
  TYPE_DIALOG = 'dialog',
  TYPE_WINDOW_AREA = 'windowAreaBox',
  TYPE_SWITCH = 'switch',
  TYPE_REVEAL_MEASURES = 'typeRevealMeasures',
}

export function isTopOrBottomHung(state: IState): boolean {
  return (
    !!state.parameters.openingType.value &&
    (state.parameters.openingType.value.toString() ===
      OpeningType.FENSTER_OEFFNUNGSART_KLAPP ||
      state.parameters.openingType.value.toString() ===
        OpeningType.FENSTER_OEFFNUNGSART_KIPP)
  );
}

function setRangeForSashSizeByDistanceToHinge(
  state: IState,
): ParameterBoxRange {
  if (
    state.parameters.distanceOption.value === 'distanceToHinge' &&
    state.parameters.drivePosition.value !==
      'ANTRIEB_MONTAGEPOSITION_POSITION_BANDGEGENSEITE'
  ) {
    return [state.parameters.distanceToHinge.toNumber() ?? 0, 20000];
  }

  return [1, 20000];
}

export function getSelectedWindow(state: IState): Window | undefined {
  return state.ui.selectedWindow;
}

function windowAreaHelp(state: IState) {
  function getNumberOfWindDeflectors(): number | undefined {
    if (state.nrwg.windDeflectors && state.nrwg.windDeflectors.length > 0) {
      return state.nrwg.windDeflectors[0].number;
    }
  }

  return state.parameters.rangeOfApplication.value === RangeOfApplication.ROOF
    ? (state: IState) => (
        <WindowAreaHelp
          numberOfWindDeflectors={getNumberOfWindDeflectors()}
          application={state.parameters.application.value as Application}
          locale={state.intl.locale as Locale}
        />
      )
    : [];
}

export const SASH_CONFIGURATIONS: ParameterSectionProps = {
  title: 'fenster_edit_wingoptions',
  editableBoxConfigurations: [
    {
      name: 'fenster_edit_winginstallationtype',
      valueKey: ValueKey.VALUEKEY_RANGE_OF_APPLICATION,
      editType: EditTypes.TYPE_RADIO,

      help: [
        {
          heading: RangeOfApplication.FACADE,
          image: 'fassade_auswaerts.jpg',
          text: 'help_Fassade',
        },
        {
          heading: RangeOfApplication.ROOF,
          image: 'dach.jpg',
          text: 'help_Dach',
        },
      ],
      options: (state: IState) => {
        return [
          { name: RangeOfApplication.FACADE },
          { name: RangeOfApplication.ROOF },
        ];
      },
    },
    {
      name: 'tree_staticChild_fenster_einbauwinkel',
      valueKey: ValueKey.VALUEKEY_ANGLE_OF_INSTALLATION,
      editType: EditTypes.TYPE_PANEL,
      unit: '˚',
      help: [
        {
          heading: 'tree_staticChild_fenster_einbauwinkel',
          image: 'installation_angle.png',
          text: 'help_tree_staticChild_fenster_einbauwinkel',
        },
      ],
      staticValue: state => {
        const w = getSelectedWindow(state);

        if (
          state.parameters.rangeOfApplication.value ===
            RangeOfApplication.FACADE &&
          getSelectedWindow(state)?.nrwg
        ) {
          return 90;
        }
      },

      range: (state: IState) => {
        if (
          state.parameters.rangeOfApplication.value ===
          RangeOfApplication.FACADE
        ) {
          if (getSelectedWindow(state)?.nrwg) {
            return [90, 90];
          } else {
            return [61, 120];
          }
        } else {
          return [0, 60];
        }
      },
    },
    {
      name: 'fenster_edit_wingtype',
      valueKey: ValueKey.VALUEKEY_OPENING_TYPE,
      editType: EditTypes.TYPE_RADIO,
      hidden: state => !!getSelectedWindow(state)?.nrwg,
      help: [
        {
          heading: OpeningType.FENSTER_OEFFNUNGSART_KIPP,
          image: 'kipp_in.jpg',
          text: 'help_tree_staticChild_fenster_oeffnungsart_kipp',
        },
        {
          heading: OpeningType.FENSTER_OEFFNUNGSART_KLAPP,
          image: 'klapp_in.jpg',
          text: 'help_tree_staticChild_fenster_oeffnungsart_klapp',
        },
        {
          heading: OpeningType.FENSTER_OEFFNUNGSART_DREH,
          image: 'dreh_in.jpg',
          text: 'help_tree_staticChild_fenster_oeffnungsart_dreh',
        },
      ],
      options: (state: IState) => {
        return [
          { name: OpeningType.FENSTER_OEFFNUNGSART_KIPP },
          { name: OpeningType.FENSTER_OEFFNUNGSART_KLAPP },
          { name: OpeningType.FENSTER_OEFFNUNGSART_DREH },
        ];
      },
    },

    {
      name: 'WINDOW_AREA_BOX_LABEL_OPENING_SIDE',
      valueKey: ValueKey.OPENING_SIDE,
      editType: EditTypes.TYPE_RADIO,
      hidden: state =>
        state.parameters.openingType.value !==
          OpeningType.FENSTER_OEFFNUNGSART_DREH ||
        state.parameters.rangeOfApplication.value === RangeOfApplication.ROOF ||
        !!getSelectedWindow(state)?.nrwg,
      help: [
        {
          heading: OpeningSide.OPENING_SIDE_RIGHT,
          image: 'drehfenster-einwaerts-DIN-rechts.svg',
        },
        {
          heading: OpeningSide.OPENING_SIDE_LEFT,
          image: 'drehfenster-einwaerts-DIN-links.svg',
        },
      ],
      options: (state: IState) => {
        return [
          { name: OpeningSide.OPENING_SIDE_RIGHT },
          { name: OpeningSide.OPENING_SIDE_LEFT },
        ];
      },
    },

    {
      name: 'tree_parent_fenster_oeffnungsrichtung',
      valueKey: ValueKey.VALUEKEY_OPENING_DIRECTION,
      editType: EditTypes.TYPE_RADIO,

      hidden: (state: IState) =>
        state.parameters.rangeOfApplication.value === RangeOfApplication.ROOF ||
        !!getSelectedWindow(state)?.nrwg,
      help: [
        {
          heading: 'FENSTER_OEFFNUNGSRICHTUNG_AUSWAERTS',
          image: 'fassade_auswaerts.jpg',
          text: 'help_tree_staticChild_fenster_oeffnungsrichtung_auswaerts',
        },
        {
          heading: 'FENSTER_OEFFNUNGSRICHTUNG_EINWAERTS',
          image: 'fassade_einwaerts.jpg',
          text: 'help_tree_staticChild_fenster_oeffnungsrichtung_einwaerts',
        },
      ],
      options: (state: IState) => {
        return [
          { name: OpeningDirection.FENSTER_OEFFNUNGSRICHTUNG_AUSWAERTS },
          { name: OpeningDirection.FENSTER_OEFFNUNGSRICHTUNG_EINWAERTS },
        ];
      },
    },
    {
      name: 'tree_staticChild_fenster_fluegelbreite',
      valueKey: ValueKey.VALUEKEY_SASH_WIDTH,
      displayedAsInvalid: (s: IState) => {
        const validation = s.nrwg.validation;

        if (!validation) {
          return false;
        }

        return (
          !validation.ratioWidthToDoubleFlapHeight ||
          !validation.ratioSashHeightToSashWidth ||
          !validation.ratioSashWidthToSashHeight ||
          !validation.ratioClearHeightToClearWidth ||
          !validation.ratioClearWidthToClearHeight
        );
      },
      validatedFields: [
        ValueKey.VALUEKEY_SASH_WIDTH,
        ValueKey.VALUEKEY_SASH_HEIGHT,
        ValueKey.AREA,
        ValueKey.RATIO_WIDTH_TO_DOUBLE_FLAP_HEIGHT,
        ValueKey.SASH_FRAME_DISTANCE,
        ValueKey.RATIO_SASH_HEIGHT_TO_SASH_WIDTH,
        ValueKey.RATIO_SASH_WIDTH_TO_SASH_HEIGHT,
        ValueKey.RATIO_CLEAR_HEIGHT_TO_CLEAR_WIDTH,
        ValueKey.RATIO_CLEAR_WIDTH_TO_CLEAR_HEIGHT,
      ],
      editType: EditTypes.TYPE_WINDOW_AREA,
      hidden: s =>
        [
          ValueKey.VALUEKEY_SASH_HEIGHT,
          ValueKey.AREA,
          ValueKey.SASH_FRAME_DISTANCE,
        ].includes(s.ui.singleEdit.valueKey as ValueKey) ||
        !getSelectedWindow(s)?.nrwg,
      unit: 'mm',
      help: [],
      dynamicHelp: windowAreaHelp,
    },
    {
      name: 'tree_staticChild_fenster_fluegelhoehe',
      valueKey: ValueKey.VALUEKEY_SASH_HEIGHT,
      displayedAsInvalid: (s: IState) => {
        const validation = s.nrwg.validation;

        if (!validation) {
          return false;
        }

        return (
          !validation.ratioWidthToDoubleFlapHeight ||
          !validation.ratioSashHeightToSashWidth ||
          !validation.ratioSashWidthToSashHeight ||
          !validation.ratioClearHeightToClearWidth ||
          !validation.ratioClearWidthToClearHeight
        );
      },
      editType: (state: IState) =>
        state.ui.singleEdit.single
          ? EditTypes.TYPE_WINDOW_AREA
          : EditTypes.TYPE_NONE,
      validatedFields: [
        ValueKey.VALUEKEY_SASH_WIDTH,
        ValueKey.VALUEKEY_SASH_HEIGHT,
        ValueKey.AREA,
        ValueKey.RATIO_WIDTH_TO_DOUBLE_FLAP_HEIGHT,
        ValueKey.SASH_FRAME_DISTANCE,
        ValueKey.RATIO_SASH_HEIGHT_TO_SASH_WIDTH,
        ValueKey.RATIO_SASH_WIDTH_TO_SASH_HEIGHT,
        ValueKey.RATIO_CLEAR_HEIGHT_TO_CLEAR_WIDTH,
        ValueKey.RATIO_CLEAR_WIDTH_TO_CLEAR_HEIGHT,
      ],
      range: (state: IState) =>
        isTopOrBottomHung(state)
          ? setRangeForSashSizeByDistanceToHinge(state)
          : [1, 20000],
      hidden: s =>
        [
          ValueKey.VALUEKEY_SASH_WIDTH,
          ValueKey.AREA,
          ValueKey.SASH_FRAME_DISTANCE,
        ].includes(s.ui.singleEdit.valueKey as ValueKey) ||
        !getSelectedWindow(s)?.nrwg,
      unit: 'mm',
      help: [],
      dynamicHelp: windowAreaHelp,
    },
    {
      name: 'WINDOW_AREA_BOX_LABEL_HEADING',
      valueKey: ValueKey.AREA,
      editType: (state: IState) =>
        state.ui.singleEdit.single
          ? EditTypes.TYPE_WINDOW_AREA
          : EditTypes.TYPE_NONE,
      validatedFields: [
        ValueKey.VALUEKEY_SASH_WIDTH,
        ValueKey.VALUEKEY_SASH_HEIGHT,
        ValueKey.AREA,
        ValueKey.RATIO_WIDTH_TO_DOUBLE_FLAP_HEIGHT,
        ValueKey.SASH_FRAME_DISTANCE,
        ValueKey.RATIO_SASH_HEIGHT_TO_SASH_WIDTH,
        ValueKey.RATIO_SASH_WIDTH_TO_SASH_HEIGHT,
        ValueKey.RATIO_CLEAR_HEIGHT_TO_CLEAR_WIDTH,
        ValueKey.RATIO_CLEAR_WIDTH_TO_CLEAR_HEIGHT,
      ],
      hidden: s =>
        [
          ValueKey.VALUEKEY_SASH_WIDTH,
          ValueKey.VALUEKEY_SASH_HEIGHT,
          ValueKey.SASH_FRAME_DISTANCE,
        ].includes(s.ui.singleEdit.valueKey as ValueKey) ||
        !getSelectedWindow(s)?.nrwg,
      unit: 'm²',
      help: [],
      dynamicHelp: windowAreaHelp,
    },
    {
      name: 'WINDOW_AREA_BOX_LABEL_SASH_FRAME_DISTANCE',
      valueKey: ValueKey.SASH_FRAME_DISTANCE,
      displayedAsInvalid: (s: IState) => {
        const validation = s.nrwg.validation;

        if (!validation) {
          return false;
        }

        return (
          !validation.ratioWidthToDoubleFlapHeight ||
          !validation.ratioSashHeightToSashWidth ||
          !validation.ratioSashWidthToSashHeight ||
          !validation.ratioClearHeightToClearWidth ||
          !validation.ratioClearWidthToClearHeight
        );
      },
      editType: (state: IState) =>
        state.ui.singleEdit.single
          ? EditTypes.TYPE_WINDOW_AREA
          : EditTypes.TYPE_NONE,
      validatedFields: [
        ValueKey.VALUEKEY_SASH_WIDTH,
        ValueKey.VALUEKEY_SASH_HEIGHT,
        ValueKey.AREA,
        ValueKey.RATIO_WIDTH_TO_DOUBLE_FLAP_HEIGHT,
        ValueKey.SASH_FRAME_DISTANCE,
        ValueKey.RATIO_SASH_HEIGHT_TO_SASH_WIDTH,
        ValueKey.RATIO_SASH_WIDTH_TO_SASH_HEIGHT,
        ValueKey.RATIO_CLEAR_HEIGHT_TO_CLEAR_WIDTH,
        ValueKey.RATIO_CLEAR_WIDTH_TO_CLEAR_HEIGHT,
      ],
      hidden: s => {
        return (
          [
            ValueKey.VALUEKEY_SASH_WIDTH,
            ValueKey.VALUEKEY_SASH_HEIGHT,
            ValueKey.AREA,
          ].includes(s.ui.singleEdit.valueKey as ValueKey) ||
          !getSelectedWindow(s)?.nrwg ||
          !isDoubleFlapApplication(s)
        );
      },
      unit: 'm²',
      help: [],
      dynamicHelp: windowAreaHelp,
    },
    {
      name: 'tree_staticChild_fenster_fluegelbreite',
      valueKey: ValueKey.VALUEKEY_SASH_WIDTH,
      editType: EditTypes.TYPE_PANEL,
      range: (state: IState) =>
        isTopOrBottomHung(state)
          ? [1, 20000]
          : setRangeForSashSizeByDistanceToHinge(state),
      unit: 'mm',
      hidden: s => {
        return !!getSelectedWindow(s)?.nrwg;
      },
      help: [
        {
          heading: 'tree_staticChild_fenster_fluegelbreite',
          image: 'sash_width.png',
          text: 'help_tree_staticChild_fenster_fluegelbreite',
        },
      ],
    },
    {
      name: 'tree_staticChild_fenster_fluegelhoehe',
      valueKey: ValueKey.VALUEKEY_SASH_HEIGHT,
      editType: EditTypes.TYPE_PANEL,

      range: (state: IState) =>
        isTopOrBottomHung(state)
          ? setRangeForSashSizeByDistanceToHinge(state)
          : [1, 20000],
      unit: 'mm',
      hidden: s => !!getSelectedWindow(s)?.nrwg,
      help: [
        {
          heading: 'tree_staticChild_fenster_fluegelhoehe',
          image: 'sash_height.png',
          text: 'help_tree_staticChild_fenster_fluegelhoehe',
        },
      ],
    },
    {
      name: 'SANDWICH_ELEMENT',
      valueKey: ValueKey.VALUEKEY_SANDWICH_ELEMENT,
      editType: EditTypes.TYPE_SWITCH,
      help: [
        {
          heading: FillType.GLASS_FILL,
          image: 'sandwich_element_glass_fill.png',
          text: 'none',
        },
        {
          heading: FillType.SANDWICH_FILL,
          image: 'sandwich_element_sandwich_fill.png',
          text: 'HELP_SANDWICH_ELEMENT',
        },
      ],
      turnedOffValue: FillType.GLASS_FILL,
      turnedOnValue: FillType.SANDWICH_FILL,
    },
    {
      name: 'SASHWEIGHT',
      valueKey: ValueKey.VALUEKEY_SASH_WEIGHT,
      editType: EditTypes.TYPE_PANEL,
      unit: 'kg',
      help: [
        {
          heading: 'SASH_WEIGHT_AND_THICKNESS',
          image: 'sash_weight.png',
          text: 'TEXT_SASH_WEIGHT',
        },
      ],
      range: (state: IState) => [0, 3000],
      hidden: state =>
        state.parameters[ValueKey.VALUEKEY_SANDWICH_ELEMENT].value !==
        FillType.SANDWICH_FILL,
    },
    {
      name: 'PARAMETER_LIST_WEIGHT_GLASS_THICKNESS_LABEL',
      valueKey: [
        ValueKey.VALUEKEY_GLAS_THICKNESS,
        ValueKey.VALUEKEY_SASH_WEIGHT,
      ],
      editType: EditTypes.TYPE_TAB_BAR_SINGLE_INPUT,
      validatedFields: [
        ValueKey.VALUEKEY_GLAS_THICKNESS,
        ValueKey.VALUEKEY_SASH_WEIGHT,
      ],
      rangeMessages: (state: IState) =>
        _.compact([
          state.parameters.weightOption.value ===
            ValueKey.VALUEKEY_SASH_WEIGHT &&
            state.nrwg.ranges?.sashWeight && {
              id: 'SASH_WEIGHT_RANGE_INFORMATION',
              values: {
                maxRange: state.nrwg.ranges?.sashWeight.max,
              },
              field: ValueKey.VALUEKEY_SASH_WEIGHT,
            },
          state.parameters.weightOption.value ===
            ValueKey.VALUEKEY_GLAS_THICKNESS &&
            state.nrwg.ranges?.glassThickness && {
              id: 'GLASS_THICKNESS_RANGE_INFORMATION',
              values: {
                minRange: state.nrwg.ranges?.glassThickness.min,
              },
              field: ValueKey.VALUEKEY_GLAS_THICKNESS,
            },
        ]),
      rangeComponents: (state: IState) =>
        _.compact([
          state.parameters.weightOption.value ===
            ValueKey.VALUEKEY_GLAS_THICKNESS &&
            state.nrwg.ranges?.sashWeight && (
              <Ratio
                label="SASHWEIGHT"
                valid={!!state.nrwg.validation?.sashWeight}
                range={state.nrwg.ranges?.sashWeight}
              />
            ),
          state.parameters.weightOption.value ===
            ValueKey.VALUEKEY_SASH_WEIGHT &&
            state.nrwg.ranges?.sashWeight && (
              <Ratio
                label="THICKNESS"
                valid={!!state.nrwg.validation?.glassThickness}
                range={state.nrwg.ranges?.glassThickness}
              />
            ),
        ]),
      saveComponentLeadingValueKeyAction: (value: string) =>
        changeCalculationParameter(ValueKey.WEIGHT_OPTION, value),
      selectedValueKey: 'weightOption',
      help: [
        {
          heading: 'SASH_WEIGHT_AND_THICKNESS',
          image: 'glass_thickness_weight.png',
          text: 'help_tree_staticChild_fenster_glasdicke',
        },
      ],
      options: (state: IState) => {
        return [
          {
            name: TabOptions.OPTION_SASHWEIGHT,
            range: (state: IState) => [0, 3000],
            valueKey: ValueKey.VALUEKEY_SASH_WEIGHT,
          },
          {
            name: TabOptions.OPTION_THICKNESS,
            range: (state: IState) => [0, 1200],
            valueKey: ValueKey.VALUEKEY_GLAS_THICKNESS,
          },
        ];
      },
      hidden: state =>
        state.ui.singleEdit.valueKey === ValueKey.VALUEKEY_GLAS_THICKNESS ||
        state.parameters[ValueKey.VALUEKEY_SANDWICH_ELEMENT].value ===
          FillType.SANDWICH_FILL,
    },
    {
      name: 'parameter_name_opening',
      withoutRanges: (state: IState) =>
        state.parameters.openingOption.value ===
        ValueKey.VALUEKEY_OPENING_STROKE,
      valueKey: [
        ValueKey.VALUEKEY_OPENING_STROKE,
        ValueKey.VALUEKEY_OPENING_ANGLE,
      ],
      editType: EditTypes.TYPE_TAB_BAR_SINGLE_INPUT,
      validatedFields: [
        ValueKey.VALUEKEY_OPENING_ANGLE,
        ValueKey.VALUEKEY_OPENING_STROKE,
      ],
      saveComponentLeadingValueKeyAction: (valueKey: string) =>
        changeCalculationParameter(ValueKey.OPENING_OPTION, valueKey),
      range: (state: IState) => [0, Infinity],
      rangeMessages: (state: IState) =>
        state.nrwg.ranges?.openingAngle && [
          {
            id: 'OPENING_ANGLE_RANGE_INFORMATION',
            values: {
              upperRange: state.nrwg.ranges?.openingAngle.max,
              lowerRange: state.nrwg.ranges?.openingAngle.min,
            },
            field: ValueKey.VALUEKEY_OPENING_ANGLE,
          },
        ],
      selectedValueKey: 'openingOption',
      help: [
        {
          heading: 'tree_staticChild_fenster_oeffnungswinkel',
          image: 'oeffnungswinkel_oeffnungshub.png',
          text: 'help_tree_staticChild_fenster_oeffnungswinkel',
        },
      ],
      options: (state: IState) => {
        return [
          {
            name: TabOptions.OPTION_OPENING_ANGLE,
            range: (state: IState) => {
              return [
                state.nrwg.ranges?.openingAngle.min || 0,
                state.nrwg.ranges?.openingAngle.max || 180,
              ];
            },
            onChangeDispatch: (
              newValue: number | undefined,
              selectedWindow: Window | undefined,
            ) => {
              return updateAngleAndRecalculate(
                getValue(newValue, selectedWindow),
              );
            },
            valueKey: ValueKey.VALUEKEY_OPENING_ANGLE,
            unit: 'degrees',
          },
          {
            name: TabOptions.OPTION_OPENING_STROKE,
            range: (state: IState) => {
              const selectedWindow = getSelectedWindow(state);
              const maxStroke = calculateMaxStroke(state.parameters);

              return selectedWindow?.nrwg ? [] : [0, maxStroke];
            },
            onChangeDispatch: (
              newValue: number | undefined,
              selectedWindow: Window | undefined,
            ) => {
              return updateStrokeAndRecalculate(
                getValue(newValue, selectedWindow),
              );
            },
            valueKey: ValueKey.VALUEKEY_OPENING_STROKE,
          },
        ];
      },
    },

    {
      name: 'tree_staticChild_fenster_windlast',
      valueKey: ValueKey.VALUEKEY_WIND_LOAD,
      editType: EditTypes.TYPE_PANEL,
      unit: 'Pa',
      help: [
        {
          heading: 'tree_staticChild_fenster_windlast',
          image: 'windlast.png',
          text: 'help_tree_staticChild_fenster_windlast',
        },
      ],
      range: (state: IState) => [0, 10000],
    },
    {
      name: 'tree_staticChild_fenster_schneelast',
      valueKey: ValueKey.VALUEKEY_SNOW_LOAD,
      editType: EditTypes.TYPE_PANEL,
      unit: 'Pa',
      help: [
        {
          heading: 'tree_staticChild_fenster_schneelast',
          image: 'none',
          text: 'help_tree_staticChild_fenster_schneelast',
        },
      ],
      range: (state: IState) => [0, 10000],
      hidden: (state: IState) =>
        state.parameters.rangeOfApplication.value === RangeOfApplication.FACADE,
    },
    {
      name: 'result_info_korrekturFaktor',
      valueKey: ValueKey.CORRECTION_FACTOR,
      editType: EditTypes.TYPE_PANEL,
      decimal: true,
      additionalDescriptionField: true,
      hidden: (state: IState) => {
        const selectedWindow = getSelectedWindow(state);
        return !selectedWindow?.rwa || selectedWindow?.nrwg;
      },
      help: [
        {
          heading: 'result_info_korrekturFaktor',
          image: 'none',
          text: 'HELP_CORRECTION_FACTOR',
        },
      ],
      range: (state: IState) => [0, 1],
    },
  ],
};

function getDistanceToHingeRange(state: IState): [number, number] {
  if (isTopOrBottomHung(state)) {
    return [1, state.parameters.sashHeight.toNumber() ?? 10000];
  } else if (
    state.parameters.drivePosition.value ===
    DrivePosition.ANTRIEB_MONTAGEPOSITION_POSITION_SEITLICH
  ) {
    return [
      MINIMUM_DISTANCE_TO_HINGE_SIDE_HUNG_SIDE_INSTALLATION,
      state.parameters.sashWidth.toNumber() ?? 10000,
    ];
  } else {
    return [1, state.parameters.sashWidth.toNumber() ?? 10000];
  }
}

function getDistanceToHintRangeForPercentDistanceToHinge(
  state: IState,
): [number, number] {
  if (isTopOrBottomHung(state)) {
    return [0, 100];
  } else if (
    state.parameters.drivePosition.value ===
    DrivePosition.ANTRIEB_MONTAGEPOSITION_POSITION_SEITLICH
  ) {
    return [
      getPercent(
        state.parameters.sashWidth.toNumber() ?? 0,
        MINIMUM_DISTANCE_TO_HINGE_SIDE_HUNG_SIDE_INSTALLATION,
      ),
      100,
    ];
  } else {
    return [0, 100];
  }
}

function getDistanceToHingeInstallationHint(state: IState): Hints {
  if (isTopOrBottomHung(state)) {
    return Hints.HINT_RECOMMENDATION_HEIGHT;
  } else if (
    state.parameters.drivePosition.value ===
    DrivePosition.ANTRIEB_MONTAGEPOSITION_POSITION_SEITLICH
  ) {
    return Hints.HINT_RECOMMENDATION_SIDE_HUNG_SIDE_INSTALLATION;
  } else {
    return Hints.HINT_RECOMMENDATION_WIDTH;
  }
}

export const DRIVE_CONFIGURATIONS: {
  title: string;
  editableBoxConfigurations: ParameterConfiguration[];
} = {
  title: 'fenster_edit_geardata_notoptional',
  editableBoxConfigurations: [
    {
      name: 'ASSEMBLY_TYPE',
      valueKey: ValueKey.VALUEKEY_ASSEMBLY_TYPE,
      editType: EditTypes.TYPE_RADIO,
      hidden: s =>
        s.parameters.openingDirection.value ===
          OpeningDirection.FENSTER_OEFFNUNGSRICHTUNG_AUSWAERTS ||
        !!getSelectedWindow(s)?.nrwg,
      help: [
        {
          heading: AssemblyType.ASSEMBLY_TYPE_FRAME,
          image: 'rahmenmontage.png',
          text: 'ASSEMBLY_TYPE_FRAME_DESCRIPTION_TEXT',
        },
        {
          heading: AssemblyType.ASSEMBLY_TYPE_SASH,
          image: 'fluegelmontage.png',
          text: 'ASSEMBLY_TYPE_SASH_DESCRIPTION_TEXT',
        },
      ],
      options: (state: IState) => {
        return [
          { name: AssemblyType.ASSEMBLY_TYPE_FRAME },
          { name: AssemblyType.ASSEMBLY_TYPE_SASH },
        ];
      },
    },
    {
      name: 'fenster_edit_antriebsposition',
      valueKey: ValueKey.VALUEKEY_DRIVE_POSITION,
      editType: EditTypes.TYPE_RADIO,
      hidden: (state: IState) => !!getSelectedWindow(state)?.nrwg,
      help: [
        {
          heading:
            DrivePosition.ANTRIEB_MONTAGEPOSITION_POSITION_BANDGEGENSEITE,
          image: 'bandgegenseite.png',
          text: 'help_tree_staticChild_antrieb_montageposition_position_bandgegenseite',
        },
        {
          heading: DrivePosition.ANTRIEB_MONTAGEPOSITION_POSITION_SEITLICH,
          image: 'seitliche_montage.png',
          text: 'help_tree_staticChild_antrieb_montageposition_position_seitlich',
        },
        {
          heading: DrivePosition.ANTRIEB_MONTAGEPOSITION_POSITION_TRAVERSE,
          image: 'traverse.png',
          text: 'help_tree_staticChild_antrieb_montageposition_position_traverse',
        },
      ],
      options: (state: IState) => {
        return [
          {
            name: DrivePosition.ANTRIEB_MONTAGEPOSITION_POSITION_BANDGEGENSEITE,
          },
          {
            name: DrivePosition.ANTRIEB_MONTAGEPOSITION_POSITION_SEITLICH,
          },
          {
            name: DrivePosition.ANTRIEB_MONTAGEPOSITION_POSITION_TRAVERSE,
          },
        ];
      },
    },
    {
      name: 'fenster_edit_verriegelung',
      valueKey: ValueKey.LOCKING_DRIVE,
      editType: EditTypes.TYPE_RADIO,
      help: [
        {
          heading: LockingDrive.FRA_11,
          image: 'none',
          text: 'HELP_LOCKING_DRIVE_FRA_11',
        },
        {
          heading: LockingDrive.VLD,
          image: 'none',
          text: 'HELP_LOCKING_DRIVE_VLD',
        },
      ],
      errorMessage: 'VALIDATION_ERROR_SERIES_DOESENT_SUPPORTS_LOCKING',
      options: (state: IState) => {
        if (
          state.parameters.rangeOfApplication.value ===
          RangeOfApplication.FACADE
        ) {
          return [
            { name: LockingDrive.WITHOUT_LOCKING_DRIVE },
            { name: LockingDrive.FRA_11 },
            { name: LockingDrive.VLD },
          ];
        } else {
          return [
            { name: LockingDrive.WITHOUT_LOCKING_DRIVE },
            { name: LockingDrive.VLD },
          ];
        }
      },
    },
    {
      name: 'tree_staticChild_antrieb_drehband',
      valueKey: ValueKey.VALUEKEY_DISTANCE_TO_HINGE,
      editType: EditTypes.TYPE_TAB_BAR,
      validatedFields: [
        ValueKey.VALUEKEY_DISTANCE_TO_HINGE,
        ValueKey.VALUEKEY_PERCENT_OF_DISTANCE_TO_HINGE,
      ],
      unit: 'mm',
      saveComponentLeadingValueKeyAction: (valueKey: string) =>
        updateStore('distanceOption', valueKey),
      hint: (state: IState) => getDistanceToHingeInstallationHint(state),
      rangeMessages: (state: IState) => {
        return (
          state.nrwg.ranges?.percentOfDistanceToHinge && [
            {
              id: 'DISTANCE_TO_HINGE_RANGE_INFORMATION',
              values: {
                lowerRange: state.nrwg.ranges?.percentOfDistanceToHinge.min,
              },
              field: ValueKey.VALUEKEY_PERCENT_OF_DISTANCE_TO_HINGE,
            },
          ]
        );
      },
      selectedValueKey: 'distanceOption',
      help: [
        {
          heading: 'tree_staticChild_antrieb_drehband',
          image: 'distance_to_hinge.jpg',
          text: 'help_tree_staticChild_antrieb_drehband',
        },
      ],
      options: (state: IState) => {
        return [
          {
            name: TabOptions.OPTION_PERCENT_TO_HINGE,
            range: (state: IState) =>
              getDistanceToHintRangeForPercentDistanceToHinge(state),
            valueKey: ValueKey.VALUEKEY_PERCENT_OF_DISTANCE_TO_HINGE,
          },
          {
            name: TabOptions.OPTION_MILLIMETRE,
            range: (state: IState) => getDistanceToHingeRange(state),
            valueKey: ValueKey.VALUEKEY_DISTANCE_TO_HINGE,
          },
        ];
      },

      hidden: (state: IState) =>
        state.parameters.drivePosition.value ===
        DrivePosition.ANTRIEB_MONTAGEPOSITION_POSITION_BANDGEGENSEITE,
    },
    {
      name: 'fenster_edit_anzahlAntriebe',
      valueKey: ValueKey.VALUEKEY_NUMBER_OF_DRIVES,
      editType: EditTypes.TYPE_PANEL,
      help: [
        {
          heading: 'fenster_edit_anzahlAntriebe',
          image: 'anzahl_der_antriebe.png',
          text: 'help_tree_staticChild_antrieb_anzahl',
        },
      ],
      range: (state: IState) => {
        if (
          state.parameters.drivePosition.value ===
          DrivePosition.ANTRIEB_MONTAGEPOSITION_POSITION_SEITLICH
        ) {
          return state.parameters.openingType.value ===
            OpeningType.FENSTER_OEFFNUNGSART_DREH
            ? [1, 2]
            : [2, 2];
        }

        return [1, 8];
      },
    },
    {
      name: 'fenster_edit_antriebsspannung',
      valueKey: ValueKey.VALUEKEY_VOLTAGE,
      editType: EditTypes.TYPE_RADIO,
      help: [
        {
          heading: Voltage.ANTRIEB_SPANNUNG_230V,
          image: 'spannung_230V.jpg',
          text: 'help_tree_staticChild_antrieb_spannung_23v',
        },
        {
          heading: Voltage.ANTRIEB_SPANNUNG_24V,
          image: 'none',
          text: 'help_tree_staticChild_antrieb_spannung_24v',
        },
      ],
      options: (state: IState) => {
        return [
          { name: Parameters.NO_SELECTION },
          { name: Voltage.ANTRIEB_SPANNUNG_230V },
          { name: Voltage.ANTRIEB_SPANNUNG_24V },
        ];
      },
    },
    {
      name: 'DRIVE_TYPE',
      valueKey: ValueKey.VALUEKEY_DRIVE_TYPE,
      editType: EditTypes.TYPE_RADIO,
      help: [
        {
          heading: DriveType.ANTRIEB_TYP_KETTE,
          image: 'kettenantrieb.jpg',
          text: 'help_tree_staticChild_antrieb_typ_kette',
        },
        {
          heading: DriveType.ANTRIEB_TYP_ZAHNSTANGE,
          image: 'zahnstangenantrieb.jpg',
          text: 'help_tree_staticChild_antrieb_typ_zahnstange',
        },
      ],
      options: (state: IState) => {
        return [
          { name: Parameters.NO_SELECTION },
          { name: DriveType.ANTRIEB_TYP_KETTE },
          { name: DriveType.ANTRIEB_TYP_ZAHNSTANGE },
        ];
      },
    },
    {
      name: 'DRIVE_SERIES',
      valueKey: ValueKey.VALUEKEY_DRIVE_SERIES,
      editType: EditTypes.TYPE_SELECT,
      optionsFilter: (state: IState, options: { name: string }[]) => {
        if (state.parameters.driveType.value === DriveType.ANTRIEB_TYP_KETTE) {
          return options.filter(
            o =>
              getDriveTypeNames(state, DriveType.ANTRIEB_TYP_KETTE).includes(
                o.name,
              ) || o.name === Parameters.NO_SELECTION,
          );
        }

        if (
          state.parameters.driveType.value === DriveType.ANTRIEB_TYP_ZAHNSTANGE
        ) {
          return options.filter(
            o =>
              getDriveTypeNames(
                state,
                DriveType.ANTRIEB_TYP_ZAHNSTANGE,
              ).includes(o.name) || o.name === Parameters.NO_SELECTION,
          );
        }

        return options;
      },
      help: [
        {
          heading: 'CDC',
          image: 'cdc.jpg',
          text: 'help_tree_staticChild_antrieb_art_cdc',
        },
        {
          heading: 'CDCTW',
          image: 'cdc_tw.jpg',
          text: 'help_tree_staticChild_antrieb_art_cdctw',
        },
        {
          heading: 'DXD',
          image: 'dxd.jpg',
          text: 'help_tree_staticChild_antrieb_art_dxd',
        },
        {
          heading: 'KA',
          image: 'ka.jpg',
          text: 'help_tree_staticChild_antrieb_art_ka',
        },
        {
          heading: 'KATW',
          image: 'ka_tw.jpg',
          text: 'help_tree_staticChild_antrieb_art_katw',
        },
        {
          heading: 'SHD',
          image: 'shd.jpg',
          text: 'help_tree_staticChild_antrieb_art_shd',
        },
        {
          heading: 'ZA',
          image: 'za.jpg',
          text: 'help_tree_staticChild_antrieb_art_za',
        },
        {
          heading: 'CDP',
          image: 'cdp.jpg',
          text: 'help_tree_staticChild_antrieb_art_cdp',
        },
        {
          heading: 'CDPTW',
          image: 'cdp_tw.jpg',
          text: 'help_tree_staticChild_antrieb_art_cdptw',
        },
        {
          heading: 'VCD',
          image: 'vcd.jpg',
          text: 'help_tree_staticChild_antrieb_art_vcd',
        },
        {
          heading: 'CDM',
          image: 'cdm.jpg',
          text: 'help_tree_staticChild_antrieb_art_cdm',
        },
      ],
      options: (state: IState) => {
        return [
          { name: Parameters.NO_SELECTION },
          { name: 'CDC' },
          { name: 'CDCTW' },
          { name: 'DXD' },
          { name: 'KA' },
          { name: 'KATW' },
          { name: 'SHD' },
          { name: 'ZA' },
          { name: 'CDP' },
          { name: 'CDPTW' },
          { name: 'VCD' },
          { name: 'CDM' },
        ];
      },
    },
  ],
};

export const GEOMETRIC_SMOKE_EXTRACTION_CONFIGURATION: {
  title: string;
  editableBoxConfigurations: ParameterConfiguration[];
} = {
  title: 'PARAMETER_LABEL_DESMOKING',
  editableBoxConfigurations: [
    {
      name: 'TYPE_OF_GEOMETRIC_CALCULATION',
      valueKey: ValueKey.VALUEKEY_TYPE_OF_PROFILE_INPUT,
      editType: EditTypes.TYPE_TAB_BAR_WITHOUT_VALUE_EDIT,
      unit: '',
      hidden: state => !!getSelectedWindow(state)?.nrwg,
      saveComponentLeadingValueKeyAction: (valueKey: string) =>
        changeCalculationParameter(
          ValueKey.VALUEKEY_TYPE_OF_PROFILE_INPUT,
          valueKey,
        ),
      selectedValueKey: ValueKey.VALUEKEY_TYPE_OF_PROFILE_INPUT,
      help: [
        {
          heading: TabOptions.OPTION_FREE_ENTRY,
          image: 'type_of_calculation.png',
          text: 'fenster_info_freeInput',
        },
        {
          heading: TabOptions.OPTION_PROFILE_ENTRY,
          image: 'none',
          text: 'HELP_TYPE_OF_GEOMETRIC_CALCULATION_PROFILE_ENTRY',
        },
      ],
      options: (state: IState) => {
        return [
          {
            name: TabOptions.OPTION_FREE_ENTRY,
            value: TabOptions.OPTION_FREE_ENTRY,
          },
          {
            name: 'PROFILE_ENTRY',
            value: TabOptions.OPTION_PROFILE_ENTRY,
          },
        ];
      },
    },
    {
      name: 'tree_System',
      valueKey: ValueKey.PROFILE_SYSTEM,
      editType: EditTypes.TYPE_BUTTON,
      help: [
        {
          heading: 'PROFILE_ENTRY_DIALOG_HEADING',
          image: 'none',
          text: 'PROFILE_ENTRY_INFORMATION_DIALOG_TEXT',
        },
      ],
      hidden: (state: IState) =>
        state.parameters.typeOfProfileDataInput.value ===
          TabOptions.OPTION_FREE_ENTRY ||
        state.ui.singleEdit.valueKey === ValueKey.PROFILE_SERIES ||
        !!getSelectedWindow(state)?.nrwg,
    },
    {
      name: 'tree_parent_system_serie',
      valueKey: ValueKey.PROFILE_SERIES,
      editType: (state: IState) =>
        state.ui.singleEdit.single
          ? EditTypes.TYPE_BUTTON
          : EditTypes.TYPE_NONE,
      help: [
        {
          heading: 'PROFILE_ENTRY_DIALOG_HEADING',
          image: 'none',
          text: 'PROFILE_ENTRY_INFORMATION_DIALOG_TEXT',
        },
      ],
      hidden: (state: IState) =>
        state.parameters.typeOfProfileDataInput.value ===
          TabOptions.OPTION_FREE_ENTRY ||
        state.ui.singleEdit.valueKey === ValueKey.PROFILE_SYSTEM ||
        !!getSelectedWindow(state)?.nrwg,
    },
    {
      name: 'GEOMETRIC_AREA',
      valueKey: ValueKey.VALUEKEY_GEOMETRIC_AREA,
      editType: EditTypes.TYPE_RADIO,
      help: [
        {
          heading: 'ONLY_RECTANGLE',
          image: 'only_rectangle.jpg',
          text: 'none',
        },
        {
          heading: 'ONE_TRIANGLES_RECTANGLE',
          image: 'rectangle+one_triangle.jpg',
          text: 'none',
        },
        {
          heading: 'TWO_TRIANGLES_RECTANGLE',
          image: 'rectangle+two_triangle.jpg',
          text: 'none',
        },
      ],
      hidden: (s: IState) =>
        !!(getSelectedWindow(s) && !getSelectedWindow(s)?.rwa),
      options: (state: IState) => {
        return [
          { name: GeometricArea.ONLY_RECTANGLE },
          { name: GeometricArea.ONE_TRIANGLES_RECTANGLE },
          { name: GeometricArea.TWO_TRIANGLES_RECTANGLE },
        ];
      },
    },
    {
      name: 'REVEAL',
      valueKey: ValueKey.REVEAL,
      editType: EditTypes.TYPE_SWITCH,
      help: [
        {
          heading: RevealDepth.NO_REVEAL,
          image: 'laibungstiefe-nicht-vorhanden_A01.png',
          text: 'none',
        },
        {
          heading: RevealDepth.WITH_REVEAL,
          image: 'laibungstiefe-vorhanden_A02.png',
          text: 'none',
        },
      ],
      hidden: (s: IState) =>
        !!getSelectedWindow(s) && !getSelectedWindow(s)?.rwa,
      turnedOffValue: RevealDepth.NO_REVEAL,
      turnedOnValue: RevealDepth.WITH_REVEAL,
    },
    {
      name: 'REVEAL_STRUCTURE',
      valueKey: ValueKey.REVEAL_STRUCTURE,
      editType: EditTypes.TYPE_RADIO,
      help: [
        {
          heading: RevealStructure.CIRCUMFERENTIAL,
          image: 'laibung_umlaufend_A01.png',
          text: 'none',
        },
        {
          heading: RevealStructure.DIFFERENT,
          image: 'laibung_unterschiedlich_A01.png',
          text: 'none',
        },
      ],
      options: (state: IState) => {
        return [
          { name: RevealStructure.CIRCUMFERENTIAL },
          { name: RevealStructure.DIFFERENT },
        ];
      },
      hidden: s =>
        s.parameters.reveal.value === RevealDepth.NO_REVEAL ||
        s.parameters.geometricArea.value === GeometricArea.ONLY_RECTANGLE ||
        s.parameters.reveal.value === Parameters.ENTRY_REQUIRED,
    },
    {
      name: 'REVEAL_OPPOSITE_THE_HINGE',
      valueKey: ValueKey.REVEAL_OPPOSITE_THE_HINGE,
      editType: EditTypes.TYPE_REVEAL_MEASURES,
      help: [
        {
          heading: 'REVEAL_OPPOSITE_THE_HINGE',
          image: 'laibungstiefe-vorhanden_A01.png',
          text: 'none',
        },
      ],
      hidden: (s: IState) =>
        s.parameters.reveal.value === RevealDepth.NO_REVEAL ||
        s.parameters.revealStructure.value ===
          RevealStructure.CIRCUMFERENTIAL ||
        [ValueKey.REVEAL_TRIANGLE_ONE, ValueKey.REVEAL_TRIANGLE_TWO].includes(
          s.ui.singleEdit.valueKey as ValueKey,
        ) ||
        s.parameters.reveal.value === Parameters.ENTRY_REQUIRED,
    },
    {
      name: 'REVEAL_TRIANGLE_ONE_SINGLE_TRIANGLE',
      valueKey: ValueKey.REVEAL_TRIANGLE_ONE,
      editType: EditTypes.TYPE_REVEAL_MEASURES,
      help: [
        {
          heading: 'REVEAL_TRIANGLE_ONE_SINGLE_TRIANGLE',
          image: 'laibungstiefe-vorhanden_A01.png',
          text: 'none',
        },
      ],
      hidden: (s: IState) =>
        s.parameters.reveal.value === RevealDepth.NO_REVEAL ||
        s.parameters.revealStructure.value ===
          RevealStructure.CIRCUMFERENTIAL ||
        s.parameters.geometricArea.value !==
          GeometricArea.ONE_TRIANGLES_RECTANGLE ||
        [
          ValueKey.REVEAL_OPPOSITE_THE_HINGE,
          ValueKey.REVEAL_TRIANGLE_TWO,
        ].includes(s.ui.singleEdit.valueKey as ValueKey) ||
        s.parameters.reveal.value === Parameters.ENTRY_REQUIRED,
      hideWhenSectionIsOpen: true,
    },
    {
      name: 'REVEAL_TRIANGLE_ONE',
      valueKey: ValueKey.REVEAL_TRIANGLE_ONE,
      editType: EditTypes.TYPE_REVEAL_MEASURES,
      help: [
        {
          heading: 'REVEAL_TRIANGLE_ONE',
          image: 'laibungstiefe-vorhanden_A01.png',
          text: 'none',
        },
      ],
      hidden: (s: IState) =>
        s.parameters.reveal.value === RevealDepth.NO_REVEAL ||
        s.parameters.revealStructure.value ===
          RevealStructure.CIRCUMFERENTIAL ||
        s.parameters.geometricArea.value !==
          GeometricArea.TWO_TRIANGLES_RECTANGLE ||
        [
          ValueKey.REVEAL_OPPOSITE_THE_HINGE,
          ValueKey.REVEAL_TRIANGLE_TWO,
        ].includes(s.ui.singleEdit.valueKey as ValueKey) ||
        s.parameters.reveal.value === Parameters.ENTRY_REQUIRED ||
        s.parameters.revealStructure.value === Parameters.ENTRY_REQUIRED,
      hideWhenSectionIsOpen: true,
    },
    {
      name: 'REVEAL_TRIANGLE_TWO',
      valueKey: ValueKey.REVEAL_TRIANGLE_TWO,
      editType: EditTypes.TYPE_REVEAL_MEASURES,
      help: [
        {
          heading: 'REVEAL_TRIANGLE_TWO',
          image: 'laibungstiefe-vorhanden_A01.png',
          text: 'none',
        },
      ],
      hidden: (s: IState) =>
        s.parameters.reveal.value === RevealDepth.NO_REVEAL ||
        s.parameters.revealStructure.value ===
          RevealStructure.CIRCUMFERENTIAL ||
        s.parameters.geometricArea.value !==
          GeometricArea.TWO_TRIANGLES_RECTANGLE ||
        [
          ValueKey.REVEAL_OPPOSITE_THE_HINGE,
          ValueKey.REVEAL_TRIANGLE_ONE,
        ].includes(s.ui.singleEdit.valueKey as ValueKey) ||
        s.parameters.reveal.value === Parameters.ENTRY_REQUIRED ||
        s.parameters.revealStructure.value === Parameters.ENTRY_REQUIRED,
      hideWhenSectionIsOpen: true,
    },
    {
      name: 'REVEAL_DEPTH',
      valueKey: ValueKey.REVEAL_OPPOSITE_THE_HINGE,
      editType: EditTypes.TYPE_PANEL,
      unit: 'mm',
      help: [
        {
          heading: 'REVEAL_DEPTH',
          image: 'laibungstiefe-vorhanden_A01.png',
          text: 'none',
        },
      ],
      range: (state: IState) => [0, 500],
      hidden: s =>
        s.parameters.reveal.value === RevealDepth.NO_REVEAL ||
        s.parameters.revealStructure.value === RevealStructure.DIFFERENT ||
        s.parameters.reveal.value === Parameters.ENTRY_REQUIRED ||
        s.parameters.revealStructure.value === Parameters.ENTRY_REQUIRED,
      dontResetWhenOutOfRange: true,
    },
    {
      name: 'DISTANCE_SASH_TO_REVEAL',
      valueKey: ValueKey.DISTANCE_SASH_TO_REVEAL,
      editType: EditTypes.TYPE_PANEL,
      unit: 'mm',
      help: [
        {
          heading: 'DISTANCE_SASH_TO_REVEAL',
          image: 'abstand_laibung-fuegel_A01.png',
          text: 'none',
        },
      ],
      range: (state: IState) => [10, 100],
      hidden: s =>
        s.parameters.reveal.value === RevealDepth.NO_REVEAL ||
        s.parameters.reveal.value === Parameters.ENTRY_REQUIRED,
      dontResetWhenOutOfRange: true,
    },
    {
      name: 'GEOMETRICAL_SMOKE_EXTRACTION_EXHAUST_DIMENSION',
      valueKey: ValueKey.VALUEKEY_EXHAUST_DIMENSION,
      editType: EditTypes.TYPE_PANEL,
      unit: 'mm',
      help: [
        {
          heading: 'GEOMETRICAL_SMOKE_EXTRACTION_EXHAUST_DIMENSION',
          image: 'abzugsmass.png',
          text: 'GEOMETRICAL_SMOKE_EXTRACTION_EXHAUST_DIMENSION_INFORMATION_TEXT',
        },
      ],
      range: (state: IState) => [0, 500],
      hidden: (state: IState) =>
        state.parameters.typeOfProfileDataInput.value ===
        TabOptions.OPTION_PROFILE_ENTRY,
    },
    {
      name: 'BUILDING_DEPTH',
      valueKey: ValueKey.VALUEKEY_BUILDING_DEPTH,
      editType: EditTypes.TYPE_PANEL,
      unit: 'mm',
      help: [
        {
          heading: 'BUILDING_DEPTH',
          image: 'rahmenbautiefe.png',
          text: 'BUILDING_DEPTH_INFORMATION_TEXT',
        },
      ],
      range: (state: IState) => [0, 500],
      hidden: (state: IState) =>
        state.parameters.typeOfProfileDataInput.value ===
        TabOptions.OPTION_PROFILE_ENTRY,
    },
  ],
};

export const ParametersColumn: FC<React.PropsWithChildren<unknown>> = () => {
  const selectedWindow = useSelectedWindow();
  const [sectionOpen, setSectionOpen] = useState<boolean>(false);

  return (
    <div
      className={classNames('parameters-column', {
        'parameters-column--section-open': sectionOpen,
      })}
    >
      {selectedWindow?.nrwg && <NRWGDefinitionSection />}
      <ParameterSection
        {...SASH_CONFIGURATIONS}
        sectionOpenCallBack={setSectionOpen}
      />
      <ParameterSection
        {...DRIVE_CONFIGURATIONS}
        sectionOpenCallBack={setSectionOpen}
      />
      {(!selectedWindow || selectedWindow?.rwa) && (
        <ParameterSection
          {...GEOMETRIC_SMOKE_EXTRACTION_CONFIGURATION}
          sectionOpenCallBack={setSectionOpen}
        />
      )}
    </div>
  );
};

export function getValue(
  value: number | undefined,
  selectedWindow: Window | undefined,
): number | undefined {
  if (selectedWindow?.nrwg) {
    return value ? parseInt(value.toString()) : undefined;
  }

  return value ? parseInt(value.toString()) : 0;
}

export default ParametersColumn;
