import { combineReducers, Reducer } from 'redux';
import { intlReducer } from 'react-intl-redux';
import parametersReducer from './parametersReducer';
import uiStateReducer from './uiStateReducer';
import calculationResultReducer from './calculationResultReducer';
import staticDataReducer from './staticDataReducer';
import desmokingResultReducer from './desmokingResultReducer';
import profileDataReducer from './profileDataReducer';
import projectsReducer from './projectsReducer';
import nrwgReducer from './nrwgReducer';
import globalUiStateReducer from './globalUiStateReducer';
import authenticationReducer from './authenticationReducer';

const appReducer: Reducer = combineReducers({
  parameters: parametersReducer,
  profileData: profileDataReducer,
  ui: uiStateReducer,
  authentication: authenticationReducer,
  globalUIState: globalUiStateReducer,
  intl: intlReducer,
  staticData: staticDataReducer,
  calculationResult: calculationResultReducer,
  desmoking: desmokingResultReducer,
  projects: projectsReducer,
  nrwg: nrwgReducer,
});

export default appReducer;
