import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import '../../app/App.scss';
import './ProjectDialog.scss';
import { createProject, updateProject } from '../../redux/projectsActions';
import { IState, MyCalcThunkDispatch } from '../../redux/store';
import { Locale } from '../../redux/uiStateReducer';
import {
  MenuPlacement,
  SelectField,
  SelectOption,
} from '../../elements/SelectField';
import { DialogFE } from '../Dialog';
import FormLayout from '../../elements/FormLayout';
import InputField from '../../elements/InputField';
import TextArea from '../../elements/TextArea';
import { FormattedMessage, useIntl } from 'react-intl';
import { ShallowProject } from '../../redux/projectsReducer';
import { RequestTypes } from '../../redux/httpClient';
import { CountryOption, LANGUAGE_LISTS } from '../../redux/constants';
import { showDialog } from '../../redux/uiStateActions';
import { AnyAction } from 'redux';

export function countryOptionToSelectOption(
  countryOption: CountryOption,
): SelectOption<string> {
  return { value: countryOption?.alpha2, label: countryOption?.name };
}

interface ProjectDialogProps {
  editedProject: ShallowProject | undefined;
  editMode: boolean;
}

export const ProjectDialog: FC<React.PropsWithChildren<ProjectDialogProps>> = (
  props: ProjectDialogProps,
) => {
  const locale: Locale = useSelector(
    (state: IState) => state.authentication.locale,
  );
  const [projectName, setProjectName] = useState('');
  const [description, setDescription] = useState('');
  const [country, setCountry] = useState('');
  const { formatMessage } = useIntl();

  const LANGUAGELIST = LANGUAGE_LISTS[locale];
  const postRequestNotFinished = useSelector<IState, boolean>(
    (state: IState) => state.ui.calculating[RequestTypes.CREATE_PROJECT],
  );
  const dispatch: MyCalcThunkDispatch<AnyAction> = useDispatch();

  useEffect(() => {
    if (props.editMode) {
      if (props.editedProject) {
        setProjectName(props.editedProject?.name);
        setDescription(props.editedProject?.description);
        setCountry(props.editedProject?.country);
      }
    }
  }, [props.editedProject, props.editMode]);

  function resetInputFields(): void {
    setProjectName('');
    setCountry('');
    setDescription('');
  }

  function createNewProject(): void {
    dispatch(showDialog(undefined));
    dispatch(
      createProject({
        name: projectName,
        description: description,
        country: country,
      }),
    );
    resetInputFields();
  }

  function editProject(): void {
    dispatch(showDialog(undefined));
    dispatch(
      updateProject(props.editedProject!.id, projectName, description, country),
    );
    resetInputFields();
  }

  function saveActionDisabled(): boolean {
    return !projectName || !country || postRequestNotFinished;
  }

  return (
    <DialogFE
      heading={props.editMode ? 'EDIT_PROJECT' : 'NEW_PROJECT'}
      componentClass="project-dialog"
      footerProps={{
        cancelAction: true,
        saveAction: saveActionDisabled()
          ? undefined
          : props.editMode
          ? editProject
          : createNewProject,
        primaryActionLabel: props.editMode
          ? 'calculations_button_save'
          : 'CREATE_PROJECT_LABEL',
      }}
    >
      <FormLayout
        additionalClass="project-dialog"
        // key={props.dialogIsShown.toString()}
      >
        <InputField
          additionalClass="project-dialog__name"
          label={formatMessage({
            id: 'LABEL_PROJECT_NAME_INPUT',
          })}
          placeholder={formatMessage({
            id: 'PLACEHOLDER_PROJECT_NAME_INPUT',
          })}
          value={projectName}
          id="projectName"
          onChange={(p: string) => {
            setProjectName(p);
          }}
        />

        <TextArea
          additionalClass="project-dialog__description"
          label={formatMessage({
            id: 'vorlage_description',
          })}
          placeholder={formatMessage({
            id: 'PLACEHOLDER_PROJECT_DESCRIPTION_INPUT',
          })}
          value={description}
          id="description"
          onChange={setDescription}
          size={10}
        />
        <SelectField
          menuPlacement={MenuPlacement.TOP}
          additionalClass="project-dialog__location"
          key={country}
          label={<FormattedMessage id="PROJECT_LOCATION" />}
          value={
            country
              ? countryOptionToSelectOption(
                  LANGUAGELIST.find(l => l.alpha2 === country) as CountryOption,
                )
              : undefined
          }
          action={newValue => {
            setCountry(newValue.value);
          }}
          options={LANGUAGELIST.map(countryOptionToSelectOption)}
          name="project-country"
          searchable={true}
          placeholder={formatMessage({
            id: 'PLACEHOLDER_PROJECT_COUNTRY_INPUT',
          })}
        />
      </FormLayout>
    </DialogFE>
  );
};
