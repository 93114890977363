import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import './Footer.scss';
import { triggerAnalyticsEvent } from '../../google_analytics/events';
import { ReactComponent as IconNewsletter } from '../../assets/icons/NewsletterIcon.svg';
import { showDialog } from '../../redux/uiStateActions';
import PrivacyPolicyModal from '../../privacy-policy/PrivacyPolicy';
import { useDispatch } from 'react-redux';

const FooterContactInformation: FC<React.PropsWithChildren<unknown>> = () => {
  return (
    <div className="footer__contact-information">
      <span>
        <div className="footer__contact-information-text">
          <FormattedMessage id={'NEWSLETTER'} />
        </div>
        <a
          className="footer__contact-link"
          href="/newsletter.html"
          target="_blank"
          rel="noopener noreferrer"
          onClick={() => triggerAnalyticsEvent('salesRequest', 'userContact')}
        >
          <div className="footer__contact-information-icon">
            <IconNewsletter />
          </div>
          <FormattedMessage id={'SUBSCRIBE'} />
        </a>
      </span>
    </div>
  );
};

const FooterLegalInformation: FC<React.PropsWithChildren<unknown>> = () => {
  const dispatch = useDispatch();

  return (
    <div className="footer__legal-information">
      © {new Date().getFullYear()} D+H Mechatronic AG |{' '}
      <span
        className="footer__legal-link"
        onClick={() => {
          dispatch(showDialog(<PrivacyPolicyModal />));
        }}
      >
        <FormattedMessage id={'fenster_info_privacydeclaration_title'} />
      </span>{' '}
      |{' '}
      <a
        className="footer__legal-link"
        href="https://www.dh-partner.com/impressum.html"
        target="_blank"
        rel="noopener noreferrer"
      >
        <FormattedMessage id={'client_menu_navigateToImpressum'} />
      </a>
      {' | '}
      <span className="footer__legal-link-extended">
        {' '}
        <a
          className="footer__legal-link"
          href="https://www.dh-partner.com/verkaufs-und-lieferbedingungen.html"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FormattedMessage id={'FOOTER_TERMS_SALE_DELIVERY'} />
        </a>{' '}
        |{' '}
      </span>
    </div>
  );
};

const Footer: FC<React.PropsWithChildren<unknown>> = () => {
  return (
    <footer className="footer">
      <FooterLegalInformation />
    </footer>
  );
};

export default Footer;
