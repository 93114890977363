import React, { FC, useEffect, useState } from 'react';
import FormLayout from '../../elements/FormLayout';
import InputField, { InputFieldNumber } from '../../elements/InputField';
import TextArea from '../../elements/TextArea';
import { Switch } from '../../elements/Switch';
import './FacadeExchangeProfileData.scss';
import { SelectField } from '../../elements/SelectField';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Route, Routes } from 'react-router-dom';
import Dialog from '../../components/Dialog';
import Table from '../components/Table';
import TableHeader from '../elements/TableHeader';
import TableRow from '../elements/TableRow';
import { AdminState, AdminThunkDispatch } from '../../redux/admin/adminStore';
import { ExchangeProfile, Series } from '../../redux/admin/adminFacadeReducer';
import SearchField from '../../elements/SearchField';
import { ProfileDataDialogBasicProps, UiConstants } from '../constants';
import Pagination from '../components/Pagination';
import {
  changeExchangeProfile,
  createExchangeProfile,
} from '../../redux/admin/adminFacadeActions';
import { fieldsFilled, getSeriesForProfileId } from '../general/helpers';
import { useValidateProfileArticleNumber } from './FacadeSashProfileDataView';
import { AnyAction } from 'redux';
import AdminNavLink from '../common/AdminNavLink';
import { useAdminSearch } from '../hooks';
import { downloadListAsCSV } from '../common/download';

const ExchangeProfileDataDialogTabNav: FC<
  React.PropsWithChildren<unknown>
> = () => {
  return (
    <div className="admin__tab-nav exchange-profile-data-dialog-tab-nav">
      <AdminNavLink to="/admin/facade/profiles/exchange-profiles/basic">
        <Routes>
          <Route
            path="/"
            element={
              <Navigate
                replace
                to="/admin/facade/profiles/exchange-profiles/basic"
              />
            }
          />
        </Routes>
        Allgemein
      </AdminNavLink>
      <AdminNavLink to="/admin/facade/profiles/exchange-profiles/basic-information">
        Eckdaten
      </AdminNavLink>
    </div>
  );
};

const ExchangeProfileDataDialogBasic: FC<
  React.PropsWithChildren<ProfileDataDialogBasicProps>
> = (props: ProfileDataDialogBasicProps) => {
  const articleNumberError = useValidateProfileArticleNumber(
    s => s.adminFacade.exchangeProfiles,
    props.series,
    props.artNr,
    props.id,
  );

  return (
    <div className="">
      <FormLayout additionalClass="exchange-profile-data-dialog__layout-basic">
        <InputField
          label="Artikelnummer*"
          placeholder="Artikelnummer des Blendrahmen"
          value={props.artNr}
          additionalClass="exchange-profile-data-dialog__article-nr"
          onChange={props.setArtNr}
          errorMessage={articleNumberError}
        />
        <TextArea
          label="Information"
          additionalClass="exchange-profile-data-dialog__info"
          placeholder="Informationen zum System"
          value={props.beschreibung}
          onChange={props.setBeschreibung}
        />
        <SelectField
          additionalClass="exchange-profile-data-dialog__serie"
          key={props.selectedExchangeProfile?.id}
          label="Serie *"
          value={
            props.series
              ? {
                  value: props.series,
                  label: props.series!.name,
                }
              : undefined
          }
          action={newValue => {
            props.setSeries(newValue.value);
          }}
          options={props.allSeries.map(series => ({
            label: series.name,
            value: series,
          }))}
          name="seriesOfExchangeProfile"
          searchable={true}
          placeholder={UiConstants.SELECT_SERIES}
        />
        <div className="exchange-profile-data-dialog__switch">
          <Switch
            labelText={
              props.series?.active
                ? 'aktiv'
                : 'Aktivierung nicht möglich. Serie ' +
                  props.series?.name +
                  ' muss aktiv sein.'
            }
            turnedOn={props.isActive}
            onChange={props.setIsActive}
          />
        </div>
        <div className="exchange-profile-data-dialog__preview">
          <Switch
            labelText="Vorschau"
            turnedOn={props.isPreview}
            onChange={props.setIsPreview}
          />
        </div>
      </FormLayout>
    </div>
  );
};

interface ExchangeProfileDataDialogBasicInformationProps {
  kammermassInnen: number | null | undefined;
  setKammermassInnen: (i: number | null) => void;
  kammermassAussen: number | null | undefined;
  setKammermassAussen: (i: number | null) => void;
  bautiefe: number | null | undefined;
  setBautiefe: (i: number | null) => void;
}

const ExchangeProfileDataDialogBasicInformation: FC<
  React.PropsWithChildren<ExchangeProfileDataDialogBasicInformationProps>
> = (props: ExchangeProfileDataDialogBasicInformationProps) => {
  return (
    <div>
      <FormLayout additionalClass="exchange-profile-data-dialog__layout-basic-information">
        <InputFieldNumber
          label="Kammermaß innen (mm)*"
          placeholder="z.B. 42"
          value={props.kammermassInnen}
          additionalClass="exchange-profile-data-dialog__measurement-inside"
          onChange={props.setKammermassInnen}
        />
        <InputFieldNumber
          label="Kammermaß außen (mm)*"
          placeholder="z.B. 42"
          value={props.kammermassAussen}
          additionalClass="exchange-profile-data-dialog__measurement-outside"
          onChange={props.setKammermassAussen}
        />

        <InputFieldNumber
          label="Bautiefe (mm)"
          placeholder="z.B. 42"
          value={props.bautiefe}
          additionalClass="exchange-profile-data-dialog__building-depth"
          onChange={props.setBautiefe}
        />
      </FormLayout>
    </div>
  );
};

interface AdminExchangeProfileDialogProps {
  setDialogIsShown: (b: boolean) => void;
  dialogIsShown: boolean;
  allSeries: Series[];
  selectedExchangeProfile: ExchangeProfile | undefined;
  activeSwitchEnabled: boolean;
}
const AdminExchangeProfileDialog: FC<
  React.PropsWithChildren<AdminExchangeProfileDialogProps>
> = (props: AdminExchangeProfileDialogProps) => {
  const dispatch: AdminThunkDispatch<AnyAction> = useDispatch();
  const [artNr, setArtNr] = useState(
    props.selectedExchangeProfile?.artNr || '',
  );
  const [isActive, setIsActive] = useState(
    props.selectedExchangeProfile?.active || false,
  );
  const [isPreview, setIsPreview] = useState(
    props.selectedExchangeProfile?.active ||
      (props.selectedExchangeProfile?.active === false ? false : true),
  );
  const [serie, setSerie] = useState(
    getSeriesForProfileId(
      props.selectedExchangeProfile?.seriesId,
      props.allSeries,
    ),
  );

  if (props.selectedExchangeProfile && !serie) {
    setSerie(
      getSeriesForProfileId(
        props.selectedExchangeProfile?.seriesId,
        props.allSeries,
      ),
    );
  }

  const [beschreibung, setBeschreibung] = useState(
    props.selectedExchangeProfile?.beschreibung || '',
  );
  const [kammermassInnen, setKammermassInnen] = useState<
    number | undefined | null
  >(props.selectedExchangeProfile?.kammermassInnen);
  const [kammermassAussen, setKammermassAussen] = useState<
    number | undefined | null
  >(props.selectedExchangeProfile?.kammermassAussen);
  const [bautiefe, setBautiefe] = useState<number | undefined | null>(
    props.selectedExchangeProfile?.bautiefe,
  );
  const articleNumberError = useValidateProfileArticleNumber(
    s => s.adminFacade.exchangeProfiles,
    serie,
    artNr,
    props.selectedExchangeProfile?.id,
  );

  useEffect(() => {
    resetInputFields();
  }, [props.selectedExchangeProfile]);

  function resetInputFields(): void {
    setIsActive(props.selectedExchangeProfile?.active || false);
    setIsPreview(
      props.selectedExchangeProfile?.preview ||
        (props.selectedExchangeProfile?.preview === false ? false : true),
    );
    setArtNr(props.selectedExchangeProfile?.artNr || '');
    setBeschreibung(props.selectedExchangeProfile?.beschreibung || '');
    setSerie(
      getSeriesForProfileId(
        props.selectedExchangeProfile?.seriesId,
        props.allSeries,
      )! || undefined,
    );
    setKammermassInnen(props.selectedExchangeProfile?.kammermassInnen);
    setKammermassAussen(props.selectedExchangeProfile?.kammermassAussen);
    setBautiefe(props.selectedExchangeProfile?.bautiefe);
  }

  function editExchangeProfile(): void {
    dispatch(
      changeExchangeProfile({
        ...props.selectedExchangeProfile,
        seriesId: serie!.id,
        series: serie,
        kammermassAussen: kammermassAussen ? kammermassAussen : 0,
        artNr: artNr,
        beschreibung: beschreibung,
        kammermassInnen: kammermassInnen ? kammermassInnen : 0,
        active: isActive,
        preview: isPreview,
        bautiefe: bautiefe,
        dbType: 'Fassade',
      }),
    );
  }

  function newExchangeProfile(): void {
    dispatch(
      createExchangeProfile({
        id: undefined,
        seriesId: serie!.id,
        series: serie,
        kammermassAussen: kammermassAussen ? kammermassAussen : 0,
        artNr: artNr,
        beschreibung: beschreibung,
        kammermassInnen: kammermassInnen ? kammermassInnen : 0,
        active: isActive,
        preview: isPreview,
        bautiefe: bautiefe,
        dbType: 'Fassade',
      }),
    );
  }

  function mandatoryFieldsFilled(): boolean {
    return (
      fieldsFilled(artNr, serie, kammermassInnen, kammermassAussen) &&
      !articleNumberError
    );
  }

  return (
    <Dialog
      setDialogIsShown={props.setDialogIsShown}
      dialogIsShown={props.dialogIsShown}
      headingText={
        props.selectedExchangeProfile === undefined
          ? 'Neues Wechselprofil anlegen'
          : `Wechselprofil ${props.selectedExchangeProfile.artNr} bearbeiten`
      }
      componentClass="full-view-dialog"
      key={props.dialogIsShown.toString()}
      footerProps={{
        notTranslated: true,
        cancelAction: () => {
          resetInputFields();
          props.setDialogIsShown(false);
        },
        saveAction: mandatoryFieldsFilled()
          ? () => {
              props.selectedExchangeProfile
                ? editExchangeProfile()
                : newExchangeProfile();
              props.setDialogIsShown(false);
            }
          : undefined,
        primaryActionLabelText:
          props.selectedExchangeProfile === undefined
            ? UiConstants.ADD
            : UiConstants.SAVE,
      }}
    >
      <ExchangeProfileDataDialogTabNav />
      <Routes>
        <Route
          path="/basic/*"
          element={
            <ExchangeProfileDataDialogBasic
              selectedExchangeProfile={props.selectedExchangeProfile}
              allSeries={props.allSeries}
              setIsActive={setIsActive}
              isActive={isActive}
              setIsPreview={setIsPreview}
              isPreview={isPreview}
              artNr={artNr}
              setArtNr={setArtNr}
              beschreibung={beschreibung}
              setBeschreibung={setBeschreibung}
              series={serie}
              setSeries={setSerie}
              id={props.selectedExchangeProfile?.id}
            />
          }
        />
        <Route
          path="/basic-information/*"
          element={
            <ExchangeProfileDataDialogBasicInformation
              kammermassInnen={kammermassInnen}
              setKammermassInnen={setKammermassInnen}
              kammermassAussen={kammermassAussen}
              setKammermassAussen={setKammermassAussen}
              bautiefe={bautiefe}
              setBautiefe={setBautiefe}
            />
          }
        />
      </Routes>
    </Dialog>
  );
};

const FacadeExchangeProfileData: FC<React.PropsWithChildren<unknown>> = () => {
  const dispatch: AdminThunkDispatch<AnyAction> = useDispatch();
  const [dialogIsShown, setDialogIsShown] = useState(false);
  const [searchString, setSearchString] = useState('');
  const allSeries = useSelector<AdminState, Series[]>(
    state => state.adminFacade.series,
  );
  const [selectedExchangeProfileID, setSelectedExchangeProfileID] = useState<
    undefined | number
  >(undefined);
  const exchangeProfiles = useSelector<AdminState, ExchangeProfile[]>(
    state => state.adminFacade.exchangeProfiles,
  );
  const [filterActive, setFilterActive, searchResult] = useAdminSearch(
    exchangeProfiles,
    searchString,
    ['artNr'],
    entry => {
      const series = getSeriesForProfileId(entry.seriesId, allSeries);
      return [series?.name || '', series?.system.name || ''];
    },
  );

  const selectedExchangeProfile = exchangeProfiles.find(
    b => b.id === selectedExchangeProfileID || undefined,
  );
  const [indexOfFirstPageElement, setIndexOfFirstPageElement] = useState(0);
  const [page, setPage] = useState(1);

  function getCurrentTableContent(): ExchangeProfile[] {
    return searchResult.slice(
      indexOfFirstPageElement,
      indexOfFirstPageElement + 20,
    );
  }
  function enableEditExchangeProfile(exchangeProfile: ExchangeProfile): void {
    setSelectedExchangeProfileID(exchangeProfile.id);
    setDialogIsShown(true);
  }
  function triggerCreationMode(): void {
    setDialogIsShown(true);
    setSelectedExchangeProfileID(undefined);
  }
  function parentSeriesIsActive(exchangeProfile: ExchangeProfile): boolean {
    return (
      allSeries.find(series => series.id === exchangeProfile.seriesId)
        ?.active || false
    );
  }

  return (
    <>
      <AdminExchangeProfileDialog
        setDialogIsShown={setDialogIsShown}
        dialogIsShown={dialogIsShown}
        allSeries={allSeries}
        selectedExchangeProfile={selectedExchangeProfile}
        activeSwitchEnabled={
          (selectedExchangeProfile &&
            parentSeriesIsActive(selectedExchangeProfile)) ||
          false
        }
      />
      <div className="sub-header">
        <div className="sub-header__title"> Wechselprofil</div>
        <SearchField
          setSearchString={setSearchString}
          searchString={searchString}
          placeholderText="Wechselprofile suchen..."
          small={true}
          setFilterActive={setFilterActive}
          filterActive={filterActive}
        />
        <button
          className="sub-header__download-button"
          onClick={() =>
            downloadListAsCSV(
              searchResult,
              ';',
              ['legacyId'],
              'WechselprofileFassade',
              { series: (s: Series) => s.name },
              { system: profile => profile.series.system.name },
            )
          }
        >
          CSV
        </button>
        <button
          className="sub-header__button sub-header__button--add"
          onClick={() => triggerCreationMode()}
        >
          {UiConstants.NEW_ENTRY}
        </button>
      </div>
      <div className="exchange-profile-data">
        <Table>
          <TableHeader>
            <th>Artikelnummer</th>
            <th>Beschreibung</th>
            <th>Bautiefe (mm)</th>
            <th>System</th>
            <th>Serie</th>
            <th>Aktiv</th>
            <th>Aktion</th>
          </TableHeader>
          <tbody>
            {getCurrentTableContent().map(exchangeProfile => (
              <TableRow key={exchangeProfile.artNr}>
                <td>{exchangeProfile.artNr}</td>
                <td>{exchangeProfile.beschreibung}</td>
                <td>{exchangeProfile.bautiefe}</td>
                <td>
                  {
                    getSeriesForProfileId(exchangeProfile.seriesId, allSeries)
                      ?.system.name
                  }
                </td>
                <td>
                  {
                    getSeriesForProfileId(exchangeProfile.seriesId, allSeries)
                      ?.name
                  }
                </td>
                <td>
                  <Switch
                    turnedOn={
                      parentSeriesIsActive(exchangeProfile)
                        ? exchangeProfile.active
                        : false
                    }
                    onChange={() =>
                      parentSeriesIsActive(exchangeProfile)
                        ? dispatch(
                            changeExchangeProfile({
                              ...exchangeProfile,
                              active: !exchangeProfile.active,
                            }),
                          )
                        : null
                    }
                  />
                </td>
                <td>
                  <button
                    onClick={() => {
                      enableEditExchangeProfile(exchangeProfile);
                    }}
                  >
                    {UiConstants.EDIT}
                  </button>
                </td>
              </TableRow>
            ))}
          </tbody>
        </Table>
      </div>
      <Pagination
        searchString={searchString}
        numberOfPages={searchResult.length}
        page={page}
        setPage={setPage}
        indexOfFirstPageElement={indexOfFirstPageElement}
        setIndexOfFirstPageElement={setIndexOfFirstPageElement}
      />
    </>
  );
};
export default FacadeExchangeProfileData;
