import React, { FC, ReactNode } from 'react';
import './Table.scss';
import classNames from 'classnames';

interface TableProps {
  stickyFirstColumn?: boolean;
  children: ReactNode;
  subNavigation?: boolean;
  maxHeight?: string;
  maxWidth?: string;
}

const Table: FC<React.PropsWithChildren<TableProps>> = (props: TableProps) => {
  return (
    <div
      className={classNames('table-container', {
        'table-container--without-sub-navigation': !props.subNavigation,
      })}
      style={{
        maxHeight: props.maxHeight,
        maxWidth: props.maxWidth,
        position: 'relative',
      }}
    >
      <table
        className={classNames('table', {
          'table--sticky-first-column': props.stickyFirstColumn,
        })}
      >
        {props.children}
      </table>
    </div>
  );
};

export default Table;
