import React, { Fragment, ReactElement } from 'react';
import EinzelklappeKippMit2Wlw from './EinzelklappeKippMit2Wlw';
import EinzelklappeKippOhneWlw from './EinzelklappeKippOhneWlw';
import EinzelklappeKippMit3Wlw from './EinzelklappeKippMit3Wlw';
import EinzelklappeKlappMit3Wlw from './EinzelklappeKlappMit3Wlw';
import DoppelklappePultdachOhneWlw from './DoppelklappePultdachOhneWlw';
import DoppelklappePultdachMitWlw from './DoppelklappePultdachMitWlw';
import DoppelklappeKippKippSatteldachOhneWlw from './DoppelklappeKippKippSatteldachOhneWlw';
import DoppelklappeKippKippSatteldachMitWlw from './DoppelklappeKippKippSatteldachMitWlw';
import DoppelklappeDrehOhneWlw from './DoppelklappeDrehOhneWlw';
import DoppelklappeDrehMit1WlwSatteldach from './DoppelklappeDrehMit1WlwSatteldach';
import DoppelklappeDrehMit2WlwSatteldach from './DoppelklappeDrehMit2WlwSatteldach';
import DoppelklappeDrehMit1WlwPultdach from './DoppelklappeDrehMit1WlwPultdach';
import DoppelklappeDrehMit2Wlw from './DoppelklappeDrehMit2Wlw';

export const TRANSLATION = {
  de: {
    heightH: 'H',
    widthB: 'B',
    frameFR: 'FR',
    distanceNRWGToRidgeFA: 'FA',
    distanceNRWGToEavesTA: 'TA',
    doubleFlapFRZK: 'FRZK',
    distanceFlapUpperEdgeToRidgeFA: 'F',
    sashFrameDistanceFRA: 'FRA',
    windDeflectorWD: 'WLW',
  },
  en: {
    heightH: 'H',
    widthB: 'W',
    frameFR: 'SF',
    distanceNRWGToRidgeFA: 'FA',
    distanceNRWGToEavesTA: 'TA',
    doubleFlapFRZK: 'DF',
    distanceFlapUpperEdgeToRidgeFA: 'F',
    sashFrameDistanceFRA: 'FRA',
    windDeflectorWD: 'WD',
  },
};

export function windowAreaHelpImage(
  application: string,
  numberOfWindDeflectors: number | null,
  locale: string,
): ReactElement | '' {
  if (application === 'SINGLE_FLAP') {
    if (numberOfWindDeflectors === 0) {
      return (
        <EinzelklappeKippOhneWlw
          {...TRANSLATION[getImageTranslationLocale(locale)]}
        />
      );
    }
    if (numberOfWindDeflectors === 2) {
      return (
        <EinzelklappeKippMit2Wlw
          {...TRANSLATION[getImageTranslationLocale(locale)]}
        />
      );
    }
    if (numberOfWindDeflectors === 3) {
      return (
        <EinzelklappeKippMit3Wlw
          {...TRANSLATION[getImageTranslationLocale(locale)]}
        />
      );
    }
  }
  if (application === 'SINGLE_FLAP_TOP_HUNG') {
    if (numberOfWindDeflectors === 3) {
      return (
        <EinzelklappeKlappMit3Wlw
          {...TRANSLATION[getImageTranslationLocale(locale)]}
        />
      );
    }
  }
  if (application === 'DOUBLE_FLAP_PENT_ROOF_BOTTOM_TOP_HUNG') {
    if (numberOfWindDeflectors === 0) {
      return (
        <DoppelklappePultdachOhneWlw
          {...TRANSLATION[getImageTranslationLocale(locale)]}
        />
      );
    }
    if (numberOfWindDeflectors && numberOfWindDeflectors === 2) {
      return (
        <DoppelklappePultdachMitWlw
          {...TRANSLATION[getImageTranslationLocale(locale)]}
        />
      );
    }
  }
  if (application === 'DOUBLE_FLAP_SADDLE_ROOF_BOTTOM_HUNG_BOTTOM_HUNG') {
    if (numberOfWindDeflectors === 0) {
      return (
        <DoppelklappeKippKippSatteldachOhneWlw
          {...TRANSLATION[getImageTranslationLocale(locale)]}
        />
      );
    }
    if (numberOfWindDeflectors && numberOfWindDeflectors === 2) {
      return (
        <DoppelklappeKippKippSatteldachMitWlw
          {...TRANSLATION[getImageTranslationLocale(locale)]}
        />
      );
    }
  }
  if (application === 'DOUBLE_FLAP_SADDLE_ROOF_SIDE_HUNG_SIDE_HUNG') {
    if (numberOfWindDeflectors === 0) {
      return (
        <DoppelklappeDrehOhneWlw
          {...TRANSLATION[getImageTranslationLocale(locale)]}
        />
      );
    }
    if (numberOfWindDeflectors && numberOfWindDeflectors === 1) {
      return (
        <DoppelklappeDrehMit1WlwSatteldach
          {...TRANSLATION[getImageTranslationLocale(locale)]}
        />
      );
    }
    if (numberOfWindDeflectors && numberOfWindDeflectors === 2) {
      return (
        <DoppelklappeDrehMit2WlwSatteldach
          {...TRANSLATION[getImageTranslationLocale(locale)]}
        />
      );
    }
  }
  if (application === 'DOUBLE_FLAP_PENT_ROOF_SIDE_HUNG_SIDE_HUNG') {
    if (numberOfWindDeflectors === 0) {
      return (
        <DoppelklappeDrehOhneWlw
          {...TRANSLATION[getImageTranslationLocale(locale)]}
        />
      );
    }
    if (numberOfWindDeflectors && numberOfWindDeflectors === 1) {
      return (
        <DoppelklappeDrehMit1WlwPultdach
          {...TRANSLATION[getImageTranslationLocale(locale)]}
        />
      );
    }
    if (numberOfWindDeflectors && numberOfWindDeflectors === 2) {
      return (
        <DoppelklappeDrehMit2Wlw
          {...TRANSLATION[getImageTranslationLocale(locale)]}
        />
      );
    }
  }

  return '';
}

export default function WindowAreaHelpImage(props: {
  application: string;
  numberOfWindDeflectors: number | null;
  locale: string;
}): ReactElement {
  return (
    windowAreaHelpImage(
      props.application,
      props.numberOfWindDeflectors,
      props.locale,
    ) || <Fragment></Fragment>
  );
}

export function getImageTranslationLocale(locale: string): 'de' | 'en' {
  return locale === 'de' ? locale : 'en';
}
