import React, { FC, useEffect, useState } from 'react';
import SearchField from '../../elements/SearchField';
import { UiConstants } from '../constants';
import './ConsoleAreaConsolesDataView.scss';
import { useDispatch, useSelector } from 'react-redux';
import Dialog from '../../components/Dialog';
import FormLayout from '../../elements/FormLayout';
import InputField from '../../elements/InputField';
import { Switch } from '../../elements/Switch';
import AdminRadioButton from '../../elements/RadioButton';
import { Console } from '../../redux/admin/adminFacadeReducer';
import { AdminState, AdminThunkDispatch } from '../../redux/admin/adminStore';
import {
  changeConsole,
  createConsole,
} from '../../redux/admin/adminFacadeActions';
import {
  containingInSearchString,
  fieldsFilled,
  getSeriesForProfileId,
} from '../general/helpers';
import TableRow from '../elements/TableRow';
import TableHeader from '../elements/TableHeader';
import Table from '../components/Table';
import { RangeOfApplication } from '../../redux/constants';
import Pagination from '../components/Pagination';
import { AnyAction } from 'redux';
import { useAdminSearch } from '../hooks';
import FormLayoutSubgroupTitle from '../../elements/FormLayoutSubgroupTitle';

interface AdminConsoleDialogProps {
  rangeOfApplication: RangeOfApplication;
  setDialogIsShown: (b: boolean) => void;
  dialogIsShown: boolean;
  selectedConsole: Console | undefined;
}

const AdminConsoleDialog: FC<
  React.PropsWithChildren<AdminConsoleDialogProps>
> = (props: AdminConsoleDialogProps) => {
  const dispatch: AdminThunkDispatch<AnyAction> = useDispatch();
  const [isActive, setIsActive] = useState(
    props.selectedConsole?.active || false,
  );
  const [name, setName] = useState(props.selectedConsole?.name || '');
  const [artNr, setArtNr] = useState(props.selectedConsole?.artNr || '');
  const [isFrameConsole, setIsFrameConsole] = useState(
    props.selectedConsole?.isFrameConsole || false,
  );
  const [isSashConsole, setIsSashConsole] = useState(
    props.selectedConsole?.isSashConsole || false,
  );

  useEffect(() => {
    resetInputFields();
  }, [props.selectedConsole]);

  function resetInputFields(): void {
    setName(props.selectedConsole?.name || '');
    setArtNr(props.selectedConsole?.artNr || '');
    setIsActive(props.selectedConsole?.active || false);
    setIsFrameConsole(props.selectedConsole?.isFrameConsole || false);
    setIsSashConsole(props.selectedConsole?.isSashConsole || false);
  }

  function mandatoryFieldsFilled(): boolean {
    return fieldsFilled(name, artNr, isFrameConsole, isSashConsole, isActive);
  }

  function editConsole(): void {
    if (props.selectedConsole)
      dispatch(
        changeConsole({
          ...props.selectedConsole,
          name: name,
          artNr: artNr,
          active: isActive,
          isSashConsole: isSashConsole,
          isFrameConsole: isFrameConsole,
        }),
      );
  }

  function generateConsole(): void {
    dispatch(
      createConsole({
        active: isActive,
        name: name,
        artNr: artNr,
        isFrameConsole: isFrameConsole,
        isSashConsole: isSashConsole,
        rangeOfApplication: props.rangeOfApplication,
      }),
    );
  }

  function selectFrameConsole(): void {
    setIsFrameConsole(true);
    setIsSashConsole(false);
  }

  function selectSashConsole(): void {
    setIsFrameConsole(false);
    setIsSashConsole(true);
  }

  return (
    <Dialog
      key={props.dialogIsShown.toString()}
      cancelAction={resetInputFields}
      setDialogIsShown={props.setDialogIsShown}
      dialogIsShown={props.dialogIsShown}
      componentClass="full-view-dialog"
      headingText={
        props.selectedConsole
          ? `Konsole ${props.selectedConsole.name} bearbeiten`
          : 'Neue Konsole anlegen'
      }
      footerProps={{
        notTranslated: true,
        cancelAction: () => {
          resetInputFields();
          props.setDialogIsShown(false);
        },
        saveAction: mandatoryFieldsFilled()
          ? () => {
              props.selectedConsole ? editConsole() : generateConsole();
              props.setDialogIsShown(false);
            }
          : undefined,
        primaryActionLabelText: props.selectedConsole ? 'Speichern' : 'Anlegen',
      }}
    >
      <FormLayout additionalClass="consoles-data-dialog__console-data">
        <InputField
          label="Artikelnummer *"
          placeholder="z.B. 42.424.42"
          value={artNr || ''}
          additionalClass="consoles-data-dialog__art-nr"
          onChange={setArtNr}
        />
        <InputField
          label="Bezeichnung *"
          placeholder="z.B. D&H's beste"
          value={name || ''}
          additionalClass="consoles-data-dialog__name"
          onChange={setName}
        />
        <div className="consoles-data-dialog__consoletyp">
          <FormLayoutSubgroupTitle>Profiltyp *</FormLayoutSubgroupTitle>
          <AdminRadioButton
            checked={isFrameConsole}
            name="Rahmenkonsole"
            onChange={selectFrameConsole}
          />
          <AdminRadioButton
            checked={isSashConsole}
            name="Flügelkonsole"
            onChange={selectSashConsole}
          />
        </div>
        <div className="consoles-data-dialog__switch">
          <Switch label="Aktiv" turnedOn={isActive} onChange={setIsActive} />
        </div>
      </FormLayout>
    </Dialog>
  );
};

interface ConsoleAreaConsolesDataViewProps {
  rangeOfApplication: RangeOfApplication;
}

const ConsoleAreaConsolesDataView: FC<
  React.PropsWithChildren<ConsoleAreaConsolesDataViewProps>
> = props => {
  const [dialogIsShown, setDialogIsShown] = useState(false);
  const consoles = useSelector<AdminState, Console[]>(state =>
    props.rangeOfApplication === RangeOfApplication.FACADE
      ? state.adminFacade.consoles
      : state.adminRoof.consoles,
  );
  const [editableConsoleID, setEditableConsoleID] = useState<
    undefined | number
  >(undefined);

  const [indexOfFirstPageElement, setIndexOfFirstPageElement] = useState(0);
  const [page, setPage] = useState(1);
  const [searchString, setSearchString] = useState('');
  const [filterActive, setFilterActive, searchResult] = useAdminSearch(
    consoles,
    searchString,
    ['artNr', 'name'],
  );

  const dispatch: AdminThunkDispatch<AnyAction> = useDispatch();

  const selectedConsole =
    consoles.find(con => con.id === editableConsoleID) || undefined;

  function enableEditConsole(console: Console): void {
    setDialogIsShown(true);
    setEditableConsoleID(console.id);
  }

  function switchActiveAction(con: Console): void {
    dispatch(changeConsole({ ...con, active: !con.active }));
  }

  function triggerCreationMode(): void {
    setDialogIsShown(true);
    setEditableConsoleID(undefined);
  }

  function getCurrentTableContent(): Console[] {
    return searchResult.slice(
      indexOfFirstPageElement,
      indexOfFirstPageElement + 20,
    );
  }

  return (
    <>
      <AdminConsoleDialog
        setDialogIsShown={setDialogIsShown}
        dialogIsShown={dialogIsShown}
        selectedConsole={selectedConsole}
        rangeOfApplication={props.rangeOfApplication}
      />
      <div className="sub-header">
        <div className="sub-header__title"> Konsole</div>

        <SearchField
          setSearchString={setSearchString}
          searchString={searchString}
          placeholderText="Konsole suchen..."
          small={true}
          setFilterActive={setFilterActive}
          filterActive={filterActive}
        />
        <button
          className="sub-header__button sub-header__button--add"
          onClick={() => triggerCreationMode()}
        >
          {UiConstants.NEW_ENTRY}
        </button>
      </div>

      <div className="facade-console-data">
        <Table subNavigation={true}>
          <TableHeader>
            <th>Artikelnummer</th>
            <th>Bezeichnung</th>
            <th>Aktiv</th>
            <th>Aktion</th>
          </TableHeader>
          <tbody>
            {getCurrentTableContent().map(c => (
              <TableRow>
                <td>{c.artNr}</td>
                <td>{c.name}</td>
                <td>
                  <Switch
                    key={c.id}
                    turnedOn={c.active}
                    onChange={(b: boolean) => {
                      switchActiveAction(c);
                    }}
                  />
                </td>
                <td>
                  <button onClick={() => enableEditConsole(c)}>
                    {UiConstants.EDIT}
                  </button>
                </td>
              </TableRow>
            ))}
          </tbody>
        </Table>
      </div>
      <Pagination
        searchString={searchString}
        numberOfPages={searchResult.length}
        page={page}
        setPage={setPage}
        indexOfFirstPageElement={indexOfFirstPageElement}
        setIndexOfFirstPageElement={setIndexOfFirstPageElement}
      />
    </>
  );
};

export default ConsoleAreaConsolesDataView;
