import { Provider, useSelector } from 'react-redux';
import { IState } from './redux/store';
import { Locale } from './redux/uiStateReducer';
import { IntlProvider } from 'react-intl-redux';
import { Store } from 'redux';
import React, { ReactElement, ReactNode } from 'react';

function MyCalcIntlProvider(props: { children: ReactNode }): ReactElement {
  const locale = useSelector<IState, Locale>(
    state => state.authentication.locale,
  );

  return (
    // @ts-ignore
    <IntlProvider locale={locale.toString()}>{props.children}</IntlProvider>
  );
}

export default function MyCalcProvider(props: {
  store: Store;
  children: ReactNode;
}): ReactElement {
  return (
    <Provider store={props.store}>
      <MyCalcIntlProvider>{props.children}</MyCalcIntlProvider>
    </Provider>
  );
}
