import React, { FC, useEffect, useState } from 'react';
import InputNumber from 'rc-input-number';
import classNames from 'classnames';
import { ParameterBoxRange } from '../ParametersColumn';
import './NumberField.scss';
import { useDispatch, useSelector } from 'react-redux';
import { updateInputfieldFocus } from '../../../redux/uiStateActions';
import { resetCalculationResults } from '../../../redux/calculationResultActions';
import { ValueKey } from '../../../redux/valueKey';
import { Locale } from '../../../redux/uiStateReducer';
import { IState, MyCalcThunkDispatch } from '../../../redux/store';
import { useSelectedWindow } from '../../../hooks/selectorHooks';
import { FormattedMessage } from 'react-intl';
import { AnyAction } from 'redux';

const upHandler = (
  <div className="number-field__input-field-icon number-field__input-field-icon--top">
    arrow_drop_up
  </div>
);
const downHandler = (
  <div className="number-field__input-field-icon number-field__input-field-icon--bottom">
    arrow_drop_down
  </div>
);

interface NumberFieldProps {
  range: ParameterBoxRange;
  name: string;
  value: number | undefined;
  onChange: (newValue: string) => void;
  disabledByUIState?: boolean;
  valueKey: ValueKey;
  focused?: boolean;
  directValueChanges?: boolean;
  dontResetWhenOutOfRange?: boolean;
  decimal?: boolean;
  unit?: string;
  hideRange?: boolean;
  small?: boolean;
}

const NumberField: FC<React.PropsWithChildren<NumberFieldProps>> = (
  props: NumberFieldProps,
) => {
  const [focused, setFocused] = useState(props.focused);
  const dispatch: MyCalcThunkDispatch<AnyAction> = useDispatch();
  const [internalValue, setInternalValue] = useState(
    props.value?.toString() || '',
  );
  const locale = useSelector<IState, Locale>(s => s.authentication.locale);
  const selectedWindow = useSelectedWindow();
  const focusedField = useSelector((store: IState) => store.ui.focusedField);

  useEffect(() => {
    (document.querySelector(`#${props.name}`) as HTMLInputElement).select();
  }, []);

  function disabled(): boolean {
    if (props.disabledByUIState) {
      return true;
    }

    return (
      !selectedWindow?.nrwg &&
      props.range.length === 2 &&
      props.range[0] === props.range[1]
    );
  }

  function enforceRange(
    value: number | string,
    range: ParameterBoxRange,
  ): number | string {
    if (selectedWindow?.nrwg) {
      return value;
    }

    const numberValue = +value;

    if (range.length !== 2) {
      return numberValue;
    }

    if (!numberValue) {
      return range[0];
    }

    if (numberValue > range[1]) {
      return range[1];
    }

    if (numberValue < range[0]) {
      return range[0];
    }

    return numberValue;
  }

  function withinRange(value: string | number | undefined | null): boolean {
    if (selectedWindow?.nrwg) {
      return true;
    }

    if (typeof value === 'string' && value.trim() === '') {
      return false;
    }

    if (value === undefined || value === null) {
      return false;
    }

    if (props.range.length !== 2) {
      return true;
    }

    const numberValue = +value;

    return numberValue >= props.range[0] && numberValue <= props.range[1];
  }

  function getValue(): string {
    const stringValue = props.value?.toString() || '';

    if (stringValue === 'NaN') {
      return '';
    }

    return disabled() || props.directValueChanges ? stringValue : internalValue;
  }

  function onBlur(): void {
    setFocused(false);
    const correctedValue = enforceRange(internalValue, props.range).toString();
    setInternalValue(correctedValue);
    dispatch(updateInputfieldFocus(ValueKey.VALUEKEY_NONE));
  }

  function removeNonDigitsIfNeeded(value: string | number): string {
    if (props.decimal) {
      return value.toString();
    }

    return typeof value === 'string'
      ? value.replace(/\D/, '')
      : value.toString();
  }

  function onChange(value: string | number | undefined | null): void {
    if (value?.toString() === getValue().toString()) {
      return;
    }

    let valueWithoutDigits = removeNonDigitsIfNeeded(value?.toString() || '');

    if (value !== undefined && value !== null) {
      setInternalValue(removeNonDigitsIfNeeded(value));
    }

    if (props.directValueChanges) {
      valueWithoutDigits = enforceRange(
        valueWithoutDigits,
        props.range,
      ).toString();
    }

    if (withinRange(valueWithoutDigits)) {
      props.onChange(removeNonDigitsIfNeeded(valueWithoutDigits));
    } else if (!props.dontResetWhenOutOfRange) {
      dispatch(resetCalculationResults());
    }
  }

  function onFocus(): void {
    setFocused(true);
    dispatch(updateInputfieldFocus(props.valueKey));
    (document.querySelector(`#${props.name}`) as HTMLInputElement).select();
  }

  return (
    <div
      className={classNames('number-field', {
        'number-field--focused': focused,
        'number-field--disabled': disabled(),
        'number-field--small': props.small,
      })}
    >
      <InputNumber
        disabled={disabled()}
        prefixCls="number-field__input"
        min={selectedWindow?.nrwg ? undefined : props.range[0]}
        max={selectedWindow?.nrwg ? undefined : props.range[1]}
        value={getValue()}
        upHandler={!disabled() && upHandler}
        downHandler={!disabled() && downHandler}
        name={props.name}
        id={props.name}
        onBlur={onBlur}
        onChange={onChange}
        onFocus={onFocus}
        step={props.decimal ? 0.01 : 1}
        decimalSeparator={locale === Locale.DE ? ',' : '.'}
        precision={props.decimal ? 2 : 0}
      />

      {!props.hideRange &&
      props.range &&
      props.range.length === 2 &&
      (props.range[0] !== props.range[1] || selectedWindow?.nrwg) ? (
        <div
          className={classNames('editable-box__range', {
            'editable-box__range editable-box__range--active':
              focusedField === props.valueKey,
          })}
        >
          <FormattedMessage
            id="parameter_range_description_with_values"
            values={{
              min: props.range[0],
              max: props.range[1],
              unit: props.unit && ` ${props.unit}`,
            }}
          />
        </div>
      ) : null}
    </div>
  );
};

export default NumberField;
