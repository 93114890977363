import React, { FC, useState } from 'react';
import './ConsoleParametersColumn.scss';
import ParameterSection, { ParameterSectionProps } from './ParameterSection';
import { EditTypes, SectionTitle } from './ParametersColumn';
import { IState } from '../../redux/store';
import TabBar from './EditBoxes/TabBar';
import { changeCalculationParameter } from '../../redux/parametersActions';
import { useSelector } from 'react-redux';
import SystemSeriesDialog from '../SystemSeriesDialog';

import { ValueKey } from '../../redux/valueKey';
import { RangeOfApplication, TabOptions } from '../../redux/constants';

const OPTIONS = [
  {
    key: TabOptions.OPTION_FREE_ENTRY,
    name: TabOptions.OPTION_FREE_ENTRY,
  },
  {
    key: TabOptions.OPTION_PROFILE_ENTRY,
    name: 'PROFILE_COMBINATION_SWITCH',
  },
];

export const PROFILE_DATA_CONFIGURATION_FREE_ENTRY: ParameterSectionProps = {
  title: SectionTitle.PROFILE_DATA,
  editableBoxConfigurations: [
    {
      name: 'ASSEMBLY_SPACE_FRAME',
      valueKey: ValueKey.VALUEKEY_ASSEMBLY_SPACE_FRAME,
      editType: EditTypes.TYPE_PANEL,
      dontResetWhenOutOfRange: true,
      help: [
        {
          heading: 'ASSEMPLY_SPACE_FRAME',
          image: 'help_assembly_space_frame.png',
          text: 'HELP_TEXT_ASSEMBLY_SPACE_FRAME',
        },
      ],
      range: (state: IState) => [
        state.staticData.defaultConsoleSetValues?.assemblySpaceFrameProfile ||
          0,
        500,
      ],
      unit: 'mm',
    },
    {
      name: 'ASSEMBLY_SPACE_SASH_PROFILE',
      valueKey: ValueKey.VALUEKEY_ASSEMBLY_SPACE_SASH_PROFILE,
      editType: EditTypes.TYPE_PANEL,
      dontResetWhenOutOfRange: true,
      help: [
        {
          heading: 'ASSEMBLY_SPACE_SASH_PROFILE',
          image: 'help_assembly_space_sash_profile.png',
          text: 'HELP_TEXT_ASSEMBLY_SPACE_SASH_PROFILE',
        },
      ],
      range: (state: IState) => [
        state.staticData.defaultConsoleSetValues?.assemblySpaceSashProfile || 0,
        500,
      ],
      unit: 'mm',
    },
  ],
};

export const PROFILE_COMBINATION: ParameterSectionProps = {
  title: SectionTitle.PROFILE_COMBINATION,
  editableBoxConfigurations: [
    {
      name: 'tree_System',
      valueKey: ValueKey.PROFILE_SYSTEM,
      editType: EditTypes.TYPE_DIALOG,
      dontResetWhenOutOfRange: true,
      help: [
        {
          heading: 'none',
          image: 'none',
          text: 'none',
        },
      ],
      unit: '',
    },
    {
      name: 'tree_parent_system_serie',
      valueKey: ValueKey.PROFILE_SERIES,
      editType: EditTypes.TYPE_DIALOG,
      dontResetWhenOutOfRange: true,
      help: [
        {
          heading: 'none',
          image: 'none',
          text: 'none',
        },
      ],
      unit: '',
    },
    {
      name: 'fenster_edit_fluegelprofil',
      valueKey: ValueKey.PROFILE_SASH,
      editType: EditTypes.TYPE_DIALOG,
      dontResetWhenOutOfRange: true,

      help: [
        {
          heading: 'none',
          image: 'none',
          text: 'none',
        },
      ],
      unit: '',
    },
    {
      name: 'fenster_edit_blendrahmen',
      valueKey: ValueKey.PROFILE_FRAME,
      editType: EditTypes.TYPE_DIALOG,
      dontResetWhenOutOfRange: true,
      help: [
        {
          heading: 'none',
          image: 'none',
          text: 'none',
        },
      ],
      unit: '',
    },
    {
      name: 'fenster_edit_wechselprofil',
      valueKey: ValueKey.PROFILE_EXCHANGE,
      editType: EditTypes.TYPE_DIALOG,
      dontResetWhenOutOfRange: true,
      help: [
        {
          heading: 'none',
          image: 'none',
          text: 'none',
        },
      ],
      unit: '',
    },
    {
      name: 'fenster_edit_grundprofil',
      valueKey: ValueKey.PROFILE_BASE,
      editType: EditTypes.TYPE_DIALOG,
      dontResetWhenOutOfRange: true,
      help: [
        {
          heading: 'none',
          image: 'none',
          text: 'none',
        },
      ],
      unit: '',
    },
  ],
};

const ConsoleParametersColumn: FC<React.PropsWithChildren<unknown>> = () => {
  const dataInput = useSelector(
    (state: IState) => state.parameters.typeOfProfileDataInput,
  );
  const rangeOfApplicationFacade = useSelector<IState, boolean>(
    s => s.parameters.rangeOfApplication.value === RangeOfApplication.FACADE,
  );

  return (
    <>
      <div className="console-parameters-column">
        {rangeOfApplicationFacade && (
          <div className="console-parameters-column__switch-container">
            <div className="console-parameters-column__entry-switch">
              <TabBar
                valueKey={ValueKey.VALUEKEY_TYPE_OF_PROFILE_INPUT}
                options={OPTIONS}
                name="Profilkombi"
                selectedValueKey="typeOfProfileDataInput"
                saveComponentLeadingValueKeyAction={(valueKey: string) =>
                  changeCalculationParameter(
                    ValueKey.VALUEKEY_TYPE_OF_PROFILE_INPUT,
                    valueKey,
                    true,
                  )
                }
                key="profile-data-switch"
              />
            </div>
          </div>
        )}

        {dataInput.value === TabOptions.OPTION_FREE_ENTRY &&
        rangeOfApplicationFacade ? (
          <ParameterSection
            {...PROFILE_DATA_CONFIGURATION_FREE_ENTRY}
            key="free-entry-section"
          />
        ) : (
          <ParameterSection
            {...PROFILE_COMBINATION}
            editor={<SystemSeriesDialog profileCombination={true} />}
            key="profile-entry-section"
          />
        )}
      </div>
    </>
  );
};

export default ConsoleParametersColumn;
