import {
  Series,
  SeriesENMaxStrokeFacade,
  SeriesENMaxStrokeRoof,
} from '../../redux/admin/adminFacadeReducer';
import { SelectOption } from '../../elements/SelectField';
import { LANGUAGE_LISTS } from '../../redux/constants';
import _, { compact } from 'lodash';

export function isDefined(value: any): boolean {
  return (
    value !== undefined &&
    value !== null &&
    value.toString().replaceAll(' ', '').length > 0
  );
}

export function fieldsFilled(...fieldValues: any[]): boolean {
  return fieldValues.every(isDefined);
}

export function fieldsFilledRecord<T, K extends (keyof T)[] = (keyof T)[]>(
  record: T,
  keys: K,
  identifier: string,
  index?: number,
): { result: K; identifier: string; index: number | undefined } | undefined {
  const result = compact(
    keys.map(key => (isDefined(record[key]) ? undefined : key)),
  ) as K;

  return result.length > 0
    ? { identifier: identifier, index: index, result }
    : undefined;
}

export function conditionalValue(condition: boolean, value: any): any {
  return condition ? value : true;
}

export function trueValue(condition: boolean): true | undefined {
  return condition ? true : undefined;
}

export function listOfStringsToSelectOptions(
  value: string[],
): SelectOption<string>[] {
  return value.map(option => ({ value: option, label: option }));
}

export function getSeriesForProfileId(
  profileSeriesId: number | undefined,
  allSeries: Series[],
): Series | undefined {
  return allSeries.find(s => s.id === profileSeriesId);
}

export function findById<T extends { id: number }>(
  id: number,
  elements: T[],
): T {
  return elements.find(element => element.id === id)!;
}

export function containingInSearchString<T>(
  target: T,
  searchValues: string[],
  searchAttributes: string[],
): boolean {
  const result: string[] = searchAttributes;

  return search(searchValues, result);
}

export function search(
  searchValues: string[],
  system: (string | undefined)[],
): boolean {
  return searchValues.every(searchValue =>
    system.some(value => {
      if (!value) {
        return false;
      }
      return value.toLowerCase().includes(searchValue.toLowerCase());
    }),
  );
}

//nach datenbank Änderung || '' entfernen
export function findCountryNameByAlpha2Id(alpha2: string): string {
  return (
    LANGUAGE_LISTS.de.find(country => country.alpha2 === alpha2)?.name || ''
  );
}

function searchBySubString<T>(
  entry: T,
  searchValues: string[],
  searchablePropertiesValuesExtractors: ((t: T) => string | undefined)[],
): boolean {
  const searchablePropertiesValues: (string | undefined)[] =
    searchablePropertiesValuesExtractors.map(
      searchablePropertiesValuesExtractor =>
        searchablePropertiesValuesExtractor(entry)?.toLowerCase(),
    );

  return search(searchValues, searchablePropertiesValues);
}

export function searchByMultipleSubstrings<T>(
  searchablePropertiesValuesExtractors: ((t: T) => string)[],
  entries: T[],
  searchString: string,
): T[] {
  const splittedSearchString = searchString.split(/\s+/);

  return entries.filter(entry =>
    searchBySubString(
      entry,
      splittedSearchString,
      searchablePropertiesValuesExtractors,
    ),
  );
}

export function seriesNameAndOpeningTypeFits(
  stroke: SeriesENMaxStrokeRoof | SeriesENMaxStrokeFacade,
  openingType: string,
  art: string,
): boolean {
  return (
    stroke.openingType === openingType &&
    stroke.driveSeries.name.toUpperCase() === art.toUpperCase()
  );
}

export function seriesIsForInwardsOrBoth(
  series: Series | undefined,
): boolean | undefined {
  return (
    (series?.oeffnungsrichtungEinwaerts &&
      !series?.oeffnungsrichtungAuswaerts) ||
    (series?.oeffnungsrichtungEinwaerts && series?.oeffnungsrichtungAuswaerts)
  );
}

export function seriesIsForOutwardsOrBoth(
  series: Series | undefined,
): boolean | undefined {
  return (
    (series?.oeffnungsrichtungAuswaerts &&
      !series?.oeffnungsrichtungEinwaerts) ||
    (series?.oeffnungsrichtungEinwaerts && series?.oeffnungsrichtungAuswaerts)
  );
}

export function seriesIsOnlyInwards(
  series: Series | undefined,
): boolean | undefined {
  return (
    series?.oeffnungsrichtungEinwaerts && !series?.oeffnungsrichtungAuswaerts
  );
}

export function seriesIsOnlyOutwards(
  series: Series | undefined,
): boolean | undefined {
  return (
    !series?.oeffnungsrichtungEinwaerts && series?.oeffnungsrichtungAuswaerts
  );
}
