import React, { FC, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import './CookieConsent.scss';
import Cookie from 'js-cookie';
import { showDialog } from '../../redux/uiStateActions';
import PrivacyPolicyModal from '../../privacy-policy/PrivacyPolicy';
import { useDispatch } from 'react-redux';

const GA4_MEASUREMENT_ID = 'G-4ENSTVG2BG';

export const START_GOOGLE_ANALYTICS_CODE = `window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments);} gtag('js', new Date()); gtag('config', '${GA4_MEASUREMENT_ID}');`;

export const COOKIE_CONSENT_COOKIE_NAME = 'cookieConsentGiven';
const CONSENT_COOKIE_LIFE_TIME = 365;

export function withdrawCookieConsent() {
  Cookie.remove(COOKIE_CONSENT_COOKIE_NAME);
  window.location.reload();
}

const CookieConsent: FC<React.PropsWithChildren<unknown>> = () => {
  const dispatch = useDispatch();
  const [consentGiven, setConsentGiven] = useState(
    !!Cookie.get(COOKIE_CONSENT_COOKIE_NAME),
  );
  const [cookieDeclined, setCookieDeclined] = useState(false);

  function cookieAccepted(): void {
    Cookie.set(COOKIE_CONSENT_COOKIE_NAME, 'true', {
      expires: CONSENT_COOKIE_LIFE_TIME,
    });
    setConsentGiven(true);
  }

  function showCookieBanner(): boolean {
    // hide if qa=1 is in the url
    if (window.location.search && window.location.search.includes('qa=1')) {
      return false;
    }

    return (
      (!cookieDeclined && !consentGiven) || (!cookieDeclined && !consentGiven)
    );
  }

  useEffect(() => {
    if (consentGiven) {
      //Don't load Google Analytics if host is not https://mycalc.dh-partner.com/
      if (window.location.hostname !== 'mycalc.dh-partner.com') {
        return;
      }

      const googleScript = document.createElement('script');
      googleScript.setAttribute(
        'src',
        `https://www.googletagmanager.com/gtag/js?id=${GA4_MEASUREMENT_ID}`,
      );
      const startGoogleAnalytics = document.createElement('script');
      const startGoogleAnalyticsCode = document.createTextNode(
        START_GOOGLE_ANALYTICS_CODE,
      );
      startGoogleAnalytics.append(startGoogleAnalyticsCode);
      document.body.append(googleScript);
      document.body.append(startGoogleAnalytics);
    }
  }, [consentGiven]);

  return (
    <>
      {showCookieBanner() && (
        <div className="cookie-consent">
          {' '}
          <div className="cookie-consent__message">
            <div className="cookie-consent__headline">
              <FormattedMessage id="COOKIE_CONSENT_HEADING" />
            </div>
            <div className="cookie-consent__text">
              <FormattedMessage id="COOKIE_CONSENT_TEXT" />{' '}
              <a
                className="cookie-consent__link"
                onClick={() => {
                  dispatch(showDialog(<PrivacyPolicyModal />));
                }}
              >
                <FormattedMessage id="PRIVACY_POLICY" />
              </a>
              .
            </div>
            <button
              className="cookie-consent__button cookie-consent__button--primary"
              onClick={cookieAccepted}
            >
              <FormattedMessage id="COOKIE_CONSENT_ACCEPT" />
            </button>
            <button
              className="cookie-consent__button cookie-consent__button--secondary"
              onClick={() => setCookieDeclined(true)}
            >
              <FormattedMessage id="COOKIE_CONSENT_DECLINE" />
            </button>
          </div>{' '}
          <div className="cookie-consent__bg"></div>
        </div>
      )}
    </>
  );
};
export default CookieConsent;
