import React, { FC, ReactElement, ReactNode, useEffect, useState } from 'react';
import './ParameterSection.scss';
import { IState, MyCalcThunkDispatch } from '../../redux/store';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import RadioBox from './EditBoxes/RadioBox';
import NumberBox from './EditBoxes/NumberBox';
import { SelectFieldBox } from './EditBoxes/SelectFieldBox';
import {
  TabBarBoxWithValuesMultipleInputs,
  TabBarBoxWithValuesSingleInput,
} from './EditBoxes/TabBarBoxWithValues';
import {
  EditTypes,
  Options,
  ParameterBoxRange,
  SectionTitle,
} from './ParametersColumn';
import { AnyAction } from 'redux';
import {
  showDialog,
  updateOverlayActive,
  updateSingleEdit,
} from '../../redux/uiStateActions';
import TabBarBoxWithOutValues from './EditBoxes/TabBarBoxWithOutValues';
import SystemSeriesBox from './EditBoxes/SystemSeriesBox';
import { ThunkAction } from 'redux-thunk';
import { UpdateCalculationResult } from '../../redux/calculationResultActions';
import {
  REQUIRED_NRWG_PARAMETERS_FACADE,
  REQUIRED_NRWG_PARAMETERS_ROOF,
  ValueKey,
} from '../../redux/valueKey';
import { Parameters } from './ParameterValue';
import WindowAreaBox from './EditBoxes/WindowAreaBox';
import { ParametersState } from '../../redux/parametersReducer';
import { RangeKeys, Ranges, Validation } from '../../redux/nrwgReducer';
import { useSelectedWindow } from '../../hooks/selectorHooks';
import CorrectionFactorDescriptionField from './EditBoxes/CorrectionFactorDescriptionField';
import classNames from 'classnames';
import { ParameterEnum, RangeOfApplication } from '../../redux/constants';
import SwitchBox from './EditBoxes/SwitchBox';
import RevealMeasuresBox from './EditBoxes/RevealMeasuresBox';
import { changeCalculationParameter } from '../../redux/parametersActions';

export interface ParameterSectionProps extends ParameterConfigurations {
  title: string;
  editor?: ReactNode;
  sectionOpenCallBack?: (b: boolean) => void;
}

interface EditModeProps {
  editMode?: boolean;
  setEditMode?: (b: boolean) => void;
}

export interface ParameterConfigurations {
  editableBoxConfigurations: ParameterConfiguration[];
  editMode?: boolean;
  setEditMode?: (b: boolean) => void;
}

function currentlyEdited(
  valueKey: ValueKey | ValueKey[],
  singleEditValueKey: ValueKey | ValueKey[],
): boolean {
  const singleEditValueKeyArray = Array.isArray(singleEditValueKey)
    ? singleEditValueKey
    : [singleEditValueKey];

  if (Array.isArray(valueKey)) {
    return valueKey.every(key => singleEditValueKeyArray.includes(key));
  } else {
    return singleEditValueKeyArray.includes(valueKey);
  }
}

export type SaveComponentLeadingValueKeyAction = (
  valueKey: string,
) =>
  | AnyAction
  | ThunkAction<Promise<void>, IState, void, UpdateCalculationResult>;

interface RangeMessage {
  id: string;
  values: Record<string, any>;
  field: ValueKey;
}

export type RangeMessages = RangeMessage[] | undefined;

export interface ParameterConfiguration {
  name: string;
  valueKey: ValueKey | ValueKey[];
  editType: string | ((state: IState) => string);
  unit?: string;
  options?: (state: IState) => Options;
  saveComponentLeadingValueKeyAction?: SaveComponentLeadingValueKeyAction;
  hint?: (state: IState) => string;
  rangeMessages?: (state: IState) => RangeMessages;
  rangeComponents?: (state: IState) => ReactNode[];
  selectedValueKey?: string;
  optionsFilter?: (
    state: IState,
    options: { name: string }[],
  ) => { name: string }[];
  hidden?: (state: IState) => boolean;
  range?: (state: IState) => ParameterBoxRange;
  help: HelpEntry[] | ((state: IState) => ReactElement);
  dynamicHelp?: (
    state: IState,
  ) => HelpEntry[] | ((state: IState) => ReactElement);
  dontResetWhenOutOfRange?: boolean;
  editMode?: boolean;
  setEditMode?: (b: boolean) => void;
  decimal?: boolean;
  additionalDescriptionField?: boolean;
  validatedFields?: ValueKey[];
  hideWhenSectionIsOpen?: boolean;
  displayedAsInvalid?: (state: IState) => boolean;
  turnedOnValue?: ParameterEnum;
  turnedOffValue?: ParameterEnum;
  errorMessage?: string;
  withoutRanges?: (s: IState) => boolean;
  openDialog?: () => void;
  staticValue?: (s: IState) => number | undefined;
}
interface HelpEntry {
  heading: string;
  image: string;
  text?: string;
}

export type Help = HelpEntry[] | ((state: IState) => ReactElement);

export interface EditableBoxConfiguration extends ParameterConfiguration {
  isSingleEdit: boolean;
}

interface ParameterSectionHeaderProps {
  data: ParameterSectionProps;
  buttonName: string;
  toggleEditMode: () => void;
  editMode: boolean;
}

type ParametersListItemProps = ParameterConfiguration &
  EditModeProps & { invalid?: boolean };

const ParametersListItem: FC<
  React.PropsWithChildren<ParametersListItemProps>
> = (config: ParametersListItemProps) => {
  const { formatMessage, formatNumber } = useIntl();
  const parameters = useSelector<IState, ParametersState>(s => s.parameters);
  const validation = useSelector<IState, Validation | undefined>(
    s => s.nrwg.validation,
  );

  const dispatch: MyCalcThunkDispatch<AnyAction> = useDispatch();

  function triggerSingleEdit(): void {
    config.setEditMode && config.setEditMode(!config.editMode);
    !config.setEditMode &&
      dispatch(updateSingleEdit(true, config.valueKey as ValueKey));
  }

  function getValueKey(): ValueKey {
    return Array.isArray(config.valueKey)
      ? (parameters[config.selectedValueKey!].value as ValueKey)
      : (config.valueKey as ValueKey);
  }

  const value = parameters[getValueKey()]?.toString(formatMessage);

  function format(value: string | number): string {
    if (typeof value === 'number') {
      return formatNumber(+value);
    }

    return value;
  }

  function getParameterSectionListItemInvalid(): boolean {
    function valueKeyIsInvalid(valueKey: ValueKey): boolean {
      if (!validation) {
        return false;
      }

      const result =
        (Object.keys(validation).includes(valueKey as ValueKey) &&
          !validation[(valueKey as ValueKey).valueOf()]) ||
        !!config.invalid;
      return result;
    }
    if (Array.isArray(config.valueKey)) {
      return config.valueKey.some(valueKeyIsInvalid);
    } else {
      return valueKeyIsInvalid(config.valueKey as ValueKey);
    }
  }

  function requiredParametersFilled(
    rangeOfApplication: RangeOfApplication,
  ): boolean {
    const requiredParameters =
      rangeOfApplication === RangeOfApplication.ROOF
        ? REQUIRED_NRWG_PARAMETERS_ROOF
        : REQUIRED_NRWG_PARAMETERS_FACADE;
    return requiredParameters.some(vk => parameters[vk].isUndefined());
  }

  return (
    <>
      {(config.valueKey === ValueKey.VALUEKEY_OPENING_ANGLE &&
        parameters.openingAngle.value === Parameters.ENTRY_REQUIRED &&
        parameters.openingStroke.value !== Parameters.ENTRY_REQUIRED) ||
      (config.valueKey === ValueKey.VALUEKEY_OPENING_STROKE &&
        parameters.openingAngle.value !== Parameters.ENTRY_REQUIRED &&
        parameters.openingStroke.value === Parameters.ENTRY_REQUIRED) ||
      (config.valueKey === ValueKey.VALUEKEY_DISTANCE_TO_HINGE &&
        parameters.drivePosition.value === Parameters.ENTRY_REQUIRED) ||
      (config.valueKey === ValueKey.AREA &&
        requiredParametersFilled(
          parameters.rangeOfApplication.value as RangeOfApplication,
        )) ? null : (
        <div
          tabIndex={1}
          onKeyDown={e => {
            if (e.key === 'Enter') {
              dispatch(updateSingleEdit(true, config.valueKey as ValueKey));
            }
          }}
          onClick={triggerSingleEdit}
          className={classNames('parameter-section__list-item', {
            'parameter-section__list-item--invalid':
              getParameterSectionListItemInvalid(),
          })}
        >
          <div className="parameter-section__list-item-name">
            <FormattedMessage id={config.name} />
          </div>
          <div className="parameter-section__list-item-data">
            {value !== Parameters.NO_SELECTION_SYSTEM_SERIES ? (
              format(value?.toString())
            ) : (
              <FormattedMessage id={value} />
            )}
          </div>
          {getParameterSectionListItemInvalid() && (
            <div className="parameter-section__list-item-marker-container">
              <div className="parameter-section__list-item-marker" />
            </div>
          )}
        </div>
      )}
    </>
  );
};

function ParameterListEntry(props: ParameterConfiguration): ReactElement {
  const state = useSelector((state: IState) => state);
  const singleEdit = useSelector((state: IState) => state.ui.singleEdit);
  const staticValue = useSelector(
    (state: IState) => props.staticValue && props.staticValue(state),
  );
  const dispatch: MyCalcThunkDispatch<AnyAction> = useDispatch();

  useEffect(() => {
    if (
      staticValue &&
      typeof props.valueKey !== 'object' &&
      staticValue !== state.parameters[props.valueKey].value
    ) {
      dispatch(changeCalculationParameter(props.valueKey, staticValue));
    }
  }, [staticValue]);

  if (props.hidden && props.hidden(state)) {
    return <></>;
  }

  return !currentlyEdited(props.valueKey, singleEdit.valueKey || []) ||
    props.setEditMode ? (
    <ParametersListItem
      key={
        props.name +
        props.editType +
        props.valueKey +
        `${props.hidden && props.hidden(state) ? 'hidden' : 'visible'}`
      }
      {...props}
      editMode={props.editMode}
      setEditMode={props.setEditMode}
      invalid={props.displayedAsInvalid && props.displayedAsInvalid(state)}
    />
  ) : (
    <Box
      {...props}
      key={props.name}
      name={props.name}
      isSingleEdit={singleEdit.single}
    />
  );
}

const ParametersList: FC<React.PropsWithChildren<ParameterConfigurations>> = (
  props: ParameterConfigurations,
) => {
  return (
    <div className="parameter-section__list">
      <div>
        {props.editableBoxConfigurations.map(
          (data: ParameterConfiguration, index) => (
            <ParameterListEntry {...data} {...props} key={index} />
          ),
        )}
      </div>
    </div>
  );
};

const ParametersSectionHeader: FC<
  React.PropsWithChildren<ParameterSectionHeaderProps>
> = (props: ParameterSectionHeaderProps) => {
  const BUTTON_MODIFIER = ['--close', '--edit'];
  const dispatch: MyCalcThunkDispatch<AnyAction> = useDispatch();

  return (
    <div className="parameter-section__header" key={props.buttonName}>
      <h4 className="parameter-section__title">
        <FormattedMessage id={props.data.title} />
      </h4>
      <button
        id={props.buttonName}
        className={`parameter-section__button parameter-section__button${
          props.editMode ? BUTTON_MODIFIER[0] : BUTTON_MODIFIER[1]
        }`}
        onClick={(): void => {
          props.toggleEditMode();
          dispatch(updateSingleEdit(false));
          dispatch(updateOverlayActive(false));
        }}
        type="button"
      >
        <FormattedMessage id={props.buttonName} />
      </button>
    </div>
  );
};

interface ParametersSectionBodyProps extends ParameterConfigurations {
  editMode: boolean;
  setEditMode?: (b: boolean) => void;
}

const Box: FC<React.PropsWithChildren<EditableBoxConfiguration>> = (
  props: EditableBoxConfiguration,
) => {
  const state = useSelector((state: IState) => state);
  const selectedWindow = useSelectedWindow();
  const ranges = useSelector<IState, Ranges | undefined>(s => s.nrwg.ranges);

  if (
    (props.hidden && props.hidden(state)) ||
    (props.hideWhenSectionIsOpen && !props.isSingleEdit)
  ) {
    return null;
  }

  const editType =
    typeof props.editType === 'string' ? props.editType : props.editType(state);

  function getRange(): (s: IState) => ParameterBoxRange {
    if (selectedWindow?.nrwg) {
      const range = ranges && ranges[props.valueKey.valueOf() as RangeKeys];
      if (range) {
        return (s: IState) => [
          range?.min || 0,
          !range?.max && range?.max !== 0 ? Infinity : range.max,
        ];
      }
    }

    return props.range || ((s: IState) => [0, Infinity]);
  }

  switch (editType) {
    case EditTypes.TYPE_DIALOG:
      return <div />;

    case EditTypes.TYPE_WINDOW_AREA:
      return (
        <WindowAreaBox
          {...{ validatedFields: props.validatedFields, ...props }}
          valueKey={props.valueKey as ValueKey}
          helpContent={props.dynamicHelp ? props.dynamicHelp(state) : []}
        />
      );

    case EditTypes.TYPE_RADIO:
      return (
        <RadioBox
          {...props}
          options={(props.options && props.options(state)) || []}
          heading={props.name}
          helpContent={props.help}
          valueKey={props.valueKey as ValueKey}
          errorMessage={props.errorMessage}
        />
      );

    case EditTypes.TYPE_PANEL:
      return (
        <NumberBox
          {...props}
          valueKey={props.valueKey as ValueKey}
          heading={props.name}
          range={getRange()}
          helpContent={props.help || [{ heading: '', image: '', text: '' }]}
          staticValue={props.staticValue && props.staticValue(state)}
        >
          {props.valueKey === ValueKey.CORRECTION_FACTOR &&
          state.parameters.correctionFactor.value! < 1 ? (
            <CorrectionFactorDescriptionField />
          ) : undefined}
        </NumberBox>
      );

    case EditTypes.TYPE_SELECT:
      return (
        <SelectFieldBox
          {...props}
          valueKey={props.valueKey as ValueKey}
          heading={props.name}
          options={(props.options && props.options(state)) || []}
          helpContent={props.help || [{ heading: '', image: '', text: '' }]}
        />
      );

    case EditTypes.TYPE_TAB_BAR_SINGLE_INPUT: {
      if (
        !props.options ||
        !props.saveComponentLeadingValueKeyAction ||
        !props.selectedValueKey
      ) {
        throw new Error('Missing parameters for tabBar');
      }
      return (
        <TabBarBoxWithValuesSingleInput
          {...props}
          valueKey={props.valueKey as ValueKey}
          options={props.options(state)}
          saveComponentLeadingValueKeyAction={
            props.saveComponentLeadingValueKeyAction
          }
          selectedValueKey={props.selectedValueKey}
          helpContent={props.help || [{ heading: '', image: '', text: '' }]}
        />
      );
    }

    case EditTypes.TYPE_TAB_BAR: {
      if (
        !props.options ||
        !props.saveComponentLeadingValueKeyAction ||
        !props.selectedValueKey
      ) {
        throw new Error('Missing parameters for tabBar');
      }
      return (
        <TabBarBoxWithValuesMultipleInputs
          {...props}
          valueKey={props.valueKey as ValueKey}
          options={props.options(state)}
          saveComponentLeadingValueKeyAction={
            props.saveComponentLeadingValueKeyAction
          }
          selectedValueKey={props.selectedValueKey}
          helpContent={props.help || [{ heading: '', image: '', text: '' }]}
        />
      );
    }

    case EditTypes.TYPE_BUTTON: {
      return (
        <SystemSeriesBox
          {...props}
          valueKey={props.valueKey as ValueKey}
          label={'ADD_SYSTEM_AND_SERIES'}
          helpContent={props.help || [{ heading: '', image: '', text: '' }]}
        />
      );
    }

    case EditTypes.TYPE_TAB_BAR_WITHOUT_VALUE_EDIT: {
      if (
        !props.options ||
        !props.saveComponentLeadingValueKeyAction ||
        !props.selectedValueKey
      ) {
        throw new Error('Missing parameters for tabBar');
      }
      return (
        <TabBarBoxWithOutValues
          {...props}
          valueKey={props.valueKey as ValueKey}
          options={props.options(state)}
          saveComponentLeadingValueKeyAction={
            props.saveComponentLeadingValueKeyAction
          }
          selectedValueKey={props.selectedValueKey}
          helpContent={props.help || [{ heading: '', image: '', text: '' }]}
        />
      );
    }

    case EditTypes.TYPE_SWITCH: {
      if (!props.turnedOnValue || !props.turnedOffValue) {
        throw new Error('Missing parameters for switch');
      }
      return (
        <SwitchBox
          helpContent={props.help}
          heading={props.name}
          isSingleEdit={props.isSingleEdit}
          valueKey={props.valueKey as ValueKey}
          turnedOnValue={props.turnedOnValue}
          turnedOffValue={props.turnedOffValue}
        />
      );
    }

    case EditTypes.TYPE_REVEAL_MEASURES: {
      return (
        <RevealMeasuresBox
          helpContent={props.help}
          heading={props.name}
          isSingleEdit={props.isSingleEdit}
          valueKey={props.valueKey as ValueKey}
        />
      );
    }

    default:
      return null;
  }
};

const ParametersSectionBody: FC<
  React.PropsWithChildren<ParametersSectionBodyProps>
> = (props: ParametersSectionBodyProps) => {
  return (
    <div className="parameter-section__body">
      {props.editMode ? (
        props.editableBoxConfigurations.map(
          (configuration: ParameterConfiguration, index: number) => (
            <Box
              {...configuration}
              key={configuration.name + index}
              isSingleEdit={false}
              name={configuration.name}
            />
          ),
        )
      ) : (
        <ParametersList {...props} />
      )}
    </div>
  );
};

export const ParameterSection: FC<
  React.PropsWithChildren<ParameterSectionProps>
> = (props: ParameterSectionProps) => {
  const [editMode, setEditMode] = useState(false);
  const dispatch: MyCalcThunkDispatch<AnyAction> = useDispatch();

  function updateEditMode(editMode: boolean): void {
    setEditMode(editMode);
    dispatch(showDialog(editMode ? props.editor : undefined));
  }

  function buttonName(): string {
    if (props.title === SectionTitle.PROFILE_COMBINATION) {
      return 'client_menu_edit';
    }

    return editMode ? 'button_close' : 'client_menu_edit';
  }

  return (
    <div
      data-testid="parameter-section"
      className={classNames('parameter-section', {
        'parameter-section--edit-mode': editMode,
      })}
    >
      <ParametersSectionHeader
        toggleEditMode={(): void => {
          updateEditMode(!editMode);
          if (props.sectionOpenCallBack) {
            props.sectionOpenCallBack(!editMode);
          }
        }}
        data={props}
        buttonName={buttonName()}
        editMode={editMode}
      />

      <ParametersSectionBody
        editMode={props.editor ? false : editMode}
        setEditMode={props.editor ? updateEditMode : undefined}
        editableBoxConfigurations={props.editableBoxConfigurations}
      />
    </div>
  );
};

export default ParameterSection;
