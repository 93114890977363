import React, { FC } from 'react';
import EditableBox, { EditableBoxProps } from './EditableBox';
import { Switch } from '../../../elements/Switch';
import { useDispatch, useSelector } from 'react-redux';
import { IState, MyCalcThunkDispatch } from '../../../redux/store';
import { changeCalculationParameter } from '../../../redux/parametersActions';
import { ParameterEnum } from '../../../redux/constants';
import { AnyAction } from 'redux';
import { Help } from '../ParameterSection';

interface SwitchBoxProps extends EditableBoxProps {
  turnedOffValue: ParameterEnum;
  turnedOnValue: ParameterEnum;
  helpContent?: Help;
}

const SwitchBox: FC<React.PropsWithChildren<SwitchBoxProps>> = props => {
  const turnedOn = useSelector<IState, boolean>(
    s => s.parameters[props.valueKey].value === props.turnedOnValue,
  );
  const dispatch: MyCalcThunkDispatch<AnyAction> = useDispatch();

  function toggle(): void {
    dispatch(
      changeCalculationParameter(
        props.valueKey,
        turnedOn ? props.turnedOffValue : props.turnedOnValue,
      ),
    );
  }

  return (
    <EditableBox {...props}>
      <div className="r">
        <Switch
          turnedOn={turnedOn}
          onChange={toggle}
          label={turnedOn ? props.turnedOnValue : props.turnedOffValue}
        />
      </div>
    </EditableBox>
  );
};

export default SwitchBox;
