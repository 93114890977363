import React, { FC, ReactElement } from 'react';
import './Headline.scss';
import { conditionalClass, getModifyClass } from './element_utils';

export enum HeadlineSize {
  SM = 'sm',
  MD = 'md',
  LG = 'lg',
  XL = 'xl',
}

const modifyClass = getModifyClass('headline');

interface HeadlineProps {
  children: (ReactElement | string)[] | ReactElement | string;
  additionalClass?: string;
  size: HeadlineSize;
  normalHeight?: boolean;
  dark?: boolean;
}
const Headline: FC<React.PropsWithChildren<HeadlineProps>> = props => {
  return (
    <div
      className={
        'headline' +
        modifyClass(props.size) +
        modifyClass('normal-height', !!props.normalHeight) +
        modifyClass('dark', props.dark) +
        conditionalClass(props.additionalClass)
      }
    >
      {props.children}
    </div>
  );
};

export default Headline;
