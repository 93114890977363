import React, { ReactElement, ReactNode } from 'react';
import classNames from 'classnames';

export default function FormLayoutSubgroupTitle(props: {
  children: ReactNode;
  additionalClassNames?: string;
}): ReactElement {
  return (
    <div
      className={classNames(
        'form-layout__sub-group-title',
        props.additionalClassNames,
      )}
    >
      {props.children}
    </div>
  );
}
