import { AnyAction, Reducer } from 'redux';

export interface GeometricDesmoking {
  geometricArea: number;
  maxGeometricArea: number;
  originalGeometricArea: number;
}
export interface AerodynamicDesmoking {
  maxGeometricArea: number;
  aerodynamicArea: number;
}
export interface DesmokingState {
  geometricDesmoking?: GeometricDesmoking;
  aerodynamicDesmoking?: AerodynamicDesmoking;
}

export const INITIAL_STATE = {};

const desmokingResultReducer: Reducer = (
  state: DesmokingState,
  action: AnyAction,
) => {
  if (!state) {
    return INITIAL_STATE;
  }

  switch (action.type) {
    case 'UPDATE_GEOMETRIC_DESMOKING_DATA':
      return { ...state, geometricDesmoking: action.value };
    case 'UPDATE_AERODYNAMIC_DESMOKING_DATA':
      return { ...state, aerodynamicDesmoking: action.value };
    case 'RESET_DESMOKING':
      return INITIAL_STATE;
    default:
      return state;
  }
};

export default desmokingResultReducer;
