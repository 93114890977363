/* eslint-disable */
import { Action, AnyAction, applyMiddleware, createStore } from 'redux';
import appReducer from './appReducer';
import thunk, { ThunkDispatch } from 'redux-thunk';
import { IntlAction, IntlState } from 'react-intl-redux';
import { ParametersState } from './parametersReducer';
import { CalculationResult } from './calculationResultReducer';
import { Locale, UIState } from './uiStateReducer';
import { StaticData } from './staticDataReducer';
import { DesmokingState } from './desmokingResultReducer';
import { ProfileData } from './profileDataReducer';
import { ProjectsState } from './projectsReducer';
import { switchLocaleAction, updateLocaleAction } from './uiStateActions';
import {
  initializeCalculationParametersByURLParameters,
  recalculate,
} from './parametersActions';
import { getSystemSeriesResult } from './profileDataActions';
import { NrwgState } from './nrwgReducer';
import { Config, GlobalUIState } from './globalUiStateReducer';
import { updateConfig } from './globalUiStateActions';
import { AuthenticationState } from './authenticationReducer';
import { useDispatch } from 'react-redux';
import { qaMode } from '../keyCloak/keyCloak';
import { fetchDriveSeries } from './staticDataActions';

export type MyCalcThunkDispatch<T extends Action> = ThunkDispatch<
  IState,
  undefined,
  T
>;

export interface IState {
  [index: string]:
    | ParametersState
    | ProfileData
    | IntlState
    | UIState
    | CalculationResult
    | StaticData
    | DesmokingState
    | ProjectsState
    | NrwgState
    | GlobalUIState
    | AuthenticationState;
  parameters: ParametersState;
  profileData: ProfileData;
  calculationResult: CalculationResult;
  intl: IntlState;
  ui: UIState;
  globalUIState: GlobalUIState;
  staticData: StaticData;
  desmoking: DesmokingState;
  projects: ProjectsState;
  nrwg: NrwgState;
  authentication: AuthenticationState;
}

export type MyCalcDispatch = ThunkDispatch<IState, void, AnyAction>;

export function useMyCalcDispatch(): MyCalcDispatch {
  return useDispatch() as MyCalcDispatch;
}

const intitializeStore = (config: Config) => {
  const store = createStore(appReducer, {}, applyMiddleware(thunk));

  const dispatch = store.dispatch as ThunkDispatch<
    IState,
    void,
    IntlAction | AnyAction
  >;
  const browserLocale = qaMode()
    ? 'EN'
    : window.navigator.language.substr(0, 2).toUpperCase();
  const legalValue = Object.keys(Locale).includes(browserLocale);

  dispatch(
    updateLocaleAction(
      (legalValue ? browserLocale.toLowerCase() : 'en') as Locale,
    ),
  );
  dispatch(switchLocaleAction(store.getState().authentication.locale, false));
  dispatch(recalculate());
  dispatch(getSystemSeriesResult());
  // dispatch(loadConfig());
  dispatch(updateConfig(config));
  dispatch(initializeCalculationParametersByURLParameters());
  dispatch(fetchDriveSeries());

  return store;
};

export default intitializeStore;
