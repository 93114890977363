import { ReactElement, ReactNode } from 'react';
import { DialogFE } from '../components/Dialog';
import './PrivacyPolicy.scss';
import { withdrawCookieConsent } from '../components/Footer/CookieConsent';
import { useSelector } from 'react-redux';
import { Locale } from '../redux/uiStateReducer';
import { IState } from '../redux/store';

const GOOGLE_ENTRY_DE: [string, string, string, ReactNode] = [
  'Google',
  '12 Monate',
  'Verarbeitungen auch außerhalb der EU/EWR möglich. Kein angemessenes\n' +
    'Datenschutzniveau. Die Übermittlung erfolgt auf Grundlage von Art.\n' +
    '49 Abs. 1 lit. a DSGVO.',
  <>
    Wenn Sie Ihre Einwilligung widerrufen wollen, klicken Sie bitte
    <a
      style={{ textDecoration: 'underline', cursor: 'pointer' }}
      onClick={() => withdrawCookieConsent()}
    >
      {' '}
      hier
    </a>{' '}
    und treffen die entsprechende Einstellung über unser Banner.
  </>,
];
const GOOGLE_ENTRY_EN: [string, string, string, ReactNode] = [
  'Google',
  '12 months',
  'Processing also possible outside of the EU/EEA. No adequate level of data protection. The transmission is based on Art. 49 (1) (a) GDPR.',
  <>
    If you wish to withdraw your consent, please click{' '}
    <a
      style={{ textDecoration: 'underline', cursor: 'pointer' }}
      onClick={() => withdrawCookieConsent()}
    >
      here
    </a>{' '}
    and make the appropriate setting via our banner.
  </>,
];

function GoogleCookieTable() {
  const locale: Locale = useSelector(
    (state: IState) => state.authentication.locale,
  );

  return (
    <InfoTable
      entries={[locale === Locale.DE ? GOOGLE_ENTRY_DE : GOOGLE_ENTRY_EN]}
    />
  );
}

function InfoTable(props: {
  entries: [ReactNode, ReactNode, ReactNode, ReactNode][];
}) {
  const locale: Locale = useSelector(
    (state: IState) => state.authentication.locale,
  );

  const germanLocale = locale === Locale.DE;

  const germanTableHeading = (
    <thead>
      <tr>
        <th>Anbieter</th>
        <th>Maximale Speicherdauer</th>
        <th>Angemessenes Datenschutzniveau</th>
        <th>Widerruf der Einwilligung</th>
      </tr>
    </thead>
  );

  const englishTableHeading = (
    <thead>
      <tr>
        <th>Provider</th>
        <th className="privacy-policy__google-table-max-storage">
          Maximum storage period
        </th>
        <th>Adequate level of data protection</th>
        <th>Withdrawal of consent</th>
      </tr>
    </thead>
  );
  return (
    <table>
      {germanLocale ? germanTableHeading : englishTableHeading}
      <tbody>
        {props.entries.map((entry, i) => (
          <tr key={i}>
            <td>{entry[0]}</td>
            <td>{entry[1]}</td>
            <td>{entry[2]}</td>
            <td>{entry[3]}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}

function GermanPolicy(): ReactElement {
  return (
    <>
      <p>
        Wir freuen uns darüber, dass Sie unsere Anwendung myCalc nutzen.
        Nachfolgend möchten wir Sie über den Umgang mit Ihren Daten gem. Art. 13
        Datenschutz-Grundverordnung (DSGVO) informieren.
      </p>
      <h2>Verantwortlicher</h2>
      <p>
        Verantwortlich für die nachfolgend dargestellten Datenverarbeitungen ist
        die im{' '}
        <a
          href="https://www.dh-partner.com/group/de/meta/rechtliches/Impressum"
          target="_blank"
          rel="noopener noreferrer"
        >
          Impressum
        </a>{' '}
        genannte Stelle.
      </p>
      <h2>Nutzungsdaten</h2>
      <p>
        Wenn Sie unsere Webseiten besuchen, werden auf unserem Webserver
        temporär sogenannte Nutzungsdaten zu statistischen Zwecken als Protokoll
        ausgewertet, um die Qualität unserer Webseiten zu verbessern. Dieser
        Datensatz besteht aus
      </p>
      <ul>
        <li>dem Namen und der Adresse der angeforderten Inhalte,</li>
        <li>dem Datum und der Uhrzeit der Abfrage,</li>
        <li>der übertragenen Datenmenge,</li>
        <li>dem Zugriffsstatus (Inhalt übertragen, Inhalt nicht gefunden),</li>
        <li>
          der Beschreibung des verwendeten Webbrowsers und des Betriebssystems,
        </li>
        <li>
          dem Referral-Link, der angibt, von welcher Seite Sie auf unsere
          gelangt sind,
        </li>
        <li>
          der IP-Adresse des anfragenden Rechners, die so verkürzt wird, dass
          ein Personenbezug nicht mehr herstellbar ist.
        </li>
      </ul>
      <p>Die genannten Protokolldaten werden nur anonymisiert ausgewertet.</p>
      <p>
        Rechtsgrundlage der Verarbeitung der Nutzungsdaten ist Art. 6 Abs. 1 S.
        1 lit. f DSGVO. Die Verarbeitung erfolgt in dem berechtigten Interesse,
        die Inhalte der Webseite bereitzustellen und eine Geräte- sowie
        Browseroptimierte Darstellung sicherzustellen.
      </p>
      <h2>Datensicherheit</h2>
      <p>
        Um Ihre Daten vor unerwünschten Zugriffen möglichst umfassend zu
        schützen, treffen wir technische und organisatorische Maßnahmen. Wir
        setzen auf unseren Webseiten ein Verschlüsselungsverfahren ein. Ihre
        Angaben werden von Ihrem Rechner zu unserem Server und umgekehrt über
        das Internet mittels einer TLS-Verschlüsselung übertragen. Sie erkennen
        dies in der Regel daran, dass in der Statusleiste Ihres Browsers das
        Schloss-Symbol geschlossen ist und die Adresszeile mit https:// beginnt.
      </p>
      <h2>Einwilligungsbanner</h2>
      <p>
        In unserer Anwendung nutzen wir ein Einwilligungs- bwz. Cookie-Banner.
        Die Protokollierung der von Ihnen vorgenommenen Einstellungen erfolgt
        auf Grundlage von Art. 6 Abs. 1 S. 1 lit. f DSGVO, in unserem
        berechtigten Interesse unsere Inhalte entsprechend Ihrer Präferenzen
        auszuspielen und Ihre erteilte(n) Einwilligung(en) nachweisen zu können.
        Ihre vorgenommenen Einstellungen, die damit erteilten Einwilligungen
        sowie Teile Ihrer Nutzungsdaten werden in einem Cookie gespeichert.
        Damit bleibt dieser für nachfolgende Seitenanfragen erhalten und Ihre
        Einwilligungen können weiterhin nachvollzogen werden. Weitere
        Informationen finden Sie hierzu unter dem Gliederungspunkt
        „erforderliche Cookies“.
      </p>
      <h2>Google Analytics</h2>
      <p>
        Zur bedarfsgerechten Gestaltung unserer Webseiten verwenden wir das
        Webanalysetool „Google Analytics“. Google Analytics erstellt
        Nutzungsprofile auf Grundlage von Pseudonymen. Hierzu werden permanente
        Cookies auf Ihrem Endgerät gespeichert und von uns ausgelesen. Auf diese
        Weise sind wir in der Lage, wiederkehrende Besucher zu erkennen und als
        solche zu zählen.
      </p>
      <p>
        Im Rahmen des Dienstes Google Analytics unterstützt uns die Google
        Ireland Limited als Auftragsverarbeiter nach Art. 28 DSGVO. Die
        Datenverarbeitung kann dabei auch durch Google außerhalb der EU bzw. des
        EWR (insbesondere in den USA) stattfinden. Im Hinblick auf Google kann
        aufgrund der Verarbeitung in den USA kein angemessenes Datenschutzniveau
        angenommen werden. Es besteht das Risiko, dass Behörden auf die Daten zu
        Sicherheits- und Überwachungszwecken zugreifen, ohne dass Sie hierüber
        informiert werden oder Rechtsmittel einlegen können. Bitte beachten Sie
        dies, wenn Sie sich dazu entscheiden, Ihre Einwilligung in unsere
        Nutzung von Google Analytics abzugeben.
      </p>
      <p>
        Die Datenverarbeitung erfolgt auf Grundlage Ihrer Einwilligung, sofern
        Sie Ihre Einwilligung über unser Banner abgegeben haben. Die
        Übermittlung in ein Drittland erfolgt auf Grundlage von Art. 49 Abs. 1
        lit. a DSGVO.
      </p>
      <p>
        Sie können Ihre Einwilligung jederzeit widerrufen. Bitte folgen Sie
        hierzu diesem Link und treffen die entsprechenden Einstellungen über
        unser Banner.
      </p>
      <GoogleCookieTable />
      <h2>Besuchermessung</h2>
      <p>
        Zur bedarfsgerechten Gestaltung unserer Webseiten verwenden wir
        Webanalysetools. Dieses erstellt Nutzungsprofile auf Grundlage von
        Pseudonymen. Hierzu werden permanente Cookies auf Ihrem Endgerät
        gespeichert und von uns ausgelesen. Darüber hinaus ist es möglich, dass
        wir Wiedererkennungsmerkmale für Ihren Browser oder Ihr Endgerät abrufen
        (z. B. einen sog. Browser-Fingerprint oder Ihre ungekürzte IP-Adresse).
        Auf diese Weise sind wir in der Lage, wiederkehrende Besucher zu
        erkennen und als solche zu zählen.
      </p>
      <p>
        Darüber hinaus nutzen wir im Rahmen der Besuchermessung folgende
        Funktionen:
        <ul>
          <li>
            Wir reichern die pseudonymen Daten mit weiteren Daten an, die uns
            Drittanbieter zur Verfügung stellen. Wir sind auf diese Weise in der
            Lage, demografische Merkmale unserer Besucher zu erfassen, z. B.
            Aussagen zu Alter, Geschlecht und Wohnort.
          </li>
          <li>
            Wir verwenden eine Erkennungsmethode, die es uns erlaubt, die
            Mauszeigerbewegung unserer Besucher zu erfassen und nachträglich
            auszuwerten.
          </li>
          <li>
            Amazon (AWS) Quicksight wird zur Erfassung und Anzeige der
            Häufigkeit gewählter Produkte sowie der Analyse verwendet..Die
            Datenverarbeitung erfolgt auf Grundlage Ihrer Einwilligung, sofern
            Sie Ihre Einwilligung über unsere Banner abgegeben haben.
          </li>
        </ul>
      </p>
      <h3>Welche Drittanbieter nutzen wir in diesem Rahmen?</h3>
      <p>
        Nachfolgend nennen wir Ihnen die Drittanbieter, mit denen wir im
        Zusammenhang mit der Besuchermessung zusammenarbeiten. Sofern die Daten
        in diesem Rahmen außerhalb der EU bzw. des EWR (insbesondere in den USA)
        verarbeitet werden, beachten Sie bitte, dass das Risiko besteht, dass
        Behörden auf die Daten zu Sicherheits- und Überwachungszwecken
        zugreifen, ohne dass Sie hierüber informiert werden oder Rechtsmittel
        einlegen können. Sofern wir Anbieter in unsicheren Drittländern
        einsetzen und Sie einwilligen, erfolgt die Übermittlung in ein Drittland
        auf Grundlage von Art. 49 Abs. 1 lit. a DSGVO.
      </p>
      <GoogleCookieTable />
      <h2>Trackingtechnologien von Drittanbietern zu Werbezwecken</h2>
      <p>
        Wir nutzen geräteübergreifende Trackingtechnologien, damit Ihnen
        basierend auf Ihrem Besuch unserer Webseiten zielgerichtete Werbung auf
        anderen Internetseiten angezeigt werden kann und wir erkennen können,
        wie wirksam unsere Werbemaßnahmen waren.
      </p>
      <p>
        Die Datenverarbeitung erfolgt auf Grundlage Ihrer Einwilligung, sofern
        Sie Ihre Einwilligung über unser Banner abgegeben haben. Ihre
        Einwilligung ist freiwillig und kann jederzeit widerrufen werden.
      </p>
      <h3>Wie funktioniert das Tracking?</h3>
      <p>
        Wenn Sie unsere Webseiten besuchen, ist es möglich, dass die unten
        genannten Drittanbieter Wiedererkennungsmerkmale für Ihren Browser oder
        Ihr Endgerät abrufen (z. B. einen sog. Browser-Fingerprint), Ihre
        IP-Adresse auswerten, Wiedererkennungsmerkmale auf Ihrem Endgerät
        speichern bzw. auslesen (z.B. Cookies) oder Zugriff auf individuelle
        Tracking-Pixel erhalten.
      </p>
      <p>
        Die einzelnen Merkmale können von den Drittanbietern genutzt werden, um
        Ihr Endgerät auf anderen Internetseiten wiederzuerkennen. Wir können bei
        den entsprechenden Drittanbietern die Schaltung von Werbung beauftragen,
        die sich nach den bei uns besuchten Seiten richtet.
      </p>
      <h3>Was bedeutet geräteübergreifendes Tracking?</h3>
      <p>
        Sofern Sie sich mit eigenen Benutzerdaten beim Drittanbieter anmelden,
        können die jeweiligen Wiedererkennungsmerkmale verschiedener Browser und
        Endgeräte miteinander verknüpft werden. Wenn der Drittanbieter also z.B.
        je ein eigenes Merkmal für den von Ihnen verwendeten Laptop, Desktop-PC
        oder das von Ihnen verwendete Smartphone bzw. Tablet erstellt hat,
        können diese einzelnen Merkmale einander zugeordnet werden, sobald Sie
        mit Ihren Anmeldedaten einen Dienst des Drittanbieters nutzen. Auf diese
        Weise kann der Drittanbieter unsere Werbekampagnen auch über
        verschiedene Endgeräte hinweg zielgerichtet steuern.
      </p>
      <h3>Welche Drittanbieter nutzen wir in diesem Rahmen?</h3>
      <p>
        Nachfolgend nennen wir Ihnen die Drittanbieter, mit denen wir zu
        Werbezwecken zusammenarbeiten. Sofern die Daten in diesem Rahmen
        außerhalb der EU bzw. des EWR (insbesondere in den USA) verarbeitet
        werden, beachten Sie bitte, dass das Risiko besteht, dass Behörden auf
        die Daten zu Sicherheits- und Überwachungszwecken zugreifen, ohne dass
        Sie hierüber informiert werden oder Rechtsmittel einlegen können. Sofern
        wir Anbieter in unsicheren Drittländern einsetzen und Sie einwilligen,
        erfolgt die Übermittlung in ein Drittland auf Grundlage von Art. 49 Abs.
        1 lit. a DSGVO.
      </p>
      <h2>Kontaktformular</h2>
      <p>
        Sie haben die Möglichkeit, sich mit uns über unser Kontaktformular in
        Verbindung zu setzen. Zur Nutzung unseres Kontaktformulars benötigen wir
        von Ihnen zunächst die als Pflichtfelder markierten Daten.
      </p>
      <p>
        Diese Daten verwenden wir auf Grundlage von Art. 6 Abs. 1 S. 1 lit. f
        DSGVO, um Ihre Anfrage zu beantworten.
      </p>
      <p>
        Darüber hinaus können Sie selbst entscheiden, ob Sie uns weitere Angaben
        mitteilen möchten. Diese Angaben erfolgen freiwillig und sind für die
        Kontaktaufnahme nicht zwingend erforderlich. Ihre freiwilligen Angaben
        verarbeiten wir auf Grundlage Ihrer Einwilligung.
      </p>
      <p>
        Ihre Daten werden nur zur Beantwortung Ihrer Anfrage verarbeitet. Wir
        löschen Ihre Daten, sofern diese nicht mehr erforderlich sind und keine
        gesetzlichen Aufbewahrungspflichten entgegenstehen. Regelmäßig ist dies
        365 Tage nach Bearbeitung der Anfrage der Fall.
      </p>
      <p>
        Soweit Ihre, per Kontaktformular übermittelten Daten, auf Grundlage von
        Art. 6 Abs. 1 S. 1 lit. f DSGVO verarbeitet werden, können Sie der
        Verarbeitung jederzeit widersprechen. Zudem können Sie Ihre Einwilligung
        in die Verarbeitung der freiwilligen Angaben jederzeit widerrufen.
        Wenden Sie sich hierfür bitte an die im Impressum genannte
        E-Mail-Adresse.
      </p>
      <h2>Kartendienste</h2>
      <p>
        Auf unseren Webseiten betten wir Kartendienste ein, die nicht auf
        unseren Servern gespeichert sind. Aus Gründen des Datenschutzes werden
        jedoch - wenn Sie unsere Webseiten aufrufen – noch keine Inhalte des
        Drittanbieters nachgeladen und der Drittanbieter erhält keine
        Informationen.
      </p>
      <p>
        Erst wenn Sie Ihre Einwilligung über unser Banner erteilen, werden
        Inhalte des Drittanbieters nachgeladen. Hierdurch erhält der
        Drittanbieter die Information, dass Sie unsere Seite aufgerufen haben
        sowie die in diesem Rahmen technisch erforderlichen Nutzungsdaten. Wir
        haben auf die weitere Datenverarbeitung durch den Drittanbieter keinen
        Einfluss. Ihre Einwilligung umfasst, dass Inhalte des Drittanbieters
        nachgeladen werden.
      </p>
      <p>
        Die Einbettung erfolgt auf Grundlage Ihrer Einwilligung, sofern Sie Ihre
        Einwilligung über unser Banner abgegeben haben.
      </p>
      <p>
        Bitte beachten Sie, dass die Einbettung mancher Kartendienste dazu
        führt, dass Ihre Daten außerhalb der EU bzw. des EWR (insbesondere in
        den USA) verarbeitet werden. Es besteht dabei das Risiko, dass Behörden
        auf die Daten zu Sicherheits- und Überwachungszwecken zugreifen, ohne
        dass Sie hierüber informiert werden oder Rechtsmittel einlegen können.
        Sofern wir Anbieter in unsicheren Drittländern einsetzen und Sie
        einwilligen, erfolgt die Übermittlung in ein unsicheres Drittland auf
        Grundlage von Art. 49 Abs. 1 lit. a DSGVO.
      </p>
      <GoogleCookieTable />
      <h2>Newsletteranmeldung und- versand</h2>
      <p>
        Sie können auf unseren Webseiten einen Newsletter bestellen. Bitte
        beachten Sie, dass wir für die Anmeldung zum Newsletter bestimmte Daten
        (mindestens Ihre E-Mail-Adresse) benötigen.
      </p>
      <p>
        Der Versand des Newsletters erfolgt nur dann, wenn Sie uns eine
        ausdrückliche Einwilligung erteilt haben. Nach erfolgter Bestellung
        erhalten Sie eine Bestätigungs-E-Mail an die von Ihnen angegebene
        E-Mail-Adresse (sog. Double-Opt-In). Sie können Ihre Einwilligung
        jederzeit widerrufen. Eine unkomplizierte Möglichkeit zum Widerruf
        erhalten Sie z. B. über den in jedem Newsletter vorhandenen Link zur
        Abbestellung.
      </p>
      <p>
        Im Rahmen der Newsletteranmeldung speichern wir über die bereits
        genannten Daten hinaus weitere Daten, sofern diese erforderlich sind,
        damit wir nachweisen können, dass Sie unseren Newsletter bestellt haben.
        Dies kann die Speicherung der vollständigen IP-Adresse zum Zeitpunkt der
        Bestellung bzw. der Bestätigung des Newsletters umfassen, sowie eine
        Kopie der von uns versendeten Bestätigungs-E-Mail. Die entsprechende
        Datenverarbeitung erfolgt auf Grundlage von Art. 6 Abs. 1 S. 1 lit. f
        DSGVO und in dem berechtigten Interesse, über die Rechtmäßigkeit des
        Newsletterversands Rechenschaft ablegen zu können.
      </p>
      <p>
        Sofern Sie unseren Newsletter bestellen, bitten wir Sie im Rahmen des
        Bestellvorgangs, sich mit einem weitergehenden Newslettertracking
        einverstanden zu erklären.
      </p>
      <p>
        Wenn Sie uns die entsprechende Einwilligung erteilen, binden wir in
        unsere Newsletter individuelle Tracking-Pixel ein, mit denen wir
        erkennen können, wann der an Sie versendete Newsletter abgerufen bzw.
        geöffnet wurde und individualisieren die im Newsletter vorhandenen Links
        um auswerten zu können, wann Sie auf welchen Link geklickt haben.
      </p>
      <p>
        Für die Durchführung und Verwaltung (Dokumentation) des Newsletters wird
        der externe Dienstleister Freshworks Inc von uns als Auftragsverarbeiter
        gem. Art. 28 DSGVO streng weisungsgebunden eingesetzt. Entsprechende
        Datenschutzvereinbarungen mit diesem Dienstleister wurden getroffen.
      </p>
      <p>
        Sollten Sie Ihre Einwilligung widerrufen wollen, nutzen Sie den in jedem
        Newsletter vorhandenen Link zur Abbestellung bzw. Anpassung Ihrer
        Einwilligung.
      </p>
      <h2>Zugangsgeschützter Bereich</h2>
      <p>
        Sofern Sie unseren zugangsgeschützten Bereich nutzen möchten, ist eine
        vorherige Registrierung notwendig.
      </p>
      <p>
        Wir erheben hierbei nur die Daten, die zur Registrierung und
        Bereitstellung des Dienstes erforderlich sind. Die Verarbeitung erfolgt
        auf Grundlage von Art. 6 Abs. 1 S. 1 lit. f DSGVO in unserem
        berechtigten Interesse, Ihnen die Dienste und Informationen des
        zugangsgeschützten Bereichs zur Verfügung zu stellen.
      </p>
      <p>
        Sofern wir darüber hinaus als freiwillig gekennzeichnete Daten erheben,
        verarbeiten wir diese auf Basis Ihrer Einwilligung. Ihre Einwilligung
        können Sie jederzeit mit Wirkung für die Zukunft widerrufen. Nutzen Sie
        hierzu bitte die entsprechenden Funktionen im zugangsgeschützten Bereich
        oder wenden sich an die im Impressum genannte E-Mail-Adresse.
      </p>
      <p>
        Wenn Sie sich von unserem zugangsgeschützten Bereich dauerhaft abmelden
        möchten (Widerspruch), nutzen Sie hierzu bitte die Abmeldemöglichkeit im
        zugangsgeschützten Bereich oder wenden sich an die im Impressum genannte
        Stelle.
      </p>
      <h2>Speicherdauer</h2>
      <p>
        Sofern wir nicht im Einzelnen bereits über die Speicherdauer informiert
        haben, löschen wir personenbezogene Daten, wenn sie für die vorgenannten
        Verarbeitungszwecke nicht mehr erforderlich sind und keine berechtigten
        Interessen oder andere (gesetzliche) Aufbewahrungsgründe einer Löschung
        entgegenstehen.
      </p>
      <h2>Weitere Auftragsverarbeiter</h2>
      <p>
        Wir geben Ihre Daten im Rahmen einer Auftragsverarbeitung gem. Art. 28
        DSGVO an Dienstleister weiter, die uns beim Betrieb unserer Webseiten
        und der damit zusammenhängenden Prozesse unterstützen. Das sind z.B.
        Hosting-Dienstleister. Unsere Dienstleister sind uns gegenüber streng
        weisungsgebunden und entsprechend vertraglich verpflichtet.
      </p>
      <p>
        Nachfolgend nennen wir Ihnen die Auftragsverarbeiter, mit denen wir
        zusammenarbeiten, sofern wir dies im vorstehenden Text der
        Datenschutzerklärung noch nicht getan haben. Sofern in diesem Rahmen
        Daten außerhalb der EU bzw. des EWR verarbeitet werden können,
        informieren wir Sie hierüber in der nachfolgenden Tabelle.
      </p>
      <table>
        <thead>
          <tr>
            <th>Auftragsverarbeiter</th>
            <th>Zweck</th>
            <th>Angemessenes Datenschutzniveau</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Metaways Infosystems GmbH</td>
            <td>Webhosting</td>
            <td>
              Angemessenes Datenschutzniveau: Verarbeitung innerhalb EU/EWR
            </td>
          </tr>
          <tr>
            <td>Amazon Web Services EMEA SARL</td>
            <td>Webhosting</td>
            <td>
              Angemessenes Datenschutzniveau: Verarbeitung innerhalb EU/EWR
            </td>
          </tr>
          <tr>
            <td>Bastian Brandt, Fa. BrainBox</td>
            <td>Webhosting</td>
            <td>
              Angemessenes Datenschutzniveau: Verarbeitung innerhalb EU/EWR
            </td>
          </tr>
        </tbody>
      </table>
      <h2>Ihre Rechte als betroffene Person</h2>
      <p>
        Bei der Verarbeitung Ihrer personenbezogenen Daten gewährt die DSGVO
        Ihnen als betroffene Person bestimmte Rechte:
      </p>
      <h3>Auskunftsrecht (Art. 15 DSGVO)</h3>
      <p>
        Sie haben das Recht eine Bestätigung darüber zu verlangen, ob Sie
        betreffende personenbezogene Daten verarbeitet werden; ist dies der
        Fall, so haben Sie ein Recht auf Auskunft über diese personenbezogenen
        Daten und auf die in Art. 15 DSGVO im Einzelnen aufgeführten
        Informationen.
      </p>
      <h3>Recht auf Berichtigung (Art. 16 DSGVO)</h3>
      <p>
        Sie haben das Recht, unverzüglich die Berichtigung Sie betreffender
        unrichtiger personenbezogener Daten und ggf. die Vervollständigung
        unvollständiger Daten zu verlangen.
      </p>
      <h3>Recht auf Löschung (Art. 17 DSGVO)</h3>
      <p>
        Sie haben das Recht, zu verlangen, dass Sie betreffende personenbezogene
        Daten unverzüglich gelöscht werden, sofern einer der in Art. 17 DSGVO im
        Einzelnen aufgeführten Gründe zutrifft.
      </p>
      <h3>Recht auf Einschränkung der Verarbeitung (Art. 18 DSGVO)</h3>
      <p>
        Sie haben das Recht, die Einschränkung der Verarbeitung zu verlangen,
        wenn eine der in Art. 18 DSGVO aufgeführten Voraussetzungen gegeben ist,
        z. B. wenn Sie Widerspruch gegen die Verarbeitung eingelegt haben, für
        die Dauer der Prüfung durch den Verantwortlichen.
      </p>
      <h3>Recht auf Datenübertragbarkeit (Art. 20 DSGVO)</h3>
      <p>
        In bestimmten Fällen, die in Art. 20 DSGVO im Einzelnen aufgeführt sind,
        haben Sie das Recht, die Sie betreffenden personenbezogenen Daten in
        einem strukturierten, gängigen und maschinenlesbaren Format zu erhalten
        bzw. die Übermittlung dieser Daten an einen Dritten zu verlangen.
      </p>
      <h3>Widerrufsrecht (Art. 7 DSGVO)</h3>
      <p>
        Sofern die Verarbeitung von Daten auf Grundlage Ihrer Einwilligung
        erfolgt, sind Sie nach Art. 7 Abs. 3 DSGVO berechtigt, die Einwilligung
        in die Verwendung Ihrer personenbezogenen Daten jederzeit zu widerrufen.
        Bitte beachten Sie, dass der Widerruf erst für die Zukunft wirkt.
        Verarbeitungen, die vor dem Widerruf erfolgt sind, sind davon nicht
        betroffen.
      </p>
      <h3>Widerspruchsrecht (Art. 21 DSGVO)</h3>
      <p>
        Werden Daten auf Grundlage von Art. 6 Abs. 1 S. 1 lit. f DSGVO
        (Datenverarbeitung zur Wahrung berechtigter Interessen) oder auf
        Grundlage von Art. 6 Abs. 1 S. 1 lit. e DSGVO (Datenverarbeitung zur
        Wahrung öffentlichen Interesse oder in Ausübung öffentlicher Gewalt)
        erhoben, steht Ihnen das Recht zu, aus Gründen, die sich aus Ihrer
        besonderen Situation ergeben, jederzeit gegen die Verarbeitung
        Widerspruch einzulegen. Wir verarbeiten die personenbezogenen Daten dann
        nicht mehr, es sei denn, es liegen nachweisbar zwingende schutzwürdige
        Gründe für die Verarbeitung vor, die gegenüber Ihren Interessen, Rechten
        und Freiheiten überwiegen, oder die Verarbeitung dient der
        Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen.
      </p>
      <h3>Beschwerderecht bei einer Aufsichtsbehörde (Art. 77 DSGVO)</h3>
      <p>
        Sie haben gem. Art. 77 DSGVO das Recht auf Beschwerde bei einer
        Aufsichtsbehörde, wenn Sie der Ansicht sind, dass die Verarbeitung der
        Sie betreffenden Daten gegen datenschutzrechtliche Bestimmungen
        verstößt. Das Beschwerderecht kann insbesondere bei einer
        Aufsichtsbehörde in dem Mitgliedstaat Ihres gewöhnlichen
        Aufenthaltsorts, Ihres Arbeitsplatzes oder des Orts des mutmaßlichen
        Verstoßes geltend gemacht werden.
      </p>
      <h3>Geltendmachung Ihrer Rechte</h3>
      <p>
        Sofern vorstehend nichts anderes beschrieben wurde, wenden Sie sich zur
        Geltendmachung Ihrer Betroffenenrechte bitte an die im Impressum
        genannte Stelle.
      </p>
      <h3>Kontaktdaten des Datenschutzbeauftragten</h3>
      <p>
        Unser externer Datenschutzbeauftragter steht Ihnen gerne für Auskünfte
        zum Thema Datenschutz unter folgenden Kontaktdaten zur Verfügung:
      </p>
      <p>
        datenschutz nord GmbH
        <br />
        Konsul-Smidt-Straße 88
        <br />
        28217 Bremen
        <br />
        Web:{' '}
        <a
          href="https://www.dsn-group.de/"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://www.dsn-group.de/
        </a>
        <br />
        E-Mail:{' '}
        <a href="mailto:office@datenschutz-nord.de">
          office@datenschutz-nord.de
        </a>
      </p>
      <p>
        Wenn Sie sich an unseren Datenschutzbeauftragten wenden, geben Sie
        hierbei bitte auch die verantwortliche Stelle an, die im Impressum
        genannt wird.
      </p>
    </>
  );
}

function EnglishPolicy(): ReactElement {
  return (
    <>
      <p>
        We are pleased that you are using our application, myCalc. The following
        information explains how we handle your data in accordance with Article
        13 of the General Data Protection Regulation (GDPR).
      </p>
      <h2>Controller</h2>
      <p>
        The party responsible for the collection and processing of data
        described below is the body stated in the{' '}
        <a
          href="https://website.dh-partner.com/INTERNATIONAL/en/Meta/Legal/Legal-notice"
          target="_blank"
          rel="noopener noreferrer"
        >
          Legal Notice
        </a>
      </p>
      <h2>Usage data</h2>
      <p>
        When you visit our websites, so-called usage data is temporarily
        evaluated on our web server as a log file for statistical purposes to
        improve the quality of our websites. This data record consists of:
      </p>
      <ul>
        <li>The name and address of the requested content,</li>
        <li>The date and time of the request,</li>
        <li>The amount of data transmitted,</li>
        <li>The access status (content transmitted, content not found),</li>
        <li>A description of the web browser and operating system used,</li>
        <li>
          The referral link, which indicates the page you accessed our page
          from,
        </li>
        <li>
          IP address of the computer used to access our website. This is
          truncated so that your identity cannot be determined.
        </li>
      </ul>
      <p>The specified log data is only stored in anonymised form.</p>
      <h2>Data security</h2>
      <p>
        We have implemented technical and organisational measures to protect
        your data against unauthorised access to the greatest extent possible.
        We use an encryption procedure on our websites. Your information is
        transmitted from your computer to our server and back again over the
        internet using TLS encryption. This is evident from the lock icon in the
        status bar of your browser and from the address line which begins with
        https:// .
      </p>
      <h2>Consent Banner</h2>
      <p>
        In our application, we use a consent or cookie banner. The logging of
        the settings you make is based on Art. 6 (1) (f) GDPR, in our legitimate
        interest to play out our content according to your preferences and to be
        able to prove your given consent(s). Your settings, the consent(s)
        granted in this way, and parts of your usage data are stored in a
        cookie. This means that it is preserved for subsequent page requests and
        your consents can continue to be traced. Further information on this can
        be found under the section "required cookies".
      </p>
      <h2>Google Analytics</h2>
      <p>
        This service is provided by: Google Ireland Limited, Gordon House,
        Barrow Street, Dublin 4, Ireland, a subsidiary of Google Inc., 1600
        Amphitheatre Parkway, Mountain View, CA 94043, USA.
      </p>
      <p>
        So that we can design our website to meet your requirements better, we
        create aliased usage profiles with Google Analytics, a web analytics
        service. Google Analytics uses cookies which are saved on your device
        and can be read. In this way we are able to recognise returning visitors
        and classify them as such.
      </p>
      <p>
        Data processing is carried out on the basis of your consent as per Art.
        6 (1) (a) of the GDPR (for example via our cookie banner) and in the
        interest of learning how frequently our websites are accessed by
        different users. You can revoke this consent at any time clicking{' '}
        <a
          style={{ textDecoration: 'underline', cursor: 'pointer' }}
          onClick={() => withdrawCookieConsent()}
        >
          here
        </a>
        .
      </p>
      <p>
        The data is processed on the basis of your consent as per Art. 6 (1) (a)
        of the General Data Protection Regulation (GDPR) (for example via our
        cookie banner), provided that you have given your consent via our
        banners.
      </p>
      <p>
        You may withdraw your consent to processing at any time. To do so,
        please use one of the following options:
        <ol>
          <li>
            You can prevent cookies from being saved by selecting the
            appropriate setting in your browser software. We would point out
            however that, in this case, you may not be able to fully use all
            features of this website
          </li>
          <li>
            In addition, you can prevent the collection of data generated by the
            cookie and related to the usage of the web site (including your IP
            address) by Google, and the processing of such data by Google, by
            downloading and installing the browser plug-in which is available
            at:&nbsp;
            <a
              href="http://tools.google.com/dlpage/gaoptout?hl=en"
              target="_blank"
              rel="noopener noreferrer"
            >
              http://tools.google.com/dlpage/gaoptout?hl=en
            </a>
            .&nbsp;
          </li>
        </ol>
      </p>
      <p>
        You can find out more about how user data is used by Google Analytics in
        Google’s privacy policy:
        <br />
        <a
          href="https://policies.google.com/privacy?hl=en"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://policies.google.com/privacy?hl=en
        </a>
      </p>
      <GoogleCookieTable />
      <h2>Demographics reporting via Google Analytics</h2>
      <p>
        This website uses the demographics reporting offered as part of Google
        Analytics. This feature can be used to generate reports on the age,
        gender and interests of site visitors. This data is generated on the
        basis of interest-related advertising from Google and visitor data
        supplied by third-party providers. This data cannot be associated with
        identifiable individuals. You can disable this function at any time by
        updating the ad settings in your Google account or prevent your data
        from being collected by Google Analytics in general as covered in the
        section on objecting to data collection.
      </p>
      <h2>Contact form</h2>
      <p>
        You may contact us using the online contact form. If you use our contact
        form, we will ask you to supply your name, contact details and e-mail
        address. You may provide additional details or upload files, but this is
        not necessary.
      </p>
      <p>
        The legal grounds for processing is Article 6 (1) (f) GDPR. Your data
        will only be processed to answer your query or for customer service
        purposes and will not be stored on our servers.
      </p>
      <p>
        The data will only be processed by employees of the D+H Group
        responsible for the performance of the contract. If required, personal
        data will be transmitted to companies participating in the performance
        of this contract. This includes subsidiaries of D+H and sales partners.
      </p>
      <h2>Google Maps</h2>
      <p>
        This service is provided by Google Inc., 1600 Amphitheatre Parkway,
        Mountain View, CA 94043, USA.
      </p>
      <p>
        This website uses persistent cookies from Google Inc. when the "Address"
        field is used to call up map information from Google Maps. You can use
        your browser settings to ensure that you are informed about any cookies
        placed on your computer, thus making the use of cookies transparent.
      </p>
      <p>
        When map material from Google Maps is called up, information about your
        use (in particular the IP address of your computer) may be transmitted
        to a server of Google Inc. in the United States and stored there.
      </p>
      <p>
        According to Art. 6 (1) (a) of the GDPR, we have the right to process
        your data provided that you have given your consent to the use of
        cookies (for example via our cookie banner). You can revoke this consent
        at any time clicking{' '}
        <a
          style={{ textDecoration: 'underline', cursor: 'pointer' }}
          onClick={() => withdrawCookieConsent()}
        >
          here
        </a>
        .
      </p>
      <p>
        We have no influence on the further processing of data by Google Inc. If
        you do not agree to having this data processed by Google Inc., please
        refrain from calling up the map material or deactivate the JavaScript
        function in your browser to obtain only a limited view.
      </p>
      <p>
        You can read more about Google’s privacy policy here:
        <br />
        <a
          href="https://policies.google.com/privacy?hl=en"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://policies.google.com/privacy?hl=en
        </a>
      </p>
      <GoogleCookieTable />
      <h2>Newsletter</h2>
      <p>
        On our websites, we offer the option to subscribe to our newsletter. If
        you have given us your express consent that we may send you information
        by e-mail about our company's products and services, this will be
        processed accordingly on the basis of Article 6 (1) (a) GDPR.
      </p>
      <p>
        Your consent may be withdrawn at any time without affecting the legality
        of any previous processing. If you withdraw your consent we will cease
        any corresponding data processing in the future.
      </p>
      <p>
        For the execution and management (documentation) of the newsletter, we
        strictly use the external service provider Freshworks Inc as a data
        processor according to Art. 28 GDPR, under strict instructions.
        Appropriate data protection agreements have been made with this service
        provider.
      </p>
      <p>
        If you decide in the future you no longer wish to receive the e-mail
        newsletter you may unsubscribe at any time by sending an e-mail to
        info@dh-partner.com or using the unsubscribe link contained in every
        newsletter.
      </p>
      <p>
        If you give us your permission during registration, we will assess how
        you personally use the offer or newsletter by analysing when the
        newsletter we sent to you was viewed or opened, and also customise the
        links in the newsletter, so we can evaluate when you clicked on which
        link and which content you clicked on. We do this to improve our content
        and prepare personal offers tailored to your specific needs.
      </p>
      <p>
        You can withdraw or modify your consent by clicking on the link provided
        in every newsletter.
      </p>
      <h2>Access-protected area</h2>
      <p>
        If you would like to use our myCalc access-protected area for planning
        and calculating offers as a customer, then prior registration or login
        is required for this service. In this case, we only collect the data
        required for registration or later use of this individual offer as a
        customer. The data is processed on the basis of Art. 6 (1) 1. (b) of the
        GDPR for the provision and use of this individual service, or on the
        basis of Art. 6 (1) (f) of the GDPR in the interest of providing you
        with the services and information available in the access-protected
        area. Where we collect other data from you that goes beyond this and is
        not absolutely necessary, this will be labelled optional and is based on
        your consent pursuant to Art. 6 (1) (a) of the GDPR. You may withdraw
        this consent at any time, effective from that point forward, for
        example, by independently deleting the data. If you would like to
        permanently unsubscribe from our access-protected area, please use the
        unsubscribe option available in the area for this purpose. If you have
        any further questions or change requests, please send us a message or
        use the contact form.
      </p>
      <h2>Additional Data Processors</h2>
      <p>
        We pass on your data in the context of order processing according to
        Art. 28 GDPR to service providers who support us in operating our
        websites and the associated processes. These include, for example,
        hosting service providers. Our service providers are strictly bound by
        our instructions and are accordingly contractually obligated.
      </p>
      <p>
        Below we list the data processors with whom we cooperate, provided we
        have not already done so in the preceding text of the privacy statement.
        If data can be processed outside the EU or the EEA in this context, we
        will inform you about this in the table below.
      </p>
      <table>
        <thead>
          <tr>
            <th>Data Processor</th>
            <th>Purpose</th>
            <th>Level of Data Protection</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Metaways Infosystems GmbH</td>
            <td>Purpose: Webhosting</td>
            <td>
              Appropriate Level of Data Protection: Processing within EU/EEA
            </td>
          </tr>
          <tr>
            <td>Bastian Brandt, Company BrainBox</td>
            <td>Purpose: Webhosting</td>
            <td>
              Appropriate Level of Data Protection: Processing within EU/EEA
            </td>
          </tr>
        </tbody>
      </table>
      <h2>Your rights as a user</h2>
      <p>
        The GDPR grants website users certain rights regarding the processing of
        their personal data:
      </p>
      <ol>
        <li>
          Right of access (Article 15 GDPR): You have the right to obtain
          confirmation as to whether or not personal data concerning yourself is
          being processed, and, where that is the case, to access the personal
          data and the information listed in Article 15 GDPR
        </li>
        <li>
          You have the right to obtain without undue delay the rectification of
          inaccurate personal data concerning yourself and, if applicable, to
          have incomplete personal data completed.
          <br />
          You also have the right to obtain the erasure of personal data
          concerning yourself without undue delay where one of the grounds
          listed in Article 17 GDPR applies, for example where the personal data
          is no longer necessary in relation to the purposes pursued.
        </li>
        <li>
          Right to restriction of processing (Article 18 GDPR): You have the
          right to obtain restriction of processing where one of the
          requirements listed in Article 18 GDPR applies, for example where you
          have objected to processing for the duration of a possible
          investigation.
        </li>
        <li>
          Right to data portability (Article 20 GDPR): In certain cases, which
          are listed in detail in Article 20 GDPR, you have the right to receive
          the personal data concerning yourself in a structured, commonly used
          and machine-readable format and to have this data transmitted to a
          third party.
        </li>
        <li>
          Right to object (Article 21 GDPR): Where data has been collected on
          the basis of Article 6 (1) (f) (data processing to protect legitimate
          interests), you have the right to object, on grounds relating to your
          particular situation, to processing at any time. We will no longer
          process the personal data unless there are demonstrable compelling
          legitimate grounds for the processing which override the interests,
          rights and freedoms of the data subject, or where processing is
          necessary for the establishment, exercise or defence of legal claims.
        </li>
        <li>
          Right to lodge a complaint with a supervisory authority: Pursuant to
          Article 77 GDPR, you have the right to lodge a complaint with a
          supervisory authority if you consider that the processing of your
          personal data infringes data protection law. The right to lodge a
          complaint with a supervisory authority may, in particular, be
          exercised in the member state of your habitual residence, place of
          work or place of the alleged infringement.
        </li>
      </ol>
      <h3>Data Protection Officer's contact details</h3>
      <p>
        Please do not hesitate to contact our Data Protection Officer for more
        information or if you have any suggestions regarding data protection:
      </p>
      <p>
        datenschutz nord GmbH
        <br />
        Konsul-Smidt-Straße 88
        <br />
        28217 Bremen
        <br />
        Web:{' '}
        <a
          href="https://www.dsn-group.de/"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://www.dsn-group.de/
        </a>
        <br />
        E-Mail:{' '}
        <a href="mailto:office@datenschutz-nord.de">
          office@datenschutz-nord.de
        </a>
      </p>
    </>
  );
}

export function PrivacyPolicy() {
  const locale: Locale = useSelector(
    (state: IState) => state.authentication.locale,
  );

  return (
    <article className="privacy-policy">
      {locale === Locale.DE ? <GermanPolicy /> : <EnglishPolicy />}
    </article>
  );
}

export default function PrivacyPolicyModal() {
  const locale: Locale = useSelector(
    (state: IState) => state.authentication.locale,
  );

  return (
    <DialogFE
      componentClass=""
      heading={locale === Locale.DE ? 'Datenschutzerklärung' : 'Privacy Policy'}
    >
      <PrivacyPolicy />
    </DialogFE>
  );
}
