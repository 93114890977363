import { Dispatch, SetStateAction, useState } from 'react';
import { containingInSearchString } from './general/helpers';
import { AdminState } from '../redux/admin/adminStore';
import { IState } from '../redux/store';
import { ACTIVE_STATE } from '../redux/admin/adminRoofReducer';

export function useAdminSearch<T extends { active: boolean | ACTIVE_STATE }>(
  elements: T[] | undefined,
  searchString: string,
  searchCriterias: (keyof T)[],
  additionalSearchCriterias?: (e: T) => string[],
): [boolean, Dispatch<SetStateAction<boolean>>, T[]] {
  const [filterActive, setFilterActive] = useState(false);
  const splittedSearchString = searchString.split(/\s+/);

  function search(): T[] {
    return (elements || [])
      .filter(s =>
        containingInSearchString(s, splittedSearchString, [
          ...searchCriterias.map(c =>
            (s[c] || ('' as string | number))?.toString(),
          ),
          ...(additionalSearchCriterias ? additionalSearchCriterias(s) : []),
        ]),
      )
      .filter(s =>
        filterActive
          ? s.active === ACTIVE_STATE.ACTIVE || s.active === true
          : true,
      );
  }

  return [filterActive, setFilterActive, search()];
}

export function useNumberState(
  number?: number | undefined | null,
): [
  number | undefined | null,
  Dispatch<SetStateAction<number | undefined | null>>,
] {
  return useState<number | undefined | null>(number);
}
