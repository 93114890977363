import './TabNavigation.scss';
import React, { FC, ReactNode, useState } from 'react';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';

interface TabNavigationProps {
  tabNavItems: TabNavItemProps[];
  withSingleTabNavItem?: boolean;
  activeTab?: number;
}

const TabNavigation: FC<React.PropsWithChildren<TabNavigationProps>> = (
  props: TabNavigationProps,
) => {
  const [activeTab, setActiveTab] = useState<number>(props.activeTab || 0);
  const withSingleTabNavItem =
    props.tabNavItems.length === 1 &&
    props.tabNavItems[0].label === 'partly_qualified_drives';

  function isActive(index: number): boolean {
    if (withSingleTabNavItem) {
      return false;
    }
    return props.tabNavItems.length > 1 && activeTab === index;
  }

  return (
    <div className="tab-nav-section">
      <ul className="tab-nav-section__tab-nav">
        {props.tabNavItems.map((tabNavItem, index) => (
          <TabNavItem
            label={tabNavItem.label}
            active={isActive(index)}
            action={() => {
              tabNavItem.action && tabNavItem.action();
              index != activeTab && setActiveTab(index);
            }}
            body={tabNavItem.body}
            key={tabNavItem.label}
            withSingleTabNavItem={props.withSingleTabNavItem}
          />
        ))}
      </ul>
      {props.tabNavItems[activeTab]!.body}
    </div>
  );
};

export interface TabNavItemProps {
  label: string;
  active?: boolean;
  action?: () => any;
  body?: ReactNode;
  withSingleTabNavItem?: boolean;
}

export const TabNavItem: FC<React.PropsWithChildren<TabNavItemProps>> = (
  props: TabNavItemProps,
) => {
  return (
    <>
      <li
        key={props.label}
        className={classNames(
          'tab-nav-section__tab-nav-item',
          {
            'tab-nav-section__tab-nav-item--active': props.active,
          },
          {
            'tab-nav-section__tab-nav-item--single-tab':
              props.withSingleTabNavItem,
          },
        )}
        onClick={() => {
          props.action && props.action();
        }}
      >
        <FormattedMessage id={props.label} />
      </li>
    </>
  );
};

export default TabNavigation;
