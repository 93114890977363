import * as React from 'react';
//@ts-ignore
const SvgDoppelklappeDrehOhneWlw = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="Doppelklappe_Dreh_ohne_WLW_svg__Ebene_2"
    data-name="Ebene 2"
    viewBox="0 0 1440 1080"
    {...props}
    style={{ marginBottom: '-170px', marginTop: '-170px' }}
  >
    <defs>
      <style>
        {
          '.Doppelklappe_Dreh_ohne_WLW_svg__cls-1{stroke-dasharray:0 0 5.03 5.03}.Doppelklappe_Dreh_ohne_WLW_svg__cls-1,.Doppelklappe_Dreh_ohne_WLW_svg__cls-3,.Doppelklappe_Dreh_ohne_WLW_svg__cls-4,.Doppelklappe_Dreh_ohne_WLW_svg__cls-6,.Doppelklappe_Dreh_ohne_WLW_svg__cls-7,.Doppelklappe_Dreh_ohne_WLW_svg__cls-8{stroke:#1e1e1c;fill:none}.Doppelklappe_Dreh_ohne_WLW_svg__cls-1,.Doppelklappe_Dreh_ohne_WLW_svg__cls-4,.Doppelklappe_Dreh_ohne_WLW_svg__cls-6,.Doppelklappe_Dreh_ohne_WLW_svg__cls-7,.Doppelklappe_Dreh_ohne_WLW_svg__cls-8{stroke-miterlimit:10}.Doppelklappe_Dreh_ohne_WLW_svg__cls-1,.Doppelklappe_Dreh_ohne_WLW_svg__cls-4,.Doppelklappe_Dreh_ohne_WLW_svg__cls-7{stroke-width:.75px}.Doppelklappe_Dreh_ohne_WLW_svg__cls-3{stroke-linejoin:round}.Doppelklappe_Dreh_ohne_WLW_svg__cls-10{font-size:30px}.Doppelklappe_Dreh_ohne_WLW_svg__cls-10,.Doppelklappe_Dreh_ohne_WLW_svg__cls-11,.Doppelklappe_Dreh_ohne_WLW_svg__cls-13,.Doppelklappe_Dreh_ohne_WLW_svg__cls-14{fill:#1e1e1c}.Doppelklappe_Dreh_ohne_WLW_svg__cls-10,.Doppelklappe_Dreh_ohne_WLW_svg__cls-11,.Doppelklappe_Dreh_ohne_WLW_svg__cls-14{font-family:ArialMT,Arial}.Doppelklappe_Dreh_ohne_WLW_svg__cls-17{opacity:.3;fill:#b0b0b1}.Doppelklappe_Dreh_ohne_WLW_svg__cls-11{font-size:27.5px}.Doppelklappe_Dreh_ohne_WLW_svg__cls-3,.Doppelklappe_Dreh_ohne_WLW_svg__cls-6{stroke-width:2px}.Doppelklappe_Dreh_ohne_WLW_svg__cls-4{stroke-dasharray:0 0 5.45 5.45}.Doppelklappe_Dreh_ohne_WLW_svg__cls-14{font-size:27.5px}'
        }
      </style>
    </defs>
    <path
      d="m943.82 472.16 71.35 74.45-356.9 176.94 285.55-251.39z"
      style={{
        fill: '#ececec',
      }}
    />
    <path
      d="M657.25 723.31 942.8 471.92 710.39 356.45 424.83 607.84l106.67 52.99 94.75-105.95-43.14-20.8 69.21-59.69 128.11 63.73-68.35 60.11-85.83-43.35-94.75 105.95 125.75 62.48z"
      style={{
        fill: '#b0b0b1',
      }}
    />
    <path
      d="m705.86 501.03-67.94 59.75 9.38 4.74 67.82-59.89-9.26-4.6z"
      style={{
        strokeLinejoin: 'round',
        fill: '#575656',
        stroke: '#1e1e1c',
      }}
    />
    <path
      d="m583.98 534.5 5.01-55.58 68.31-60.55-4.98 56.02-68.34 60.11zM710.92 597.94l-4.28-54.78 69.87-61.34 2.76 56.01-68.35 60.11z"
      className="Doppelklappe_Dreh_ohne_WLW_svg__cls-17"
    />
    <path
      d="m942.89 472.16 71.34 74.45-356.9 176.94 285.56-251.39z"
      className="Doppelklappe_Dreh_ohne_WLW_svg__cls-3"
    />
    <path
      d="M942.8 471.92 710.39 356.45 424.83 607.84l232.42 115.47"
      className="Doppelklappe_Dreh_ohne_WLW_svg__cls-6"
    />
    <path
      d="m712.08 598.23-128.1-63.73 68.34-60.11 128.11 63.73-68.35 60.11z"
      className="Doppelklappe_Dreh_ohne_WLW_svg__cls-6"
    />
    <path
      d="m712.08 598.23-5.44-55.07 69.87-61.34 3.92 56.3-68.35 60.11zM583.98 534.5l5.01-55.58 68.31-60.55-4.98 56.02-68.34 60.11z"
      className="Doppelklappe_Dreh_ohne_WLW_svg__cls-3"
    />
    <path
      d="m790.32 552.42-55.04 48.36"
      className="Doppelklappe_Dreh_ohne_WLW_svg__cls-8"
    />
    <path
      d="m786.92 551.55 10.06-4.98-6.23 9.34-3.83-4.36zM734.85 597.29l-6.23 9.34 10.06-4.97-3.83-4.37z"
      className="Doppelklappe_Dreh_ohne_WLW_svg__cls-13"
    />
    <path
      d="m811.25 553.27-2.24-1.11"
      className="Doppelklappe_Dreh_ohne_WLW_svg__cls-7"
    />
    <path
      d="m804.51 549.92-15.77-7.81"
      className="Doppelklappe_Dreh_ohne_WLW_svg__cls-1"
    />
    <path
      d="m786.49 540.99-2.24-1.11"
      className="Doppelklappe_Dreh_ohne_WLW_svg__cls-7"
    />
    <path
      d="m685.3 607.58-111.15-55.22"
      className="Doppelklappe_Dreh_ohne_WLW_svg__cls-8"
    />
    <path
      d="m684.81 604.09 8.42 7.43-11-2.22 2.58-5.21zM577.22 550.64l-11-2.23 8.41 7.43 2.59-5.2z"
      className="Doppelklappe_Dreh_ohne_WLW_svg__cls-13"
    />
    <path
      d="m580.14 537.78-1.88 1.65"
      className="Doppelklappe_Dreh_ohne_WLW_svg__cls-7"
    />
    <path
      d="M574.16 543.02 559.8 555.6"
      className="Doppelklappe_Dreh_ohne_WLW_svg__cls-4"
    />
    <path
      d="m557.75 557.4-1.88 1.65M708.09 600.39l-1.88 1.65"
      className="Doppelklappe_Dreh_ohne_WLW_svg__cls-7"
    />
    <path
      d="m702.11 605.63-14.36 12.58"
      className="Doppelklappe_Dreh_ohne_WLW_svg__cls-4"
    />
    <path
      d="m685.7 620.01-1.88 1.65M742.12 613.78l-2.24-1.11"
      className="Doppelklappe_Dreh_ohne_WLW_svg__cls-7"
    />
    <path
      d="m735.38 610.44-15.77-7.82"
      className="Doppelklappe_Dreh_ohne_WLW_svg__cls-1"
    />
    <path
      d="m717.36 601.5-2.24-1.11"
      className="Doppelklappe_Dreh_ohne_WLW_svg__cls-7"
    />
    <path
      d="M815.41 634.42c-5.8-7.19-12.35-13.75-19.52-19.57"
      className="Doppelklappe_Dreh_ohne_WLW_svg__cls-8"
    />
    <path
      d="m816.48 631.12 4.26 10.38-8.88-6.86 4.62-3.52zM799.2 613.8l-10.37-4.29 6.84 8.9 3.53-4.61z"
      className="Doppelklappe_Dreh_ohne_WLW_svg__cls-13"
    />
    <path
      d="m670.86 519.31-36.36-18.09"
      className="Doppelklappe_Dreh_ohne_WLW_svg__cls-8"
    />
    <path
      d="m670.38 515.82 8.41 7.43-11-2.22 2.59-5.21zM637.57 499.5l-11-2.23 8.41 7.43 2.59-5.2z"
      className="Doppelklappe_Dreh_ohne_WLW_svg__cls-13"
    />
    <path
      d="m664.67 499.12 19.18 9.54"
      className="Doppelklappe_Dreh_ohne_WLW_svg__cls-8"
    />
    <path
      d="m680.78 510.38 11 2.23-8.41-7.43-2.59 5.2z"
      className="Doppelklappe_Dreh_ohne_WLW_svg__cls-13"
    />
    <path
      d="m827.07 446.31-96.84 84.91-19.65-9.78"
      style={{
        strokeLinejoin: 'round',
        fill: 'none',
        stroke: '#1e1e1c',
      }}
    />
    <path
      d="m713.65 519.72-11-2.23 8.41 7.43 2.59-5.2z"
      className="Doppelklappe_Dreh_ohne_WLW_svg__cls-13"
    />
    <text
      className="Doppelklappe_Dreh_ohne_WLW_svg__cls-14"
      transform="rotate(-41.22 1186.633 -713.17)"
    >
      <tspan x={0} y={0}>
        {props.heightH}
      </tspan>
    </text>
    <text
      style={{
        fontSize: 30,
        fontFamily: 'ArialMT,Arial',
        fill: '#1e1e1c',
      }}
      transform="rotate(-41.22 1190.747 -742.55) scale(.58)"
    >
      <tspan x={0} y={0}>
        {props.frameFR}
      </tspan>
    </text>
    <text
      className="Doppelklappe_Dreh_ohne_WLW_svg__cls-11"
      transform="rotate(25.4 -999.645 1582.035)"
    >
      <tspan x={0} y={0}>
        {props.widthB}
      </tspan>
    </text>
    <text
      className="Doppelklappe_Dreh_ohne_WLW_svg__cls-10"
      transform="rotate(25.4 -1031.736 1621.09) scale(.58)"
    >
      <tspan x={0} y={0}>
        {props.doubleFlapFRZK}
      </tspan>
    </text>
    <text
      style={{
        fontSize: '27.5px',
        fontFamily: 'ArialMT,Arial',
        fill: '#1e1e1c',
      }}
      transform="translate(784.96 641.34)"
    >
      <tspan x={0} y={0}>
        {'\u03B1'}
      </tspan>
    </text>
    <path
      d="m720.75 612.93-96.53 84.82"
      className="Doppelklappe_Dreh_ohne_WLW_svg__cls-8"
    />
    <path
      d="m717.35 612.06 10.06-4.98-6.23 9.34-3.83-4.36zM623.79 694.25l-6.23 9.34 10.07-4.97-3.84-4.37z"
      className="Doppelklappe_Dreh_ohne_WLW_svg__cls-13"
    />
    <path
      d="m897.77 458.7-93.36 82.03"
      className="Doppelklappe_Dreh_ohne_WLW_svg__cls-8"
    />
    <path
      d="m894.36 457.82 10.06-4.97-6.22 9.34-3.84-4.37zM803.98 537.24l-6.23 9.33 10.06-4.97-3.83-4.36z"
      className="Doppelklappe_Dreh_ohne_WLW_svg__cls-13"
    />
    <text
      style={{
        letterSpacing: '-.06em',
        fontSize: '27.5px',
        fontFamily: 'ArialMT,Arial',
        fill: '#1e1e1c',
      }}
      transform="rotate(-41.22 1134.507 -864.983)"
    >
      <tspan x={0} y={0}>
        {props.distanceNRWGToRidgeFA}
      </tspan>
    </text>
    <text
      className="Doppelklappe_Dreh_ohne_WLW_svg__cls-14"
      transform="rotate(-41.22 1245.453 -560.918)"
    >
      <tspan
        x={0}
        y={0}
        style={{
          letterSpacing: '-.07em',
        }}
      >
        {props.distanceNRWGToEavesTA}
      </tspan>
    </text>
    <text
      className="Doppelklappe_Dreh_ohne_WLW_svg__cls-14"
      transform="rotate(-41.22 1030.398 -799.803)"
    >
      <tspan x={0} y={0}>
        {props.sashFrameDistanceFRA}
      </tspan>
    </text>
    <text
      className="Doppelklappe_Dreh_ohne_WLW_svg__cls-11"
      transform="rotate(25.4 -845.549 1624.942)"
    >
      <tspan x={0} y={0}>
        {props.widthB}
      </tspan>
    </text>
    <text
      className="Doppelklappe_Dreh_ohne_WLW_svg__cls-10"
      transform="rotate(25.4 -877.64 1663.997) scale(.58)"
    >
      <tspan x={0} y={0}>
        {props.frameFR}
      </tspan>
    </text>
  </svg>
);
export default SvgDoppelklappeDrehOhneWlw;
