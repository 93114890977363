import React, { ReactElement, useEffect, useRef } from 'react';
import readXlsxFile from 'read-excel-file';
import { Cell } from 'read-excel-file/types';
import TextArea from './TextArea';

export function CSVInput<T>(props: {
  disabled: boolean;
  value: string;
  onChange: (s: T[]) => void;
  arrayToObject: (values: number[]) => T;
}): ReactElement {
  const fileInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    function readCVS(): void {
      if (fileInputRef.current !== null && fileInputRef.current.files) {
        readXlsxFile(fileInputRef.current.files[0]).then(rows => {
          const cvValues: T[] = [];
          rows.forEach(row => {
            if (
              isNumberOrStringNumber(row[0]) &&
              isNumberOrStringNumber(row[1])
            ) {
              cvValues.push(props.arrayToObject(row.map(el => +el)));
            }
          });
          props.onChange(cvValues);
        });
      }
    }

    if (fileInputRef.current !== null) {
      fileInputRef.current.addEventListener('change', readCVS);
    }

    return () => {
      fileInputRef.current &&
        fileInputRef.current.removeEventListener('change', readCVS);
    };
  }, [fileInputRef.current]);

  function isNumberOrStringNumber(value: Cell): boolean {
    if (typeof value === 'number') {
      return true;
    }

    if (typeof value === 'string' && !isNaN(+value)) {
      return true;
    }

    return false;
  }

  return (
    <div className="cv-data-dialog__values-content">
      <label
        htmlFor="cvs-file-upload"
        className="button button--frame cv-data-dialog__file-upload"
      >
        Excel-Datei hochladen
      </label>
      <input
        type="file"
        id="cvs-file-upload"
        ref={fileInputRef}
        className="cv-data-dialog__input"
      />
      <TextArea
        disabled={props.disabled}
        label=""
        placeholder="Grad - Durchflusswert"
        value={props.value}
      />
    </div>
  );
}
