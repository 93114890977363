import { Action, ActionCreator, AnyAction, Dispatch } from 'redux';
import {
  BaseProfile,
  ExchangeProfile,
  FrameProfile,
  SashProfile,
  SystemSeries,
} from './profileDataReducer';
import { ThunkAction } from 'redux-thunk';
import { IState } from './store';
import { fetchAndStore, RequestTypes } from './httpClient';

export interface UpdateSystemSeriesResult
  extends Action<'UPDATE_SYSTEM_SERIES_RESULT'> {
  systemSeriesResult: SystemSeries[];
}

export interface UpdateSashProfilesAction
  extends Action<'UPDATE_SASH_PROFILES'> {
  sashProfiles: SashProfile[];
}

export interface UpdateExchangeProfilesAction
  extends Action<'UPDATE_EXCHANGE_PROFILES'> {
  exchangeProfiles: ExchangeProfile[];
}

export interface UpdateBaseProfilesAction
  extends Action<'UPDATE_BASE_PROFILES'> {
  baseProfiles: BaseProfile[];
}

export interface UpdateFrameProfilesAction
  extends Action<'UPDATE_FRAME_PROFILES'> {
  frameProfiles: FrameProfile[];
}

export function releaseProfileData(): ThunkAction<
  Promise<void>,
  IState,
  void,
  AnyAction
> {
  return async (dispatch: Dispatch): Promise<void> => {
    dispatch(updateSystemSeriesResult([]));
  };
}

export function getSystemSeriesResult(): ThunkAction<
  Promise<void>,
  IState,
  void,
  AnyAction
> {
  return async (dispatch: Dispatch, getState: () => IState): Promise<void> => {
    return fetchAndStore(
      RequestTypes.SYSTEM_SERIES,
      (systemSeries: SystemSeries[]) =>
        dispatch(updateSystemSeriesResult(systemSeries)),
      getState,
    );
  };
}

export const getSashProfileResult: ActionCreator<
  ThunkAction<Promise<void>, IState, void, AnyAction>
> = (series: SystemSeries) => {
  return async (dispatch: Dispatch, getState: () => IState): Promise<void> => {
    return fetchAndStore(
      RequestTypes.SASH_PROFILES,
      (sashProfiles: SashProfile[]) =>
        dispatch(updateSashProfilesAction(sashProfiles)),
      getState,
      { args: { profileSeriesId: series.id } },
    );
  };
};

export const getFrameProfileResult: ActionCreator<
  ThunkAction<Promise<void>, IState, void, AnyAction>
> = (series: SystemSeries) => {
  return async (dispatch: Dispatch, getState: () => IState): Promise<void> => {
    return fetchAndStore(
      RequestTypes.FRAME_PROFILES,
      (frameProfiles: FrameProfile[]) =>
        dispatch(updateFrameProfilesAction(frameProfiles)),
      getState,
      { args: { profileSeriesId: series.id } },
    );
  };
};

export const getExchangeProfileResult: ActionCreator<
  ThunkAction<Promise<void>, IState, void, AnyAction>
> = (series: SystemSeries) => {
  return async (dispatch: Dispatch, getState: () => IState): Promise<void> => {
    return fetchAndStore(
      RequestTypes.EXCHANGE_PROFILES,
      (exchangeProfiles: ExchangeProfile[]) =>
        dispatch(updateExchangeProfilesAction(exchangeProfiles)),
      getState,
      { args: { profileSeriesId: series.id } },
    );
  };
};

export const getBaseProfileResult: ActionCreator<
  ThunkAction<Promise<void>, IState, void, AnyAction>
> = (series: SystemSeries) => {
  return async (dispatch: Dispatch, getState: () => IState): Promise<void> => {
    return fetchAndStore(
      RequestTypes.BASE_PROFILES,
      (baseProfiles: BaseProfile[]) =>
        dispatch(updateBaseProfilesAction(baseProfiles)),
      getState,
      { args: { profileSeriesId: series.id } },
    );
  };
};

export function updateSystemSeriesResult(
  systemSeriesResult: SystemSeries[],
): UpdateSystemSeriesResult {
  return {
    type: 'UPDATE_SYSTEM_SERIES_RESULT',
    systemSeriesResult: systemSeriesResult,
  };
}

export function updateSashProfilesAction(
  sashProfiles: SashProfile[],
): UpdateSashProfilesAction {
  return {
    type: 'UPDATE_SASH_PROFILES',
    sashProfiles: sashProfiles,
  };
}

export function updateFrameProfilesAction(
  frameProfiles: FrameProfile[],
): UpdateFrameProfilesAction {
  return {
    type: 'UPDATE_FRAME_PROFILES',
    frameProfiles: frameProfiles,
  };
}

export function updateExchangeProfilesAction(
  exchangeProfiles: ExchangeProfile[],
): UpdateExchangeProfilesAction {
  return {
    type: 'UPDATE_EXCHANGE_PROFILES',
    exchangeProfiles: exchangeProfiles,
  };
}

export function updateBaseProfilesAction(
  baseProfiles: BaseProfile[],
): UpdateBaseProfilesAction {
  return {
    type: 'UPDATE_BASE_PROFILES',
    baseProfiles: baseProfiles,
  };
}
