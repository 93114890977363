import React, { useState } from 'react';
import classNames from 'classnames';
import { ActiveFurtherInformationUiSection } from '../constants';
import FieldLabel from '../../elements/FieldLabel';
import AssignSelectGroupDialog from './AssignSelectGroup';
import '../facade/TestPhases.scss';

interface TestPhaseSelectFieldProps<T> {
  componentClassIdentifier: string;
  isActive: boolean;
  selectKey?: string;
  setActiveSection: (section: ActiveFurtherInformationUiSection) => void;
  label: string;
  withCopyFunction?: boolean;
  dialogHeadings: { dialog: string; leftTable: string; rightTable: string };
  onFocus: () => void;
  selectedElements: T[];
  deselectedElements: T[];
  displayNameExtractor: (v: T) => string;
  saveAction: (selectedElements: T[], deselectedElements: T[]) => void;
  elementSelected: (element: T) => void;
  copyButtonPressed?: () => void;
  highlightSelectedElement: boolean;
}

const TestPhaseSelectField = <T,>(
  props: TestPhaseSelectFieldProps<T>,
): JSX.Element | null => {
  const [dialogIsShown, setDialogIsShown] = useState<boolean>(false);
  const [markedElement, setMarkedElement] = useState<number | undefined>();

  if (!props.selectedElements || !props.deselectedElements) {
    return null;
  }

  return (
    <>
      <div
        className={classNames(
          `test-phases__select test-phases__select-${props.componentClassIdentifier}`,
          {
            'test-phases__select--active': props.isActive,
            'test-phases__select--highlight-selected':
              !props.highlightSelectedElement,
          },
        )}
      >
        <FieldLabel>{props.label}</FieldLabel>
        <select
          // key={props.selectKey}
          size={Math.max(2, 5 || 1)}
          onFocus={() => {
            props.onFocus();
            props.elementSelected(props.selectedElements[markedElement || 0]);
          }}
          onChange={e => {
            props.elementSelected(props.selectedElements[+e.target.value]);
            setMarkedElement(+e.target.value);
          }}
        >
          {props.selectedElements.map((tps, index) => (
            <option
              key={index}
              value={index}
              className={classNames('', {
                'assign-component__option-highlight-path':
                  markedElement === index && props.highlightSelectedElement,
              })}
            >
              {props.displayNameExtractor(tps)}
            </option>
          ))}
        </select>
        <button
          onClick={e => {
            e.preventDefault();
            setDialogIsShown(true);
          }}
        >
          Liste editieren
        </button>
        {props.withCopyFunction && (
          <button
            onClick={e => {
              e.preventDefault();
              if (props.copyButtonPressed) {
                props.copyButtonPressed();
              }
            }}
          >
            Kopieren
          </button>
        )}
      </div>
      <AssignSelectGroupDialog
        dialogHeading={props.dialogHeadings.dialog}
        componentClass=""
        dialogIsShown={dialogIsShown}
        headings={{
          headingLeftTable: props.dialogHeadings.leftTable,
          headingRightTable: props.dialogHeadings.rightTable,
        }}
        setDialogIsShown={setDialogIsShown}
        displayNameExtractor={props.displayNameExtractor}
        selectedElements={props.selectedElements}
        deselectedElements={props.deselectedElements}
        saveAction={props.saveAction}
      />
    </>
  );
};

export default TestPhaseSelectField;
