import React, { FC } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import SystemDataView from '../general/SystemDataView';
import { RangeOfApplication } from '../../redux/constants';
import SeriesDataView from '../general/SeriesDataView';
import RoofFrameProfileDataView from './RoofFrameProfileData';
import RoofSashProfileDataView from './RoofSashProfileData';
import CVDataView from '../general/CVDataView';
import DrivesData from '../general/DriveGroupsDataView';
import ConsoleAreaConsolesDataView from '../facade/ConsoleAreaConsolesDataView';
import ConsoleAreaConsoleSetsDataView from '../facade/ConsoleAreaConsoleSetsDataView';
import ConsoleAreaConsoleSetAssignmentsDataView from '../facade/ConsoleAreaConsoleSetAssignmentsDataView';
import TestPhasesDataViewRoof from '../facade/RoofTestPhases';
import { useDispatch, useSelector } from 'react-redux';
import { useSeriesENMaxStrokesRoof } from '../../hooks/selectorHooks';
import RoofMaxStrokesDataView from './RoofMaxStrokesDataView';
import WindDeflectorDataView from './WindDeflectorDataView';
import { updateEditedSeriesENMaxStrokesRoof } from '../../redux/admin/adminRoofActions';
import { IState } from '../../redux/store';
import { LoggedInUser } from '../../redux/authenticationReducer';
import { AnyAction } from 'redux';
import { AdminThunkDispatch } from '../../redux/admin/adminStore';
import AdminNavLink from '../common/AdminNavLink';

const TabNavRoof: FC<React.PropsWithChildren<unknown>> = () => {
  const dispatch: AdminThunkDispatch<AnyAction> = useDispatch();
  const strokes = useSeriesENMaxStrokesRoof();

  return (
    <div className="admin__tab-nav">
      <AdminNavLink to="/admin/roof/systems">Systeme</AdminNavLink>
      <AdminNavLink to="/admin/roof/series">Serien</AdminNavLink>
      <AdminNavLink to="/admin/roof/profiles">Profile</AdminNavLink>
      <AdminNavLink to="/admin/roof/cv">CV-Kurven</AdminNavLink>
      <AdminNavLink to="/admin/roof/test-phases">Prüfphasen</AdminNavLink>

      <AdminNavLink to="/admin/roof/consoles">Konsolen</AdminNavLink>

      <AdminNavLink
        to="/admin/roof/max-strokes"
        onClick={() => dispatch(updateEditedSeriesENMaxStrokesRoof(strokes!))}
      >
        Maximale Hübe
      </AdminNavLink>

      <AdminNavLink to="/admin/roof/wind-deflectors">
        Windleitwände
      </AdminNavLink>
    </div>
  );
};

const RoofArea: FC<React.PropsWithChildren<unknown>> = () => {
  const currentUser = useSelector<IState, LoggedInUser | undefined>(
    (state: IState) => state.authentication.currentUser,
  );

  if (!currentUser?.admin) {
    return null;
  }

  return (
    <div className="admin__main">
      <TabNavRoof />
      <Routes>
        <Route path="/profiles/*" element={<RoofProfiles />} />
        <Route path="/consoles/*" element={<RoofConsoles />} />
      </Routes>
      <Routes>
        <Route path="/systems/*" element={<RoofSystems />} />
        <Route path="/series/*" element={<RoofSeries />} />
        <Route path="/profiles/*" element={<RoofProfiles />} />
        <Route
          path="/profiles"
          element={
            <Navigate replace to="/admin/roof/profiles/frame-profiles" />
          }
        />

        <Route
          path="/profiles/frame-profiles/*"
          element={<RoofProfilesFrameProfiles />}
        />
        <Route
          path="/profiles/sash-profiles/*"
          element={<RoofProfilesSashProfiles />}
        />
        <Route path="/cv/*" element={<RoofCV />} />
        <Route path="/test-phases/*" element={<RoofTestPhasesTestPhases />} />
        <Route path="/drives/*" element={<RoofDrives />} />

        <Route
          path="/consoles"
          element={<Navigate replace to="/admin/roof/consoles/consoles" />}
        />

        <Route path="/consoles/consoles/*" element={<RoofConsoleConsoles />} />
        <Route path="/consoles/console-sets/*" element={<RoofConsoleSets />} />
        <Route
          path="/consoles/console-set-assignments/*"
          element={<RoofConsoleSetAssignments />}
        />

        <Route path="/max-strokes/*" element={<MaxStrokesRoof />} />
        <Route path="/wind-deflectors/*" element={<WindDeflectors />} />
      </Routes>
    </div>
  );
};

const WindDeflectors: FC<React.PropsWithChildren<unknown>> = () => {
  return (
    <div>
      <WindDeflectorDataView />
    </div>
  );
};

const MaxStrokesRoof: FC<React.PropsWithChildren<unknown>> = () => {
  return (
    <div className="admin__content">
      <RoofMaxStrokesDataView />
    </div>
  );
};

const RoofSystems: FC<React.PropsWithChildren<unknown>> = () => {
  return (
    <div>
      <SystemDataView rangeOfApplication={RangeOfApplication.ROOF} />
    </div>
  );
};

const RoofSeries: FC<React.PropsWithChildren<unknown>> = () => {
  return (
    <div>
      <SeriesDataView rangeOfApplication={RangeOfApplication.ROOF} />
    </div>
  );
};

const RoofProfiles: FC<React.PropsWithChildren<unknown>> = () => {
  return (
    <div className="admin__sub-nav">
      <AdminNavLink to="/admin/roof/profiles/frame-profiles">
        Blendrahmen
      </AdminNavLink>
      <AdminNavLink to="/admin/roof/profiles/sash-profiles">
        Flügelprofile
      </AdminNavLink>
    </div>
  );
};

const RoofProfilesFrameProfiles: FC<React.PropsWithChildren<unknown>> = () => {
  return (
    <div>
      <RoofFrameProfileDataView />
    </div>
  );
};

const RoofProfilesSashProfiles: FC<React.PropsWithChildren<unknown>> = () => {
  return (
    <div>
      <RoofSashProfileDataView />
    </div>
  );
};

const RoofCV: FC<React.PropsWithChildren<unknown>> = () => {
  return (
    <div>
      <CVDataView rangeOfApplication={RangeOfApplication.ROOF} />
    </div>
  );
};

const RoofTestPhasesTestPhases: FC<React.PropsWithChildren<unknown>> = () => {
  return (
    <div>
      <TestPhasesDataViewRoof />
    </div>
  );
};

const RoofDrives: FC<React.PropsWithChildren<unknown>> = () => {
  return (
    <div>
      <DrivesData />
    </div>
  );
};

const RoofConsoles: FC<React.PropsWithChildren<unknown>> = () => {
  return (
    <div className="admin__sub-nav">
      <AdminNavLink to="/admin/roof/consoles/consoles">Konsolen</AdminNavLink>
      <AdminNavLink to="/admin/roof/consoles/console-sets">
        Konsolen-Sets
      </AdminNavLink>
      <AdminNavLink to="/admin/roof/consoles/console-set-assignments">
        Konsolen-Set-Zuordnungen
      </AdminNavLink>
    </div>
  );
};

const RoofConsoleConsoles: FC<React.PropsWithChildren<unknown>> = () => {
  return (
    <div className="admin__content">
      <ConsoleAreaConsolesDataView
        rangeOfApplication={RangeOfApplication.ROOF}
      />
    </div>
  );
};

const RoofConsoleSets: FC<React.PropsWithChildren<unknown>> = () => {
  return (
    <div className="admin__content">
      <ConsoleAreaConsoleSetsDataView
        rangeOfApplication={RangeOfApplication.ROOF}
      />
    </div>
  );
};

const RoofConsoleSetAssignments: FC<React.PropsWithChildren<unknown>> = () => {
  return (
    <div className="admin__content">
      <ConsoleAreaConsoleSetAssignmentsDataView
        rangeOfApplication={RangeOfApplication.ROOF}
      />
    </div>
  );
};

export default RoofArea;
