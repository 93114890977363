import React, { FC, ReactElement, ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

interface TableCellProps {
  content: string | ReactNode;
  index: number;
  button?: JSX.Element | null;
}

const TableCell: FC<React.PropsWithChildren<TableCellProps>> = (
  props: TableCellProps,
) => {
  return (
    <td className={`result-table__cell result-table__cell--${props.index + 1}`}>
      {props.content}
      {props.button}
    </td>
  );
};

const AddButton: FC<React.PropsWithChildren<unknown>> = () => {
  return (
    <button className="system-series-dialog__add-button">
      <span className="system-series-dialog__add-button-label">
        <FormattedMessage id="BUTTON_LABEL_ADD" />
      </span>
    </button>
  );
};

interface TableRowProps<T> {
  entry: T;
  rowClickCallback: (entry: T) => void;
  addCallback?: ((entry: T) => void) | null;
  markedEntry: T | undefined;
  highlightedEntry: T | undefined;
  toRow: (t: T) => (string | ReactNode)[];
}

const TableRow = <T extends { id: string | number }>(
  props: TableRowProps<T>,
): ReactElement => {
  const row = props.toRow(props.entry);

  const button = props.addCallback ? <AddButton /> : null;

  return (
    <tr
      key={props.entry.id}
      onClick={() =>
        props.addCallback
          ? props.addCallback(props.entry)
          : props.rowClickCallback(props.entry)
      }
      className={classNames('result-table__row', {
        'result-table__row--selected':
          props.highlightedEntry &&
          props.highlightedEntry.id === props.entry.id,
      })}
    >
      {(row as Array<string | ReactNode>).map(
        (content: string | ReactNode, index: number) => (
          <TableCell
            key={content?.toString()}
            content={content}
            index={index}
            button={
              props.addCallback && index === row.length - 1 ? button : null
            }
          />
        ),
      )}
    </tr>
  );
};

interface TableHeaderProps {
  columnTitles: string[];
}

const TableHeader: FC<React.PropsWithChildren<TableHeaderProps>> = (
  props: TableHeaderProps,
) => {
  return (
    <tr className="result-table__header-row">
      {props.columnTitles.map((c: string, index: number) => (
        <th
          key={c}
          className={`result-table__header-cell result-table__header-cell--${
            index + 1
          }`}
        >
          <FormattedMessage id={c} />
        </th>
      ))}
    </tr>
  );
};

interface ResultTableProps<T extends { id: string | number }> {
  markedEntry?: T | undefined;
  addCallback?: ((t: T) => void) | null;
  rowClickCallback: (entry: T) => void;
  columnTitles: string[];
  entries: T[];
  toRow: (t: T) => string[] | [string, ReactNode] | [string, string, ReactNode];
  highlightedEntry?: T | undefined;
}

export const ResultTable = <T extends { id: string | number }>(
  props: ResultTableProps<T>,
): ReactElement => {
  return (
    <div className="result-table">
      <table
        className={`result-table__table result-table__table--columns-${props.entries.length}`}
      >
        <tbody className="result-table__body">
          <TableHeader columnTitles={props.columnTitles} />
          {props.entries.map(element => {
            return (
              <TableRow<T>
                entry={element}
                rowClickCallback={props.rowClickCallback}
                addCallback={props.addCallback}
                key={element.id}
                markedEntry={props.markedEntry}
                highlightedEntry={props.highlightedEntry}
                toRow={props.toRow}
              />
            );
          })}
        </tbody>
      </table>
    </div>
  );
};
