import React, { FC, ReactNode } from 'react';
import classNames from 'classnames';
import './Tile.scss';

interface TileProps {
  children: ReactNode;
  additionalClass?: string;
  onClick: () => void;
}

const Tile: FC<React.PropsWithChildren<TileProps>> = (props: TileProps) => {
  function conditionalClass(
    property: string | undefined,
    prefix?: string,
  ): string {
    return property ? ` ${prefix || ''}${property}` : '';
  }

  return (
    <div
      className={classNames('tile' + conditionalClass(props.additionalClass))}
      onClick={props.onClick}
    >
      {props.children}
    </div>
  );
};

export default Tile;
