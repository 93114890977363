import React, { FC, useState } from 'react';
import { DialogFE } from '../../Dialog';
import { useDispatch, useSelector } from 'react-redux';
import { IState, MyCalcThunkDispatch } from '../../../redux/store';

import { FormattedMessage } from 'react-intl';
import {
  showDialog,
  updateDownloadStatus,
} from '../../../redux/uiStateActions';

import Cookies from 'js-cookie';
import {
  DOWNLOAD_FINISHED_COOKIE_NAME,
  LoadingCube,
} from '../../DownloadDialogButton';
import InputField from '../../../elements/InputField';
import './LockNRWGDialog.scss';
import { lockNRWG } from '../../../redux/projectsActions';
import { Project } from '../../../redux/projectsReducer';
import { AnyAction } from 'redux';

const LockNRGWDialog: FC<React.PropsWithChildren<unknown>> = () => {
  const dispatch: MyCalcThunkDispatch<AnyAction> = useDispatch();
  const selectedProject = useSelector<IState, Project>(
    s => s.ui.selectedProject!,
  );
  const downloadActive = useSelector<IState>(
    (s: IState) => s.ui.downloadActive,
  );
  const [orderNumber, setOrderNumber] = useState(selectedProject.orderNumber);

  function downloadCookieExists(): RegExpMatchArray | null {
    return document.cookie.match(DOWNLOAD_FINISHED_COOKIE_NAME);
  }

  function checkCookie(): void {
    if (downloadCookieExists() !== null) {
      dispatch(updateDownloadStatus(false));
      dispatch(showDialog(undefined));
      Cookies.remove(DOWNLOAD_FINISHED_COOKIE_NAME);
    } else {
      setTimeout(checkCookie, 200);
    }
  }

  return (
    <DialogFE
      heading="LOCK_NRWG_DIALOG_HEADING"
      componentClass="lock-dialog confirmation-dialog dialog--confirmation"
      isConfirmation={true}
      footerProps={{
        cancelAction: true,
        saveAction: orderNumber
          ? () => {
              dispatch(lockNRWG(orderNumber));
              dispatch(updateDownloadStatus(true));
              setTimeout(checkCookie, 200);
            }
          : undefined,
        primaryActionLabel:
          'LOCK_NRWG_DIALOG_HEADING_CONFIRMATION_BUTTON_LABEL',

        isConfirmation: true,
      }}
    >
      <>
        {downloadActive && (
          <LoadingCube message="LOCK_NRWG_DIALOG_LOCK_PROGRESS_MESSAGE" />
        )}
        <div className="lock-dialog__content">
          <div className="lock-dialog__description">
            <FormattedMessage id="LOCK_NRWG_DIALOG_HEADING_DESCRIPTION" />
          </div>
          <div>
            <InputField
              label={
                <FormattedMessage id="result_lockwindow_ordernumber_label" />
              }
              placeholder=""
              value={orderNumber}
              id="orderNumber"
              onChange={setOrderNumber}
              additionalClass="lock-dialog__order-number"
              inputMax={30}
            />
          </div>
        </div>
      </>
    </DialogFE>
  );
};

export default LockNRGWDialog;
