import React, { FC, useEffect, useState } from 'react';
import FormLayout from '../../elements/FormLayout';
import InputField from '../../elements/InputField';
import TextArea from '../../elements/TextArea';
import { Switch } from '../../elements/Switch';
import './SystemDataView.scss';
import Table from '../components/Table';
import TableHeader from '../elements/TableHeader';
import TableRow from '../elements/TableRow';
import Dialog from '../../components/Dialog';
import { useDispatch, useSelector } from 'react-redux';
import { System } from '../../redux/admin/adminFacadeReducer';
import { AdminState, AdminThunkDispatch } from '../../redux/admin/adminStore';
import SearchField from '../../elements/SearchField';
import Pagination from '../components/Pagination';
import {
  changeSystem,
  createSystem,
  updateSeriesFacade,
} from '../../redux/admin/adminFacadeActions';
import { fieldsFilled, search } from './helpers';
import { UiConstants } from '../constants';
import { useSeries } from '../../hooks/selectorHooks';
import { RangeOfApplication } from '../../redux/constants';
import { updateSeriesRoof } from '../../redux/admin/adminRoofActions';
import { AnyAction } from 'redux';
import { useAdminSearch } from '../hooks';

interface AdminSystemDialogProps {
  dialogIsShown: boolean;
  setDialogIsShown: (b: boolean) => void;
  selectedSystem: System | undefined;
}

const AdminSystemDialog: FC<React.PropsWithChildren<AdminSystemDialogProps>> = (
  props: AdminSystemDialogProps,
) => {
  const dispatch: AdminThunkDispatch<AnyAction> = useDispatch();
  const [name, setName] = useState(props.selectedSystem?.name || '');
  const [info, setInfo] = useState(props.selectedSystem?.info || '');
  const [shortCode, setShortCode] = useState(
    props.selectedSystem?.shortCode || '',
  );
  const [active, setActive] = useState(!!props.selectedSystem?.active);

  useEffect(() => {
    resetInputFields();
  }, [props.selectedSystem]);

  function resetInputFields(): void {
    setName(props.selectedSystem?.name || '');
    setInfo(props.selectedSystem?.info || '');
    setShortCode(props.selectedSystem?.shortCode || '');
    setActive(props.selectedSystem?.active || false);
  }

  function mandatoryFieldsFilled(): boolean {
    return fieldsFilled(name, shortCode);
  }

  function editSystem(): void {
    if (!props.selectedSystem) {
      return;
    }

    dispatch(
      changeSystem({
        ...props.selectedSystem,
        name: name,
        info: info,
        shortCode: shortCode,
        active: active,
      }),
    );
  }

  function generateSystem(): void {
    dispatch(
      createSystem({
        active: active,
        info: info,
        name: name,
        shortCode: shortCode,
      }),
    );
  }

  return (
    <Dialog
      cancelAction={resetInputFields}
      setDialogIsShown={props.setDialogIsShown}
      dialogIsShown={props.dialogIsShown}
      headingText={
        props.selectedSystem
          ? `System ${props.selectedSystem.name} bearbeiten`
          : 'Neues System anlegen'
      }
      componentClass="full-view-dialog"
      key={props.selectedSystem?.id}
      footerProps={{
        notTranslated: true,
        cancelAction: () => {
          resetInputFields();
          props.setDialogIsShown(false);
        },
        saveAction: mandatoryFieldsFilled()
          ? () => {
              props.selectedSystem ? editSystem() : generateSystem();
              props.setDialogIsShown(false);
            }
          : undefined,
        primaryActionLabelText: props.selectedSystem ? 'Speichern' : 'Anlegen',
      }}
    >
      <FormLayout additionalClass="system-data-view-dialog">
        <InputField
          label="Name *"
          placeholder="Name des Systems"
          value={name}
          additionalClass="system-data-view-dialog__name"
          onChange={setName}
        />
        <TextArea
          label="Information"
          additionalClass="system-data-view-dialog__info"
          placeholder="Informationen zum System"
          value={info}
          onChange={setInfo}
        />

        <InputField
          additionalClass="system-data-view-dialog__short-name"
          label="Kürzel *"
          placeholder="z.B. SC für Schüco"
          value={shortCode}
          onChange={setShortCode}
        />

        <div className="system-data-view-dialog__switch">
          <Switch
            labelText="Aktiv"
            turnedOn={active}
            onChange={() => {
              if (props.selectedSystem!.active) {
                alert(
                  'Alle Serien sowie Profile des Systems ' +
                    props.selectedSystem!.name +
                    ' werden deaktiviert wenn die Änderungen gespeichert werden.',
                );
              }
              setActive(!props.selectedSystem?.active);
            }}
          />
        </div>
      </FormLayout>
    </Dialog>
  );
};

const SystemDataView: FC<
  React.PropsWithChildren<{
    rangeOfApplication: RangeOfApplication;
  }>
> = props => {
  const [dialogIsShown, setDialogIsShown] = useState(false);
  const systems = useSelector<AdminState, System[]>(s => s.adminFacade.systems);
  const [editableSystemID, setEditableSystemID] = useState<undefined | number>(
    undefined,
  );
  const [indexOfFirstPageElement, setIndexOfFirstPageElement] = useState(0);
  const [page, setPage] = useState(1);
  const [searchString, setSearchString] = useState('');
  const [filterActive, setFilterActive, searchResult] = useAdminSearch(
    systems,
    searchString,
    ['name', 'shortCode'],
  );
  const allSeries = useSeries(props.rangeOfApplication);

  const dispatch: AdminThunkDispatch<AnyAction> = useDispatch();

  const selectedSystem =
    systems.find(sys => sys.id === editableSystemID) || undefined;

  function enableEditSystem(s: System): void {
    setDialogIsShown(true);
    setEditableSystemID(s.id);
  }

  function switchActiveAction(system: System): void {
    if (system.active) {
      alert(
        'Alle Serien und Profile des Systems ' +
          system.name +
          ' wurden deaktiviert.',
      );
    }
    allSeries.forEach(series => {
      if (series.system.id === system.id && system.active) {
        dispatch(
          props.rangeOfApplication === RangeOfApplication.FACADE
            ? updateSeriesFacade({ ...series, active: false })
            : updateSeriesRoof({ ...series, active: false }),
        );
      }
    });
    dispatch(changeSystem({ ...system, active: !system.active }));
  }

  function triggerCreationMode(): void {
    setDialogIsShown(true);
    setEditableSystemID(undefined);
  }

  function getCurrentTableContent(): System[] {
    return searchResult.slice(
      indexOfFirstPageElement,
      indexOfFirstPageElement + 20,
    );
  }

  return (
    <div>
      <AdminSystemDialog
        dialogIsShown={dialogIsShown}
        setDialogIsShown={setDialogIsShown}
        selectedSystem={selectedSystem}
      />

      <div className="sub-header">
        <div className="sub-header__title"> Systeme</div>
        <SearchField
          setSearchString={setSearchString}
          searchString={searchString}
          placeholderText="System suchen..."
          small={true}
          setFilterActive={setFilterActive}
          filterActive={filterActive}
        />
        <button
          className="sub-header__button sub-header__button--add"
          onClick={() => triggerCreationMode()}
        >
          {UiConstants.NEW_ENTRY}
        </button>
      </div>
      <div className="system-profile-data">
        <Table subNavigation={false}>
          <TableHeader>
            <th>Name</th>
            <th>Kürzel</th>
            <th>Info</th>

            <th>Aktiv</th>
            <th>Aktion</th>
          </TableHeader>
          <tbody>
            {getCurrentTableContent().map(system => (
              <TableRow key={system.id}>
                <td>{system.name}</td>
                <td>{system.shortCode}</td>
                <td>{system.info}</td>

                <td>
                  <Switch
                    key={system.id}
                    turnedOn={system.active}
                    onChange={(b: boolean) => switchActiveAction(system)}
                  />
                </td>
                <td>
                  <button
                    onClick={() => {
                      enableEditSystem(system);
                    }}
                  >
                    {UiConstants.EDIT}
                  </button>
                </td>
              </TableRow>
            ))}
          </tbody>
        </Table>
      </div>

      <Pagination
        searchString={searchString}
        numberOfPages={searchResult.length}
        page={page}
        setPage={setPage}
        indexOfFirstPageElement={indexOfFirstPageElement}
        setIndexOfFirstPageElement={setIndexOfFirstPageElement}
      />
    </div>
  );
};

export default SystemDataView;
