import React, { FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { resetConsoleSets, switchPage } from '../../../redux/uiStateActions';
import './ConsoleColumn.scss';
import { IState, MyCalcThunkDispatch } from '../../../redux/store';
import { ConsoleSet } from '../../../redux/staticDataReducer';
import DownloadDialogButton from '../../DownloadDialogButton';
import classNames from 'classnames';
import { ReactComponent as IconRemove } from '../../../IconRemove.svg';
import Button, {
  ButtonPosition,
  ButtonSize,
  ButtonType,
} from '../../../elements/Button';
import { LoggedInUser, Pages } from '../../../redux/authenticationReducer';
import {
  getSelectedWindow,
  useSelectedDrive,
} from '../../../hooks/selectorHooks';
import { AnyAction } from 'redux';
import { onEnter } from '../../../lib/utils';

function SelectedConsoleSets() {
  const { formatMessage } = useIntl();
  const dispatch: MyCalcThunkDispatch<AnyAction> = useDispatch();
  const selectedConsoleSets = useSelector<IState, ConsoleSet[] | undefined>(
    s => {
      const selectedWindow = getSelectedWindow(s);
      return selectedWindow
        ? selectedWindow.consoleSets
        : s.ui.selectedConsoleSets;
    },
  )!;
  const selectedDrive = useSelectedDrive();
  const currentUser = useSelector<IState, LoggedInUser>(
    state => state.authentication.currentUser!,
  );

  function resetConsoleSetsListener(): void {
    dispatch(resetConsoleSets());
  }

  function reloadWindow() {
    window.location.reload();
  }

  return (
    <>
      <div
        className="console-column__vertical-button vertical-button vertical-button--remove"
        onClick={() => dispatch(resetConsoleSets())}
        onKeyDown={onEnter(resetConsoleSetsListener)}
        tabIndex={0}
      >
        <div className="vertical-button__title">
          <FormattedMessage id="DESELECT_SELECTION" />
        </div>
        <div className="vertical-button__icon-container">
          <IconRemove />
        </div>
      </div>
      <div className="console-column__description">
        <div className="console-column__console-column__description-section">
          <div className="console-column__title">
            <div className="console-column__description-small-headline console-details-column__title-byline">
              {selectedConsoleSets.length === 1 ? (
                <FormattedMessage id="CONSOLE_SET_BYLINE" />
              ) : (
                <FormattedMessage id="CONSOLE_RESULTS_DRIVE_ONE" />
              )}
            </div>
            <div className="console-column__description-name">
              {selectedConsoleSets[0]?.name}
            </div>
          </div>
          <div className="console-column__description-text-container">
            <div
              className="console-column__description-text"
              dangerouslySetInnerHTML={{
                __html: formatMessage({
                  id: selectedConsoleSets[0].descriptionKey,
                }),
              }}
            />

            <div className="console-column__assembly-data">
              <div className="console-column__description-small-headline">
                <FormattedMessage id="TYPE_ASSEMBLY_SPACE" />
              </div>
              <div data-qa="required-space-frame">
                <FormattedMessage id="ASSEMBLY_SPACE_ON_THE_FRAME" />{' '}
                {selectedConsoleSets[0].requiredSpaceFrame} mm
              </div>
              <div data-qa="required-space-sash-frame">
                <FormattedMessage id="ASSEMBLY_SPACE_ON_THE_SASH_FRAME" />{' '}
                {selectedConsoleSets[0].requiredSpaceSashFrame} mm
              </div>
            </div>
            {!selectedConsoleSets[0].active && (
              <div className="drive-details-column__errors">
                <div className="drive-details-column__description-errors-headline">
                  <FormattedMessage id="ERROR_MESSAGES" />
                </div>
                <div
                  key="DRIVE_NOT_SOLD_ANY_MORE"
                  className="drive-details-column__errors-items"
                >
                  <FormattedMessage id="DRIVE_NOT_SOLD_ANY_MORE" />
                </div>
              </div>
            )}
          </div>
        </div>
        {selectedConsoleSets.length === 2 && (
          <div className="console-column__console-column__description-section">
            <div className="console-column__title">
              <div className="console-column__description-small-headline console-details-column__title-byline">
                <FormattedMessage id="CONSOLE_RESULTS_DRIVE_TWO" />
              </div>
              <div className="console-column__description-name">
                {selectedConsoleSets[1].name}
              </div>
            </div>
            <div className="console-column__description-text-container">
              <div className="console-column__description-text">
                <FormattedMessage id={selectedConsoleSets[1].descriptionKey} />
              </div>

              <div className="console-column__assembly-data">
                <div className="console-column__description-small-headline">
                  <FormattedMessage id="TYPE_ASSEMBLY_SPACE" />
                </div>
                <div data-qa="required-space-frame">
                  <FormattedMessage id="ASSEMBLY_SPACE_ON_THE_FRAME" />{' '}
                  {selectedConsoleSets[1].requiredSpaceFrame} mm
                </div>
                <div data-qa="required-space-sash-frame">
                  <FormattedMessage id="ASSEMBLY_SPACE_ON_THE_SASH_FRAME" />{' '}
                  {selectedConsoleSets[1].requiredSpaceSashFrame} mm
                </div>
              </div>
              {!selectedConsoleSets[1].active && (
                <div className="drive-details-column__errors">
                  <div className="drive-details-column__description-errors-headline">
                    <FormattedMessage id="ERROR_MESSAGES" />
                  </div>
                  <div
                    key="DRIVE_NOT_SOLD_ANY_MORE"
                    className="drive-details-column__errors-items"
                  >
                    <FormattedMessage id="DRIVE_NOT_SOLD_ANY_MORE" />
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
        <div className="console-column__CTA">
          <div className="console-column__CTA-headline">
            <FormattedMessage id="CONSOLE_COLUMN_DOWNLOAD_ANIMATION" />
          </div>
          {selectedDrive && (
            <DownloadDialogButton
              selectedDrive={selectedDrive}
              primary={true}
              showConsoleDocuments={true}
              showWindDeflectorDocuments={false}
            />
          )}

          {!currentUser && (
            <button
              className="console-column__reload-action"
              onClick={() => reloadWindow()}
            >
              <FormattedMessage id="NEW_CALCULATION" />
            </button>
          )}
        </div>{' '}
      </div>
    </>
  );
}

function AddConsoleCTA() {
  const dispatch: MyCalcThunkDispatch<AnyAction> = useDispatch();
  const currentUser = useSelector<IState, LoggedInUser>(
    state => state.authentication.currentUser!,
  );

  function reloadWindow() {
    window.location.reload();
  }

  return (
    <div className="console-column__CTA">
      <div className="console-column__CTA-headline">
        <FormattedMessage id="ADD_CONSOLE_ANIMATION_TEXT" />
      </div>
      <Button
        label="ADD_CONSOLE"
        iconName="add"
        action={() => dispatch(switchPage(Pages.CONSOLE_CALCULATION))}
        type={ButtonType.PRIMARY}
        position={ButtonPosition.CENTER}
        size={ButtonSize.LARGE}
        qaId="add-console-button"
      />

      {!currentUser && (
        <button
          className="console-column__reload-action"
          onClick={() => reloadWindow()}
        >
          <FormattedMessage id="NEW_CALCULATION" />
        </button>
      )}
    </div>
  );
}

const ConsoleColumn: FC<React.PropsWithChildren<unknown>> = () => {
  const selectedConsoleSets = useSelector<IState, ConsoleSet[] | undefined>(
    s => {
      const selectedWindow = getSelectedWindow(s);
      return selectedWindow
        ? selectedWindow.consoleSets
        : s.ui.selectedConsoleSets;
    },
  );
  const selectedDrive = useSelectedDrive();

  if (selectedDrive?.includedConsoleSet) {
    return (
      <div className="console-column">
        <div className="console-column__no-console-message">
          <FormattedMessage id="CONSOLE_COLUMN_MESSAGE_CONSOLE_INCLUDED" />
        </div>
      </div>
    );
  }

  return (
    <div
      className={classNames('console-column', {
        'console-column--console-selected':
          selectedConsoleSets && selectedConsoleSets?.length > 0,
      })}
    >
      {selectedConsoleSets && selectedConsoleSets?.length > 0 ? (
        <SelectedConsoleSets />
      ) : (
        <AddConsoleCTA />
      )}
    </div>
  );
};

export default ConsoleColumn;
