import { AnyAction, Reducer } from 'redux';
import { Material } from '../admin/constants';

export interface SystemSeries {
  system: string;
  series: string;
  allowsLocking: boolean;
  material: Material;
  id: string;
}

export interface SashProfile {
  itemNumber: string;
  assemblySpace?: number;
  system: string;
  series: string;
  id: string;
}

export type ExchangeProfile = SashProfile;
export type BaseProfile = SashProfile;

export interface FrameProfile extends SashProfile {
  exchangeProfileRequired: boolean;
  baseProfilePossible: boolean;
}

export interface ProfileData {
  systemSeriesResult: SystemSeries[];
  sashProfilesResult: SashProfile[];
  frameProfilesResult: FrameProfile[];
  exchangeProfilesResult: ExchangeProfile[];
  baseProfilesResult: BaseProfile[];
}

export const INITIAL_STATE = {
  systemSeriesResult: [],
  sashProfilesResult: [],
  frameProfilesResult: [],
  exchangeProfilesResult: [],
  baseProfilesResult: [],
};

export const profileDataReducer: Reducer = (
  state: ProfileData,
  action: AnyAction,
) => {
  if (!state) {
    return INITIAL_STATE;
  }

  switch (action.type) {
    case 'UPDATE_SYSTEM_SERIES_RESULT':
      if (!action.systemSeriesResult) {
        return state;
      }
      return { ...state, systemSeriesResult: action.systemSeriesResult };
    case 'UPDATE_SASH_PROFILES':
      if (!action.sashProfiles) {
        return state;
      }
      return { ...state, sashProfilesResult: action.sashProfiles };
    case 'UPDATE_FRAME_PROFILES':
      if (!action.frameProfiles) {
        return state;
      }
      return { ...state, frameProfilesResult: action.frameProfiles };
    case 'UPDATE_EXCHANGE_PROFILES':
      if (!action.exchangeProfiles) {
        return state;
      }
      return { ...state, exchangeProfilesResult: action.exchangeProfiles };
    case 'UPDATE_BASE_PROFILES':
      if (!action.baseProfiles) {
        return state;
      }
      return { ...state, baseProfilesResult: action.baseProfiles };
    default:
      return state;
  }
};

export default profileDataReducer;
