import React, { FC } from 'react';
import './Header.scss';
import { ThunkDispatch } from 'redux-thunk';
import { useDispatch, useSelector } from 'react-redux';
import { IntlAction } from 'react-intl-redux';
import { IState, MyCalcThunkDispatch } from '../../redux/store';
import {
  setProjectNavigationOpen,
  showDialog,
  switchLocaleAction,
  switchPage,
} from '../../redux/uiStateActions';
import { Locale, Pages } from '../../redux/uiStateReducer';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { ReactComponent as IconLogin } from './IconLogin.svg';
import { ReactComponent as IconLogout } from './IconLogout.svg';
import { ReactComponent as IconApplicationAreas } from './IconApplicationAreas.svg';
import { ReactComponent as IconProjectnavigation } from './IconProjectnavigation.svg';
import { ReactComponent as IconFolder } from './IconFolder.svg';
import { ReactComponent as IconProjectData } from './IconProjectData.svg';
import { ReactComponent as MenuPopupIconTemplates } from './MenuPopupIconTemplates.svg';
import { ReactComponent as MenuPopupIconProjects } from './MenuPopupIconProjects.svg';
import { ReactComponent as MenuPopupIconDocuments } from './MenuPopupIconDocuments.svg';
import { ReactComponent as CertifiedSystems } from './Certified.svg';
import { ReactComponent as DHlogo } from './dh-logo.svg';
import {
  BuildingArea,
  Project,
  ShallowProject,
  ShallowWindow,
} from '../../redux/projectsReducer';
import { ProjectDialog } from '../Projects/ProjectDialog';
import {
  BadgeFacadePrimary,
  BadgeNRWGPrimary,
  BadgeRoofPrimary,
  BadgeRWAPrimary,
} from '../Badges';
import { useSelectedWindow } from '../../hooks/selectorHooks';
import PopupMenu from '../../elements/PopupMenu';
import Button, {
  ButtonPosition,
  ButtonType,
  IconPosition,
} from '../../elements/Button';
import { DialogFE } from '../Dialog';
import Support from '../Support';
import { adminView } from '../../lib/utils';
import { AnyAction } from 'redux';
import SelectSearch, { SelectedOptionValue } from 'react-select-search';
import HelpIcon from '@mui/icons-material/Help';
import TutorialDialog from './TutorialDialog';

type SelectOption = { value: Locale; label: string };

const languageOptions = [
  { value: Locale.DE, name: 'DE' },
  { value: Locale.EN, name: 'EN' },
  { value: Locale.PL, name: 'PL' },
  { value: Locale.CS, name: 'CS' },
  { value: Locale.DA, name: 'DA' },
  { value: Locale.ES, name: 'ES' },
  { value: Locale.ET, name: 'ET' },
  { value: Locale.FR, name: 'FR' },
  { value: Locale.IT, name: 'IT' },
  { value: Locale.NL, name: 'NL' },
  { value: Locale.PT, name: 'PT' },
  { value: Locale.RU, name: 'RU' },
  { value: Locale.SV, name: 'SV' },
  { value: Locale.TR, name: 'TR' },
  { value: Locale.LT, name: 'LT' },
  { value: Locale.LV, name: 'LV' },
];

const Header: FC<React.PropsWithChildren<unknown>> = () => {
  const userAuthStatus = useSelector(
    (state: IState) => state.authentication.userIsAuthenticated,
  );
  const dispatch: ThunkDispatch<IState, void, IntlAction> = useDispatch();
  const locale: Locale = useSelector(
    (state: IState) => state.authentication.locale,
  );
  const activePage = useSelector<IState, number>(s => s.ui.activePage);
  const selectedProject = useSelector<IState, Project | undefined>(
    s => s.ui.selectedProject,
  );
  const selectedShallowProject = useSelector<
    IState,
    ShallowProject | undefined
  >(s => s.projects?.projects.find(p => p.id === s.ui.selectedProject?.id));
  const selectedWindow = useSelectedWindow();
  const buildingAreaOfSelectedWindow = useSelector<
    IState,
    BuildingArea | undefined
  >(s =>
    s.ui.selectedProject?.buildingAreas?.find(b =>
      b.windows.find(w => w.id === s.ui.selectedWindow?.id),
    ),
  );
  const userIsAuthenticated = useSelector<IState, boolean>(
    state => state.authentication.userIsAuthenticated,
  );
  const shallowSelectedWindow = useSelector<IState, ShallowWindow | undefined>(
    s =>
      s.ui.selectedProject?.buildingAreas
        .flatMap(b => b.windows)
        .find(w => w.id === s.ui.selectedWindow?.id),
  );

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const popUpOptions = [
    {
      action: () => {
        dispatch(switchPage(Pages.PROJECTS_MANAGER));
      },
      label: 'PROJECTS',
      icon: <MenuPopupIconProjects />,
    },
    {
      action: () => {
        dispatch(switchPage(Pages.TEMPLATES_MANAGER));
      },
      label: 'TEMPLATES',
      icon: <MenuPopupIconTemplates />,
    },
    {
      action: () => {
        dispatch(switchPage(Pages.DOCUMENTS_AREA));
      },
      label: 'result_docs_view1',
      icon: <MenuPopupIconDocuments />,
    },
    {
      action: () => {
        window.open(
          'https://downloads.dh-partner.com/Website/D-H-Mechatronic/myCalc/DH_EN_12101-2_System_Solution.pdf',
          '_blank',
        );
      },
      label: 'HEADER_SECTION_LABEL_CERTIFIED_SYSTEMS',
      icon: <CertifiedSystems />,
    },
  ];

  function managerPageActive(): boolean {
    return (
      activePage === Pages.PROJECTS_MANAGER ||
      activePage === Pages.TEMPLATES_MANAGER ||
      activePage === Pages.DOCUMENTS_AREA
    );
  }

  return (
    <header
      className={classNames('header', {
        'header--manager-page-active': managerPageActive(),
      })}
    >
      {selectedProject && !managerPageActive() && (
        <div
          className="header__project-navigation"
          onClick={() => dispatch(setProjectNavigationOpen(true))}
        >
          <div className="header__project-navigation-icon">
            <IconProjectnavigation />
          </div>
          <div className="header__project-navigation-label">
            <FormattedMessage id="PROJECT_NAVIGATION_HEADER" />
          </div>
          {selectedWindow && (
            <div className="header__project-navigation-breadcrumb">
              <div className="header__project-navigation-breadcrumb-item">
                {buildingAreaOfSelectedWindow?.name}{' '}
              </div>{' '}
              <div className="header__project-navigation-breadcrumb-item">
                {selectedWindow.name} [ {selectedWindow.quantity}{' '}
                <FormattedMessage id="PIECES_ABBREVIATION" /> ]{' '}
              </div>
              <div className="header__project-navigation-badges">
                {shallowSelectedWindow && (
                  <>
                    <BadgeFacadePrimary window={shallowSelectedWindow} />
                    <BadgeRoofPrimary window={shallowSelectedWindow} />
                    <BadgeNRWGPrimary window={shallowSelectedWindow} />
                    <BadgeRWAPrimary window={shallowSelectedWindow} />{' '}
                  </>
                )}
              </div>
            </div>
          )}
        </div>
      )}
      <div className="header__app-header">
        <DHlogo />
      </div>
      {selectedProject && !managerPageActive() && (
        <div className="header__project-information">
          {' '}
          <div className="header__project-icon-folder">
            <IconFolder />
          </div>
          <div className="header__project-name">{selectedProject.name}</div>{' '}
          <div
            className="header__info-icon"
            onClick={() =>
              dispatch(
                showDialog(
                  <ProjectDialog
                    editMode={true}
                    editedProject={selectedShallowProject}
                  />,
                ),
              )
            }
          >
            <IconProjectData />
          </div>
        </div>
      )}{' '}
      <div className="header__project-options">
        <a className="icon-help" onClick={handleClickOpen}>
          <HelpIcon />
        </a>
        <a
          className="header__help-button button__label"
          onClick={handleClickOpen}
        >
          <FormattedMessage id="HELP" />
        </a>
        <TutorialDialog
          open={open}
          locale={locale}
          onClose={handleClose}
          userIsAuthenticated={userIsAuthenticated}
        />
        {userIsAuthenticated && (
          <>
            <PopupMenu
              icon={<IconApplicationAreas />}
              label="SECTIONS_MENU_GROUP_HEADER"
              items={popUpOptions}
            />
          </>
        )}
        {!userIsAuthenticated && (
          <>
            <a
              className="header__register-button"
              href="https://sso.dh-partner.com/auth/realms/DH/protocol/openid-connect/registrations?client_id=mycalc&response_type=code&redirect_uri=https%3A%2F%2Fmycalc.dh-partner.com%2F"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FormattedMessage id="REGISTER" />
            </a>
            <Button
              label={adminView() ? 'Login' : 'LOGIN'}
              action={() => {
                dispatch(
                  showDialog(
                    <LoginInfoDialog
                      heading="Wichtige Information / Important notice"
                      contentDE="Wir haben ein neues Login System eingeführt. Ihre bisher verwendeten Passwörter verlieren dadurch Ihre Gültigkeit. Bitte nutzen Sie die Passwortvergessenfunktion, um ein neues Passwort zu vergeben."
                      contentEN="We have introduced a new login system. Your previously used passwords will lose their validity as a result. Please use the forgotten password function to set a new password."
                      userAuthStatus={userAuthStatus}
                    />,
                  ),
                );
              }}
              type={ButtonType.ICON_ACTION}
              icon={<IconLogin />}
              iconPosition={IconPosition.LEFT}
              additionalClass="header__login-button"
            />
          </>
        )}
        {userIsAuthenticated && (
          <Button
            label="LOGOUT"
            action={() => {
              if (userAuthStatus) {
                window.keyCloak?.logout().then(() => window.location?.reload());
              }
            }}
            type={ButtonType.ICON_ACTION}
            icon={<IconLogout />}
            iconPosition={IconPosition.LEFT}
            additionalClass="header__login-button"
          />
        )}
        <SelectSearch
          // className="header__select"
          options={languageOptions}
          value={languageOptions.find(v => v.value === locale)!.value}
          onChange={locale => {
            // @ts-ignore
            if (window.FreshworksWidget) {
              try {
                // @ts-ignore
                FreshworksWidget('hide');
                // @ts-ignore
                FreshworksWidget('destroy');
              } catch (e) {
                // ignore freshworks errors
              }
            }
            dispatch(
              switchLocaleAction(
                locale as SelectedOptionValue as Locale,
                false,
              ),
            );
          }}
        />
      </div>
      <Support locale={locale === Locale.DE ? locale : Locale.EN} />
    </header>
  );
};

interface LoginInfoProps {
  contentEN: string;
  contentDE: string;
  heading: string;
  userAuthStatus: boolean | undefined;
}

const LoginInfoDialog: FC<React.PropsWithChildren<LoginInfoProps>> = (
  props: LoginInfoProps,
) => {
  const dispatch: MyCalcThunkDispatch<AnyAction> = useDispatch();
  return (
    <DialogFE heading={props.heading} componentClass="">
      <>
        <div className="header__login-hint-container">
          <div className="header__login-hint">{props.contentDE}</div>
          <div className="header__login-hint"> {props.contentEN}</div>
          <Button
            action={() => {
              if (!props.userAuthStatus) {
                window.keyCloak?.login();
              }
              dispatch(showDialog(undefined));
            }}
            type={ButtonType.PRIMARY}
            label={'Verstanden / Agree'}
            position={ButtonPosition.CENTER}
          />
        </div>
      </>
    </DialogFE>
  );
};

export default Header;
