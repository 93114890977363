import React, { FC, ReactElement } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import CompanyDataView from '../companies-management/CompanyDataView';
import { ArticleNumberDataView } from './ArticleNumberDataView';
import { useSelector } from 'react-redux';
import { IState } from '../../redux/store';
import { LoggedInUser } from '../../redux/authenticationReducer';
import AdminNavLink from '../common/AdminNavLink';

const TabNavCompaniesManagement: FC<React.PropsWithChildren<unknown>> = () => {
  return (
    <div className="admin__tab-nav">
      <AdminNavLink to="/admin/companies-management/companies">
        Firmen
      </AdminNavLink>
      <AdminNavLink to="/admin/companies-management/article-numbers">
        Artikelnummern
      </AdminNavLink>
    </div>
  );
};

const CompanyManagementArea: FC<React.PropsWithChildren<unknown>> = () => {
  const currentUser = useSelector<IState, LoggedInUser | undefined>(
    (state: IState) => state.authentication.currentUser,
  );

  if (!currentUser?.userAdmin) {
    return null;
  }

  return (
    <div className="admin__main">
      <TabNavCompaniesManagement />
      <Routes>
        <Route
          path="/companies"
          element={
            <Navigate
              replace
              to="/admin/companies-management/companies/baseData"
            />
          }
        />
        <Route path="/companies/*" element={<Companies />} />
        <Route path="/article-numbers" element={<ArticleNumbers />} />
      </Routes>
    </div>
  );
};

function ArticleNumbers(): ReactElement {
  return (
    <div className="admin__content">
      <ArticleNumberDataView />
    </div>
  );
}

const Companies: FC<React.PropsWithChildren<unknown>> = () => {
  return (
    <div className="admin__content">
      <CompanyDataView />
    </div>
  );
};

export default CompanyManagementArea;
