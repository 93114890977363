import './WindDeflectorDataView.scss';
import React, { FC, ReactElement, ReactNode, useState } from 'react';
import FormLayout, { FormLayoutElement } from '../../elements/FormLayout';
import Table from '../components/Table';
import TableHeader from '../elements/TableHeader';
import TableRow from '../elements/TableRow';
import Dialog from '../../components/Dialog';
import Pagination from '../components/Pagination';
import { ROOF_TYPE_OPTIONS, UiConstants } from '../constants';
import { useDispatch, useSelector } from 'react-redux';
import { AdminState, AdminThunkDispatch } from '../../redux/admin/adminStore';
import {
  DoppelklappeDrehDreh,
  RoofFlap,
  WindDeflectorData,
  WindDeflectorHolder,
} from '../../redux/admin/adminRoofReducer';
import {
  changeWindDeflectors,
  selectEditedWindDeflector,
  updateEditedWindDeflector,
} from '../../redux/admin/adminRoofActions';
import { Navigate, Route, Routes } from 'react-router-dom';
import InputField, { InputFieldNumber } from '../../elements/InputField';
import Headline, { HeadlineSize } from '../../elements/Headline';
import { useEditedWindDeflector } from '../../hooks/selectorHooks';
import Button, { ButtonType } from '../../elements/Button';
import { MenuPlacement, SelectField } from '../../elements/SelectField';
import _ from 'lodash';
import { Switch } from '../../elements/Switch';
import { fieldsFilled } from '../general/helpers';
import { AnyAction } from 'redux';
import AdminNavLink from '../common/AdminNavLink';
import { ThunkDispatch } from 'redux-thunk';
import { CSVInput } from '../../elements/CSVInput';
import { Edit } from '../../types';

const WindDeflectorDataDialogTabNav: FC<
  React.PropsWithChildren<unknown>
> = () => {
  return (
    <div className="admin__tab-nav wind-deflector-view-dialog__tab-nav">
      <AdminNavLink to="/admin/roof/wind-deflectors/info">
        <Routes>
          <Route
            path="/"
            element={<Navigate replace to="/admin/roof/wind-deflectors/info" />}
          />
        </Routes>
        Info
      </AdminNavLink>
      <AdminNavLink to="/admin/roof/wind-deflectors/dkKlippKlapp">
        Doppelklappe Kipp Klapp
      </AdminNavLink>
      <AdminNavLink to="/admin/roof/wind-deflectors/dkDrehDreh">
        Doppelklappe Dreh Dreh
      </AdminNavLink>
      <AdminNavLink to="/admin/roof/wind-deflectors/ekKipp">
        Einzelklappe Kipp
      </AdminNavLink>
      <AdminNavLink to="/admin/roof/wind-deflectors/ekKlapp">
        Einzelklappe Klapp
      </AdminNavLink>
      <AdminNavLink to="/admin/roof/wind-deflectors/halterEK">
        Halter Einzelklappe
      </AdminNavLink>
      <AdminNavLink to="/admin/roof/wind-deflectors/halterDK">
        Halter Doppelklappe
      </AdminNavLink>
    </div>
  );
};

const WindDeflectorDataDialogInfo: FC<
  React.PropsWithChildren<unknown>
> = () => {
  const editedWindDeflector = useEditedWindDeflector();
  const dispatch: AdminThunkDispatch<AnyAction> = useDispatch();

  return (
    <FormLayout additionalClass="wind-deflector-view-dialog__layout-info">
      <InputField
        label="Windleitwandbezeichnung*"
        placeholder="Name der Windleitwand"
        value={editedWindDeflector?.bezeichnung}
        additionalClass="wind-deflector-view-dialog__wlw-name"
        onChange={newValue =>
          dispatch(
            updateEditedWindDeflector({
              ...editedWindDeflector!,
              bezeichnung: newValue,
            }),
          )
        }
      />
      <div className="wind-deflector-view-dialog__sheet-metal-wlw-heading">
        <Headline
          additionalClass="headline--normal-height"
          size={HeadlineSize.MD}
        >
          Blech
        </Headline>
      </div>
      <InputFieldNumber
        label="max. WLW-Länge (mm)*"
        value={editedWindDeflector?.maxWLWLaengeBlech}
        additionalClass="wind-deflector-view-dialog__sheet-metal-wlw-max-lenght"
        onChange={newValue =>
          dispatch(
            updateEditedWindDeflector({
              ...editedWindDeflector!,
              maxWLWLaengeBlech: newValue,
            }),
          )
        }
      />
      <InputFieldNumber
        label="Platzbedarf WLW-Konsole (mm)*"
        value={editedWindDeflector?.platzbedarfKonsoleBlech}
        additionalClass="wind-deflector-view-dialog__sheet-metal-wlw-console"
        onChange={newValue =>
          dispatch(
            updateEditedWindDeflector({
              ...editedWindDeflector!,
              platzbedarfKonsoleBlech: newValue,
            }),
          )
        }
      />

      <div className="wind-deflector-view-dialog__glas-wlw-heading">
        <Headline
          additionalClass="headline--normal-height"
          size={HeadlineSize.MD}
        >
          Glas
        </Headline>
      </div>
      <InputFieldNumber
        label="max. WLW-Länge (mm)*"
        value={editedWindDeflector?.maxWLWLaengeGlas}
        additionalClass="wind-deflector-view-dialog__glas-wlw-max-lenght"
        onChange={newValue =>
          dispatch(
            updateEditedWindDeflector({
              ...editedWindDeflector!,
              maxWLWLaengeGlas: newValue,
            }),
          )
        }
      />
      <InputFieldNumber
        label="Platzbedarf WLW-Konsole (mm)*"
        value={editedWindDeflector?.platzbedarfKonsoleGlas}
        additionalClass="wind-deflector-view-dialog__glas-wlw-console"
        onChange={newValue =>
          dispatch(
            updateEditedWindDeflector({
              ...editedWindDeflector!,
              platzbedarfKonsoleGlas: newValue,
            }),
          )
        }
      />
    </FormLayout>
  );
};

const WindDeflectorDataDialogHalterEinzelklappe: FC<
  React.PropsWithChildren<unknown>
> = () => {
  const editedWindDeflector = useEditedWindDeflector();
  const dispatch: AdminThunkDispatch<AnyAction> = useDispatch();

  const updateWindDeflectorHolder = getUpdateWindDeflectorHolder(
    editedWindDeflector,
    dispatch,
  );

  return (
    <FormLayout additionalClass="wind-deflector-view-dialog__layout-halter-einzelklappe">
      <div className="wind-deflector-view-dialog__heading1">
        <Headline
          additionalClass="headline--normal-height"
          size={HeadlineSize.MD}
        >
          Blech
        </Headline>
      </div>

      <InputField
        label="Bezeichnung Eckhalter*"
        placeholder="Name des Eckhalters"
        value={editedWindDeflector?.windleitwandhalterBlechEK?.eckhalter}
        additionalClass="wind-deflector-view-dialog__name-eckhalter"
        onChange={newValue =>
          dispatch(
            updateEditedWindDeflector({
              ...editedWindDeflector!,
              windleitwandhalterBlechEK: {
                ...editedWindDeflector!.windleitwandhalterBlechEK!,
                eckhalter: newValue,
              },
            }),
          )
        }
      />

      <div className="wind-deflector-view-dialog__heading2">
        <Headline
          additionalClass="headline--normal-height"
          size={HeadlineSize.MD}
        >
          Glas
        </Headline>
      </div>

      <div className="wind-deflector-view-dialog__sub-group-1">
        <div className="wind-deflector-view-dialog__sub-heading">
          <Headline
            additionalClass="headline--normal-height"
            size={HeadlineSize.SM}
          >
            Zweiseitige WLW
          </Headline>
        </div>
        <InputField
          label="Bezeichnung Eckhalter*"
          placeholder="Name des Eckhalters"
          value={
            editedWindDeflector?.windleitwandhalterGlasZweiWlwEK![0].eckhalter
          }
          additionalClass="wind-deflector-view-dialog__row1-column1"
          onChange={eckhalter =>
            updateWindDeflectorHolder(
              eckhalter,
              'eckhalter',
              'windleitwandhalterGlasZweiWlwEK',
              0,
            )
          }
        />
        <InputFieldNumber
          label="Bis max WLW Höhe (mm)*"
          value={
            editedWindDeflector?.windleitwandhalterGlasZweiWlwEK![0].maxHoehe
          }
          additionalClass="wind-deflector-view-dialog__row1-column2"
          onChange={maxHoehe =>
            updateWindDeflectorHolder(
              maxHoehe,
              'maxHoehe',
              'windleitwandhalterGlasZweiWlwEK',
              0,
            )
          }
        />
        <InputFieldNumber
          label="Abstand beinhalteter Windhalter (mm)*"
          value={
            editedWindDeflector?.windleitwandhalterGlasZweiWlwEK![0]
              .abstandBeinhalteterGlashalter
          }
          additionalClass="wind-deflector-view-dialog__row1-column3"
          onChange={abstandBeinhalteterGlashalter =>
            updateWindDeflectorHolder(
              abstandBeinhalteterGlashalter,
              'abstandBeinhalteterGlashalter',
              'windleitwandhalterGlasZweiWlwEK',
              0,
            )
          }
        />
        <div className="wind-deflector-view-dialog__row1-active wind-deflector-view-dialog__active-switch">
          <Switch
            turnedOn={
              !!editedWindDeflector?.windleitwandhalterGlasZweiWlwEK![0].active
            }
            onChange={active => {
              updateWindDeflectorHolder(
                active,
                'active',
                'windleitwandhalterGlasZweiWlwEK',
                0,
              );
            }}
          />
          <label>Aktiv</label>
        </div>
        <InputField
          label="Bezeichnung Eckhalter*"
          placeholder="Name des Eckhalters"
          value={
            editedWindDeflector?.windleitwandhalterGlasZweiWlwEK![1].eckhalter
          }
          additionalClass="wind-deflector-view-dialog__row2-column1"
          onChange={eckhalter =>
            updateWindDeflectorHolder(
              eckhalter,
              'eckhalter',
              'windleitwandhalterGlasZweiWlwEK',
              1,
            )
          }
        />
        <InputFieldNumber
          label="Bis max WLW Höhe (mm)*"
          value={
            editedWindDeflector?.windleitwandhalterGlasZweiWlwEK![1].maxHoehe
          }
          additionalClass="wind-deflector-view-dialog__row2-column2"
          onChange={maxHoehe =>
            updateWindDeflectorHolder(
              maxHoehe,
              'maxHoehe',
              'windleitwandhalterGlasZweiWlwEK',
              1,
            )
          }
        />
        <InputFieldNumber
          label="Abstand beinhalteter Windhalter (mm)*"
          value={
            editedWindDeflector?.windleitwandhalterGlasZweiWlwEK![1]
              .abstandBeinhalteterGlashalter
          }
          additionalClass="wind-deflector-view-dialog__row2-column3"
          onChange={abstandBeinhalteterGlashalter =>
            updateWindDeflectorHolder(
              abstandBeinhalteterGlashalter,
              'abstandBeinhalteterGlashalter',
              'windleitwandhalterGlasZweiWlwEK',
              1,
            )
          }
        />
        <div className="wind-deflector-view-dialog__row2-active wind-deflector-view-dialog__active-switch">
          <Switch
            turnedOn={
              !!editedWindDeflector?.windleitwandhalterGlasZweiWlwEK![1].active
            }
            onChange={active => {
              updateWindDeflectorHolder(
                active,
                'active',
                'windleitwandhalterGlasZweiWlwEK',
                1,
              );
            }}
          />
          <label>Aktiv</label>
        </div>
      </div>

      <div className="wind-deflector-view-dialog__sub-group-2">
        <div className="wind-deflector-view-dialog__sub-heading">
          <Headline
            additionalClass="headline--normal-height"
            size={HeadlineSize.SM}
          >
            Dreiseitige WLW
          </Headline>
        </div>
        <InputField
          label="Bezeichnung Eckhalter*"
          placeholder="Name des Eckhalters"
          value={
            editedWindDeflector?.windleitwandhalterGlasDreiWlwEK![0].eckhalter
          }
          additionalClass="wind-deflector-view-dialog__row1-column1"
          onChange={eckhalter =>
            updateWindDeflectorHolder(
              eckhalter,
              'eckhalter',
              'windleitwandhalterGlasDreiWlwEK',
              0,
            )
          }
        />
        <InputFieldNumber
          label="Bis max WLW Höhe (mm)*"
          value={
            editedWindDeflector?.windleitwandhalterGlasDreiWlwEK![0].maxHoehe
          }
          additionalClass="wind-deflector-view-dialog__row1-column2"
          onChange={maxHoehe =>
            updateWindDeflectorHolder(
              maxHoehe,
              'maxHoehe',
              'windleitwandhalterGlasDreiWlwEK',
              0,
            )
          }
        />
        <div className="wind-deflector-view-dialog__row1-active wind-deflector-view-dialog__active-switch">
          <Switch
            turnedOn={
              !!editedWindDeflector?.windleitwandhalterGlasDreiWlwEK![0].active
            }
            onChange={active => {
              updateWindDeflectorHolder(
                active,
                'active',
                'windleitwandhalterGlasDreiWlwEK',
                0,
              );
            }}
          />
          <label>Aktiv</label>
        </div>
        <InputFieldNumber
          label="Abstand beinhalteter Windhalter (mm)*"
          value={
            editedWindDeflector?.windleitwandhalterGlasDreiWlwEK![0]
              .abstandBeinhalteterGlashalter
          }
          additionalClass="wind-deflector-view-dialog__row1-column3"
          onChange={abstandBeinhalteterGlashalter =>
            updateWindDeflectorHolder(
              abstandBeinhalteterGlashalter,
              'abstandBeinhalteterGlashalter',
              'windleitwandhalterGlasDreiWlwEK',
              0,
            )
          }
        />

        <InputField
          label="Bezeichnung Eckhalter*"
          placeholder="Name des Eckhalters"
          value={
            editedWindDeflector?.windleitwandhalterGlasDreiWlwEK![1].eckhalter
          }
          additionalClass="wind-deflector-view-dialog__row2-column1"
          onChange={eckhalter =>
            updateWindDeflectorHolder(
              eckhalter,
              'eckhalter',
              'windleitwandhalterGlasDreiWlwEK',
              1,
            )
          }
        />
        <InputFieldNumber
          label="Bis max WLW Höhe (mm)*"
          value={
            editedWindDeflector?.windleitwandhalterGlasDreiWlwEK![1].maxHoehe
          }
          additionalClass="wind-deflector-view-dialog__row2-column2"
          onChange={maxHoehe =>
            updateWindDeflectorHolder(
              maxHoehe,
              'maxHoehe',
              'windleitwandhalterGlasDreiWlwEK',
              1,
            )
          }
        />
        <InputFieldNumber
          label="Abstand beinhalteter Windhalter (mm)*"
          value={
            editedWindDeflector?.windleitwandhalterGlasDreiWlwEK![1]
              .abstandBeinhalteterGlashalter
          }
          additionalClass="wind-deflector-view-dialog__row2-column3"
          onChange={abstandBeinhalteterGlashalter =>
            updateWindDeflectorHolder(
              abstandBeinhalteterGlashalter,
              'abstandBeinhalteterGlashalter',
              'windleitwandhalterGlasDreiWlwEK',
              1,
            )
          }
        />
        <div className="wind-deflector-view-dialog__row2-active wind-deflector-view-dialog__active-switch">
          <Switch
            turnedOn={
              !!editedWindDeflector?.windleitwandhalterGlasDreiWlwEK![1].active
            }
            onChange={active => {
              updateWindDeflectorHolder(
                active,
                'active',
                'windleitwandhalterGlasDreiWlwEK',
                1,
              );
            }}
          />
          <label>Aktiv</label>
        </div>
        <InputField
          label="Bezeichnung Glashalter*"
          placeholder="Name des Glashalters"
          value={editedWindDeflector?.bezeichnungGlashalterEK}
          additionalClass="wind-deflector-view-dialog__row3-column1"
          onChange={newValue =>
            dispatch(
              updateEditedWindDeflector({
                ...editedWindDeflector!,
                bezeichnungGlashalterEK: newValue,
              }),
            )
          }
        />
        <InputFieldNumber
          label="Abstand zwischen Montageplätzen (mm)*"
          value={editedWindDeflector?.abstandMontagesaetzeEK}
          additionalClass="wind-deflector-view-dialog__row3-column2"
          onChange={newValue =>
            dispatch(
              updateEditedWindDeflector({
                ...editedWindDeflector!,
                abstandMontagesaetzeEK: newValue,
              }),
            )
          }
        />
      </div>
    </FormLayout>
  );
};

function getUpdateWindDeflectorHolder(
  editedWindDeflector: Edit<WindDeflectorData> | undefined,
  dispatch: ThunkDispatch<AdminState, undefined, AnyAction>,
) {
  return function updateWindDeflectorHolder<
    K extends keyof WindDeflectorHolder,
    T extends WindDeflectorHolder[K],
  >(
    value: T | null,
    key: K,
    halter:
      | 'windleitwandhalterGlasZweiWlwEK'
      | 'windleitwandhalterGlasDreiWlwEK'
      | 'windleitwandhalterGlasPultdachDK'
      | 'windleitwandhalterGlasSatteldachDK',
    index: number,
  ): void {
    if (!editedWindDeflector) {
      return;
    }

    const elementToUpdate = _.cloneDeep(editedWindDeflector[halter]);

    if (!elementToUpdate) {
      return;
    }

    elementToUpdate[index]![key] = value as T;

    dispatch(
      updateEditedWindDeflector({
        ...editedWindDeflector!,
        [halter]: elementToUpdate,
      }),
    );
  };
}

const WindDeflectorDataDialogHalterDoppellklappe: FC<
  React.PropsWithChildren<unknown>
> = () => {
  const editedWindDeflector = useEditedWindDeflector();
  const dispatch: AdminThunkDispatch<AnyAction> = useDispatch();

  const updateWindDeflectorHolder = getUpdateWindDeflectorHolder(
    editedWindDeflector,
    dispatch,
  );

  return (
    <FormLayout additionalClass="wind-deflector-view-dialog__layout-halter-einzelklappe">
      <div className="wind-deflector-view-dialog__heading1">
        <Headline
          additionalClass="headline--normal-height"
          size={HeadlineSize.MD}
        >
          Blech
        </Headline>
      </div>

      <InputField
        label="Bezeichnung Eckhalter*"
        placeholder="Name des Eckhalters"
        value={editedWindDeflector?.windleitwandhalterBlechDK?.eckhalter}
        additionalClass="wind-deflector-view-dialog__name-eckhalter"
        onChange={newValue =>
          dispatch(
            updateEditedWindDeflector({
              ...editedWindDeflector!,
              windleitwandhalterBlechDK: {
                ...editedWindDeflector!.windleitwandhalterBlechDK!,
                eckhalter: newValue,
              },
            }),
          )
        }
      />

      <div className="wind-deflector-view-dialog__heading2">
        <Headline
          additionalClass="headline--normal-height"
          size={HeadlineSize.MD}
        >
          Glas
        </Headline>
      </div>

      <div className="wind-deflector-view-dialog__sub-group-wlw-glas-pultdach">
        <div className="wind-deflector-view-dialog__sub-heading">
          <Headline
            additionalClass="headline--normal-height"
            size={HeadlineSize.SM}
          >
            Pultdach
          </Headline>
        </div>
        <InputField
          label="Bezeichnung Eckhalter*"
          placeholder="Name des Eckhalters"
          value={
            editedWindDeflector?.windleitwandhalterGlasPultdachDK![0].eckhalter
          }
          additionalClass="wind-deflector-view-dialog__row1-column1"
          onChange={eckhalter =>
            updateWindDeflectorHolder(
              eckhalter,
              'eckhalter',
              'windleitwandhalterGlasPultdachDK',
              0,
            )
          }
        />
        <InputFieldNumber
          label="Bis max WLW Höhe (mm)*"
          value={
            editedWindDeflector?.windleitwandhalterGlasPultdachDK![0].maxHoehe
          }
          additionalClass="wind-deflector-view-dialog__row1-column2"
          onChange={maxHoehe =>
            updateWindDeflectorHolder(
              maxHoehe,
              'maxHoehe',
              'windleitwandhalterGlasPultdachDK',
              0,
            )
          }
        />
        <InputFieldNumber
          label="Abstand beinhalteter Windhalter (mm)* dd"
          value={
            editedWindDeflector?.windleitwandhalterGlasPultdachDK![0]
              .abstandBeinhalteterGlashalter
          }
          additionalClass="wind-deflector-view-dialog__row1-column3"
          onChange={abstandBeinhalteterGlashalter =>
            updateWindDeflectorHolder(
              abstandBeinhalteterGlashalter,
              'abstandBeinhalteterGlashalter',
              'windleitwandhalterGlasPultdachDK',
              0,
            )
          }
        />
        <InputField
          label="Bezeichnung Mittelhalter"
          placeholder="Bezeichnung Mittelhalter"
          value={
            editedWindDeflector?.windleitwandhalterGlasPultdachDK![0]
              .mittelhalter
          }
          additionalClass="wind-deflector-view-dialog__row1-column4"
          onChange={mittelhalter =>
            updateWindDeflectorHolder(
              mittelhalter,
              'mittelhalter',
              'windleitwandhalterGlasPultdachDK',
              0,
            )
          }
        />
        <InputField
          label="Bezeichnung Eckhalter*"
          placeholder="Name des Eckhalters"
          value={
            editedWindDeflector?.windleitwandhalterGlasPultdachDK![1].eckhalter
          }
          additionalClass="wind-deflector-view-dialog__row2-column1"
          onChange={eckhalter =>
            updateWindDeflectorHolder(
              eckhalter,
              'eckhalter',
              'windleitwandhalterGlasPultdachDK',
              1,
            )
          }
        />
        <InputFieldNumber
          label="Bis max WLW Höhe (mm)*"
          value={
            editedWindDeflector?.windleitwandhalterGlasPultdachDK![1].maxHoehe
          }
          additionalClass="wind-deflector-view-dialog__row2-column2"
          onChange={maxHoehe =>
            updateWindDeflectorHolder(
              maxHoehe,
              'maxHoehe',
              'windleitwandhalterGlasPultdachDK',
              1,
            )
          }
        />
        <InputFieldNumber
          label="Abstand beinhalteter Windhalter (mm)*"
          value={
            editedWindDeflector?.windleitwandhalterGlasPultdachDK![1]
              .abstandBeinhalteterGlashalter
          }
          additionalClass="wind-deflector-view-dialog__row2-column3"
          onChange={abstandBeinhalteterGlashalter =>
            updateWindDeflectorHolder(
              abstandBeinhalteterGlashalter,
              'abstandBeinhalteterGlashalter',
              'windleitwandhalterGlasPultdachDK',
              1,
            )
          }
        />
        <div className="wind-deflector-view-dialog__row1-active wind-deflector-view-dialog__active-switch">
          <Switch
            turnedOn={
              !!editedWindDeflector?.windleitwandhalterGlasPultdachDK![0].active
            }
            onChange={active => {
              updateWindDeflectorHolder(
                active,
                'active',
                'windleitwandhalterGlasPultdachDK',
                0,
              );
            }}
          />
          <label>Aktiv</label>
        </div>
        <InputField
          label="Bezeichnung Mittelhalter"
          placeholder="Bezeichnung Mittelhalter"
          value={
            editedWindDeflector?.windleitwandhalterGlasPultdachDK![1]
              .mittelhalter
          }
          additionalClass="wind-deflector-view-dialog__row2-column4"
          onChange={mittelhalter =>
            updateWindDeflectorHolder(
              mittelhalter,
              'mittelhalter',
              'windleitwandhalterGlasPultdachDK',
              1,
            )
          }
        />
        <div className="wind-deflector-view-dialog__row2-active wind-deflector-view-dialog__active-switch">
          <Switch
            turnedOn={
              !!editedWindDeflector?.windleitwandhalterGlasPultdachDK![1].active
            }
            onChange={active => {
              updateWindDeflectorHolder(
                active,
                'active',
                'windleitwandhalterGlasPultdachDK',
                1,
              );
            }}
          />
          <label>Aktiv</label>
        </div>
      </div>

      <div className="wind-deflector-view-dialog__sub-group-2">
        <div className="wind-deflector-view-dialog__sub-heading">
          <Headline
            additionalClass="headline--normal-height"
            size={HeadlineSize.SM}
          >
            Satteldach
          </Headline>
        </div>
        <InputField
          label="Bezeichnung Eckhalter*"
          placeholder="Name des Eckhalters"
          value={
            editedWindDeflector?.windleitwandhalterGlasSatteldachDK![0]
              .eckhalter
          }
          additionalClass="wind-deflector-view-dialog__row1-column1"
          onChange={eckhalter =>
            updateWindDeflectorHolder(
              eckhalter,
              'eckhalter',
              'windleitwandhalterGlasSatteldachDK',
              0,
            )
          }
        />
        <InputFieldNumber
          label="Bis max WLW Höhe (mm)*"
          value={
            editedWindDeflector?.windleitwandhalterGlasSatteldachDK![0].maxHoehe
          }
          additionalClass="wind-deflector-view-dialog__row1-column2"
          onChange={maxHoehe =>
            updateWindDeflectorHolder(
              maxHoehe,
              'maxHoehe',
              'windleitwandhalterGlasSatteldachDK',
              0,
            )
          }
        />
        <InputFieldNumber
          label="Abstand beinhalteter Windhalter (mm)*"
          value={
            editedWindDeflector?.windleitwandhalterGlasSatteldachDK![0]
              .abstandBeinhalteterGlashalter
          }
          additionalClass="wind-deflector-view-dialog__row1-column3"
          onChange={abstandBeinhalteterGlashalter =>
            updateWindDeflectorHolder(
              abstandBeinhalteterGlashalter,
              'abstandBeinhalteterGlashalter',
              'windleitwandhalterGlasSatteldachDK',
              0,
            )
          }
        />
        <div className="wind-deflector-view-dialog__row1-active wind-deflector-view-dialog__active-switch">
          <Switch
            turnedOn={
              !!editedWindDeflector?.windleitwandhalterGlasSatteldachDK![0]
                .active
            }
            onChange={active => {
              updateWindDeflectorHolder(
                active,
                'active',
                'windleitwandhalterGlasSatteldachDK',
                0,
              );
            }}
          />
          <label>Aktiv</label>
        </div>
        <InputField
          label="Bezeichnung Eckhalter*"
          placeholder="Name des Eckhalters"
          value={
            editedWindDeflector?.windleitwandhalterGlasSatteldachDK![1]
              .eckhalter
          }
          additionalClass="wind-deflector-view-dialog__row2-column1"
          onChange={eckhalter =>
            updateWindDeflectorHolder(
              eckhalter,
              'eckhalter',
              'windleitwandhalterGlasSatteldachDK',
              1,
            )
          }
        />
        <InputFieldNumber
          label="Bis max WLW Höhe (mm)*"
          value={
            editedWindDeflector?.windleitwandhalterGlasSatteldachDK![1].maxHoehe
          }
          additionalClass="wind-deflector-view-dialog__row2-column2"
          onChange={maxHoehe =>
            updateWindDeflectorHolder(
              maxHoehe,
              'maxHoehe',
              'windleitwandhalterGlasSatteldachDK',
              1,
            )
          }
        />
        <InputFieldNumber
          label="Abstand beinhalteter Windhalter (mm)*"
          value={
            editedWindDeflector?.windleitwandhalterGlasSatteldachDK![1]
              .abstandBeinhalteterGlashalter
          }
          additionalClass="wind-deflector-view-dialog__row2-column3"
          onChange={abstandBeinhalteterGlashalter =>
            updateWindDeflectorHolder(
              abstandBeinhalteterGlashalter,
              'abstandBeinhalteterGlashalter',
              'windleitwandhalterGlasSatteldachDK',
              1,
            )
          }
        />
        <div className="wind-deflector-view-dialog__row2-active wind-deflector-view-dialog__active-switch">
          <Switch
            turnedOn={
              !!editedWindDeflector?.windleitwandhalterGlasSatteldachDK![1]
                .active
            }
            onChange={active => {
              updateWindDeflectorHolder(
                active,
                'active',
                'windleitwandhalterGlasSatteldachDK',
                1,
              );
            }}
          />
          <label>Aktiv</label>
        </div>
        <InputField
          label="Bezeichnung Glashalter*"
          placeholder="Name des Glashalters"
          value={editedWindDeflector?.bezeichnungGlashalterDK}
          additionalClass="wind-deflector-view-dialog__row3-column1"
          onChange={newValue =>
            dispatch(
              updateEditedWindDeflector({
                ...editedWindDeflector!,
                bezeichnungGlashalterDK: newValue,
              }),
            )
          }
        />
        <InputFieldNumber
          label="Abstand zwischen Montageplätzen (mm)*"
          value={editedWindDeflector?.abstandMontagesaetzeDK}
          additionalClass="wind-deflector-view-dialog__row3-column2"
          onChange={newValue =>
            dispatch(
              updateEditedWindDeflector({
                ...editedWindDeflector!,
                abstandMontagesaetzeEK: newValue,
              }),
            )
          }
        />
      </div>
    </FormLayout>
  );
};

const WindDeflectorDataDialogEinzelklappeKipp: FC<
  React.PropsWithChildren<unknown>
> = () => {
  const editedWindDeflector = useEditedWindDeflector();
  const dispatch: AdminThunkDispatch<AnyAction> = useDispatch();

  function addRow(): void {
    const einzelklappen = editedWindDeflector!.einzelklappenKipp || [];
    einzelklappen.push({
      id: undefined,
      oeffnungswinkelMin: undefined,
      oeffnungswinkelMax: undefined,
      einbaulageMin: undefined,
      einbaulageMax: undefined,
      punkt1Hoehe: undefined,
      punkt1Breite: undefined,
      punkt2Hoehe: undefined,
      punkt2Breite: undefined,
      wlwHoehe: undefined,
      beschraenkt: undefined,
    });

    editedWindDeflector &&
      dispatch(
        updateEditedWindDeflector({
          ...editedWindDeflector,
          einzelklappenKipp: einzelklappen,
        }),
      );
  }

  function deleteRow(i: number): void {
    const dps = editedWindDeflector!.doppelklappen!;
    dps.splice(i, 1);
    editedWindDeflector &&
      dispatch(
        updateEditedWindDeflector({
          ...editedWindDeflector,
          doppelklappen: dps,
        }),
      );
  }

  return (
    <div className="wind-deflector-view-dialog__layout-einzelklappe-kipp">
      <FormLayout additionalClass="wind-deflector-view-dialog__ekKipp-input-section">
        <FormLayoutElement rowStart={1} columnStart={1} columnWidth={6}>
          <div className="wind-deflector-view-dialog__ekKipp-input-section-row">
            <FormLayoutElement rowStart={1} columnStart={1} columnWidth={1}>
              Windleitwandhöhe (mm) für Einbaulage von{' '}
            </FormLayoutElement>
            <FormLayoutElement rowStart={1} columnStart={2} columnWidth={1}>
              <InputFieldNumber
                value={editedWindDeflector?.minEinbauneigungWlwNachLichteBreite}
                onChange={newValue =>
                  dispatch(
                    updateEditedWindDeflector({
                      ...editedWindDeflector!,
                      minEinbauneigungWlwNachLichteBreite: newValue,
                    }),
                  )
                }
                additionalClass="wind-deflector-view-dialog__input-section-select-field"
              />
            </FormLayoutElement>

            <FormLayoutElement rowStart={1} columnStart={3} columnWidth={1}>
              Grad bis{' '}
            </FormLayoutElement>

            <FormLayoutElement rowStart={1} columnStart={4} columnWidth={1}>
              <InputFieldNumber
                value={editedWindDeflector?.maxEinbauneigungWlwNachLichteBreite}
                onChange={newValue =>
                  dispatch(
                    updateEditedWindDeflector({
                      ...editedWindDeflector!,
                      maxEinbauneigungWlwNachLichteBreite: newValue,
                    }),
                  )
                }
                additionalClass="wind-deflector-view-dialog__input-section-select-field"
              />
            </FormLayoutElement>
            <FormLayoutElement rowStart={1} columnStart={5} columnWidth={1}>
              Grad
            </FormLayoutElement>
          </div>
        </FormLayoutElement>

        <FormLayoutElement rowStart={2} columnStart={1} columnWidth={6}>
          <div className="wind-deflector-view-dialog__ekKipp-input-section-row">
            <FormLayoutElement rowStart={2} columnStart={1} columnWidth={1}>
              {'Flügelbreite <='}
            </FormLayoutElement>
            <FormLayoutElement rowStart={2} columnStart={1} columnWidth={1}>
              <InputFieldNumber
                value={editedWindDeflector?.sashWidth1}
                onChange={newValue =>
                  dispatch(
                    updateEditedWindDeflector({
                      ...editedWindDeflector!,
                      sashWidth1: newValue,
                    }),
                  )
                }
                additionalClass="wind-deflector-view-dialog__input-section-select-field"
              />
            </FormLayoutElement>
            <FormLayoutElement rowStart={2} columnStart={2} columnWidth={1}>
              {' '}
              Hwlw{' '}
            </FormLayoutElement>

            <FormLayoutElement
              rowStart={2}
              columnStart={2}
              columnWidth={1}
              additionalClass="wind-deflector-view-dialog__seperator"
            >
              <InputFieldNumber
                value={editedWindDeflector?.wlwHoehe1}
                onChange={newValue =>
                  dispatch(
                    updateEditedWindDeflector({
                      ...editedWindDeflector!,
                      wlwHoehe1: newValue,
                    }),
                  )
                }
                additionalClass="wind-deflector-view-dialog__input-section-select-field"
              />
            </FormLayoutElement>

            <FormLayoutElement rowStart={2} columnStart={3} columnWidth={1}>
              <InputFieldNumber
                value={editedWindDeflector?.sashWidth1}
                onChange={newValue =>
                  dispatch(
                    updateEditedWindDeflector({
                      ...editedWindDeflector!,
                      sashWidth1: newValue,
                    }),
                  )
                }
                additionalClass="wind-deflector-view-dialog__input-section-select-field"
              />
            </FormLayoutElement>
            <FormLayoutElement rowStart={2} columnStart={3} columnWidth={1}>
              {'< Flügelbreite <='}
            </FormLayoutElement>
            <FormLayoutElement rowStart={2} columnStart={3} columnWidth={1}>
              <InputFieldNumber
                value={editedWindDeflector?.sashWidth2}
                onChange={newValue =>
                  dispatch(
                    updateEditedWindDeflector({
                      ...editedWindDeflector!,
                      sashWidth2: newValue,
                    }),
                  )
                }
                additionalClass="wind-deflector-view-dialog__input-section-select-field"
              />
            </FormLayoutElement>
            <FormLayoutElement rowStart={2} columnStart={4} columnWidth={1}>
              {'Hwlw'}
            </FormLayoutElement>
            <FormLayoutElement
              rowStart={2}
              columnStart={4}
              columnWidth={1}
              additionalClass="wind-deflector-view-dialog__seperator"
            >
              <InputFieldNumber
                value={editedWindDeflector?.wlwHoehe2}
                onChange={newValue =>
                  dispatch(
                    updateEditedWindDeflector({
                      ...editedWindDeflector!,
                      wlwHoehe2: newValue,
                    }),
                  )
                }
                additionalClass="wind-deflector-view-dialog__input-section-select-field"
              />
            </FormLayoutElement>

            <FormLayoutElement rowStart={2} columnStart={5} columnWidth={1}>
              <InputFieldNumber
                value={editedWindDeflector?.sashWidth2}
                onChange={newValue =>
                  dispatch(
                    updateEditedWindDeflector({
                      ...editedWindDeflector!,
                      sashWidth2: newValue,
                    }),
                  )
                }
                additionalClass="wind-deflector-view-dialog__input-section-select-field"
              />
            </FormLayoutElement>
            <FormLayoutElement rowStart={2} columnStart={5} columnWidth={1}>
              {'< Flügelbreite '}
            </FormLayoutElement>
            <FormLayoutElement
              rowStart={2}
              columnStart={6}
              columnWidth={1}
              additionalClass="wind-deflector-view-dialog__label-with-space"
            >
              Hwlw
            </FormLayoutElement>
            <FormLayoutElement rowStart={2} columnStart={6} columnWidth={1}>
              <InputFieldNumber
                value={editedWindDeflector?.wlwHoehe3}
                onChange={newValue =>
                  dispatch(
                    updateEditedWindDeflector({
                      ...editedWindDeflector!,
                      wlwHoehe3: newValue,
                    }),
                  )
                }
                additionalClass="wind-deflector-view-dialog__input-section-select-field"
              />
            </FormLayoutElement>
          </div>
        </FormLayoutElement>
      </FormLayout>

      <div className="sub-header wind-deflector-view-dialog__table-sub-header">
        <div className="sub-header__title">Einzelklappen Kipp</div>
        <button
          className="sub-header__button sub-header__button--add"
          onClick={e => {
            addRow();
            e.preventDefault();
          }}
        >
          {UiConstants.NEW_ROW}
        </button>
      </div>
      <div className="wind-deflector-view-dialog__table">
        <Table>
          <TableHeader>
            <th>min Öffnung (°)</th>
            <th>max Öffnung (°)</th>
            <th>min Einbaulage (°)</th>
            <th>max Einbaulage (°)</th>
            <th>Punkt1 Breite (mm)</th>
            <th>Punkt1 Höhe (mm)</th>
            <th>Punkt2 Breite (mm)</th>
            <th>Punkt2 Höhe (mm)</th>
            <th>WLW Höhe (mm)</th>
            <th>Beschränkt</th>
            <th>Aktion</th>
          </TableHeader>
          <tbody>
            {editedWindDeflector?.einzelklappenKipp!.map(
              (einzelklappeKipp, i) => (
                <TableRow>
                  <td>
                    <InputFieldNumber
                      value={einzelklappeKipp.oeffnungswinkelMin}
                      onChange={newValue => {
                        const elementsToUpdate: Edit<RoofFlap>[] =
                          editedWindDeflector.einzelklappenKipp!;

                        elementsToUpdate!.splice(i, 1, {
                          ...editedWindDeflector?.einzelklappenKipp![i],
                          oeffnungswinkelMin: newValue,
                        });

                        dispatch(
                          updateEditedWindDeflector({
                            ...editedWindDeflector!,
                            einzelklappenKipp: elementsToUpdate as RoofFlap[],
                          }),
                        );
                      }}
                    />
                  </td>
                  <td>
                    <InputFieldNumber
                      value={einzelklappeKipp.oeffnungswinkelMax}
                      onChange={newValue => {
                        const elementsToUpdate =
                          editedWindDeflector?.einzelklappenKipp;

                        elementsToUpdate!.splice(i, 1, {
                          ...editedWindDeflector?.einzelklappenKipp![i],
                          oeffnungswinkelMax: newValue || undefined,
                        });

                        dispatch(
                          updateEditedWindDeflector({
                            ...editedWindDeflector!,
                            einzelklappenKipp: elementsToUpdate!,
                          }),
                        );
                      }}
                    />
                  </td>
                  <td>
                    <InputFieldNumber
                      value={einzelklappeKipp.einbaulageMin}
                      onChange={newValue => {
                        const elementsToUpdate =
                          editedWindDeflector?.einzelklappenKipp;

                        elementsToUpdate!.splice(i, 1, {
                          ...editedWindDeflector?.einzelklappenKipp![i],
                          einbaulageMin: newValue || undefined,
                        });

                        dispatch(
                          updateEditedWindDeflector({
                            ...editedWindDeflector!,
                            einzelklappenKipp: elementsToUpdate!,
                          }),
                        );
                      }}
                    />
                  </td>
                  <td>
                    <InputFieldNumber
                      value={einzelklappeKipp.einbaulageMax}
                      onChange={newValue => {
                        const elementsToUpdate =
                          editedWindDeflector?.einzelklappenKipp;

                        elementsToUpdate!.splice(i, 1, {
                          ...editedWindDeflector?.einzelklappenKipp![i],
                          einbaulageMax: newValue!,
                        });

                        dispatch(
                          updateEditedWindDeflector({
                            ...editedWindDeflector!,
                            einzelklappenKipp: elementsToUpdate!,
                          }),
                        );
                      }}
                    />
                  </td>
                  <td>
                    <InputFieldNumber
                      value={einzelklappeKipp.punkt1Breite}
                      onChange={newValue => {
                        const elementsToUpdate =
                          editedWindDeflector?.einzelklappenKipp;

                        elementsToUpdate!.splice(i, 1, {
                          ...editedWindDeflector?.einzelklappenKipp![i],
                          punkt1Breite: newValue!,
                        });

                        dispatch(
                          updateEditedWindDeflector({
                            ...editedWindDeflector!,
                            einzelklappenKipp: elementsToUpdate!,
                          }),
                        );
                      }}
                    />
                  </td>
                  <td>
                    <InputFieldNumber
                      value={einzelklappeKipp.punkt1Hoehe}
                      onChange={newValue => {
                        const elementsToUpdate =
                          editedWindDeflector?.einzelklappenKipp;

                        elementsToUpdate!.splice(i, 1, {
                          ...editedWindDeflector?.einzelklappenKipp![i],
                          punkt1Hoehe: newValue || undefined,
                        });

                        dispatch(
                          updateEditedWindDeflector({
                            ...editedWindDeflector!,
                            einzelklappenKipp: elementsToUpdate!,
                          }),
                        );
                      }}
                    />
                  </td>
                  <td>
                    <InputFieldNumber
                      value={einzelklappeKipp.punkt2Breite}
                      onChange={newValue => {
                        const elementsToUpdate =
                          editedWindDeflector?.einzelklappenKipp;

                        elementsToUpdate!.splice(i, 1, {
                          ...editedWindDeflector?.einzelklappenKipp![i],
                          punkt2Breite: newValue || undefined,
                        });

                        dispatch(
                          updateEditedWindDeflector({
                            ...editedWindDeflector!,
                            einzelklappenKipp: elementsToUpdate!,
                          }),
                        );
                      }}
                    />
                  </td>
                  <td>
                    <InputFieldNumber
                      value={einzelklappeKipp.punkt2Hoehe}
                      onChange={newValue => {
                        const elementsToUpdate =
                          editedWindDeflector?.einzelklappenKipp;

                        elementsToUpdate!.splice(i, 1, {
                          ...editedWindDeflector?.einzelklappenKipp![i],
                          punkt2Hoehe: newValue!,
                        });

                        dispatch(
                          updateEditedWindDeflector({
                            ...editedWindDeflector!,
                            einzelklappenKipp: elementsToUpdate!,
                          }),
                        );
                      }}
                    />
                  </td>
                  <td>
                    <InputFieldNumber
                      value={einzelklappeKipp.wlwHoehe}
                      onChange={newValue => {
                        const elementsToUpdate =
                          editedWindDeflector?.einzelklappenKipp;

                        elementsToUpdate!.splice(i, 1, {
                          ...editedWindDeflector?.einzelklappenKipp![i],
                          wlwHoehe: newValue!,
                        });

                        dispatch(
                          updateEditedWindDeflector({
                            ...editedWindDeflector!,
                            einzelklappenKipp: elementsToUpdate!,
                          }),
                        );
                      }}
                    />
                  </td>
                  <td>
                    <Switch
                      turnedOn={einzelklappeKipp.beschraenkt || false}
                      onChange={newValue => {
                        const elementsToUpdate =
                          editedWindDeflector?.einzelklappenKipp;

                        elementsToUpdate!.splice(i, 1, {
                          ...editedWindDeflector?.einzelklappenKipp![i],
                          beschraenkt: newValue,
                        });

                        dispatch(
                          updateEditedWindDeflector({
                            ...editedWindDeflector!,
                            einzelklappenKipp: elementsToUpdate!,
                          }),
                        );
                      }}
                    />
                  </td>

                  <td>
                    <Button
                      type={ButtonType.SECONDARY}
                      action={e => {
                        deleteRow(i);
                        e.preventDefault();
                      }}
                      labelText="Löschen"
                    />
                  </td>
                </TableRow>
              ),
            )}
          </tbody>
        </Table>
      </div>
    </div>
  );
};

function WindDeflectorDataDialogDoppelklappeDrehDreh(): ReactElement {
  const editedWindDeflector = useEditedWindDeflector()!;
  const dispatch = useDispatch();
  function cvValuesToString(
    cvValues: DoppelklappeDrehDreh[] | undefined,
  ): string {
    if (cvValues === undefined) {
      return '';
    }

    return cvValues
      .map(
        c =>
          `${c.openingAngle?.toString()}  ${c.avLarger6?.toString()}    ${
            c.avSmallerEqual6
          }  ${c.avSmallerEqual3} ${c.avSmallerEqual1Dot5}\n`,
      )
      .toString()
      .replaceAll(',', '');
  }

  if (!editedWindDeflector) {
    return <></>;
  }

  return (
    <FormLayout>
      <FormLayoutElement rowStart={1} columnStart={1} columnWidth={2}>
        <CSVInput<DoppelklappeDrehDreh>
          disabled={false}
          value={cvValuesToString(editedWindDeflector!.doppelklappenDrehDreh!)}
          onChange={(v: DoppelklappeDrehDreh[]) => {
            dispatch(
              updateEditedWindDeflector({
                ...editedWindDeflector,
                doppelklappenDrehDreh: v,
              }),
            );
          }}
          arrayToObject={entries =>
            (
              [
                'openingAngle',
                'avLarger6',
                'avSmallerEqual6',
                'avSmallerEqual3',
                'avSmallerEqual1Dot5',
              ] as (keyof DoppelklappeDrehDreh)[]
            ).reduce((acc, key, i) => {
              acc[key] = entries[i];
              return acc;
            }, {} as Record<string, number>) as unknown as DoppelklappeDrehDreh
          }
        />
      </FormLayoutElement>
      <FormLayoutElement rowStart={1} columnStart={3} columnWidth={4}>
        <Table>
          <TableHeader>
            <th>Öffnungswinkel</th>
            <th>Av&gt;6</th>
            <th>Av&lt;=6</th>
            <th>Av&lt;=3</th>
            <th>Av&lt;=1,5</th>
          </TableHeader>
          <tbody>
            {editedWindDeflector!.doppelklappenDrehDreh?.map(
              (doppelklappeDrehDreh, i) => (
                <TableRow key={doppelklappeDrehDreh.openingAngle}>
                  <td>{doppelklappeDrehDreh.openingAngle}</td>
                  <td>{doppelklappeDrehDreh.avLarger6}</td>
                  <td>{doppelklappeDrehDreh.avSmallerEqual6}</td>
                  <td>{doppelklappeDrehDreh.avSmallerEqual3}</td>
                  <td>{doppelklappeDrehDreh.avSmallerEqual1Dot5}</td>
                </TableRow>
              ),
            )}
          </tbody>
        </Table>
      </FormLayoutElement>
    </FormLayout>
  );
}

const WindDeflectorDataDialogDoppelklappeKippKlapp: FC<
  React.PropsWithChildren<unknown>
> = () => {
  const editedWindDeflector = useEditedWindDeflector();
  const dispatch: AdminThunkDispatch<AnyAction> = useDispatch();

  function addRow(): void {
    const doppelklappen = editedWindDeflector!.doppelklappen || [];
    doppelklappen.push({
      id: undefined,
      oeffnungswinkelMin: undefined,
      oeffnungswinkelMax: undefined,
      einbaulageMin: undefined,
      einbaulageMax: undefined,
      punkt1Hoehe: undefined,
      punkt1Breite: undefined,
      punkt2Hoehe: undefined,
      punkt2Breite: undefined,
      wlwHoehe: undefined,
      beschraenkt: false,
      dachform: undefined,
    });

    editedWindDeflector &&
      dispatch(
        updateEditedWindDeflector({
          ...editedWindDeflector,
          doppelklappen: doppelklappen,
        }),
      );
  }
  function deleteRow(i: number): void {
    const doppelklappen = editedWindDeflector!.doppelklappen!;
    doppelklappen.splice(i, 1);
    editedWindDeflector &&
      dispatch(
        updateEditedWindDeflector({
          ...editedWindDeflector,
          doppelklappen: doppelklappen,
        }),
      );
  }

  return (
    <div className="wind-deflector-view-dialog__layout-raw-table">
      <div className="sub-header wind-deflector-view-dialog__table-sub-header">
        <div className="sub-header__title">Doppelklappen</div>
        <button
          className="sub-header__button sub-header__button--add"
          onClick={e => {
            addRow();
            e.preventDefault();
          }}
        >
          {UiConstants.NEW_ROW}
        </button>
      </div>

      <div className="wind-deflector-view-dialog__table">
        <Table>
          <TableHeader>
            <th>min Öffnung (°)</th>
            <th>max Öffnung (°)</th>
            <th>min Einbaulage (°)</th>
            <th>max Einbaulage (°)</th>
            <th>Dachform</th>
            <th>Punkt1 Breite (mm)</th>
            <th>Punkt1 Höhe (mm)</th>
            <th>Punkt2 Breite (mm)</th>
            <th>Punkt2 Höhe (mm)</th>
            <th>WLW Höhe (mm)</th>
            <th>Aktion</th>
          </TableHeader>
          <tbody>
            {editedWindDeflector?.doppelklappen?.map((doppelklappe, i) => (
              <TableRow>
                <td>
                  <InputFieldNumber
                    value={doppelklappe.oeffnungswinkelMin}
                    onChange={newValue => {
                      const doubleFlapsToUpdate =
                        editedWindDeflector?.doppelklappen;

                      doubleFlapsToUpdate!.splice(i, 1, {
                        ...editedWindDeflector?.doppelklappen![i],
                        oeffnungswinkelMin: newValue || undefined,
                      });

                      dispatch(
                        updateEditedWindDeflector({
                          ...editedWindDeflector!,
                          doppelklappen: doubleFlapsToUpdate!,
                        }),
                      );
                    }}
                  />
                </td>
                <td>
                  <InputFieldNumber
                    value={doppelklappe.oeffnungswinkelMax}
                    onChange={newValue => {
                      const doubleFlapsToUpdate =
                        editedWindDeflector?.doppelklappen;

                      doubleFlapsToUpdate!.splice(i, 1, {
                        ...editedWindDeflector?.doppelklappen![i],
                        oeffnungswinkelMax: newValue || undefined,
                      });

                      dispatch(
                        updateEditedWindDeflector({
                          ...editedWindDeflector!,
                          doppelklappen: doubleFlapsToUpdate!,
                        }),
                      );
                    }}
                  />
                </td>
                <td>
                  <InputFieldNumber
                    value={doppelklappe.einbaulageMin}
                    onChange={newValue => {
                      const doubleFlapsToUpdate =
                        editedWindDeflector?.doppelklappen;

                      doubleFlapsToUpdate!.splice(i, 1, {
                        ...editedWindDeflector?.doppelklappen![i],
                        einbaulageMin: newValue || undefined,
                      });

                      dispatch(
                        updateEditedWindDeflector({
                          ...editedWindDeflector!,
                          doppelklappen: doubleFlapsToUpdate!,
                        }),
                      );
                    }}
                  />
                </td>
                <td>
                  <InputFieldNumber
                    value={doppelklappe.einbaulageMax}
                    onChange={newValue => {
                      const doubleFlapsToUpdate =
                        editedWindDeflector?.doppelklappen;

                      doubleFlapsToUpdate!.splice(i, 1, {
                        ...editedWindDeflector?.doppelklappen![i],
                        einbaulageMax: newValue || undefined,
                      });

                      dispatch(
                        updateEditedWindDeflector({
                          ...editedWindDeflector!,
                          doppelklappen: doubleFlapsToUpdate!,
                        }),
                      );
                    }}
                  />
                </td>
                <td
                  className={
                    'wind-deflector-view-dialog__table-cell-select-field'
                  }
                >
                  <SelectField
                    additionalClass="wind-deflector-view-dialog__table-select-field"
                    menuPlacement={MenuPlacement.BOTTOM}
                    value={
                      doppelklappe.dachform
                        ? {
                            value: doppelklappe.dachform,
                            label: doppelklappe.dachform,
                          }
                        : undefined
                    }
                    action={newValue => {
                      const doubleFlapsToUpdate =
                        editedWindDeflector?.doppelklappen;

                      doubleFlapsToUpdate!.splice(i, 1, {
                        ...editedWindDeflector?.doppelklappen![i],
                        dachform: newValue.value,
                      });

                      dispatch(
                        updateEditedWindDeflector({
                          ...editedWindDeflector!,
                          doppelklappen: doubleFlapsToUpdate!,
                        }),
                      );
                    }}
                    options={ROOF_TYPE_OPTIONS.map(roofType => ({
                      label: roofType.roofType,
                      value: roofType.roofType,
                    }))}
                    name="roof-Type-field"
                    searchable={true}
                  />
                </td>
                <td>
                  <InputFieldNumber
                    value={doppelklappe.punkt1Breite}
                    onChange={newValue => {
                      const doubleFlapsToUpdate =
                        editedWindDeflector?.doppelklappen;

                      doubleFlapsToUpdate!.splice(i, 1, {
                        ...editedWindDeflector?.doppelklappen![i],
                        punkt1Breite: newValue || undefined,
                      });

                      dispatch(
                        updateEditedWindDeflector({
                          ...editedWindDeflector!,
                          doppelklappen: doubleFlapsToUpdate!,
                        }),
                      );
                    }}
                  />
                </td>
                <td>
                  <InputFieldNumber
                    value={doppelklappe.punkt1Hoehe}
                    onChange={newValue => {
                      const doubleFlapsToUpdate =
                        editedWindDeflector?.doppelklappen;

                      doubleFlapsToUpdate!.splice(i, 1, {
                        ...editedWindDeflector?.doppelklappen![i],
                        punkt1Hoehe: newValue || undefined,
                      });

                      dispatch(
                        updateEditedWindDeflector({
                          ...editedWindDeflector!,
                          doppelklappen: doubleFlapsToUpdate!,
                        }),
                      );
                    }}
                  />
                </td>
                <td>
                  <InputFieldNumber
                    value={doppelklappe.punkt2Breite}
                    onChange={newValue => {
                      const doubleFlapsToUpdate =
                        editedWindDeflector?.doppelklappen;

                      doubleFlapsToUpdate!.splice(i, 1, {
                        ...editedWindDeflector?.doppelklappen![i],
                        punkt2Breite: newValue || undefined,
                      });

                      dispatch(
                        updateEditedWindDeflector({
                          ...editedWindDeflector!,
                          doppelklappen: doubleFlapsToUpdate!,
                        }),
                      );
                    }}
                  />
                </td>
                <td>
                  <InputFieldNumber
                    value={doppelklappe.punkt2Hoehe}
                    onChange={newValue => {
                      const doubleFlapsToUpdate =
                        editedWindDeflector?.doppelklappen;

                      doubleFlapsToUpdate!.splice(i, 1, {
                        ...editedWindDeflector?.doppelklappen![i],
                        punkt2Hoehe: newValue || undefined,
                      });

                      dispatch(
                        updateEditedWindDeflector({
                          ...editedWindDeflector!,
                          doppelklappen: doubleFlapsToUpdate!,
                        }),
                      );
                    }}
                  />
                </td>
                <td>
                  <InputFieldNumber
                    value={doppelklappe.wlwHoehe}
                    onChange={newValue => {
                      const doubleFlapsToUpdate =
                        editedWindDeflector?.doppelklappen;

                      doubleFlapsToUpdate!.splice(i, 1, {
                        ...editedWindDeflector?.doppelklappen![i],
                        wlwHoehe: newValue || undefined,
                      });

                      dispatch(
                        updateEditedWindDeflector({
                          ...editedWindDeflector!,
                          doppelklappen: doubleFlapsToUpdate!,
                        }),
                      );
                    }}
                  />
                </td>

                <td>
                  <Button
                    type={ButtonType.SECONDARY}
                    action={e => {
                      deleteRow(i);
                      e.preventDefault();
                    }}
                    labelText="Löschen"
                  />
                </td>
              </TableRow>
            ))}
          </tbody>
        </Table>
      </div>
    </div>
  );
};

const WindDeflectorDataDialogEinzelklappeKlapp: FC<
  React.PropsWithChildren<unknown>
> = () => {
  const editedWindDeflector = useEditedWindDeflector();
  const dispatch: AdminThunkDispatch<AnyAction> = useDispatch();

  function addRow(): void {
    const einzelklappenKlapp = editedWindDeflector!.einzelklappenKlapp || [];
    einzelklappenKlapp.push({
      id: undefined,
      oeffnungswinkelMin: undefined,
      oeffnungswinkelMax: undefined,
      einbaulageMin: undefined,
      einbaulageMax: undefined,
      punkt1Hoehe: undefined,
      punkt1Breite: undefined,
      punkt2Hoehe: undefined,
      punkt2Breite: undefined,
      wlwHoehe: undefined,
      beschraenkt: undefined,
    });

    editedWindDeflector &&
      dispatch(
        updateEditedWindDeflector({
          ...editedWindDeflector,
          einzelklappenKlapp: einzelklappenKlapp,
        }),
      );
  }
  function deleteRow(i: number): void {
    const einzelklappenKlapp = editedWindDeflector!.einzelklappenKlapp!;
    einzelklappenKlapp.splice(i, 1);
    editedWindDeflector &&
      dispatch(
        updateEditedWindDeflector({
          ...editedWindDeflector,
          einzelklappenKlapp: einzelklappenKlapp,
        }),
      );
  }

  return (
    <div className="wind-deflector-view-dialog__layout-raw-table">
      <div className="sub-header wind-deflector-view-dialog__table-sub-header">
        <div className="sub-header__title">Einzelklappen Klapp</div>
        <button
          className="sub-header__button sub-header__button--add"
          onClick={e => {
            addRow();
            e.preventDefault();
          }}
        >
          {UiConstants.NEW_ROW}
        </button>
      </div>

      <div className="wind-deflector-view-dialog__table">
        <Table>
          <TableHeader>
            <th>min Öffnung (°)</th>
            <th>max Öffnung (°)</th>
            <th>min Einbaulage (°)</th>
            <th>max Einbaulage (°)</th>
            <th>Punkt1 Breite (mm)</th>
            <th>Punkt1 Höhe (mm)</th>
            <th>Punkt2 Breite (mm)</th>
            <th>Punkt2 Höhe (mm)</th>
            <th>WLW Höhe (mm)</th>
            <th>Beschränkt</th>
            <th>Aktion</th>
          </TableHeader>
          <tbody>
            {editedWindDeflector?.einzelklappenKlapp!.map(
              (einzelklappeKlapp, i) => (
                <TableRow>
                  <td>
                    <InputFieldNumber
                      value={einzelklappeKlapp.oeffnungswinkelMin}
                      onChange={newValue => {
                        const singleFlapToUpdate =
                          editedWindDeflector?.einzelklappenKlapp;

                        singleFlapToUpdate!.splice(i, 1, {
                          ...editedWindDeflector?.einzelklappenKlapp![i],
                          oeffnungswinkelMin: newValue || undefined,
                        });

                        dispatch(
                          updateEditedWindDeflector({
                            ...editedWindDeflector!,
                            einzelklappenKlapp: singleFlapToUpdate!,
                          }),
                        );
                      }}
                    />
                  </td>
                  <td>
                    <InputFieldNumber
                      value={einzelklappeKlapp.oeffnungswinkelMax}
                      onChange={newValue => {
                        const singleFlapToUpdate =
                          editedWindDeflector?.einzelklappenKlapp;

                        singleFlapToUpdate!.splice(i, 1, {
                          ...editedWindDeflector?.einzelklappenKlapp![i],
                          oeffnungswinkelMax: newValue || undefined,
                        });

                        dispatch(
                          updateEditedWindDeflector({
                            ...editedWindDeflector!,
                            einzelklappenKlapp: singleFlapToUpdate!,
                          }),
                        );
                      }}
                    />
                  </td>
                  <td>
                    <InputFieldNumber
                      value={einzelklappeKlapp.einbaulageMin}
                      onChange={newValue => {
                        const singleFlapToUpdate =
                          editedWindDeflector?.einzelklappenKlapp;

                        singleFlapToUpdate!.splice(i, 1, {
                          ...editedWindDeflector?.einzelklappenKlapp![i],
                          einbaulageMin: newValue || undefined,
                        });

                        dispatch(
                          updateEditedWindDeflector({
                            ...editedWindDeflector!,
                            einzelklappenKlapp: singleFlapToUpdate!,
                          }),
                        );
                      }}
                    />
                  </td>
                  <td>
                    <InputFieldNumber
                      value={einzelklappeKlapp.einbaulageMax}
                      onChange={newValue => {
                        const singleFlapToUpdate =
                          editedWindDeflector?.einzelklappenKlapp;

                        singleFlapToUpdate!.splice(i, 1, {
                          ...editedWindDeflector?.einzelklappenKlapp![i],
                          einbaulageMax: newValue || undefined,
                        });

                        dispatch(
                          updateEditedWindDeflector({
                            ...editedWindDeflector!,
                            einzelklappenKlapp: singleFlapToUpdate!,
                          }),
                        );
                      }}
                    />
                  </td>
                  <td>
                    <InputFieldNumber
                      value={einzelklappeKlapp.punkt1Breite}
                      onChange={newValue => {
                        const singleFlapToUpdate =
                          editedWindDeflector?.einzelklappenKlapp;

                        singleFlapToUpdate!.splice(i, 1, {
                          ...editedWindDeflector?.einzelklappenKlapp![i],
                          punkt1Breite: newValue || undefined,
                        });

                        dispatch(
                          updateEditedWindDeflector({
                            ...editedWindDeflector!,
                            einzelklappenKlapp: singleFlapToUpdate!,
                          }),
                        );
                      }}
                    />
                  </td>
                  <td>
                    <InputFieldNumber
                      value={einzelklappeKlapp.punkt1Hoehe}
                      onChange={newValue => {
                        const singleFlapToUpdate =
                          editedWindDeflector?.einzelklappenKlapp;

                        singleFlapToUpdate!.splice(i, 1, {
                          ...editedWindDeflector?.einzelklappenKlapp![i],
                          punkt1Hoehe: newValue || undefined,
                        });

                        dispatch(
                          updateEditedWindDeflector({
                            ...editedWindDeflector!,
                            einzelklappenKlapp: singleFlapToUpdate!,
                          }),
                        );
                      }}
                    />
                  </td>
                  <td>
                    <InputFieldNumber
                      value={einzelklappeKlapp.punkt2Breite}
                      onChange={newValue => {
                        const singleFlapToUpdate =
                          editedWindDeflector?.einzelklappenKlapp;

                        singleFlapToUpdate!.splice(i, 1, {
                          ...editedWindDeflector?.einzelklappenKlapp![i],
                          punkt2Breite: newValue || undefined,
                        });

                        dispatch(
                          updateEditedWindDeflector({
                            ...editedWindDeflector!,
                            einzelklappenKlapp: singleFlapToUpdate!,
                          }),
                        );
                      }}
                    />
                  </td>
                  <td>
                    <InputFieldNumber
                      value={einzelklappeKlapp.punkt2Hoehe}
                      onChange={newValue => {
                        const singleFlapToUpdate =
                          editedWindDeflector?.einzelklappenKlapp;

                        singleFlapToUpdate!.splice(i, 1, {
                          ...editedWindDeflector?.einzelklappenKlapp![i],
                          punkt2Hoehe: newValue || undefined,
                        });

                        dispatch(
                          updateEditedWindDeflector({
                            ...editedWindDeflector!,
                            einzelklappenKlapp: singleFlapToUpdate!,
                          }),
                        );
                      }}
                    />
                  </td>
                  <td>
                    <InputFieldNumber
                      value={einzelklappeKlapp.wlwHoehe}
                      onChange={newValue => {
                        const singleFlapToUpdate =
                          editedWindDeflector?.einzelklappenKlapp;

                        singleFlapToUpdate!.splice(i, 1, {
                          ...editedWindDeflector?.einzelklappenKlapp![i],
                          wlwHoehe: newValue || undefined,
                        });

                        dispatch(
                          updateEditedWindDeflector({
                            ...editedWindDeflector!,
                            einzelklappenKlapp: singleFlapToUpdate!,
                          }),
                        );
                      }}
                    />
                  </td>
                  <td>
                    <Switch
                      turnedOn={einzelklappeKlapp.beschraenkt || false}
                      onChange={newValue => {
                        const singleFlapToUpdate =
                          editedWindDeflector?.einzelklappenKipp;

                        singleFlapToUpdate!.splice(i, 1, {
                          ...editedWindDeflector?.einzelklappenKipp![i],
                          beschraenkt: newValue,
                        });

                        dispatch(
                          updateEditedWindDeflector({
                            ...editedWindDeflector!,
                            einzelklappenKipp: singleFlapToUpdate!,
                          }),
                        );
                      }}
                    />
                  </td>
                  <td>
                    <Button
                      type={ButtonType.SECONDARY}
                      action={e => {
                        deleteRow(i);
                        e.preventDefault();
                      }}
                      labelText="Löschen"
                    />
                  </td>
                </TableRow>
              ),
            )}
          </tbody>
        </Table>
      </div>
    </div>
  );
};

interface AdminSystemDialogProps {
  dialogIsShown: boolean;
  setDialogIsShown: (b: boolean) => void;
}

const WindDeflectorDialog: FC<
  React.PropsWithChildren<AdminSystemDialogProps>
> = (props: AdminSystemDialogProps) => {
  const dispatch: AdminThunkDispatch<AnyAction> = useDispatch();
  const editedWindDeflector = useEditedWindDeflector();

  function mandatoryFieldsFilled(): boolean {
    return (
      editedWindDeflector?.doppelklappen!.length !== 0 &&
      editedWindDeflector?.einzelklappenKipp!.length !== 0 &&
      editedWindDeflector?.einzelklappenKlapp!.length !== 0 &&
      fieldsFilled(
        editedWindDeflector?.bezeichnung,
        editedWindDeflector?.maxWLWLaengeBlech,
        editedWindDeflector?.platzbedarfKonsoleBlech,
        editedWindDeflector?.maxWLWLaengeGlas,
        editedWindDeflector?.platzbedarfKonsoleGlas,
        editedWindDeflector?.windleitwandhalterBlechEK!.eckhalter,
        editedWindDeflector?.windleitwandhalterGlasZweiWlwEK![0].eckhalter,
        editedWindDeflector?.windleitwandhalterGlasZweiWlwEK![0].maxHoehe,
        editedWindDeflector?.windleitwandhalterGlasZweiWlwEK![0]
          .abstandBeinhalteterGlashalter,
        editedWindDeflector?.windleitwandhalterGlasZweiWlwEK![1].eckhalter,
        editedWindDeflector?.windleitwandhalterGlasZweiWlwEK![1].maxHoehe,
        editedWindDeflector?.windleitwandhalterGlasZweiWlwEK![1]
          .abstandBeinhalteterGlashalter,
        editedWindDeflector?.windleitwandhalterGlasDreiWlwEK![0].eckhalter,
        editedWindDeflector?.windleitwandhalterGlasDreiWlwEK![0].maxHoehe,
        editedWindDeflector?.windleitwandhalterGlasDreiWlwEK![0]
          .abstandBeinhalteterGlashalter,
        editedWindDeflector?.windleitwandhalterGlasDreiWlwEK![1].eckhalter,
        editedWindDeflector?.windleitwandhalterGlasDreiWlwEK![1].maxHoehe,
        editedWindDeflector?.windleitwandhalterGlasDreiWlwEK![1]
          .abstandBeinhalteterGlashalter,
        editedWindDeflector?.bezeichnungGlashalterEK,
        editedWindDeflector?.abstandMontagesaetzeEK,
        editedWindDeflector?.windleitwandhalterBlechDK!.eckhalter,
        editedWindDeflector?.windleitwandhalterGlasPultdachDK![0].eckhalter,
        editedWindDeflector?.windleitwandhalterGlasPultdachDK![0].maxHoehe,
        editedWindDeflector?.windleitwandhalterGlasPultdachDK![0]
          .abstandBeinhalteterGlashalter,

        editedWindDeflector?.windleitwandhalterGlasPultdachDK![1].eckhalter,
        editedWindDeflector?.windleitwandhalterGlasPultdachDK![1].maxHoehe,
        editedWindDeflector?.windleitwandhalterGlasPultdachDK![1]
          .abstandBeinhalteterGlashalter,
        editedWindDeflector?.windleitwandhalterGlasSatteldachDK![0].eckhalter,
        editedWindDeflector?.windleitwandhalterGlasSatteldachDK![0].maxHoehe,
        editedWindDeflector?.windleitwandhalterGlasSatteldachDK![0]
          .abstandBeinhalteterGlashalter,
        editedWindDeflector?.windleitwandhalterGlasSatteldachDK![1].eckhalter,
        editedWindDeflector?.windleitwandhalterGlasSatteldachDK![1].maxHoehe,
        editedWindDeflector?.windleitwandhalterGlasSatteldachDK![1]
          .abstandBeinhalteterGlashalter,
        editedWindDeflector?.bezeichnungGlashalterDK,
        editedWindDeflector?.abstandMontagesaetzeDK,
      )
    );
  }
  return (
    <Dialog
      setDialogIsShown={props.setDialogIsShown}
      dialogIsShown={props.dialogIsShown}
      headingText={'Windleitwand bearbeiten'}
      componentClass="full-view-dialog"
      key={'wlw' + props.dialogIsShown.toString()}
      footerProps={{
        notTranslated: true,
        cancelAction: () => {
          props.setDialogIsShown(false);
        },
        saveAction: mandatoryFieldsFilled()
          ? () => {
              dispatch(
                changeWindDeflectors([
                  editedWindDeflector as WindDeflectorData,
                ]),
              );
              props.setDialogIsShown(false);
            }
          : undefined,
        primaryActionLabelText: 'Speichern',
      }}
    >
      <WindDeflectorDataDialogTabNav />
      <Routes>
        <Route path="/info/*" element={<WindDeflectorDataDialogInfo />} />
        <Route
          path="/dkKlippKlapp/*"
          element={<WindDeflectorDataDialogDoppelklappeKippKlapp />}
        />
        <Route
          path="/dkDrehDreh/*"
          element={<WindDeflectorDataDialogDoppelklappeDrehDreh />}
        />
        <Route
          path="/ekKipp/*"
          element={<WindDeflectorDataDialogEinzelklappeKipp />}
        />
        <Route
          path="/ekKlapp/*"
          element={<WindDeflectorDataDialogEinzelklappeKlapp />}
        />
        <Route
          path="/halterEK/*"
          element={<WindDeflectorDataDialogHalterEinzelklappe />}
        />
        <Route
          path="/halterDK/*"
          element={<WindDeflectorDataDialogHalterDoppellklappe />}
        />
      </Routes>
    </Dialog>
  );
};

const WindDeflectorDataView: FC<React.PropsWithChildren<unknown>> = props => {
  const dispatch: AdminThunkDispatch<AnyAction> = useDispatch();
  const windDeflectors = useSelector<AdminState, WindDeflectorData[]>(
    state => state.adminRoof.windDeflectors!,
  );

  const [dialogIsShown, setDialogIsShown] = useState(false);

  return (
    <div>
      <WindDeflectorDialog
        dialogIsShown={dialogIsShown}
        setDialogIsShown={setDialogIsShown}
      />

      <div className="sub-header">
        <div className="sub-header__title"> Windleitwände</div>
      </div>
      <div className="wind-deflector-data">
        <Table subNavigation={false}>
          <TableHeader>
            <th>Windleitwandbezeichnung</th>
            <th>Aktion</th>
          </TableHeader>
          <tbody>
            {windDeflectors.map(windDeflector => (
              <TableRow key={windDeflector.id}>
                <td>{windDeflector.bezeichnung}</td>
                <td>
                  <button
                    onClick={() => {
                      setDialogIsShown(true);
                      dispatch(
                        selectEditedWindDeflector(_.cloneDeep(windDeflector)),
                      );
                    }}
                  >
                    {UiConstants.EDIT}
                  </button>
                </td>
              </TableRow>
            ))}
          </tbody>
        </Table>
      </div>

      <Pagination
        searchString={'placeholder because we dont need a searchbar'}
        numberOfPages={1}
        page={1}
        setPage={() => 'prevent'}
        indexOfFirstPageElement={1}
        setIndexOfFirstPageElement={() => 'prevent'}
      />
    </div>
  );
};

export default WindDeflectorDataView;
