import _ from 'lodash';
import Papa from 'papaparse';

export function generateCSVExportFileName(prefix: string): string {
  const date = new Date().toISOString().split('T')[0];
  return `${prefix}_${date}`;
}

export function ignoreProperties<T>(
  rows: T[],
  propertiesToIgnore: string[],
): (keyof T)[] {
  return _.difference(Object.keys(rows[0]!), propertiesToIgnore) as (keyof T)[];
}

export function getCsvRow<T>(values: T[], separator: string): string {
  return values.join(separator) + '\n';
}

export function deliverBlob(userListCSV: string, filename: string): void {
  const blob = new Blob([userListCSV], { type: 'text/csv;charset=utf-8;' });
  // @ts-ignore
  if (navigator.msSaveBlob) {
    // IE 10+
    // @ts-ignore
    navigator.msSaveBlob(blob, filename);
  } else {
    const link = document.createElement('a');
    if (link.download !== undefined) {
      // Browsers that support HTML5 download attribute
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', filename);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
}

export function downloadListAsCSV<T extends Record<keyof T, any>>(
  items: T[],
  separator: string,
  fieldsToIgnore: string[],
  fileNamePrefix: string,
  serializers: Partial<Record<keyof T, (element: any) => string | number>>,
  additionalFields: Record<string, (item: T) => string | number>,
): void {
  const filename = generateCSVExportFileName(fileNamePrefix);
  const keys = ignoreProperties(items, fieldsToIgnore);
  const additionalKeys = Object.keys(additionalFields);

  function getEntriesForKeys(c: T): (string | number)[] {
    function getSerializedValue(key: keyof T): string | number {
      return serializers[key] && serializers[key]!(c[key]);
    }

    function getEntryForKeys(key: keyof T): string | number {
      return getSerializedValue(key) || (c[key] as string | number);
    }

    return keys.map((key: keyof T) => getEntryForKeys(key));
  }

  const csvContent = [
    [...keys, ...additionalKeys],
    ...items.map(c => [
      ...getEntriesForKeys(c),
      ...additionalKeys.map(k => additionalFields[k](c)),
    ]),
  ];

  deliverBlob(Papa.unparse(csvContent, { delimiter: ';' }), filename);
}
