import React, { FC, ReactNode } from 'react';
import classNames from 'classnames';
import LoadingSkeleton from '../elements/LoadingSkeleton';
import Tile from '../elements/Tile';
import './ResultItem.scss';
import { FormattedMessage } from 'react-intl';
import OptionsMenu, { OptionsMenuActions } from './OptionsMenu';

export enum ResultItemStyle {
  WITHOUT_ICONS,
  WITH_ICONS,
}

interface ResultItemProps {
  qaId?: string;
  onClick?: () => void;
  selected?: boolean;
  loading: boolean;
  children?: ReactNode;
  additionalClass?: string;
  actionIcon?: ReactNode;
  name: string | ReactNode;
  byline?: string | ReactNode;
  amount?: string | ReactNode;
  options?: OptionsMenuActions;
  propertiesStyle: ResultItemStyle;
}

export const ResultItem: FC<React.PropsWithChildren<ResultItemProps>> = (
  props: ResultItemProps,
) => {
  return (
    <Tile
      data-qa={props.name}
      additionalClass={classNames(
        'result-item',
        {
          'result-item--selected': props.selected,
          'result-item--with-byline': props.byline,
          'result-item--with-amount': props.amount,
          'result-item--display-only': !props.onClick,
          'result-item--with-options': props.options,
        },
        props.additionalClass,
      )}
      onClick={
        props?.onClick ||
        (() => {
          //empty
        })
      }
    >
      <div className="result-item__byline">{props.byline}</div>
      {props.amount && (
        <div className="result-item__amount">
          {props.amount} <FormattedMessage id="PIECES_ABBREVIATION" />
        </div>
      )}
      {props.options && <OptionsMenu actions={props.options} />}

      <div
        className="result-item__name"
        data-qa={props.qaId}
        data-qa-value={props.name}
      >
        {props.name}
      </div>
      {props.actionIcon && (
        <div className="result-item__plus-icon"> {props.actionIcon}</div>
      )}
      <div
        className={classNames('result-item__properties', {
          'result-item__properties--with-icons':
            props.propertiesStyle === ResultItemStyle.WITH_ICONS,
          'result-item__properties--without-icons':
            props.propertiesStyle === ResultItemStyle.WITHOUT_ICONS,
        })}
      >
        {' '}
        {props.children}
      </div>
      {props.loading && <LoadingSkeleton />}
    </Tile>
  );
};

export default ResultItem;
