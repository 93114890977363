import React, { FC, useEffect, useRef, useState } from 'react';
import { MDCMenu } from '@material/menu';
import '@material/list/mdc-list.scss';
import '@material/menu-surface/mdc-menu-surface.scss';
import '@material/menu/mdc-menu.scss';
import './OptionsMenu.scss';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

export type OptionsMenuActions = Record<string, () => void>;

export interface OptionsMenuProps {
  actions: OptionsMenuActions;
  menuOpened?: (opened: boolean) => void;
}

export const OptionsMenu: FC<React.PropsWithChildren<OptionsMenuProps>> = (
  props: OptionsMenuProps,
) => {
  const menuRef = useRef<MDCMenu | undefined>();
  const menuDomRef = useRef<HTMLDivElement>(null);
  const [menuOpen, setMenuOpen] = useState(false);

  function menuOpened(event: any, b: boolean): void {
    event.stopPropagation();
    setMenuOpen(b);

    props.menuOpened && props.menuOpened(b);
  }

  useEffect(() => {
    if (menuRef.current) {
      menuRef.current.destroy();
    }

    if (menuDomRef.current !== null) {
      const clickMenu = new MDCMenu(menuDomRef.current!);
      menuRef.current = clickMenu;

      clickMenu.listen('MDCMenuSurface:closed', event => {
        menuOpened(event, false);
      });
    }

    return () => {
      if (menuRef.current) {
        menuRef.current.destroy();
        menuRef.current = undefined;
      }
    };
  }, [menuDomRef.current]);

  useEffect(() => {
    if (!menuRef.current) {
      return;
    }
    const menu = menuRef.current;

    if (menuOpen) {
      menu.open = true;
    } else {
      menu.open = false;
    }
  }, [menuRef.current, menuOpen]);

  return (
    <div className="options-menu mdc-menu-surface--anchor">
      <div
        className={classNames('options-menu__icon', {
          'options-menu__icon--active': menuOpen,
        })}
        onClick={event => {
          menuOpened(event, true);
        }}
      >
        more_vert
      </div>
      <div className="mdc-menu mdc-menu-surface" ref={menuDomRef}>
        <ul
          className="mdc-deprecated-list"
          role="menu"
          aria-hidden="true"
          aria-orientation="vertical"
        >
          {Object.keys(props.actions).map(label => {
            return (
              <li
                className="mdc-deprecated-list-item"
                role="menuitem"
                onClick={event => {
                  props.actions[label]();
                  menuOpened(event, false);
                }}
                key={label}
              >
                <span className="mdc-deprecated-list-item__ripple"></span>
                <span className="mdc-deprecated-list-item__text">
                  <FormattedMessage id={label} />
                </span>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default OptionsMenu;
