import { AnyAction, Reducer } from 'redux';

export interface Config {
  authServerUrl: string;
}

export interface GlobalUIState {
  snackBarMessage: string;
  snackBarMessageText: string;
  displaySnackBar: boolean;
  config: Config | undefined;
}

export const GLOBAL_UI_INITIAL_STATE = {
  snackBarMessage: '',
  snackBarMessageText: '',
  displaySnackBar: false,
  config: undefined,
};

const uiStateReducer: Reducer = (state: GlobalUIState, action: AnyAction) => {
  if (state === undefined) {
    return {
      ...GLOBAL_UI_INITIAL_STATE,
    };
  }

  switch (action.type) {
    case 'UPDATE_CONFIG':
      return {
        ...state,
        config: action.config,
      };
    case 'UPDATE_DISPLAY_SNACK_BAR':
      return {
        ...state,
        displaySnackBar: action.displaySnackBar,
      };
    case 'UPDATE_SNACK_BAR_MESSAGE':
      return {
        ...state,
        snackBarMessage: action.snackBarMessage,
      };
    case 'UPDATE_SNACK_BAR_MESSAGE_TEXT':
      return { ...state, snackBarMessageText: action.snackBarMessageText };
    default:
      return state;
  }
};

export default uiStateReducer;
