import React, { FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Drive } from '../../../redux/calculationResultReducer';
import { ForceInformation } from './RecommendationsColumn';
import './DriveItem.scss';
import {
  markDriveAction,
  setPerformanceClassesOpen,
} from '../../../redux/uiStateActions';
import { useDispatch } from 'react-redux';
import ResultItem, { ResultItemStyle } from '../../ResultItem';
import { MyCalcThunkDispatch } from '../../../redux/store';
import { AnyAction } from 'redux';

interface DriveItemProps {
  qaId?: string;
  selected?: boolean;
  drive?: Drive;
  forces?: ForceInformation;
  loading?: boolean;
  testValue?: string;
}

const DriveItem: FC<React.PropsWithChildren<DriveItemProps>> = (
  props: DriveItemProps,
) => {
  const { formatMessage } = useIntl();
  const dispatch: MyCalcThunkDispatch<AnyAction> = useDispatch();
  const noForces =
    !props.forces?.pullForceExists && !props.forces?.pushForceExists;

  return (
    <ResultItem
      onClick={() => {
        dispatch(setPerformanceClassesOpen(false));
        dispatch(markDriveAction(props.drive));
      }}
      qaId={props.qaId}
      loading={!!props.loading}
      selected={!!props.selected}
      additionalClass="drive-item"
      name={props.drive?.name || ''}
      amount={props.drive?.numberOfDrives || ''}
      byline={
        formatMessage({
          id: props.drive?.type || 'tree_Antrieb',
        }) +
        '   ' +
        formatMessage({
          id: props.drive?.voltage || 'fenster_edit_antriebsspannung',
        })
      }
      propertiesStyle={ResultItemStyle.WITHOUT_ICONS}
    >
      <div className="drive-item__properties">
        {!noForces && props.forces?.pushForceExists && (
          <div className="drive-item__property">
            <div className="drive-item__property-title">
              <FormattedMessage id="PUSH_FORCE_WITHOUT_UNIT" />{' '}
            </div>

            <div className="drive-item__property-value">
              {props.drive?.maximumPushPower} N
            </div>
          </div>
        )}
        {!noForces && props.forces?.pullForceExists && (
          <div className="drive-item__property">
            <div className="drive-item__property-title">
              <FormattedMessage id="PULL_FORCE_WITHOUT_UNIT" />{' '}
            </div>
            <div className="drive-item__property-value">
              {props.drive?.maximumPullPower} N
            </div>
          </div>
        )}
        <div className="drive-item__property">
          <div className="drive-item__property-title">
            {' '}
            <FormattedMessage id="STROKE" />
          </div>
          <div className="drive-item__property-value">
            {props.drive?.maximumStroke} mm
          </div>
        </div>
      </div>
      {props.drive?.lockingDriveInformation && (
        <LockingInformation
          lockingDriveType={
            props.drive?.lockingDriveInformation.lockingDriveType
          }
          lockingDrive={props.drive?.lockingDriveInformation?.articleDrive}
          lockingConsole={props.drive?.lockingDriveInformation?.articleConsole}
        />
      )}
    </ResultItem>
  );
};

interface LockingInformationProps {
  lockingDrive: string;
  lockingConsole: string;
  lockingDriveType: string;
}

const LockingInformation: FC<
  React.PropsWithChildren<LockingInformationProps>
> = (props: LockingInformationProps) => {
  return (
    <div className="drive-item__locking-items">
      <div className="drive-item__locking-drive">
        <span className="drive-item__locking-drive-text">
          <FormattedMessage id={props.lockingDriveType} />
          {`: ${props.lockingDrive}`}
        </span>
      </div>
      {props.lockingConsole && (
        <div className="drive-item__locking-drive-console">
          <span className="drive-item__locking-drive-text">
            <FormattedMessage id="CONSOLE" />
            {`: ${props.lockingConsole}`}
          </span>
        </div>
      )}
    </div>
  );
};

export default DriveItem;
