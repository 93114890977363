import React, { FC, ReactElement, useRef } from 'react';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import './Button.scss';
import { conditionalClass, getModifyClass } from './element_utils';

export enum ButtonType {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  FRAME = 'frame',
  ICON_ACTION = 'icon-action',
}

export enum ButtonPosition {
  CENTER = 'center',
  LEFT = 'left',
}

export enum ButtonSize {
  SMALL = 'small',
  LARGE = 'large',
  FULL_WIDTH = 'full-width',
}

export enum IconPosition {
  RIGHT = 'icon-right',
  LEFT = 'icon-left',
}

interface ButtonProps {
  label?: string;
  labelText?: string;
  action:
    | ((e: React.MouseEvent<HTMLButtonElement>) => void)
    | (() => void)
    | undefined;
  type: ButtonType;
  disabled?: boolean;
  position?: ButtonPosition;
  additionalClass?: string;
  iconName?: string;
  icon?: ReactElement;
  size?: ButtonSize;
  onBlur?: () => void;
  iconPosition?: IconPosition;
  qaId?: string;
}

const modifyClass = getModifyClass('button');

export const Button: FC<React.PropsWithChildren<ButtonProps>> = (
  props: ButtonProps,
) => {
  const buttonRef = useRef<HTMLButtonElement>(null);
  return (
    <button
      ref={buttonRef}
      data-qa={props.label}
      className={classNames(
        'button' +
          modifyClass(props.type) +
          modifyClass(props.position) +
          modifyClass(props.size) +
          modifyClass(props.iconPosition) +
          modifyClass('disabled', !!props.disabled) +
          conditionalClass(props.additionalClass),
      )}
      type="button"
      onClick={
        props.action
          ? e => {
              buttonRef.current && buttonRef.current.focus();
              props.action!(e);
            }
          : undefined
      }
      onBlur={props.onBlur}
    >
      <div className="button__label" data-qa={props.qaId}>
        {props.label ? <FormattedMessage id={props.label} /> : props.labelText}
      </div>
      {(props.iconName || props.icon) && (
        <div className="button__icon">{props.iconName || props.icon}</div>
      )}
    </button>
  );
};

export default Button;
