import React, { FC, useEffect } from 'react';
import './Pagination.scss';

interface PaginationProps {
  numberOfPages: number;
  searchString: string;
  page: number;
  setPage: (n: number) => void;
  indexOfFirstPageElement: number;
  setIndexOfFirstPageElement: (n: number) => void;
}

export const NUMBER_ELEMENTS_PER_PAGE = 20;

export const Pagination: FC<React.PropsWithChildren<PaginationProps>> = (
  props: PaginationProps,
) => {
  useEffect(() => {
    props.setIndexOfFirstPageElement(0);
    props.setPage(1);
  }, [props.searchString]);

  function pageUp(): void {
    props.setIndexOfFirstPageElement(
      props.indexOfFirstPageElement + NUMBER_ELEMENTS_PER_PAGE,
    );
    props.setPage(props.page + 1);
  }
  function pageDown(): void {
    if (props.indexOfFirstPageElement >= NUMBER_ELEMENTS_PER_PAGE) {
      props.setIndexOfFirstPageElement(
        props.indexOfFirstPageElement - NUMBER_ELEMENTS_PER_PAGE,
      );
      props.setPage(props.page - 1);
    }
  }

  function numberOfPages(): number {
    return Math.ceil(props.numberOfPages / NUMBER_ELEMENTS_PER_PAGE);
  }

  return (
    <div className={'pagination'}>
      {props.page !== 1 && (
        <span className={'pagination__backward'} onClick={() => pageDown()} />
      )}
      <span>
        Seite {props.page} von {numberOfPages()}
      </span>
      {props.page < numberOfPages() && (
        <span className={'pagination__forward'} onClick={() => pageUp()} />
      )}
    </div>
  );
};

export default Pagination;
