import React, { FC, ReactElement, useState } from 'react';
import Button, { ButtonType, IconPosition } from './Button';
import { FormattedMessage } from 'react-intl';
import './PopupMenu.scss';
import { useSelector } from 'react-redux';
import { IState } from '../redux/store';

interface PopupMenuProps {
  icon: ReactElement;
  label: string;
  items: PopupMenuItemProps[];
}

const PopupMenu: FC<React.PropsWithChildren<PopupMenuProps>> = props => {
  const [open, setOpen] = useState(false);
  const userIsAuthorized = useSelector<IState, boolean>(
    s => !!s.authentication.userIsAuthorized,
  );

  return (
    <div className="popup-menu" data-qa="area-button">
      <Button
        label="SECTIONS_MENU_GROUP_HEADER"
        action={() => {
          setOpen(true);
        }}
        type={ButtonType.ICON_ACTION}
        icon={props.icon}
        onBlur={() => setTimeout(() => setOpen(false), 200)}
        iconPosition={IconPosition.LEFT}
      />
      {open && (
        <div className="popup-menu__menu-card" onClick={() => setOpen(false)}>
          {!userIsAuthorized && (
            <div className="popup-menu__empty-state">
              <div className="popup-menu__empty-state-icon">error_outline</div>
              <FormattedMessage id="NO_EXISTING_ACCESS_RIGHTS" />
              <a
                href="mailto:mycalc.support@dh-partner.com"
                className="popup-menu__empty-state-action"
              >
                <FormattedMessage id="CONTACT_SUPPORT" />
              </a>
            </div>
          )}
          {userIsAuthorized &&
            props.items.map(i => <PopupMenuItem {...i} key={i.label} />)}
        </div>
      )}
    </div>
  );
};

interface PopupMenuItemProps {
  label: string;
  icon: ReactElement;
  action: () => void;
}

const PopupMenuItem: FC<
  React.PropsWithChildren<PopupMenuItemProps>
> = props => {
  return (
    <div
      className="popup-menu__item"
      onClick={props.action}
      data-qa={props.label}
    >
      <div className="popup-menu__item-icon">{props.icon}</div>
      <div className="popup-menu__item-label">
        <FormattedMessage id={props.label} />
      </div>
    </div>
  );
};

export default PopupMenu;
