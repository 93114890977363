import React, { FC, useState } from 'react';
import FormLayout from '../../elements/FormLayout';
import InputField from '../../elements/InputField';
import './DriveGroupsDataView.scss';
import TableHeader from '../elements/TableHeader';
import Dialog from '../../components/Dialog';
import TableRow from '../elements/TableRow';
import Table from '../components/Table';
import SearchField from '../../elements/SearchField';
import FieldLabel from '../../elements/FieldLabel';

const DrivesDialog: FC<React.PropsWithChildren<unknown>> = () => {
  return (
    <FormLayout additionalClass="drives-group-data-dialog">
      <InputField
        label="Name *"
        placeholder="Name der Antriebsgruppe"
        value={''}
        additionalClass="drives-group-data-dialog__name"
        onChange={() => 'prevent lint warning'}
      />
      <InputField
        label="Testphase *"
        placeholder="Name der Testphase"
        value={''}
        additionalClass="drives-group-data-dialog__test-phase"
        onChange={() => 'prevent lint warning'}
      />

      <div className="drives-group-data-dialog__select-group admin__assign-select-group">
        <div className="admin__assign-select-group-title">
          Blendrahmen zuordnen
        </div>
        <div className="admin__assign-select">
          <FieldLabel>Alle Blendrahmen</FieldLabel>
          <select multiple>
            <option value="admin/roof/test-phases/further-information/series">
              AWS 65
            </option>
            <option value="admin/roof/test-phases/further-information/series">
              AWS RL
            </option>
            <option value="admin/roof/test-phases/further-information/series">
              AWS 70BS.HI
            </option>
            <option value="admin/roof/test-phases/further-information/series">
              AWS 70RL.HI
            </option>
            <option value="admin/roof/test-phases/further-information/series">
              AWS 75.SI
            </option>
            <option value="admin/roof/test-phases/further-information/series">
              AWS 75BS.HI
            </option>
            <option value="admin/roof/test-phases/further-information/series">
              AWS 70.HI
            </option>
            <option value="admin/roof/test-phases/further-information/series">
              AWS 60
            </option>
          </select>
        </div>
        <div className="admin__assign-select-group-buttons">
          <button>chevron_right</button>
          <button>chevron_left</button>
        </div>
        <div className="admin__assign-select">
          <FieldLabel>Blendrahmen</FieldLabel>
          <select multiple>
            <option value="admin/roof/test-phases/further-information/series">
              AWS 65
            </option>
            <option value="admin/roof/test-phases/further-information/series">
              AWS RL
            </option>
            <option value="admin/roof/test-phases/further-information/series">
              AWS 70BS.HI
            </option>
            <option value="admin/roof/test-phases/further-information/series">
              AWS 70RL.HI
            </option>
            <option value="admin/roof/test-phases/further-information/series">
              AWS 75.SI
            </option>
            <option value="admin/roof/test-phases/further-information/series">
              AWS 75BS.HI
            </option>
            <option value="admin/roof/test-phases/further-information/series">
              AWS 70.HI
            </option>
            <option value="admin/roof/test-phases/further-information/series">
              AWS 60
            </option>
          </select>
        </div>
      </div>
    </FormLayout>
  );
};

const DrivesData: FC<React.PropsWithChildren<unknown>> = () => {
  const [dialogIsShown, setDialogIsShown] = useState(false);

  function triggerCreationMode(): void {
    setDialogIsShown(true);
  }
  return (
    <>
      <Dialog
        setDialogIsShown={setDialogIsShown}
        dialogIsShown={dialogIsShown}
        headingText="Antriebsgruppe bearbeiten"
        componentClass="full-view-dialog"
        key={dialogIsShown.toString()}
      >
        <DrivesDialog />
      </Dialog>
      <div className="sub-header">
        <div className="sub-header__title"> Antriebsgruppen</div>

        <SearchField
          setSearchString={() => 'prevent lint warning'}
          searchString=""
          placeholderText="Antriebsgruppe suchen..."
          small={true}
        />
        <button
          className="sub-header__button sub-header__button--add"
          onClick={() => triggerCreationMode()}
        >
          Neuer Eintrag
        </button>
      </div>
      <div className="cv-data">
        <Table>
          <TableHeader>
            <th>Name</th> <th>Prüfphase</th>
            <th>Antriebe</th>
            <th>Löschen</th>
            <th>Aktion</th>
          </TableHeader>
          <TableRow>
            <td>Dach_Wicona_Hearoal_Raico</td>
            <td>D2</td>
            <td>85, ....</td>
            <td>
              <button
                onClick={() => {
                  setDialogIsShown(true);
                }}
              >
                löschen
              </button>
            </td>{' '}
            <td>
              <button
                onClick={() => {
                  setDialogIsShown(true);
                }}
              >
                Bearbeiten
              </button>
            </td>
          </TableRow>
        </Table>
      </div>
    </>
  );
};
export default DrivesData;
