import { AnyAction, Store } from 'redux';
import React, { useEffect } from 'react';
import PrivacyPolicyModal from '../privacy-policy/PrivacyPolicy';
import { showDialog } from '../redux/uiStateActions';

export function useShowPrivacyPolicyEffect(
  store: Store<any, AnyAction> | undefined,
) {
  useEffect(() => {
    const showPrivacyPolicy = location.hash === '#privacy-policy';
    if (store && showPrivacyPolicy) {
      store.dispatch(showDialog(<PrivacyPolicyModal />));
    }
  }, [store]);
}
