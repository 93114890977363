import React, { FC, ReactNode, useEffect } from 'react';
import EditableBox from './EditableBox';
import NumberField from './NumberField';
import './NumberBox.scss';
import { useDispatch, useSelector } from 'react-redux';
import { IState, MyCalcThunkDispatch } from '../../../redux/store';
import { changeCalculationParameter } from '../../../redux/parametersActions';
import { ParameterBoxRange } from '../ParametersColumn';
import { ValueKey } from '../../../redux/valueKey';
import { AnyAction } from 'redux';
import { Help } from '../ParameterSection';

interface NumberBoxProps {
  heading: string;
  valueKey: ValueKey;
  range: (state: IState) => ParameterBoxRange;
  unit?: string;
  isSingleEdit: boolean;
  helpContent: Help;
  dontResetWhenOutOfRange?: boolean;
  decimal?: boolean;
  children?: ReactNode;
  staticValue?: number | undefined;
}

export const NumberBox: FC<React.PropsWithChildren<NumberBoxProps>> = (
  props: NumberBoxProps,
) => {
  const state = useSelector((store: IState) => store);
  const dispatch: MyCalcThunkDispatch<AnyAction> = useDispatch();

  function getNumberFieldKey(): string {
    return `${props.range && props.range(state).toString()}`;
  }

  return (
    <EditableBox {...props} range={props.range && props.range(state)}>
      <div data-testid="number-box">
        <div className="number-box__input">
          <NumberField
            key={getNumberFieldKey()}
            value={
              props.staticValue || state.parameters[props.valueKey].toNumber()
            }
            name={props.heading}
            range={props.range && props.range(state)}
            onChange={(newValue: string) => {
              dispatch(changeCalculationParameter(props.valueKey, newValue));
            }}
            dontResetWhenOutOfRange={props.dontResetWhenOutOfRange}
            valueKey={props.valueKey}
            decimal={props.decimal}
            unit={props.unit}
            small={
              props.valueKey === ValueKey.VALUEKEY_OPENING_ANGLE ||
              props.valueKey === ValueKey.VALUEKEY_OPENING_STROKE
            }
            disabledByUIState={!!props.staticValue}
          />
        </div>
      </div>
      {props.children && props.children}
    </EditableBox>
  );
};

export default NumberBox;
