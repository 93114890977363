import React, { FC, ReactNode } from 'react';
import './EmptyStateContainer.scss';
import classNames from 'classnames';
import { getModifyClass } from './element_utils';

interface EmptyStateContainerProps {
  children: ReactNode;
  size: string;
}

const modifyClass = getModifyClass('empty-state-container');

const EmptyStateContainer: FC<
  React.PropsWithChildren<EmptyStateContainerProps>
> = (props: EmptyStateContainerProps) => {
  return (
    <div
      className={classNames('empty-state-container' + modifyClass(props.size))}
    >
      {props.children}
    </div>
  );
};

export default EmptyStateContainer;
