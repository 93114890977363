import React, { FC } from 'react';
import { DialogFE } from '../components/Dialog';

import { MessageDescriptor, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import {
  deleteBuildingArea,
  deleteProject,
  deleteWindow,
} from '../redux/projectsActions';
import {
  BuildingArea,
  ShallowProject,
  ShallowWindow,
} from '../redux/projectsReducer';
import './ConfirmationDialog.scss';
import { deleteTemplateOnServer, showDialog } from '../redux/uiStateActions';
import {
  WindowTemplate,
  WindowTemplateWithOptionalId,
} from '../redux/uiStateReducer';
import { AnyAction } from 'redux';
import { MyCalcThunkDispatch } from '../redux/store';

export enum TYPE_OF_CONFIRMATION {
  CONFIRMATION_WINDOW = 'window',
  CONFIRMATION_BUILDING_AREA = 'buildingArea',
  CONFIRMATION_PROJECT = 'project',
  CONFIRMATION_TEMPLATE = 'template',
}

interface ConfirmationDialogProps {
  typeOfConfirmation: TYPE_OF_CONFIRMATION;

  deletedObject?:
    | ShallowWindow
    | BuildingArea
    | ShallowProject
    | WindowTemplateWithOptionalId;
}
const TEXTS: {
  [key: string]: [string, string];
} = {
  [TYPE_OF_CONFIRMATION.CONFIRMATION_BUILDING_AREA]: [
    'DELETE_BUILDING_AREA_HEADING',
    'DELETE_BUILDING_AREA_TEXT',
  ],
  [TYPE_OF_CONFIRMATION.CONFIRMATION_WINDOW]: [
    'DELETE_WINDOW_HEADING',
    'DELETE_WINDOW_TEXT',
  ],
  [TYPE_OF_CONFIRMATION.CONFIRMATION_PROJECT]: [
    'DELETE_PROJECT_HEADING',
    'DELETE_PROJECT_TEXT',
  ],
  [TYPE_OF_CONFIRMATION.CONFIRMATION_TEMPLATE]: [
    'DELETE_TEMPLATE_HEADING',
    'DELETE_TEMPLATE_TEXT',
  ],
};

export const ConfirmationDialog: FC<
  React.PropsWithChildren<ConfirmationDialogProps>
> = (props: ConfirmationDialogProps) => {
  const dispatch: MyCalcThunkDispatch<AnyAction> = useDispatch();
  const { formatMessage } = useIntl();

  function getSpecificDeleteMessage(
    formatMessage: (
      descriptor: MessageDescriptor,
      values: Record<string, string | undefined>,
    ) => string,
  ): string {
    return formatMessage(
      { id: TEXTS[props.typeOfConfirmation][1] },
      { deletedObject: props.deletedObject?.name },
    );
  }

  function deleteAction(typeOfConfirmation: TYPE_OF_CONFIRMATION): void {
    if (
      typeOfConfirmation === TYPE_OF_CONFIRMATION.CONFIRMATION_BUILDING_AREA
    ) {
      dispatch(deleteBuildingArea(props.deletedObject!.id as number));
    }
    if (typeOfConfirmation === TYPE_OF_CONFIRMATION.CONFIRMATION_WINDOW) {
      dispatch(deleteWindow(props.deletedObject as ShallowWindow));
    }
    if (typeOfConfirmation === TYPE_OF_CONFIRMATION.CONFIRMATION_PROJECT) {
      dispatch(deleteProject(props.deletedObject));
    }
    if (typeOfConfirmation === TYPE_OF_CONFIRMATION.CONFIRMATION_TEMPLATE) {
      dispatch(deleteTemplateOnServer(props.deletedObject as WindowTemplate));
    }
  }

  return (
    <DialogFE
      heading={TEXTS[props.typeOfConfirmation][0]}
      componentClass="confirmation-dialog dialog--confirmation"
      isConfirmation={true}
      footerProps={{
        cancelAction: true,
        saveAction: () => {
          deleteAction(props.typeOfConfirmation);
          dispatch(showDialog(undefined));
        },
        primaryActionLabel: 'DELETE_LABEL',
        isConfirmation: true,
      }}
    >
      <div>
        {props.typeOfConfirmation && (
          <>
            <span>{getSpecificDeleteMessage(formatMessage)}</span>
          </>
        )}
      </div>
    </DialogFE>
  );
};

export default ConfirmationDialog;
