import { useSelector } from 'react-redux';
import { IState } from '../redux/store';
import { Window } from '../redux/projectsReducer';
import flatMap from 'array.prototype.flatmap';
import {
  CompanyWithOptionalId,
  Series,
  SeriesENMaxStrokeFacade,
  SeriesENMaxStrokeRoof,
  TestPhaseApplicationWithOptionalId,
  TestPhaseMountingTypeWithOptionalId,
  TestPhaseSeriesOpeningDirectionWithOptionalId,
  TestPhaseSeriesWithOptionalId,
  TestPhaseSystemWithOptionalId,
  TestPhaseWithOptionalId,
} from '../redux/admin/adminFacadeReducer';
import { AdminState } from '../redux/admin/adminStore';
import {
  TestPhaseApplicationRoofWithOptionalId,
  TestPhaseRoofWithOptionalId,
  TestPhaseSeriesRoofWithOptionalId,
  TestPhaseSystemRoofWithOptionalId,
  WindDeflectorData,
} from '../redux/admin/adminRoofReducer';
import { RangeOfApplication } from '../redux/constants';
import { Drive } from '../redux/calculationResultReducer';
import { Edit } from '../types';
import { ConsoleSet } from '../redux/staticDataReducer';

flatMap.shim();

export function getSelectedWindow(s: IState): Window | undefined {
  return s.ui.selectedWindow;
}

export function getMarkedDrive(s: IState): Drive | undefined {
  return s.ui.markedDriveName !== undefined
    ? [
        ...s.calculationResult.suitableDrives,
        ...s.calculationResult.partlyQualifiedDrives,
      ].find(d => d.name === s.ui.markedDriveName)
    : undefined;
}

export function useSelectedWindow(): Window | undefined {
  return useSelector<IState, Window | undefined>(s => {
    return getSelectedWindow(s);
  });
}

export function useSelectedConsoleSets(): ConsoleSet[] | undefined {
  return useSelector<IState, ConsoleSet[] | undefined>(s => {
    return getSelectedWindow(s)?.consoleSets || s.ui.selectedConsoleSets;
  });
}

export function useSelectedDrive(): Drive | undefined {
  return useSelector<IState, Drive | undefined>((s: IState) => {
    return (
      (getSelectedWindow(s)?.nrwg && getSelectedWindow(s)?.selectedDrive) ||
      getMarkedDrive(s)
    );
  });
}

export function useEditedTestPhase(): TestPhaseWithOptionalId {
  return useSelector<AdminState, TestPhaseWithOptionalId>(
    state => state.adminFacade.editedTestPhase!,
  );
}

export function useEditedTestPhaseSystem(): TestPhaseSystemWithOptionalId {
  return useSelector<AdminState, TestPhaseSystemWithOptionalId>(
    state => state.adminFacade.editedTestPhaseSystem!,
  );
}

export function useEditedTestPhaseSeries(): TestPhaseSeriesWithOptionalId {
  return useSelector<AdminState, TestPhaseSeriesWithOptionalId>(
    state => state.adminFacade.editedTestPhaseSeries!,
  );
}

export function useEditedTestPhaseOpeningDirection(): TestPhaseSeriesOpeningDirectionWithOptionalId {
  return useSelector<AdminState, TestPhaseSeriesOpeningDirectionWithOptionalId>(
    state => state.adminFacade.editedTestPhaseSeriesOpeningDirection!,
  );
}

export function useEditedTestPhaseApplication(): TestPhaseApplicationWithOptionalId {
  return useSelector<AdminState, TestPhaseApplicationWithOptionalId>(
    state => state.adminFacade.editedTestPhaseApplication!,
  );
}

export function useEditedTestPhaseMountingType(): TestPhaseMountingTypeWithOptionalId {
  return useSelector<AdminState, TestPhaseMountingTypeWithOptionalId>(
    state => state.adminFacade.editedTestPhaseMountingType!,
  );
}

export function useEditedTestPhaseRoof(): TestPhaseRoofWithOptionalId {
  return useSelector<AdminState, TestPhaseRoofWithOptionalId>(
    state => state.adminRoof.editedTestPhase!,
  );
}

export function useEditedTestPhaseSystemRoof(): TestPhaseSystemRoofWithOptionalId {
  return useSelector<AdminState, TestPhaseSystemRoofWithOptionalId>(
    state => state.adminRoof.editedTestPhaseSystem!,
  );
}

export function useEditedTestPhaseSeriesRoof(): TestPhaseSeriesRoofWithOptionalId {
  return useSelector<AdminState, TestPhaseSeriesRoofWithOptionalId>(
    state => state.adminRoof.editedTestPhaseSeries!,
  );
}

export function useEditedTestApplicationRoof(): TestPhaseApplicationRoofWithOptionalId {
  return useSelector<AdminState, TestPhaseApplicationRoofWithOptionalId>(
    state => state.adminRoof.editedTestPhaseApplication!,
  );
}

export function useEditedCompany(): CompanyWithOptionalId | undefined {
  return useSelector<AdminState, CompanyWithOptionalId | undefined>(
    state => state.adminFacade.editedCompany,
  );
}

export function useSeriesENMaxStrokesFacade():
  | SeriesENMaxStrokeFacade[]
  | undefined {
  return useSelector<AdminState, SeriesENMaxStrokeFacade[]>(
    state => state.adminFacade.seriesENMaxStrokesFacade,
  );
}

export function useSeriesENMaxStrokesRoof():
  | SeriesENMaxStrokeRoof[]
  | undefined {
  return useSelector<AdminState, SeriesENMaxStrokeRoof[]>(
    state => state.adminRoof.seriesENMaxStrokesRoof,
  );
}

export function useEditedWindDeflector(): Edit<WindDeflectorData> | undefined {
  return useSelector<AdminState, WindDeflectorData>(
    state => state.adminRoof.editedWindDeflector!,
  );
}

export function useSeries(rangeOfApplication: RangeOfApplication): Series[] {
  return useSelector<AdminState, Series[]>(state =>
    rangeOfApplication === RangeOfApplication.FACADE
      ? state.adminFacade.series
      : state.adminRoof.series,
  );
}
