import React, { FC, useState } from 'react';
import './NumberBox.scss';
import { useDispatch, useSelector } from 'react-redux';
import { IState, MyCalcThunkDispatch } from '../../../redux/store';
import { changeCalculationParameter } from '../../../redux/parametersActions';
import { Parameters } from '../ParameterValue';
import { useIntl } from 'react-intl';
import TextArea from '../../../elements/TextArea';
import './CorrectionFactorDescription.scss';
import { ValueKey } from '../../../redux/valueKey';
import { AnyAction } from 'redux';

export const CorrectionFactorDescriptionField: FC<
  React.PropsWithChildren<unknown>
> = () => {
  const state = useSelector((store: IState) => store);
  const dispatch: MyCalcThunkDispatch<AnyAction> = useDispatch();
  const [description, setDescription] = useState(
    state.parameters.correctionFactorDescription.value,
  );
  const { formatMessage } = useIntl();

  function saveDescription(description: string): void {
    dispatch(
      changeCalculationParameter(
        ValueKey.CORRECTION_FACTOR_DESCRIPTION,
        description,
      ),
    );
  }

  function onChange(descriptionText: string): void {
    setDescription(
      descriptionText === Parameters.NO_SELECTION ? '' : descriptionText,
    );
  }

  return (
    <>
      <TextArea
        label={formatMessage({ id: 'REASON_OF_CORRECTION' })}
        placeholder={formatMessage({
          id: 'PLACEHOLDER_CORRECTION_DESCRIPTION',
        })}
        value={description === Parameters.NO_SELECTION ? '' : description!}
        id={'REASON_OF_CORRECTION'}
        additionalClass="correction-factor-description"
        onChange={onChange}
        saveValue={saveDescription}
        size={3}
        inputMax={120}
        inputCount={true}
      />
    </>
  );
};

export default CorrectionFactorDescriptionField;
