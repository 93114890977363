import React, { FC } from 'react';
import classNames from 'classnames';
import './AdminListIcon.scss';

interface AdminListIconProps {
  checked: boolean;
}

const AdminListIcon: FC<
  React.PropsWithChildren<AdminListIconProps>
> = props => {
  return (
    <span
      className={classNames('admin__list-icon', {
        'admin__list-icon--unchecked': !props.checked,
        'admin__list-icon--checked': props.checked,
      })}
    >
      {props.checked ? 'check_circle' : 'highlight_off'}
    </span>
  );
};

export default AdminListIcon;
