export function calcAngleBasic(
  windowWidth: number,
  windowHeight: number,
  distanceToHinge: number,
  stroke: number,
  isSideHungVent: boolean,
  sideOrCrossBeamIstallation: boolean,
): number {
  return round(
    calcAngle(
      installationPosition(
        windowWidth,
        windowHeight,
        distanceToHinge,
        isSideHungVent,
        sideOrCrossBeamIstallation,
      ),
      stroke,
    ),
    0,
  );
}

export function calcStrokeBasic(
  windowWidth: number,
  windowHeight: number,
  distanceToHinge: number,
  angle: number,
  isSideHungVent: boolean,
  sideOrCrossBeamIstallation: boolean,
): number {
  return round(
    Math.sin(angleToRadian(angle) / 2) *
      installationPosition(
        windowWidth,
        windowHeight,
        distanceToHinge,
        isSideHungVent,
        sideOrCrossBeamIstallation,
      ) *
      2,
    0,
  );
}

function installationPosition(
  windowWidth: number,
  windowHeight: number,
  distanceToHinge: number,
  isSideHungVent: boolean,
  sideOrCrossBeamIstallation: boolean,
): number {
  const frameLength = isSideHungVent ? windowWidth : windowHeight;

  return sideOrCrossBeamIstallation ? distanceToHinge : frameLength;
}

export function round(value: number, precision: number): number {
  const pow = Math.pow(precision, precision);
  return Math.round(value * pow) / pow;
}

function calcAngle(laenge: number, stroke: number): number {
  const radians = 2 * Math.asin(stroke / 2 / laenge);
  return radianToAngle(radians);
}

function radianToAngle(radian: number): number {
  return radian * (180 / Math.PI);
}

function angleToRadian(angle: number): number {
  return angle * (Math.PI / 180);
}
