import React, { FC, useState } from 'react';
import SearchField from '../../elements/SearchField';
import Table from '../components/Table';
import TableHeader from '../elements/TableHeader';
import TableRow from '../elements/TableRow';
import {
  containingInSearchString,
  seriesNameAndOpeningTypeFits,
} from '../general/helpers';
import { EXISTING_DRIVE_SERIES_FACADE, UiConstants } from '../constants';
import Pagination from '../components/Pagination';
import { useDispatch, useSelector } from 'react-redux';
import { SeriesENMaxStrokeFacade } from '../../redux/admin/adminFacadeReducer';
import { AdminState, AdminThunkDispatch } from '../../redux/admin/adminStore';
import _ from 'lodash';
import './MaxStrokesDataView.scss';
import {
  changeMaxStrokesFacade,
  updateEditedSeriesENMaxStrokesFacade,
} from '../../redux/admin/adminFacadeActions';
import { InputFieldNumber } from '../../elements/InputField';
import { AnyAction } from 'redux';
import { DriveSeries } from '../../redux/constants';

const FacadeMaxStrokesDataView: FC<React.PropsWithChildren<unknown>> = () => {
  const dispatch: AdminThunkDispatch<AnyAction> = useDispatch();
  const editedSeriesEnMaxStrokesFacade = useSelector<
    AdminState,
    SeriesENMaxStrokeFacade[] | undefined
  >(state => state.adminFacade.editedSeriesENMaxStrokesFacade);
  const allDriveSeries = useSelector<AdminState, DriveSeries[]>(
    s => s.adminFacade.driveSeries,
  );

  const [indexOfFirstPageElement, setIndexOfFirstPageElement] = useState(0);
  const [page, setPage] = useState(1);
  const [searchString, setSearchString] = useState('');

  function searchByMultipleSubstrings(): { driveSeries: string }[] {
    const splittedSearchString = searchString.split(/\s+/);

    return EXISTING_DRIVE_SERIES_FACADE.filter(driveSeries =>
      containingInSearchString(driveSeries, splittedSearchString, [
        driveSeries.driveSeries,
      ]),
    );
  }

  function getCurrentTableContent(): { driveSeries: string }[] {
    return searchByMultipleSubstrings().slice(
      indexOfFirstPageElement,
      indexOfFirstPageElement + 20,
    );
  }

  function getMaxStrokeValue(
    art: string,
    isLockingDrive: boolean,
    openingType: string,
  ): number | null | undefined {
    if (editedSeriesEnMaxStrokesFacade) {
      return editedSeriesEnMaxStrokesFacade.filter(
        stroke =>
          seriesNameAndOpeningTypeFits(stroke, openingType, art) &&
          stroke.withLockingDrive === isLockingDrive,
      )[0].maxOpenStroke!;
    }
  }

  function setMaxStrokeValue(
    art: string,
    isLockingDrive: boolean,
    openingType: string,
    newValue: number | null,
  ): void {
    if (editedSeriesEnMaxStrokesFacade) {
      const newState: SeriesENMaxStrokeFacade[] = _.cloneDeep(
        editedSeriesEnMaxStrokesFacade,
      );
      const indexToUpdate = _.findIndex(
        newState,
        stroke =>
          seriesNameAndOpeningTypeFits(stroke, openingType, art) &&
          stroke.withLockingDrive === isLockingDrive,
      );
      newState[indexToUpdate].maxOpenStroke = newValue!;
      dispatch(updateEditedSeriesENMaxStrokesFacade(newState));
    }
  }

  return (
    <>
      <div className="sub-header">
        <div className="sub-header__title"> Antriebsarten</div>

        <SearchField
          setSearchString={setSearchString}
          searchString={searchString}
          placeholderText="Antriebsart suchen..."
          small={true}
        />
        <button
          className="sub-header__button"
          onClick={() =>
            dispatch(changeMaxStrokesFacade(editedSeriesEnMaxStrokesFacade!))
          }
        >
          {UiConstants.SAVE}
        </button>
      </div>

      <div className="max-stroke-data">
        <Table subNavigation={true}>
          <TableHeader>
            <th>Antriebsart</th>
            <th>Kipp</th>
            <th>Klapp/Senkklapp</th>
            <th>Dreh</th>
            <th>Kipp mit Verriegelung</th>
            <th>Klapp/Senkklapp mit Verriegelung</th>
            <th>Dreh mit Verriegelung</th>
          </TableHeader>
          <tbody>
            {getCurrentTableContent().map((driveSeries, index) => (
              <TableRow key={index}>
                <td>{driveSeries.driveSeries.toUpperCase()}</td>
                <td>
                  <InputFieldNumber
                    value={getMaxStrokeValue(
                      driveSeries.driveSeries,
                      false,
                      'FENSTER_OEFFNUNGSART_KIPP',
                    )}
                    onChange={newValue =>
                      setMaxStrokeValue(
                        driveSeries.driveSeries,
                        false,
                        'FENSTER_OEFFNUNGSART_KIPP',
                        newValue,
                      )
                    }
                  />
                </td>
                <td>
                  <InputFieldNumber
                    value={getMaxStrokeValue(
                      driveSeries.driveSeries,
                      false,
                      'FENSTER_OEFFNUNGSART_KLAPP',
                    )}
                    onChange={newValue =>
                      setMaxStrokeValue(
                        driveSeries.driveSeries,
                        false,
                        'FENSTER_OEFFNUNGSART_KLAPP',
                        newValue,
                      )
                    }
                  />
                </td>
                <td>
                  <InputFieldNumber
                    value={getMaxStrokeValue(
                      driveSeries.driveSeries,
                      false,
                      'FENSTER_OEFFNUNGSART_DREH',
                    )}
                    onChange={newValue =>
                      setMaxStrokeValue(
                        driveSeries.driveSeries,
                        false,
                        'FENSTER_OEFFNUNGSART_DREH',
                        newValue,
                      )
                    }
                  />
                </td>
                <td>
                  <InputFieldNumber
                    value={getMaxStrokeValue(
                      driveSeries.driveSeries,
                      true,
                      'FENSTER_OEFFNUNGSART_KIPP',
                    )}
                    onChange={newValue =>
                      setMaxStrokeValue(
                        driveSeries.driveSeries,
                        true,
                        'FENSTER_OEFFNUNGSART_KIPP',
                        newValue,
                      )
                    }
                  />
                </td>
                <td>
                  <InputFieldNumber
                    value={getMaxStrokeValue(
                      driveSeries.driveSeries,
                      true,
                      'FENSTER_OEFFNUNGSART_KLAPP',
                    )}
                    onChange={newValue =>
                      setMaxStrokeValue(
                        driveSeries.driveSeries,
                        true,
                        'FENSTER_OEFFNUNGSART_KLAPP',
                        newValue,
                      )
                    }
                  />
                </td>
                <td>
                  <InputFieldNumber
                    value={getMaxStrokeValue(
                      driveSeries.driveSeries,
                      true,
                      'FENSTER_OEFFNUNGSART_DREH',
                    )}
                    onChange={newValue =>
                      setMaxStrokeValue(
                        driveSeries.driveSeries,
                        true,
                        'FENSTER_OEFFNUNGSART_DREH',
                        newValue,
                      )
                    }
                  />
                </td>
              </TableRow>
            ))}
          </tbody>
        </Table>
      </div>
      <Pagination
        searchString={searchString}
        numberOfPages={searchByMultipleSubstrings().length}
        page={page}
        setPage={setPage}
        indexOfFirstPageElement={indexOfFirstPageElement}
        setIndexOfFirstPageElement={setIndexOfFirstPageElement}
      />
    </>
  );
};

export default FacadeMaxStrokesDataView;
