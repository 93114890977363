import React, { FC, MouseEventHandler, ReactElement } from 'react';
import '../../HintDialog.scss';
import { ReactComponent as IconToggle } from './toggle_off.svg';
import { ReactComponent as IconConfirmed } from './confimed.svg';
import { ReactComponent as IconConfirmedDisabled } from './confimedDisabled.svg';
import classNames from 'classnames';
import { DialogFE } from '../../Dialog';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import {
  showDialog,
  updateHintAcceptedStatus,
} from '../../../redux/uiStateActions';
import { IState, MyCalcThunkDispatch } from '../../../redux/store';
import { AnyAction } from 'redux';
import { Hint } from '../../../redux/calculationResultReducer';

interface ErrorItemProps {
  hint?: Hint;
  message: string;
  labels: [string, string];
}

export const ErrorItem: FC<React.PropsWithChildren<ErrorItemProps>> = (
  props: ErrorItemProps,
) => {
  const dispatch: MyCalcThunkDispatch<AnyAction> = useDispatch();
  const acceptedHints = useSelector((state: IState) => state.ui.acceptedHints);
  const windowLocked = useSelector(
    (state: IState) => state.ui.selectedWindow?.locked,
  );

  function hint(): (Hint & { accepted: boolean }) | undefined {
    if (!props.hint) {
      return;
    }

    const acceptedHint = acceptedHints.find(
      acceptedHint => acceptedHint.type === props.hint?.type,
    );

    if (acceptedHint) {
      return { ...acceptedHint, accepted: true };
    }

    return props.hint;
  }

  function handleAcceptHintClick(e: React.MouseEvent<HTMLDivElement>) {
    if (windowLocked) {
      return;
    }

    if (hint() && hint()!.accepted) {
      dispatch(updateHintAcceptedStatus(hint()!));
    } else {
      dispatch(
        showDialog(
          <DialogFE
            componentClass="hint-dialog"
            heading="HEADING_HINT_CONFIRMATION_DIALOG"
            footerProps={{
              cancelAction: true,
              saveAction: () => {
                dispatch(updateHintAcceptedStatus(hint()!));
                dispatch(showDialog(undefined));
              },
              primaryActionLabel: 'HINT_CONFIRMATION',
            }}
          >
            <HintConfirmationDialogContent type={props.message} />
          </DialogFE>,
        ),
      );
      e.stopPropagation();
    }
  }

  return !(hint() && hint()!.acceptable) ? (
    <div className="drive-details-column__errors-items">{props.message}</div>
  ) : (
    <>
      <div
        className={classNames('drive-details-column__errors-items', {
          'drive-details-column__errors-items--confirmed': hint()!.accepted,
        })}
      >
        <div className="drive-details-column__error-message">
          {props.message}
        </div>
        <div
          className={classNames('drive-details-column__error-confirmation', {
            'drive-details-column__error-confirmation--confirmed':
              hint()!.accepted,
          })}
          onClick={handleAcceptHintClick}
        >
          <div
            className={classNames(
              'drive-details-column__error-confirmation-switch',
              {
                'drive-details-column__error-confirmation-switch--disabled':
                  windowLocked,
              },
            )}
          >
            {hint()!.accepted ? <IconConfirmed /> : <IconToggle />}
          </div>
          <div
            className={classNames(
              'drive-details-column__error-confirmation-text',
              {
                'drive-details-column__error-confirmation-text--disabled':
                  windowLocked,
              },
            )}
          >
            {hint()!.accepted ? (
              <FormattedMessage id={props.labels[0]} />
            ) : (
              <FormattedMessage id={props.labels[1]} />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

interface HintConfirmationDialogContentProps {
  type: string;
}

function HintConfirmationDialogContent(
  props: HintConfirmationDialogContentProps,
): ReactElement {
  return (
    <div className="drive-details-column__error-confirmation-dialog-content">
      <div className="drive-details-column__error-confirmation-dialog-content-top">
        <FormattedMessage id={props.type} />
        <br />
        <br />
        <FormattedMessage id="CONFIRMATION_HINT_DIALOG_CONTENT_CONFIRMATION_MESSAGE" />
      </div>
      <div className="drive-details-column__error-confirmation-dialog-content-bottom">
        <IconConfirmedDisabled />

        <FormattedMessage id="CONFIRMATION_HINT_DIALOG_SPEC_WARN" />
      </div>
    </div>
  );
}

export default ErrorItem;
