import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import '../ManagerPage.scss';
import './TemplatesManager.scss';
import { useDispatch, useSelector } from 'react-redux';
import { IState, MyCalcThunkDispatch } from '../../redux/store';
import { WindowTemplate } from '../../redux/uiStateReducer';
import TemplateDialog from '../ProjectNavigation/TemplateDialog';
import { ReactComponent as IconTemplate } from '../Templates/IconTemplate.svg';
import EmptyStateContainer from '../../elements/EmptyStateContainer';
import ConfirmationDialog, {
  TYPE_OF_CONFIRMATION,
} from '../ConfirmationDialog';
import ManagerPage, { cell, label, ManagerPageTable } from '../ManagerPage';
import { showDialog } from '../../redux/uiStateActions';
import { AnyAction } from 'redux';

const TemplatesManager: FC<React.PropsWithChildren<unknown>> = () => {
  const templates = useSelector<IState, WindowTemplate[]>(
    state => state.ui.templatesForCurrentUser,
  );
  const dispatch: MyCalcThunkDispatch<AnyAction> = useDispatch();

  return (
    <ManagerPage headline="TEMPLATES" additionalClass="templates-manager">
      <>
        {templates.length > 0 ? (
          <ManagerPageTable
            hasMore={false}
            loadMore={() => false}
            header={[
              label('PROJECT_NAME'),
              label('PDF_PROJECT_DESCRIPTION'),
              label('PROJECT_OPTIONS', true),
            ]}
            rows={templates.map(t => ({
              cells: [cell(t.name), cell(t.description)],
              rowElement: t,
              icon: <IconTemplate />,
              key: t.id?.toString(),
              options: {
                EDIT_LABEL: () => {
                  dispatch(showDialog(<TemplateDialog template={t} />));
                },
                DELETE_LABEL: () => {
                  dispatch(
                    showDialog(
                      <ConfirmationDialog
                        typeOfConfirmation={
                          TYPE_OF_CONFIRMATION.CONFIRMATION_TEMPLATE
                        }
                        deletedObject={t}
                      />,
                    ),
                  );
                },
              },
            }))}
          />
        ) : (
          <EmptyStateContainer size="lg">
            <FormattedMessage id="NO_EXISTING_TEMPLATES" />
          </EmptyStateContainer>
        )}
      </>
    </ManagerPage>
  );
};

export default TemplatesManager;
