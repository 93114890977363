import React, { FC, ReactNode } from 'react';
import classNames from 'classnames';
import './InputField.scss';
import { FieldLabel } from './FieldLabel';
import InputNumber from 'rc-input-number';
import { conditionalClass } from './element_utils';
import { Locale } from '../redux/uiStateReducer';
import { useSelector } from 'react-redux';
import { IState } from '../redux/store';

interface InputFieldProps extends CommonInputFieldProps {
  placeholder: string;
  value: string | number | undefined | null;
  id?: string;
  onChange?: (s: string) => void;
  inputMax?: number;
  saveValue?: (s: string) => void;
  type?: InputType;
  autoComplete?: string;
  disabled?: boolean;
  onKeyPress?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  autoFocus?: boolean;
  onBlur?: () => void;
}

interface InputFieldNumberProps {
  label?: string | ReactNode;
  placeholder?: string;
  value: string | number | undefined | null;
  onChange: (s: number | null) => void;
  id?: string;
  additionalClass?: string;
  disabled?: boolean;
  onBlur?: () => void;
  integer?: boolean;
  positive?: boolean;
  notNegative?: boolean;
}

interface InputFieldWrapperProps extends CommonInputFieldProps {
  children: ReactNode;
}

interface CommonInputFieldProps {
  additionalClass?: string;
  label?: string | ReactNode;
  errorMessage?: string;
}

export enum InputType {
  PASSWORD = 'password',
  TEXT = 'text',
  NUMBER = 'number',
  EMAIL = 'email',
}

export const InputField: FC<React.PropsWithChildren<InputFieldProps>> = (
  props: InputFieldProps,
) => {
  return (
    <InputFieldWrapper
      additionalClass={props.additionalClass}
      label={props.label}
    >
      <input
        className="input-field__field"
        maxLength={props.inputMax}
        type={props.type || 'text'}
        placeholder={props.placeholder}
        value={props.value || ''}
        id={props.id}
        name={props.id}
        autoComplete={props.autoComplete}
        onChange={e => {
          props.onChange && props.onChange(e.target.value);
          props.saveValue && props.saveValue(e.target.value);
        }}
        disabled={props.disabled}
        onKeyPress={props.onKeyPress}
        autoFocus={props.autoFocus}
        onBlur={props.onBlur}
      />
    </InputFieldWrapper>
  );
};

export const InputFieldWrapper: FC<
  React.PropsWithChildren<InputFieldWrapperProps>
> = props => {
  return (
    <div
      className={classNames(
        'input-field' + conditionalClass(props.additionalClass),
      )}
    >
      <FieldLabel>{props.label}</FieldLabel>
      {props.children}
      {props.errorMessage && (
        <div className="input-field__error">{props.errorMessage}</div>
      )}
    </div>
  );
};

export const InputFieldNumber: FC<
  React.PropsWithChildren<InputFieldNumberProps>
> = props => {
  const locale =
    useSelector<IState, Locale>(s => s.authentication.locale) || Locale.DE;

  function onChange(value: number): void {
    if (props.notNegative && value < 0) {
      props.onChange(null);
      return;
    }

    if (props.positive && value <= 0) {
      props.onChange(null);
      return;
    }

    props.onChange(value);
  }

  return (
    <InputFieldWrapper {...props}>
      <InputNumber
        prefixCls="input-field-number"
        placeholder={props.placeholder}
        value={props.value === null ? undefined : props.value}
        id={props.id}
        name={props.id}
        onChange={onChange}
        disabled={props.disabled}
        onBlur={props.onBlur}
        decimalSeparator={locale === Locale.DE ? ',' : '.'}
        precision={props.integer ? 0 : undefined}
      />
    </InputFieldWrapper>
  );
};

export default InputField;
