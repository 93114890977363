import React, { FC, ReactElement } from 'react';
import './ConsoleResultsColumn.scss';
import './ConsoleItem.scss';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { IState, MyCalcThunkDispatch } from '../../../redux/store';
import { ConsoleSet } from '../../../redux/staticDataReducer';
import classNames from 'classnames';
import {
  removePreselectedConsoleSet,
  setHighlightedConsoleSet,
} from '../../../redux/uiStateActions';
import '../RecommendationsColumn/RecommendationsColumn.scss';
import ParameterValue, { Parameters } from '../../Parameters/ParameterValue';
import { Calculating } from '../../../redux/uiStateReducer';
import InfoMessage from '../../../elements/InfoMessage';
import LoadingSkeleton from '../../../elements/LoadingSkeleton';
import { RequestTypes } from '../../../redux/httpClient';
import {
  AssemblyType,
  RangeOfApplication,
  TabOptions,
} from '../../../redux/constants';
import { AnyAction } from 'redux';
import OptionsMenu from '../../OptionsMenu';
import { onEnter } from '../../../lib/utils';

function ConsoleRequestMessage(): ReactElement {
  const { formatMessage } = useIntl();
  const assemblyType = useSelector<IState, ParameterValue>(
    s => s.parameters.assemblyType,
  );

  return (
    <div className="console-results-column__empty-state">
      <div className="console-results-column__advice-container">
        <InfoMessage>
          <FormattedMessage id="CONSOLE_REQUEST_MESSAGE" />
        </InfoMessage>
        <div className="console-results-column__empty-state-advice">
          <FormattedMessage id="CONTACT_SUPPORT_MESSAGE" />:{' '}
          <a href="mailto:info@dh-partner.com">info@dh-partner.com</a>
        </div>
      </div>

      <div
        className="console-results-column__empty-state-list"
        dangerouslySetInnerHTML={{
          __html: formatMessage({
            id:
              assemblyType.value === AssemblyType.ASSEMBLY_TYPE_FRAME
                ? 'HELP_TEXT_NO_CONSOLE_FRAME_ASSEMBLY'
                : 'HELP_TEXT_NO_CONSOLE_SASH_ASSEMBLY',
          }),
        }}
      ></div>
    </div>
  );
}

function ResultsColumnHeader(props: { id: string }): ReactElement {
  return (
    <div className="console-results-column__title">
      <FormattedMessage id={props.id} />
    </div>
  );
}

function ConsoleItem(props: {
  name: string;
  selected?: boolean;
  onClick?: () => void;
  showSkeleton?: boolean;
  confirmed?: boolean;
  deleteOption?: () => void;
}) {
  return (
    <div
      className={classNames('console-item', {
        'console-item--selected': props.selected,
        'console-item--confirmed': props.confirmed,
      })}
      onClick={props.onClick}
      onKeyDown={onEnter(props.onClick)}
      tabIndex={0}
    >
      {props.showSkeleton && <LoadingSkeleton />}
      {/*<div className="console-item__icon">chevron_right</div>*/}
      <div className="console-item__name" data-qa="first-console">
        {props.name}
      </div>
      {props.confirmed && props.deleteOption && (
        <div className="console-item__options-container">
          <OptionsMenu
            actions={{
              CONSOLE_COLUMN_REMOVE_CONSOLE_BUTTON: () =>
                props.deleteOption?.(),
            }}
          />
        </div>
      )}
    </div>
  );
}

function ConsoleSets(props: {
  loadingConsoleSets: boolean;
  consoleSets: ConsoleSet[];
  highlightedConsoleSet?: ConsoleSet;
  consoleIndex: number;
  highlightedConsoleSetConsolesSection: number;
  withPreselection: boolean;
}): ReactElement {
  const dispatch: MyCalcThunkDispatch<AnyAction> = useDispatch();
  const preselectedConsoleSet = useSelector<IState, ConsoleSet | undefined>(
    s => s.ui.preselectedConsoleSets[props.consoleIndex],
  );
  return (
    <>
      {!props.loadingConsoleSets &&
        props.consoleSets.map(cs => {
          return (
            <ConsoleItem
              key={cs.itemNumber}
              name={cs.name}
              selected={
                cs.id === props.highlightedConsoleSet?.id &&
                props.consoleIndex ===
                  props.highlightedConsoleSetConsolesSection
              }
              onClick={() =>
                dispatch(setHighlightedConsoleSet(cs, props.consoleIndex))
              }
              deleteOption={
                props.withPreselection && props.consoleSets.length > 1
                  ? () =>
                      dispatch(removePreselectedConsoleSet(props.consoleIndex))
                  : undefined
              }
              confirmed={
                cs.id === preselectedConsoleSet?.id && props.withPreselection
              }
            />
          );
        })}
    </>
  );
}

const ConsoleResultsColumn: FC<React.PropsWithChildren<unknown>> = () => {
  const suitableConsoleSets = useSelector<IState, ConsoleSet[][]>(
    s => s.staticData.suitableConsoleSets,
  );

  const calculating = useSelector<IState, Calculating>(s => s.ui.calculating);
  const typeOfProfileDataInput = useSelector<IState, ParameterValue>(
    s => s.parameters.typeOfProfileDataInput,
  );
  const highlightedConsoleSet = useSelector<IState, ConsoleSet | undefined>(
    s => s.ui.highlightedConsoleSet,
  );
  const highlightedConsoleSetConsolesSection = useSelector<IState, number>(
    s => s.ui.highlightedConsoleSetConsolesSection,
  );
  const minimumProfileDataEntered = useSelector<IState, boolean>(
    s =>
      s.parameters.profileSeries.value !==
        Parameters.NO_SELECTION_SYSTEM_SERIES &&
      s.parameters.profileSash.value !==
        Parameters.NO_SELECTION_SYSTEM_SERIES &&
      s.parameters.profileFrame.value !== Parameters.NO_SELECTION_SYSTEM_SERIES,
  );
  const rangeOfApplicationRoof = useSelector<IState, boolean>(
    s => s.parameters.rangeOfApplication.value === RangeOfApplication.ROOF,
  );

  function profileEntry(): boolean {
    return typeOfProfileDataInput.value === TabOptions.OPTION_PROFILE_ENTRY;
  }

  function loadingConsoleSets(): boolean {
    return calculating[RequestTypes.CONSOLE_SETS];
  }

  function showConsoleOnRequestMessage(): boolean {
    if (loadingConsoleSets()) {
      return false;
    }

    if (profileEntry() || rangeOfApplicationRoof) {
      if (!minimumProfileDataEntered) {
        return false;
      }
    }

    return !suitableConsoleSetsPresent();
  }

  function suitableConsoleSetsPresent(): boolean {
    return suitableConsoleSets.length !== 0; //|| suitableConsoleSetsRight?.length !== 0 || suitableConsoleSetsLeft?.length !== 0;
  }

  function showMoreProfileDataNeededEmptyState(): boolean {
    return (
      (profileEntry() || rangeOfApplicationRoof) && !minimumProfileDataEntered
    );
  }

  return (
    <div className="console-results-column">
      <div className="console-results-column__content">
        {(suitableConsoleSetsPresent() ||
          loadingConsoleSets() ||
          showMoreProfileDataNeededEmptyState()) && (
          <ResultsColumnHeader id="CONSOLE_SELECTION_HEADER" />
        )}
        <div className="console-results-column__section">
          {!loadingConsoleSets() && suitableConsoleSets.length > 1 && (
            <div className="console-results-column__sub-title">
              <FormattedMessage id="CONSOLE_RESULTS_DRIVE_ONE" />
            </div>
          )}

          {!showMoreProfileDataNeededEmptyState() && (
            <ConsoleSets
              loadingConsoleSets={loadingConsoleSets()}
              highlightedConsoleSet={highlightedConsoleSet}
              consoleSets={suitableConsoleSets[0] || []}
              highlightedConsoleSetConsolesSection={
                highlightedConsoleSetConsolesSection
              }
              consoleIndex={0}
              withPreselection={suitableConsoleSets.length > 1}
            />
          )}
        </div>
        <div className="console-results-column__section">
          {!loadingConsoleSets() && suitableConsoleSets.length > 1 && (
            <div className="console-results-column__sub-title">
              <FormattedMessage id="CONSOLE_RESULTS_DRIVE_TWO" />
            </div>
          )}
          {suitableConsoleSets.length > 1 &&
            !showMoreProfileDataNeededEmptyState() && (
              <ConsoleSets
                loadingConsoleSets={loadingConsoleSets()}
                highlightedConsoleSet={highlightedConsoleSet}
                consoleSets={suitableConsoleSets[1] || []}
                highlightedConsoleSetConsolesSection={
                  highlightedConsoleSetConsolesSection
                }
                consoleIndex={1}
                withPreselection={suitableConsoleSets.length > 1}
              />
            )}
        </div>
        {showConsoleOnRequestMessage() && <ConsoleRequestMessage />}
        {loadingConsoleSets() && !showMoreProfileDataNeededEmptyState() && (
          <ConsoleItem name="." selected={false} showSkeleton={true} />
        )}

        {showMoreProfileDataNeededEmptyState() && (
          <>
            <div className="console-item console-item--empty">
              <div className="console-item__name">.</div>
              <div className="console-item__empty-state-message">
                <FormattedMessage id="PROFILE_COMBINATION_REQUIRED" />
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default ConsoleResultsColumn;
