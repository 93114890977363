import React, { FC, ReactNode } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { IState, MyCalcThunkDispatch } from '../../../redux/store';
import ResultItem, { ResultItemStyle } from '../../ResultItem';
import DownloadDialogButton from '../../DownloadDialogButton';
import './NRWGSolutionColumn.scss';
import { ConsoleSet } from '../../../redux/staticDataReducer';
import { RequestTypes } from '../../../redux/httpClient';
import { WindDeflector } from '../../../redux/nrwgReducer';
import OptionsMenu from '../../OptionsMenu';
import Button, { ButtonSize, ButtonType } from '../../../elements/Button';
import {
  showDialog,
  deselectWindDeflector,
  storeSelectedNRWGConsoleSet,
  deSelectConsoleSet,
} from '../../../redux/uiStateActions';
import AddWindDeflectorDialog from './AddWindDeflectorDialog';
import {
  AERODYNAMIC_CASE,
  AERODYNAMIC_CASES_WITHOUT_WIND_DEFLECTOR,
  Application,
  DOUBLE_FLAP_APPLICATIONS,
  RangeOfApplication,
} from '../../../redux/constants';
import LockNRGWDialog from './LockNRWGDialog';
import {
  getSelectedWindow,
  useSelectedDrive,
  useSelectedWindow,
} from '../../../hooks/selectorHooks';
import classNames from 'classnames';
import AddNRWGConsoleSetDialog from './AddNRWGConsoleSetDialog';
import { Material } from '../../../admin/constants';
import { Company } from '../../../redux/authenticationReducer';
import { AnyAction } from 'redux';

const WindDeflectorParts: FC<React.PropsWithChildren<unknown>> = () => {
  const selectedWindDeflector = useSelector<IState, WindDeflector | undefined>(
    s => getSelectedWindow(s)?.windDeflector,
  );
  const dispatch: MyCalcThunkDispatch<AnyAction> = useDispatch();
  const selectedWindow = useSelectedWindow()!;
  const facadeWindow = useSelector<IState, boolean>(
    s => s.parameters.rangeOfApplication.value === RangeOfApplication.FACADE,
  );

  if ((!selectedWindow?.locked && !selectedWindDeflector) || facadeWindow) {
    return null;
  }

  function windDeflector(): WindDeflector {
    return selectedWindow.windDeflector!;
  }

  if (!windDeflector()) {
    return null;
  }

  return (
    <>
      <div className="nrwg-solution-column__wind-deflector-result">
        {windDeflector().number} x {''}
        <FormattedMessage id="tree_parent_fenster_windleitwand" />,{' '}
        <FormattedMessage id={'ADD_WIND_DEFLECTOR_TABLE_HEADER_MATERIAL'} />:{' '}
        <FormattedMessage
          id={
            windDeflector().glass
              ? 'WIND_DEFLECTOR_GLASS'
              : 'WIND_DEFLECTOR_SHEET_METAL'
          }
        />
        , <FormattedMessage id="HIGHT" />: {windDeflector().height} mm
        {!selectedWindow?.locked && (
          <OptionsMenu
            actions={{
              DELETE_LABEL: () => {
                dispatch(deselectWindDeflector());
              },
            }}
          />
        )}
      </div>
      {windDeflector().glass ? (
        <>
          <ResultItem
            loading={false}
            byline={<FormattedMessage id="result_windwand_edgeholder_label" />}
            amount={windDeflector().numberOfCornerBracket}
            name={windDeflector().nameOfCornerBracket}
            propertiesStyle={ResultItemStyle.WITHOUT_ICONS}
          />

          {windDeflector().numberOfMiddleBracket > 0 && (
            <ResultItem
              loading={false}
              amount={windDeflector().numberOfMiddleBracket}
              byline={
                <FormattedMessage id="result_windwand_middleholder_label" />
              }
              name={<>{windDeflector().nameOfMiddleBracket} </>}
              propertiesStyle={ResultItemStyle.WITHOUT_ICONS}
            />
          )}

          {windDeflector().numberOfGlassBracket > 0 && (
            <ResultItem
              loading={false}
              amount={windDeflector().numberOfGlassBracket}
              byline={
                <FormattedMessage id="result_windwand_glassholder_label" />
              }
              name={windDeflector().nameOfGlassBracket}
              propertiesStyle={ResultItemStyle.WITHOUT_ICONS}
            />
          )}
        </>
      ) : (
        <a
          className="nrwg-solution-column__application-drawing"
          target="_blank"
          rel="noopener noreferrer"
          href="windDeflectorDocuments/Blech-Windleitwand.pdf"
        >
          <ResultItem
            loading={false}
            byline={<FormattedMessage id="SHEET_METAL_ASSEMBLY" />}
            name={
              <FormattedMessage id="WIND_DEFLECTOR_MESSAGE_SEE_APPLICATION_DIAGRAMM" />
            }
            propertiesStyle={ResultItemStyle.WITHOUT_ICONS}
          />
        </a>
      )}
    </>
  );
};

interface AddNRWGElementButtonProps {
  dialog: ReactNode;
  label: string;
}

const AddNRWGElementButton: FC<
  React.PropsWithChildren<AddNRWGElementButtonProps>
> = props => {
  const dispatch: MyCalcThunkDispatch<AnyAction> = useDispatch();
  return (
    <button
      className="nrwg-solution-column__add-nrwg-element-button"
      onClick={() => dispatch(showDialog(props.dialog))}
    >
      <span className="nrwg-solution-column__add-nrwg-element-button-label">
        <span className="nrwg-solution-column__add-nrwg-element-button-icon">
          add_circle
        </span>
        <FormattedMessage id={props.label} />
      </span>
      <ResultItem
        loading={false}
        byline={<FormattedMessage id="SHEET_METAL_ASSEMBLY" />}
        name={
          <FormattedMessage id="WIND_DEFLECTOR_MESSAGE_SEE_APPLICATION_DIAGRAMM" />
        }
        propertiesStyle={ResultItemStyle.WITHOUT_ICONS}
      />
    </button>
  );
};

const NRWGSolutionColumn: FC<React.PropsWithChildren<unknown>> = () => {
  const dispatch: MyCalcThunkDispatch<AnyAction> = useDispatch();
  const selectedDrive = useSelectedDrive();
  const usersCompany = useSelector<IState, Company | undefined>(
    state => state.authentication.currentUser?.company as unknown as Company,
  );
  const selectedNRWGConsoleSet = useSelector<IState, ConsoleSet | undefined>(
    s => s.ui.selectedNRWGConsoleSet,
  );
  const nrwgConsoleSetCandidates = useSelector<IState, ConsoleSet[]>(
    s => s.staticData.nrwgConsoleSetCandidates,
  );
  const consoleSetLoading = useSelector<IState, boolean>(
    s => s.ui.calculating[RequestTypes.NRWG_CONSOLE_SETS],
  );
  const { formatMessage } = useIntl();
  const selectedWindow = useSelectedWindow();
  const selectedWindDeflector = useSelector<IState, WindDeflector | undefined>(
    s => getSelectedWindow(s)?.windDeflector,
  );
  const aerodynamicCase = useSelector<IState, AERODYNAMIC_CASE>(
    state => state.parameters.aerodynamicCase.value as AERODYNAMIC_CASE,
  );
  const rangeOfApplication = useSelector<IState, RangeOfApplication>(
    s => s.parameters.rangeOfApplication.value as RangeOfApplication,
  );
  const windowFrameMaterial = useSelector<IState, Material>(
    state =>
      state.profileData.systemSeriesResult.filter(
        p =>
          p.series ===
          selectedWindow?.calculationParameters.profileSeries.value,
      )[0]?.material,
  );

  function getApplicationEnum(application: string): Application | undefined {
    for (const [k, v] of Object.entries(Application)) {
      if (k === application) {
        return v;
      }
    }
  }
  //undefined bc of non selected drive for locked NRWG's
  const numberOfDrives = useSelector<IState, number | undefined>(s => {
    const numberOfDrives = selectedDrive?.numberOfDrives;

    const application = s.parameters.application.value as string;
    if (
      DOUBLE_FLAP_APPLICATIONS.includes(getApplicationEnum(application)!) &&
      numberOfDrives
    ) {
      return numberOfDrives * 2;
    }

    return numberOfDrives;
  });

  function windDeflectorNeedsToBeAdded(): boolean {
    if (
      selectedWindDeflector ||
      AERODYNAMIC_CASES_WITHOUT_WIND_DEFLECTOR.includes(
        aerodynamicCase as AERODYNAMIC_CASE,
      ) ||
      rangeOfApplication === RangeOfApplication.FACADE ||
      selectedWindow?.locked
    ) {
      return false;
    }

    return true;
  }

  if (!selectedDrive) {
    return null;
  }

  function usersCompanyCanLockWindows(material: Material): boolean {
    if (!usersCompany) {
      return false;
    }

    if (rangeOfApplication === RangeOfApplication.FACADE) {
      if (material === Material.STAHL) {
        return !!usersCompany.cprNumberFacadeSteel?.trim();
      } else {
        return !!usersCompany.cprNumberFacade?.trim();
      }
    } else {
      return !!usersCompany.cprNumberRoof?.trim();
    }
  }

  function moreThanOneConsoleAvailable(): boolean {
    return nrwgConsoleSetCandidates.length > 1;
  }

  function consoleNeedsToBeAdded(): boolean {
    return moreThanOneConsoleAvailable() && !selectedNRWGConsoleSet;
  }

  return (
    <div className="nrwg-solution-column">
      <div className="nrwg-solution-column__content">
        <div className="nrwg-solution-column__title">
          {selectedWindow?.locked && (
            <span className="nrwg-solution-column__locked_icon">lock</span>
          )}
          <FormattedMessage id="NRWG_SOLUTION_COLUMN_HEADING" />
          {selectedWindow?.locked && (
            <>
              {' '}
              (<FormattedMessage id="NRWG_SOLUTION_COLUMN_LOCKED_MESSAGE" />)
            </>
          )}
        </div>

        <div>
          <ResultItem
            byline={formatMessage({
              id: 'tree_Antrieb',
            })}
            loading={false}
            name={selectedDrive.name}
            amount={numberOfDrives}
            propertiesStyle={ResultItemStyle.WITHOUT_ICONS}
          />
        </div>
        <div>
          {!moreThanOneConsoleAvailable() || selectedNRWGConsoleSet ? (
            <ResultItem
              byline={formatMessage({
                id: 'CONSOLE_SET_BYLINE',
              })}
              loading={
                consoleSetLoading ||
                !selectedWindow?.consoleSets ||
                selectedWindow?.consoleSets.length === 0
              }
              name={selectedWindow?.consoleSets?.[0]?.name}
              amount={numberOfDrives}
              options={
                moreThanOneConsoleAvailable()
                  ? {
                      CONSOLE_COLUMN_REMOVE_CONSOLE_BUTTON: () => {
                        dispatch(storeSelectedNRWGConsoleSet(undefined));
                        dispatch(deSelectConsoleSet());
                      },
                    }
                  : undefined
              }
              propertiesStyle={ResultItemStyle.WITHOUT_ICONS}
            />
          ) : (
            <AddNRWGElementButton
              dialog={<AddNRWGConsoleSetDialog />}
              label="ADD_CONSOLE"
            />
          )}
        </div>
        <div>
          <WindDeflectorParts />
          {windDeflectorNeedsToBeAdded() && (
            <AddNRWGElementButton
              dialog={<AddWindDeflectorDialog />}
              label="ADD_WIND_DEFLECTOR_BUTTON_LABEL"
            />
          )}
        </div>
      </div>
      <div
        className={classNames('nrwg-solution-column__actions', {
          'nrwg-solution-column__actions--locked': selectedWindow?.locked,
        })}
      >
        <DownloadDialogButton
          selectedDrive={selectedDrive}
          showConsoleDocuments={true}
          showWindDeflectorDocuments={true}
          smallButton={true}
        />

        {!selectedWindow?.locked &&
          !windDeflectorNeedsToBeAdded() &&
          !consoleNeedsToBeAdded() &&
          usersCompanyCanLockWindows(windowFrameMaterial) && (
            <Button
              label="LOCK_NRWG_BUTTON_LABEL"
              additionalClass="nrwg-solution-column__lock-button"
              action={() => dispatch(showDialog(<LockNRGWDialog />))}
              iconName="lock"
              size={ButtonSize.LARGE}
              type={ButtonType.SECONDARY}
            />
          )}
      </div>
    </div>
  );
};

export default NRWGSolutionColumn;
