import React, { FC } from 'react';
import EditableBox from './EditableBox';
import './SystemSeriesBox.scss';
import { FormattedMessage } from 'react-intl';
import SystemSeriesDialog from '../../SystemSeriesDialog';
import { useDispatch, useSelector } from 'react-redux';
import { IState, MyCalcThunkDispatch } from '../../../redux/store';
import { showDialog } from '../../../redux/uiStateActions';
import ParameterValue, { Parameters } from '../ParameterValue';
import { ValueKey } from '../../../redux/valueKey';
import { AnyAction } from 'redux';
import { Help } from '../ParameterSection';

interface ButtonBoxProps extends ButtonProps {
  isSingleEdit: boolean;
  helpContent: Help;
}

interface ButtonProps {
  label: string;
  valueKey: ValueKey;
}

const Button: FC<React.PropsWithChildren<ButtonProps>> = (
  props: ButtonProps,
) => {
  const dispatch: MyCalcThunkDispatch<AnyAction> = useDispatch();
  const selectedProfileSystem = useSelector<IState, ParameterValue>(
    s => s.parameters.profileSystem,
  );
  const selectedProfileSeries = useSelector<IState, ParameterValue>(
    s => s.parameters.profileSeries,
  );

  return (
    <>
      {selectedProfileSystem.value !== Parameters.NO_SELECTION_SYSTEM_SERIES ? (
        <div className="system-series-box">
          <dl className="system-series-box__values">
            <dt>
              <FormattedMessage id="SYSTEM" />
            </dt>
            <dd data-testid="system-series-box__system">
              {selectedProfileSystem.value}
            </dd>
            <dt>
              <FormattedMessage id="SERIES" />
            </dt>
            <dd data-testid="system-series-box__series">
              {selectedProfileSeries.value}
            </dd>
          </dl>
          <button
            className="system-series-box__values-button"
            onClick={() =>
              dispatch(
                showDialog(<SystemSeriesDialog profileCombination={false} />),
              )
            }
          >
            <FormattedMessage id="EDIT" />
          </button>
        </div>
      ) : (
        <button
          onClick={() =>
            dispatch(
              showDialog(<SystemSeriesDialog profileCombination={false} />),
            )
          }
          className="system-series-box__button"
          type="button"
        >
          <FormattedMessage id={props.label} />
        </button>
      )}
    </>
  );
};

const SystemSeriesBox: FC<React.PropsWithChildren<ButtonBoxProps>> = (
  props: ButtonBoxProps,
) => {
  return (
    <EditableBox {...props} heading="SYSTEM_AND_SERIES">
      <div>
        <Button
          label={props.label}
          valueKey={props.valueKey}
          key={props.label}
        />
      </div>
    </EditableBox>
  );
};

export default SystemSeriesBox;
