import FieldLabel from '../../elements/FieldLabel';
import React, { useEffect, useState } from 'react';
import './../components/AssignSelectGroup.scss';
import { UiConstants } from '../constants';
import Dialog from '../../components/Dialog';
import _ from 'lodash';
import classNames from 'classnames';

interface Headings {
  componentHeadingWithoutDialog?: string;
  headingLeftTable: string;
  headingRightTable: string;
}

interface AssignSelectGroupProps<T> {
  componentClass: string;
  headings: Headings;
  addElements: (e: T[]) => void;
  removeElements: (e: T[]) => void;
  selectedElements: T[];
  deselectedElements: T[];
  displayNameExtractor: (v: T) => string;
  backgroundWhite?: boolean;
  multipleSelectAllowed?: boolean;
}

export const AssignSelectGroup = <T,>(
  props: AssignSelectGroupProps<T>,
): JSX.Element => {
  const [markedElements, setMarkedElements] = useState<T[] | undefined>(
    undefined,
  );
  const [markedElementsToRemove, setMarkedElementsToRemove] = useState<
    T[] | undefined
  >(undefined);

  return (
    <div
      className={classNames(
        `assign-component__assign-select-group`,
        {
          'assign-component__assign-select-group--white': props.backgroundWhite,
        },
        props.componentClass,
      )}
    >
      <div className="assign-component__assign-select-group-title">
        {props.headings.componentHeadingWithoutDialog}
      </div>
      <AssignSelectField
        heading={props.headings.headingLeftTable}
        onChange={setMarkedElements}
        elements={props.deselectedElements}
        displayNameExtractor={props.displayNameExtractor}
        multipleSelectAllowed={props.multipleSelectAllowed || false}
      />
      <div className="assign-component__assign-select-group-buttons">
        <button
          onClick={e => {
            e.preventDefault();
            markedElements && props.addElements(markedElements);
            setMarkedElements(undefined);
          }}
        >
          chevron_right
        </button>
        <button
          onClick={e => {
            e.preventDefault();
            markedElementsToRemove &&
              props.removeElements(markedElementsToRemove);
            setMarkedElementsToRemove(undefined);
          }}
        >
          chevron_left
        </button>
      </div>
      <AssignSelectField
        heading={props.headings.headingRightTable}
        onChange={v => setMarkedElementsToRemove(v)}
        elements={props.selectedElements}
        displayNameExtractor={props.displayNameExtractor}
        multipleSelectAllowed={props.multipleSelectAllowed || false}
      />
    </div>
  );
};

interface AssignSelectFieldProps<T> {
  elements: T[];
  onChange: (v: T[]) => void;
  heading: string;
  displayNameExtractor: (v: T) => string;
  multipleSelectAllowed: boolean;
}

const AssignSelectField = <T,>(
  props: AssignSelectFieldProps<T>,
): JSX.Element => {
  function getSelectValues(select: HTMLSelectElement): T[] {
    const indices: string[] = [];
    const options = select && select.options;
    let singleOption;

    Array.from(options).forEach((option, index) => {
      singleOption = options[index];
      if (singleOption.selected) {
        indices.push(singleOption.value || singleOption.text);
      }
    });
    return indices
      .map(indizesAsString => +indizesAsString)
      .map(i => props.elements[i]);
  }

  return (
    <div className="assign-component__assign-select assign-component__assign-select--small">
      <FieldLabel>{props.heading}</FieldLabel>
      <select
        multiple={props.multipleSelectAllowed}
        key={props.elements.length}
        size={Math.max(2, props.elements.length)}
        onChange={e => {
          props.onChange(getSelectValues(e.target));
        }}
      >
        {props.elements.map((e, index) => (
          <option key={index} value={index}>
            {props.displayNameExtractor(e)}
          </option>
        ))}
      </select>
    </div>
  );
};

interface AssignSelectGroupDialogProps<T> {
  componentClass: string;
  headings: Headings;
  setDialogIsShown: (b: boolean) => void;
  dialogIsShown: boolean;
  dialogHeading: string;
  saveAction: (selectedElements: T[], deselectedElememnts: T[]) => void;
  selectedElements: T[];
  deselectedElements: T[];
  displayNameExtractor: (v: T) => string;
}

const AssignSelectGroupDialog = <T,>(
  props: AssignSelectGroupDialogProps<T>,
): JSX.Element | null => {
  const [selectedElements, setSelectedElements] = useState(
    props.selectedElements,
  );
  const [deselectedElements, setDeselectedElements] = useState(
    props.deselectedElements,
  );

  useEffect(() => {
    setSelectedElements(props.selectedElements);
    setDeselectedElements(props.deselectedElements);
  }, [props.dialogIsShown]);

  if (!props.dialogIsShown) {
    return null;
  }

  function removeElementsFromList(list: T[], elementsToRemove: T[]): T[] {
    return list.filter(element => !elementsToRemove.includes(element));
  }

  return (
    <Dialog
      cancelAction={() => {
        props.setDialogIsShown(false);
      }}
      setDialogIsShown={props.setDialogIsShown}
      dialogIsShown={props.dialogIsShown}
      heading={props.dialogHeading}
      componentClass={''}
      key={props.dialogIsShown.toString()}
      nested={true}
      footerProps={{
        notTranslated: true,
        cancelAction: () => {
          props.setDialogIsShown(false);
        },
        saveAction: () => {
          props.saveAction(selectedElements, deselectedElements);
          props.setDialogIsShown(false);
        },
        primaryActionLabelText: UiConstants.FINISH,
      }}
    >
      <AssignSelectGroup
        multipleSelectAllowed={false}
        componentClass={props.componentClass}
        headings={props.headings}
        deselectedElements={_.sortBy(deselectedElements, [
          props.displayNameExtractor,
        ])}
        selectedElements={selectedElements}
        addElements={e => {
          setSelectedElements([...selectedElements, ...e]);
          setDeselectedElements(removeElementsFromList(deselectedElements, e));
        }}
        removeElements={e => {
          setSelectedElements(removeElementsFromList(selectedElements, e));
          setDeselectedElements([...deselectedElements, ...e]);
        }}
        displayNameExtractor={props.displayNameExtractor}
      />
    </Dialog>
  );
};

export default AssignSelectGroupDialog;
