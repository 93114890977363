import React, { FC } from 'react';
import './ConsoleDetailsColumn.scss';
import { FormattedMessage, useIntl } from 'react-intl';
import { IState, MyCalcThunkDispatch } from '../../../redux/store';
import { ConsoleSet } from '../../../redux/staticDataReducer';
import { useDispatch, useSelector } from 'react-redux';
import {
  preSelectConsoleSet,
  selectConsoleSet,
  switchPage,
} from '../../../redux/uiStateActions';
import { Pages } from '../../../redux/uiStateReducer';
import ParameterValue from '../../Parameters/ParameterValue';
import {
  HINT_CONFIRMATION_LABELS,
  RangeOfApplication,
  TabOptions,
} from '../../../redux/constants';
import { AnyAction } from 'redux';
import { changeCalculationParameter } from '../../../redux/parametersActions';
import { ValueKey } from '../../../redux/valueKey';
import Button, { ButtonSize, ButtonType } from '../../../elements/Button';
import ErrorItem from '../DriveDetailsColumn/ErrorItem';
import { ReactComponent as IconWarningAmber } from '../../../assets/icons/WarningAmberIcon.svg';

const ConsoleDetailsColumn: FC<React.PropsWithChildren<unknown>> = () => {
  const dispatch: MyCalcThunkDispatch<AnyAction> = useDispatch();
  const { formatMessage } = useIntl();
  const highlightedConsoleSet = useSelector<IState, ConsoleSet | undefined>(
    s => s.ui.highlightedConsoleSet,
  );
  const highlightedConsoleSetConsolesSection = useSelector<
    IState,
    number | undefined
  >(s => s.ui.highlightedConsoleSetConsolesSection);
  const rangeOfApplication = useSelector<IState, ParameterValue>(
    s => s.parameters.rangeOfApplication,
  );
  const numberOfPreselectedConsoleSets = useSelector<IState, number>(
    s => s.ui.preselectedConsoleSets.length,
  );
  const preselectedConsoleSets = useSelector<IState, ConsoleSet[]>(
    s => s.ui.preselectedConsoleSets,
  );
  const suitableConsoleSets = useSelector<IState, ConsoleSet[][]>(
    s => s.staticData.suitableConsoleSets,
  );

  return (
    <div className="console-details-column">
      {highlightedConsoleSet && (
        <>
          <div className="console-details-column__description-title">
            <div className="console-details-column__description-small-headline console-details-column__title-byline">
              <FormattedMessage id="CONSOLE_SET_BYLINE" />
            </div>
            <div className="console-details-column__description-name">
              {highlightedConsoleSet.name}
            </div>{' '}
          </div>
          <div className="console-details-column__description-text-container">
            <div
              className="console-details-column__description-text"
              dangerouslySetInnerHTML={{
                __html: formatMessage({
                  id: highlightedConsoleSet.descriptionKey,
                }),
              }}
            />

            {rangeOfApplication.value !== RangeOfApplication.ROOF && (
              <div>
                <div className="console-details-column__description-small-headline">
                  <FormattedMessage id="TYPE_ASSEMBLY_SPACE" />
                </div>
                <div>
                  <FormattedMessage id="ASSEMBLY_SPACE_ON_THE_FRAME" />{' '}
                  {highlightedConsoleSet.requiredSpaceFrame} mm
                </div>
                <div>
                  <FormattedMessage id="ASSEMBLY_SPACE_ON_THE_SASH_FRAME" />{' '}
                  {highlightedConsoleSet.requiredSpaceSashFrame} mm
                </div>
              </div>
            )}
            <div className="console-details-column__description-errors">
              {highlightedConsoleSet.hints.length >= 1 && (
                <div className="drive-details-column__description-errors-headline">
                  <div className="drive-details-column__description-errors-icon">
                    <IconWarningAmber />
                  </div>
                  <FormattedMessage id="ERROR_MESSAGES" />
                </div>
              )}

              <div className="drive-details-column__errors">
                {highlightedConsoleSet.hints.map(hint => (
                  <ErrorItem
                    key={hint.type}
                    hint={hint}
                    message={formatMessage({ id: hint.type }, hint.parameters)}
                    labels={HINT_CONFIRMATION_LABELS}
                  />
                ))}
              </div>
            </div>
          </div>

          <button
            className="console-details-column__button"
            onClick={() => {
              if (suitableConsoleSets.length === 1) {
                dispatch(
                  selectConsoleSet([highlightedConsoleSet], {
                    recalculateDrives: true,
                  }),
                );

                //For roof free entry is not available. If the user can click this button it means she has entered profile data,
                //The user does not switch actively to profile entry on the console selectio page. This ensures that profile entry is
                //selected, if the profile data has been used to find a console.
                if (rangeOfApplication.value === RangeOfApplication.ROOF) {
                  dispatch(
                    changeCalculationParameter(
                      ValueKey.VALUEKEY_TYPE_OF_PROFILE_INPUT,
                      TabOptions.OPTION_PROFILE_ENTRY,
                      true,
                    ),
                  );
                }

                dispatch(switchPage(Pages.SELECTED_PRODUCTS));
              } else if (
                numberOfPreselectedConsoleSets !== 2 &&
                highlightedConsoleSetConsolesSection !== undefined
              ) {
                dispatch(
                  preSelectConsoleSet(
                    highlightedConsoleSet,
                    highlightedConsoleSetConsolesSection,
                  ),
                );
              } else {
                dispatch(
                  selectConsoleSet(preselectedConsoleSets, {
                    recalculateDrives: true,
                  }),
                );
                dispatch(switchPage(Pages.SELECTED_PRODUCTS));
              }
            }}
            data-qa="select-console-button"
          >
            {numberOfPreselectedConsoleSets === 2 ? (
              <FormattedMessage id="CONFIRM_CONSOLE_SELECTION" />
            ) : (
              <FormattedMessage id="SELECT_CONSOLE" />
            )}
            <div className="button__icon">add</div>
          </button>
          {numberOfPreselectedConsoleSets === 2 && (
            <Button
              action={() => {
                dispatch(
                  selectConsoleSet(preselectedConsoleSets, {
                    recalculateDrives: true,
                  }),
                );
                dispatch(switchPage(Pages.SELECTED_PRODUCTS));
              }}
              additionalClass="console-details-column__confirm-button"
              type={ButtonType.PRIMARY}
              label="CONFIRM_CONSOLE_SELECTION"
              iconName="add"
              size={ButtonSize.LARGE}
            />
          )}
        </>
      )}
    </div>
  );
};

export default ConsoleDetailsColumn;
