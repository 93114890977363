import React, { FC } from 'react';
import './NRWGDefinitionSection.scss';
import { FormattedMessage, useIntl } from 'react-intl';
import EmptyStateContainer from '../../elements/EmptyStateContainer';
import { useDispatch, useSelector } from 'react-redux';
import { ParametersState } from '../../redux/parametersReducer';
import { IState, MyCalcThunkDispatch } from '../../redux/store';
import ParameterValue, { Parameters } from './ParameterValue';
import { changeCalculationParameter } from '../../redux/parametersActions';
import {
  REQUIRED_NRWG_PARAMETERS_FACADE,
  REQUIRED_NRWG_PARAMETERS_ROOF,
  ValueKey,
} from '../../redux/valueKey';
import { RangeOfApplication } from '../../redux/constants';
import { TRANSLATED_TEST_SEARCH_OPTION_VALUES } from '../../redux/nrwgReducer';
import { updateSingleEdit } from '../../redux/uiStateActions';
import { AnyAction } from 'redux';

export const NRWGDefinitionSection: FC<
  React.PropsWithChildren<unknown>
> = () => {
  const parameters = useSelector<IState, ParametersState>(s => s.parameters);
  const dispatch: MyCalcThunkDispatch<AnyAction> = useDispatch();

  function noTestSelectionOptionSet(
    rangeOfApplication: RangeOfApplication,
  ): boolean {
    const requiredParameters =
      rangeOfApplication === RangeOfApplication.ROOF
        ? REQUIRED_NRWG_PARAMETERS_ROOF
        : REQUIRED_NRWG_PARAMETERS_FACADE;
    return requiredParameters.every(vk => parameters[vk].isUndefined());
  }

  function removeDefinitionNoSelection(valueKey: ValueKey): void {
    remove(valueKey, Parameters.NO_SELECTION_SYSTEM_SERIES);
  }

  function removeDefinitionEntryRequired(valueKey: ValueKey): void {
    remove(valueKey, Parameters.ENTRY_REQUIRED);
  }

  function remove(valueKey: ValueKey, value: Parameters): void {
    dispatch(changeCalculationParameter(valueKey, value));
    dispatch(updateSingleEdit(false, undefined));
  }

  return (
    <div
      data-testid="nrwg-definition-section"
      className="nrwg-definition-section"
    >
      <div className="nrwg-definition-section__header">
        <div className="nrwg-definition-section__title">
          <FormattedMessage id="PARAMETER_SECTION_TITLE_NRWG_DEFINITION" />
        </div>
      </div>{' '}
      <div className="nrwg-definition-section__body">
        {parameters.profileSystem.value !==
          Parameters.NO_SELECTION_SYSTEM_SERIES && (
          <TestSearchOption
            label="SYSTEM"
            value={parameters.profileSystem}
            onClick={() => removeDefinitionNoSelection(ValueKey.PROFILE_SYSTEM)}
          />
        )}
        {parameters.profileSeries.value !==
          Parameters.NO_SELECTION_SYSTEM_SERIES && (
          <TestSearchOption
            label="SERIES"
            value={parameters.profileSeries}
            onClick={() => removeDefinitionNoSelection(ValueKey.PROFILE_SERIES)}
          />
        )}
        {parameters.profileSash.value !==
          Parameters.NO_SELECTION_SYSTEM_SERIES && (
          <TestSearchOption
            label="PROFILE_SASH"
            value={parameters.profileSash}
            onClick={() => removeDefinitionNoSelection(ValueKey.PROFILE_SASH)}
          />
        )}
        {parameters.profileFrame.value !==
          Parameters.NO_SELECTION_SYSTEM_SERIES && (
          <TestSearchOption
            label="PROFILE_FRAME"
            value={parameters.profileFrame}
            onClick={() => removeDefinitionNoSelection(ValueKey.PROFILE_FRAME)}
          />
        )}
        {parameters.profileExchange.value !==
          Parameters.NO_SELECTION_SYSTEM_SERIES && (
          <TestSearchOption
            label="PROFILE_EXCHANGE"
            value={parameters.profileExchange}
            onClick={() =>
              removeDefinitionNoSelection(ValueKey.PROFILE_EXCHANGE)
            }
          />
        )}
        {parameters.profileBase.value !==
          Parameters.NO_SELECTION_SYSTEM_SERIES && (
          <TestSearchOption
            label="PROFILE_BASE"
            value={parameters.profileBase}
            onClick={() => removeDefinitionNoSelection(ValueKey.PROFILE_BASE)}
          />
        )}
        {parameters.openingDirection.value !== Parameters.ENTRY_REQUIRED &&
          parameters.rangeOfApplication.value === RangeOfApplication.FACADE && (
            <TestSearchOption
              label="fenster_edit_openingdirection"
              value={parameters.openingDirection}
              onClick={() =>
                removeDefinitionEntryRequired(
                  ValueKey.VALUEKEY_OPENING_DIRECTION,
                )
              }
            />
          )}
        {parameters.application.value !== Parameters.ENTRY_REQUIRED && (
          <TestSearchOption
            label="APPLICATION"
            value={parameters.application}
            onClick={() => removeDefinitionEntryRequired(ValueKey.APPLICATION)}
          />
        )}
        {parameters.mountingType.value !== Parameters.ENTRY_REQUIRED && (
          <TestSearchOption
            label="MOUNTING_TYPE"
            value={parameters.mountingType}
            onClick={() =>
              removeDefinitionEntryRequired(ValueKey.MOUNTING_TYPE)
            }
          />
        )}
        {parameters.aerodynamicCase.value !== Parameters.ENTRY_REQUIRED && (
          <TestSearchOption
            label="AERODYNAMIC_CASE"
            value={parameters.aerodynamicCase}
            onClick={() =>
              removeDefinitionEntryRequired(ValueKey.AERODYNAMIC_CASE)
            }
          />
        )}
        {parameters.drivePosition.value !== Parameters.ENTRY_REQUIRED && (
          <TestSearchOption
            label="fenster_edit_antriebsposition"
            value={parameters.drivePosition}
            onClick={() =>
              removeDefinitionEntryRequired(ValueKey.VALUEKEY_DRIVE_POSITION)
            }
          />
        )}
        {parameters.assemblyType.value !== Parameters.ENTRY_REQUIRED &&
          parameters.rangeOfApplication.value !== RangeOfApplication.ROOF && (
            <TestSearchOption
              label="ASSEMBLY_TYPE"
              value={parameters.assemblyType}
              onClick={() =>
                removeDefinitionEntryRequired(ValueKey.VALUEKEY_ASSEMBLY_TYPE)
              }
            />
          )}
        {noTestSelectionOptionSet(
          parameters.rangeOfApplication.value as RangeOfApplication,
        ) && (
          <EmptyStateContainer size="sm">
            <FormattedMessage id="EMPTY_STATE_NRWG_DEFINITION" />
          </EmptyStateContainer>
        )}
      </div>
    </div>
  );
};

interface TestSearchOptionProps {
  label: string;
  value: ParameterValue;
  onClick: () => void;
}

const TestSearchOption: FC<React.PropsWithChildren<TestSearchOptionProps>> = (
  props: TestSearchOptionProps,
) => {
  const { formatMessage } = useIntl();
  const value = props.value.value as string;
  return (
    <div onClick={props.onClick} className="nrwg-definition-section__list-item">
      <div className="nrwg-definition-section__list-item-name">
        <FormattedMessage id={props.label} />
      </div>
      <div className="nrwg-definition-section__list-item-data">
        {TRANSLATED_TEST_SEARCH_OPTION_VALUES.includes(value)
          ? formatMessage({ id: value })
          : props.value.toString(formatMessage)}
      </div>
    </div>
  );
};

export default NRWGDefinitionSection;
