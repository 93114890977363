import _ from 'lodash';
import {
  Application,
  DOUBLE_FLAP_APPLICATIONS,
  FillType,
  GeometricArea,
  RangeOfApplication,
  RevealDepth,
  RevealStructure,
} from './constants';
import { IState } from './store';
import { getSelectedWindow } from '../hooks/selectorHooks';
import { Window } from './projectsReducer';
import ParameterValue from '../components/Parameters/ParameterValue';

export enum ValueKey {
  VALUEKEY_RANGE_OF_APPLICATION = 'rangeOfApplication',
  VALUEKEY_ANGLE_OF_INSTALLATION = 'angleOfInstallation',
  VALUEKEY_OPENING_TYPE = 'openingType',
  VALUEKEY_OPENING_DIRECTION = 'openingDirection',
  VALUEKEY_SASH_WIDTH = 'sashWidth',
  VALUEKEY_SASH_HEIGHT = 'sashHeight',
  VALUEKEY_SASH_WEIGHT = 'sashWeight',
  VALUEKEY_SANDWICH_ELEMENT = 'sandwichElement',
  VALUEKEY_GLAS_THICKNESS = 'glassThickness',
  VALUEKEY_OPENING_ANGLE = 'openingAngle',
  VALUEKEY_OPENING_STROKE = 'openingStroke',
  VALUEKEY_WIND_LOAD = 'windLoad',
  VALUEKEY_SNOW_LOAD = 'snowLoad',
  VALUEKEY_ASSEMBLY_TYPE = 'assemblyType',
  VALUEKEY_DRIVE_POSITION = 'drivePosition',
  VALUEKEY_DISTANCE_TO_HINGE = 'distanceToHinge',
  VALUEKEY_PERCENT_OF_DISTANCE_TO_HINGE = 'percentOfDistanceToHinge',
  VALUEKEY_NUMBER_OF_DRIVES = 'numberOfDrives',
  VALUEKEY_VOLTAGE = 'voltage',
  VALUEKEY_DRIVE_TYPE = 'driveType',
  VALUEKEY_DRIVE_SERIES = 'driveSeries',
  VALUEKEY_EXHAUST_DIMENSION = 'exhaustDimension',
  VALUEKEY_BUILDING_DEPTH = 'buildingDepth',
  VALUEKEY_GEOMETRIC_AREA = 'geometricArea',
  VALUEKEY_ASSEMBLY_SPACE_FRAME = 'assemblySpaceFrame',
  VALUEKEY_ASSEMBLY_SPACE_SASH_PROFILE = 'assemblySpaceSashProfile',
  VALUEKEY_NONE = '',
  PROFILE_ENTRY = 'systemEntry',
  FREE_ENTRY = 'freeEntry',
  VALUEKEY_TYPE_OF_PROFILE_INPUT = 'typeOfProfileDataInput',
  PROFILE_SYSTEM_ID = 'profileSystemId',
  PROFILE_SYSTEM = 'profileSystem',
  PROFILE_SERIES_ID = 'profileSeriesId',
  PROFILE_SERIES = 'profileSeries',
  PROFILE_SASH_ID = 'profileSashId',
  PROFILE_SASH = 'profileSash',
  PROFILE_FRAME_ID = 'profileFrameId',
  PROFILE_FRAME = 'profileFrame',
  PROFILE_EXCHANGE_ID = 'profileExchangeId',
  PROFILE_EXCHANGE = 'profileExchange',
  PROFILE_BASE_ID = 'profileBaseId',
  PROFILE_BASE = 'profileBase',
  LOCKING_DRIVE = 'lockingDrive',
  CORRECTION_FACTOR = 'correctionFactor',
  CORRECTION_FACTOR_DESCRIPTION = 'correctionFactorDescription',
  SIDE_WIND_CONSIDERATION = 'sideWindConsideration',
  APPLICATION = 'application',
  MOUNTING_TYPE = 'mountingType',
  AERODYNAMIC_CASE = 'aerodynamicCase',
  AREA = 'area',
  SASH_FRAME_DISTANCE = 'sashFrameDistance',
  RATIO_WIDTH_TO_DOUBLE_FLAP_HEIGHT = 'ratioWidthToDoubleFlapHeight',
  RATIO_SASH_WIDTH_TO_SASH_HEIGHT = 'ratioSashWidthToSashHeight',
  RATIO_SASH_HEIGHT_TO_SASH_WIDTH = 'ratioSashHeightToSashWidth',
  RATIO_CLEAR_WIDTH_TO_CLEAR_HEIGHT = 'ratioClearWidthToClearHeight',
  RATIO_CLEAR_HEIGHT_TO_CLEAR_WIDTH = 'ratioClearHeightToClearWidth',
  OPENING_OPTION = 'openingOption',
  WEIGHT_OPTION = 'weightOption',
  REVEAL = 'reveal',
  REVEAL_OPPOSITE_THE_HINGE = 'revealOppositeTheHinge',
  DISTANCE_SASH_TO_REVEAL = 'distanceSashToReveal',
  REVEAL_STRUCTURE = 'revealStructure',
  REVEAL_TRIANGLE_ONE = 'revealTriangleOne',
  REVEAL_TRIANGLE_TWO = 'revealTriangleTwo',
  OPENING_SIDE = 'openingSide',
}

export const CONSOLE_PARAMETERS = [
  ValueKey.VALUEKEY_ASSEMBLY_SPACE_FRAME,
  ValueKey.VALUEKEY_ASSEMBLY_SPACE_SASH_PROFILE,
  ValueKey.PROFILE_SYSTEM_ID,
  ValueKey.PROFILE_SYSTEM,
  ValueKey.PROFILE_SERIES_ID,
  ValueKey.PROFILE_SERIES,
  ValueKey.PROFILE_SASH_ID,
  ValueKey.PROFILE_SASH,
  ValueKey.PROFILE_FRAME_ID,
  ValueKey.PROFILE_FRAME,
  ValueKey.PROFILE_EXCHANGE_ID,
  ValueKey.PROFILE_EXCHANGE,
  ValueKey.PROFILE_BASE_ID,
  ValueKey.PROFILE_BASE,
  ValueKey.VALUEKEY_TYPE_OF_PROFILE_INPUT,
];

const EXCLUSIVE_DESMOKING_PARAMETERS = [
  ValueKey.VALUEKEY_EXHAUST_DIMENSION,
  ValueKey.VALUEKEY_BUILDING_DEPTH,
  ValueKey.VALUEKEY_GEOMETRIC_AREA,
  ValueKey.VALUEKEY_TYPE_OF_PROFILE_INPUT,
  ValueKey.REVEAL,
  ValueKey.REVEAL_OPPOSITE_THE_HINGE,
  ValueKey.DISTANCE_SASH_TO_REVEAL,
  ValueKey.REVEAL_STRUCTURE,
  ValueKey.REVEAL_TRIANGLE_ONE,
  ValueKey.REVEAL_TRIANGLE_TWO,
];

export const DESMOKING_PARAMETERS = [
  ...EXCLUSIVE_DESMOKING_PARAMETERS,
  ValueKey.VALUEKEY_SASH_HEIGHT,
  ValueKey.VALUEKEY_SASH_WIDTH,
  ValueKey.PROFILE_SERIES,
  ValueKey.PROFILE_SYSTEM,
];

export const NRWG_PARAMETERS = [
  ValueKey.PROFILE_SYSTEM,
  ValueKey.PROFILE_SERIES,
  ValueKey.VALUEKEY_OPENING_DIRECTION,
  ValueKey.SIDE_WIND_CONSIDERATION,
  ValueKey.APPLICATION,
  ValueKey.MOUNTING_TYPE,
  ValueKey.AERODYNAMIC_CASE,
  ValueKey.VALUEKEY_RANGE_OF_APPLICATION,
  ValueKey.VALUEKEY_DRIVE_POSITION,
];

export const REQUIRED_NRWG_PARAMETERS_FACADE = _.difference(NRWG_PARAMETERS, [
  ValueKey.VALUEKEY_RANGE_OF_APPLICATION,
  ValueKey.AERODYNAMIC_CASE,
  ValueKey.SIDE_WIND_CONSIDERATION, //Because its filled by default
  ValueKey.PROFILE_SASH,
  ValueKey.PROFILE_FRAME,
  ValueKey.PROFILE_EXCHANGE,
  ValueKey.PROFILE_BASE,
]);
export const REQUIRED_NRWG_PARAMETERS_ROOF = _.difference(NRWG_PARAMETERS, [
  ValueKey.VALUEKEY_RANGE_OF_APPLICATION,
  ValueKey.VALUEKEY_OPENING_DIRECTION, //Because its filled by default
  ValueKey.MOUNTING_TYPE,
  ValueKey.SIDE_WIND_CONSIDERATION, //Because its filled by default
  ValueKey.PROFILE_SASH,
  ValueKey.PROFILE_FRAME,
  ValueKey.PROFILE_EXCHANGE,
  ValueKey.PROFILE_BASE,
]);

export const DRIVE_FILTER_PARAMETERS = [
  ValueKey.VALUEKEY_DRIVE_TYPE,
  ValueKey.VALUEKEY_VOLTAGE,
  ValueKey.VALUEKEY_DRIVE_SERIES,
];

export const CALCULATION_PARAMETERS_NRWG = _.difference(
  Object.values(ValueKey),
  EXCLUSIVE_DESMOKING_PARAMETERS.concat(CONSOLE_PARAMETERS).concat([
    ValueKey.VALUEKEY_NONE,
    ValueKey.PROFILE_ENTRY,

    ValueKey.FREE_ENTRY,
    ValueKey.AREA,
    ValueKey.CORRECTION_FACTOR_DESCRIPTION,
    ValueKey.RATIO_WIDTH_TO_DOUBLE_FLAP_HEIGHT,
    ValueKey.RATIO_SASH_WIDTH_TO_SASH_HEIGHT,
    ValueKey.RATIO_SASH_HEIGHT_TO_SASH_WIDTH,
    ValueKey.RATIO_CLEAR_WIDTH_TO_CLEAR_HEIGHT,
    ValueKey.RATIO_CLEAR_HEIGHT_TO_CLEAR_WIDTH,
    ValueKey.OPENING_SIDE,
  ]),
).concat([
  ValueKey.PROFILE_SYSTEM,
  ValueKey.PROFILE_SERIES,
  ValueKey.PROFILE_SASH,
  ValueKey.PROFILE_FRAME,
  ValueKey.PROFILE_EXCHANGE,
  ValueKey.PROFILE_BASE,
  ValueKey.PROFILE_SYSTEM,
  ValueKey.VALUEKEY_TYPE_OF_PROFILE_INPUT,
]);

export const REQUIRED_CALCULATION_PARAMETERS_NRWG = _.difference(
  CALCULATION_PARAMETERS_NRWG,
  DRIVE_FILTER_PARAMETERS,
);

export const CALCULATION_PARAMETERS_ROOF_NRWG = _.difference(
  CALCULATION_PARAMETERS_NRWG,
  [ValueKey.MOUNTING_TYPE],
);

export const REQUIRED_CALCULATION_PARAMETERS_ROOF_DOUBLE_FLAP_NRWG =
  _.difference(REQUIRED_CALCULATION_PARAMETERS_NRWG, [
    ValueKey.MOUNTING_TYPE,
    ValueKey.PROFILE_EXCHANGE,
    ValueKey.PROFILE_BASE,
  ]);

export const REQUIRED_CALCULATION_PARAMETERS_ROOF_SINGLE_FLAP_NRWG =
  _.difference(REQUIRED_CALCULATION_PARAMETERS_ROOF_DOUBLE_FLAP_NRWG, [
    ValueKey.SASH_FRAME_DISTANCE,
  ]);

export const CALCULATION_PARAMETERS_FACADE_NRWG = _.difference(
  CALCULATION_PARAMETERS_ROOF_NRWG,
  [
    ValueKey.VALUEKEY_SNOW_LOAD,
    ValueKey.AERODYNAMIC_CASE,
    ValueKey.SIDE_WIND_CONSIDERATION,
    ValueKey.SASH_FRAME_DISTANCE,
  ],
);

export const REQUIRED_CALCULATION_PARAMETERS_FACADE_NRWG = [
  ..._.difference(REQUIRED_CALCULATION_PARAMETERS_ROOF_DOUBLE_FLAP_NRWG, [
    ValueKey.VALUEKEY_SNOW_LOAD,
    ValueKey.AERODYNAMIC_CASE,
  ]),
  ValueKey.PROFILE_EXCHANGE,
  ValueKey.PROFILE_BASE,
];

export function getRequiredNrwgParameters(
  rangeOfApplication: RangeOfApplication,
): ValueKey[] {
  return rangeOfApplication === RangeOfApplication.ROOF
    ? REQUIRED_NRWG_PARAMETERS_ROOF
    : REQUIRED_NRWG_PARAMETERS_FACADE;
}

const ROOF_NRWG_TEST_SEARCH_PARAMETERS = [
  ValueKey.SIDE_WIND_CONSIDERATION,
  ValueKey.APPLICATION,
  ValueKey.AERODYNAMIC_CASE,
  ValueKey.PROFILE_SYSTEM,
  ValueKey.PROFILE_SERIES,
  ValueKey.PROFILE_SASH,
  ValueKey.PROFILE_FRAME,
  ValueKey.PROFILE_EXCHANGE,
  ValueKey.PROFILE_BASE,
];

export const CALCULATION_PARAMETERS_ROOF = _.difference(
  CALCULATION_PARAMETERS_ROOF_NRWG,
  ROOF_NRWG_TEST_SEARCH_PARAMETERS.concat(ValueKey.SASH_FRAME_DISTANCE),
).concat(ValueKey.PROFILE_SERIES, ValueKey.PROFILE_SYSTEM);

export const REQUIRED_CALCULATION_PARAMETERS_ROOF = _.difference(
  REQUIRED_CALCULATION_PARAMETERS_ROOF_DOUBLE_FLAP_NRWG,
  ROOF_NRWG_TEST_SEARCH_PARAMETERS.concat(ValueKey.SASH_FRAME_DISTANCE),
);

export const CALCULATION_PARAMETERS_FACADE = _.difference(
  CALCULATION_PARAMETERS_ROOF,
  [ValueKey.VALUEKEY_SNOW_LOAD],
).concat([ValueKey.OPENING_SIDE]);

export const REQUIRED_CALCULATION_PARAMETERS_FACADE = _.difference(
  REQUIRED_CALCULATION_PARAMETERS_ROOF,
  [ValueKey.VALUEKEY_SNOW_LOAD],
);

export function isDoubleFlapApplication(s: IState): boolean {
  return DOUBLE_FLAP_APPLICATIONS.includes(
    // @ts-ignore
    Application[s.parameters.application.value as any],
  );
}

export function notAllRequiredCalculationParametersDefined(
  state: IState,
): boolean {
  const requiredParameters = getRequiredDriveCalculationParameters(state);
  return !_.every(requiredParameters, c => {
    return !state.parameters[c].isUndefined();
  });
}

function excludeRequiredValuesWhichAreCalculatedOnServer(
  state: IState,
  requiredParameters: ValueKey[],
): ValueKey[] {
  const excludedOpeningParameter =
    state.parameters.openingOption.value === ValueKey.VALUEKEY_OPENING_ANGLE
      ? ValueKey.VALUEKEY_OPENING_STROKE
      : ValueKey.VALUEKEY_OPENING_ANGLE;
  const excludedWeightParameter =
    state.parameters.weightOption.value === ValueKey.VALUEKEY_SASH_WEIGHT
      ? ValueKey.VALUEKEY_GLAS_THICKNESS
      : ValueKey.VALUEKEY_SASH_WEIGHT;

  return requiredParameters.filter(
    r => r !== excludedOpeningParameter && r !== excludedWeightParameter,
  );
}

function getRequiredDriveCalculationParameters(state: IState): ValueKey[] {
  const selectedWindow = getSelectedWindow(state);

  let requiredParameters: ValueKey[];

  if (state.parameters.rangeOfApplication.value === RangeOfApplication.FACADE) {
    requiredParameters = _.clone(
      selectedWindow?.nrwg
        ? REQUIRED_CALCULATION_PARAMETERS_FACADE_NRWG
        : REQUIRED_CALCULATION_PARAMETERS_FACADE,
    );
  } else {
    if (selectedWindow?.nrwg) {
      if (
        DOUBLE_FLAP_APPLICATIONS.includes(
          // @ts-ignore
          Application[state.parameters.application.value as any],
        )
      ) {
        requiredParameters = _.clone(
          REQUIRED_CALCULATION_PARAMETERS_ROOF_DOUBLE_FLAP_NRWG,
        );
      } else {
        requiredParameters = _.clone(
          REQUIRED_CALCULATION_PARAMETERS_ROOF_SINGLE_FLAP_NRWG,
        );
      }
    } else {
      requiredParameters = _.clone(REQUIRED_CALCULATION_PARAMETERS_ROOF);
    }
  }

  if (selectedWindow?.rwa) {
    requiredParameters.push(ValueKey.VALUEKEY_GEOMETRIC_AREA);
    requiredParameters.push(ValueKey.REVEAL);

    if (state.parameters.reveal.value === RevealDepth.WITH_REVEAL) {
      requiredParameters.push(ValueKey.REVEAL_STRUCTURE);
      requiredParameters.push(ValueKey.DISTANCE_SASH_TO_REVEAL);
      requiredParameters.push(ValueKey.REVEAL_OPPOSITE_THE_HINGE);

      if (
        state.parameters.revealStructure.value === RevealStructure.DIFFERENT
      ) {
        if (
          state.parameters.geometricArea.value ===
          GeometricArea.ONE_TRIANGLES_RECTANGLE
        ) {
          requiredParameters.push(ValueKey.REVEAL_TRIANGLE_ONE);
        }
        if (
          state.parameters.geometricArea.value ===
          GeometricArea.TWO_TRIANGLES_RECTANGLE
        ) {
          requiredParameters.push(ValueKey.REVEAL_TRIANGLE_ONE);
          requiredParameters.push(ValueKey.REVEAL_TRIANGLE_TWO);
        }
      }
    }
  }

  if (!isDoubleFlapApplication(state)) {
    requiredParameters = requiredParameters.filter(
      p => p !== ValueKey.SASH_FRAME_DISTANCE,
    );
  }
  requiredParameters = excludeRequiredValuesWhichAreCalculatedOnServer(
    state,
    requiredParameters,
  );

  return requiredParameters;
}

export function getDriveCalculationParameters(
  getState: () => IState,
  selectedWindow: Window | undefined,
): ValueKey[] {
  const requiredParameters =
    getState().parameters.rangeOfApplication.value === RangeOfApplication.FACADE
      ? selectedWindow?.nrwg
        ? CALCULATION_PARAMETERS_FACADE_NRWG
        : CALCULATION_PARAMETERS_FACADE
      : selectedWindow?.nrwg
      ? CALCULATION_PARAMETERS_ROOF_NRWG
      : CALCULATION_PARAMETERS_ROOF;

  if (getSelectedWindow(getState())?.rwa) {
    requiredParameters.push(ValueKey.VALUEKEY_GEOMETRIC_AREA);
  }

  return requiredParameters;
}
