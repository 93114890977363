import React, { FC, useState } from 'react';
import { DialogFE } from '../../Dialog';
import { useDispatch, useSelector } from 'react-redux';
import { IState, MyCalcThunkDispatch } from '../../../redux/store';
import { WindDeflector } from '../../../redux/nrwgReducer';
import { FormattedMessage } from 'react-intl';

import Table from '../../../elements/Table';
import { selectWindDeflector, showDialog } from '../../../redux/uiStateActions';
import { AnyAction } from 'redux';

const AddWindDeflectorDialog: FC<React.PropsWithChildren<unknown>> = () => {
  const windDeflectors = useSelector<IState, WindDeflector[] | undefined>(
    s => s.nrwg.windDeflectors,
  );
  const [markedWindDeflector, setMarkedWindDeflector] = useState<
    WindDeflector | undefined
  >();
  const dispatch: MyCalcThunkDispatch<AnyAction> = useDispatch();

  function select(): void {
    if (markedWindDeflector) {
      dispatch(selectWindDeflector(markedWindDeflector));
      dispatch(showDialog(undefined));
    }
  }

  return (
    <DialogFE
      heading="ADD_WIND_DEFLECTOR_DIALOG_HEADER"
      componentClass="add-wind-deflector-dialog"
      footerProps={{
        cancelAction: true,
        saveAction: markedWindDeflector ? select : undefined,
        primaryActionLabel: 'BUTTON_LABEL_ADD',
      }}
    >
      <Table
        head={{
          columns: [
            <FormattedMessage id="ADD_WIND_DEFLECTOR_TABLE_HEADER_MATERIAL" />,
            <FormattedMessage id="ADD_WIND_DEFLECTOR_TABLE_HEADER_QUANTITY" />,
            <FormattedMessage id="ADD_WIND_DEFLECTOR_TABLE_HEADER_HEIGHT" />,
            <FormattedMessage id="ADD_WIND_DEFLECTOR_TABLE_HEADER_BRACKET" />,
          ],
        }}
        body={
          windDeflectors?.map(wd => {
            return {
              key: wd.glass ? 'glass' : 'sheet',
              selected: wd === markedWindDeflector,
              onClick: () => setMarkedWindDeflector(wd),
              columns: [
                <FormattedMessage
                  id={
                    wd.glass
                      ? 'WIND_DEFLECTOR_GLASS'
                      : 'WIND_DEFLECTOR_SHEET_METAL'
                  }
                />,
                wd.number.toString(),
                wd.height.toString(),
                <Brackets {...wd} />,
              ],
            };
          }) || []
        }
      />
    </DialogFE>
  );
};

const Brackets: FC<React.PropsWithChildren<WindDeflector>> = props => {
  if (props.sheetMetal) {
    return (
      <FormattedMessage id="WIND_DEFLECTOR_RESULTS_CORNER_HOLDER_SHEET_METAL" />
    );
  }

  return (
    <>
      {props.numberOfCornerBracket > 0 && (
        <FormattedMessage
          id="WIND_DEFLECTOR_RESULTS_CORNER_HOLDER_GLASS"
          values={{
            name: props.nameOfCornerBracket,
            quantity: props.numberOfCornerBracket,
          }}
        />
      )}{' '}
      {props.numberOfGlassBracket > 0 && (
        <>
          ,{' '}
          <FormattedMessage
            id="WIND_DEFLECTOR_RESULTS_GLASS_BRACKET"
            values={{
              name: props.nameOfGlassBracket,
              quantity: props.numberOfGlassBracket,
            }}
          />
        </>
      )}
      {props.numberOfMiddleBracket > 0 && (
        <>
          ,{' '}
          <FormattedMessage
            id="WIND_DEFLECTOR_RESULTS_MIDDLE_BRACKET"
            values={{
              name: props.nameOfMiddleBracket,
              quantity: props.numberOfMiddleBracket,
            }}
          />
        </>
      )}{' '}
    </>
  );
};

export default AddWindDeflectorDialog;
