import React, { FC, useEffect, useRef } from 'react';
import { MDCSwitch } from '@material/switch';
import './Switch.scss';
import '@material/switch/styles.scss';
import { FormattedMessage } from 'react-intl';
import _ from 'lodash';
import classNames from 'classnames';

interface SwitchProps {
  label?: string;
  labelText?: string;
  turnedOn: boolean;
  onChange: (active: boolean) => void;
  additionalClassName?: string;
  disabled?: boolean;
}

export const Switch: FC<React.PropsWithChildren<SwitchProps>> = (
  props: SwitchProps,
) => {
  const switchRef = useRef<HTMLButtonElement>(null);
  const mdcSwitchRef = useRef<MDCSwitch>();

  useEffect(() => {
    function toggleOnChange(): void {
      props.onChange(!props.turnedOn);
    }

    if (mdcSwitchRef.current) {
      mdcSwitchRef.current.destroy();
    }

    if (switchRef.current !== null) {
      const switchInstance = new MDCSwitch(switchRef.current);
      mdcSwitchRef.current = switchInstance;
      switchRef.current.addEventListener('click', toggleOnChange);
    }

    return () => {
      if (mdcSwitchRef.current) {
        mdcSwitchRef.current.destroy();
      }
      if (switchRef.current) {
        switchRef.current.removeEventListener('click', toggleOnChange);
      }
    };
  }, [switchRef.current, props.onChange, props.turnedOn]);

  return (
    <>
      <button
        className={classNames(
          'switch mdc-switch',
          {
            'mdc-switch--unselected': !props.turnedOn,
            'mdc-switch--selected': props.turnedOn,
          },
          props.additionalClassName,
        )}
        type="button"
        role="switch"
        aria-checked={props.turnedOn}
        ref={switchRef}
        disabled={props.disabled}
      >
        <div className="mdc-switch__track"></div>
        <div className="mdc-switch__handle-track">
          <div className="mdc-switch__handle">
            <div className="mdc-switch__shadow">
              <div className="mdc-elevation-overlay"></div>
            </div>
            <div className="mdc-switch__ripple"></div>
            <div className="mdc-switch__icons">
              <svg
                className="mdc-switch__icon mdc-switch__icon--on"
                viewBox="0 0 24 24"
              >
                <path d="M19.69,5.23L8.96,15.96l-4.23-4.23L2.96,13.5l6,6L21.46,7L19.69,5.23z" />
              </svg>
              <svg
                className="mdc-switch__icon mdc-switch__icon--off"
                viewBox="0 0 24 24"
              >
                <path d="M20 13H4v-2h16v2z" />
              </svg>
            </div>
          </div>
        </div>
      </button>
      {(props.label || props.labelText) && (
        <label htmlFor={_.camelCase(props.label)}>
          {props.label ? (
            <FormattedMessage id={props.label} />
          ) : (
            props.labelText
          )}
        </label>
      )}
    </>
  );
};
