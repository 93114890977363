import React, { FC, ReactElement, useState } from 'react';
import FormLayout from '../../elements/FormLayout';
import InputField, { InputFieldNumber } from '../../elements/InputField';
import './CVDataView.scss';
import { SelectField, SelectOption } from '../../elements/SelectField';
import { useDispatch, useSelector } from 'react-redux';
import Dialog from '../../components/Dialog';
import Table from '../components/Table';
import TableHeader from '../elements/TableHeader';
import TableRow from '../elements/TableRow';
import SearchField from '../../elements/SearchField';
import Pagination from '../components/Pagination';
import {
  CVKurve,
  CVWert,
  Kurvensatz,
} from '../../redux/admin/adminFacadeReducer';
import { AdminState, AdminThunkDispatch } from '../../redux/admin/adminStore';
import {
  COMPARE_OPTIONS,
  DEFAULT_CURVE_SET,
  DESCRIPTION_OPTIONS,
  UiConstants,
} from '../constants';
import {
  changeCvCurve,
  createCvCurve,
} from '../../redux/admin/adminFacadeActions';
import { RangeOfApplication } from '../../redux/constants';
import _ from 'lodash';
import { containingInSearchString, fieldsFilled } from './helpers';
import { AnyAction } from 'redux';
import { CSVInput } from '../../elements/CSVInput';

interface AdminCVDataDialogContentProps {
  name: string;
  setName: (s: string) => void;
  curveSets: Kurvensatz[];
  setCurveSets: (curveSets: Kurvensatz[]) => void;
}

function AdminCVDataDialogContent(
  props: AdminCVDataDialogContentProps,
): ReactElement {
  const [editedCurveSet, setEditedCurveSet] = useState<Kurvensatz>(
    props.curveSets[0],
  );

  function valueAsSelectOption(
    value: string,
    options: SelectOption<string>[],
  ): SelectOption<string> | undefined {
    return options.find(entry => entry.value === value);
  }

  function getCompareOperator(input: string): string | undefined {
    return COMPARE_OPTIONS.find(o => o.value === input)?.label;
  }

  function filterNonDefinedValues(
    curveSetMember: string | number | undefined,
  ): string {
    if (
      curveSetMember === 'NaN' ||
      curveSetMember === undefined ||
      curveSetMember === null
    ) {
      return '';
    }
    return curveSetMember.toString();
  }

  function cvCurveToString(k: Kurvensatz): string {
    function translateDescription(description: string): string | undefined {
      return DESCRIPTION_OPTIONS.find(o => o.value === description)?.label;
    }

    return `${filterNonDefinedValues(k.value1)} ${filterNonDefinedValues(
      getCompareOperator(k.compare1),
    )} ${filterNonDefinedValues(
      translateDescription(k.description),
    )} ${filterNonDefinedValues(
      getCompareOperator(k.compare2),
    )} ${filterNonDefinedValues(k.value2)}`;
  }

  function cvValuesToString(cvValues: CVWert[] | undefined): string {
    if (cvValues === undefined) {
      return '';
    }

    return cvValues
      .map(c => `${c.grad?.toString()} ${c.cvDurchflusswert?.toString()}\n`)
      .toString()
      .replaceAll(',', '');
  }

  function deleteCurveSet(deletedCurveset: Kurvensatz): void {
    const updateCurveSets = props.curveSets.filter(
      cs => cs !== deletedCurveset,
    );
    props.setCurveSets(updateCurveSets);
    setEditedCurveSet(props.curveSets[0]);
  }

  function newDefaultCurveSet(): Kurvensatz {
    return _.cloneDeep(DEFAULT_CURVE_SET);
  }

  function newCurveSet(): void {
    const newCurveSet = newDefaultCurveSet();
    props.setCurveSets([...props.curveSets, newCurveSet]);
    setEditedCurveSet(newCurveSet);
  }

  function updateCurveSet(
    updatedValues: Record<string, number | string | null | CVWert[]>,
  ): void {
    const updatedCurveSet = {
      ...editedCurveSet,
      ...updatedValues,
    } as Kurvensatz;
    setEditedCurveSet(updatedCurveSet);

    const updadedCurveSets = _.cloneDeep(props.curveSets);
    const index = updadedCurveSets.findIndex(
      cs => cs.value1 === editedCurveSet?.value1 && cs.value2 === cs.value2,
    );
    updadedCurveSets[index] = updatedCurveSet;
    props.setCurveSets(updadedCurveSets);
  }

  return (
    <FormLayout additionalClass="cv-data-dialog">
      <InputField
        key="nameField"
        label="Name *"
        placeholder="Name der CV-Kurve"
        value={props.name}
        id="22"
        additionalClass="cv-data-dialog__name"
        onChange={props.setName}
      />
      <div className="cv-data-dialog__add-curve">
        <div className="cv-data-dialog__add-curve-title">
          <div className="cv-data-dialog__title-number">2</div>
          Kurve bearbeiten
        </div>
        <div className="cv-data-dialog__add-curve-content">
          <InputFieldNumber
            disabled={!editedCurveSet}
            key="value1"
            label="value1"
            placeholder="z.B. 42"
            value={editedCurveSet?.value1}
            id="9"
            onChange={v => {
              updateCurveSet({
                value1: v,
              });
            }}
          />
          <SelectField
            disabled={!editedCurveSet}
            key={1}
            label=""
            value={
              editedCurveSet
                ? valueAsSelectOption(editedCurveSet.compare1!, COMPARE_OPTIONS)
                : undefined
            }
            action={newValue => {
              updateCurveSet({
                compare1: newValue.value,
              });
            }}
            options={COMPARE_OPTIONS}
            name="project-country"
            searchable={true}
            placeholder=""
          />
          <SelectField
            disabled={!editedCurveSet}
            key={2}
            label=""
            value={
              editedCurveSet
                ? valueAsSelectOption(
                    editedCurveSet.description,
                    DESCRIPTION_OPTIONS,
                  )
                : undefined
            }
            action={newValue => {
              updateCurveSet({
                description: newValue.value,
              });
            }}
            options={DESCRIPTION_OPTIONS}
            name="project-country"
            searchable={true}
            placeholder=""
          />
          <SelectField
            disabled={!editedCurveSet}
            key={3}
            label=" "
            value={
              editedCurveSet
                ? valueAsSelectOption(editedCurveSet.compare2, COMPARE_OPTIONS)
                : undefined
            }
            action={newValue => {
              updateCurveSet({
                compare2: newValue.value,
              });
            }}
            options={COMPARE_OPTIONS}
            name="project-country"
            searchable={true}
            placeholder=""
          />{' '}
          <InputFieldNumber
            disabled={!editedCurveSet}
            key="value2"
            label="value2"
            placeholder="z.B. 42"
            value={filterNonDefinedValues(editedCurveSet?.value2)}
            id="8"
            onChange={v => {
              updateCurveSet({
                // @ts-ignore
                value2: v === '' ? null : v,
              });
            }}
          />
        </div>
      </div>
      <div className="cv-data-dialog__curve">
        <div className="cv-data-dialog__curve-title">
          <div>
            <div className="cv-data-dialog__title-number">1</div>
            Kurven
          </div>
          <div>
            <button
              type="button"
              onClick={() => {
                deleteCurveSet(editedCurveSet!);
              }}
            >
              remove
            </button>
            <button type="button" onClick={() => newCurveSet()}>
              add
            </button>
          </div>
        </div>{' '}
        <div className="cv-data-dialog__curve-content">
          <select
            size={Math.max(2, props.curveSets.length)}
            className="cv-data-dialog__curve-select"
            onChange={e => {
              setEditedCurveSet(props.curveSets[+e.target.value]);
            }}
            value={editedCurveSet && props.curveSets.indexOf(editedCurveSet)}
          >
            {props.curveSets?.map((k, index) => (
              <option key={index} value={index}>
                {cvCurveToString(k)}
              </option>
            ))}
          </select>
        </div>
      </div>
      {editedCurveSet && (
        <div className="cv-data-dialog__values">
          <div className="cv-data-dialog__values-title">
            <div className="cv-data-dialog__title-number">3</div>
            Wertetabelle
          </div>

          <CSVInput
            disabled={!editedCurveSet}
            value={cvValuesToString(editedCurveSet?.cvwerte)}
            onChange={(v: CVWert[]) => {
              updateCurveSet({
                cvwerte: v,
              });
            }}
            arrayToObject={pair => ({
              grad: pair[0],
              cvDurchflusswert: pair[1],
            })}
          />
        </div>
      )}
    </FormLayout>
  );
}

interface AdminCVDataDialogProps {
  hideDialog: () => void;
  dialogIsShown: boolean;
  selectedCVKurve: CVKurve;
  rangeOfApplication: RangeOfApplication;
}

const AdminCVDataDialog: FC<React.PropsWithChildren<AdminCVDataDialogProps>> = (
  props: AdminCVDataDialogProps,
) => {
  const dispatch: AdminThunkDispatch<AnyAction> = useDispatch();

  const [name, setName] = useState(props.selectedCVKurve?.name || '');

  const [curveSets, setCurveSets] = useState<Kurvensatz[]>(
    _.cloneDeep(props.selectedCVKurve?.kurvensaetze || []),
  );

  function newCvCurve(): void {
    dispatch(
      createCvCurve({
        kurvensaetze: curveSets,
        name: name,
        rangeOfApplication: props.rangeOfApplication,
      }),
    );
  }

  function editCvCurve(): void {
    dispatch(
      changeCvCurve({
        ...props.selectedCVKurve!,
        kurvensaetze: curveSets!,
        name: name,
        rangeOfApplication: props.rangeOfApplication,
      }),
    );
  }

  function curveSetValid(curveSet: Kurvensatz): boolean {
    return curveSet.cvwerte.length > 0;
  }

  function mandatoryFieldsFilled(): boolean {
    return (
      fieldsFilled(name) &&
      curveSets.length > 0 &&
      curveSets.every(curveSetValid)
    );
  }

  return (
    <Dialog
      setDialogIsShown={b => props.hideDialog()}
      dialogIsShown={props.dialogIsShown}
      componentClass="full-view-dialog"
      headingText={
        props.selectedCVKurve ? 'CV-Kurve bearbeiten' : 'CV-Kurve anlegen'
      }
      key={props.dialogIsShown.toString()}
      footerProps={{
        notTranslated: true,
        cancelAction: () => {
          props.hideDialog();
        },
        saveAction: mandatoryFieldsFilled()
          ? () => {
              props.selectedCVKurve ? editCvCurve() : newCvCurve();
              props.hideDialog();
            }
          : undefined,
        primaryActionLabelText:
          props.selectedCVKurve === undefined
            ? UiConstants.ADD
            : UiConstants.SAVE,
      }}
    >
      <AdminCVDataDialogContent
        name={name}
        setName={setName}
        curveSets={curveSets}
        setCurveSets={setCurveSets}
      />
    </Dialog>
  );
};

const CVDataView: FC<
  React.PropsWithChildren<{
    rangeOfApplication: RangeOfApplication;
  }>
> = props => {
  const [dialogIsShown, setDialogIsShown] = useState(false);
  const [searchString, setSearchString] = useState('');
  const [page, setPage] = useState(1);
  const [indexOfFirstPageElement, setIndexOfFirstPageElement] = useState(0);
  const cvKurven = useSelector<AdminState, CVKurve[]>(state =>
    props.rangeOfApplication === RangeOfApplication.FACADE
      ? state.adminFacade.cvKurven
      : state.adminRoof.cvKurven,
  );

  const [selectedCVKurveID, setSelectedCVKurveID] = useState<
    undefined | number
  >();

  const selectedCVKurve = cvKurven.find(
    b => b.id === selectedCVKurveID || undefined,
  );

  function hideDialog(): void {
    setDialogIsShown(false);
    setSelectedCVKurveID(undefined);
  }

  function searchByMultipleSubstrings(): CVKurve[] {
    const splittedSearchString = searchString.split(/\s+/);

    return cvKurven.filter(cv =>
      containingInSearchString(cv, splittedSearchString, [cv.name]),
    );
  }

  function getCurrentTableContent(): CVKurve[] {
    return searchByMultipleSubstrings().slice(
      indexOfFirstPageElement,
      indexOfFirstPageElement + 20,
    );
  }

  return (
    <>
      {dialogIsShown && (
        <AdminCVDataDialog
          hideDialog={hideDialog}
          dialogIsShown={dialogIsShown}
          selectedCVKurve={selectedCVKurve!}
          rangeOfApplication={props.rangeOfApplication}
          key={selectedCVKurve?.id?.toString()}
        />
      )}

      <div className="sub-header">
        <div className="sub-header__title"> CV-Kurven</div>
        <SearchField
          setSearchString={setSearchString}
          searchString={searchString}
          placeholderText="CV-Kurve suchen..."
          small={true}
        />
        <button
          className="sub-header__button sub-header__button--add"
          onClick={() => setDialogIsShown(true)}
        >
          Neuer Eintrag
        </button>
      </div>
      <div className="cv-data">
        <Table>
          <TableHeader>
            <th>{UiConstants.NAME}</th>
            <th>{UiConstants.EDIT}</th>
          </TableHeader>
          <tbody>
            {getCurrentTableContent().map(cv => (
              <TableRow>
                <td>{cv.name}</td>
                <td>
                  <button
                    onClick={() => {
                      setSelectedCVKurveID(cv.id);
                      setDialogIsShown(true);
                    }}
                  >
                    Bearbeiten
                  </button>
                </td>
              </TableRow>
            ))}
          </tbody>
        </Table>
      </div>
      <Pagination
        searchString={searchString}
        numberOfPages={searchByMultipleSubstrings().length}
        page={page}
        setPage={setPage}
        indexOfFirstPageElement={indexOfFirstPageElement}
        setIndexOfFirstPageElement={setIndexOfFirstPageElement}
      />
    </>
  );
};
export default CVDataView;
