import React, { FC, ReactNode, useCallback, useEffect, useRef } from 'react';
import '@material/dialog/mdc-dialog.scss';
import './Dialog.scss';
import './ConfirmationDialog.scss';
import { MDCDialog } from '@material/dialog';
import { FormattedMessage } from 'react-intl';
import { MDCCheckbox } from '@material/checkbox';
import {
  showDialog,
  updateDownloadStatus,
  updateOverlayActive,
} from '../redux/uiStateActions';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import Button, { ButtonType } from '../elements/Button';
import { conditionalClass, getModifyClass } from '../elements/element_utils';
import { standardHeaders } from '../redux/httpClient';
import { IState, MyCalcThunkDispatch } from '../redux/store';
import { AnyAction } from 'redux';

interface DialogProps {
  heading?: string;
  headingText?: string;
  setDialogIsShown: (b: boolean) => void;
  dialogIsShown: boolean;
  dialogByline?: string;
  withNavigation?: boolean;
  isConfirmation?: boolean;
  children: ReactNode;
  componentClass: string;
  cancelAction?: () => void;
  footerProps?: FooterProps;
  nested?: boolean;
}

interface NewFooterProps {
  saveAction: (() => void) | undefined;
  primaryActionLabel?: string;
  primaryActionLabelText?: string;
  saveLink?: string;
  isConfirmation?: boolean;
  notTranslated?: boolean;
  cancelAction: boolean;
}

const NewFooter: FC<React.PropsWithChildren<NewFooterProps>> = (
  props: NewFooterProps,
) => {
  const dispatch: MyCalcThunkDispatch<AnyAction> = useDispatch();
  function fetchTokenAndTriggerDownload(): void {
    standardHeaders().then(headers =>
      window
        .fetch('/downloadToken', { headers: headers })
        .then(r => r.json())
        .then(token => {
          const link = document.querySelector(
            '#download-link',
          ) as HTMLAnchorElement;
          const href = link!.getAttribute('href')!.replace(/&token=.*/, '');

          if (href.match('assemblyType')) {
            link!.setAttribute('href', href + `&token=${token.token}`);
          }
          link!.click();
        }),
    );
  }

  return (
    <div className="dialog__footer">
      {props.cancelAction && (
        <Button
          action={() => dispatch(showDialog(undefined))}
          label={props.notTranslated ? undefined : 'global_cancel'}
          labelText={props.notTranslated ? 'Abbrechen' : undefined}
          type={ButtonType.FRAME}
          disabled={false}
          additionalClass="dialog__button"
        />
      )}

      {!props.saveLink ? (
        <Button
          action={props.saveAction}
          label={props.primaryActionLabel}
          labelText={props.primaryActionLabelText}
          type={ButtonType.PRIMARY}
          disabled={!props.saveAction}
          additionalClass="dialog__button dialog__button--primary"
        />
      ) : window.keyCloak?.loggedIn ? (
        <>
          <Button
            action={() => {
              fetchTokenAndTriggerDownload();
            }}
            label={props.primaryActionLabel}
            type={ButtonType.PRIMARY}
            disabled={false}
            additionalClass="dialog__button dialog__button--primary"
          />
          <a
            id="download-link"
            href={props.saveLink}
            onClick={props.saveAction}
            download
            target="_blank"
            rel="noopener noreferrer"
          >
            <FormattedMessage id={props.primaryActionLabel} />
          </a>
        </>
      ) : (
        <a
          onClick={props.saveAction}
          href={props.saveLink}
          download
          target="_blank"
          rel="noopener noreferrer"
          className={classNames(
            'button button--primary dialog__button dialog__button--primary',
            {
              'button--disabled': !props.saveLink,
              'button--primary': props.saveLink,
            },
          )}
        >
          <FormattedMessage id={props.primaryActionLabel} />
        </a>
      )}
    </div>
  );
};

interface FooterProps {
  cancelAction?: () => void;
  saveAction: (() => void) | undefined;
  primaryActionLabel?: string;
  primaryActionLabelText?: string;
  saveLink?: string;
  isConfirmation?: boolean;
  notTranslated?: boolean;
}

const Footer: FC<React.PropsWithChildren<FooterProps>> = (
  props: FooterProps,
) => {
  function fetchTokenAndTriggerDownload(): void {
    standardHeaders().then(headers =>
      window
        .fetch('/downloadToken', { headers: headers })
        .then(r => r.json())
        .then(token => {
          const link = document.querySelector(
            '#download-link',
          ) as HTMLAnchorElement;
          const href = link!.getAttribute('href')!.replace(/&token=.*/, '');

          if (href.match('assemblyType')) {
            link!.setAttribute('href', href + `&token=${token.token}`);
          }
          link!.click();
        }),
    );
  }

  return (
    <div className="dialog__footer">
      {props.cancelAction && (
        <Button
          action={props.cancelAction}
          label={props.notTranslated ? undefined : 'global_cancel'}
          labelText={props.notTranslated ? 'Abbrechen' : undefined}
          type={ButtonType.FRAME}
          disabled={false}
          additionalClass="dialog__button"
        />
      )}

      {!props.saveLink ? (
        <Button
          action={props.saveAction}
          label={props.primaryActionLabel}
          labelText={props.primaryActionLabelText}
          type={ButtonType.PRIMARY}
          disabled={!props.saveAction}
          additionalClass="dialog__button dialog__button--primary"
        />
      ) : window.keyCloak?.loggedIn ? (
        <>
          <Button
            action={() => {
              fetchTokenAndTriggerDownload();
            }}
            label={props.primaryActionLabel}
            type={ButtonType.PRIMARY}
            disabled={false}
            additionalClass="dialog__button dialog__button--primary"
          />
          <a
            id="download-link"
            href={props.saveLink}
            onClick={props.saveAction}
            download
            target="_blank"
            rel="noopener noreferrer"
          >
            <FormattedMessage id={props.primaryActionLabel} />
          </a>
        </>
      ) : (
        <a
          onClick={props.saveAction}
          href={props.saveLink}
          download
          target="_blank"
          rel="noopener noreferrer"
          className={classNames(
            'button button--primary dialog__button dialog__button--primary',
            {
              'button--disabled': !props.saveLink,
              'button--primary': props.saveLink,
            },
          )}
        >
          <FormattedMessage id={props.primaryActionLabel} />
        </a>
      )}
    </div>
  );
};

const modifyClass = getModifyClass('mdc-dialog dialog');

interface NewDialogProps {
  heading?: string;
  headingText?: string;
  dialogByline?: string;
  withNavigation?: boolean;
  isConfirmation?: boolean;
  children: ReactNode;
  componentClass: string;
  footerProps?: NewFooterProps;
  nested?: boolean;
}

export const DialogFE: FC<React.PropsWithChildren<NewDialogProps>> = (
  props: NewDialogProps,
) => {
  const dispatch: MyCalcThunkDispatch<AnyAction> = useDispatch();
  const dialogDOMRef = useRef<HTMLDivElement>(null);
  const dialogRef = useRef<MDCDialog | undefined>();
  const dialog = useSelector<IState, ReactNode>(state => state.ui.dialog);

  useEffect(() => {
    if (dialogRef.current) {
      dialogRef.current.destroy();
    }

    if (dialogDOMRef.current !== null) {
      const dia = new MDCDialog(dialogDOMRef.current!);
      dialogRef.current = dia;

      if (props.isConfirmation) {
        dia.scrimClickAction = '';
        dia.escapeKeyAction = '';
      }

      dia.listen('MDCDialog:closed', () => {
        dispatch(showDialog(undefined));
        dispatch(updateDownloadStatus(false));
        dispatch(updateOverlayActive(false));
      });

      dia.listen('MDCDialog:opened', () => {
        const checkBoxRipples = document.querySelectorAll('.mdc-checkbox');
        checkBoxRipples.forEach(c => {
          const ripple = new MDCCheckbox(c);
          ripple.ripple.layout();
        });
      });

      if (dialog) {
        dia.open();
      }
    }
    return () => {
      if (dialogRef.current) {
        dialogRef.current.destroy();
        dialogRef.current = undefined;
      }
    };
  }, [dispatch]);

  return (
    <div
      onClick={e => e.stopPropagation()}
      className={classNames(
        'mdc-dialog dialog' +
          modifyClass(undefined) +
          conditionalClass(props.componentClass),
        {
          'dialog--dialog-closed': !dialog,
          'dialog--nested': props.nested,
        },
      )}
      ref={dialogDOMRef}
    >
      <div className="mdc-dialog__container dialog__container">
        <div
          className="mdc-dialog__surface"
          role="alertdialog"
          aria-modal="true"
          aria-labelledby="dialog-title"
          aria-describedby="dialog-content"
        >
          {' '}
          {!props.isConfirmation && !props.nested && (
            <button
              data-mdc-dialog-action="close"
              type="button"
              className="mdc-button mdc-dialog__button"
              onClick={() => {
                dispatch(showDialog(undefined));
                dispatch(updateOverlayActive(false));
                dispatch(updateDownloadStatus(false));
              }}
            >
              close
            </button>
          )}
          <header className="dialog__header">
            <div className="dialog__title-byline">
              {props.dialogByline && (
                <FormattedMessage id={props.dialogByline} />
              )}
            </div>

            <h2 className="dialog__title mdc-dialog__title">
              {props.heading ? (
                <FormattedMessage id={props.heading} />
              ) : (
                props.headingText
              )}
            </h2>
          </header>
          <div className="dialog__content">{props.children}</div>
          {props.footerProps && <NewFooter {...props.footerProps} />}
        </div>
      </div>
      {!props.nested && (
        <div
          className={classNames('mdc-dialog__scrim', {
            'mdc-dialog__scrim--scrim-closed': !dialog,
          })}
        />
      )}
    </div>
  );
};

const Dialog: FC<React.PropsWithChildren<DialogProps>> = (
  props: DialogProps,
) => {
  const dispatch: MyCalcThunkDispatch<AnyAction> = useDispatch();
  const dialogRef = useRef<MDCDialog | undefined>();

  const dialogCallback = useCallback(
    (dialog: HTMLDivElement) => {
      if (dialogRef.current) {
        dialogRef.current.destroy();
      }

      if (dialog !== null) {
        const dia = new MDCDialog(dialog);

        if (props.isConfirmation) {
          dia.scrimClickAction = '';
          dia.escapeKeyAction = '';
        }

        dialogRef.current = dia;

        dia.listen('MDCDialog:closed', () => {
          props.setDialogIsShown(false);
          dispatch(updateDownloadStatus(false));
          dispatch(updateOverlayActive(false));
        });

        dia.listen('MDCDialog:opened', () => {
          const checkBoxRipples = document.querySelectorAll('.mdc-checkbox');
          checkBoxRipples.forEach(c => {
            const ripple = new MDCCheckbox(c);
            ripple.ripple.layout();
          });
        });

        if (props.dialogIsShown) {
          dia.open();
        }
      }
    },
    [props.setDialogIsShown, props.dialogIsShown, dispatch],
  );

  return (
    <div
      onClick={e => e.stopPropagation()}
      className={classNames(
        'mdc-dialog dialog' +
          modifyClass(undefined) +
          conditionalClass(props.componentClass),
        {
          'dialog--dialog-closed': !props.dialogIsShown,
          'dialog--nested': props.nested,
        },
      )}
      ref={dialogCallback}
    >
      <div className="mdc-dialog__container dialog__container">
        <div
          className="mdc-dialog__surface"
          role="alertdialog"
          aria-modal="true"
          aria-labelledby="dialog-title"
          aria-describedby="dialog-content"
        >
          {' '}
          {!props.isConfirmation && !props.nested && (
            <button
              data-mdc-dialog-action="close"
              type="button"
              className="mdc-button mdc-dialog__button"
              onClick={() => {
                props.cancelAction && props.cancelAction();
                props.setDialogIsShown(false);
                dispatch(updateOverlayActive(false));
                dispatch(updateDownloadStatus(false));
              }}
            >
              close
            </button>
          )}
          <header className="dialog__header">
            <div className="dialog__title-byline">
              {props.dialogByline && (
                <FormattedMessage id={props.dialogByline} />
              )}
            </div>

            <h2 className="dialog__title mdc-dialog__title">
              {props.heading ? (
                <FormattedMessage id={props.heading} />
              ) : (
                props.headingText
              )}
            </h2>
          </header>
          <div className="dialog__content">{props.children}</div>
          {props.footerProps && <Footer {...props.footerProps} />}
        </div>
      </div>
      {!props.nested && (
        <div
          className={classNames('mdc-dialog__scrim', {
            'mdc-dialog__scrim--scrim-closed': !props.dialogIsShown,
          })}
        />
      )}
    </div>
  );
};

export default Dialog;
