import { KeyboardEvent } from 'react';
import { Locale } from '../redux/uiStateReducer';
import { useIntl } from 'react-intl';

export function formatNumber(
  number: number | string | undefined,
  locale: Locale,
): string | undefined {
  if (typeof number === 'string') {
    return number;
  }

  return number?.toFixed(2).replace('.', locale === Locale.DE ? ',' : '.');
}

export function useTranslateConditionally(): (
  value: string,
  translate: boolean,
) => string {
  const { formatMessage } = useIntl();

  return (value: string, translate: boolean) =>
    translate ? formatMessage({ id: value }) : value;
}

export function generatePassword(pwdLength: number): string {
  const POSSIBLE_PWD_CHARS =
    '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz!@$%^&*()_+=-<>,.?/"|`~';
  return Array(pwdLength)
    .fill(POSSIBLE_PWD_CHARS)
    .map(x => {
      return x[Math.floor(Math.random() * x.length)];
    })
    .join('');
}

export function enumToList(enumeration: any): string[] {
  return Object.keys(enumeration).map(key => enumeration[key]);
}

export function conditionalClass(
  property: string | undefined,
  prefix?: string,
): string {
  return property ? ` ${prefix || ''}${property}` : '';
}

export function adminView(): boolean {
  return !!window.location.href.match(/admin/);
}

export function onEnter(callback?: () => void): (event: KeyboardEvent) => void {
  return (event: KeyboardEvent) => {
    if (event.key === 'Enter') {
      callback?.();
    }
  };
}
