import React, { FC, useState } from 'react';
import Dialog from '../../components/Dialog';
import {
  MenuPlacement,
  SelectField,
  selectFieldValue,
  SelectOption,
} from '../../elements/SelectField';
import FormLayout, { FormLayoutElement } from '../../elements/FormLayout';
import { Series } from '../../redux/admin/adminFacadeReducer';
import { useDispatch, useSelector } from 'react-redux';
import { AdminState, AdminThunkDispatch } from '../../redux/admin/adminStore';
import { AssignSelectGroup } from '../components/AssignSelectGroup';
import _ from 'lodash';
import {
  TestPhaseRoof,
  TestPhaseRoofWithOptionalId,
  TestPhaseSeriesRoofWithOptionalId,
  TestPhaseSystemRoofWithOptionalId,
} from '../../redux/admin/adminRoofReducer';
import { updateEditedTestPhaseSystemRoof } from '../../redux/admin/editedTestPhaseActionsRoof';
import { AnyAction } from 'redux';

interface CopySeriesDialogProps {
  shown: boolean;
  setShown: (b: boolean) => void;
}

const CopySeriesDialogRoof: FC<
  React.PropsWithChildren<CopySeriesDialogProps>
> = props => {
  const [copyFromSeries, setCopyFromSeries] =
    useState<TestPhaseSeriesRoofWithOptionalId>();
  const [copyFromTestPhase, setCopyFromTestPhase] = useState<
    TestPhaseRoofWithOptionalId | undefined
  >();
  const [copyFromSystem, setCopyFromSystem] = useState<
    TestPhaseSystemRoofWithOptionalId | undefined
  >();
  const possibleSourceTestPhases = useSelector<AdminState, TestPhaseRoof[]>(
    s => s.adminRoof.testPhases,
  );
  const dispatch: AdminThunkDispatch<AnyAction> = useDispatch();
  const editedTestPhaseSystem = useSelector<
    AdminState,
    TestPhaseSystemRoofWithOptionalId | undefined
  >(state => state.adminRoof.editedTestPhaseSystem);
  const possibleTargetSeries = useSelector<AdminState, Series[]>(state => {
    const editedSystem = state.adminRoof.editedTestPhaseSystem?.system;

    if (!editedSystem) {
      return [];
    }

    const existingSeriesIds =
      state.adminRoof.editedTestPhaseSystem?.testPhaseSeries.map(
        s => s.series.id,
      ) || [];

    return state.adminRoof.series.filter(
      s => s.system.id === editedSystem.id && !existingSeriesIds.includes(s.id),
    );
  });
  const [selectedSeries, setSelectedSeries] = useState<Series[]>([]);

  function possibleSourceSeries(): TestPhaseSeriesRoofWithOptionalId[] {
    return copyFromSystem?.testPhaseSeries || [];
  }
  function possibleSourceSystems(): TestPhaseSystemRoofWithOptionalId[] {
    return copyFromTestPhase?.testPhaseSystems || [];
  }

  function selectCopyFromSystem(
    newValue: SelectOption<TestPhaseSystemRoofWithOptionalId>,
  ): void {
    if (newValue.value) {
      setCopyFromSystem(newValue.value);
      setCopyFromSeries(undefined);
    }
  }

  function selectCopyFromTestPhase(
    newValue: SelectOption<TestPhaseRoofWithOptionalId>,
  ): void {
    if (newValue.value) {
      setCopyFromTestPhase(newValue.value);
      setCopyFromSystem(undefined);
      setCopyFromSeries(undefined);
    }
  }

  function selectCopyFromSeries(
    newValue: SelectOption<TestPhaseSeriesRoofWithOptionalId>,
  ): void {
    if (newValue.value) {
      setCopyFromSeries(newValue.value);
    }
  }

  function copySeries(): void {
    const seriesWithoutId = _.cloneDeep(copyFromSeries!);
    delete seriesWithoutId.id;
    seriesWithoutId.applicationOverviews.forEach(ao => delete ao.id);
    seriesWithoutId.windLoads.forEach(wl => delete wl.id);
    seriesWithoutId.snowLoads.forEach(sl => delete sl.id);
    seriesWithoutId.anwendungen.forEach(aw => {
      delete aw.id;
      aw.testPhaseAerodynamicCaseRoofs.forEach(ac => {
        delete ac.id;
      });
    });

    const newTestPhaseSeries = selectedSeries.map(s => {
      const newTestPhaseSeries = _.cloneDeep(seriesWithoutId);
      newTestPhaseSeries.series = s;
      return newTestPhaseSeries;
    });
    const newSystemIds = selectedSeries.map(s => s.system.id);

    const existingSeriesWithoutNewOnes =
      editedTestPhaseSystem!.testPhaseSeries.filter(
        tps => !newSystemIds.includes(tps.series.id),
      );

    dispatch(
      updateEditedTestPhaseSystemRoof(editedTestPhaseSystem, {
        testPhaseSeries: [
          ...existingSeriesWithoutNewOnes,
          ...newTestPhaseSeries,
        ],
      }),
    );
  }

  return (
    <Dialog
      headingText="Serie kopieren"
      setDialogIsShown={props.setShown}
      dialogIsShown={props.shown}
      componentClass="copy-series-dialog"
      nested={true}
      footerProps={{
        notTranslated: true,
        cancelAction: () => props.setShown(false),
        primaryActionLabelText: 'Kopieren',
        saveAction: copyFromSeries
          ? () => {
              props.setShown(false);
              copySeries();
            }
          : undefined,
      }}
    >
      <FormLayout>
        <FormLayoutElement rowStart={1} columnStart={1} columnWidth={3}>
          <SelectField
            name="kopieren von Testphase"
            placeholder="Testphase"
            action={selectCopyFromTestPhase}
            options={possibleSourceTestPhases.map(s => {
              return {
                label: s.name,
                value: s,
              };
            })}
            value={
              copyFromTestPhase &&
              selectFieldValue(copyFromTestPhase.name, copyFromTestPhase)
            }
            menuPlacement={MenuPlacement.BOTTOM}
          />
        </FormLayoutElement>
        <FormLayoutElement rowStart={2} columnStart={1} columnWidth={3}>
          <SelectField
            name="kopieren von System"
            placeholder="System"
            key={copyFromSystem?.id}
            action={selectCopyFromSystem}
            options={possibleSourceSystems().map(s => {
              return {
                label: s.system.name,
                value: s,
              };
            })}
            value={
              copyFromSystem &&
              selectFieldValue(copyFromSystem?.system.name, copyFromSystem)
            }
            menuPlacement={MenuPlacement.BOTTOM}
          />
        </FormLayoutElement>
        <FormLayoutElement rowStart={3} columnStart={1} columnWidth={3}>
          <SelectField
            name="kopieren von Serie"
            placeholder="Serie"
            key={copyFromSeries?.id}
            action={selectCopyFromSeries}
            options={possibleSourceSeries().map(s => {
              return {
                label: s.series.name,
                value: s,
              };
            })}
            value={
              copyFromSeries &&
              selectFieldValue(copyFromSeries?.series.name, copyFromSeries)
            }
            menuPlacement={MenuPlacement.BOTTOM}
          />
        </FormLayoutElement>
        <FormLayoutElement rowStart={4} columnStart={1} columnWidth={3}>
          <AssignSelectGroup
            headings={{
              headingLeftTable: 'verfügbare Serien',
              headingRightTable: 'kopieren nach',
            }}
            componentClass=""
            addElements={(s: Series[]) =>
              setSelectedSeries([...s, ...selectedSeries])
            }
            removeElements={(s: Series[]) =>
              setSelectedSeries(
                selectedSeries.filter(series => s.includes(series)),
              )
            }
            selectedElements={selectedSeries}
            deselectedElements={possibleTargetSeries.filter(
              ps => !selectedSeries.includes(ps),
            )}
            displayNameExtractor={(s: Series) => s.name}
            backgroundWhite={true}
          />
        </FormLayoutElement>
      </FormLayout>
    </Dialog>
  );
};

export default CopySeriesDialogRoof;
