import { Action, AnyAction, Dispatch } from 'redux';
import {
  CompanyWithOptionalId,
  UserWithOptionalId,
} from '../adminFacadeReducer';
import { ThunkAction } from 'redux-thunk';
import { AdminState } from '../adminStore';
import { newCompany } from '../../../admin/constants';

export interface StoreEditedUser extends Action<'STORE_EDITED_USER'> {
  editedUser: UserWithOptionalId;
}

export interface StoreEditedCompany extends Action<'STORE_EDITED_COMPANY'> {
  editedCompany: CompanyWithOptionalId;
}

export interface StoreTrainingCertificateToUpload
  extends Action<'STORE_TRAINING_CERTIFICATE_TO_UPLOAD'> {
  certificate: File | undefined;
}

export interface StoreDeleteTrainingCertificateOnSave
  extends Action<'STORE_DELETED_TRAINING_CERTIFICATE_ON_SAVE'> {
  deleteOnSave: boolean;
}

export type USER_MANAGEMENT_ACTIONS =
  | StoreEditedUser
  | StoreEditedCompany
  | StoreTrainingCertificateToUpload
  | StoreDeleteTrainingCertificateOnSave;

export function updateEditedCompany(
  editedCompany: CompanyWithOptionalId,
): StoreEditedCompany {
  return { type: 'STORE_EDITED_COMPANY', editedCompany: editedCompany };
}

export function storeTrainingCertificateToUpload(
  certificate: File | undefined,
): StoreTrainingCertificateToUpload {
  return {
    type: 'STORE_TRAINING_CERTIFICATE_TO_UPLOAD',
    certificate: certificate,
  };
}

export function storeDeleteTrainingCertificateOnSave(
  deleteOnSave: boolean,
): StoreDeleteTrainingCertificateOnSave {
  return {
    type: 'STORE_DELETED_TRAINING_CERTIFICATE_ON_SAVE',
    deleteOnSave: deleteOnSave,
  };
}

export function selectEditedCompany(
  companyToSelect: CompanyWithOptionalId,
): ThunkAction<Promise<void>, AdminState, void, AnyAction> {
  return async (dispatch: Dispatch): Promise<void> => {
    dispatch(updateEditedCompany(companyToSelect));
  };
}

export function initializeNewEditedCompany(): ThunkAction<
  Promise<void>,
  AdminState,
  void,
  AnyAction
> {
  return async (dispatch: Dispatch): Promise<void> => {
    const newEditedCompany: CompanyWithOptionalId = newCompany;
    dispatch(updateEditedCompany(newEditedCompany));
  };
}
