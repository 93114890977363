import React, { FC, ReactElement, useState } from 'react';
import TabNavigation, { TabNavItemProps } from './TabNavigation';
import '../elements/TabbedComponent.scss';

interface TabbedComponentProps {
  items: Item[];
}

export interface Item {
  label: string;
  component?: ReactElement;
  action?: () => void;
}

const TabbedComponent: FC<React.PropsWithChildren<TabbedComponentProps>> = (
  props: TabbedComponentProps,
) => {
  const [activeTab, setActiveTab] = useState<number>(0);
  const isSingleTab = props.items.length > 1;

  function toTabNavItemProps(input: Item[]): TabNavItemProps[] {
    return input.map((element, index) => {
      return {
        label: element.label,
        active: index === activeTab,
        action: () => {
          element.action && element.action();
          setActiveTab(index);
        },
      };
    });
  }

  return (
    <>
      {isSingleTab && (
        <TabNavigation tabNavItems={toTabNavItemProps(props.items)} />
      )}
      {
        <div className="tabbed-component">
          {props.items[activeTab].component}
        </div>
      }
    </>
  );
};

export default TabbedComponent;
