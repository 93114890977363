import { Action, ActionCreator, AnyAction, Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { getSelectedWindow } from '../hooks/selectorHooks';
import { fetchAndStore, RequestTypes } from './httpClient';
import {
  PerformanceClasses,
  Ranges,
  TestSearchOptions,
  Validation,
  WindDeflector,
} from './nrwgReducer';
import { IState } from './store';
import { calculating, finishedCalculation } from './uiStateActions';
import {
  getRequiredNrwgParameters,
  notAllRequiredCalculationParametersDefined,
  ValueKey,
} from './valueKey';
import { RangeOfApplication } from './constants';
import { updateStore } from './parametersActions';
import { Parameters } from '../components/Parameters/ParameterValue';

export type NRWG_ACTIONS =
  | UpdateTestSearchOptions
  | UpdateRanges
  | UpdateValidation
  | UpdatePerformanceClasses
  | StoreWindDeflectors;

export interface StoreWindDeflectors extends Action<'UPDATE_WIND_DEFLECTORS'> {
  windDeflectors: WindDeflector[] | undefined;
}

export interface UpdateTestSearchOptions
  extends Action<'UPDATE_TEST_SEARCH_OPTIONS'> {
  testSearchOptions: TestSearchOptions;
}

export interface UpdateRanges extends Action<'UPDATE_RANGES'> {
  ranges: Ranges | undefined;
}

export interface UpdateValidation extends Action<'UPDATE_VALIDATION'> {
  validation: Validation | undefined;
}

export interface UpdatePerformanceClasses
  extends Action<'UPDATE_PERFORMANCE_CLASSES'> {
  performanceClasses: PerformanceClasses | undefined;
}

export function storeWindDeflectors(
  windDeflectors: WindDeflector[] | undefined,
): StoreWindDeflectors {
  return {
    type: 'UPDATE_WIND_DEFLECTORS',
    windDeflectors: windDeflectors,
  };
}

export const updateTestSearchOptions: ActionCreator<UpdateTestSearchOptions> = (
  testSearchOptions: TestSearchOptions,
): UpdateTestSearchOptions => {
  return {
    type: 'UPDATE_TEST_SEARCH_OPTIONS',
    testSearchOptions: testSearchOptions,
  };
};

export const updatePerformanceClasses: ActionCreator<
  UpdatePerformanceClasses
> = (
  performanceClasses: PerformanceClasses | undefined,
): UpdatePerformanceClasses => {
  return {
    type: 'UPDATE_PERFORMANCE_CLASSES',
    performanceClasses: performanceClasses,
  };
};

export function updateRanges(ranges: Ranges | undefined): UpdateRanges {
  return {
    type: 'UPDATE_RANGES',
    ranges: ranges,
  };
}

export function updateValidation(
  validation: Validation | undefined,
): UpdateValidation {
  return {
    type: 'UPDATE_VALIDATION',
    validation: validation,
  };
}

export function getOrResetWindDeflectors(): ThunkAction<
  Promise<void>,
  IState,
  void,
  AnyAction
> {
  return async (dispatch: Dispatch, getState: () => IState): Promise<void> => {
    const selectedWindow = getSelectedWindow(getState());

    if (
      !selectedWindow?.nrwg ||
      getState().parameters.rangeOfApplication.value ===
        RangeOfApplication.FACADE
    ) {
      return;
    }

    if (notAllRequiredCalculationParametersDefined(getState())) {
      dispatch(storeWindDeflectors(undefined));
      return;
    }

    dispatch(calculating(RequestTypes.WIND_DEFLECTORS));

    await fetchAndStore<WindDeflector[]>(
      RequestTypes.WIND_DEFLECTORS,
      windDeflectors => dispatch(storeWindDeflectors(windDeflectors)),
      getState,
    ).then(() => dispatch(finishedCalculation(RequestTypes.WIND_DEFLECTORS)));
  };
}

export function getTestSearchOptions(): ThunkAction<
  Promise<void>,
  IState,
  void,
  AnyAction
> {
  return async (dispatch: Dispatch, getState: () => IState): Promise<void> => {
    const selectedWindow = getSelectedWindow(getState());

    if (!selectedWindow?.nrwg) {
      return;
    }

    dispatch(calculating(RequestTypes.NRWG));

    await fetchAndStore(
      RequestTypes.NRWG,
      testSearchOptionResult =>
        dispatch(updateTestSearchOptions(testSearchOptionResult)),
      getState,
    ).then(() => dispatch(finishedCalculation(RequestTypes.NRWG)));
  };
}

export const getPerformanceClasses: ActionCreator<
  ThunkAction<Promise<void>, IState, void, AnyAction>
> = () => {
  return async (dispatch: Dispatch, getState: () => IState): Promise<void> => {
    const selectedWindow = getSelectedWindow(getState());

    if (!selectedWindow?.nrwg) {
      return;
    }

    if (notAllRequiredCalculationParametersDefined(getState())) {
      dispatch(updatePerformanceClasses(undefined));
      return;
    }

    dispatch(calculating(RequestTypes.PERFORMANCE_CLASSES));

    await fetchAndStore(
      RequestTypes.PERFORMANCE_CLASSES,
      performanceClasses =>
        dispatch(updatePerformanceClasses(performanceClasses)),
      getState,
      {
        errorHandler: () => {
          dispatch(updatePerformanceClasses(undefined));
        },
      },
    );
    dispatch(finishedCalculation(RequestTypes.PERFORMANCE_CLASSES));
  };
};

function allRequiredTestSearchParametersPresent(
  getState: () => IState,
): boolean {
  return !getRequiredNrwgParameters(
    getState().parameters.rangeOfApplication.value as RangeOfApplication,
  ).every(vk => getState().parameters[vk].isDefined());
}

export function getRanges(): ThunkAction<
  Promise<void>,
  IState,
  void,
  AnyAction
> {
  return async (dispatch: Dispatch, getState: () => IState): Promise<void> => {
    const selectedWindow = getSelectedWindow(getState());

    if (!selectedWindow?.nrwg) {
      return;
    }

    if (allRequiredTestSearchParametersPresent(getState)) {
      dispatch(updateRanges(undefined));
      return;
    }

    dispatch(calculating(RequestTypes.NRWG_RANGES));

    await fetchAndStore(
      RequestTypes.NRWG_RANGES,
      (ranges: Ranges) => dispatch(updateRanges(ranges)),
      getState,
    )
      .then(() => dispatch(finishedCalculation(RequestTypes.NRWG_RANGES)))
      .then(() => {
        if (!getState().nrwg.ranges?.area.calculatedValue) {
          return dispatch(
            updateStore(ValueKey.AREA, Parameters.ENTRY_REQUIRED),
          );
        }
        const calculatedValue =
          getState().nrwg.ranges?.area?.calculatedValue || 0;

        dispatch(
          updateStore(ValueKey.AREA, Math.round(100 * calculatedValue) / 100),
        );
      });
  };
}

export function getValidation(): ThunkAction<
  Promise<void>,
  IState,
  void,
  AnyAction
> {
  return async (dispatch: Dispatch, getState: () => IState): Promise<void> => {
    const window = getSelectedWindow(getState());

    if (!window?.nrwg) {
      return;
    }

    if (allRequiredTestSearchParametersPresent(getState)) {
      dispatch(updateValidation(undefined));
      return;
    }

    dispatch(calculating(RequestTypes.NRWG_VALIDATION));

    await fetchAndStore(
      RequestTypes.NRWG_VALIDATION,
      (validation: Validation) => dispatch(updateValidation(validation)),
      getState,
    ).then(() => dispatch(finishedCalculation(RequestTypes.NRWG_VALIDATION)));
  };
}
