import React, { FC, ReactElement, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  selectProject,
  setProjectNavigationOpen,
  setProjectScopeFetchProjects,
  showDialog,
  switchPage,
} from '../../redux/uiStateActions';
import { useDispatch, useSelector } from 'react-redux';
import '../../app/App.scss';
import './ProjectsManager.scss';
import './../../components/SystemSeriesDialog.scss';
import { IState, MyCalcThunkDispatch } from '../../redux/store';
import { ShallowProject } from '../../redux/projectsReducer';
import Button, { ButtonSize, ButtonType } from '../../elements/Button';
import EmptyStateContainer from '../../elements/EmptyStateContainer';
import { Pages, ProjectScopes } from '../../redux/uiStateReducer';
import {
  ConfirmationDialog,
  TYPE_OF_CONFIRMATION,
} from '../ConfirmationDialog';
import { updateProjectSearchStringAndFetchProjects } from '../../redux/projectsActions';
import ManagerPage, {
  cell,
  label,
  ManagerPageTable,
  ManagerTableCellProps,
  ManagerTableHeaderElementProps,
} from '../ManagerPage';
import { ReactComponent as IconFolder } from './IconFolder.svg';
import { ProjectDialog } from './ProjectDialog';
import _ from 'lodash';
import classNames from 'classnames';
import TabbedComponent from '../../elements/TabbedComponent';
import { LoggedInUser } from '../../redux/authenticationReducer';
import { AnyAction } from 'redux';

const ProjectsManager: FC<React.PropsWithChildren<unknown>> = () => {
  const dispatch: MyCalcThunkDispatch<AnyAction> = useDispatch();

  // const [editedProject, setEditedProject] = useState<Project | undefined>();
  const projects = useSelector<IState, ShallowProject[]>(
    (state: IState) => state.projects.projects,
  );
  const currentUser = useSelector<IState, LoggedInUser>(
    state => state.authentication.currentUser!,
  );
  const projectScope = useSelector<IState, ProjectScopes>(
    state => state.ui.projectScope,
  );
  const searchString = useSelector<IState, string>(
    state => state.projects.searchString,
  );
  const page = useSelector<IState, number>(s => s.projects.currentProjectsPage);
  const totalPages = useSelector<IState, number>(s => s.projects.totalPages);

  useEffect(() => {
    dispatch(setProjectScopeFetchProjects(ProjectScopes.USER));
  }, [dispatch]);

  async function selectProjectAndSwitchPage(
    project: ShallowProject,
  ): Promise<void> {
    dispatch(switchPage(Pages.CALCULATION));
    dispatch(selectProject(project));
    dispatch(setProjectNavigationOpen(true));
  }

  function editProject(project: ShallowProject): void {
    dispatch(
      showDialog(<ProjectDialog editMode={true} editedProject={project} />),
    );
  }

  function getActions(project: ShallowProject): Record<string, () => void> {
    const actions = {
      EDIT_LABEL: () => {
        editProject(project);
      },
    };

    if (!project.containsLockedWindows) {
      return {
        ...actions,
        DELETE_LABEL: () => {
          dispatch(
            showDialog(
              <ConfirmationDialog
                typeOfConfirmation={TYPE_OF_CONFIRMATION.CONFIRMATION_PROJECT}
                deletedObject={project}
              />,
            ),
          );
        },
      };
    }

    return actions;
  }

  function getSearchFieldPlaceHolder(): string {
    return 'SEARCH_PROJECT_PLACE_HOLDER_SCOPE_' + projectScope;
  }

  function tableLabels(): ManagerTableHeaderElementProps[] {
    function additionalColumns(): ManagerTableHeaderElementProps[] {
      if (projectScope === ProjectScopes.USER) {
        return [];
      } else if (projectScope === ProjectScopes.COMPANY) {
        return [label('PROJECT_CREATOR')];
      } else {
        return [label('PROJECT_CREATOR'), label('COMPANY_NAME')];
      }
    }

    return [label('PROJECT_NAME')]
      .concat(additionalColumns())
      .concat([label('PROJECT_ID', true), label('PROJECT_OPTIONS', true)]);
  }

  function getCells(p: ShallowProject): ManagerTableCellProps[] {
    function additionelCells(p: ShallowProject): ManagerTableCellProps[] {
      function getCreatorName(): ManagerTableCellProps {
        return cell(p.userName);
      }

      if (projectScope === ProjectScopes.USER) {
        return [];
      } else if (projectScope === ProjectScopes.COMPANY) {
        return [getCreatorName()];
      } else {
        return [getCreatorName(), cell(p.companyName)];
      }
    }

    return [cell(p.name)]
      .concat(additionelCells(p))
      .concat(cell(p.uniqueNumber, true, true));
  }

  const PROJECT_EMPTY_STATE: ReactElement = (
    <EmptyStateContainer size="lg">
      <FormattedMessage id="NO_EXISTING_PROJECTS" />
    </EmptyStateContainer>
  );

  function loadMore(): void {
    dispatch(updateProjectSearchStringAndFetchProjects(searchString, page + 1));
  }

  const MANAGER_PAGE_TABLE: ReactElement = (
    <>
      <ManagerPageTable
        setSearchString={searchString => {
          dispatch(updateProjectSearchStringAndFetchProjects(searchString, 0));
        }}
        searchString={searchString}
        placeHolder={getSearchFieldPlaceHolder()}
        header={tableLabels()}
        hasMore={page < totalPages - 1}
        emptyState={PROJECT_EMPTY_STATE}
        loadMore={loadMore}
        rows={projects.map(project => ({
          cells: getCells(project),
          onClick: () => selectProjectAndSwitchPage(project),
          rowElement: project,
          icon: <IconFolder />,
          options: getActions(project),
          key: project.id.toString(),
        }))}
      />
    </>
  );

  return (
    <ManagerPage
      additionalClass={classNames(
        'projects-manager',
        `projects-manager--${_.kebabCase(projectScope)}`,
      )}
      headline="MY_PROJECTS"
    >
      <>
        <Button
          label="NEW_PROJECT"
          type={ButtonType.PRIMARY}
          action={() =>
            dispatch(
              showDialog(
                <ProjectDialog editMode={false} editedProject={undefined} />,
              ),
            )
          }
          iconName="add"
          size={ButtonSize.SMALL}
        />
        <TabbedComponent
          items={_.compact([
            {
              label: 'PROJECTS_MANAGER_MY_PROJECTS',
              action: () =>
                dispatch(setProjectScopeFetchProjects(ProjectScopes.USER)),
              component: MANAGER_PAGE_TABLE,
            },

            {
              label: 'PROJECTS_MANAGER_MY_COMPANIES_PROJECTS',
              action: () =>
                dispatch(setProjectScopeFetchProjects(ProjectScopes.COMPANY)),
              component:
                projects.length > 0 || searchString
                  ? MANAGER_PAGE_TABLE
                  : PROJECT_EMPTY_STATE,
            },
            currentUser.admin
              ? {
                  label: 'PROJECTS_MANAGER_ALL_PROJECTS',
                  action: () =>
                    dispatch(setProjectScopeFetchProjects(ProjectScopes.ALL)),
                  component:
                    projects.length > 0 || searchString
                      ? MANAGER_PAGE_TABLE
                      : PROJECT_EMPTY_STATE,
                }
              : undefined,
          ])}
        />
      </>
    </ManagerPage>
  );
};

export default ProjectsManager;
