/* eslint-disable */
import { AdminFacadeState } from './adminFacadeReducer';
import { Action, AnyAction, applyMiddleware, createStore } from 'redux';
import thunk, { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { AdminRoofState } from './adminRoofReducer';
import { Config, GlobalUIState } from '../globalUiStateReducer';
import { updateConfig } from '../globalUiStateActions';
import adminReducer from '../adminReducer';

export interface AdminState {
  [index: string]: AdminFacadeState | AdminRoofState | GlobalUIState;
  adminFacade: AdminFacadeState;
  adminRoof: AdminRoofState;
  globalUIState: GlobalUIState;
}

export type AdminThunkAction<T extends Action> = ThunkAction<
  Promise<void>,
  AdminState,
  undefined,
  T
>;

export type AdminThunkDispatch<T extends Action> = ThunkDispatch<
  AdminState,
  undefined,
  T
>;

const intitializeAdminStore = (config: Config) => {
  const store = createStore(adminReducer, {}, applyMiddleware(thunk));
  window.adminDispatch = store.dispatch as ThunkDispatch<
    AdminState,
    void,
    AnyAction
  >;
  window.adminDispatch(updateConfig(config));
  return store;
};

export default intitializeAdminStore;
