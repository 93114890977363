import React, { FC, ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import LoadingSkeleton from '../../elements/LoadingSkeleton';
import './Column.scss';
import classNames from 'classnames';
import { showDialog } from '../../redux/uiStateActions';
import { useDispatch } from 'react-redux';

interface ColumnProps {
  children: ReactNode;
  loading: boolean;
  heading?: string;
  className?: string;
  columnRenderedCallback?: (d: HTMLDivElement) => void;
  icon?: ReactNode;
  infoModal?: ReactNode;
}

export const Column: FC<React.PropsWithChildren<ColumnProps>> = (
  props: ColumnProps,
) => {
  const dispatch = useDispatch();

  return (
    <div
      className={classNames('column', props.className)}
      ref={props.columnRenderedCallback}
      data-qa={props.heading}
    >
      <div className="column__content">
        {props.heading && (
          <div className="column__title">
            {props.icon}
            <FormattedMessage id={props.heading} />{' '}
            {props.loading && <LoadingSkeleton />}
            {props.infoModal && (
              <div
                className="editable-box__information-icon-container"
                onClick={() => {
                  dispatch(showDialog(props.infoModal));
                }}
              />
            )}
          </div>
        )}
        {props.children}
      </div>
    </div>
  );
};
