export function getModifyClass(
  prefix: string,
): (property: string | undefined, modify?: boolean) => string {
  return (property: string | undefined, modify?: boolean) => {
    return modify || modify === undefined
      ? conditionalClass(property, `${prefix}--`)
      : '';
  };
}

export function conditionalClass(
  property: string | undefined,
  prefix?: string,
): string {
  return property ? ` ${prefix || ''}${property}` : '';
}
