import React, { FC, useCallback, useRef } from 'react';
import { ValueKey } from '../redux/valueKey';
import { MDCRadio } from '@material/radio';
import './RadioButton.scss';

interface RadioButtonProps {
  checked: boolean;
  valueKey?: ValueKey;
  onChange?: () => void;
  name: string;
}

const AdminRadioButton: FC<React.PropsWithChildren<RadioButtonProps>> = (
  props: RadioButtonProps,
) => {
  const buttonRef = useRef<MDCRadio | undefined>();

  const radioButtonRef = useCallback(
    (button: any) => {
      if (buttonRef.current) {
        buttonRef.current.destroy();
      }

      if (!button) {
        return;
      }

      buttonRef.current = new MDCRadio(button);
    },
    [buttonRef.current],
  );

  return (
    <div className="radio-button">
      <div className="mdc-radio " ref={radioButtonRef}>
        <input
          className="mdc-radio__native-control"
          id={props.name}
          type="radio"
          name={props.name}
          checked={props.checked}
          onChange={props.onChange}
        />
        <div className="mdc-radio__background">
          <div className="mdc-radio__outer-circle" />
          <div className="mdc-radio__inner-circle" />
        </div>
        <div className="mdc-radio__ripple" />{' '}
      </div>{' '}
      <label htmlFor={props.name}>{props.name}</label>
    </div>
  );
};

export default AdminRadioButton;
