import React, { FC, ReactElement } from 'react';
import './Table.scss';
import classNames from 'classnames';

type Column = string | ReactElement;

interface HeaderRow {
  selected?: boolean;
  onClick?: () => void;
  columns: Column[];
}

interface BodyRow extends HeaderRow {
  key: string;
}

interface TableProps {
  head: HeaderRow;
  body: BodyRow[];
}

const Table: FC<React.PropsWithChildren<TableProps>> = props => {
  return (
    <table className="table">
      <thead className="table__head">
        <tr>
          {props.head.columns.map((c, i) => (
            <th className="table__head-cell" key={i}>
              {c}
            </th>
          ))}
        </tr>
      </thead>
      <tbody className="table__body">
        {props.body.map(r => (
          <tr
            className={classNames('table__row', {
              'table__row--selected': r.selected,
            })}
            onClick={r.onClick}
            key={r.key}
          >
            {r.columns.map((c, i) => (
              <td className="table__body-cell" key={i}>
                {c}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default Table;
