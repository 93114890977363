import React, { FC, ReactNode } from 'react';
import './TableRow.scss';
import classNames from 'classnames';

interface TableRowProps {
  children: ReactNode;
  selected?: boolean;
  onClick?: () => void;
}

const TableRow: FC<React.PropsWithChildren<TableRowProps>> = (
  props: TableRowProps,
) => {
  return (
    <tr
      className={classNames('table-row', {
        'table-row--selected': props.selected,
      })}
      onClick={props.onClick}
    >
      {props.children}
    </tr>
  );
};

export default TableRow;
