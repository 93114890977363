import React, { FC } from 'react';
import './TextBanner.scss';
import { FormattedMessage } from 'react-intl';

interface TextSectionWithLabelProps {
  label: string;
  textContent?: string;
  additionalClass: string;
}
const TextBanner: FC<React.PropsWithChildren<TextSectionWithLabelProps>> = (
  props: TextSectionWithLabelProps,
) => {
  return (
    <div className={`text-banner ${props.additionalClass}`}>
      <span className="text-banner__label">
        {props.label && <FormattedMessage id={props.label} />}
      </span>
      {': '}
      <span className="text-banner__content">
        {props.textContent && <FormattedMessage id={props.textContent} />}
      </span>
    </div>
  );
};

export default TextBanner;
