import React, { FC, useEffect, useState } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import FormLayout from '../../elements/FormLayout';
import InputField, { InputFieldNumber } from '../../elements/InputField';
import TableHeader from '../elements/TableHeader';
import TextArea from '../../elements/TextArea';
import { SelectField } from '../../elements/SelectField';
import { Switch } from '../../elements/Switch';
import CheckBox from '../../elements/CheckBox';
import Dialog from '../../components/Dialog';
import TableRow from '../elements/TableRow';
import Table from '../components/Table';
import SearchField from '../../elements/SearchField';
import './RoofSashProfileData.scss';
import { useDispatch, useSelector } from 'react-redux';
import { AdminState, AdminThunkDispatch } from '../../redux/admin/adminStore';
import { Series } from '../../redux/admin/adminFacadeReducer';
import {
  fieldsFilled,
  getSeriesForProfileId,
  seriesIsOnlyInwards,
  seriesIsOnlyOutwards,
} from '../general/helpers';
import AdminListIcon from '../elements/AdminListIcon';
import AdminActiveSwitch from '../components/AdminActiveSwitch';
import _ from 'lodash';
import Pagination from '../components/Pagination';
import {
  changeSashProfileRoof,
  createSashProfileRoof,
  updatedEditedSashProfileRoof,
} from '../../redux/admin/adminRoofActions';
import {
  NewSashProfileRoof,
  SashProfileRoof,
} from '../../redux/admin/adminRoofReducer';
import classNames from 'classnames';
import { useValidateProfileArticleNumber } from '../facade/FacadeSashProfileDataView';
import { AnyAction } from 'redux';
import AdminNavLink from '../common/AdminNavLink';
import { useAdminSearch } from '../hooks';
import { downloadListAsCSV } from '../common/download';
import { Edit } from '../../types';
import FormLayoutSubgroupTitle from '../../elements/FormLayoutSubgroupTitle';

const SashProfileDataDialogTabNav: FC<
  React.PropsWithChildren<unknown>
> = () => {
  return (
    <div className="admin__tab-nav roof-sash-profile-data-dialog-tab-nav">
      <AdminNavLink to="/admin/roof/profiles/sash-profiles/basic">
        Allgemein
      </AdminNavLink>
      <AdminNavLink to="/admin/roof/profiles/sash-profiles/basic-information">
        Eckdaten
      </AdminNavLink>
      <AdminNavLink to="/admin/roof/profiles/sash-profiles/properties">
        Eigenschaften
      </AdminNavLink>{' '}
    </div>
  );
};

const SashProfileDataDialogBasic: FC<React.PropsWithChildren<unknown>> = () => {
  const allSeriesRoof = useSelector<AdminState, Series[]>(
    s => s.adminRoof.series,
  );
  const editedSashProfile = useSelector<AdminState, Edit<SashProfileRoof>>(
    s => s.adminRoof.editedSashProfile!,
  );
  const articleNumberError = useValidateProfileArticleNumber(
    s => s.adminRoof.sashProfiles,
    editedSashProfile.series!,
    editedSashProfile.artNr || '',
    editedSashProfile.id!,
  );

  const dispatch: AdminThunkDispatch<AnyAction> = useDispatch();

  useEffect(() => {
    dispatch(
      updatedEditedSashProfileRoof({
        ...editedSashProfile,
        oeffnungsrichtungAuswaerts:
          editedSashProfile.series?.oeffnungsrichtungAuswaerts || null,
      }),
    );
  }, [editedSashProfile.series]);

  function parentSeriesIsActive(sashProfile: Edit<SashProfileRoof>): boolean {
    return !!(
      !sashProfile.series ||
      allSeriesRoof.find(series => series.id === sashProfile.seriesId)?.active
    );
  }

  return (
    <div className="">
      <FormLayout additionalClass="roof-frame-profile-data-dialog__layout-basic">
        <InputField
          label="Artikelnummer *"
          placeholder="Artikelnummer des Blendrahmen"
          value={editedSashProfile.artNr}
          additionalClass="roof-frame-profile-data-dialog__article-nr"
          onChange={v =>
            dispatch(
              updatedEditedSashProfileRoof({
                ...editedSashProfile,
                artNr: v,
              }),
            )
          }
          errorMessage={articleNumberError}
        />
        <TextArea
          label="Information"
          additionalClass="roof-frame-profile-data-dialog__info"
          placeholder="Informationen zum System"
          value={editedSashProfile.beschreibung || ''}
          onChange={v =>
            dispatch(
              updatedEditedSashProfileRoof({
                ...editedSashProfile,
                beschreibung: v,
              }),
            )
          }
        />
        <SelectField
          additionalClass="roof-frame-profile-data-dialog__serie"
          label="Serie *"
          value={
            editedSashProfile.series
              ? {
                  value: editedSashProfile.series.id.toString(),
                  label: editedSashProfile.series.name,
                }
              : undefined
          }
          action={newValue => {
            dispatch(
              updatedEditedSashProfileRoof({
                ...editedSashProfile,
                series: allSeriesRoof.find(
                  s => s.id === +newValue.value,
                ) as Series,
                seriesId: +newValue.value,
              }),
            );
          }}
          options={allSeriesRoof.map(s => ({
            value: s.id.toString(),
            label: s.name,
          }))}
          name="project-country"
          searchable={true}
          placeholder="Serie"
        />
        <div className="roof-frame-profile-data-dialog__switch">
          <Switch
            labelText={
              parentSeriesIsActive(editedSashProfile)
                ? 'aktiv'
                : 'Aktivierung nicht möglich. Serie ' +
                  editedSashProfile.series?.name +
                  ' muss aktiv sein.'
            }
            turnedOn={
              parentSeriesIsActive(editedSashProfile)
                ? !!editedSashProfile.active
                : false
            }
            onChange={v =>
              dispatch(
                updatedEditedSashProfileRoof({
                  ...editedSashProfile,
                  active: v,
                }),
              )
            }
          />
        </div>
        <div className="roof-frame-profile-data-dialog__preview">
          <Switch
            labelText="Vorschau"
            turnedOn={editedSashProfile.preview === false ? false : true}
            onChange={v =>
              dispatch(
                updatedEditedSashProfileRoof({
                  ...editedSashProfile,
                  preview: v,
                }),
              )
            }
          />
        </div>
      </FormLayout>
    </div>
  );
};

const SashProfileDataDialogBasicInformation: FC<
  React.PropsWithChildren<unknown>
> = () => {
  const editedSashProfile = useSelector<AdminState, Edit<SashProfileRoof>>(
    s => s.adminRoof.editedSashProfile!,
  );
  const dispatch: AdminThunkDispatch<AnyAction> = useDispatch();

  return (
    <div>
      <FormLayout additionalClass="roof-sash-profile-data-dialog__layout-basic-information">
        <CheckBox
          label="Verrigelungsbeschlag geeignet"
          checked={!!editedSashProfile.verriegelungsbeschlagGeeignet}
          onClick={() =>
            dispatch(
              updatedEditedSashProfileRoof({
                ...editedSashProfile,
                verriegelungsbeschlagGeeignet:
                  !editedSashProfile.verriegelungsbeschlagGeeignet,
              }),
            )
          }
        />
        <InputFieldNumber
          label="minimale Einspannstärke (mm)"
          placeholder="z.B. 42"
          value={editedSashProfile.minEinspannstaerke}
          additionalClass="roof-sash-profile-data-dialog__min-strength"
          onChange={v =>
            dispatch(
              updatedEditedSashProfileRoof({
                ...editedSashProfile,
                minEinspannstaerke: v,
              }),
            )
          }
        />
        <InputFieldNumber
          label="maximale Einspannstärke (mm)"
          placeholder="z.B. 42"
          value={editedSashProfile.maxEinspannstaerke}
          additionalClass="roof-sash-profile-data-dialog__max-strength"
          onChange={v =>
            dispatch(
              updatedEditedSashProfileRoof({
                ...editedSashProfile,
                maxEinspannstaerke: v,
              }),
            )
          }
        />
        <InputFieldNumber
          label="min. Füllungsdicke (mm)"
          placeholder="z.B. 42"
          value={editedSashProfile.minFuellungsdicke}
          additionalClass="roof-sash-profile-data-dialog__min-filling"
          onChange={v =>
            dispatch(
              updatedEditedSashProfileRoof({
                ...editedSashProfile,
                minFuellungsdicke: v,
              }),
            )
          }
        />
        <InputFieldNumber
          label="max. Füllungsdicke (mm)"
          placeholder="z.B. 42"
          value={editedSashProfile.maxFuellungsdicke}
          additionalClass="roof-sash-profile-data-dialog__max-filling"
          onChange={v =>
            dispatch(
              updatedEditedSashProfileRoof({
                ...editedSashProfile,
                maxFuellungsdicke: v,
              }),
            )
          }
        />
        <InputFieldNumber
          label="max. Flügelgewicht (kg)"
          placeholder="z.B. 42"
          value={editedSashProfile.maxFluegelgewicht}
          additionalClass="roof-sash-profile-data-dialog__max-weight"
          onChange={v =>
            dispatch(
              updatedEditedSashProfileRoof({
                ...editedSashProfile,
                maxFluegelgewicht: v,
              }),
            )
          }
        />
        <InputFieldNumber
          label="max. Flügelbreite (mm)"
          placeholder="z.B. 42"
          value={editedSashProfile.maxFluegelbreite}
          additionalClass="roof-sash-profile-data-dialog__max-sash-width"
          onChange={v =>
            dispatch(
              updatedEditedSashProfileRoof({
                ...editedSashProfile,
                maxFluegelbreite: v,
              }),
            )
          }
        />
        <InputFieldNumber
          label="max. Flügelhöhe (mm)"
          placeholder="z.B. 42"
          value={editedSashProfile.maxFluegelhoehe}
          additionalClass="roof-sash-profile-data-dialog__max-sash-height"
          onChange={v =>
            dispatch(
              updatedEditedSashProfileRoof({
                ...editedSashProfile,
                maxFluegelhoehe: v,
              }),
            )
          }
        />

        <InputFieldNumber
          label="min. Einbauneigung (mm)"
          placeholder="z.B. 42"
          value={editedSashProfile.minEinbauneigung}
          additionalClass="roof-sash-profile-data-dialog__min-tilt"
          onChange={v =>
            dispatch(
              updatedEditedSashProfileRoof({
                ...editedSashProfile,
                minEinbauneigung: v,
              }),
            )
          }
        />
        <InputFieldNumber
          label="max. Einbauneigung (mm)"
          placeholder="z.B. 42"
          value={editedSashProfile.maxEinbauneigung}
          additionalClass="roof-sash-profile-data-dialog__max-tilt"
          onChange={v =>
            dispatch(
              updatedEditedSashProfileRoof({
                ...editedSashProfile,
                maxEinbauneigung: v,
              }),
            )
          }
        />
        <InputFieldNumber
          label="Abstand Achse-Flügel bei 40 (mm)"
          placeholder="z.B. 42"
          value={editedSashProfile.abstandAchseFluegelBei40}
          additionalClass="roof-sash-profile-data-dialog__distance-40"
          onChange={v =>
            dispatch(
              updatedEditedSashProfileRoof({
                ...editedSashProfile,
                abstandAchseFluegelBei40: v,
              }),
            )
          }
        />
        <InputFieldNumber
          label="Abstand Achse-Flügel bei 50 (mm)"
          placeholder="z.B. 42"
          value={editedSashProfile.abstandAchseFluegelBei50}
          additionalClass="roof-sash-profile-data-dialog__distance-50"
          onChange={v =>
            dispatch(
              updatedEditedSashProfileRoof({
                ...editedSashProfile,
                abstandAchseFluegelBei50: v,
              }),
            )
          }
        />
        <InputFieldNumber
          label="Abstand Achse-Flügel bei 60 (mm)"
          placeholder="z.B. 42"
          value={editedSashProfile.abstandAchseFluegelBei60}
          additionalClass="roof-sash-profile-data-dialog__distance-60"
          onChange={v =>
            dispatch(
              updatedEditedSashProfileRoof({
                ...editedSashProfile,
                abstandAchseFluegelBei60: v,
              }),
            )
          }
        />
        <InputFieldNumber
          label="Abstand Achse-Flügel bei 70 (mm)"
          placeholder="z.B. 42"
          value={editedSashProfile.abstandAchseFluegelBei70}
          additionalClass="roof-sash-profile-data-dialog__distance-70"
          onChange={v =>
            dispatch(
              updatedEditedSashProfileRoof({
                ...editedSashProfile,
                abstandAchseFluegelBei70: v,
              }),
            )
          }
        />
        <InputFieldNumber
          label="Abstand Achse-Flügel bei 80 (mm)"
          placeholder="z.B. 42"
          value={editedSashProfile.abstandAchseFluegelBei80}
          additionalClass="roof-sash-profile-data-dialog__distance-80"
          onChange={v =>
            dispatch(
              updatedEditedSashProfileRoof({
                ...editedSashProfile,
                abstandAchseFluegelBei80: v,
              }),
            )
          }
        />
      </FormLayout>
    </div>
  );
};

const SashProfileDataDialogProperties: FC<
  React.PropsWithChildren<unknown>
> = () => {
  const editedSashProfile = useSelector<AdminState, Edit<SashProfileRoof>>(
    s => s.adminRoof.editedSashProfile!,
  );
  const dispatch: AdminThunkDispatch<AnyAction> = useDispatch();

  return (
    <div>
      <FormLayout additionalClass="roof-sash-profile-data-dialog__layout-properties">
        <Switch
          labelText="Isolation"
          turnedOn={!!editedSashProfile.isolation}
          onChange={v =>
            dispatch(
              updatedEditedSashProfileRoof({
                ...editedSashProfile,
                isolation: v,
              }),
            )
          }
        />
        <div
          className={classNames(
            'form-layout__sub-group form-layout__sub-group--2 roof-sash-profile-data-dialog__opening-direction',
            {
              'roof-sash-profile-data-dialog__opening-direction--disabled':
                seriesIsOnlyInwards(editedSashProfile.series!) ||
                seriesIsOnlyOutwards(editedSashProfile.series!),
            },
          )}
        >
          <FormLayoutSubgroupTitle>Öffnungsrichtung *</FormLayoutSubgroupTitle>

          {!editedSashProfile.series && (
            <div className="roof-sash-profile-data-dialog__hint">
              Bitte zuerst eine Serie ausgewählen
            </div>
          )}

          {editedSashProfile.oeffnungsrichtungEinwaerts && (
            <CheckBox
              label="Einwärts"
              checked={editedSashProfile.oeffnungsrichtungEinwaerts}
              onClick={() =>
                dispatch(
                  updatedEditedSashProfileRoof({
                    ...editedSashProfile,
                    oeffnungsrichtungEinwaerts:
                      !editedSashProfile.oeffnungsrichtungEinwaerts,
                  }),
                )
              }
            />
          )}
          {editedSashProfile.oeffnungsrichtungAuswaerts && (
            <CheckBox
              label="Auswärts"
              checked={editedSashProfile.oeffnungsrichtungAuswaerts}
              onClick={() =>
                dispatch(
                  updatedEditedSashProfileRoof({
                    ...editedSashProfile,
                    oeffnungsrichtungAuswaerts:
                      !editedSashProfile.oeffnungsrichtungAuswaerts,
                  }),
                )
              }
            />
          )}
        </div>

        <div className="form-layout__sub-group roof-sash-profile-data-dialog__application">
          <FormLayoutSubgroupTitle>Anwendung *</FormLayoutSubgroupTitle>
          <CheckBox
            id="a"
            label="Kipp"
            checked={!!editedSashProfile.anwendungKipp}
            onClick={() =>
              dispatch(
                updatedEditedSashProfileRoof({
                  ...editedSashProfile,
                  anwendungKipp: !editedSashProfile.anwendungKipp,
                }),
              )
            }
          />
          <CheckBox
            id="a"
            label="Klapp"
            checked={!!editedSashProfile.anwendungKlapp}
            onClick={() =>
              dispatch(
                updatedEditedSashProfileRoof({
                  ...editedSashProfile,
                  anwendungKlapp: !editedSashProfile.anwendungKlapp,
                }),
              )
            }
          />
          <CheckBox
            id="a"
            label="Dreh"
            checked={!!editedSashProfile.anwendungDreh}
            onClick={() =>
              dispatch(
                updatedEditedSashProfileRoof({
                  ...editedSashProfile,
                  anwendungDreh: !editedSashProfile.anwendungDreh,
                }),
              )
            }
          />
        </div>
      </FormLayout>
    </div>
  );
};

interface RoofSashSashProfileDataDialogProps {
  dialogIsShown: boolean;
  setDialogIsShown: (b: boolean) => void;
}

const RoofSashSashProfileDataDialog: FC<
  React.PropsWithChildren<RoofSashSashProfileDataDialogProps>
> = props => {
  const dispatch: AdminThunkDispatch<AnyAction> = useDispatch();
  const editedSashProfile = useSelector<AdminState, Edit<SashProfileRoof>>(
    s => s.adminRoof.editedSashProfile!,
  );
  const articleNumberError = useValidateProfileArticleNumber(
    s => s.adminRoof.sashProfiles,
    editedSashProfile.series!,
    editedSashProfile.artNr || '',
    editedSashProfile.id!,
  );

  function resetInputFields(): void {
    dispatch(updatedEditedSashProfileRoof({} as Edit<SashProfileRoof>));
  }

  function mandatoryFieldsFilled(): boolean {
    return !!(
      fieldsFilled(editedSashProfile.series, editedSashProfile.artNr) &&
      (editedSashProfile.oeffnungsrichtungAuswaerts ||
        editedSashProfile.oeffnungsrichtungEinwaerts) &&
      (editedSashProfile.anwendungKipp ||
        editedSashProfile.anwendungKlapp ||
        editedSashProfile.anwendungDreh ||
        editedSashProfile.anwendungSenkklapp) &&
      !articleNumberError
    );
  }

  function newSashProfile(): void {
    if (mandatoryFieldsFilled()) {
      dispatch(createSashProfileRoof(editedSashProfile as NewSashProfileRoof));
    }
  }

  function editSashProfile(): void {
    if (mandatoryFieldsFilled()) {
      dispatch(changeSashProfileRoof(editedSashProfile as SashProfileRoof));
    }
  }

  return (
    <Dialog
      setDialogIsShown={props.setDialogIsShown}
      dialogIsShown={props.dialogIsShown}
      headingText="Flügelprofil bearbeiten"
      componentClass={''}
      key={props.dialogIsShown.toString()}
      footerProps={{
        notTranslated: true,
        cancelAction: () => {
          resetInputFields();
          props.setDialogIsShown(false);
        },
        saveAction: mandatoryFieldsFilled()
          ? () => {
              editedSashProfile.id ? editSashProfile() : newSashProfile();
              props.setDialogIsShown(false);
            }
          : undefined,
        primaryActionLabelText: editedSashProfile.id ? 'Speichern' : 'Anlegen',
      }}
    >
      <SashProfileDataDialogTabNav />
      <Routes>
        <Route path="/basic/*" element={<SashProfileDataDialogBasic />} />
        <Route
          path="/basic-information/*"
          element={<SashProfileDataDialogBasicInformation />}
        />
        <Route
          path="/properties/*"
          element={<SashProfileDataDialogProperties />}
        />
      </Routes>
    </Dialog>
  );
};

const RoofSashProfileData: FC<React.PropsWithChildren<unknown>> = () => {
  const [dialogIsShown, setDialogIsShown] = useState(false);
  const sashProfilesRoof = useSelector<AdminState, SashProfileRoof[]>(
    s => s.adminRoof.sashProfiles,
  );
  const allSeries = useSelector<AdminState, Series[]>(
    state => state.adminRoof.series,
  );
  const [indexOfFirstPageElement, setIndexOfFirstPageElement] = useState(0);
  const [page, setPage] = useState(1);
  const dispatch: AdminThunkDispatch<AnyAction> = useDispatch();
  const [searchString, setSearchString] = useState('');
  const [filterActive, setFilterActive, searchResult] = useAdminSearch(
    sashProfilesRoof,
    searchString,
    ['artNr'],
    entry => {
      const series = getSeriesForProfileId(entry.seriesId, allSeries);
      return [series?.name || '', series?.system.name || ''];
    },
  );

  const navigate = useNavigate();

  function showDialog(): void {
    setDialogIsShown(true);
    navigate('/admin/roof/profiles/sash-profiles/basic');
  }

  function triggerCreationMode(): void {
    showDialog();
    dispatch(updatedEditedSashProfileRoof({} as Edit<SashProfileRoof>));
  }

  function getCurrentTableContent(): SashProfileRoof[] {
    return searchResult.slice(
      indexOfFirstPageElement,
      indexOfFirstPageElement + 20,
    );
  }

  function enableEditSashProfile(sashProfileRoof: SashProfileRoof): void {
    dispatch(updatedEditedSashProfileRoof(_.cloneDeep(sashProfileRoof)));
    showDialog();
  }

  return (
    <>
      <div className="sub-header">
        <div className="sub-header__title"> Flügelprofile</div>
        <RoofSashSashProfileDataDialog
          setDialogIsShown={setDialogIsShown}
          dialogIsShown={dialogIsShown}
        />
        <SearchField
          setSearchString={setSearchString}
          searchString={searchString}
          placeholderText="Flügelprofil suchen..."
          small={true}
          setFilterActive={setFilterActive}
          filterActive={filterActive}
        />
        <button
          className="sub-header__download-button"
          onClick={() =>
            downloadListAsCSV(
              searchResult,
              ';',
              ['legacyId'],
              'FlügelprofileDach',
              { series: (s: Series) => s.name },
              { system: profile => profile.series.system.name },
            )
          }
        >
          CSV
        </button>
        <button
          className="sub-header__button sub-header__button--add"
          onClick={() => triggerCreationMode()}
        >
          Neuer Eintrag
        </button>
      </div>
      <div className="roof-sash-profile-data">
        <Table>
          <TableHeader>
            <th>Artikelnummer</th>
            <th>Beschreibung</th>
            <th>Isolation</th>
            <th>System</th>
            <th>Serie</th>
            <th>Kipp</th>
            <th>Klapp</th>
            <th>Dreh</th>
            <th>Einwärts</th>
            <th>Auswärts</th>
            <th>Verriegelungsbeschlag geeignet</th>
            <th>Aktiv</th>
            <th>Aktion</th>
          </TableHeader>
          {getCurrentTableContent().map(sashProfileRoof => (
            <TableRow key={sashProfileRoof.id}>
              <td>{sashProfileRoof.artNr}</td>
              <td>{sashProfileRoof.beschreibung}</td>
              <td>
                <AdminListIcon checked={sashProfileRoof.isolation} />
              </td>
              <td>{sashProfileRoof.series.system.name}</td>
              <td>{sashProfileRoof.series.name}</td>
              <td>
                <AdminListIcon checked={sashProfileRoof.anwendungKipp} />
              </td>
              <td>
                <AdminListIcon checked={sashProfileRoof.anwendungKlapp} />
              </td>
              <td>
                <AdminListIcon checked={sashProfileRoof.anwendungDreh} />
              </td>
              <td>
                <AdminListIcon
                  checked={sashProfileRoof.oeffnungsrichtungEinwaerts}
                />
              </td>
              <td>
                <AdminListIcon
                  checked={sashProfileRoof.oeffnungsrichtungAuswaerts}
                />
              </td>
              <td>
                <AdminListIcon
                  checked={sashProfileRoof.verriegelungsbeschlagGeeignet}
                />
              </td>
              <td>
                <AdminActiveSwitch
                  active={sashProfileRoof.active}
                  itemNumber={sashProfileRoof.artNr}
                  description={sashProfileRoof.beschreibung || ''}
                  saveAction={active =>
                    dispatch(
                      changeSashProfileRoof({
                        ...sashProfileRoof,
                        active: active,
                      }),
                    )
                  }
                />
              </td>
              <td>
                <button
                  onClick={() => {
                    enableEditSashProfile(sashProfileRoof);
                  }}
                >
                  Bearbeiten
                </button>
              </td>
            </TableRow>
          ))}
        </Table>
      </div>
      <Pagination
        searchString={searchString}
        numberOfPages={searchResult.length}
        page={page}
        setPage={setPage}
        indexOfFirstPageElement={indexOfFirstPageElement}
        setIndexOfFirstPageElement={setIndexOfFirstPageElement}
      />
    </>
  );
};
export default RoofSashProfileData;
