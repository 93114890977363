import React, { FC, useState } from 'react';
import Dialog from '../../components/Dialog';
import {
  MenuPlacement,
  SelectField,
  selectFieldValue,
  SelectOption,
} from '../../elements/SelectField';
import FormLayout, { FormLayoutElement } from '../../elements/FormLayout';
import {
  Series,
  TestPhase,
  TestPhaseSeriesWithOptionalId,
  TestPhaseSystemWithOptionalId,
  TestPhaseWithOptionalId,
} from '../../redux/admin/adminFacadeReducer';
import { useDispatch, useSelector } from 'react-redux';
import { AdminState, AdminThunkDispatch } from '../../redux/admin/adminStore';
import { AssignSelectGroup } from '../components/AssignSelectGroup';
import _ from 'lodash';
import { updateEditedTestPhaseSystem } from '../../redux/admin/editedTestPhaseActions';
import { AnyAction } from 'redux';

interface CopySeriesDialogProps {
  shown: boolean;
  setShown: (b: boolean) => void;
}

const CopySeriesDialog: FC<
  React.PropsWithChildren<CopySeriesDialogProps>
> = props => {
  const [copyFromSeries, setCopyFromSeries] =
    useState<TestPhaseSeriesWithOptionalId>();
  const [copyFromTestPhase, setCopyFromTestPhase] = useState<
    TestPhaseWithOptionalId | undefined
  >();
  const [copyFromSystem, setCopyFromSystem] = useState<
    TestPhaseSystemWithOptionalId | undefined
  >();
  const dispatch: AdminThunkDispatch<AnyAction> = useDispatch();
  const possibleSourceTestPhases = useSelector<AdminState, TestPhase[]>(
    s => s.adminFacade.testPhases,
  );

  const editedTestPhaseSystem = useSelector<
    AdminState,
    TestPhaseSystemWithOptionalId | undefined
  >(state => state.adminFacade.editedTestPhaseSystem);
  const possibleTargetSeries = useSelector<AdminState, Series[]>(state => {
    const editedSystem = state.adminFacade.editedTestPhaseSystem?.system;

    if (!editedSystem) {
      return [];
    }

    const existingSeriesIds =
      state.adminFacade.editedTestPhaseSystem?.testPhaseSeries.map(
        s => s.series.id,
      ) || [];

    return state.adminFacade.series.filter(
      s => s.system.id === editedSystem.id && !existingSeriesIds.includes(s.id),
    );
  });
  const [selectedSeries, setSelectedSeries] = useState<Series[]>([]);

  function possibleSourceSeries(): TestPhaseSeriesWithOptionalId[] {
    return copyFromSystem?.testPhaseSeries || [];
  }
  function possibleSourceSystems(): TestPhaseSystemWithOptionalId[] {
    return copyFromTestPhase?.testPhaseSystems || [];
  }
  function selectCopyFromSystem(
    newValue: SelectOption<TestPhaseSystemWithOptionalId>,
  ): void {
    if (newValue.value) {
      setCopyFromSystem(newValue.value);
      setCopyFromSeries(undefined);
    }
  }

  function selectCopyFromTestPhase(
    newValue: SelectOption<TestPhaseWithOptionalId>,
  ): void {
    if (newValue.value) {
      setCopyFromTestPhase(newValue.value);
      setCopyFromSystem(undefined);
      setCopyFromSeries(undefined);
    }
  }

  function selectCopyFromSeries(
    newValue: SelectOption<TestPhaseSeriesWithOptionalId>,
  ): void {
    if (newValue.value) {
      setCopyFromSeries(newValue.value);
    }
  }

  function copySeries(): void {
    const seriesWithoutId = _.cloneDeep(copyFromSeries!);
    delete seriesWithoutId.id;
    seriesWithoutId.applicationOverviews.forEach(ao => delete ao.id);
    seriesWithoutId.windLoads.forEach(wl => delete wl.id);
    seriesWithoutId.openingDirection.forEach(od => {
      delete od.id;
      od.anwendungen.forEach(aw => {
        delete aw.id;
        aw.mountingTypes.forEach(mt => {
          delete mt.id;
          mt.profileTypes.forEach(pt => delete pt.id);
        });
      });
    });

    const newTestPhaseSeries = selectedSeries.map(s => {
      const newTestPhaseSeries = _.cloneDeep(seriesWithoutId);
      newTestPhaseSeries.series = s;
      return newTestPhaseSeries;
    });
    const newSystemIds = selectedSeries.map(s => s.system.id);

    const existingSeriesWithoutNewOnes =
      editedTestPhaseSystem!.testPhaseSeries.filter(
        tps => !newSystemIds.includes(tps.series.id),
      );

    if (newTestPhaseSeries.length > 0) {
      newTestPhaseSeries.forEach(testPhaseSeries => {
        if (testPhaseSeries.baseProfiles) {
          testPhaseSeries.baseProfiles = [];
        }

        if (testPhaseSeries.exchangeProfiles) {
          testPhaseSeries.exchangeProfiles = [];
        }

        if (testPhaseSeries.frameProfiles) {
          testPhaseSeries.frameProfiles = [];
        }

        if (testPhaseSeries.sashProfiles) {
          testPhaseSeries.sashProfiles = [];
        }
      });
    }

    dispatch(
      updateEditedTestPhaseSystem(editedTestPhaseSystem, {
        testPhaseSeries: [
          ...existingSeriesWithoutNewOnes,
          ...newTestPhaseSeries,
        ],
      }),
    );
  }

  return (
    <Dialog
      headingText="Serie kopieren"
      setDialogIsShown={props.setShown}
      dialogIsShown={props.shown}
      componentClass="copy-series-dialog"
      nested={true}
      footerProps={{
        notTranslated: true,
        cancelAction: () => props.setShown(false),
        primaryActionLabelText: 'Kopieren',
        saveAction: copyFromSeries
          ? () => {
              props.setShown(false);
              copySeries();
            }
          : undefined,
      }}
    >
      <FormLayout>
        <FormLayoutElement rowStart={1} columnStart={1} columnWidth={3}>
          <SelectField
            name="kopieren von Testphase"
            placeholder="Testphase"
            action={selectCopyFromTestPhase}
            options={possibleSourceTestPhases.map(s => {
              return {
                label: s.name,
                value: s,
              };
            })}
            value={
              copyFromTestPhase &&
              selectFieldValue(copyFromTestPhase.name, copyFromTestPhase)
            }
            menuPlacement={MenuPlacement.BOTTOM}
          />
        </FormLayoutElement>
        <FormLayoutElement rowStart={2} columnStart={1} columnWidth={3}>
          <SelectField
            name="kopieren von System"
            placeholder="System"
            key={copyFromSystem?.id}
            action={selectCopyFromSystem}
            options={possibleSourceSystems().map(s => {
              return {
                label: s.system.name,
                value: s,
              };
            })}
            value={
              copyFromSystem &&
              selectFieldValue(copyFromSystem?.system.name, copyFromSystem)
            }
            menuPlacement={MenuPlacement.BOTTOM}
          />
        </FormLayoutElement>
        <FormLayoutElement rowStart={3} columnStart={1} columnWidth={3}>
          <SelectField
            name="kopieren von Serie"
            placeholder="Serie"
            key={copyFromSeries?.id}
            action={selectCopyFromSeries}
            options={possibleSourceSeries().map(s => {
              return {
                label: s.series.name,
                value: s,
              };
            })}
            value={
              copyFromSeries &&
              selectFieldValue(copyFromSeries?.series.name, copyFromSeries)
            }
            menuPlacement={MenuPlacement.BOTTOM}
          />
        </FormLayoutElement>
        <FormLayoutElement rowStart={4} columnStart={1} columnWidth={3}>
          <AssignSelectGroup
            headings={{
              headingLeftTable: 'verfügbare Serien',
              headingRightTable: 'kopieren nach',
            }}
            componentClass=""
            addElements={(s: Series[]) =>
              setSelectedSeries([...s, ...selectedSeries])
            }
            removeElements={(s: Series[]) =>
              setSelectedSeries(
                selectedSeries.filter(series => s.includes(series)),
              )
            }
            selectedElements={selectedSeries}
            deselectedElements={possibleTargetSeries.filter(
              ps => !selectedSeries.includes(ps),
            )}
            displayNameExtractor={(s: Series) => s.name}
            backgroundWhite={true}
          />
        </FormLayoutElement>
      </FormLayout>
    </Dialog>
  );
};

export default CopySeriesDialog;
