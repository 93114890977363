import MuiDialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import { Locale } from '../../redux/uiStateReducer';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import React, { useState } from 'react';
import './TutorialDialog.scss';

export interface TutorialDialogProps {
  locale: Locale;
  userIsAuthenticated: boolean;
  open: boolean;
  onClose: () => void;
}

export default function TutorialDialog(props: TutorialDialogProps) {
  const { onClose, open } = props;

  const handleClose = () => {
    onClose();
  };

  const helpHeadline = 'HELP_HEADLINE';

  let tutorialItems: any[] = [];

  if (props.userIsAuthenticated) {
    if (props.locale === 'de') {
      tutorialItems = [
        {
          title: 'HELP_SIMPLE_DRIVE_CALCULATION',
          videoUrl: 'https://player.vimeo.com/video/507058906',
          message: null,
        },
        {
          title: 'HELP_SHEV_CALCULATION_EXAMPLE',
          videoUrl: 'https://player.vimeo.com/video/507054564',
          message: null,
        },
        {
          title: 'HELP_NSHEV_CALCULATION_EXAMPLE',
          videoUrl: 'https://player.vimeo.com/video/507053174',
          message: null,
        },
      ];
    } else {
      tutorialItems = [
        {
          title: 'HELP_SIMPLE_DRIVE_CALCULATION',
          videoUrl: 'https://player.vimeo.com/video/507058979',
          message: null,
        },
        {
          title: 'HELP_SHEV_CALCULATION_EXAMPLE',
          videoUrl: 'https://player.vimeo.com/video/507054649',
          message: null,
        },
        {
          title: 'HELP_NSHEV_CALCULATION_EXAMPLE',
          videoUrl: 'https://player.vimeo.com/video/507053259',
          message: null,
        },
      ];
    }
  } else {
    if (props.locale === 'de') {
      tutorialItems = [
        {
          title: 'HELP_SIMPLE_DRIVE_CALCULATION',
          videoUrl: 'https://player.vimeo.com/video/507058906',
          message: null,
        },
        {
          title: 'HELP_SHEV_CALCULATION_EXAMPLE',
          videoUrl: null,
          message: 'HELP_MESSAGE',
        },
        {
          title: 'HELP_NSHEV_CALCULATION_EXAMPLE',
          videoUrl: null,
          message: 'HELP_MESSAGE',
        },
      ];
    } else {
      tutorialItems = [
        {
          title: 'HELP_SIMPLE_DRIVE_CALCULATION',
          videoUrl: 'https://player.vimeo.com/video/507058979',
        },
        {
          title: 'HELP_SHEV_CALCULATION_EXAMPLE',
          videoUrl: null,
          message: 'HELP_MESSAGE',
        },
        {
          title: 'HELP_NSHEV_CALCULATION_EXAMPLE',
          videoUrl: null,
          message: 'HELP_MESSAGE',
        },
      ];
    }
  }

  const [activeDialogItem, setActiveDialogItem] = useState(
    tutorialItems[0].title,
  );

  return (
    <MuiDialog
      onClose={handleClose}
      open={open}
      fullWidth={true}
      maxWidth="xl"
      className="tutorial-dialog"
    >
      <DialogTitle className="tutorial-dialog__heading">
        <FormattedMessage id={helpHeadline} />
      </DialogTitle>
      <div className="information-dialog__nav-container">
        {tutorialItems.map(tutorialItem => (
          <div
            className={classNames(
              'information-dialog__nav-item information-dialog__nav-item',
              {
                'information-dialog__nav-item information-dialog__nav-item--active':
                  activeDialogItem === tutorialItem.title,
                'information-dialog__nav-item inactive':
                  tutorialItem.videoUrl === null,
              },
            )}
            key={`${tutorialItem.title}_menu`}
            onClick={() => {
              setActiveDialogItem(tutorialItem.title);
            }}
          >
            <FormattedMessage id={tutorialItem.title} />
          </div>
        ))}
      </div>
      {tutorialItems.map(tutorialItem =>
        tutorialItem.title === activeDialogItem ? (
          <div className="information-dialog__content" key={tutorialItem.title}>
            {tutorialItem.videoUrl ? (
              <iframe
                src={tutorialItem.videoUrl}
                width="1284"
                height="723"
                frameBorder="0"
                allow="fullscreen; picture-in-picture"
                allowFullScreen
              ></iframe>
            ) : (
              <div className="tutorial-dialog__message">
                <p className="tutorial-dialog__paragraph">
                  <FormattedMessage id={tutorialItem.message} />
                </p>
              </div>
            )}
          </div>
        ) : null,
      )}
      <div className="clear"></div>
    </MuiDialog>
  );
}
