import React, { FC, ReactNode } from 'react';
import './ToolTip.scss';
import { useSelector } from 'react-redux';
import { IState } from '../redux/store';
import classNames from 'classnames';
import { conditionalClass } from '../elements/element_utils';

interface ToolTipProps {
  text: ReactNode;
  children: ReactNode;
  additionalClass?: string;
}

const ToolTip: FC<React.PropsWithChildren<ToolTipProps>> = (
  props: ToolTipProps,
) => {
  const overlayActive = useSelector(
    (state: IState) => state.ui.overlayIsActive,
  );
  return (
    <div
      className={classNames('tooltip', {
        'tooltip tooltip--overlay-active': overlayActive,
      })}
    >
      <div>{props.children}</div>
      <div
        className={classNames(
          'tooltip__text' + conditionalClass(props.additionalClass),
        )}
      >
        {props.text}
      </div>
    </div>
  );
};

export default ToolTip;
