import { Reducer } from 'redux';
import { NRWG_ACTIONS } from './nrwgActions';

export interface TestParameterWithInfo {
  value: string;
  info: string[];
  id: string;
}

export type TestSearchOptions = {
  [key: string]: string[] | TestParameterWithInfo[];
  sideWindConsideration: string[];
  profileSystem: string[];
  profileSeries: TestParameterWithInfo[];
  openingDirection: string[];
  application: string[];
  mountingType: string[];
  aerodynamicCase: string[];
  drivePosition: string[];
  profileSash: TestParameterWithInfo[];
  profileFrame: TestParameterWithInfo[];
  profileExchange: TestParameterWithInfo[];
  profileBase: TestParameterWithInfo[];
  assemblyType: string[];
};

export interface Range {
  max: number;
  min: number;
  calculatedValue?: number;
  label?: string;
}

export interface Message {
  message: string;
  values: Record<string, string>;
  heading: boolean;
}

export interface Ranges {
  area: Range;
  angleOfInstallation: Range;
  windLoad: Range;
  snowLoad: Range;
  sashHeight: Range;
  sashWidth: Range;
  sashWeight: Range;
  percentOfDistanceToHinge: Range;
  correctionFactor: Range;
  numberOfDrives: Range;
  openingAngle: Range;
  ratioSashWidthToDoubleFlapHeight: Range | undefined;
  sashFrameDistance: Range | undefined;
  ratioSashWidthToSashHeight: Range | undefined;
  ratioClearWidthToClearHeight: Range | undefined;
  ratioSashHeightToSashWidth: Range | undefined;
  ratioClearHeightToClearWidth: Range | undefined;
  doubleFlapHeight: Range | undefined;
  doubleFlapWidth: Range | undefined;
  ratioClearWidthToClearDoubleFlapHeight: Range | undefined;
  ratioClearDoubleFlapWidthToClearHeight: Range | undefined;
  ratioDoubleFlapHeightToSashWidth: Range | undefined;
  ratioSashHeightToDoubleFlapWidth: Range | undefined;
  ratioClearDoubleFlapHeightToClearWidth: Range | undefined;
  ratioClearHeightToClearDoubleFlapWidth: Range | undefined;
  ratioDoubleFlapWidthToSashHeight: Range | undefined;
  glassThickness: Range | undefined;
  messagesForUnvalidatedRanges: Message[];
}

export type RangeKeys = Exclude<keyof Ranges, 'messagesForUnvalidatedRanges'>;

export interface Validation {
  [key: string]: boolean;

  sashHeight: boolean;
  sashWidth: boolean;
  area: boolean;
  angleOfInstallation: boolean;
  windLoad: boolean;
  snowLoad: boolean;
  clearWidth: boolean;
  clearHeight: boolean;
  ratioClearWidthToClearHeight: boolean;
  ratioClearHeightToClearWidth: boolean;
  ratioSashWidthToSashHeight: boolean;
  ratioSashHeightToSashWidth: boolean;
  sashWeight: boolean;
  distanceToHinge: boolean;
  correctionFactor: boolean;
  numberOfDrives: boolean;
  ratioWidthToDoubleFlapHeight: boolean;
  sashFrameDistance: boolean;
  lockingDrive: boolean;
  valid: boolean;
  ratioClearWidthToClearDoubleFlapHeight: boolean;
  ratioClearDoubleFlapWidthToClearHeight: boolean;
  ratioDoubleFlapHeightToSashWidth: boolean;
  ratioSashHeightToDoubleFlapWidth: boolean;
  ratioClearDoubleFlapHeightToClearWidth: boolean;
  ratioClearHeightToClearDoubleFlapWidth: boolean;
  ratioDoubleFlapWidthToSashHeight: boolean;
  glassThickness: boolean;
}

export interface PerformanceClassEntry {
  label: string;
  details: string;
}

export interface PerformanceClasses {
  [key: string]: PerformanceClassEntry | undefined;

  aerodynamics: PerformanceClassEntry;
  safety: PerformanceClassEntry;
  snowLoad: PerformanceClassEntry | undefined;
  temperature: PerformanceClassEntry;
  windLoad: PerformanceClassEntry;
  heat: PerformanceClassEntry;
}

export interface WindDeflectorDocument {
  languages: string[];
  type: string;
  fileName: string;
  url?: string;
}

export interface WindDeflector {
  height: number;
  length: number;
  number: number;
  numberOfCornerBracket: number;
  numberOfMiddleBracket: number;
  numberOfGlassBracket: number;

  nameOfCornerBracket: string;
  nameOfMiddleBracket: string;
  nameOfGlassBracket: string;
  glass: boolean;
  sheetMetal: boolean;
  documents: WindDeflectorDocument[];
}

export interface NrwgState {
  performanceClasses?: PerformanceClasses;
  testSearchOptions: TestSearchOptions;
  ranges?: Ranges;
  validation?: Validation;
  windDeflectors?: WindDeflector[];
}

export const INITIAL_STATE: NrwgState = {
  testSearchOptions: {
    sideWindConsideration: ['dummy'],
    profileSystem: ['dummy'],
    profileSeries: [{ value: 'dummy', info: ['dummy'], id: 'dummy' }],
    openingDirection: ['dummy'],
    application: ['dummy'],
    mountingType: ['dummy'],
    aerodynamicCase: ['dummy'],
    drivePosition: ['dummy'],
    profileSash: [{ value: 'dummy', info: ['dummy'], id: 'dummy' }],
    profileFrame: [{ value: 'dummy', info: ['dummy'], id: 'dummy' }],
    profileExchange: [{ value: 'dummy', info: ['dummy'], id: 'dummy' }],
    profileBase: [{ value: 'dummy', info: ['dummy'], id: 'dummy' }],
    assemblyType: ['dummy'],
  },
};

const nrwgReducer: Reducer<NrwgState | undefined, NRWG_ACTIONS> = (
  state: NrwgState | undefined,
  action: NRWG_ACTIONS,
) => {
  if (state === undefined) {
    return INITIAL_STATE;
  }

  switch (action.type) {
    case 'UPDATE_TEST_SEARCH_OPTIONS': {
      return { ...state, testSearchOptions: action.testSearchOptions };
    }
    case 'UPDATE_RANGES': {
      return { ...state, ranges: action.ranges };
    }
    case 'UPDATE_VALIDATION': {
      return { ...state, validation: action.validation };
    }
    case 'UPDATE_PERFORMANCE_CLASSES': {
      return { ...state, performanceClasses: action.performanceClasses };
    }
    case 'UPDATE_WIND_DEFLECTORS': {
      return { ...state, windDeflectors: action.windDeflectors };
    }
    default:
      return state;
  }
};

export default nrwgReducer;
export const TRANSLATED_TEST_SEARCH_OPTION_VALUES = [
  'NO_BASE_PROFILE',
  'NO_EXCHANGE_PROFILE',
];
