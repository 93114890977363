import React, {
  FC,
  ReactElement,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { FormattedMessage } from 'react-intl';
import { Drive } from '../redux/calculationResultReducer';
import { useDispatch, useSelector } from 'react-redux';
import { MDCFormField } from '@material/form-field';
import { MDCCheckbox } from '@material/checkbox';
import { IState, MyCalcThunkDispatch } from '../redux/store';
import {
  ConsoleSet,
  Document,
  DocumentTypes,
  DocumentVoltage,
  StaticData,
} from '../redux/staticDataReducer';
import { Locale } from '../redux/uiStateReducer';
import './DownloadDialog.scss';
import '@material/form-field/mdc-form-field.scss';
import '@material/checkbox/mdc-checkbox.scss';
import moment from 'moment';
import classNames from 'classnames';
import loadingCube from './loading-cube.json';
import { showDialog, updateDownloadStatus } from '../redux/uiStateActions';
import Cookies from 'js-cookie';
import lottie, { AnimationItem } from 'lottie-web';
import { Project, Window } from '../redux/projectsReducer';

import Button, {
  ButtonPosition,
  ButtonSize,
  ButtonType,
} from '../elements/Button';
import { DialogFE } from './Dialog';
import { calcultationURLParameters } from '../redux/httpClient';
import { Voltage } from '../redux/constants';
import { WindDeflectorDocument } from '../redux/nrwgReducer';
import {
  getMarkedDrive,
  getSelectedWindow,
  useSelectedDrive,
  useSelectedWindow,
} from '../hooks/selectorHooks';
import { triggerAnalyticsEvent } from '../google_analytics/events';
import { AnyAction } from 'redux';

interface DownloadDialogProps {
  showWindDeflectorDocuments: boolean;
  selectedDrive: Drive;
  showConsoleDocuments: boolean;
  primary?: boolean;
  smallButton?: boolean;
  additionalClassNames?: string;
}

const LOTTIE_PROPS = {
  loop: true,
  autoplay: true,
  animationData: loadingCube,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

interface LoadingCubeProps {
  message?: string;
}

export const LoadingCube: FC<
  React.PropsWithChildren<LoadingCubeProps>
> = props => {
  const lottieRef = useRef<HTMLDivElement>(null);
  const animation = useRef<AnimationItem | null>(null);

  useEffect(() => {
    if (lottieRef.current) {
      animation.current = lottie.loadAnimation({
        ...LOTTIE_PROPS,
        container: lottieRef.current,
      });
    }
    return () => {
      if (animation.current) {
        animation.current.destroy();
      }
    };
  }, [lottieRef.current]);

  const lottieStyles = {
    width: '100px',
    height: '100px',
    overflow: 'hidden',
    margin: '0 auto',
    outline: 'none',
  };

  return (
    <div className="download-dialog__loading-overlay">
      {' '}
      <div
        className="download-dialog__loading-overlay-animation"
        style={lottieStyles}
      >
        <div ref={lottieRef} />
      </div>
      <div className="download-dialog__loading-overlay-text">
        <FormattedMessage id={props.message || 'DOWNLOAD_FEEDBACK_TEXT'} />
      </div>
    </div>
  );
};

export const DOWNLOAD_FINISHED_COOKIE_NAME = 'downloadFinishedCookie';

type AnyDocument = Document | WindDeflectorDocument;

function addUrls(
  documents: WindDeflectorDocument[] | undefined,
  baseUrl: string,
  selectedWindow: Window | undefined,
  selectedProject: Project | undefined,
): WindDeflectorDocument[] | undefined {
  documents?.forEach(d => {
    if (!d) {
      console.error(
        `Documents contains empty entry (window: ${
          selectedWindow?.id
        }, project: ${selectedProject?.id}): ${JSON.stringify(documents)}`,
      );
      return;
    }

    d.url = `${baseUrl}${d.fileName}`;
  });

  return documents;
}

export function DownloadDialog(props: DownloadDialogProps): ReactElement {
  const selectedDrive = useSelectedDrive();
  const LockingDriveOrConsole = selectedDrive?.lockingDriveInformation;
  // const state: IState = useSelector<IState, IState>(s => s);
  const numberOfDrives = useSelector<IState, number>(
    s => s.parameters.numberOfDrives.value as number,
  );
  const staticData: StaticData = useSelector<IState, StaticData>(
    s => s.staticData,
  );
  const currentLocale = useSelector<IState, Locale>(
    (s: IState) => s.authentication.locale,
  );
  const downloadActive = useSelector<IState>(
    (s: IState) => s.ui.downloadActive,
  );
  const urlParameters = useSelector<IState>(s =>
    calcultationURLParameters(s, {
      acceptedHints: s.ui.acceptedHints.map(hint => hint.type).join(';'),
    }),
  );

  const selectedDriveName = useSelector<IState, string>(
    s =>
      getSelectedWindow(s)?.selectedDrive?.name ??
      getMarkedDrive(s)?.name ??
      '',
  );
  const nrwgManualDocuments = staticData.nrwgManualDocuments;
  const driveDocuments = staticData.driveDocuments;
  const [selectedDriveDocuments, setSelectedDriveDocuments] = useState<
    AnyDocument[]
  >([]);
  const [selectedConsoleDocuments, setSelectedConsoleDocuments] = useState<
    AnyDocument[]
  >([]);
  const [selectedLockingDocuments, setSelectedLockingDocuments] = useState<
    AnyDocument[]
  >([]);
  const [selectedLockingConsoleDocuments, setSelectedLockingConsoleDocuments] =
    useState<AnyDocument[]>([]);
  const [selectedNrwgManualDocuments, setSelectedNrwgManualDocuments] =
    useState<AnyDocument[]>([]);
  const [selectedWindDeflectorDocuments, setSelectedWindDeflectorDocuments] =
    useState<AnyDocument[]>([]);
  const consoleDocuments = useSelector<IState, Document[][]>(
    s => s.staticData.consoleDocuments || [],
  );
  const lockingDocuments = useSelector<IState, Document[]>(state =>
    filterForLanguage(state.staticData.lockingDriveDocuments),
  );

  const lockingConsoleDocuments = useSelector<IState, Document[]>(state =>
    filterForLanguage(state.staticData.lockingConsoleDocuments),
  );

  const [specificationSelected, setSpecificationSelected] = useState(true);
  const [dopSelected, setDopSelected] = useState(false);
  const [auditLabelSelected, setAuditLabelSelected] = useState(false);
  const [auditSpecificationSelected, setAuditSpecificationSelected] =
    useState(false);
  const dispatch: MyCalcThunkDispatch<AnyAction> = useDispatch();
  const selectedWindow = useSelectedWindow();
  const selectedProject = useSelector<IState, Project | undefined>(
    s => s.ui.selectedProject,
  );
  const windDeflectorDocuments = useSelector<
    IState,
    WindDeflectorDocument[] | undefined
  >(s =>
    addUrls(
      getSelectedWindow(s)?.windDeflector?.documents,
      '/windDeflectorDocuments/',
      selectedWindow,
      selectedProject,
    ),
  );
  const selectedConsoleSets = useSelector<IState, ConsoleSet[]>(
    s => s.ui.selectedConsoleSets,
  );

  function downloadCookieExists(): RegExpMatchArray | null {
    return document.cookie.match(DOWNLOAD_FINISHED_COOKIE_NAME);
  }

  function getWindDeflectorDocuments(): WindDeflectorDocument[] | undefined {
    return selectedWindow?.locked
      ? selectedWindow.windDeflector?.documents
      : windDeflectorDocuments;
  }

  function checkCookie(): void {
    if (downloadCookieExists() !== null) {
      dispatch(updateDownloadStatus(false));
      Cookies.remove(DOWNLOAD_FINISHED_COOKIE_NAME);
    } else {
      setTimeout(checkCookie, 200);
    }
  }

  function setDownloadStatus(): void {
    if (
      moreThanOneSelected() ||
      specificationSelected ||
      dopSelected ||
      auditLabelSelected ||
      auditSpecificationSelected
    ) {
      dispatch(updateDownloadStatus(true));
      setTimeout(checkCookie, 200);
    }
  }

  function languageStrokeFilteredDriveDocuments(): Document[] {
    return driveDocuments!.filter(
      d =>
        (!d.languages || d.languages.includes(currentLocale)) &&
        (d.stroke === props.selectedDrive.maximumStroke || !d.stroke),
    );
  }

  function nonCADDocuments(): Document[] {
    return languageStrokeFilteredDriveDocuments().filter(
      d => !d.type.includes(DocumentTypes.CAD),
    );
  }

  function onlyCADDocuments(): Document[] {
    return languageStrokeFilteredDriveDocuments().filter(
      d => d.type.includes(DocumentTypes.CAD) && documentHasStroke(d),
    );
  }

  function documentHasStroke(document: Document): boolean {
    if (document.stroke === null) {
      return false;
    }
    return true;
  }

  function filteredDriveDocuments(): Document[] {
    if (driveDocuments && selectedDrive) {
      let cadDocuments: Document[] = [];

      if (numberOfDrives === 1) {
        cadDocuments = onlyCADDocuments().filter(
          d =>
            d.type === DocumentTypes.CAD || d.type === DocumentTypes.CAD_RIGHT,
        );
      }

      if (
        numberOfDrives &&
        ((numberOfDrives >= 2 &&
          selectedDrive.voltage === Voltage.ANTRIEB_SPANNUNG_24V) ||
          (numberOfDrives > 2 &&
            selectedDrive.voltage === Voltage.ANTRIEB_SPANNUNG_230V))
      ) {
        cadDocuments = onlyCADDocuments().filter(
          d =>
            d.type === DocumentTypes.CAD_RIGHT ||
            d.type === DocumentTypes.CAD_LEFT ||
            d.type === DocumentTypes.CAD,
        );
      }

      if (
        numberOfDrives === 2 &&
        selectedDrive.voltage === Voltage.ANTRIEB_SPANNUNG_230V
      ) {
        cadDocuments = onlyCADDocuments().filter(
          d =>
            (d.type === DocumentTypes.CAD_RIGHT ||
              d.type === DocumentTypes.CAD_LEFT) &&
            [
              DocumentVoltage.TWO_HUNDRED_THIRTY_VOLTS,
              DocumentVoltage.ON_24_VOLTS,
            ].includes(d.voltage),
        );
      }
      return [...nonCADDocuments(), ...cadDocuments];
    } else {
      return [];
    }
  }

  function toggleDriveDocumentSelection(document: AnyDocument): void {
    toggleDocumentSelection(
      document,
      selectedDriveDocuments,
      setSelectedDriveDocuments,
    );
  }

  function toggleConsoleDocumentSelection(document: AnyDocument): void {
    toggleDocumentSelection(
      document,
      selectedConsoleDocuments,
      setSelectedConsoleDocuments,
    );
  }

  function toggleLockingDocumentSelection(document: AnyDocument): void {
    toggleDocumentSelection(
      document,
      selectedLockingDocuments,
      setSelectedLockingDocuments,
    );
  }

  function toggleLockingConsoleDocumentSelection(document: AnyDocument): void {
    toggleDocumentSelection(
      document,
      selectedLockingConsoleDocuments,
      setSelectedLockingConsoleDocuments,
    );
  }

  function toggleNrwgManualDocumentSelection(document: AnyDocument): void {
    toggleDocumentSelection(
      document,
      selectedNrwgManualDocuments,
      setSelectedNrwgManualDocuments,
    );
  }

  function toggleWindDeflectorDocumentSelection(
    document: WindDeflectorDocument,
  ): void {
    toggleDocumentSelection(
      document,
      selectedWindDeflectorDocuments,
      setSelectedWindDeflectorDocuments,
    );
  }

  function toggleDocumentSelection(
    document: AnyDocument,
    selectedDocuments: AnyDocument[],
    setSelectedDocuments: (ds: AnyDocument[]) => void,
  ): void {
    if (selectedDocuments.includes(document)) {
      setSelectedDocuments(
        selectedDocuments.filter((d: AnyDocument) => d !== document),
      );
    } else {
      setSelectedDocuments([...selectedDocuments, document]);
    }
  }

  function allDriveDocumentsSelected(): boolean {
    return (
      (specificationSelected || !!selectedWindow?.nrwg) &&
      selectedDriveDocuments.length === filteredDriveDocuments().length
    );
  }

  function allNrwgDocumentsSelected(): boolean {
    return (
      (dopSelected || !selectedWindow?.nrwg) &&
      ((auditSpecificationSelected && auditLabelSelected) ||
        !selectedWindow?.locked)
    );
  }

  function toggleSelectAllNrwgDocuments(): void {
    if (!allNrwgDocumentsSelected()) {
      setSpecificationSelected(true);
      setDopSelected(true);

      if (selectedWindow?.locked) {
        setAuditLabelSelected(true);
        setAuditSpecificationSelected(true);
        setSelectedNrwgManualDocuments(nrwgManualDocuments || []);
      }
    } else {
      setSpecificationSelected(false);
      setDopSelected(false);
      setAuditLabelSelected(false);
      setAuditSpecificationSelected(false);
      setSelectedNrwgManualDocuments([]);
    }
  }

  function toggleSelectAllDriveDocuments(): void {
    if (!allDriveDocumentsSelected()) {
      !selectedWindow?.nrwg && setSpecificationSelected(true);
      setSelectedDriveDocuments(filteredDriveDocuments());
    } else {
      !selectedWindow?.nrwg && setSpecificationSelected(false);
      setSelectedDriveDocuments([]);
    }
  }

  function specificationDownloadLink(): string {
    return `/specification?${specificationParameterString()}`;
  }

  function dopDownloadLink(): string {
    return `/declarationOfPerformance?${specificationParameterString()}`;
  }

  function auditLabelLink(): string {
    return `/auditLabels?${specificationParameterString()}`;
  }

  function auditSpecificationLink(): string {
    return `/auditSpecifications?${specificationParameterString()}`;
  }

  function downloadLinksForSelectedDocuments(): string {
    return [
      ...selectedDriveDocuments.map(driveDocument => driveDocument.url),
      ...selectedConsoleDocuments.map(consoleDocument => consoleDocument.url),
      ...selectedLockingDocuments.map(lockingDocument => lockingDocument.url),
      ...selectedLockingConsoleDocuments.map(
        lockingDocument => lockingDocument.url,
      ),
      ...selectedNrwgManualDocuments.map(nrwgManual => nrwgManual.url),
      ...selectedWindDeflectorDocuments.map(
        windDeflectorDocuments => windDeflectorDocuments.url,
      ),
    ].join(',');
  }

  function fileNamesForDownloadLinks(): string {
    return [
      ...selectedDriveDocuments.map(driveDocuments =>
        btoa(encodeURI(driveDocuments.fileName)),
      ),
      ...selectedConsoleDocuments.map(consoleDocuments =>
        btoa(encodeURI(consoleDocuments.fileName)),
      ),
      ...selectedLockingDocuments.map(lockingDocument =>
        btoa(lockingDocument.fileName),
      ),
      ...selectedLockingConsoleDocuments.map(lockingConsoleDocument =>
        btoa(lockingConsoleDocument.fileName),
      ),
      ...selectedNrwgManualDocuments.map(nrwgManual =>
        btoa(encodeURI(nrwgManual.fileName)),
      ),
      ...selectedWindDeflectorDocuments.map(windDeflectorDocuments =>
        btoa(encodeURI(windDeflectorDocuments.fileName)),
      ),
    ].join(',');
  }

  function zipDownloadLink(): string {
    return `/downloads/${zipDownloadName(
      selectedDriveName,
    )}?downloadLinks=${downloadLinksForSelectedDocuments()}&fileNames=${fileNamesForDownloadLinks()}&includeSpecification=${specificationSelected}&includeDop=${dopSelected}&includeAuditLabel=${auditLabelSelected}&includeAuditSpecification=${auditSpecificationSelected}&${specificationParameterString()}`;
  }

  function specificationParameterString(): string {
    return `${urlParameters}`;
  }

  function zipDownloadName(driveName: string): string {
    return `${downloadFileNameSuffix(driveName)}.zip`;
  }

  function downloadFileNameSuffix(downloadFileName: string): string {
    const today = moment().toISOString().slice(0, 10);

    return `${downloadFileName
      .trim()
      .replaceAll('/', '-')
      .split(/\s\//)
      .join('_')}-${today}`;
  }

  function oneForTrue(b: boolean): 1 | 0 {
    return b ? 1 : 0;
  }

  function numberOfSelectedDocuments(): number {
    return (
      oneForTrue(specificationSelected) +
      oneForTrue(dopSelected) +
      oneForTrue(auditLabelSelected) +
      oneForTrue(auditSpecificationSelected) +
      selectedDriveDocuments.length +
      selectedConsoleDocuments.length +
      selectedLockingDocuments.length +
      selectedLockingConsoleDocuments.length +
      selectedWindDeflectorDocuments.length +
      selectedNrwgManualDocuments.length
    );
  }

  function onlyOneIsSelected(): boolean {
    return numberOfSelectedDocuments() === 1;
  }

  function moreThanOneSelected(): boolean {
    return numberOfSelectedDocuments() > 1;
  }

  function downloadLink(): string | undefined {
    if (onlyOneIsSelected()) {
      if (specificationSelected) {
        return specificationDownloadLink();
      } else if (dopSelected) {
        return dopDownloadLink();
      } else if (auditLabelSelected) {
        return auditLabelLink();
      } else if (auditSpecificationSelected) {
        return auditSpecificationLink();
      } else {
        return (
          selectedDriveDocuments[0]?.url ||
          selectedConsoleDocuments[0]?.url ||
          selectedLockingDocuments[0]?.url ||
          selectedLockingConsoleDocuments[0]?.url ||
          selectedWindDeflectorDocuments[0]?.url ||
          selectedNrwgManualDocuments[0]?.url
        );
      }
    }

    if (moreThanOneSelected()) {
      return zipDownloadLink();
    }
  }

  function analyticsEventLabel(): string {
    if (onlyOneIsSelected()) {
      if (specificationSelected) {
        return 'specification';
      } else if (dopSelected) {
        return 'dop';
      } else if (auditLabelSelected) {
        return 'auditLabel';
      } else if (auditSpecificationSelected) {
        return 'auditSpecification';
      } else {
        return (
          selectedDriveDocuments[0]?.type?.toLowerCase() ||
          selectedConsoleDocuments[0]?.type?.toLowerCase() ||
          selectedLockingDocuments[0]?.type?.toLowerCase() ||
          selectedLockingConsoleDocuments[0]?.type?.toLowerCase() ||
          selectedWindDeflectorDocuments[0]?.type?.toLowerCase()
        );
      }
    }
    return 'zip';
  }

  function allConsoleDocumentsSelected(): boolean {
    return (
      selectedConsoleDocuments.length ===
      allFilteredConsoleSetDocuments().length
    );
  }

  function allLockingDocumentsSelected(): boolean {
    return selectedLockingDocuments.length === lockingDocuments.length;
  }

  function allLockingConsoleDocumentsSelected(): boolean {
    return (
      selectedLockingConsoleDocuments.length === lockingConsoleDocuments.length
    );
  }

  function allWindDeflectorDocumentsSelected(): boolean {
    return (
      selectedWindDeflectorDocuments.length ===
      (filteredWindDeflectorDocuments()?.length || 0)
    );
  }

  function toggleSelectAllConsoleDocuments(): void {
    if (allConsoleDocumentsSelected()) {
      setSelectedConsoleDocuments([]);
    } else {
      setSelectedConsoleDocuments(allFilteredConsoleSetDocuments());
    }
  }

  function toggleSelectAllLockingDocuments(): void {
    if (allLockingDocumentsSelected()) {
      setSelectedLockingDocuments([]);
    } else {
      setSelectedLockingDocuments(lockingDocuments);
    }
  }

  function toggleSelectAllLockingConsoleDocuments(): void {
    if (allLockingConsoleDocumentsSelected()) {
      setSelectedLockingConsoleDocuments([]);
    } else {
      setSelectedLockingConsoleDocuments(lockingConsoleDocuments);
    }
  }

  function toggleSelectAllWindDeflectorDocuments(): void {
    if (allWindDeflectorDocumentsSelected()) {
      setSelectedWindDeflectorDocuments([]);
    } else {
      setSelectedWindDeflectorDocuments(filteredWindDeflectorDocuments() || []);
    }
  }

  function filteredConsoleDocuments(consoleIndex: 0 | 1): Document[] {
    return (
      consoleDocuments[consoleIndex]?.filter(
        d => !d.languages || d.languages.includes(currentLocale),
      ) || []
    );
  }

  function allFilteredConsoleSetDocuments(): Document[] {
    return [...filteredConsoleDocuments(0), ...filteredConsoleDocuments(1)];
  }

  function resetSelectedDocuments(): void {
    setSpecificationSelected(true);
    setDopSelected(false);
    setAuditLabelSelected(false);
    setAuditSpecificationSelected(false);
    setSelectedWindDeflectorDocuments([]);
    setSelectedDriveDocuments([]);
    setSelectedConsoleDocuments([]);
    setSelectedLockingDocuments([]);
    setSelectedLockingConsoleDocuments([]);
  }

  function filteredWindDeflectorDocuments():
    | WindDeflectorDocument[]
    | undefined {
    if (!getWindDeflectorDocuments()) {
      return;
    }

    return getWindDeflectorDocuments()?.filter(d =>
      d.languages.includes(currentLocale),
    );
  }

  function filterForLanguage(lockingDocuments: Document[]): Document[] {
    return lockingDocuments.filter(
      document =>
        !document.languages || document.languages.includes(currentLocale),
    );
  }

  function displayTwoConsoleSetSections() {
    return (
      selectedConsoleSets.length === 2 &&
      selectedConsoleSets[0]?.name !== selectedConsoleSets[1]?.name
    );
  }

  return (
    <DialogFE
      heading="DOCUMENT_DOWNLOAD_HEADING"
      componentClass={'download-dialog'}
      footerProps={{
        saveAction: downloadLink()
          ? () => {
              setDownloadStatus();
              triggerAnalyticsEvent(
                'download',
                'downloads',
                analyticsEventLabel(),
              );
            }
          : undefined,
        primaryActionLabel: 'DOCUMENT_DOWNLOAD_DOWNLOAD_BUTTON',
        cancelAction: true,
        saveLink: downloadLink(),
      }}
    >
      <>
        {' '}
        {downloadActive && <LoadingCube />}
        {selectedWindow?.nrwg && (
          <>
            <DownloadSectionHeader
              onClick={toggleSelectAllNrwgDocuments}
              allSelected={allNrwgDocumentsSelected()}
              heading="NRWG_DOWNLOAD_SECTION_HEADER"
            />
            <div className="download-dialog__content">
              <CheckBox
                label="SPECIFICATION"
                onClick={() => {
                  setSpecificationSelected(!specificationSelected);
                }}
                checked={specificationSelected}
                id="SPECIFICATION"
              />
              {selectedWindow?.nrwg && (
                <CheckBox
                  label="result_docs_performance_label"
                  onClick={() => {
                    setDopSelected(!dopSelected);
                  }}
                  checked={dopSelected}
                  id="DOP_DOWNLOAD_BUTTON"
                />
              )}
              {selectedWindow?.locked && (
                <>
                  <CheckBox
                    label="result_docs_celabel_label"
                    onClick={() => {
                      setAuditLabelSelected(!auditLabelSelected);
                    }}
                    checked={auditLabelSelected}
                    id="AUDIT_LABEL_DOWNLOAD_BUTTON"
                  />
                  <CheckBox
                    label="result_docs_testingcondition_label"
                    onClick={() => {
                      setAuditSpecificationSelected(
                        !auditSpecificationSelected,
                      );
                    }}
                    checked={auditSpecificationSelected}
                    id="AUDIT_SPECIFICATION_DOWNLOAD_BUTTON"
                  />
                  {nrwgManualDocuments.length > 0 && (
                    <CheckBox
                      label="DOCUMENT_DOWNLOAD_ITEM_DESCRIPTION_MANUAL"
                      onClick={() =>
                        toggleNrwgManualDocumentSelection(
                          nrwgManualDocuments![0],
                        )
                      }
                      checked={selectedNrwgManualDocuments.includes(
                        nrwgManualDocuments[0],
                      )}
                      id="NRWG_MANUAL_DOWNLOAD_BUTTON"
                    />
                  )}
                </>
              )}
            </div>
          </>
        )}
        <>
          <DownloadSectionHeader
            onClick={toggleSelectAllDriveDocuments}
            allSelected={allDriveDocumentsSelected()}
            heading="DOWNLOAD_SECTION_HEADING_DRIVE_DOCUMENTS"
          />
          <div className="download-dialog__subtitle">{selectedDrive?.name}</div>
          <div className="download-dialog__content">
            {!selectedWindow?.nrwg && (
              <CheckBox
                label="SPECIFICATION"
                onClick={() => {
                  setSpecificationSelected(!specificationSelected);
                }}
                checked={specificationSelected}
                id="SPECIFICATION"
              />
            )}
            {driveDocuments &&
              filteredDriveDocuments().map((d: Document) => (
                <CheckBox
                  label={`DOCUMENT_DOWNLOAD_ITEM_DESCRIPTION_${d.type}`}
                  onClick={() => toggleDriveDocumentSelection(d)}
                  checked={selectedDriveDocuments.includes(d)}
                  key={d.fileName}
                  id={d.fileName.replace('.', '')}
                />
              ))}
          </div>
        </>
        {props.showConsoleDocuments && (
          <>
            <>
              <DownloadSectionHeader
                onClick={toggleSelectAllConsoleDocuments}
                allSelected={allConsoleDocumentsSelected()}
                heading="CONSOLE_SET_BYLINE"
              />
              <div className="download-dialog__subtitle">
                {selectedConsoleSets[0]?.name}
              </div>
              <div
                className={classNames('download-dialog__content', {
                  'download-dialog__content--section-following':
                    displayTwoConsoleSetSections(),
                })}
              >
                <div>
                  {filteredConsoleDocuments(0).map((d: Document) => (
                    <CheckBox
                      label={`DOCUMENT_DOWNLOAD_ITEM_DESCRIPTION_${d.type}`}
                      onClick={() => toggleConsoleDocumentSelection(d)}
                      checked={selectedConsoleDocuments.includes(d)}
                      key={d.fileName}
                      id={d.fileName.replace('.', '')}
                    />
                  ))}
                </div>
              </div>
            </>
            {displayTwoConsoleSetSections() && (
              <>
                <div className="download-dialog__subtitle">
                  {selectedConsoleSets[1].name}
                </div>
                <div className="download-dialog__content">
                  <div>
                    {filteredConsoleDocuments(1).map((d: Document) => (
                      <CheckBox
                        label={`DOCUMENT_DOWNLOAD_ITEM_DESCRIPTION_${d.type}`}
                        onClick={() => toggleConsoleDocumentSelection(d)}
                        checked={selectedConsoleDocuments.includes(d)}
                        key={d.fileName}
                        id={d.fileName.replace('.', '')}
                      />
                    ))}
                  </div>
                </div>
              </>
            )}
          </>
        )}
        {LockingDriveOrConsole && (
          <>
            <DownloadSectionHeader
              onClick={toggleSelectAllLockingDocuments}
              allSelected={allLockingDocumentsSelected()}
              heading="LOCKING_DRIVE_DOCUMENTS"
            />
            <div className="download-dialog__content">
              <div>
                {lockingDocuments.map((d: Document) => (
                  <CheckBox
                    label={`DOCUMENT_DOWNLOAD_ITEM_DESCRIPTION_${d.type}`}
                    onClick={() => toggleLockingDocumentSelection(d)}
                    checked={selectedLockingDocuments.includes(d)}
                    key={d.fileName}
                    id={d.fileName.replace('.', '')}
                  />
                ))}
              </div>
            </div>
            {LockingDriveOrConsole.articleConsole && (
              <>
                <DownloadSectionHeader
                  onClick={toggleSelectAllLockingConsoleDocuments}
                  allSelected={allLockingConsoleDocumentsSelected()}
                  heading="LOCKING_CONSOLE_DOCUMENTS"
                />
                <div className="download-dialog__content">
                  <div>
                    {lockingConsoleDocuments.map((d: Document) => (
                      <CheckBox
                        label={`DOCUMENT_DOWNLOAD_ITEM_DESCRIPTION_${d.type}`}
                        onClick={() => toggleLockingConsoleDocumentSelection(d)}
                        checked={selectedLockingConsoleDocuments.includes(d)}
                        key={d.fileName}
                        id={d.fileName.replace('.', '')}
                      />
                    ))}
                  </div>
                </div>
              </>
            )}
          </>
        )}
        {props.showWindDeflectorDocuments &&
          filteredWindDeflectorDocuments() && (
            <>
              <DownloadSectionHeader
                onClick={toggleSelectAllWindDeflectorDocuments}
                allSelected={allWindDeflectorDocumentsSelected()}
                heading="DOWNLOAD_SECTION_HEADING_WIND_DEFLECTOR_DOCUMENTS"
              />
              <div className="download-dialog__content">
                <div>
                  {filteredWindDeflectorDocuments()!.map(
                    (d: WindDeflectorDocument) => (
                      <CheckBox
                        label={`DOCUMENT_DOWNLOAD_ITEM_DESCRIPTION_${d.type}`}
                        onClick={() => toggleWindDeflectorDocumentSelection(d)}
                        checked={selectedWindDeflectorDocuments.includes(d)}
                        key={d.fileName}
                        id={d.fileName.replace('.', '')}
                      />
                    ),
                  )}
                </div>
              </div>
            </>
          )}
      </>
    </DialogFE>
  );
}

function DownloadDialogButton(props: DownloadDialogProps): ReactElement {
  const dispatch: MyCalcThunkDispatch<AnyAction> = useDispatch();

  return (
    <>
      <Button
        label={
          props.smallButton
            ? 'DOCUMENT_DOWNLOAD_BUTTON_SHORT'
            : 'DOCUMENT_DOWNLOAD_BUTTON'
        }
        action={() => {
          dispatch(showDialog(<DownloadDialog {...props} />));
        }}
        type={props.primary ? ButtonType.PRIMARY : ButtonType.SECONDARY}
        additionalClass={classNames(
          'download-dialog__button',
          props.additionalClassNames,
        )}
        iconName="cloud_download"
        size={ButtonSize.LARGE}
        position={props.primary ? ButtonPosition.CENTER : undefined}
        qaId="download-button"
      />
    </>
  );
}

interface DownloadSectionHeaderProps {
  onClick: () => void;
  allSelected: boolean;
  heading: string;
}

const DownloadSectionHeader: FC<
  React.PropsWithChildren<DownloadSectionHeaderProps>
> = (props: DownloadSectionHeaderProps) => {
  return (
    <div className="download-dialog__section-header">
      <div className="download-dialog__title" onClick={props.onClick}>
        <FormattedMessage id={props.heading} />{' '}
        <button
          className={classNames('download-dialog__title-button', {
            'download-dialog__title-button--selected': props.allSelected,
          })}
        >
          <FormattedMessage
            id={
              props.allSelected
                ? 'DOCUMENT_DOWNLOAD_DESELECT_ALL'
                : 'DOCUMENT_DOWNLOAD_SELECT_ALL'
            }
          />
        </button>
      </div>
    </div>
  );
};

interface CheckBoxProps {
  id: string;
  label: string;
  onClick: () => void;
  checked?: boolean;
}

const CheckBox: FC<React.PropsWithChildren<CheckBoxProps>> = (
  props: CheckBoxProps,
) => {
  const [checkBox, setCheckBox] = useState<HTMLInputElement>();
  const [form, setForm] = useState<MDCFormField>();

  const checkBoxRef = useCallback(
    (box: any) => {
      if (!box) {
        return;
      }

      const checkBox = new MDCCheckbox(box);
      setCheckBox(box);

      if (form) {
        form.input = checkBox;
      }
    },
    [form],
  );

  const formRef = useCallback(
    (formElement: any) => {
      if (!formElement) {
        return;
      }
      const localForm = new MDCFormField(formElement);
      setForm(localForm);

      if (checkBox) {
        localForm.input = new MDCCheckbox(checkBox);
      }
    },
    [checkBox],
  );

  return (
    <div className="download-dialog__list-item" ref={formRef}>
      <div className="mdc-form-field">
        <div
          className={classNames('mdc-checkbox download-dialog__checkbox', {
            'mdc-checkbox--selected': props.checked,
          })}
          ref={checkBoxRef}
        >
          <input
            type="checkbox"
            className="mdc-checkbox__native-control"
            id={props.id}
            onClick={props.onClick}
            onChange={() => 'prevent lint warning'}
            data-indeterminate={true}
            checked={props.checked}
          />
          <div className="mdc-checkbox__background download-dialog__checkbox-background download-dialog__checkbox-background">
            <svg className="mdc-checkbox__checkmark" viewBox="0 0 24 24">
              <path
                className="mdc-checkbox__checkmark-path"
                fill="none"
                d="M1.73,12.91 8.1,19.28 22.79,4.59"
              />
            </svg>
            <div className="mdc-checkbox__mixedmark"></div>
          </div>
          <div className="mdc-checkbox__ripple"></div>
        </div>
        <label htmlFor={props.id}>
          <FormattedMessage id={props.label} />
        </label>
      </div>
    </div>
  );
};

export default DownloadDialogButton;
