import { Action, ActionCreator, AnyAction, Dispatch } from 'redux';
import {
  Console,
  ConsoleSet,
  ConsoleSetAssignment,
  CVKurve,
  Series,
  SeriesENMaxStrokeRoof,
} from './adminFacadeReducer';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { AdminState, AdminThunkAction, AdminThunkDispatch } from './adminStore';
import {
  AddConsoleSetAssignmentFacade,
  AdminFacadeThunkAction,
  fetchFrameProfilesRoof,
  fetchSashProfilesRoof,
  fetchSeriesENMaxStrokesRoof,
  fetchTestPhasesRoof,
  getTestPhasesRoof,
  UpdateConsoleSetAssignmentsFacade,
  UpdateEditedStrokesRoof,
  UpdateSeriesENMaxStrokesRoof,
} from './adminFacadeActions';
import {
  FrameProfileRoof,
  NewFrameProfileRoof,
  NewSashProfileRoof,
  SashProfileRoof,
  TestPhaseRoof,
  TestPhaseRoofWithOptionalId,
  WindDeflectorData,
} from './adminRoofReducer';
import {
  fetchWithoutCalculationParameters,
  postAndStore,
  putAndStoreWithDelay,
  RequestTypes,
} from '../httpClient';
import { updateEditedTestPhaseRoof } from './editedTestPhaseActionsRoof';
import { Edit } from '../../types';

export interface UpdateEditedWindDeflector
  extends Action<'UPDATE_EDITED_WIND_DEFLECTOR'> {
  windDeflector: Edit<WindDeflectorData>;
}

export interface UpdateWindDeflectors extends Action<'UPDATE_WIND_DEFLECTORS'> {
  windDeflectors: WindDeflectorData[];
}

export interface AddSeriesRoof extends Action<'ADD_SERIES_ROOF'> {
  series: Series;
}

export interface UpdateAllSeriesRoof extends Action<'UPDATE_ALL_SERIES_ROOF'> {
  series: Series[];
}

export interface UpdateSeriesRoof extends Action<'UPDATE_SERIES_ROOF'> {
  series: Series;
}

export interface AddCvCurveRoof extends Action<'ADD_CV_CURVE_ROOF'> {
  cvCurve: CVKurve;
}

export interface UpdateCvCurveRoof extends Action<'UPDATE_CV_CURVE_ROOF'> {
  cvCurve: CVKurve;
}

export interface UpdateCVKurvenRoof extends Action<'UPDATE_CV_KURVEN_ROOF'> {
  cvKurven: CVKurve[];
}
export interface UpdateEditedFrameProfileRoof
  extends Action<'UPDATE_EDITED_FRAME_PROFILE_ROOF'> {
  frameProfile: Edit<FrameProfileRoof> | Edit<NewFrameProfileRoof>;
}
export interface UpdateEditedSashProfileRoof
  extends Action<'UPDATE_EDITED_SASH_PROFILE_ROOF'> {
  sashProfile: Edit<SashProfileRoof>;
}

export interface UpdateFrameProfilesRoof
  extends Action<'UPDATE_FRAME_PROFILES_ROOF'> {
  frameProfiles: FrameProfileRoof[];
}

export interface UpdateSashProfilesRoof
  extends Action<'UPDATE_SASH_PROFILES_ROOF'> {
  sashProfiles: SashProfileRoof[];
}

export interface UpdateFrameProfileRoof
  extends Action<'UPDATE_FRAME_PROFILE_ROOF'> {
  frameProfile: FrameProfileRoof;
}

export interface UpdateSashProfileRoof
  extends Action<'UPDATE_SASH_PROFILE_ROOF'> {
  sashProfile: SashProfileRoof;
}

export interface AddFrameProfileRoof extends Action<'ADD_FRAME_PROFILE_ROOF'> {
  frameProfile: FrameProfileRoof;
}

export interface UpdateConsoleSetAssignmentsRoof
  extends Action<'UPDATE_CONSOLE_SET_ASSIGNMENTS_ROOF'> {
  consoleSetAssignments: ConsoleSetAssignment[];
}

export interface UpdateConsoleSetAssignmentRoof
  extends Action<'UPDATE_CONSOLE_SET_ASSIGNMENT_ROOF'> {
  consoleSetAssignment: ConsoleSetAssignment;
}

export interface AddConsoleSetAssignmentRoof
  extends Action<'ADD_CONSOLE_SET_ASSIGNMENT_ROOF'> {
  consoleSetAssignment: ConsoleSetAssignment;
}

export interface UpdateConsolesRoof extends Action<'UPDATE_CONSOLES_ROOF'> {
  consoles: Console[];
}

export interface UpdateConsoleRoof extends Action<'UPDATE_CONSOLE_ROOF'> {
  console: Console;
}

export interface AddConsoleRoof extends Action<'ADD_CONSOLE_ROOF'> {
  console: Console;
}

export interface UpdateConsoleSetsRoof
  extends Action<'UPDATE_CONSOLE_SETS_ROOF'> {
  consoleSets: ConsoleSet[];
}

export interface UpdateConsoleSetRoof
  extends Action<'UPDATE_CONSOLE_SET_ROOF'> {
  consoleSet: ConsoleSet;
}

export interface AddConsoleSetRoof extends Action<'ADD_CONSOLE_SET_ROOF'> {
  consoleSet: ConsoleSet;
}

export interface AddSashProfileRoof extends Action<'ADD_SASH_PROFILE_ROOF'> {
  SashProfile: SashProfileRoof;
}

export interface UpdateTestPhasesRoof
  extends Action<'UPDATE_TEST_PHASES_ROOF'> {
  testPhases: TestPhaseRoof[];
}

export interface UpdateTestPhaseRoof extends Action<'UPDATE_TEST_PHASE_ROOF'> {
  testPhase: TestPhaseRoof;
}

export interface AddTestPhaseRoof extends Action<'ADD_TEST_PHASE_ROOF'> {
  testPhase: TestPhaseRoof;
}

export type ADMIN_ROOF_ACTIONS =
  | UpdateSeriesRoof
  | UpdateAllSeriesRoof
  | AddCvCurveRoof
  | UpdateCvCurveRoof
  | UpdateCVKurvenRoof
  | AddSeriesRoof
  | UpdateSashProfilesRoof
  | UpdateSashProfileRoof
  | UpdateFrameProfileRoof
  | UpdateFrameProfilesRoof
  | AddFrameProfileRoof
  | AddSashProfileRoof
  | UpdateEditedFrameProfileRoof
  | UpdateEditedSashProfileRoof
  | UpdateConsolesRoof
  | UpdateConsoleRoof
  | AddConsoleRoof
  | UpdateConsoleSetsRoof
  | UpdateConsoleSetRoof
  | AddConsoleSetRoof
  | UpdateConsoleSetAssignmentRoof
  | UpdateConsoleSetAssignmentsRoof
  | AddConsoleSetAssignmentRoof
  | UpdateTestPhasesRoof
  | UpdateTestPhaseRoof
  | AddTestPhaseRoof
  | UpdateSeriesENMaxStrokesRoof
  | UpdateEditedStrokesRoof
  | UpdateWindDeflectors
  | UpdateEditedWindDeflector;

export function getSeriesENMaxStrokesRoof(): ThunkAction<
  Promise<void>,
  AdminState,
  void,
  AnyAction
> {
  return async (dispatch: Dispatch): Promise<void> => {
    const seriesENMaxStrokesRoofResult = await fetchSeriesENMaxStrokesRoof();
    dispatch(updateSeriesENMaxStrokesRoof(seriesENMaxStrokesRoofResult));
  };
}

export function updateEditedSeriesENMaxStrokesRoof(
  roofStrokes: SeriesENMaxStrokeRoof[],
): UpdateEditedStrokesRoof {
  return {
    type: 'UPDATE_EDITED_STROKES_ROOF',
    roofStrokes: roofStrokes,
  };
}

export function updateSeriesENMaxStrokesRoof(
  seriesENMaxStrokesRoof: SeriesENMaxStrokeRoof[],
): UpdateSeriesENMaxStrokesRoof {
  return {
    type: 'UPDATE_SERIES_EN_MAX_STROKES_ROOF',
    seriesENMaxStrokesRoof: seriesENMaxStrokesRoof,
  };
}

export function updateWindDeflectors(
  windDeflectors: WindDeflectorData[],
): UpdateWindDeflectors {
  return {
    type: 'UPDATE_WIND_DEFLECTORS',
    windDeflectors: windDeflectors,
  };
}

export function updateConsolesRoof(consoles: Console[]): UpdateConsolesRoof {
  return {
    type: 'UPDATE_CONSOLES_ROOF',
    consoles: consoles,
  };
}

export function updateConsoleRoof(console: Console): UpdateConsoleRoof {
  return {
    type: 'UPDATE_CONSOLE_ROOF',
    console: console,
  };
}

export function addConsoleRoof(newConsole: Console): AddConsoleRoof {
  return { type: 'ADD_CONSOLE_ROOF', console: newConsole };
}

export function updateConsoleSetsRoof(
  consoleSets: ConsoleSet[],
): UpdateConsoleSetsRoof {
  return {
    type: 'UPDATE_CONSOLE_SETS_ROOF',
    consoleSets: consoleSets,
  };
}

export function addConsoleSetAssignmentRoof(
  newConsoleSetAssignment: ConsoleSetAssignment,
): AddConsoleSetAssignmentRoof {
  return {
    type: 'ADD_CONSOLE_SET_ASSIGNMENT_ROOF',
    consoleSetAssignment: newConsoleSetAssignment,
  };
}
export function addConsoleSetAssignmentFacade(
  newConsoleSetAssignment: ConsoleSetAssignment,
): AddConsoleSetAssignmentFacade {
  return {
    type: 'ADD_CONSOLE_SET_ASSIGNMENT_FACADE',
    consoleSetAssignment: newConsoleSetAssignment,
  };
}

export function addConsoleSetRoof(
  newConsoleSet: ConsoleSet,
): AddConsoleSetRoof {
  return { type: 'ADD_CONSOLE_SET_ROOF', consoleSet: newConsoleSet };
}

export function updateConsoleSetAssignmentsFacade(
  consoleSetAssignments: ConsoleSetAssignment[],
): UpdateConsoleSetAssignmentsFacade {
  return {
    type: 'UPDATE_CONSOLE_SET_ASSIGNMENTS_FACADE',
    consoleSetAssignments: consoleSetAssignments,
  };
}

export function updateConsoleSetAssignmentRoof(
  consoleSetAssignment: ConsoleSetAssignment,
): UpdateConsoleSetAssignmentRoof {
  return {
    type: 'UPDATE_CONSOLE_SET_ASSIGNMENT_ROOF',
    consoleSetAssignment: consoleSetAssignment,
  };
}

export function updateConsoleSetAssignmentsRoof(
  consoleSetAssignments: ConsoleSetAssignment[],
): UpdateConsoleSetAssignmentsRoof {
  return {
    type: 'UPDATE_CONSOLE_SET_ASSIGNMENTS_ROOF',
    consoleSetAssignments: consoleSetAssignments,
  };
}

export function getWindDeflectors(): ThunkAction<
  Promise<void>,
  AdminState,
  void,
  AnyAction
> {
  return async (dispatch: Dispatch): Promise<void> => {
    const windDeflectorsResponse = await fetchWindDeflectors();
    dispatch(updateWindDeflectors(windDeflectorsResponse));
  };
}

export async function fetchWindDeflectors(): Promise<WindDeflectorData[]> {
  return fetchWithoutCalculationParameters('/api/admin/windDeflectors');
}

export function getFrameProfilesRoof(): ThunkAction<
  Promise<void>,
  AdminState,
  void,
  AnyAction
> {
  return async (dispatch: Dispatch): Promise<void> => {
    const frameProfileResult = await fetchFrameProfilesRoof();
    dispatch(updateFrameProfilesRoof(frameProfileResult));
  };
}

export function getSashProfilesRoof(): ThunkAction<
  Promise<void>,
  AdminState,
  any,
  AnyAction
> {
  return async (dispatch: Dispatch): Promise<void> => {
    const sashProfileResult = await fetchSashProfilesRoof();
    dispatch(updateSashProfilesRoof(sashProfileResult));
  };
}

export const updateFrameProfilesRoof: ActionCreator<UpdateFrameProfilesRoof> = (
  frameProfiles: FrameProfileRoof[],
): UpdateFrameProfilesRoof => {
  return {
    type: 'UPDATE_FRAME_PROFILES_ROOF',
    frameProfiles: frameProfiles,
  };
};

export function updateFrameProfileRoof(
  frameProfile: FrameProfileRoof,
): UpdateFrameProfileRoof {
  return {
    type: 'UPDATE_FRAME_PROFILE_ROOF',
    frameProfile: frameProfile,
  };
}

export const updateSashProfilesRoof: ActionCreator<UpdateSashProfilesRoof> = (
  sashProfiles: SashProfileRoof[],
): UpdateSashProfilesRoof => {
  return {
    type: 'UPDATE_SASH_PROFILES_ROOF',
    sashProfiles: sashProfiles,
  };
};

export const updateSashProfileRoof: ActionCreator<UpdateSashProfileRoof> = (
  sashProfile: SashProfileRoof,
): UpdateSashProfileRoof => {
  return {
    type: 'UPDATE_SASH_PROFILE_ROOF',
    sashProfile: sashProfile,
  };
};

export function updateCvCurveRoof(cvCurve: CVKurve): UpdateCvCurveRoof {
  return {
    type: 'UPDATE_CV_CURVE_ROOF',
    cvCurve: cvCurve,
  };
}

export function updateCVKurvenRoof(cvKurven: CVKurve[]): UpdateCVKurvenRoof {
  return {
    type: 'UPDATE_CV_KURVEN_ROOF',
    cvKurven: cvKurven,
  };
}

export function addCvCurveRoof(cvCurve: CVKurve): AddCvCurveRoof {
  return { type: 'ADD_CV_CURVE_ROOF', cvCurve: cvCurve };
}

export function addSeriesRoof(series: Series): AddSeriesRoof {
  return { type: 'ADD_SERIES_ROOF', series: series };
}

export const updateAllSeriesRoof: ActionCreator<UpdateAllSeriesRoof> = (
  series: Series[],
): UpdateAllSeriesRoof => {
  return {
    type: 'UPDATE_ALL_SERIES_ROOF',
    series: series,
  };
};

export function updateSeriesRoof(series: Series): UpdateSeriesRoof {
  return {
    type: 'UPDATE_SERIES_ROOF',
    series: series,
  };
}

export function changeWindDeflectors(
  changedWindDeflectors: WindDeflectorData[],
): ThunkAction<Promise<void>, AdminState, void, AnyAction> {
  return async (dispatch: Dispatch): Promise<void> => {
    await putAndStoreWithDelay(
      RequestTypes.ADMIN_WIND_DEFLECTOR,
      (updatedWindDeflectors: WindDeflectorData[] | undefined) => {
        if (updatedWindDeflectors) {
          dispatch(updateWindDeflectors(updatedWindDeflectors));
        }
      },
      changedWindDeflectors,
      -1,
    );
  };
}

export function changeMaxStrokesRoof(
  changedMaxStrokes: SeriesENMaxStrokeRoof[],
): ThunkAction<Promise<void>, AdminState, void, AnyAction> {
  return async (dispatch: Dispatch): Promise<void> => {
    await putAndStoreWithDelay(
      RequestTypes.MAX_STROKES_ROOF,
      (updatedMaxStrokes: SeriesENMaxStrokeRoof[] | undefined) => {
        if (updatedMaxStrokes) {
          dispatch(updateSeriesENMaxStrokesRoof(updatedMaxStrokes));
        }
      },
      changedMaxStrokes,
      -1,
    );
  };
}

export function changeFrameProfileRoof(
  changedFrameProfile: FrameProfileRoof,
): ThunkAction<Promise<void>, AdminState, void, AnyAction> {
  return async (dispatch: Dispatch): Promise<void> => {
    await putAndStoreWithDelay(
      RequestTypes.FRAME_PROFILE_ROOF,
      (updatedFrameProfile: FrameProfileRoof | undefined) => {
        if (!updatedFrameProfile) {
          return;
        }
        dispatch(updateFrameProfileRoof(updatedFrameProfile));
      },
      changedFrameProfile,
      changedFrameProfile.id,
    );
  };
}

export function changeSashProfileRoof(
  changedSashProfile: SashProfileRoof,
): ThunkAction<Promise<void>, AdminState, void, AnyAction> {
  return async (dispatch: Dispatch): Promise<void> => {
    await putAndStoreWithDelay(
      RequestTypes.SASH_PROFILE_ROOF,
      (updatedSashProfile: SashProfileRoof | undefined) => {
        if (!updatedSashProfile) {
          return;
        }
        dispatch(updateSashProfileRoof(updatedSashProfile));
      },
      changedSashProfile,
      changedSashProfile.id,
    );
  };
}

export function createFrameProfileRoof(
  newFrameProfile: NewFrameProfileRoof,
): ThunkAction<Promise<void>, AdminState, void, AnyAction> {
  return async (dispatch: Dispatch): Promise<void> => {
    await postAndStore(
      RequestTypes.FRAME_PROFILE_ROOF,
      (createdFrameProfile: FrameProfileRoof | undefined) => {
        if (!createdFrameProfile) {
          return;
        }
        dispatch(addFrameProfileRoof(createdFrameProfile));
      },
      newFrameProfile,
    );
  };
}

export function addFrameProfileRoof(
  frameProfile: FrameProfileRoof,
): AddFrameProfileRoof {
  return { type: 'ADD_FRAME_PROFILE_ROOF', frameProfile: frameProfile };
}

export function updatedEditedFrameProfileRoof(
  frameProfile: Edit<FrameProfileRoof> | Edit<NewFrameProfileRoof>,
): UpdateEditedFrameProfileRoof {
  return {
    type: 'UPDATE_EDITED_FRAME_PROFILE_ROOF',
    frameProfile: frameProfile,
  };
}

export function createSashProfileRoof(
  newSashProfile: NewSashProfileRoof,
): ThunkAction<Promise<void>, AdminState, void, AnyAction> {
  return async (dispatch: Dispatch): Promise<void> => {
    await postAndStore(
      RequestTypes.SASH_PROFILE_ROOF,
      (createdSashProfile: SashProfileRoof | undefined) => {
        if (!createdSashProfile) {
          return;
        }
        dispatch(addSashProfileRoof(createdSashProfile));
      },
      newSashProfile,
    );
  };
}

export function addSashProfileRoof(
  SashProfile: SashProfileRoof,
): AddSashProfileRoof {
  return { type: 'ADD_SASH_PROFILE_ROOF', SashProfile: SashProfile };
}

export function updatedEditedSashProfileRoof(
  SashProfile: Edit<SashProfileRoof>,
): UpdateEditedSashProfileRoof {
  return {
    type: 'UPDATE_EDITED_SASH_PROFILE_ROOF',
    sashProfile: SashProfile,
  };
}

export function updateTestPhasesRoof(
  testPhases: TestPhaseRoof[],
): UpdateTestPhasesRoof {
  return {
    type: 'UPDATE_TEST_PHASES_ROOF',
    testPhases: testPhases,
  };
}

export function updateTestPhaseRoof(
  testPhase: TestPhaseRoof,
): UpdateTestPhaseRoof {
  return {
    type: 'UPDATE_TEST_PHASE_ROOF',
    testPhase: testPhase,
  };
}

export function updateEditedWindDeflector(
  editedWindDeflector: Edit<WindDeflectorData>,
): UpdateEditedWindDeflector {
  return {
    type: 'UPDATE_EDITED_WIND_DEFLECTOR',
    windDeflector: editedWindDeflector,
  };
}

export function addTestPhaseRoof(testPhase: TestPhaseRoof): AddTestPhaseRoof {
  return { type: 'ADD_TEST_PHASE_ROOF', testPhase: testPhase };
}

export function changeTestPhaseRoof(
  changedTestPhase: TestPhaseRoofWithOptionalId,
): ThunkAction<Promise<void>, AdminState, void, AnyAction> {
  return async (
    dispatch: ThunkDispatch<AdminState, any, AnyAction>,
  ): Promise<void> => {
    if (!changedTestPhase.id) {
      throw new Error('Test phase id must be provided');
    }
    await putAndStoreWithDelay(
      RequestTypes.TEST_PHASES_ROOF,
      (updatedTestPhase: TestPhaseRoof | undefined) => {
        if (updatedTestPhase) {
          dispatch(updateTestPhaseRoof(updatedTestPhase));
        }
      },
      changedTestPhase,
      changedTestPhase.id,
    );
  };
}

export function createTestPhaseRoof(): ThunkAction<
  Promise<void>,
  AdminState,
  void,
  AnyAction
> {
  return async (
    dispatch: Dispatch,
    getState: () => AdminState,
  ): Promise<void> => {
    await postAndStore(
      RequestTypes.TEST_PHASES_ROOF,
      (createdTestPhase: TestPhaseRoof | undefined) => {
        if (!createdTestPhase) {
          return;
        }
        dispatch(addTestPhaseRoof(createdTestPhase));
      },
      getState().adminRoof.editedTestPhase!,
    );
  };
}

export function selectEditableTestPhaseRoof(
  testPhaseToSelect: TestPhaseRoof,
): ThunkAction<Promise<void>, AdminState, void, AnyAction> {
  return async (dispatch: Dispatch): Promise<void> => {
    dispatch(updateEditedTestPhaseRoof(testPhaseToSelect));
  };
}

export function selectEditedWindDeflector(
  windDeflectorToSelect: WindDeflectorData,
): ThunkAction<Promise<void>, AdminState, void, AnyAction> {
  return async (dispatch: Dispatch): Promise<void> => {
    dispatch(updateEditedWindDeflector(windDeflectorToSelect));
  };
}

export function copyTestPhaseRoof(
  id: number,
): ThunkAction<Promise<void>, AdminState, void, AnyAction> {
  return async (
    dispatch: ThunkDispatch<AdminState, any, AnyAction>,
  ): Promise<void> => {
    postAndStore(
      RequestTypes.COPY_TEST_PHASE_ROOF,
      () => {
        dispatch(getTestPhasesRoof());
      },
      {},
      id,
    );
  };
}
