import React, { FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Drive } from '../redux/calculationResultReducer';
import { useDispatch, useSelector } from 'react-redux';
import { DialogFE } from './Dialog';
import { IState, MyCalcThunkDispatch } from '../redux/store';
import './InstallationDiagramDialog.scss';
import { showDialog, updateOverlayActive } from '../redux/uiStateActions';
import { useSelectedDrive } from '../hooks/selectorHooks';
import { AnyAction } from 'redux';

const installationDiagramRoot = '/installationDiagrams/';

const InstallationDiagramDialog: FC<React.PropsWithChildren<unknown>> = () => {
  const dispatch: MyCalcThunkDispatch<AnyAction> = useDispatch();
  const translator = useIntl();
  const selectedDrive = useSelectedDrive();
  if (!selectedDrive || !selectedDrive.installationDiagramName) {
    return <></>;
  }

  return (
    <div className="installation-diagram-dialog__button-area">
      <div className="installation-diagram-dialog__button-label">
        <FormattedMessage id="INSTALLATION_DIAGRAM_DIALOG_BUTTON" />
      </div>
      <div
        className="installation-diagram-dialog__button"
        onClick={() => {
          dispatch(
            showDialog(
              <DialogFE
                heading="INSTALLATION_DIAGRAM_DIALOG_HEADING"
                componentClass="installation-diagram-dialog"
              >
                <img
                  src={`${installationDiagramRoot}${selectedDrive.installationDiagramName}`}
                  alt={translator.formatMessage({
                    id: 'INSTALLATION_DIAGRAM_DIALOG_HEADING',
                  })}
                />
              </DialogFE>,
            ),
          );
          dispatch(updateOverlayActive(true));
        }}
      >
        <img
          src={`${installationDiagramRoot}${selectedDrive.installationDiagramName}`}
          alt={translator.formatMessage({
            id: 'INSTALLATION_DIAGRAM_DIALOG_BUTTON',
          })}
        />
      </div>
    </div>
  );
};

export default InstallationDiagramDialog;
