import { ThunkAction } from 'redux-thunk';
import { Action, AnyAction, Dispatch } from 'redux';
import { ReactElement } from 'react';
import { Config } from './globalUiStateReducer';

interface UpdateDisplaySnackbar extends Action<'UPDATE_DISPLAY_SNACK_BAR'> {
  displaySnackBar: boolean;
}

interface UpdateSnackBarMessage extends Action<'UPDATE_SNACK_BAR_MESSAGE'> {
  snackBarMessage: string | ReactElement;
}

interface UpdateSnackBarMessageText
  extends Action<'UPDATE_SNACK_BAR_MESSAGE_TEXT'> {
  snackBarMessageText: string;
}

export interface UpdateConfig extends Action<'UPDATE_CONFIG'> {
  config: Config;
}

export function showSnackBar<T>(
  snackBarMessage?: string,
  snackBarMessageText?: string,
): ThunkAction<Promise<void>, T, void, AnyAction> {
  return async (dispatch: Dispatch, getState: () => T): Promise<void> => {
    dispatch(updateDisplaySnackBar(true));
    if (snackBarMessageText) {
      dispatch(updateSnackBarMessageText(snackBarMessageText));
    }
    if (snackBarMessage) {
      dispatch(updateSnackBarMessage(snackBarMessage));
    }
  };
}

export function updateDisplaySnackBar(
  displaySnackBar: boolean,
): UpdateDisplaySnackbar {
  return {
    type: 'UPDATE_DISPLAY_SNACK_BAR',
    displaySnackBar: displaySnackBar,
  };
}

export function updateSnackBarMessage(
  snackBarMessage: string | ReactElement,
): UpdateSnackBarMessage {
  return {
    type: 'UPDATE_SNACK_BAR_MESSAGE',
    snackBarMessage: snackBarMessage,
  };
}

export function updateSnackBarMessageText(
  snackBarMessageText: string,
): UpdateSnackBarMessageText {
  return {
    type: 'UPDATE_SNACK_BAR_MESSAGE_TEXT',
    snackBarMessageText: snackBarMessageText,
  };
}

export function updateConfig(config: Config): UpdateConfig {
  return {
    type: 'UPDATE_CONFIG',
    config: config,
  };
}
