import Keycloak from 'keycloak-js';
import {
  loginSSO,
  setUserIsAuthenticated,
  setUserIsAuthorized,
} from '../redux/key_cloak/keyCloakActions';
import { Store } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

type ErrorMessages = {
  loginFailed: string;
  noMyCalcAccess: string;
  newUserWithoutCompany: string;
};

export function qaMode(): boolean {
  const queryParams = new URLSearchParams(window.location.search);
  return queryParams.has('qa');
}

//Generated using JwtTokenService::generateTestLoginToken() valid 5 years;
function qaToken() {
  return 'eyJhbGciOiJSUzI1NiJ9.eyJzdWIiOiI4OGU2NDBiOC0xYTg1LTQwMDMtOWJlYi01MDBlMTE4MGMzOWEiLCJleHAiOjIwMDM0ODU3NDQsImlhdCI6MTY4ODEyNTc0NCwicm9sZXMiOlsiYWRtaW4iLCJ1c2VyX2FkbWluIl19.e7c1vjChu_h8PtxCC3ZQxs25bSQ7oiGv57oSMk8hTOoX-L0THf5dvonfjtv-aTkDdkD4oD0pyy2G6MyfJDf2cFyVDerMBpoPBqHvwG8AR_JaSHfBwU_wm1GUCOQg9j9iclEwDL8IjtSY-KmheeOIGTLvEMqt1rhkzks5Y7j4sn7KAooXHvhtjSH8PgGUQpi4UoGIv8joTSfHzbRVuIJ2TX2yWnzpAmZxBHJuwVnVnZN5yjEFANIcLWv6k68DzQMzmfsyXtjWkxr2ieghozM2dSMmUL4L2DfULBl_PGhZb95sBsnFVqwg4_3CvnnI78Ip8HwVIO22K94SIhaU0fgB7g';
}

export class KeyCloak {
  private store: Store & { dispatch: ThunkDispatch<any, any, any> };
  requireLogin: boolean;
  private keycloakInstance: Keycloak.KeycloakInstance;
  errorMessages: ErrorMessages;
  loggedIn: boolean;
  keyCloakId: string | undefined;

  constructor(
    store: Store & {
      dispatch: ThunkDispatch<any, any, any>;
    },
    requireLogin: boolean,
    serverUrl: string,
    errorMessages: ErrorMessages,
  ) {
    this.store = store;
    this.requireLogin = requireLogin;
    this.keycloakInstance = new Keycloak({
      realm: 'DH',
      clientId: 'mycalc',
      url: serverUrl,
    });
    this.errorMessages = errorMessages;
    this.loggedIn = false;
  }

  withTimeOut<T>(prom: Promise<T>, time: number): Promise<T> {
    return Promise.race([
      prom,
      new Promise<T>((_r, rej) => setTimeout(rej, time)),
    ]);
  }

  jwtToken(): Promise<string | undefined> {
    // @ts-ignore
    if (qaMode() && this.loggedIn) {
      return Promise.resolve(qaToken());
      // return Promise.resolve('');
    }
    if (this.loggedIn) {
      if (this.keycloakInstance.isTokenExpired(20)) {
        return this.withTimeOut(this.keycloakInstance.updateToken(30), 1000)
          .then(refreshSuccessful => {
            if (!refreshSuccessful) {
              window.location.reload();
            }

            return this.keycloakInstance.token;
          })
          .catch(() => {
            window.logger.error('Update Token timed out after ' + 1000 + 'ms');
            alert(
              'Your login could not be refreshed; Please apologize the inconvenience; Please reload the window!',
            );
            throw Error(
              'Your login could not be refreshed; Please apologize the inconvenience; Please reload the window!',
            );
          });
      } else {
        return Promise.resolve(this.keycloakInstance.token);
      }
    }

    return Promise.resolve(undefined);
  }

  login(): void {
    this.keycloakInstance.login();
  }

  logout(): Promise<void> {
    return this.keycloakInstance.logout();
  }

  loginSuccessful(): void {
    this.loggedIn = true;
    if (!qaMode()) {
      this.startInterval();
    }
  }

  startInterval(): void {
    setInterval(() => {
      try {
        if (this.keycloakInstance.isTokenExpired(30)) {
          this.keycloakInstance.updateToken(30).then(refreshSuccessful => {
            if (!refreshSuccessful) {
              window.location.reload();
            }
          });
        }
      } catch (e) {
        window.location.reload();
      }
    }, 500);
  }

  initKeyCloak(): void {
    if (qaMode()) {
      this.store.dispatch(setUserIsAuthenticated(true));
      this.store.dispatch(
        loginSSO(
          JSON.stringify({
            roles: ['mycalc_access'],
            sub: '344b989d-573a-4c14-9782-6dbf26a644b4',
            company_id: 53,
            email: 'test@email.de',
            preferred_username: 'test@email.de',
            family_name: 'test',
            given_name: 'test',
            gender: '',
            admin: false,
          }),
          this.errorMessages.loginFailed,
        ),
      );
      return;
    }

    this.keycloakInstance
      .init({
        enableLogging: false,
        onLoad: this.requireLogin ? 'login-required' : 'check-sso',
        silentCheckSsoRedirectUri:
          window.location.origin + '/silent-check-sso.html',
      })
      .then((authenticated: boolean) => {
        if (!authenticated) {
          this.store.dispatch(setUserIsAuthenticated(false));
        }

        authenticated &&
          this.keycloakInstance
            .loadUserInfo()
            // @ts-ignore
            .then((i: { company_id: number }) => {
              this.store.dispatch(setUserIsAuthenticated(true));

              const companyId: number = i.company_id;
              const hasMycalcAccess: boolean =
                this.keycloakInstance.hasRealmRole('mycalc_access');

              if (
                (!companyId || !hasMycalcAccess) &&
                !location.pathname.startsWith('/admin')
              ) {
                this.store.dispatch(setUserIsAuthorized(false));
              } else {
                this.store.dispatch(
                  loginSSO(
                    this.keycloakInstance.token!,
                    this.errorMessages.loginFailed,
                  ),
                );
              }
            });
      })
      .catch(e => {
        window.logger.error(e);
        alert('failed to initialize');
      });
  }
}

export default KeyCloak;
