import React, { FC, useEffect, useState } from 'react';
import SearchField from '../../elements/SearchField';
import { UiConstants } from '../constants';
import './ConsoleAreaConsoleSetAssignmentDataView.scss';
import { useDispatch, useSelector } from 'react-redux';
import Dialog from '../../components/Dialog';
import Pagination from '../components/Pagination';
import FormLayout from '../../elements/FormLayout';
import { InputFieldNumber } from '../../elements/InputField';
import { Switch } from '../../elements/Switch';
import {
  BaseProfileDataWithId,
  ConsoleSet,
  ConsoleSetAssignment,
  NewConsoleSetAssignment,
  Series,
  System,
} from '../../redux/admin/adminFacadeReducer';
import { AdminState, AdminThunkDispatch } from '../../redux/admin/adminStore';
import {
  changeConsoleSetAssignment,
  createConsoleSetAssignment,
} from '../../redux/admin/adminFacadeActions';
import {
  MenuPlacement,
  SelectField,
  selectFieldValue,
  SelectOption,
} from '../../elements/SelectField';
import { fieldsFilled, findById, search } from '../general/helpers';
import TableRow from '../elements/TableRow';
import TableHeader from '../elements/TableHeader';
import Table from '../components/Table';
import { RangeOfApplication } from '../../redux/constants';
import { AnyAction } from 'redux';
import { useAdminSearch } from '../hooks';

interface AdminConsoleSetAssignmentDialogContentProps {
  selectedConsoleSetAssignment: ConsoleSetAssignment | undefined;
  setSelectedConsoleSetAssignment: (
    csa: ConsoleSetAssignment | undefined,
  ) => void;
  isActive: boolean;
  setIsActive: (b: boolean) => void;
  isPreview: boolean;
  setIsPreview: (b: boolean) => void;
  system: System | undefined;
  setSystem: (s: System | undefined) => void;
  series: Series | undefined;
  setSeries: (s: Series | undefined) => void;
  sashProfile: BaseProfileDataWithId | undefined;
  setSashProfile: (sp: BaseProfileDataWithId | undefined) => void;
  frameProfile: BaseProfileDataWithId | undefined;
  setFrameProfile: (fp: BaseProfileDataWithId | undefined) => void;
  consoleSet: ConsoleSet | undefined;
  setConsoleSet: (cs: ConsoleSet | undefined) => void;
  kettenaustritt: number | undefined | null;
  setKettenaustritt: (k: number | null) => void;
  setAllSashProfile: (b: boolean | undefined) => void;
  allSashProfile: boolean | undefined;
  allFrameProfile: boolean | undefined;
  setAllFrameProfile: (b: boolean) => void;
  rangeOfApplication: RangeOfApplication;
}

const AdminConsoleSetAssignmentDialogContent: FC<
  React.PropsWithChildren<AdminConsoleSetAssignmentDialogContentProps>
> = (props: AdminConsoleSetAssignmentDialogContentProps) => {
  const allSystems = useSelector<AdminState, System[]>(
    state => state.adminFacade.systems,
  );
  const allSeries = useSelector<AdminState, Series[]>(
    state =>
      (props.rangeOfApplication === RangeOfApplication.FACADE
        ? state.adminFacade
        : state.adminRoof
      ).series,
  );
  const allSashProfiles = useSelector<AdminState, BaseProfileDataWithId[]>(
    state =>
      (props.rangeOfApplication === RangeOfApplication.FACADE
        ? state.adminFacade
        : state.adminRoof
      ).sashProfiles,
  );
  const allFrameProfiles = useSelector<AdminState, BaseProfileDataWithId[]>(
    state =>
      (props.rangeOfApplication === RangeOfApplication.FACADE
        ? state.adminFacade
        : state.adminRoof
      ).frameProfiles,
  );
  const allConsoleSets = useSelector<AdminState, ConsoleSet[]>(
    state =>
      (props.rangeOfApplication === RangeOfApplication.FACADE
        ? state.adminFacade
        : state.adminRoof
      ).consoleSets,
  );

  function setSashProfile(newValue: SelectOption<string>): void {
    if (newValue.value === 'all') {
      props.setAllSashProfile(true);
      props.setSashProfile(undefined);
    } else {
      props.setAllSashProfile(false);
      props.setSashProfile(findById(+newValue.value, allSashProfiles));
    }
  }

  function setFrameProfile(newValue: SelectOption<string>): void {
    if (newValue.value === 'all') {
      props.setAllFrameProfile(true);
      props.setFrameProfile(undefined);
    } else {
      props.setAllFrameProfile(false);
      props.setFrameProfile(findById(+newValue.value, allFrameProfiles));
    }
  }

  function setSeries(newValue: SelectOption<string>): void {
    props.setSashProfile(undefined);
    props.setFrameProfile(undefined);
    props.setSeries(findById(+newValue.value, allSeries));
  }

  function setSystem(newValue: SelectOption<string>): void {
    props.setSashProfile(undefined);
    props.setFrameProfile(undefined);
    props.setSeries(undefined);
    props.setSystem(findById(+newValue.value, allSystems));
  }

  return (
    <div>
      <FormLayout additionalClass="console-set-assignment-data-dialog__console-set-assignment-data">
        <div className="console-set-assignment-data-dialog__system"></div>
        <SelectField
          additionalClass="console-set-assignment-data-dialog__system"
          label="System *"
          value={
            props.system
              ? {
                  value: props.system.id.toString(),
                  label: props.system.name,
                }
              : undefined
          }
          action={setSystem}
          options={allSystems.map(sys => ({
            label: sys.name,
            value: sys.id.toString(),
          }))}
          name="system-field"
          searchable={true}
          placeholder="z.B. Schüco"
        />

        <div className="console-set-assignment-data-dialog__series"></div>
        <SelectField
          additionalClass="console-set-assignment-data-dialog__series"
          key={props.series?.id}
          label="Serie *"
          value={
            props.series
              ? {
                  value: props.series.id.toString(),
                  label: props.series.name,
                }
              : undefined
          }
          action={setSeries}
          options={allSeries
            .filter(series => series.system.id === props.system?.id)
            .map(s => ({ label: s.name, value: s.id.toString() }))}
          name="series-field"
          searchable={true}
          placeholder="z.B. AWS ..."
          disabled={!props.system}
        />

        <div className="console-set-assignment-data-dialog__sash-profile"></div>
        <SelectField
          additionalClass="console-set-assignment-data-dialog__sash-profile"
          label="Flügelprofil *"
          value={
            props.allSashProfile
              ? { value: 'all', label: 'Alle Flügelprofile' }
              : props.sashProfile
              ? {
                  value: props.sashProfile.id.toString(),
                  label: props.sashProfile.artNr,
                }
              : undefined
          }
          action={newValue => {
            setSashProfile(newValue);
          }}
          options={allSashProfiles
            .filter(sash => sash.seriesId === props.series?.id)
            .map(sp => ({ label: sp.artNr, value: sp.id.toString() }))
            .concat({ value: 'all', label: 'Alle Flügelprofile' })}
          name="sash-field"
          searchable={true}
          placeholder="z.B. 0815"
          disabled={!(props.system && props.series)}
        />

        <div className="console-set-assignment-data-dialog__frame-profile"></div>
        <SelectField
          additionalClass="console-set-assignment-data-dialog__frame-profile"
          label="Blendrahmen *"
          value={
            props.allFrameProfile
              ? { value: 'all', label: 'Alle Blendrahmen' }
              : props.frameProfile
              ? {
                  value: props.frameProfile.id.toString(),
                  label: props.frameProfile.artNr,
                }
              : undefined
          }
          action={setFrameProfile}
          options={allFrameProfiles
            .filter(frame => frame.seriesId === props.series?.id)
            .map(fp => ({ label: fp.artNr, value: fp.id.toString() }))
            .concat({ value: 'all', label: 'Alle Blendrahmen' })}
          name="frame-field"
          searchable={true}
          placeholder="z.B. 0815"
          disabled={!(props.system && props.series)}
        />

        <div className="console-set-assignment-data-dialog__console-set"></div>
        <SelectField
          additionalClass="console-set-assignment-data-dialog__console-set"
          label="Konsolen-Set *"
          value={
            props.consoleSet
              ? {
                  value: props.consoleSet,
                  label: props.consoleSet.artNr,
                }
              : undefined
          }
          action={newValue => {
            props.setConsoleSet(newValue.value);
          }}
          options={allConsoleSets.map(set => selectFieldValue(set.artNr, set))}
          name="console-set"
          searchable={true}
          placeholder="z.B. 0815"
        />
        <InputFieldNumber
          additionalClass="console-set-assignment-data-dialog__chain"
          label="Kettenaustritt *"
          placeholder="z.B. 42"
          value={props.kettenaustritt}
          onChange={props.setKettenaustritt}
        />
        <div className="consoles-data-dialog__switch">
          <Switch
            labelText="Aktiv"
            turnedOn={props.isActive}
            onChange={props.setIsActive}
          />
        </div>
        <div className="consoles-data-dialog__preview">
          <Switch
            labelText="Vorschau"
            turnedOn={props.isPreview}
            onChange={props.setIsPreview}
          />
        </div>
      </FormLayout>
    </div>
  );
};

interface AdminConsoleDialogProps {
  rangeOfApplication: RangeOfApplication;
  setDialogIsShown: (b: boolean) => void;
  dialogIsShown: boolean;
  selectedConsoleSetAssignment: ConsoleSetAssignment | undefined;
  setSelectedConsoleSetAssignment: (
    assignment: ConsoleSetAssignment | undefined,
  ) => void;
}

const AdminConsoleSetAssignmentDialog: FC<
  React.PropsWithChildren<AdminConsoleDialogProps>
> = (props: AdminConsoleDialogProps) => {
  const dispatch: AdminThunkDispatch<AnyAction> = useDispatch();

  const [isActive, setIsActive] = useState(
    props.selectedConsoleSetAssignment?.active || false,
  );

  const [isPreview, setIsPreview] = useState(
    props.selectedConsoleSetAssignment?.preview ||
      (props.selectedConsoleSetAssignment?.preview === false ? false : true),
  );

  const [system, setSystem] = useState(
    props.selectedConsoleSetAssignment?.system,
  );
  const [series, setSeries] = useState(
    props.selectedConsoleSetAssignment?.series,
  );

  const [sashProfile, setSashProfile] = useState<
    BaseProfileDataWithId | undefined
  >(
    props.rangeOfApplication === RangeOfApplication.FACADE
      ? props.selectedConsoleSetAssignment?.sashProfileFacade
      : props.selectedConsoleSetAssignment?.sashProfileRoof,
  );

  const [frameProfile, setFrameProfile] = useState<
    BaseProfileDataWithId | undefined
  >(
    props.rangeOfApplication === RangeOfApplication.FACADE
      ? props.selectedConsoleSetAssignment?.frameProfileFacade
      : props.selectedConsoleSetAssignment?.frameProfileRoof,
  );
  const [consoleSet, setConsoleSet] = useState(
    props.selectedConsoleSetAssignment?.consoleSet,
  );
  const [kettenaustritt, setKettenaustritt] = useState<
    number | undefined | null
  >(props.selectedConsoleSetAssignment?.kettenaustritt);
  const [allSashProfile, setAllSashProfile] = useState(
    props.rangeOfApplication === RangeOfApplication.FACADE
      ? props.selectedConsoleSetAssignment?.allFluegelprofile
      : props.selectedConsoleSetAssignment?.allFluegelprofileDach,
  );
  const [allFrameProfile, setAllFrameProfile] = useState(
    props.rangeOfApplication === RangeOfApplication.FACADE
      ? props.selectedConsoleSetAssignment?.allBlendrahmen
      : props.selectedConsoleSetAssignment?.allBlendrahmenDach,
  );

  useEffect(() => {
    resetInputFields();
  }, [props.selectedConsoleSetAssignment]);

  function resetInputFields(): void {
    setSystem(props.selectedConsoleSetAssignment?.system);
    setSeries(props.selectedConsoleSetAssignment?.series);
    setSashProfile(
      props.rangeOfApplication === RangeOfApplication.FACADE
        ? props.selectedConsoleSetAssignment?.sashProfileFacade
        : props.selectedConsoleSetAssignment?.sashProfileRoof,
    );
    setFrameProfile(
      props.rangeOfApplication === RangeOfApplication.FACADE
        ? props.selectedConsoleSetAssignment?.frameProfileFacade
        : props.selectedConsoleSetAssignment?.frameProfileRoof,
    );

    setConsoleSet(props.selectedConsoleSetAssignment?.consoleSet);
    setKettenaustritt(props.selectedConsoleSetAssignment?.kettenaustritt);
    setIsActive(props.selectedConsoleSetAssignment?.active || false);
    setIsPreview(
      props.selectedConsoleSetAssignment?.preview ||
        (props.selectedConsoleSetAssignment?.preview === false ? false : true),
    );
    setAllSashProfile(
      props.rangeOfApplication === RangeOfApplication.FACADE
        ? props.selectedConsoleSetAssignment?.allFluegelprofile
        : props.selectedConsoleSetAssignment?.allFluegelprofileDach,
    );
    setAllFrameProfile(
      props.rangeOfApplication === RangeOfApplication.FACADE
        ? props.selectedConsoleSetAssignment?.allBlendrahmen
        : props.selectedConsoleSetAssignment?.allBlendrahmenDach,
    );
  }

  function mandatoryFieldsFilled(): boolean {
    return fieldsFilled(system, series, kettenaustritt, consoleSet, isActive);
  }

  function editConsoleSetAssignment(): void {
    const updatedAssignment: ConsoleSetAssignment = {
      ...props.selectedConsoleSetAssignment!,
      system: system!,
      series: series!,
      consoleSet: consoleSet!,
      active: isActive,
      preview: isPreview,
      kettenaustritt: kettenaustritt!,
      allFluegelprofile: allSashProfile,
      allBlendrahmen: allFrameProfile,
    };
    if (props.rangeOfApplication === RangeOfApplication.FACADE) {
      updatedAssignment.frameProfileFacade = frameProfile!;
      updatedAssignment.sashProfileFacade = sashProfile!;
      updatedAssignment.allFluegelprofile = allSashProfile;
      updatedAssignment.allBlendrahmen = allFrameProfile;
    } else {
      updatedAssignment.frameProfileRoof = frameProfile!;
      updatedAssignment.sashProfileRoof = sashProfile!;
      updatedAssignment.allFluegelprofileDach = allSashProfile;
      updatedAssignment.allBlendrahmenDach = allFrameProfile;
    }
    if (props.selectedConsoleSetAssignment)
      dispatch(changeConsoleSetAssignment(updatedAssignment));
  }

  function generateConsoleSetAssignment(): void {
    const updatedAssignment: NewConsoleSetAssignment = {
      ...props.selectedConsoleSetAssignment!,
      system: system!,
      series: series!,
      consoleSet: consoleSet!,
      active: isActive,
      preview: isPreview,
      kettenaustritt: kettenaustritt!,

      rangeOfApplication: props.rangeOfApplication,
    };
    if (props.rangeOfApplication === RangeOfApplication.FACADE) {
      updatedAssignment.frameProfileFacade = frameProfile!;
      updatedAssignment.sashProfileFacade = sashProfile!;
      updatedAssignment.allFluegelprofile = allSashProfile;
      updatedAssignment.allBlendrahmen = allFrameProfile;
    } else {
      updatedAssignment.frameProfileRoof = frameProfile!;
      updatedAssignment.sashProfileRoof = sashProfile!;
      updatedAssignment.allFluegelprofileDach = allSashProfile;
      updatedAssignment.allBlendrahmenDach = allFrameProfile;
    }
    dispatch(createConsoleSetAssignment(updatedAssignment));
  }

  return (
    <Dialog
      key={props.dialogIsShown.toString()}
      cancelAction={resetInputFields}
      setDialogIsShown={props.setDialogIsShown}
      dialogIsShown={props.dialogIsShown}
      componentClass={''}
      headingText={
        props.selectedConsoleSetAssignment
          ? 'Konsolen-Set-Zuordnung bearbeiten'
          : 'Konsolen-Set-Zuordnung anlegen'
      }
      footerProps={{
        notTranslated: true,
        cancelAction: () => {
          resetInputFields();
          props.setDialogIsShown(false);
          props.setSelectedConsoleSetAssignment(undefined);
        },
        saveAction: mandatoryFieldsFilled()
          ? () => {
              props.selectedConsoleSetAssignment
                ? editConsoleSetAssignment()
                : generateConsoleSetAssignment();
              props.setDialogIsShown(false);
            }
          : undefined,

        primaryActionLabelText: props.selectedConsoleSetAssignment
          ? 'Speichern'
          : 'Anlegen',
      }}
    >
      <AdminConsoleSetAssignmentDialogContent
        selectedConsoleSetAssignment={props.selectedConsoleSetAssignment}
        setSelectedConsoleSetAssignment={props.setSelectedConsoleSetAssignment}
        system={system}
        setSystem={setSystem}
        isActive={isActive}
        isPreview={isPreview}
        setIsActive={setIsActive}
        setIsPreview={setIsPreview}
        series={series}
        setSeries={setSeries}
        sashProfile={sashProfile}
        setSashProfile={setSashProfile}
        frameProfile={frameProfile}
        setFrameProfile={setFrameProfile}
        consoleSet={consoleSet}
        setConsoleSet={setConsoleSet}
        kettenaustritt={kettenaustritt}
        setKettenaustritt={setKettenaustritt}
        setAllSashProfile={setAllSashProfile}
        allSashProfile={allSashProfile}
        allFrameProfile={allFrameProfile}
        setAllFrameProfile={setAllFrameProfile}
        rangeOfApplication={props.rangeOfApplication}
      />
    </Dialog>
  );
};

interface ConsoleAreaConsoleSetAssignmentsDataViewProps {
  rangeOfApplication: RangeOfApplication;
}

const ConsoleAreaConsoleSetAssignmentsDataView: FC<
  React.PropsWithChildren<ConsoleAreaConsoleSetAssignmentsDataViewProps>
> = props => {
  const [dialogIsShown, setDialogIsShown] = useState(false);
  const consoleSetAssignments = useSelector<AdminState, ConsoleSetAssignment[]>(
    state =>
      props.rangeOfApplication === RangeOfApplication.FACADE
        ? state.adminFacade.consoleSetAssignments
        : state.adminRoof.consoleSetAssignments,
  );

  const [selectedConsoleSetAssignment, setSelectedConsoleSetAssignment] =
    useState<undefined | ConsoleSetAssignment>(undefined);

  const [indexOfFirstPageElement, setIndexOfFirstPageElement] = useState(0);
  const [page, setPage] = useState(1);
  const [searchString, setSearchString] = useState('');
  const [filterActive, setFilterActive, searchResult] = useAdminSearch(
    consoleSetAssignments,
    searchString,
    [],
    assignment => [
      assignment.system?.name,
      assignment.series?.name,
      (props.rangeOfApplication === RangeOfApplication.FACADE
        ? assignment?.frameProfileFacade
        : assignment?.frameProfileRoof
      )?.artNr || '',
      (props.rangeOfApplication === RangeOfApplication.FACADE
        ? assignment?.sashProfileFacade
        : assignment?.sashProfileRoof
      )?.artNr || '',
      assignment.consoleSet?.name,
      assignment.consoleSet.artNr,
    ],
  );

  const dispatch: AdminThunkDispatch<AnyAction> = useDispatch();

  function enableEditConsoleSetAssignment(
    consoleSetAssignment: ConsoleSetAssignment,
  ): void {
    setDialogIsShown(true);
    setSelectedConsoleSetAssignment(consoleSetAssignment);
  }

  function switchActiveAction(
    consoleSetAssignment: ConsoleSetAssignment,
  ): void {
    dispatch(
      changeConsoleSetAssignment({
        ...consoleSetAssignment,
        active: !consoleSetAssignment.active,
      }),
    );
  }

  function triggerCreationMode(): void {
    setDialogIsShown(true);
    setSelectedConsoleSetAssignment(undefined);
  }

  function getCurrentTableContent(): ConsoleSetAssignment[] {
    return searchResult.slice(
      indexOfFirstPageElement,
      indexOfFirstPageElement + 20,
    );
  }

  return (
    <>
      <AdminConsoleSetAssignmentDialog
        setDialogIsShown={setDialogIsShown}
        dialogIsShown={dialogIsShown}
        selectedConsoleSetAssignment={selectedConsoleSetAssignment}
        setSelectedConsoleSetAssignment={setSelectedConsoleSetAssignment}
        rangeOfApplication={props.rangeOfApplication}
      />
      <div className="sub-header">
        <div className="sub-header__title"> Konsolen-Set-Zuordnung</div>

        <SearchField
          setSearchString={setSearchString}
          searchString={searchString}
          placeholderText="Konsolenset-Zuordnung suchen..."
          small={true}
          setFilterActive={setFilterActive}
          filterActive={filterActive}
        />
        <button
          className="sub-header__button sub-header__button--add"
          onClick={() => triggerCreationMode()}
        >
          {UiConstants.NEW_ENTRY}
        </button>
      </div>

      <div className="facade-console-set-assignment-data">
        <Table subNavigation={true}>
          <TableHeader>
            <th>System</th>
            <th>Serie</th>
            <th>Flügel</th>
            <th>Blendrahmen</th>
            <th>Konsolen-Set</th>
            <th>Kettenaustritt (mm)</th>
            <th>Aktiv</th>
            <th>Aktion</th>
          </TableHeader>
          <tbody>
            {getCurrentTableContent().map(assignment => (
              <TableRow key={assignment.id}>
                <td>{assignment.system.name}</td>
                <td>{assignment.series.name}</td>
                <td>
                  {(props.rangeOfApplication === RangeOfApplication.FACADE
                    ? assignment?.sashProfileFacade
                    : assignment?.sashProfileRoof
                  )?.artNr || 'alle Flügelprofile'}
                </td>
                <td>
                  {(props.rangeOfApplication === RangeOfApplication.FACADE
                    ? assignment?.frameProfileFacade
                    : assignment?.frameProfileRoof
                  )?.artNr || 'alle Blendrahmen'}
                </td>
                <td>{assignment.consoleSet?.artNr}</td>
                <td>{assignment.kettenaustritt}</td>
                <td>
                  <Switch
                    key={assignment.id}
                    turnedOn={assignment.active}
                    onChange={(b: boolean) => switchActiveAction(assignment)}
                  />
                </td>
                <td>
                  <button
                    onClick={() => enableEditConsoleSetAssignment(assignment)}
                  >
                    {UiConstants.EDIT}
                  </button>
                </td>
              </TableRow>
            ))}
          </tbody>
        </Table>
      </div>
      <Pagination
        searchString={searchString}
        numberOfPages={searchResult.length}
        page={page}
        setPage={setPage}
        indexOfFirstPageElement={indexOfFirstPageElement}
        setIndexOfFirstPageElement={setIndexOfFirstPageElement}
      />
    </>
  );
};

export default ConsoleAreaConsoleSetAssignmentsDataView;
